import React, { useState } from 'react';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import {
  ClusterBoxHeader,
  ClusterTitle,
  ClusterBox,
  CollapseButton,
  ClusterBoxDetail,
} from 'src/style/AccordionStyle';
import { ISubCluster } from 'src/types/PatentSearchTypes';

interface IProps {
  content: ISubCluster;
  appendKeyword: (clusterKeyword: string) => void;
}

/** 聚類分析-摺疊面板 */
const ClusterAccordion: React.FC<IProps> = props => {
  const { content, appendKeyword } = props;
  const [isActive, setIsActive] = useState(true);
  const togglePanel = (e: any) => {
    e.stopPropagation();
    setIsActive(!isActive);
  };
  return (
    <ClusterBox key={content.keyword}>
      <ClusterBoxHeader onClick={() => appendKeyword(content.keyword)}>
        <ClusterTitle>{content.keyword}</ClusterTitle>
        {content.subKeywords && (
          <CollapseButton onClick={e => togglePanel(e)}>
            {isActive ? <FaAngleUp /> : <FaAngleDown />}
          </CollapseButton>
        )}
      </ClusterBoxHeader>
      {content.subKeywords && (
        <ClusterBoxDetail isShow={isActive}>
          {content.subKeywords.map(subKeyword => (
            <p key={subKeyword} onClick={() => appendKeyword(subKeyword)}>
              {subKeyword}
            </p>
          ))}
        </ClusterBoxDetail>
      )}
    </ClusterBox>
  );
};

export default ClusterAccordion;
