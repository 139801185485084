import React from 'react';
import styled from 'styled-components';

import { IBasicRequest } from 'src/types/PatentSearchTypes';
import { blue1, blue4, blue7, blue8, iceblue1 } from 'src/style/theme/Color';
import { countries } from 'src/constants/PatentSearchConstants';

const Column = styled.div`
  margin-bottom: 12px;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  position: relative;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: ${iceblue1};
`;

const CountryTabs = styled.div`
  margin-bottom: 12px;
  display: flex;
`;
const CountryTab = styled.div<{ active: boolean }>`
  width: 64px;
  height: 35px;
  margin-right: 10px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 7px 22px;
  border-radius: 100px;
  appearance: none;
  color: ${props => (props.active ? blue8 : blue1)};
  background-color: ${props => (props.active ? blue4 : blue7)};
  cursor: pointer;
`;

interface IReduxProps {
  composeBasicRequest: (basicRequest: IBasicRequest) => void;
  ipcCodeList?: string[];
  handleIpcCodeList?: (code?: string[]) => void;
}

interface IProps {
  activeTab: string;
  click: (tab: string) => void;
}

/** 基本檢索 */
const ConuntryTab: React.FC<IProps> = props => {
  const countryTabs = (
    <CountryTabs>
      {countries.map(tab => (
        <CountryTab key={tab} active={props.activeTab === tab} onClick={() => props.click(tab)}>
          {tab}
        </CountryTab>
      ))}
    </CountryTabs>
  );

  return (
    <>
      <Column>檢索範圍</Column>
      {countryTabs}
    </>
  );
};

export default ConuntryTab;
