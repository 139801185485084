import { Auth } from 'src/apps/auth/Auth';

export enum Role {
  PERSONAL = 'PERSONAL',
  COMPANY = 'COMPANY',
  COMPANY_SUB = 'COMPANY_SUB',
}

export const roleChecker = () => {
  if (Auth().getPayload().info.type === 'C') {
    if (Auth().getPayload().info.parentId === null) {
      return Role.COMPANY;
    } else {
      return Role.COMPANY_SUB;
    }
  } else {
    return Role.PERSONAL;
  }
};

export const isCompanyMember = () => {
  return roleChecker() === Role.PERSONAL ? false : true;
};
