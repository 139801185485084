import React from 'react';
import { FaAngleDown, FaAngleUp, FaTimesCircle } from 'react-icons/fa';
import {
  Container,
  Header,
  TaggedHeader,
  Title,
  Panel,
  TagWithTooltip,
  FacetLinkBox,
  FacetLinkName,
  FacetLinkCount,
  NoFilteredResult,
} from 'src/style/AccordionStyle';
import { connect } from 'react-redux';
import { ReduxAppState } from 'src/redux/reducers';
import { toggleBilingualTerm } from 'src/redux/actions/patentSearchAction';
import { numFormatter } from 'src/utils/Formatter';
import { Spinner } from 'src/components/ui/interactive/Spinner';
import SearchBar from './common/SearchBar';
import { IFacetFieldCount } from 'src/types/PatentSearchTypes';
import useAccordion from './common/useAccordion';

interface IReduxMappingProps {
  tag?: string;
  content: Array<IFacetFieldCount>;
  toggleBilingualTerm: (bilingualTerm?: string) => void;
  isLoading: boolean;
}

interface IProps extends IReduxMappingProps {
  title: string;
}

/** 雙語詞彙-摺疊面板 */
const BilingualAccordion: React.FC<IProps> = props => {
  const { title, tag, content, toggleBilingualTerm, isLoading } = props;

  const {
    isActive,
    searchKeyword,
    filteredContent,
    togglePanel,
    handleKeywordChange,
    getHighlightedText,
  } = useAccordion(content);

  const isTagged = tag !== undefined;
  const isSearchbarShow = !isTagged && isActive && content && content.length > 6;

  return content.length > 0 ? (
    <Container>
      {isTagged ? (
        <TaggedHeader>
          <Title>{title}</Title>
          <TagWithTooltip placement="top" overlay={tag}>
            {tag}
          </TagWithTooltip>
          <FaTimesCircle size={20} onClick={() => toggleBilingualTerm(undefined)} />
        </TaggedHeader>
      ) : (
        <Header onClick={() => togglePanel()}>
          <Title>{title}</Title>
          {isActive ? <FaAngleUp size={20} /> : <FaAngleDown size={20} />}
        </Header>
      )}
      {isSearchbarShow && (
        <SearchBar searchKeyword={searchKeyword} onChange={e => handleKeywordChange(e)} />
      )}
      {isLoading ? (
        <Spinner margin="58px auto" />
      ) : (
        !isTagged && (
          <Panel isShow={isActive}>
            {searchKeyword !== '' && filteredContent.length === 0 ? (
              <NoFilteredResult>查無 "{searchKeyword}" 的符合結果</NoFilteredResult>
            ) : (
              filteredContent.map(item => (
                <FacetLinkBox key={item.name} onClick={() => toggleBilingualTerm(item.name)}>
                  <FacetLinkName>{getHighlightedText(item.name, searchKeyword)}</FacetLinkName>
                  <FacetLinkCount>({numFormatter(item.count)})</FacetLinkCount>
                </FacetLinkBox>
              ))
            )}
          </Panel>
        )
      )}
    </Container>
  ) : null;
};

const mapStateToProps = (state: ReduxAppState) => {
  return {
    tag: state.patentSearchReducer.request.bilingualTerm,
    content: state.patentFilterReducer.bilingualTerms.map(item => ({
      name: item.term,
      count: item.count,
    })),
    isLoading: state.patentFilterReducer.isBilingualTermsLoading,
  };
};

const mapDispatchToProps = {
  toggleBilingualTerm,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BilingualAccordion);
