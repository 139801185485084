import { CONDITION_NOT } from 'src/constants/PatentSearchConstants';

/** 將 word 用 () 包起來。 */
export const enclose = (word: string) => {
  return '(' + word + ')';
};

/** 將 word 用 "" 包起來。 */
const doubleQuote = (word: string) => {
  return '"' + word + '"';
};

/** 若 word 中含有空白字元則用 "" 包起來。 */
export const doubleQuoteBlank = (word: string) => {
  return word.search(/ |\t/) !== -1 ? doubleQuote(word) : word;
};

/** 取得日期範圍 */
export const getRangeQuery = (dateType: string, dateStart: Date, dateEnd: Date) => {
  return dateType + '=[' + formatQueryDate(dateStart) + ' TO ' + formatQueryDate(dateEnd) + ']';
};

/** 取得檢索式欄位前綴: 非空字串(所有欄位)的話加上等號，否則回傳空字串。 */
export const getFieldPrefix = (field: string) => {
  return field === '' ? '' : field + '=';
};

export const getFuzzyFieldPostfix = (field: string) => {
  return field === '' ? '' : '@' + field;
};

/** 根據目前檢索式長度(以及運算子是否為NOT)決定是否加入邏輯運算子 */
export const appendCondition = (len: number, condition: string) => {
  return len > 0 || condition === CONDITION_NOT ? condition : '';
};

/** 判斷字串是否有值 */
export const isNotEmpty = (text?: string) => {
  return text && text.trim() !== '';
};

/** 取得solr日期格式: yyyyMMdd */
export const formatQueryDate = (date: Date) => {
  const year = date.getFullYear().toString();
  let month: number | string = date.getMonth() + 1;
  let day: number | string = date.getDate();
  if (month < 10) {
    month = '0' + month;
  }
  if (day < 10) {
    day = '0' + day;
  }
  return year + month + day;
};

/** 將可能造成Regex Error的特殊字元前面加上反斜線跳脫 */
export const escapeInvalidRegex = (str: string) => {
  return str.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
};

/** 將 str 的最後一個 searchValue 以 replacement 取代 */
export const replaceLast = (str: string, searchValue: string, replacement: string) => {
  const pcs = str.split(searchValue);
  const lastPc = pcs.pop();
  return pcs.join(searchValue) + replacement + lastPc;
};
