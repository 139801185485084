import React from 'react';
import styled from 'styled-components';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import * as LoginUtils from 'src/apps/auth/LoginUtils';

const Container = styled.div<{ color?: string }>`
  padding: 8px 16px;
  color: ${props => props.color || '#263237'};
  text-align: left;
  font-size: 14px;
  :hover {
    background-color: rgba(12, 175, 204, 0.2);
  }
`;

interface IProps extends RouteComponentProps {
  name: string;
  path: string;
  color?: string;
  setDropdown: React.Dispatch<React.SetStateAction<boolean>>;
}

const dropdownItem: React.FC<IProps> = props => (
  <Link
    to={props.path}
    onClick={() =>
      props.path === '/'
        ? LoginUtils.logout() && props.setDropdown(false)
        : props.setDropdown(false)
    }
  >
    <Container color={props.color}>{props.name}</Container>
  </Link>
);

export default withRouter(dropdownItem);
