import React from 'react';
import styled from 'styled-components';
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';

import SimpleSideNav from 'src/components/common/nav/side-nav/SimpleSideNav';

import Opendata from './Opendata';
import Bilingual from './Bilingual';
import Patent from './Patent';
import Search from './Search';
import InventPubList from './InventPubList';
import InventPubReport from './InventPubReport';
import Technical from 'src/components/opendata/Technical';

const Container = styled.div`
  margin: 0 auto;
  padding: 16px 140px 0 140px;
  max-width: 1440px;
  display: flex;
  align-items: flex-start;
  align-content: space-between;
  flex-flow: row;
`;
const SideNavStyle = styled.div`
  width: 180px;
  display: inline-flex;
`;
const ContentStyle = styled.div`
  padding: 0 0 0 16px;
  width: calc(100% - 180px);
`;

const opendataLayout: React.FC<RouteComponentProps> = routeProps => {
  const sector = '開放資料';
  const features = [
    { feature: '公報OPEN DATA下載', path: '/opendata' },
    { feature: '公報清單查詢 API', path: '/inventPub' },
    { feature: '公報ZIP檔下載 API', path: '/inventPubReport' },
    { feature: '專利查詢 API', path: '/search' },
    { feature: '專利說明書 API', path: '/patent' },
    { feature: '雙語詞彙 API', path: '/bilingual' },
    { feature: '技術辭庫下載', path: '/technical' },
  ];

  return (
    <Container>
      <SideNavStyle>
        <SimpleSideNav sector={sector} features={features} {...routeProps} />
      </SideNavStyle>
      <ContentStyle>
        <Switch>
          <Route path={routeProps.match.url + features[0].path} component={Opendata} />>
          <Route path={routeProps.match.url + features[1].path} component={InventPubList} />
          <Route path={routeProps.match.url + features[2].path} component={InventPubReport} />
          <Route path={routeProps.match.url + features[3].path} component={Search} />
          <Route path={routeProps.match.url + features[4].path} component={Patent} />
          <Route path={routeProps.match.url + features[5].path} component={Bilingual} />
          <Route path={routeProps.match.url + features[6].path} component={Technical} />
          <Route component={() => <Redirect to={routeProps.match.url + features[0].path} />} />
        </Switch>
      </ContentStyle>
    </Container>
  );
};

export default opendataLayout;
