import { useRef, useEffect } from 'react';
import { isModalOpen } from 'src/utils/ModalUtils';

interface IProps {
  show: boolean;
  setShow: (show: boolean) => void;
}

function useOutSideClick(props: IProps) {
  const node = useRef<HTMLElement>(null);

  useEffect(() => {
    const handleOutSide = (event: Event) => {
      if ((node.current && node.current.contains(event.target as Node)) || isModalOpen()) return;

      props.setShow(false);
    };

    if (props.show) {
      document.addEventListener('mousedown', handleOutSide);
    } else {
      document.removeEventListener('mousedown', handleOutSide);
    }

    return () => document.removeEventListener('mousedown', handleOutSide);
  }, [props]);

  return node;
}

export default useOutSideClick;
