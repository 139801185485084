import { axisBottom, axisLeft } from 'd3';

/** 產生 x 軸格線(因 TypeScript 型別問題, 將功能抽出) */
export const generateXGrid = (scaleX, yAxisLength) =>
  axisBottom(scaleX)
    .tickSize(yAxisLength)
    // 錯誤發生處, tickFormat不接受空字串
    .tickFormat('');

/** 產生 y 軸格線(因 TypeScript 型別問題, 將功能抽出) */
export const generateYGrid = (scaleY, xAxisLength) =>
  axisLeft(scaleY)
    .tickSize(xAxisLength)
    // 錯誤發生處, tickFormat不接受空字串
    .tickFormat('');
