import React, { useEffect, useRef } from 'react';
import _ from 'lodash';
import LoadMore, { LoadMoreStatus } from 'src/components/ui/interactive/LoadMore';

// 預設數值 (若需客製化，可新增選擇性 props 傳入)
/** 觸發讀取之最小距離。 */
const defaultThreshold = 100;
/** 預設 5 頁暫停一次自動載入。 */
const defaultStopCount = 5;

interface IProps {
  className?: string;
  isNextPageLoading: boolean;
  totalCount: number;
  currentPage: number;
  totalPages: number;
  isNextPageError: boolean;
  errorMessage?: string;
  /** 讀取 下一頁 */
  handleNextPageLoad: () => void;
  /** 重新讀取 當前頁面 */
  handleReload: () => void;
  /** 是否偵測瀏覽器 scrollbar，預設為 true  */
  useWindow?: boolean;
  /** 客製化 Div onScroll 事件 */
  customScroll?: (ref: React.RefObject<HTMLDivElement>) => void;
  /** 幾頁暫停一次自動載入，預設 5 頁， -1 則不會暫停。 */
  stopCount?: number;
  /** LoadMore Button Padding */
  loadMorePadding?: string;
  threshold?: number;
}

/** 滾動分頁讀取。 */
const InfiniteScroll: React.FC<IProps> = ({
  children,
  className,
  totalCount,
  isNextPageLoading,
  isNextPageError,
  errorMessage,
  handleNextPageLoad,
  handleReload,
  useWindow = true,
  currentPage,
  totalPages,
  customScroll,
  stopCount = defaultStopCount,
  loadMorePadding,
  threshold = defaultThreshold,
}) => {
  const divRef = useRef<HTMLDivElement>(null);

  const handleScroll = _.debounce(() => {
    const toNextPage = (distToBottom: number) => {
      if (distToBottom <= threshold && getPageLoadingStatus() === LoadMoreStatus.DEFAULT) {
        handleNextPageLoad();
      }
    };

    if (useWindow) {
      toNextPage(
        document.documentElement.offsetHeight -
          (window.innerHeight + document.documentElement.scrollTop),
      );
    } else {
      if (divRef.current) {
        const { scrollTop, clientHeight, scrollHeight } = divRef.current;
        toNextPage(scrollHeight - (scrollTop + clientHeight));
      }
    }
    // wait : 需要確定使用者已經停止行為 X ms以上, 才會執行此debounce function.
    // e.g : 使用者停止滑鼠輪動後ScrollBar X ms後
  }, 100);

  const handleCustomScroll = () => {
    customScroll && customScroll(divRef);
    handleScroll();
  };

  /** 判斷 LoadMoreButton 的顯示狀態 */
  const getPageLoadingStatus = () => {
    if (isNextPageLoading) {
      return LoadMoreStatus.LOADING;
    } else if (isNextPageError) {
      return LoadMoreStatus.ERROR;
    } else if (currentPage >= totalPages) {
      return LoadMoreStatus.END;
    } else if (stopCount > 0 && currentPage % stopCount === 0) {
      return LoadMoreStatus.BUTTON;
    } else {
      return LoadMoreStatus.DEFAULT;
    }
  };

  useEffect(() => {
    if (useWindow) {
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, [useWindow, handleScroll]);

  return (
    <div
      // 使外部 styled-component extend 後具有作用。
      className={className}
      ref={divRef}
      onScroll={!useWindow ? handleCustomScroll : undefined}
    >
      {children}
      {totalCount > 0 && (
        <LoadMore
          status={getPageLoadingStatus()}
          onLoadClick={handleNextPageLoad}
          onReloadClick={handleReload}
          errorMessage={errorMessage}
          padding={loadMorePadding}
        />
      )}
    </div>
  );
};

export default InfiniteScroll;
