import React, { useState } from 'react';
import { FormikProps } from 'formik';
import DatePicker from 'src/components/ui/interactive/DatePicker';
import { Row, Symbol } from 'src/style/PatentSearchStyle';
import { IDropdownProps, ISelectedItem } from 'src/components/common/Dropdown';
import { SearchDropdown } from 'src/components/search/SearchDropdown';
import { IAdvancedSearch, IFormSearch, IBooleanSearch } from 'src/types/form/SearchFormTypes';

interface IProps {
  formikBag: FormikProps<IAdvancedSearch | IFormSearch | IBooleanSearch>;
}

/**
 * 日期範圍
 * @description 對應 Formik 欄位名稱: "dateType"、"dateStart"、"dateEnd"
 */
const DateRange: React.FC<IProps> = props => {
  // 下拉選單預設選取值
  const [selectedItem, setSelectedItem] = useState<ISelectedItem>({
    dateType: { name: '公開/公告日', value: 'ID' },
  });
  // 下拉選單選項
  const dateTypeItems: IDropdownProps['items'] = [
    { name: '公開/公告日', value: 'ID' },
    { name: '申請日', value: 'AD' },
  ];

  const { formikBag } = props;

  return (
    <Row>
      <SearchDropdown
        items={dateTypeItems}
        activeItem={selectedItem.dateType}
        handleOnclick={item => {
          setSelectedItem(prevState => ({
            ...prevState,
            dateType: item,
          }));
          formikBag.setFieldValue('dateType', item.value);
        }}
        width="30%"
      />
      <Symbol>=</Symbol>
      <DatePicker
        width="30%"
        selected={formikBag.values.dateStart}
        onChange={date => formikBag.setFieldValue('dateStart', date)}
      />
      <Symbol>~</Symbol>
      <DatePicker
        width="30%"
        selected={formikBag.values.dateEnd}
        onChange={date => formikBag.setFieldValue('dateEnd', date)}
      />
    </Row>
  );
};

export default DateRange;
