import React, { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import { blue3, iceblue3, ultrawhite } from 'src/style/theme/Color';

import { FaMinus, FaPlus } from 'react-icons/fa';

const SuggestionTag = styled.div<{ isAdded: boolean }>`
  border-radius: 4px;
  margin: 6px 12px 6px 8px;
  overflow: hidden;
  border-style: solid;
  border-color: ${ultrawhite};
  padding: 0px 6px;
  color: ${iceblue3};
  border-width: thin;

  :hover {
    cursor: pointer;
    border-color: ${blue3};
    background-color: ${blue3};
    color: white;
  }

  ${props =>
    props.isAdded &&
    css`
      border-color: ${blue3};
      background-color: ${blue3};
      color: white;
    `}
`;

const AddIcon = styled(FaPlus)`
  top: 7.5px;
  right: 8px;
  margin-left: 8px;
  height: 12px;
`;

const CancelIcon = styled(FaMinus)`
  top: 7.5px;
  right: 8px;
  margin-left: 8px;
  height: 12px;
`;

interface IProps {
  value: string;
  isCleared: boolean;
  inputText: string;
  handleSuggestionAdd: (value: string) => void;
  handleSuggestionRemove: (value: string) => void;
}

/** 單筆查詢結果-圖片模式 */
const Suggestion: React.FC<IProps> = (props: IProps) => {
  const { value, isCleared, inputText, handleSuggestionAdd, handleSuggestionRemove } = props;

  // 確認搜尋框有無該推薦詞
  const checkInputText = useMemo(() => {
    return inputText.split(' ').includes(value);
  }, [inputText, value]);

  let [isAdded, setIsAdded] = useState(checkInputText);
  const toggleStatus = () => {
    isAdded ? setIsAdded(false) : setIsAdded(true);
  };
  useEffect(() => {
    isCleared && setIsAdded(false);
  }, [isCleared]);

  return (
    <SuggestionTag
      isAdded={isAdded}
      onClick={e => {
        toggleStatus();
        isAdded ? handleSuggestionRemove(value) : handleSuggestionAdd(value);
      }}
    >
      {value}
      {isAdded ? <CancelIcon /> : <AddIcon />}
    </SuggestionTag>
  );
};

export default Suggestion;
