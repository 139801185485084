import { AxiosResponse, AxiosError } from 'axios';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { ReduxAppState } from 'src/redux/reducers';
import {
  FETCH_FACETS_START,
  FETCH_FACETS_SUCCESS,
  FETCH_FACETS_ERROR,
  FETCH_IPC_DESCRIPTIONS_START,
  FETCH_IPC_DESCRIPTIONS_SUCCESS,
  FETCH_IPC_DESCRIPTIONS_ERROR,
  FETCH_BILINGUAL_TERMS_START,
  FETCH_BILINGUAL_TERMS_SUCCESS,
  FETCH_BILINGUAL_TERMS_ERROR,
  PatentFilterActionTypes,
} from 'src/redux/types/patentFilterTypes';
import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import { IPatentFacet, IBilingualTermsRequest } from 'src/types/PatentSearchTypes';

// 分組統計資料
export const fetchFacets = (): ThunkAction<void, ReduxAppState, null, Action<string>> => async (
  dispatch,
  getState,
) => {
  dispatch(fetchFacetsStart());

  const state = getState().patentSearchReducer;
  const searchRequest = state.request;

  try {
    const response = await Api().post('patent/search/facet', searchRequest);

    // 當查詢字串造成solr查詢錯誤時，response.data 會回傳空字串
    // 為了避免後續操作陣列產生null pointer，所以替換成空陣列
    (!response.data || response.data === '') && (response.data = []);

    // 以國際分類號進行再檢索時，畫面不顯示故不查詢IPC描述。
    const isFetchIpcDescription = !(
      searchRequest.advanceKeywords &&
      searchRequest.advanceKeywords.some(item => item.keyCode === 'IC')
    );

    if (isFetchIpcDescription) {
      // 取得國際分類號之分組統計物件，並將其IPC代碼組合成List
      const facets: Array<IPatentFacet> = response.data;
      const icFacet = facets && facets.find(item => item.keyCode === 'IC');
      const ipcCodes = icFacet && icFacet.patentFacetFieldCount.map(item => item.name);
      ipcCodes && dispatch(fetchIpcDescriptions(ipcCodes));
    }

    dispatch(fetchFacetsSuccess(response));
  } catch (error) {
    dispatch(fetchFacetsFailure(error));
  }
};

export function fetchFacetsStart(): PatentFilterActionTypes {
  return {
    type: FETCH_FACETS_START,
  };
}

export function fetchFacetsSuccess(response: AxiosResponse): PatentFilterActionTypes {
  return {
    type: FETCH_FACETS_SUCCESS,
    response: response,
  };
}

export function fetchFacetsFailure(error: AxiosError): PatentFilterActionTypes {
  errorHandler(error);
  return {
    type: FETCH_FACETS_ERROR,
    error: error,
  };
}

// IPC 中文敘述
export const fetchIpcDescriptions = (
  ipcCodes: Array<string>,
): ThunkAction<void, ReduxAppState, null, Action<string>> => async dispatch => {
  dispatch(fetchIpcDescriptionsStart());
  try {
    const response = await Api().post('ipc/descriptions', ipcCodes);
    dispatch(fetchIpcDescriptionsSuccess(response));
  } catch (error) {
    dispatch(fetchIpcDescriptionsFailure(error));
  }
};

export function fetchIpcDescriptionsStart(): PatentFilterActionTypes {
  return {
    type: FETCH_IPC_DESCRIPTIONS_START,
  };
}

export function fetchIpcDescriptionsSuccess(response: AxiosResponse): PatentFilterActionTypes {
  return {
    type: FETCH_IPC_DESCRIPTIONS_SUCCESS,
    response: response,
  };
}

export function fetchIpcDescriptionsFailure(error: AxiosError): PatentFilterActionTypes {
  errorHandler(error);
  return {
    type: FETCH_IPC_DESCRIPTIONS_ERROR,
    error: error,
  };
}

// 雙語詞彙
export const fetchBilingualTerms = (
  request: IBilingualTermsRequest,
): ThunkAction<void, ReduxAppState, null, Action<string>> => async dispatch => {
  dispatch(fetchBilingualTermsStart());
  try {
    const response = await Api().post('patent/search/bilingual/facet', request);
    dispatch(fetchBilingualTermsSuccess(response));
  } catch (error) {
    dispatch(fetchBilingualTermsFailure(error));
  }
};

export function fetchBilingualTermsStart(): PatentFilterActionTypes {
  return {
    type: FETCH_BILINGUAL_TERMS_START,
  };
}

export function fetchBilingualTermsSuccess(response: AxiosResponse): PatentFilterActionTypes {
  return {
    type: FETCH_BILINGUAL_TERMS_SUCCESS,
    response: response,
  };
}

export function fetchBilingualTermsFailure(error: AxiosError): PatentFilterActionTypes {
  errorHandler(error);
  return {
    type: FETCH_BILINGUAL_TERMS_ERROR,
    error: error,
  };
}
