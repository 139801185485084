import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { IndexService } from 'src/api/indexApi';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import { IKindCode } from 'src/types/api/IndexApiTypes';

import { numFormatter } from 'src/utils/Formatter';

import MountainPic from 'src/assets/images/mountain-bg.svg';
import { Button } from 'src/style/theme/Common';
import { Spinner } from 'src/components/ui/interactive/Spinner';
import { blue4, blue6, blue1rgba, ultrawhite } from 'src/style/theme/Color';

import { connect } from 'react-redux';
import { ReduxAppState } from 'src/redux/reducers';
import { openCustomModal, closeCustomModal } from 'src/redux/actions/modalAction';
import { CustomModalType } from 'src/types/ModalTypes';
import PatentDataScopeModal from 'src/components/ui/interactive/modal/custom/PatentDataScopeModal';

const api = IndexService();

const MARGIN_TO_VIDEO = '104px';

const Background = styled.div`
  margin: ${MARGIN_TO_VIDEO} 0 0 0;
  width: 100%;
  height: 272px;
  background-image: url(${MountainPic});
  background-size: cover;
`;
const Container = styled.div`
  padding: 0 50px;
`;
const Ancher = styled.div<{ hasError: boolean }>`
  margin: auto;
  padding: 24px 64px;
  width: 90%;
  max-width: 1440px;
  border-radius: 4px;
  background-color: ${blue1rgba(0.8)};
  display: ${({ hasError }) => hasError && 'flex'};
  justify-content: ${({ hasError }) => hasError && 'center'};
`;
const Row = styled.div<{ margin?: string; justify?: string }>`
  margin: ${({ margin }) => margin};
  padding: 0 48px;
  width: 100%;
  display: flex;
  justify-content: ${({ justify }) => (justify ? justify : 'center')};
  align-items: center;
  flex-flow: row wrap;
  color: ${ultrawhite};
  font-size: 40px;
  text-align: center;
  font-weight: bold;
`;
const TotalPatent = styled.section`
  margin: 0 24px;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;

  div {
    color: ${blue6};
    font-size: 20px;
  }
  section {
    margin: 0 0 0 8px;
    color: ${blue4};
    font-size: 16px;
  }
`;
const PatentKindStat = styled.section`
  display: flex;
  align-items: center;

  div {
    color: ${blue6};
    font-size: 20px;
  }
  span {
    margin: 0 0 0 16px;
    color: ${ultrawhite};
    font-size: 24px;
  }
`;
const MoreStat = styled.div`
  color: ${blue4};
  font-size: 16px;
  cursor: pointer;
`;
const ErrorMessage = styled.div`
  margin: auto;
  padding: 36px;
  color: ${ultrawhite};
  text-align: center;
`;
const Discription = styled.div`
  padding: 0 0 16px;
`;

interface IReduxMappingProps {
  isModalOpen: boolean;
  openModal: (customModalType: CustomModalType) => void;
  closeModal: () => void;
}

const PatentStat: React.FC<IReduxMappingProps> = ({ isModalOpen, openModal, closeModal }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalKindAmount, setTotalKindAmount] = useState<IKindCode>();
  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await api.getPatentAmount();
        setTotalAmount(res.total);
        setTotalKindAmount(res.kindTotal);
      } catch (error) {
        setHasError(true);
        errorHandler(error);
      } finally {
        setIsLoading(false);
      }
    };

    isLoading && fetch();
  }, [isLoading]);

  return (
    <Background>
      <Container>
        <Ancher hasError={hasError}>
          {isLoading ? (
            <Spinner width="78px" margin="32px auto" />
          ) : hasError || !totalKindAmount ? (
            <ErrorMessage>
              <Discription>目前統計功能似乎出了點小問題，如有需要，請聯絡系統管理員。</Discription>
              <a href="mailto:tipoeservice@tipo.gov.tw">
                <Button type="button" template="primary">
                  聯絡系統管理員
                </Button>
              </a>
            </ErrorMessage>
          ) : (
            <>
              <Row>
                <TotalPatent>
                  <div>所有專利</div>
                  <section>臺、日、韓、美、中、歐</section>
                </TotalPatent>
              </Row>
              <Row>{numFormatter(totalAmount)}</Row>
              <Row margin="16px 0 0 0" justify="space-between">
                <PatentKindStat>
                  <div>發明公開</div>
                  <span>{totalKindAmount ? numFormatter(totalKindAmount.A) : 0}</span>
                </PatentKindStat>
                <PatentKindStat>
                  <div>發明專利</div>
                  <span>{totalKindAmount ? numFormatter(totalKindAmount.B) : 0}</span>
                </PatentKindStat>
                <PatentKindStat>
                  <div>新型專利</div>
                  <span>{totalKindAmount ? numFormatter(totalKindAmount.U) : 0}</span>
                </PatentKindStat>
                <PatentKindStat>
                  <div>設計專利</div>
                  <span>{totalKindAmount ? numFormatter(totalKindAmount.D) : 0}</span>
                </PatentKindStat>
              </Row>
              <Row margin="16px 0 0 0">
                <MoreStat onClick={() => openModal(CustomModalType.PATENT_DATA_SCOPE)}>
                  專利資料範圍
                </MoreStat>
              </Row>
              {isModalOpen && <PatentDataScopeModal onClose={() => closeModal()} />}
            </>
          )}
        </Ancher>
      </Container>
    </Background>
  );
};

const mapStateToProps = (state: ReduxAppState) => {
  return {
    isModalOpen: state.modalReducer.customModalType === CustomModalType.PATENT_DATA_SCOPE,
  };
};

const mapDispatchToProps = {
  openModal: openCustomModal,
  closeModal: closeCustomModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PatentStat);
