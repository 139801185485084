import React, { useState } from 'react';
import styled from 'styled-components';
import withShortcut from 'src/components/search/withShortcut/withShortcut';
import { Formik, Form, FormikProps, FormikActions, FieldArray } from 'formik';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { Row, Symbol, StyledField } from 'src/style/PatentSearchStyle';
import { IBooleanSearch } from 'src/types/form/SearchFormTypes';
import {
  getRangeQuery,
  getFieldPrefix,
  appendCondition,
  isNotEmpty,
  enclose,
  getFuzzyFieldPostfix,
  doubleQuoteBlank,
} from 'src/utils/TextUtils';
import {
  CONDITION_AND,
  logicItems,
  countries,
  classificationCode,
  excludeItems,
} from 'src/constants/PatentSearchConstants';

import Content from 'src/components/ui/content/Content';
import { Button } from 'src/style/theme/Common';
import { IDropdownProps, IDropdownItem } from 'src/components/common/Dropdown';
import { SearchDropdown } from 'src/components/search/SearchDropdown';
import Tooltip from 'src/components/ui/interactive/Tooltip';
import { connect } from 'react-redux';
import { composeBasicRequest } from 'src/redux/actions/patentSearchAction';
import { IBasicRequest } from 'src/types/PatentSearchTypes';
import { alertMessage } from 'src/utils/ModalUtils';
import { SystemMessage } from 'src/apps/applicationMessages';
import { blue3, blue7 } from 'src/style/theme/Color';
import DateRange from 'src/components/search/partialForm/DateRange';
import ClassificationCode from 'src/components/search/partialForm/ClassificationCode';
import CountryTabs from 'src/components/search/CountryTabs';

const BooleanForm = styled(Form)`
  /* for 增加/減少欄位 Icon */
  padding-right: 64px;
`;

const FieldArrayContainer = styled.div`
  position: relative;
`;

const ButtonTooltip = styled(Tooltip)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  margin-right: 4px;
  background-color: ${blue7};
  border-radius: 50%;
  :hover {
    cursor: pointer;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  left: 101%;
  bottom: 0;
`;

interface ISelectedItem {
  basicField: IDropdownItem;
  queryList: Array<{
    condition: IDropdownItem;
    field: IDropdownItem;
  }>;
}

interface IReduxProps {
  composeBasicRequest: (basicRequest: IBasicRequest) => void;
  ipcCodeList?: string[];
  handleIpcCodeList?: (code?: string[]) => void;
}

interface IProps extends IReduxProps, RouteComponentProps {}

const getClassificationQuery = (values: IBooleanSearch, code: string, queryLength: number) => {
  let result = '';
  if (isNotEmpty(values[code + 'Text'] && values[code + 'Text'].trim())) {
    result =
      appendCondition(queryLength, values[code + 'Condition']) +
      '(' +
      getFieldPrefix(values[code + 'Field']) +
      values[code + 'Text'].trim() +
      ')';
  }
  return result;
};
/** 布林檢索 */
const BooleanSearch: React.FC<IProps> = (props: IProps) => {
  const defaultQueryListSelectedItem = {
    condition: { name: 'AND', value: CONDITION_AND },
    field: { name: '所有欄位', value: '' },
  };

  // 下拉選單預設選取值
  const [selectedItem, setSelectedItem] = useState<ISelectedItem>({
    basicField: { name: '所有欄位', value: '' },
    queryList: new Array(10).fill(defaultQueryListSelectedItem),
  });

  /** 更新 queryList 陣列中特定索引值的下拉選單被選值 */
  const updateQueryListSelectedItem = (
    idx: number,
    item:
      | { condition: IDropdownItem }
      | { field: IDropdownItem }
      | { condition: IDropdownItem; field: IDropdownItem },
  ) => {
    // TODO: 確認(Immutabel Update)做法
    setSelectedItem(prevState => {
      const newQueryList = prevState.queryList.map((listItem, listIdx) =>
        idx === listIdx ? { ...listItem, ...item } : listItem,
      );
      return {
        ...prevState,
        queryList: newQueryList,
      };
    });
  };

  const handleIpcText = (ipcText: string) => {
    ipcText = ipcText.replace('OR ', 'OR IC=');
    ipcText = ipcText.replace('AND ', 'AND IC=');
    ipcText = ipcText.replace('NOT ', 'NOT IC=');
    ipcText = ipcText.replace(/,/g, ' OR IC=');
    return ipcText;
  };

  const handleIpcValue = (values: IBooleanSearch) => {
    if (props.ipcCodeList && isNotEmpty(props.ipcCodeList.toString())) {
      values.ipcText = props.ipcCodeList.toString().trim();
    }
    values.ipcText = handleIpcText(values.ipcText);
  };

  const handleSubmit = (values: IBooleanSearch, actions: FormikActions<IBooleanSearch>) => {
    actions.setSubmitting(false);

    // 判斷文字欄位有無填寫
    const excludeFields = [
      'basicField',
      'dateType',
      'dateStart',
      'dateEnd',
      'ipcCondition',
      'cpcCondition',
      'locCondition',
      'queryList',
    ];
    const filteredTextFields = Object.keys(values).filter(
      key => !excludeFields.includes(key) && isNotEmpty(values[key]),
    );

    // 過濾FieldArray (取queryList有填寫的欄位)
    const filteredQueryList = values.queryList.filter(item => isNotEmpty(item.text));

    // 日期區間是否填寫完整
    const dateStart = values.dateStart;
    const dateEnd = values.dateEnd;
    const isDateRangeValid = dateStart && dateEnd;

    // 串接request檢索字串
    if (filteredQueryList.length === 0 && filteredTextFields.length === 0 && !isDateRangeValid) {
      alertMessage(SystemMessage.SEARCH_CONDITION_INCOMPLETE);
    } else {
      let submitQuery = '';

      // 第一列檢索
      if (isNotEmpty(values.basicText)) {
        const text = enclose(
          excludeItems.includes(values.basicField)
            ? values.basicText.trim()
            : doubleQuoteBlank(values.basicText.trim()),
        );
        submitQuery +=
          appendCondition(submitQuery.length, CONDITION_AND) +
          text +
          getFuzzyFieldPostfix(values.basicField);
      }
      // 日期範圍
      // TODO: check typescript 無法使用 isDateRangeValid
      if (dateStart && dateEnd) {
        submitQuery +=
          appendCondition(submitQuery.length, CONDITION_AND) +
          getRangeQuery(values.dateType, dateStart, dateEnd);
      }
      // IPC, CPC, LOC
      classificationCode.forEach(
        code => (submitQuery += getClassificationQuery(values, code, submitQuery.length)),
      );

      // 動態增減的欄位

      filteredQueryList.forEach(item => {
        const text = enclose(
          excludeItems.includes(item.field) ? item.text.trim() : doubleQuoteBlank(item.text.trim()),
        );
        submitQuery +=
          appendCondition(submitQuery.length, item.condition) +
          text +
          getFuzzyFieldPostfix(item.field);
      });

      // 送出request並跳到搜尋結果頁
      props.composeBasicRequest({
        queryString: submitQuery,
        advanceKeywords: [{ keyCode: 'PO', keyword: activeTab }],
      });
      props.history.push('/result');
    }
  };

  const patentFacetFields: IDropdownProps['items'] = [
    { value: '', name: '所有欄位' },
    { value: 'PN', name: '公開/公告號' },
    { value: 'AN', name: '申請號' },
    { value: 'FT', name: 'F-Term' },
    { value: 'IR', name: 'D-Term' },
    { value: 'PA', name: '申請人' },
    { value: 'PA,_L', name: '申請人(開頭)' },
    { value: 'IN', name: '發明人' },
    { value: 'IN,_L', name: '發明人(開頭)' },
    { value: 'LX', name: '代理人' },
    { value: 'LX,_L', name: '代理人(開頭)' },
    { value: 'PB', name: '優先權號' },
    { value: 'CI,CE', name: '引用專利及非專利' },
    { value: 'TI', name: '專利名稱' },
    { value: 'AB', name: '摘要' },
    { value: 'CL', name: '申請範圍' },
    { value: 'DE', name: '專利說明' },

    //  { value: 'UC', name: 'USPC' },
    //  { value: 'FI', name: 'FI' },
    //  { value: 'EX', name: '審查委員' },
  ];

  const defaultQueryListItemValue = {
    condition: CONDITION_AND,
    text: '',
    field: '',
  };

  const formInitialValues = {
    basicText: '',
    basicField: '',
    queryList: new Array(3).fill(defaultQueryListItemValue),
    dateType: 'ID',
    dateStart: null,
    dateEnd: null,
    ipcCondition: CONDITION_AND,
    ipcField: 'IC',
    ipcText: '',
    cpcField: 'CS',
    cpcCondition: CONDITION_AND,
    cpcText: '',
    locField: 'IQ',
    locCondition: CONDITION_AND,
    locText: '',
  };

  const [activeTab, setActiveTab] = useState(countries[0]);

  return (
    <Content sector="專利檢索" feature="布林檢索">
      <>
        <CountryTabs activeTab={activeTab} click={tab => setActiveTab(tab)} />
        <Formik
          initialValues={formInitialValues}
          onSubmit={(values, actions) => {
            handleIpcValue(values);
            handleSubmit(values, actions);
          }}
          render={(formikBag: FormikProps<IBooleanSearch>) => {
            return (
              <BooleanForm>
                <Row>
                  <StyledField width="72%" type="text" name="basicText" />
                  <Symbol>@</Symbol>
                  <SearchDropdown
                    items={patentFacetFields}
                    activeItem={selectedItem.basicField}
                    handleOnclick={item => {
                      setSelectedItem(prevState => ({
                        ...prevState,
                        basicField: item,
                      }));
                      formikBag.setFieldValue('basicField', item.value);
                    }}
                    width="23%"
                  />
                </Row>
                <FieldArray
                  name="queryList"
                  render={arrayHelpers => (
                    <FieldArrayContainer>
                      {formikBag.values.queryList.map((obj, idx) => (
                        <Row key={idx}>
                          <SearchDropdown
                            items={logicItems}
                            activeItem={selectedItem.queryList[idx].condition}
                            handleOnclick={item => {
                              updateQueryListSelectedItem(idx, { condition: item });
                              formikBag.setFieldValue(`queryList[${idx}].condition`, item.value);
                            }}
                            width="18%"
                          />
                          <Symbol />
                          <StyledField type="text" name={`queryList[${idx}].text`} width="49%" />
                          <Symbol>@</Symbol>
                          <SearchDropdown
                            items={patentFacetFields}
                            activeItem={selectedItem.queryList[idx].field}
                            handleOnclick={item => {
                              updateQueryListSelectedItem(idx, { field: item });
                              formikBag.setFieldValue(`queryList[${idx}].field`, item.value);
                            }}
                            width="23%"
                          />
                        </Row>
                      ))}

                      <ButtonsContainer>
                        {formikBag.values.queryList.length < 10 && (
                          <ButtonTooltip
                            overlay="增加欄位"
                            placement="top"
                            onClick={() => arrayHelpers.push(defaultQueryListItemValue)}
                          >
                            <FaPlus size={16} color={blue3} />
                          </ButtonTooltip>
                        )}
                        {formikBag.values.queryList.length > 3 && (
                          <ButtonTooltip
                            overlay="減少欄位"
                            placement="top"
                            onClick={() => {
                              const removedIdx = formikBag.values.queryList.length - 1;
                              arrayHelpers.remove(removedIdx);
                              // 重設被刪除列 selected item，避免再按"增加欄位"後狀態殘留。
                              updateQueryListSelectedItem(removedIdx, defaultQueryListSelectedItem);
                            }}
                          >
                            <FaMinus size={16} color={blue3} />
                          </ButtonTooltip>
                        )}
                      </ButtonsContainer>
                    </FieldArrayContainer>
                  )}
                />
                <ClassificationCode
                  formikBag={formikBag}
                  ipcCodeList={props.ipcCodeList}
                  handleIpcCodeList={props.handleIpcCodeList}
                />
                <DateRange formikBag={formikBag} />
                <Row justifyContent="flex-start">
                  <Button type="submit" template="primary">
                    查詢
                  </Button>
                  <Button type="reset">清除</Button>
                </Row>
              </BooleanForm>
            );
          }}
        />
      </>
    </Content>
  );
};

const mapDispatchToProps = {
  composeBasicRequest,
};

export default withShortcut(
  withRouter(
    connect(
      null,
      mapDispatchToProps,
    )(BooleanSearch),
  ),
);
