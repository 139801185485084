import React from 'react';

import { INotifyItem } from 'src/types/api/MemberApiTypes';
import Checkbox from 'src/components/ui/interactive/checkbox/SubscribeCheckbox';
import {
  Container,
  Subject,
  Description,
  Inform,
  SubContainer,
  SubItem,
} from 'src/style/MemberNotificationStyle';

interface IProps {
  data: Array<INotifyItem> | undefined;
  subject: string;
  description: string;
  type: string;
  click: (idx: number, type: string) => void;
}

const Items: React.FC<IProps> = props => {
  const content = () => {
    if (!props.data || props.data.length === 0) {
      return (
        <SubItem>
          <Subject inactive>目前{props.subject}無可通知項目</Subject>
        </SubItem>
      );
    } else {
      return props.data.map((item, idx) => (
        <SubItem key={item.id} child={item.parentId !== undefined && item.parentId !== null}>
          <Subject>{item.name ? item.name : item.keyword}</Subject>
          <Inform>
            <Checkbox
              type={props.type}
              idx={idx}
              checked={item.notification}
              change={props.click}
            />
          </Inform>
        </SubItem>
      ));
    }
  };

  return (
    <>
      <Container>
        <Subject>{props.subject}</Subject>
        <Description>{props.description}</Description>
      </Container>
      <SubContainer>{content()}</SubContainer>
    </>
  );
};

export default Items;
