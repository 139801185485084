import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FaCaretDown } from 'react-icons/fa';

import { IIndustrialClassType } from 'src/types/IndustryTypes';

import useOutSideClick from 'src/hooks/useOutSideClick';
import Checkbox from 'src/components/ui/interactive/checkbox/Checkbox';
import { Button } from 'src/style/theme/Common';
import {
  ToolbarPaneField,
  ToolbarPaneScrollDiv,
  ToolbarPaneDescription,
} from 'src/style/PatentContentStyle';

import { connect } from 'react-redux';
import { ReduxAppState } from 'src/redux/reducers';

const DropdownContainer = styled.div`
  margin-left: 16px;
  position: relative;
  display: inline-block;
  cursor: pointer;
  z-index: 1;
`;
const DropdownSelectedItem = styled.div<{ active: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  padding: 3px 8px;
  color: #077790;
  white-space: nowrap;
  background-color: ${props =>
    props.active ? 'rgba(245, 253, 254, 0.2)' : 'rgba(245, 253, 254, 0)'};
  &:hover {
    background-color: rgba(245, 253, 254, 0.2);
  }
`;
const DropdownSelectedItemName = styled.div`
  font-size: 18px;
  font-weight: bold;
  width: calc(100% - 20px);
  overflow: hidden;
  text-align: left;
`;
const DropdownItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const DropdownItemBlock = styled.div`
  position: absolute;
  top: 40px;
  left: 0px;
  width: 304px;
  border-radius: 4px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  padding: 16px 32px;
  cursor: auto;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: #263237;
`;

const Clean = styled.div`
  color: #077790;
  cursor: pointer;
`;

const Footer = styled.div`
  text-align: center;
`;

interface IReduxProps {
  industrialClassList: Array<IIndustrialClassType>;
}

interface IProps extends IReduxProps {
  industrialClassIds: Array<number>;
  onSubmit: (ids: Array<number>) => void;
}

// 最多可選取 5 個大類
const limit = 5;

const EditTrends: React.FC<IProps> = ({ industrialClassList, industrialClassIds, onSubmit }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedIds, setSelectedIds] = useState<Array<number>>([...industrialClassIds]);

  const node = useOutSideClick({
    show: isOpen,
    setShow: setIsOpen,
  });
  useEffect(() => {
    if (selectedIds.length === 0 && !isOpen) {
      setSelectedIds([...industrialClassIds]);
    }
  }, [industrialClassIds, selectedIds, isOpen]);

  return (
    <DropdownContainer>
      <DropdownSelectedItem active={isOpen} onClick={() => setIsOpen(!isOpen)}>
        <DropdownSelectedItemName>行業別</DropdownSelectedItemName>
        <FaCaretDown size={20} />
      </DropdownSelectedItem>
      {isOpen && (
        <DropdownItemBlock ref={node as any}>
          <DropdownItemHeader>
            <Title>{'行業別 ' + selectedIds.length + '/' + limit}</Title>
            <Clean
              onClick={() => {
                selectedIds.splice(0, selectedIds.length);
                setSelectedIds([...selectedIds]);
              }}
            >
              清除
            </Clean>
          </DropdownItemHeader>
          <ToolbarPaneField>
            <ToolbarPaneScrollDiv maxHeight="300px">
              {!industrialClassIds ? (
                <ToolbarPaneDescription>行業列表讀取中...</ToolbarPaneDescription>
              ) : (
                industrialClassList.map(section => (
                  <Checkbox
                    name={section.description}
                    checked={selectedIds.includes(section.id)}
                    key={section.id}
                  >
                    <input
                      id={section.id.toString()}
                      type="checkbox"
                      name={section.description}
                      checked={selectedIds.includes(section.id)}
                      onChange={event => {
                        const thisId = parseInt(event.target.id) || 0;
                        const index = selectedIds.findIndex(item => item === thisId);

                        if (index === -1 && selectedIds.length < limit) {
                          setSelectedIds([...selectedIds, thisId]);
                        } else if (index !== -1) {
                          selectedIds.splice(index, 1);
                          setSelectedIds([...selectedIds]);
                        }
                      }}
                    />
                  </Checkbox>
                ))
              )}
            </ToolbarPaneScrollDiv>
          </ToolbarPaneField>
          <Footer>
            <Button
              template="primary"
              onClick={() => {
                onSubmit(selectedIds);
                setIsOpen(false);
              }}
            >
              確定
            </Button>
            <Button onClick={() => setSelectedIds(industrialClassIds)}>取消</Button>
          </Footer>
        </DropdownItemBlock>
      )}
    </DropdownContainer>
  );
};

const mapStateToProps = (state: ReduxAppState) => {
  return {
    industrialClassList: state.industrialClassReducer.industrialClassList,
  };
};

export default connect(
  mapStateToProps,
  null,
)(EditTrends);
