import React from 'react';
import styled from 'styled-components';

import { IGetFilm } from 'src/types/api/FilmApiTypes';

const Container = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  :hover {
    background-color: #f5fdfe;
  }
`;
const TopContainer = styled.div`
  padding: 8px 8px 8px 8px;
  width: 100%;
  img {
    margin: 8px 0;
    width: 100%;
    cursor: pointer;
  }
`;
const BotContainer = styled.div`
  padding: 0px 8px 8px 8px;
`;

const Title = styled.a`
  padding: 0 0 8px 0;
  display: block;
  color: #077790;
  font-size: 18px;
  cursor: pointer;
  height: 80px;
  max-height: 80px;
`;
const Text = styled.div`
  color: #666666;
  padding: 0 0 4px 0;
`;
const Channel = styled.span`
  cursor: pointer;
`;

export const handleArticleAncherOnClick = async (
  e: React.MouseEvent<HTMLElement, MouseEvent>,
  url: string,
) => {
  e.preventDefault();
  try {
    window.open(url);
  } catch (error) {
    console.error(error);
  }
};

interface IProps {
  columnData: IGetFilm;
}

//影片專區Grid模式
const VideoGridItem = ({ columnData }: IProps) => (
  <Container>
    {
      <>
        <TopContainer>
          {columnData && columnData.pic && (
            <img
              src={columnData.pic}
              alt="no pic"
              onClick={e => handleArticleAncherOnClick(e, columnData.url)}
            />
          )}
        </TopContainer>
        <BotContainer>
          <Title onClick={e => handleArticleAncherOnClick(e, columnData.url)}>
            {columnData.title}
          </Title>
          <Text>
            <Channel onClick={e => handleArticleAncherOnClick(e, columnData.channelUrl)}>
              {columnData.channelName}
            </Channel>
            <span>&nbsp;&nbsp;|&nbsp;&nbsp;{columnData.createTime}</span>
          </Text>
        </BotContainer>
      </>
    }
  </Container>
);

export default VideoGridItem;
