import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import { ITrendPatent } from 'src/types/api/IndexApiTypes';
import { IIndustrialRankingApiParamType } from 'src/types/IndustryTypes';

import { numFormatter, dateFormatter } from 'src/utils/Formatter';
import { getStatTabClassName } from 'src/components/index/tabs/getGaClassName';

import { Button } from 'src/style/theme/Common';
import { Spinner } from 'src/components/ui/interactive/Spinner';
import { Table, HRow, HOne, HTwo, HThree, TRow } from 'src/style/IndexTabStyle';
import { blue8, iceblue6, iceblue8, ultrawhite, fontblack1 } from 'src/style/theme/Color';

const ACTIVE_BG_COLOR = blue8;

const Container = styled.div`
  min-height: 400px;
  padding: 100px;
`;
const HCategory = styled(HOne)`
  width: 136px;
`;
const HTitle = styled(HTwo)``;
const HDate = styled(HThree)`
  width: 120px;
`;
const Item = styled(TRow)<{ active: boolean }>`
  height: 58px;
  border-bottom: 1px solid ${iceblue8};
  background-color: ${props => (props.active ? ACTIVE_BG_COLOR : ultrawhite)};
  cursor: pointer;
`;
const Views = styled(HCategory)`
  color: ${fontblack1};
`;
const Title = styled(HTwo)``;
const MultiLineEllipsisText = styled.div<{ active: boolean }>`
  margin: 0 -1rem 0 0;
  padding: 0 1rem 0 0;
  width: calc(100% - 1rem);
  max-height: 2.4rem;
  line-height: 1.2rem;
  overflow: hidden;
  position: relative;
  word-break: break-all;
  :before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
  }
  :after {
    content: '';
    position: absolute;
    right: 0;
    width: 1rem;
    height: 1rem;
    margin-top: 0.2rem;
    background: ${props => (props.active ? ACTIVE_BG_COLOR : ultrawhite)};
  }
`;
const Date = styled(HDate)`
  color: ${iceblue6};
`;

const ErrorContent = styled.div`
  padding: 16px 32px;
  min-height: 403px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const MsgAncher = styled.div`
  padding: 0 0 56px;
  text-align: center;
`;
const Message = styled.div`
  padding: 0 0 32px;
`;

interface IProps extends RouteComponentProps {
  ajaxUrl: string;
  countsColumnName: string;
  apiParams: IIndustrialRankingApiParamType;
}

function RankingPanel({ ajaxUrl, countsColumnName, apiParams, history }: IProps) {
  const [hover, setHover] = useState(0);

  // Fetch contents
  const [loading, setLoading] = useState(true);
  const [patents, setPatents] = useState<Array<ITrendPatent>>([]);
  const [noData, setNoData] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await Api().post(ajaxUrl, apiParams);
        if (res.data && res.data !== '' && res.data.length > 0) {
          setNoData(false);
          setPatents(res.data);
        } else {
          setNoData(true);
        }
      } catch (error) {
        errorHandler(error);
      } finally {
        setLoading(false);
      }
    };

    // 還原設定
    setLoading(true);
    setPatents([]);
    // setWords([]);

    fetchData();
  }, [ajaxUrl, apiParams]);

  /* UI-Stack Area Start */
  const dataContent = (
    <Table>
      <HRow>
        <HCategory>{countsColumnName}</HCategory>
        <HTitle>專利名稱</HTitle>
        <HDate>公開/公告日</HDate>
      </HRow>
      {patents &&
        patents.map((patent, idx) => {
          const activeBool = idx === hover;
          return (
            <Item
              key={patent.id}
              active={activeBool}
              onMouseOver={() => setHover(patents.indexOf(patent))}
              onClick={() => history.push(`/patent/${patent.id}/view`)}
              className={getStatTabClassName(ajaxUrl)}
            >
              <Views>{numFormatter(patent.triggerTimes)}</Views>
              <Title>
                <MultiLineEllipsisText active={activeBool} className="title">
                  {patent.bibliographic.inventionTitle.title.join(' | ')}
                </MultiLineEllipsisText>
              </Title>
              <Date>
                {patent.bibliographic.publicationReference &&
                patent.bibliographic.publicationReference.documentId &&
                patent.bibliographic.publicationReference.documentId.date
                  ? dateFormatter(patent.bibliographic.publicationReference.documentId.date)
                  : '無日期資訊'}
              </Date>
            </Item>
          );
        })}
    </Table>
  );
  const noDataContent = (
    <ErrorContent>
      <MsgAncher>
        <Message>
          因排行資料數量不足，故無資料顯示。
          <br />
          您可以嘗試選擇其他行業別或時間區間觀看更多統計資料，如有其他問題歡迎聯繫我們。
        </Message>
        <a href="mailto:tipoeservice@tipo.gov.tw">
          <Button type="button" template="primary-light">
            聯絡系統管理員
          </Button>
        </a>
      </MsgAncher>
    </ErrorContent>
  );
  const errorContent = (
    <ErrorContent>
      <MsgAncher>
        <Message>目前排行服務似乎出了點小問題，如有需要，請聯絡系統管理員。</Message>
        <a href="mailto:tipoeservice@tipo.gov.tw">
          <Button type="button" template="primary">
            聯絡系統管理員
          </Button>
        </a>
      </MsgAncher>
    </ErrorContent>
  );

  const listUiStack = () => {
    if (loading) {
      return (
        <Container>
          <Spinner width="160px" margin="auto" />
        </Container>
      );
    } else if (patents.length > 0) {
      return dataContent;
    } else if (noData) {
      return noDataContent;
    } else {
      return errorContent;
    }
  };

  return listUiStack();
}

export default withRouter(RankingPanel);
