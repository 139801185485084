import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';

import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import { IGetRssLatest, IMainTabSrc } from 'src/types/api/IndexApiTypes';

import INSPECT_BG from 'src/assets/images/inspect-bg.png';
import NewsItems from './NewsItems';
import NewsInspect from './NewsInspect';
import ExceptionGuestPanel from './ExceptionGuestPanel';
import { getMainTabGaClassName } from 'src/components/index/tabs/getGaClassName';
import { ultrawhite } from 'src/style/theme/Color';

// Layout
const Container = styled.div`
  width: 100%;
  height: 408px;
  display: flex;
`;

const LeftContainer = styled.div`
  width: 65%;
  display: flex;
`;
const RightLoadingStyle = styled.div`
  height: 348px;
  background-image: url(${INSPECT_BG});
  background-size: cover;
`;
const RightContainer = styled(RightLoadingStyle)`
  margin: -20px -8px -8px 0;
  width: 35%;
  height: 436px;
  border-radius: 4px;
  color: ${ultrawhite};
  box-shadow: rgba(38, 50, 55, 0.3) -4px 4px 20px 0px;
`;

const NEWS_HEADER = ['來源', '標題', '發布時間'];

interface IProps {
  src: IMainTabSrc;
}

function NewsPanel({ src }: IProps) {
  const [hover, setHover] = useState(0);
  const [news, setNews] = useState<Array<IGetRssLatest>>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [noData, setNoData] = useState(false);
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const fetchData = async () => {
      try {
        const res = await Api().get(src.apiUrl, {
          params: {
            pageNo: 1,
            paginationSize: 6,
          },
          cancelToken: source.token,
        });

        res.data.data.length > 0 ? setNews(res.data.data) : setNoData(true);
      } catch (error) {
        setHasError(true);
        errorHandler(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (!noData && !hasError && isLoading) {
      fetchData();
    }

    return () => source.cancel();
  }, [src, isLoading, hasError, noData]);

  return (
    <Container>
      {news.length > 0 ? (
        <>
          <LeftContainer>
            <NewsItems
              header={NEWS_HEADER}
              news={news}
              hover={hover}
              setHover={setHover}
              gaClass={getMainTabGaClassName(src.tab)}
              readMoreUrl={src.readMoreUrl}
            />
          </LeftContainer>
          <RightContainer>
            <NewsInspect news={news[hover]} gaClass={getMainTabGaClassName(src.tab)} />
          </RightContainer>
        </>
      ) : (
        <ExceptionGuestPanel
          tab={src.tab}
          header={NEWS_HEADER}
          error={hasError}
          noData={noData}
          isLoading={isLoading}
        />
      )}
    </Container>
  );
}

export default NewsPanel;
