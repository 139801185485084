import { AxiosResponse, AxiosError } from 'axios';
import { IPaginatedData } from 'src/types/PaginatedDataTypes';
import { IGetPatentTypes } from 'src/types/IGetPatentTypes';

export const FETCH_PATENT_RESULT_START = 'FETCH_PATENT_RESULT_START';
export const FETCH_PATENT_RESULT_SUCCESS = 'FETCH_PATENT_RESULT_SUCCESS';
export const FETCH_PATENT_RESULT_ERROR = 'FETCH_PATENT_RESULT_ERROR';
export const FETCH_PATENT_NEXT_PAGE_RESULT_ERROR = 'FETCH_PATENT_NEXT_PAGE_RESULT_ERROR';

export interface FetchPatentResultState {
  isLoading: boolean;
  paginatedData: IPaginatedData<IGetPatentTypes>;
  nextPageErrMsg?: string;
}

interface FetchPatentResultStartAction {
  type: typeof FETCH_PATENT_RESULT_START;
}

interface FetchPatentResultSuccessAction {
  type: typeof FETCH_PATENT_RESULT_SUCCESS;
  response: AxiosResponse;
}

interface FetchPatentResultErrorAction {
  type: typeof FETCH_PATENT_RESULT_ERROR;
  error: AxiosError;
}

interface FetchPatentNextPageResultErrorAction {
  type: typeof FETCH_PATENT_NEXT_PAGE_RESULT_ERROR;
  nextPageErrMsg?: string;
}

export type FetchPatentResultActionTypes =
  | FetchPatentResultStartAction
  | FetchPatentResultSuccessAction
  | FetchPatentResultErrorAction
  | FetchPatentNextPageResultErrorAction;
