import React from 'react';
import { Container, Message } from 'src/style/AccordionStyle';

interface IProps {
  message?: string;
}

/** 查無結果 */
const NoResult = ({ message }: IProps) => (
  <Container>
    <Message>{message}</Message>
  </Container>
);

export default NoResult;
