import React, { useEffect, useState } from 'react';
import { FaInfoCircle } from 'react-icons/fa';

import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';

import LineChart from 'src/components/trend/industrialTrends/chart/LineChart';
import { Dropdown } from 'src/components/common/Dropdown';
import Tooltip from 'src/components/trend/industrialTrends/chart/Tooltip';
import {
  IIndustrialTrendApiParamType,
  IIndustrialTrendType,
  ILineChartType,
} from 'src/types/IndustryTypes';
import { numFormatter } from 'src/utils/Formatter';
import { getCountryName } from 'src/utils/PatentUtils';
import {
  getTrendsTitleKindText,
  getTrendsTitleClassText,
  getTrendsTitlePartyText,
  getStatStartYear,
  getStatEndYear,
  getStatRangeText,
  convertToLineChartData,
} from 'src/components/trend/TrendsHelper';

import { Spinner } from 'src/components/ui/interactive/Spinner';
import {
  BlockTitle,
  Flexbox,
  StatLeftContainer,
  PartyRankItem,
  PartyRankName,
  PartyRankCount,
  GraphTitle,
  RightGraphTitle,
  LineChartToggler,
  ArrowLeft,
  ArrowRight,
  StatRightContainer,
  LineChartLoader,
  NoResult,
  customStyleSelectedItemStyle,
  ToolTipTitle,
  ToolTipContent,
} from 'src/style/IndustrialTrendStyle';

interface IProps {
  apiParams: IIndustrialTrendApiParamType;
  statTimeRange: Array<string>;
}

// 國別下拉選單
const countryDropdown = [
  { name: getCountryName('TW'), value: 'TW' },
  { name: getCountryName('US'), value: 'US' },
  { name: getCountryName('EP'), value: 'EP' },
  { name: getCountryName('JP'), value: 'JP' },
  { name: getCountryName('CN'), value: 'CN' },
  { name: getCountryName('KR'), value: 'KR' },
];

const StatApplicant: React.FC<IProps> = ({ apiParams, statTimeRange }) => {
  const [activeItem, setActiveItem] = useState('');
  const [selectedCountry, setSelectedCountry] = useState(countryDropdown[0]);
  const [apiData, setApiData] = useState<Array<IIndustrialTrendType>>([]);
  const [lineChartData, setLineChartData] = useState<Array<ILineChartType>>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [tooltipPosition, setTooltipPosition] = useState<{ top: number; left: number } | undefined>(
    undefined,
  );
  const minYear = getStatStartYear(statTimeRange);
  const maxYear = getStatEndYear(statTimeRange);
  const [selectedYear, setSelectedYear] = useState(maxYear);

  useEffect(() => {
    // 行業別/專利類別/國別改變時, 先清空所有資料
    setActiveItem('');
    setSelectedYear(maxYear);
    setApiData([]);
    setLineChartData([]);
    setIsLoading(true);

    const fetchData = async () => {
      try {
        const apiRes = await Api().post('trend/industry/party', {
          industrialClassIds: apiParams.industrialClassIds,
          kind: apiParams.kind,
          country: selectedCountry.value,
        });
        setApiData(apiRes.data);
        setIsLoading(false);
      } catch (error) {
        errorHandler(error);
      }
    };
    fetchData();
  }, [apiParams, selectedCountry, maxYear]);

  useEffect(() => {
    apiData.length > 0 && setActiveItem(apiData[0].category);
  }, [apiData]);

  useEffect(() => {
    // 指定申請人/專利權人改變時, 先清空折線圖資料
    setLineChartData([]);
    const fetchData = async () => {
      try {
        const past = await Api().post('trend/industry/party', {
          industrialClassIds: apiParams.industrialClassIds,
          kind: apiParams.kind,
          country: selectedCountry.value,
          name: activeItem,
          year: selectedYear - 1,
        });
        const current = await Api().post('trend/industry/party', {
          industrialClassIds: apiParams.industrialClassIds,
          kind: apiParams.kind,
          country: selectedCountry.value,
          name: activeItem,
          year: selectedYear,
        });
        let result: Array<ILineChartType> = [];
        result.push({
          name: (selectedYear - 1).toString(),
          data: convertToLineChartData(past.data, selectedYear - 1, statTimeRange),
        });
        result.push({
          name: selectedYear.toString(),
          data: convertToLineChartData(current.data, selectedYear, statTimeRange),
        });
        setLineChartData(result);
      } catch (error) {
        errorHandler(error);
      }
    };
    fetchData();
  }, [apiParams, selectedCountry, selectedYear, activeItem, statTimeRange]);

  /** 取得Tooltip的顯示座標位置 */
  function handleToolTip(element: any) {
    const offset = 8; // icon與tooltip間距
    const rect = element.getBoundingClientRect();
    setTooltipPosition({ left: rect.right - 5, top: rect.top - offset });
  }

  const tooltip = tooltipPosition && (
    <Tooltip
      left={tooltipPosition.left}
      top={tooltipPosition.top}
      overlay={
        <>
          <ToolTipTitle>數據統計說明</ToolTipTitle>
          <ToolTipContent>
            <p>統計時間：{getStatRangeText(statTimeRange)}</p>
            <p>
              件數（數字）：指定行業之{getTrendsTitlePartyText(apiParams.kind)}近五年
              {getTrendsTitleKindText(apiParams.kind)}。
            </p>
          </ToolTipContent>
        </>
      }
    />
  );

  return (
    <>
      <BlockTitle>{getTrendsTitlePartyText(apiParams.kind)}趨勢</BlockTitle>
      {tooltip}
      {apiData.length !== 0 ? (
        <Flexbox>
          <StatLeftContainer>
            <GraphTitle>
              <Dropdown
                activeItem={selectedCountry}
                items={countryDropdown}
                handleOnclick={setSelectedCountry}
                customStyleSelectedItem={customStyleSelectedItemStyle}
              />
              {getTrendsTitleClassText(apiParams.industrialClassIds)}
              {getTrendsTitlePartyText(apiParams.kind)}近五年件數比較
              <FaInfoCircle
                onMouseOver={(e: any) => handleToolTip(e.currentTarget)}
                onMouseLeave={() => setTooltipPosition(undefined)}
              />
            </GraphTitle>
            {apiData.map((item, idx) => (
              <PartyRankItem
                key={idx}
                active={item.category === activeItem}
                onClick={() => setActiveItem(item.category)}
              >
                <PartyRankName>{item.category}</PartyRankName>
                <PartyRankCount>{numFormatter(item.count)}</PartyRankCount>
              </PartyRankItem>
            ))}
          </StatLeftContainer>
          <StatRightContainer>
            <RightGraphTitle>
              {selectedCountry.name}&nbsp;
              {activeItem}
              <br />
              {getTrendsTitleClassText(apiParams.industrialClassIds)}
              {getTrendsTitleKindText(apiParams.kind)}年度趨勢
            </RightGraphTitle>
            <LineChartToggler>
              <ArrowLeft
                disabled={selectedYear <= minYear}
                onClick={() => {
                  if (selectedYear > minYear) {
                    setSelectedYear(selectedYear - 1);
                  }
                }}
              />
              {selectedYear} 年
              <ArrowRight
                disabled={selectedYear >= maxYear}
                onClick={() => {
                  if (selectedYear < maxYear) {
                    setSelectedYear(selectedYear + 1);
                  }
                }}
              />
            </LineChartToggler>
            {lineChartData.length !== 0 ? (
              <LineChart dataset={lineChartData} />
            ) : (
              <LineChartLoader />
            )}
          </StatRightContainer>
        </Flexbox>
      ) : isLoading ? (
        <Spinner width="200px" margin="84px auto" />
      ) : (
        <NoResult>
          <div>查無資料</div>
          <span>請嘗試變更行業別或時間區間。</span>
        </NoResult>
      )}
    </>
  );
};
export default StatApplicant;
