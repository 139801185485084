import { IDropdownProps } from 'src/components/common/Dropdown';
import {
  CHANGE_CHART_TITLE,
  CHANGE_SELECTED_FACET_FIELD,
  CHANGE_SELECTED_ANALYSIS_NUM,
  CHANGE_SELECTED_CHART_TYPE,
  CLEAR_STATISTICS_FORM,
  StatisticsFormActionTypes,
} from './types';

export function changeChartTitle(chartTitle: string): StatisticsFormActionTypes {
  return {
    type: CHANGE_CHART_TITLE,
    chartTitle: chartTitle,
  };
}

export function changeSelectedFacetField(
  facetField: IDropdownProps['activeItem'],
): StatisticsFormActionTypes {
  return {
    type: CHANGE_SELECTED_FACET_FIELD,
    facetField: facetField,
  };
}

export function changeSelectedAnalysisNum(
  analysisNum: IDropdownProps['activeItem'],
): StatisticsFormActionTypes {
  return {
    type: CHANGE_SELECTED_ANALYSIS_NUM,
    analysisNum: analysisNum,
  };
}

export function changeSelectedChartType(selectedChartType: string): StatisticsFormActionTypes {
  return {
    type: CHANGE_SELECTED_CHART_TYPE,
    selectedChartType: selectedChartType,
  };
}

export function clearStatisticsForm(): StatisticsFormActionTypes {
  return {
    type: CLEAR_STATISTICS_FORM,
  };
}
