import React, { useState, useEffect } from 'react';
import {
  FaBookmark,
  FaBook,
  FaTasks,
  FaSitemap,
  FaQuoteRight,
  FaCopy,
  FaBalanceScale,
} from 'react-icons/fa';

import useOutSideClick from 'src/hooks/useOutSideClick';
import ToolbarNotePane from './ToolbarNotePane';
import ToolbarCollectionPane from './ToolbarCollectionPane';
import { PatentService } from 'src/api/Patent';
import {
  PatentDataTypes,
  IGetPatentCollectionCategoryTypes,
  IGetPatentNoteTypes,
  IGetPtabTypes,
} from 'src/types/api/PatentApiTypes';
import {
  ToolbarContainer,
  ToolbarItem,
  ToolbarIcon,
  DividingLine,
} from 'src/style/PatentContentStyle';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import { alertMessage } from 'src/utils/ModalUtils';
import { checkLogin } from 'src/apps/auth/LoginUtils';

import useLoginChecker from 'src/apps/auth/useLoginChecker';
import { IGetPatentCaseList } from 'src/types/IGetPatentTypes';

const patentService = PatentService();
const PATENT_CASE_LIST_STATUS_SUCCESS = '0';
const defaultCaseList = {
  status: '',
  msg: '',
  url: '',
};
interface IProps {
  iconOnly: boolean;
  activeComparedPane: string;
  toggleComparedPane: (activePane: string) => void;
  patentDataId: number;
  patentData: PatentDataTypes;
  ptab: IGetPtabTypes[];
}

function Toolbar(props: IProps) {
  const isLogin = useLoginChecker();
  const [activePane, setActivePane] = useState('');
  const [ratingStars, setRatingStars] = useState(0);
  const [collectionCat, setCollectionCat] = useState<Array<IGetPatentCollectionCategoryTypes>>([]);
  const [isCollected, setIsCollected] = useState(false);
  const [note, setNote] = useState<IGetPatentNoteTypes | null>(null);
  const [newCat, setNewCat] = useState('');
  const [caseList, setCaseList] = useState<IGetPatentCaseList>(defaultCaseList);

  const toggleActivePane = (pane: string | boolean) => {
    if (!pane || pane === activePane) {
      setActivePane('');
    }
    if (typeof pane === 'string' && pane !== activePane) {
      setActivePane(pane);
    }
  };

  const handleAddCategory = (input: string) => {
    // 新增收藏分類
    patentService
      .postPatentCollectionCategory(input)
      .then(() => patentService.getPatentCollectionCategory(props.patentDataId))
      .then(
        res => {
          setCollectionCat([...res]);
          setNewCat(input);
        },
        err => {
          // TODO: 評估是否由errorHandler處理 #Ian:應該, 不是每次都會有後端回應, 缺少相對應的處理可以增加或另提方法集中管理
          alertMessage(err.response.data.resultMessage);
        },
      );
  };

  const handleCollectionSubmit = (
    submitRating: number,
    submitCollection: IGetPatentCollectionCategoryTypes[],
  ) => {
    setNewCat('');
    const submit = async () => {
      // 更新評分
      await patentService.putPatentRating(props.patentDataId, submitRating).then(
        () => setRatingStars(submitRating),
        err => {
          // TODO: 評估是否由errorHandler處理
          alertMessage(err.response.data.resultMessage);
        },
      );
      // 更新蒐藏分類，有修改的才更新
      // Use for-of substitute forEach to resolve serial async scenario
      for (let item of submitCollection) {
        const origItem = collectionCat.find(cat => cat.id === item.id);
        const notSame = !origItem || (origItem && origItem.collected !== item.collected);
        if (item.collected && notSame) {
          // 收藏專利
          await patentService
            .putPatentCollectionCategory(item.id, props.patentDataId)
            .catch(err => {
              // TODO: 評估是否由errorHandler處理
              alertMessage(err.response.data.resultMessage);
            });
        } else if (!item.collected && notSame) {
          // 移除收藏
          await patentService
            .deletePatentCollectionCategory(item.id, props.patentDataId)
            .catch(err => {
              // TODO: 評估是否由errorHandler處理
              alertMessage(err.response.data.resultMessage);
            });
        }
      }
    };
    submit().then(() => {
      setCollectionCat([...submitCollection]);
      setIsCollected(submitCollection.filter(item => item.collected).length > 0);
      setActivePane('');
    });
  };

  const handleSubmitNote = (input: string) => {
    patentService.putPatentNoteById(props.patentDataId, input).then(
      () => {
        // 重取資料更新時間
        patentService.getPatentNoteById(props.patentDataId).then(data => {
          setNote(data);
        });
      },
      err => {
        // TODO: 評估是否由errorHandler處理
        alertMessage(err.response.data.resultMessage);
      },
    );
  };

  const nodeCollection = useOutSideClick({
    show: activePane === 'PatentCollection',
    setShow: toggleActivePane,
  });

  const nodeNote = useOutSideClick({
    show: activePane === 'PatentNote',
    setShow: toggleActivePane,
  });

  useEffect(() => {
    //查詢專利筆記
    isLogin &&
      patentService.getPatentNoteById(props.patentDataId).then(data => {
        setNote(data);
      });
    //查詢專利評等
    isLogin &&
      patentService.getPatentRating(props.patentDataId).then(data => {
        setRatingStars(data);
      });
    // 查詢收藏列表、確認是否蒐藏
    isLogin &&
      patentService.getPatentCollectionCategory(props.patentDataId).then(data => {
        const collection: IGetPatentCollectionCategoryTypes[] = data;
        setCollectionCat([...collection]);
        setIsCollected(collection.filter(item => item.collected).length > 0);
      });
  }, [props.patentDataId, isLogin]);

  useEffect(() => {
    const applicationNumber =
      props.patentData.content &&
      props.patentData.content.bibliographic.applicationReference &&
      props.patentData.content.bibliographic.applicationReference.documentId.docNumber;
    // 查詢專利訴訟/訴願資訊案號清單
    applicationNumber &&
      patentService.getPatentCaseList(applicationNumber).then(data => {
        data && setCaseList(data);
      });
  }, [props.patentData.content]);

  return (
    <ToolbarContainer iconOnly={props.iconOnly}>
      <ToolbarItem ref={nodeCollection as any} active={activePane === 'PatentCollection'}>
        <div
          onClick={() =>
            checkLogin(() => {
              toggleActivePane('PatentCollection');
              setNewCat('');
            })
          }
        >
          <ToolbarIcon>
            <FaBookmark size="16px" />
          </ToolbarIcon>
          收藏專利
        </div>
        {activePane === 'PatentCollection' && (
          <ToolbarCollectionPane
            activePane={activePane}
            origRratingStars={ratingStars}
            origCollectionCat={collectionCat}
            newCat={newCat}
            handleAddCategory={handleAddCategory}
            handleCollectionSubmit={handleCollectionSubmit}
            toggleActivePane={toggleActivePane}
          />
        )}
      </ToolbarItem>

      <ToolbarItem ref={nodeNote as any} active={activePane === 'PatentNote'}>
        <div onClick={() => checkLogin(() => toggleActivePane('PatentNote'))}>
          <ToolbarIcon>
            <FaBook size="16px" />
          </ToolbarIcon>
          筆記
        </div>
        {activePane === 'PatentNote' && (
          <ToolbarNotePane
            origNote={note}
            isCollected={isCollected}
            handleSubmitNote={handleSubmitNote}
            toggleActivePane={toggleActivePane}
          />
        )}
      </ToolbarItem>
      <DividingLine />
      <ToolbarItem
        active={false}
        onClick={() => {
          const content = props.patentData.content;
          content &&
            patentService
              .getPatentUrlGlobalDossier(
                content.country,
                content.bibliographic.applicationReference.documentId.docNumber,
              )
              .then(
                data => window.open(data),
                err => {
                  errorHandler(err);
                },
              );
        }}
      >
        <ToolbarIcon>
          <FaTasks size="16px" />
        </ToolbarIcon>
        申請查詢
      </ToolbarItem>
      <ToolbarItem
        active={props.activeComparedPane === 'PatentFamily'}
        onClick={() => props.toggleComparedPane('PatentFamily')}
      >
        <ToolbarIcon>
          <FaSitemap size="16px" />
        </ToolbarIcon>
        專利家族
      </ToolbarItem>
      <ToolbarItem
        active={props.activeComparedPane === 'PatentCited'}
        onClick={() => props.toggleComparedPane('PatentCited')}
      >
        <ToolbarIcon>
          <FaQuoteRight size="16px" />
        </ToolbarIcon>
        引用文獻
      </ToolbarItem>
      <ToolbarItem
        active={props.activeComparedPane === 'PatentSimilar'}
        onClick={() => props.toggleComparedPane('PatentSimilar')}
      >
        <ToolbarIcon>
          <FaCopy size="16px" />
        </ToolbarIcon>
        相似專利
      </ToolbarItem>
      {props.patentData.content && props.patentData.content.country === 'US' && (
        <ToolbarItem
          active={props.activeComparedPane === 'Ptab'}
          disable={props.ptab && props.ptab.length === 0}
          onClick={() => props.ptab && props.ptab.length > 0 && props.toggleComparedPane('Ptab')}
        >
          <ToolbarIcon>
            <FaBalanceScale size="18px" />
          </ToolbarIcon>
          美國PTAB資料
        </ToolbarItem>
      )}
      {props.patentData.content && props.patentData.content.country === 'TW' && (
        <ToolbarItem
          active={false}
          disable={!(caseList.status === PATENT_CASE_LIST_STATUS_SUCCESS && caseList.url)}
          onClick={() => {
            caseList.status === PATENT_CASE_LIST_STATUS_SUCCESS &&
              caseList.url &&
              window.open(caseList.url);
          }}
        >
          <ToolbarIcon>
            <FaBalanceScale size="18px" />
          </ToolbarIcon>
          訴願訴訟資料
        </ToolbarItem>
      )}
    </ToolbarContainer>
  );
}

export default Toolbar;
