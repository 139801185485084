import React, { useState, useEffect, useCallback } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import axios from 'axios';
import { AxiosResponse } from 'axios';
import styled from 'styled-components';
import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';

import { IGetRssLatest } from 'src/types/api/NewsApiTypes';
import SubscribeContent from 'src/components/ui/content/News/SubscribeContent';
import { Spinner } from 'src/components/ui/interactive/Spinner';
import InfiniteScroll from 'src/components/ui/interactive/InfiniteScroll';
import {
  TutorialContainer,
  TutorialTitle,
  TutorialContent,
  TutorialHighlight,
} from 'src/style/TutorialStyle';
import LatestItem from './item/LatestItem';

const NoResult = styled.div`
  padding: 16px 32px;
  border-bottom: 1px solid #eceff1;
  align-items: left;
  display: flex;
  :hover {
    background-color: #f5fdfe;
  }
`;

// phase 3
const srcList = [
  '標籤1',
  '標籤2',
  '標籤3',
  '標籤4',
  '標籤5',
  'Item1',
  'Item2',
  'Item3',
  'Item4',
  'Item5',
];

interface IProps {
  isShare: boolean;
  shareUser?: string;
  shareUserId?: number;
  sector: string;
  feature: string;
  keyword: string;
  id: number;
  notification: boolean;
  subscribeName: string;
  handleSubscribePaneDisplay: (displaySubscribePane: boolean, subscribeIdToEdit: number) => void;
  handleSubmit: (option: string, id: number, data: { keyword: string }) => void;
  topicId: number;
}

function Subscribe(props: IProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [noSubscribe, setNoSubscribe] = useState(false);
  const [newsList, setNewsList] = useState<Array<IGetRssLatest>>([]);

  const [pageNo, setPageNo] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [isNextPageLoading, setIsNextPageLoading] = useState<boolean>(false);
  const [isNextPageError, setIsNextPageError] = useState<boolean>(false);

  const { id, handleSubmit } = props;

  const loadClickHandler = useCallback(() => {
    unstable_batchedUpdates(() => {
      setIsNextPageLoading(true);
      setPageNo(prev => prev + 1);
    });
  }, []);

  const reloadClickHandler = useCallback(() => {
    if (pageNo === 1) {
      setIsLoading(true);
      setNewsList([]);
    } else {
      setIsNextPageLoading(true);
    }
  }, [pageNo]);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const fetchData = async () => {
      try {
        let res: AxiosResponse<any>;
        if (props.isShare) {
          res = await Api().get(
            `rss/follow/${id}/share?pageNo=${pageNo}&sourceSystemAccountId=${props.shareUserId}`,
            { cancelToken: source.token },
          );
        } else {
          res = await Api().get(`/rss/follow/${id}?pageNo=${pageNo}`, {
            cancelToken: source.token,
          });
        }

        setNewsList(prev => [...prev, ...res.data.data]);
        setTotalPages(res.data.totalPages);
        setTotalCount(res.data.total);

        setIsNextPageError(false);
      } catch (error) {
        errorHandler(error);
        isNextPageLoading && setIsNextPageError(true);
      } finally {
        setIsLoading(false);
        setIsNextPageLoading(false);
      }
    };

    if (id !== -1) {
      (isLoading || isNextPageLoading) && fetchData();
    } else {
      setIsLoading(false);
      setIsNextPageLoading(false);
      setNoSubscribe(true);
    }

    return () => source.cancel();
  }, [isLoading, isNextPageLoading, pageNo, id, props.isShare, props.shareUserId]);

  const handleSubscribe = useCallback(
    (option: string, data: any) => {
      handleSubmit(option, id, data);
    },
    [id, handleSubmit],
  );

  const switchContent = () => {
    const listNews = () => {
      if (isLoading) {
        return <Spinner width="150px" />;
      } else if (newsList.length > 0) {
        return (
          <InfiniteScroll
            isNextPageLoading={isNextPageLoading}
            isNextPageError={isNextPageError}
            errorMessage={'系統發生異常'}
            handleNextPageLoad={loadClickHandler}
            handleReload={reloadClickHandler}
            totalCount={totalCount}
            currentPage={pageNo}
            totalPages={totalPages}
          >
            {newsList.map(news => (
              <LatestItem key={news.id} newsData={news} />
            ))}
          </InfiniteScroll>
        );
      } else {
        return <NoResult>無新聞訊息</NoResult>;
      }
    };
    if (props.isShare) {
      if (noSubscribe) {
        return (
          <TutorialContainer>
            <TutorialTitle>與他人一同分享新的發現</TutorialTitle>
            <TutorialContent>
              您可以在我的頻道與他人分享您的自訂頻道，追蹤該頻道的新聞內容。
            </TutorialContent>
            <TutorialHighlight>
              1. 點擊左側選單的我的頻道
              <br />
              2. 在既有的自訂頻道的標題列點選分享
              <br />
              3. 選擇您想分享的對象
              <br />
              4. 按分享按鈕，您分享的對象即可看見您的自訂頻道
              <br />
              5. 請他人與您分享，即可在此看到對方的自訂頻道
            </TutorialHighlight>
          </TutorialContainer>
        );
      } else {
        return listNews();
      }
    } else {
      if (noSubscribe) {
        return (
          <TutorialContainer>
            <TutorialTitle>建立新的自訂頻道</TutorialTitle>
            <TutorialContent>
              看到本頁代表您尚未建立自訂頻道，您可以在右方工具列自訂頻道，追蹤符合自訂條件的新聞內容。
            </TutorialContent>
            <TutorialHighlight>
              1. 點擊自訂頻道
              <br />
              2. 在頻道名稱的輸入框為自訂頻道命名
              <br />
              3. 在頻道條件設定的輸入框輸入條件，系統會為您查詢相符的新聞內容
              <br />
              4. 在設定範圍選取查詢的來源，例如選擇經濟部智慧財產局
              <br />
              5. 按新增按鈕即可為您查詢智慧財產局中符合條件的新聞並儲存
            </TutorialHighlight>
          </TutorialContainer>
        );
      } else {
        return listNews();
      }
    }
  };

  return (
    <SubscribeContent
      isShare={props.isShare}
      shareUser={props.shareUser}
      sector={props.sector}
      feature={props.feature}
      keyword={props.keyword}
      srcs={srcList}
      subscribeId={id}
      notification={props.notification}
      handleSubscribePaneDisplay={props.handleSubscribePaneDisplay}
      submitSubscribe={handleSubscribe}
      noSubscribe={noSubscribe}
      subscribeName={props.subscribeName}
    >
      {switchContent()}
    </SubscribeContent>
  );
}

export default Subscribe;
