import React, { useEffect, useState } from 'react';
import {
  FaArrowLeft,
  FaCopy,
  FaDownload,
  FaExternalLinkAlt,
  FaSitemap,
  FaTimes,
  FaSearchPlus,
  FaSearchMinus,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { PatentService } from 'src/api/Patent';
import { alertMessage } from 'src/utils/ModalUtils';
import {
  Familyvalues,
  IGetPatentCitedTypes,
  IGetPatentFamilyTypes,
  IGetPatentOcrLabelTypes,
  IGetPtabTypes,
  PatentDataTypes,
  PatentLinkDropdownTypes,
} from 'src/types/api/PatentApiTypes';
import {
  ColorSpan,
  ComparedPaneContainer,
  ComparedPaneHeader,
  ComparedPaneSubHeader,
  ComparedPaneSubHeaderTd,
  ComparedPaneTable,
  ComparedPaneTd,
  ComparedPaneTr,
  ComparedPaneTrTitle,
  FadeoutMask,
  Icon,
  PatentBody,
  PatentListDropdown,
  ShowDiv,
  StickyHeaderWrapper,
  ImgTitleContainer,
} from 'src/style/PatentContentStyle';
import { dateFormatter } from 'src/utils/Formatter';

import PatentContent, { templates } from './content/Content';
import { Spinner } from 'src/components/ui/interactive/Spinner';
import { blue3 } from 'src/style/theme/Color';
import { baseURL } from 'src/api/helpers/apiBase';
import { IPatentBasicIntro } from 'src/types/IGetPatentTypes';
import ImageModal from './content/ImageModal';
import { getImgUrls } from 'src/utils/PatentUtils';

/** 專利圖片視窗圖片大小調整。*/
export const getImgSize = (currentSize: number, isPlus: boolean) => {
  const zoomSize = 0.1;
  return isPlus ? currentSize + zoomSize : currentSize - zoomSize;
};

const patentService = PatentService();

const comparePaneMap: { [key: string]: string } = {
  PatentEmpty: '雙欄比對',
  PatentFamily: '專利家族',
  PatentCited: '引用文獻',
  PatentSimilar: '相似專利',
  Ptab: '美國專利審查與上訴委員會資料 (PTAB)',
  PatentOcrLabel: '圖式',
  PatentCompareOcrLabel: '圖式',
};

interface IProps {
  isCompareMode: boolean;
  toggleComparedPane: (activePane: string) => void;
  activeComparedPane: string;
  patentData: PatentDataTypes;
  searchText: string[];
  tagColorArray: string[];
  ptab: IGetPtabTypes[];
  lightboxFirstShowImage: number;
  patentOcrLabel: IGetPatentOcrLabelTypes | null;
  isOcrLabelLoading: boolean;
}

function PatentComparePane({
  isCompareMode,
  toggleComparedPane,
  activeComparedPane,
  patentData,
  searchText,
  tagColorArray,
  ptab,
  lightboxFirstShowImage,
  patentOcrLabel,
  isOcrLabelLoading,
}: IProps) {
  const [searchInput, setSearchInput] = useState('');
  const [patentDataCompare, setPatentDataCompare] = useState<PatentDataTypes | null>(null);
  const [patentFamily, setPatentFamily] = useState<IGetPatentFamilyTypes | null>(null);
  const [linkableFamily, setLinkableFamily] = useState<Familyvalues[]>([]);
  const [patentCited, setPatentCited] = useState<Array<IGetPatentCitedTypes> | null>(null);
  const [patentSimilar, setPatentSimilar] = useState<Array<IPatentBasicIntro> | null>(null);
  const [patentList, setPatentList] = useState<PatentLinkDropdownTypes | null>(null);
  const [patentNotFoundMessage, setPatentNotFoundMessage] = useState<{
    title: string;
    msg: JSX.Element;
  }>({ title: '', msg: <p /> });
  const [imgUrls, setImgUrls] = useState<string[]>([]);
  const [patentDataCompareImage, setPatentDataCompareImage] = useState<number>(0);
  const [
    patentCompareOcrLabel,
    setPatentCompareOcrLabel,
  ] = useState<IGetPatentOcrLabelTypes | null>(null);
  const [patentCompareImgUrls, setPatentCompareImgUrls] = useState<string[]>([]);
  const [addSize, setAddSize] = useState<number>(1);

  useEffect(() => {
    const content = patentData.content;

    const handlePtab = () => {
      if (content) {
        patentService
          .getPtab(content.bibliographic.applicationReference.documentId.docNumber)
          .then(data => {
            if (data && data.length > 0) {
              setPatentList({ item: [], type: activeComparedPane });
            } else {
              handlePatentNotFound(null);
            }
          });
      }
    };

    const handlePatentFamily = () => {
      var result: Familyvalues[] = [];
      if (content) {
        patentService
          .getPatentFamily(
            content.country,
            content.bibliographic.publicationReference.documentId.docNumber,
          )
          .then(
            data => {
              if (data.familyValues && data.familyValues.length !== 0) {
                // 將可連結(patentId有值表示solr有資料)的專利家族取出
                data.familyValues.forEach(item => {
                  item.patentId && result.push(item);
                  item.childFamilyValues &&
                    item.childFamilyValues.forEach(item => {
                      item.patentId && result.push(item);
                    });
                });
                setPatentFamily(data);
                setPatentList({ item: [], type: activeComparedPane });
              } else {
                handlePatentNotFound(null);
              }
            },
            () => {
              handlePatentNotFound(null);
            },
          )
          .then(() => setLinkableFamily(result));
      }
    };

    const handlePatentCited = () => {
      if (content) {
        patentService
          .getPatentCited(
            content.country,
            content.bibliographic.publicationReference.documentId.docNumber,
          )
          .then(
            data => {
              if (data.length !== 0) {
                setPatentCited(data);
                setPatentList({ item: [], type: activeComparedPane });
              } else {
                handlePatentNotFound(null);
              }
            },
            () => {
              handlePatentNotFound(null);
            },
          );
      }
    };

    const handlePatentSimilar = () => {
      if (content) {
        patentService.getPatentSimilar(content.country, content.id).then(
          data => {
            if (data.length !== 0) {
              setPatentSimilar(data);
              setPatentList({ item: [], type: activeComparedPane });
            } else {
              handlePatentNotFound(null);
            }
          },
          () => {
            handlePatentNotFound(null);
          },
        );
      }
    };

    const handlePatentOcrLabel = () => {
      if (content) {
        setPatentList({ item: [], type: activeComparedPane });
        setImgUrls(getImgUrls(content.id, content.img_alias, patentOcrLabel));
      }
    };

    const handlePatentNotFound = (data: any) => {
      if (!data || data.length === 0) {
        const msg = () => {
          switch (activeComparedPane) {
            case 'Ptab':
              return <p>查無美國專利審查與上訴委員會資料</p>;
            case 'PatentFamily':
              return <p>查無專利家族資訊</p>;
            case 'PatentCited':
              return <p>查無專利引用文獻資訊</p>;
            case 'PatentSimilar':
              return <p>查無相似專利資訊</p>;
            case 'PatentEmpty':
              return (
                <>
                  <p>本篇專利目前還沒有建議的比對項目，</p>
                  <p>請直接輸入欲比對專利之公開/公告號或申請號。</p>
                </>
              );
            default:
              return <p />;
          }
        };
        setPatentNotFoundMessage({
          title: activeComparedPane,
          msg: msg(),
        });
        // 若為雙欄比對則不需跳到錯誤訊息頁
        activeComparedPane !== 'PatentEmpty' && toggleComparedPane('PatentNotFound');
      }
    };

    switch (activeComparedPane) {
      case 'Ptab':
        ptab && handlePtab();
        break;
      case 'PatentFamily':
        !patentFamily && handlePatentFamily();
        break;
      case 'PatentCited':
        !patentCited && handlePatentCited();
        break;
      case 'PatentSimilar':
        !patentSimilar && handlePatentSimilar();
        break;
      case 'PatentOcrLabel':
        handlePatentOcrLabel();
        break;
      case 'PatentEmpty':
        !patentFamily && handlePatentFamily();
        !patentSimilar && handlePatentSimilar();
        break;
      default:
        break;
    }
  }, [
    activeComparedPane,
    toggleComparedPane,
    patentData.content,
    patentFamily,
    patentCited,
    patentSimilar,
    ptab,
    patentOcrLabel,
  ]);

  const setComparedPatentImg = (patentId: string, comparedImgUrls: string[]) => {
    patentService.getPatentOcrLabel(patentId).then(data => {
      if (data) {
        setPatentCompareOcrLabel(data);
      }
      setPatentCompareImgUrls(getImgUrls(patentId, comparedImgUrls, data));
    });
  };

  const handleSearchPatent = (e: any) => {
    const input = e.target.value;
    if (e.key === 'Enter' && input !== '') {
      patentService.getPatentDataByNumber(input).then(
        data => {
          setPatentDataCompare(data);
          setSearchInput(input);
          toggleComparedPane('ComparedPatent');
          // 搜尋對比專利的圖片
          data.content &&
            data.content.id &&
            setComparedPatentImg(data.content.id, data.content.img_alias);
        },
        () => {
          setSearchInput(input);
          setPatentNotFoundMessage({
            title: 'PatentEmpty',
            msg: (
              <p>
                <br />
                很抱歉，查無此專利，可能的情況如下：
                <br />
                <br />
                1. 本平台尚未有該專利資料，詳情可聯絡我們
                <br />
                2. 公開/公告號或申請號有誤
              </p>
            ),
          });
          toggleComparedPane('PatentNotFound');
        },
      );
    }
  };

  const handlePatentLink = (patentId: string | null) => {
    if (patentId) {
      patentService
        .getPatentData(patentId)
        .then(
          data => {
            setPatentDataCompare(data);
            const comparedContent = data.content;
            comparedContent &&
              setSearchInput(
                comparedContent.bibliographic.publicationReference.documentId.docNumber,
              );
            comparedContent &&
              comparedContent.id &&
              setComparedPatentImg(comparedContent.id, comparedContent.img_alias);
          },
          err => {
            // TODO: 評估是否由errorHandler處理
            alertMessage(err.response.data.resultMessage);
          },
        )
        .then(() => {
          toggleComparedPane('ComparedPatent');
        });
    }
  };

  const linkIcon = (patentId: string, size?: number, color?: string) => (
    <Link to={`/patent/${patentId}/view`} target="_blank">
      <Icon margin="0 10px">
        <FaExternalLinkAlt size={(size || 12) + 'px'} color={color || '#b3b9bc'} />
      </Icon>
    </Link>
  );

  const closeIcon = (
    <Icon>
      <FaTimes
        size="16px"
        onClick={() => {
          activeComparedPane === 'PatentCompareOcrLabel'
            ? toggleComparedPane('ComparedPatent')
            : toggleComparedPane(activeComparedPane);
        }}
      />
    </Icon>
  );

  const patentImageTitle = (
    <ImgTitleContainer>
      <span>{comparePaneMap[activeComparedPane]}</span>
      <Icon>
        <FaSearchPlus size="16px" onClick={() => setAddSize(getImgSize(addSize, true))} />
      </Icon>
      <Icon>
        <FaSearchMinus size="16px" onClick={() => setAddSize(getImgSize(addSize, false))} />
      </Icon>
    </ImgTitleContainer>
  );
  const patentTitle = (
    <ComparedPaneHeader>
      {activeComparedPane === 'PatentOcrLabel' || activeComparedPane === 'PatentCompareOcrLabel' ? (
        patentImageTitle
      ) : (
        <p>{comparePaneMap[activeComparedPane]}</p>
      )}
      {closeIcon}
    </ComparedPaneHeader>
  );

  const patentSearchBar = (
    <ComparedPaneSubHeader>
      <input
        type="text"
        placeholder="請輸入比對專利之公開/公告號或申請號..."
        onKeyPress={e => handleSearchPatent(e)}
        defaultValue={searchInput}
      />
    </ComparedPaneSubHeader>
  );

  const patentLinkDropdown = (
    <ComparedPaneSubHeader>
      <Icon margin="0 10px 0 0">
        <FaArrowLeft
          color="#0cafcc"
          size="18px"
          onClick={() => {
            toggleComparedPane(patentList ? patentList.type : 'ComparedPatent');
          }}
        />
      </Icon>
      <PatentListDropdown>{searchInput}</PatentListDropdown>
    </ComparedPaneSubHeader>
  );

  const patentLoadingPane = (
    <ComparedPaneContainer>
      <StickyHeaderWrapper>{patentTitle}</StickyHeaderWrapper>
      <PatentBody>
        <Spinner width="150px" />
      </PatentBody>
    </ComparedPaneContainer>
  );

  const patentEmptyPane = (
    <ComparedPaneContainer>
      <StickyHeaderWrapper>
        {patentTitle}
        {patentSearchBar}
      </StickyHeaderWrapper>
      <PatentBody>
        {linkableFamily && linkableFamily.length !== 0 && (
          <>
            <ComparedPaneTrTitle>
              <div>
                <FaSitemap />
                專利家族
              </div>
              <span onClick={() => toggleComparedPane('PatentFamily')}>顯示全部</span>
            </ComparedPaneTrTitle>
            <ComparedPaneTable>
              {linkableFamily.map(
                (item, idx) =>
                  idx < 3 && (
                    <ComparedPaneTr
                      key={item.pubNumber + idx}
                      linkable={true}
                      onClick={() => {
                        handlePatentLink(item.patentId);
                      }}
                    >
                      <ComparedPaneTd width="50%">
                        <b>{item.pubNumber}</b>
                      </ComparedPaneTd>
                      <ComparedPaneTd width="30%">{dateFormatter(item.date)}</ComparedPaneTd>
                      <ComparedPaneTd>{item.country}</ComparedPaneTd>
                    </ComparedPaneTr>
                  ),
              )}
            </ComparedPaneTable>
          </>
        )}
        {patentSimilar && (
          <>
            <ComparedPaneTrTitle>
              <div>
                <FaCopy />
                相似專利
              </div>
              <span onClick={() => toggleComparedPane('PatentSimilar')}>顯示全部</span>
            </ComparedPaneTrTitle>
            <ComparedPaneTable>
              {patentSimilar.map(
                (item, idx) =>
                  idx < 3 && (
                    <ComparedPaneTr
                      key={item.id + idx}
                      linkable={item.id !== null}
                      onClick={() => {
                        handlePatentLink(item.id);
                      }}
                    >
                      <ComparedPaneTd width="50%">
                        <b>{item.bibliographic.publicationReference.documentId.docNumber}</b>
                      </ComparedPaneTd>
                      <ComparedPaneTd width="30%">
                        {item.bibliographic.publicationReference &&
                          item.bibliographic.publicationReference.documentId &&
                          item.bibliographic.publicationReference.documentId.date &&
                          dateFormatter(item.bibliographic.publicationReference.documentId.date)}
                      </ComparedPaneTd>
                      <ComparedPaneTd>{item.country}</ComparedPaneTd>
                    </ComparedPaneTr>
                  ),
              )}
            </ComparedPaneTable>
          </>
        )}
        {(!linkableFamily || linkableFamily.length === 0) && !patentCited && (
          <ComparedPaneTable>
            <ComparedPaneTd>{patentNotFoundMessage.msg}</ComparedPaneTd>
          </ComparedPaneTable>
        )}
      </PatentBody>
    </ComparedPaneContainer>
  );

  const patentNotFoundPane = (
    <ComparedPaneContainer notFound={true}>
      <StickyHeaderWrapper>
        <ComparedPaneHeader>
          <p>{comparePaneMap[patentNotFoundMessage.title]}</p>
          {closeIcon}
        </ComparedPaneHeader>
        {patentNotFoundMessage.title === 'PatentEmpty' && patentSearchBar}
      </StickyHeaderWrapper>
      <PatentBody>{patentNotFoundMessage.msg}</PatentBody>
    </ComparedPaneContainer>
  );

  const comparedPatentPane =
    patentDataCompare && patentDataCompare.content ? (
      <ComparedPaneContainer notSticky={true}>
        <StickyHeaderWrapper>
          <ComparedPaneHeader>
            <p>{patentDataCompare.content.bibliographic.inventionTitle.title.join(' | ')}</p>
            <div>
              {linkIcon(patentDataCompare.content.id, 16, '#ffffff')}
              {closeIcon}
            </div>
          </ComparedPaneHeader>
          {patentList ? patentLinkDropdown : patentSearchBar}
          <FadeoutMask />
        </StickyHeaderWrapper>
        <PatentBody>
          <PatentContent
            template={templates.HalfPage}
            patentData={patentDataCompare}
            searchText={searchText}
            tagColorArray={tagColorArray}
            anchorIndex={2}
            toggleComparedPane={toggleComparedPane}
            setLightboxFirstShowImage={setPatentDataCompareImage}
            lightboxFirstShowImage={patentDataCompareImage}
            isPatentCompare={true}
            patentOcrLabel={patentCompareOcrLabel}
          />
        </PatentBody>
      </ComparedPaneContainer>
    ) : (
      patentLoadingPane
    );

  const patentFamilyPane = patentFamily ? (
    <ComparedPaneContainer>
      <StickyHeaderWrapper>
        {patentTitle}
        <ComparedPaneSubHeader>
          <ComparedPaneSubHeaderTd basis="50%">公告號</ComparedPaneSubHeaderTd>
          <ComparedPaneSubHeaderTd basis="30%">公告日</ComparedPaneSubHeaderTd>
          <ComparedPaneSubHeaderTd>國別</ComparedPaneSubHeaderTd>
        </ComparedPaneSubHeader>
      </StickyHeaderWrapper>
      <PatentBody>
        <ComparedPaneTable>
          {patentFamily.familyValues.map((item, idx) => (
            <React.Fragment key={idx}>
              <ComparedPaneTr
                key={item.pubNumber + idx}
                linkable={item.patentId !== null}
                onClick={() => {
                  handlePatentLink(item.patentId);
                }}
              >
                <ComparedPaneTd width="50%">
                  <b>{item.pubNumber}</b>
                  {item.patentId && linkIcon(item.patentId)}
                </ComparedPaneTd>
                <ComparedPaneTd width="30%">{dateFormatter(item.date)}</ComparedPaneTd>
                <ComparedPaneTd>{item.country}</ComparedPaneTd>
              </ComparedPaneTr>
              {item.childFamilyValues &&
                item.childFamilyValues.map((item, idx) => (
                  <ComparedPaneTr
                    key={item.pubNumber + idx}
                    linkable={item.patentId !== null}
                    onClick={() => {
                      handlePatentLink(item.patentId);
                    }}
                  >
                    <ComparedPaneTd width="50%">
                      <ColorSpan color="#d0d4d6">&#9492;&ensp;</ColorSpan>
                      <b>{item.pubNumber}</b>
                      {item.patentId && linkIcon(item.patentId)}
                    </ComparedPaneTd>
                    <ComparedPaneTd width="30%">{dateFormatter(item.date)}</ComparedPaneTd>
                    <ComparedPaneTd>{item.country}</ComparedPaneTd>
                  </ComparedPaneTr>
                ))}
            </React.Fragment>
          ))}
        </ComparedPaneTable>
      </PatentBody>
    </ComparedPaneContainer>
  ) : (
    patentLoadingPane
  );

  const ptabPane = ptab ? (
    <ComparedPaneContainer>
      <StickyHeaderWrapper>
        {patentTitle}
        <ComparedPaneSubHeader>
          <ComparedPaneSubHeaderTd basis="50%">檔案名稱</ComparedPaneSubHeaderTd>
          <ComparedPaneSubHeaderTd basis="30%">公開日期</ComparedPaneSubHeaderTd>
          <ComparedPaneSubHeaderTd>下載</ComparedPaneSubHeaderTd>
        </ComparedPaneSubHeader>
      </StickyHeaderWrapper>
      <PatentBody>
        <ComparedPaneTable>
          {ptab.map((item, idx) => (
            <React.Fragment key={idx}>
              <ComparedPaneTr
                key={item.applicationNumber + idx}
                linkable={item.applicationNumber !== null}
              >
                <ComparedPaneTd width="50%">
                  <b>{item.docName}</b>
                </ComparedPaneTd>
                <ComparedPaneTd width="29%">{dateFormatter(item.date)}</ComparedPaneTd>

                <ComparedPaneTd>
                  {item.downloaded ? (
                    <a href={baseURL + 'ptab/' + item.ptabDocNumber + '/file'}>
                      <FaDownload color={blue3} size={20} />
                    </a>
                  ) : (
                    '尚無下載'
                  )}
                </ComparedPaneTd>
              </ComparedPaneTr>
            </React.Fragment>
          ))}
        </ComparedPaneTable>
      </PatentBody>
    </ComparedPaneContainer>
  ) : (
    patentLoadingPane
  );

  const patentCitedPane = patentCited ? (
    <ComparedPaneContainer>
      <StickyHeaderWrapper>
        {patentTitle}
        <ComparedPaneSubHeader>
          <ComparedPaneSubHeaderTd basis="20%">公告號</ComparedPaneSubHeaderTd>
          <ComparedPaneSubHeaderTd basis="20%">公告日</ComparedPaneSubHeaderTd>
          <ComparedPaneSubHeaderTd basis="25%">所有權人</ComparedPaneSubHeaderTd>
          <ComparedPaneSubHeaderTd>專利標題</ComparedPaneSubHeaderTd>
        </ComparedPaneSubHeader>
      </StickyHeaderWrapper>
      <PatentBody>
        <ComparedPaneTable>
          {patentCited.map((item, idx) => (
            <ComparedPaneTr
              key={idx}
              linkable={item.patentId !== null}
              onClick={() => {
                handlePatentLink(item.patentId);
              }}
            >
              <ComparedPaneTd width="20%">
                <b>{item.pubNumber}</b>
                {item.patentId && linkIcon(item.patentId)}
              </ComparedPaneTd>
              <ComparedPaneTd width="20%">{dateFormatter(item.pubDate)}</ComparedPaneTd>
              <ComparedPaneTd width="25%">{item.assignee}</ComparedPaneTd>
              <ComparedPaneTd>{item.title}</ComparedPaneTd>
            </ComparedPaneTr>
          ))}
        </ComparedPaneTable>
      </PatentBody>
    </ComparedPaneContainer>
  ) : (
    patentLoadingPane
  );

  const patentSimilarPane = patentSimilar ? (
    <ComparedPaneContainer>
      <StickyHeaderWrapper>
        {patentTitle}
        <ComparedPaneSubHeader>
          <ComparedPaneSubHeaderTd basis="35%">公告號</ComparedPaneSubHeaderTd>
          <ComparedPaneSubHeaderTd>專利名稱</ComparedPaneSubHeaderTd>
        </ComparedPaneSubHeader>
      </StickyHeaderWrapper>
      <PatentBody>
        <ComparedPaneTable>
          {patentSimilar.map((item, idx) => (
            <ComparedPaneTr
              key={idx}
              linkable={item.id !== null}
              onClick={() => {
                handlePatentLink(item.id);
              }}
            >
              <ComparedPaneTd width="35%">
                <b>{item.bibliographic.publicationReference.documentId.docNumber}</b>
                {item.id && linkIcon(item.id)}
              </ComparedPaneTd>
              <ComparedPaneTd>{item.bibliographic.inventionTitle.title}</ComparedPaneTd>
            </ComparedPaneTr>
          ))}
        </ComparedPaneTable>
      </PatentBody>
    </ComparedPaneContainer>
  ) : (
    patentLoadingPane
  );

  const getImagePane = (
    images: string[] | null,
    imageOnDisplay: number,
    data: IGetPatentOcrLabelTypes | null,
  ) => {
    return !isOcrLabelLoading && images ? (
      <ComparedPaneContainer>
        <StickyHeaderWrapper>{patentTitle}</StickyHeaderWrapper>
        <PatentBody>
          <ImageModal
            images={images}
            imageOnDisplay={imageOnDisplay}
            isOpen={images.length !== 0}
            showThumbnails={true}
            data={data}
            setAddSize={setAddSize}
            addSize={addSize}
          />
        </PatentBody>
      </ComparedPaneContainer>
    ) : (
      patentLoadingPane
    );
  };

  const patentOcrImagePane = getImagePane(imgUrls, lightboxFirstShowImage, patentOcrLabel);

  const patentCompareOcrImagePane = getImagePane(
    patentCompareImgUrls,
    patentDataCompareImage,
    patentCompareOcrLabel,
  );

  return (
    <ShowDiv show={isCompareMode}>
      {activeComparedPane === 'PatentEmpty' && patentEmptyPane}
      {activeComparedPane === 'PatentNotFound' && patentNotFoundPane}
      {activeComparedPane === 'ComparedPatent' && comparedPatentPane}
      {activeComparedPane === 'PatentFamily' && patentFamilyPane}
      {activeComparedPane === 'PatentCited' && patentCitedPane}
      {activeComparedPane === 'PatentSimilar' && patentSimilarPane}
      {activeComparedPane === 'Ptab' && ptabPane}
      {activeComparedPane === 'PatentOcrLabel' && patentOcrImagePane}
      {activeComparedPane === 'PatentCompareOcrLabel' && patentCompareOcrImagePane}
    </ShowDiv>
  );
}

export default PatentComparePane;
