import React from 'react';
import styled from 'styled-components';

import { IGetRssLatest } from 'src/types/api/NewsApiTypes';
import { dateFormatter } from 'src/utils/Formatter';
import { white } from 'src/style/theme/Color';
import useRssFeedNews from 'src/hooks/useRssFeedNews';

// Layout
const Container = styled.div`
  padding: 16px 0;
  border-bottom: 1px solid #eceff1;
  display: flex;
  align-items: center;
  background-color: ${white};
  :hover {
    background-color: #f5fdfe;
  }
`;
const Title = styled.div`
  padding: 0 0 0 32px;
  width: 55%;
  color: #1f8ba3;
  span {
    cursor: pointer;
  }
`;
const Source = styled.div`
  padding: 0 0 0 16px;
  width: 22.5%;
  color: #323846;
`;
const Announce = styled.div`
  padding: 0 32px 0 0;
  width: 22.5%;
  color: #323846;
`;
const Entities = require('html-entities').Html5Entities;
const htmlEntities = new Entities();
const XmlEntities = require('html-entities').XmlEntities;
const xmlEntities = new XmlEntities();
const regex = /(<([^>]+)>)/gi;
const [handleClick] = useRssFeedNews();

interface IProps {
  newsData: IGetRssLatest;
}

const PreviewItem: React.FC<IProps> = props => {
  return (
    <Container>
      <Title onClick={() => handleClick(props.newsData.id, props.newsData.link)}>
        <span>
          {htmlEntities
            .decode(xmlEntities.decode(htmlEntities.decode(props.newsData.title)))
            .replace(regex, '')}
        </span>
      </Title>
      <Source>{props.newsData.feedName}</Source>
      <Announce>{dateFormatter(props.newsData.releaseDate)}</Announce>
    </Container>
  );
};

export default PreviewItem;
