import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';

/** RSS 新聞 共用邏輯。 */
const useRssFeedNews = () => {
  /** RSS 新聞 點擊閱覽。 */
  const handleClick = (rssFeedItemUuid: string, rssFeedItemLink: string) => {
    try {
      Api().post(`rss/item/${rssFeedItemUuid}/view`);
      window.open(rssFeedItemLink);
    } catch (error) {
      errorHandler(error);
    }
  };

  return [handleClick];
};

export default useRssFeedNews;
