import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Api } from 'src/api/helpers/apiBase';
import _ from 'lodash';
import { Container, Item } from 'src/style/SearchDropdownStyle';

interface IProps {
  cssTop?: string;
  industryIds: Array<number | string>;
  inputText: string;
  handleSuggestionClick: (value?: string) => void;
}

const Autocomplete: React.FC<IProps> = props => {
  const { cssTop, industryIds, inputText, handleSuggestionClick } = props;

  const [suggestions, setSuggestions] = useState<Array<string>>([]);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const fetchSuggestion = async () => {
      const res = await Api({
        'Content-Type': 'application/json',
      }).post(
        '/patent/search/term',
        {
          industryId: industryIds,
          keyword: inputText,
        },
        { cancelToken: source.token },
      );
      setSuggestions(res.data);
    };

    if (inputText !== '') {
      const debounce = _.debounce(fetchSuggestion, 300);
      debounce();
    }

    return () => source.cancel();
  }, [inputText, industryIds]);

  return suggestions && suggestions.length > 0 && suggestions[0] !== inputText ? (
    <Container cssTop={cssTop} onClick={() => handleSuggestionClick()}>
      {suggestions.map(term => (
        <Item
          key={term}
          onClick={e => {
            e.stopPropagation();
            handleSuggestionClick(term);
          }}
        >
          <span>{term}</span>
        </Item>
      ))}
    </Container>
  ) : null;
};

export default Autocomplete;
