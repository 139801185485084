import React from 'react';
import styled from 'styled-components';
import { FaSearch } from 'react-icons/fa';
import { ultrawhite, iceblue5, iceblue6 } from 'src/style/theme/Color';

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 16px;
  margin-bottom: 8px;
  input {
    width: 100%;
    border-radius: 4px;
    border: solid 1px ${iceblue6};
    background-color: ${ultrawhite};
    padding: 8px 32px 8px 16px;
  }
  svg {
    position: absolute;
    right: 32px;
    font-size: 16px;
  }
`;

interface IProps {
  searchKeyword: string;
  onChange: (e: any) => void;
}

const SearchBar: React.FC<IProps> = props => {
  return (
    <Container>
      <input
        type="text"
        placeholder="Search"
        value={props.searchKeyword}
        onChange={props.onChange}
      />
      <FaSearch color={iceblue5} />
    </Container>
  );
};

export default SearchBar;
