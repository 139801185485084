import React from 'react';
import styled from 'styled-components';
import patent_track from 'src/assets/images/manual/search-result-analysis/patent_track.png';

const ImgContainer = styled.div<{ height: string; imageUrl: string }>`
  position: relative;
  height: ${props => props.height}px;
  padding: 10px 0;
  background-image: url(${props => props.imageUrl});
  background-size: contain;
  background-repeat: no-repeat;
`;
export default function SearchResultAnalysisManual() {
  return (
    <>
      <p>以目前檢索條件儲存對其專利結果進行追蹤</p>
      <ol>
        <li>名稱設定：設定專利追蹤名稱</li>
        <li>追蹤管理：於專利專區/專利追蹤，顯示專利追蹤內容，進行編輯、分享及刪除</li>
      </ol>
      <br />
      <ImgContainer height="350" imageUrl={patent_track} />
    </>
  );
}
