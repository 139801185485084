import React from 'react';
import styled from 'styled-components';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { blue2, blue7, blue8, iceblue7, ultrawhite, iceblue4 } from 'src/style/theme/Color';
import { Carousel } from 'src/style/ResultGridItemStyle';
import BasicCarouselContent from './grid-item/BasicCarouselContent';
import OverlayCarousel from './grid-item/OverlayCarousel';
import { IGetPatentTypes } from 'src/types/IGetPatentTypes';

import { connect } from 'react-redux';
import { fetchPatentContent } from 'src/redux/actions/patentContentAction';

const Container = styled.div<{ isPreviewMode: boolean; isPreviewing: boolean }>`
  border-radius: 4px;
  border: solid 1px ${iceblue7};
  background-color: ${props => props.isPreviewing && blue7};
  :hover {
    background-color: ${blue8};
    cursor: ${props => props.isPreviewMode && 'pointer'};
  }
`;

const Title = styled.div`
  padding: 16px;
  font-size: 18px;
  :hover {
    cursor: pointer;
  }
`;

const TitleEllipsis = styled.div`
  color: ${blue2};
  font-size: 1em;
  font-weight: 500;

  margin: 0 -1em 0 0;
  padding: 0 1em 0 0;
  width: calc(100% - 1em);
  line-height: calc(1em * 1.5);
  max-height: calc(1em * 1.5 * 2);

  overflow: hidden;
  position: relative;
  word-break: break-all;

  :before {
    content: '...';
    position: absolute;
    right: 2px;
    bottom: 1px;
  }

  :after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: calc(1em * 0.2);
    background-color: ${ultrawhite};
  }

  ${Container}:hover & {
    :after {
      background-color: ${blue8};
    }
  }
`;

const Distance = styled.div`
  margin-top: 8px;
  opacity: 0.8;
  color: ${iceblue4};
`;

interface IReduxMappingProps {
  fetchPatentContent: (uuid: string) => void;
}

interface IProps extends RouteComponentProps, IReduxMappingProps {
  isPreviewMode: boolean;
  isPreviewing: boolean;
  patent: IGetPatentTypes;
  distance?: string;
  hasOverlay?: boolean;
  listIndex?: number;
}

/** 單筆查詢結果-圖片模式 */
const ResultGridItem: React.FC<IProps> = ({
  patent,
  distance,
  isPreviewMode,
  isPreviewing,
  hasOverlay,
  listIndex,
  fetchPatentContent,
  history,
}) => {
  const handleRedirect = () => {
    !isPreviewMode && history.push(`/patent/${patent.id}/view`);
  };

  // 專利相關資訊
  const title = patent.bibliographic.inventionTitle.title;

  return (
    <Container
      isPreviewMode={isPreviewMode}
      isPreviewing={isPreviewing}
      onClick={() => isPreviewMode && fetchPatentContent(patent.id)}
    >
      <Title onClick={() => handleRedirect()}>
        <TitleEllipsis>{title}</TitleEllipsis>
        {distance && (
          <Distance>
            相似度分數&nbsp;:&nbsp;{parseFloat(distance).toFixed(2)}&nbsp;(分數越低者越接近)
          </Distance>
        )}
      </Title>

      {patent.img_alias &&
        patent.img_alias.length > 0 &&
        (hasOverlay ? (
          <OverlayCarousel patent={patent} listIndex={listIndex} />
        ) : (
          <Carousel>
            <BasicCarouselContent patent={patent} />
          </Carousel>
        ))}
    </Container>
  );
};

const mapDispatchToProps = {
  fetchPatentContent,
};

export default connect(
  null,
  mapDispatchToProps,
)(withRouter(ResultGridItem));
