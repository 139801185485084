import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { IGetRecommendPatents } from 'src/types/api/IndexApiTypes';
import { dateFormatter } from 'src/utils/Formatter';

import { pink3, ultrawhite } from 'src/style/theme/Color';

// const PatentClass = styled.div`
//   padding: 5px 0;
//   opacity: 0.8;
// `;
const PatentTitle = styled.div`
  padding: 5px 0;
  height: 60%;
  font-weight: 900;
  div:nth-child(2) {
    padding: 16px 0 0;
    height: 74px;
    overflow: hidden;
    img {
      margin: 0 10px 0 0;
      width: 58px;
      height: 58px;
    }
  }
`;
const MultiLineEllipsisText = styled.div`
  margin: 0 -1rem 0 0;
  padding: 0 1rem 0 0;
  width: calc(100% - 1rem);
  max-height: 3.6rem;
  line-height: 1.2rem;
  overflow: hidden;
  position: relative;
  word-break: break-all;
  :before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
  }
  :after {
    content: '';
    position: absolute;
    right: 0;
    width: 1rem;
    height: 1rem;
    margin-top: 0.2rem;
    background: #0f96a9;
  }
`;
const PatentDescribe = styled.div`
  padding: 5px 0 0 0;
  height: 80px;
  font-size: 14px;
  > div {
    opacity: 0.8;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
const FlexDiv = styled.div`
  padding: 20px 0 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;
const InspectBtn = styled.div`
  margin: 10px 0 0 0;
  width: 64px;
  height: 32px;
  border-radius: 4px;
  color: ${ultrawhite};
  background-color: ${pink3};
  text-align: center;
  line-height: 32px;
  cursor: pointer;
`;

interface IProps {
  patent: IGetRecommendPatents;
  gaClass: string;
}

const inspect = ({ patent, gaClass }: IProps) => {
  const imgList = [];
  if (patent.img_alias) {
    const len = patent.img_alias.length;
    for (let i = 0; i < len && i < 4; ++i) {
      imgList.push(
        `${process.env.REACT_APP_API_URL}patent/${patent.id}/img/${patent.img_alias[i]}`,
      );
    }
  }
  const documentId = patent.bibliographic.publicationReference.documentId;
  const notKindA =
    documentId.kind &&
    !documentId.kind.includes('A') &&
    !documentId.kind.includes('P1') &&
    !documentId.kind.includes('P4') &&
    !documentId.kind.includes('P9');

  return (
    <>
      {/* <PatentClass>{patent.industryName}</PatentClass> */}
      <PatentTitle>
        <div>
          <MultiLineEllipsisText>
            {patent.bibliographic.inventionTitle.title.join(' | ')}
          </MultiLineEllipsisText>
        </div>
        <div>
          {patent.img_alias && patent.img_alias.length === 0
            ? null
            : imgList.map(img => <img key={img} src={img} alt="" />)}
        </div>
      </PatentTitle>
      <PatentDescribe>
        <div>
          發明人：
          {patent.bibliographic.parties.inventor &&
          patent.bibliographic.parties.inventor.addressbook.name
            ? patent.bibliographic.parties.inventor.addressbook.name.join(' ')
            : ''}
        </div>
        <div>
          申請人：
          {patent.bibliographic.parties.applicant &&
          patent.bibliographic.parties.applicant.addressbook.name
            ? patent.bibliographic.parties.applicant.addressbook.name.join(' ')
            : ''}
        </div>
        <div>
          {notKindA ? '公告號：' : '公開號：'}
          {documentId && documentId.docNumber + documentId.kind}
        </div>
        <div>
          {notKindA ? '公告日：' : '公開日：'}
          {documentId && documentId.date ? dateFormatter(documentId.date) : '無日期資訊'}
        </div>
        <div>國&emsp;別：{patent.country}</div>
      </PatentDescribe>
      <FlexDiv>
        <Link to={'/patent/' + patent.id + '/view'}>
          <InspectBtn className={gaClass}>全文</InspectBtn>
        </Link>
      </FlexDiv>
    </>
  );
};

export default inspect;
