import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { IGetPatentTypes } from 'src/types/IGetPatentTypes';
import { dateFormatter } from 'src/utils/Formatter';

// Layout
const Container = styled.div`
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  :hover {
    background-color: #f5fdfe;
  }
`;
const LeftContainer = styled.div`
  padding: 20px 8px 20px 32px;
  width: 70%;
`;
const RightContainer = styled.div`
  width: 30%;
  padding: 20px 32px 20px 8px;
  img {
    width: 100%;
    max-height: 200px;
  }
`;

// Elements
const Title = styled.div`
  padding: 0 0 8px 0;
  font-size: 18px;
  a {
    color: #077790;
  }
`;
const Text = styled.div`
  color: #666666;
  padding: 0 0 4px 0;
`;

interface IProps {
  trackData: IGetPatentTypes;
}

function TrackItem(props: IProps) {
  const documentId = props.trackData.bibliographic.publicationReference.documentId;
  const notKindA =
    documentId.kind &&
    !documentId.kind.includes('A') &&
    !documentId.kind.includes('P1') &&
    !documentId.kind.includes('P4') &&
    !documentId.kind.includes('P9');
  return (
    <Container>
      <LeftContainer>
        <Title>
          <Link to={`/patent/${props.trackData.id}/view`}>
            {props.trackData.bibliographic.inventionTitle.title.join(' | ')}
          </Link>
        </Title>
        <Text>
          發明人：
          {props.trackData.bibliographic.parties &&
            props.trackData.bibliographic.parties.inventor &&
            props.trackData.bibliographic.parties.inventor.addressbook.name &&
            props.trackData.bibliographic.parties.inventor.addressbook.name.join(' ')}
        </Text>
        <Text>
          申請人：
          {props.trackData.bibliographic.parties &&
            props.trackData.bibliographic.parties.applicant &&
            props.trackData.bibliographic.parties.applicant.addressbook.name &&
            props.trackData.bibliographic.parties.applicant.addressbook.name.join(' ')}
        </Text>
        <Text>
          {notKindA ? '公告號：' : '公開號：'}
          {documentId && documentId.docNumber + documentId.kind}
        </Text>
        <Text>
          {notKindA ? '公告日：' : '公開日：'}
          {documentId && documentId.date ? dateFormatter(documentId.date) : '無日期資訊'}
        </Text>
      </LeftContainer>
      <RightContainer>
        {props.trackData.img_alias && (
          <img
            src={
              process.env.REACT_APP_API_URL +
              'patent/' +
              props.trackData.id +
              '/img/' +
              props.trackData.img_alias[0]
            }
            alt=""
          />
        )}
      </RightContainer>
    </Container>
  );
}

export default TrackItem;
