import styled from 'styled-components';

export const Container = styled.div<{ cssTop?: string }>`
  z-index: 100;
  padding: 16px 0;
  width: 100%;
  left: 0;
  top: ${props => (props.cssTop ? props.cssTop : '48px')};
  position: absolute;
  opacity: 0.96;
  border-radius: 4px;
  background-color: #ffffff;
  -webkit-backdrop-filter: blur(36px);
  backdrop-filter: blur(36px);
  box-shadow: 0 6px 20px 0 rgba(67, 67, 75, 0.2);
  line-height: 16px;
`;
// prettier-ignore
export const Feature = styled.div<{ recent: boolean, btmLine: boolean }>`
  margin: 0 0 10px;
  border-bottom: ${props => props.btmLine && '1px solid #eaeaea'};
  section {
    padding: ${props => (props.recent ? '0 26px 10px' : '0 26px 0')};
    display: block;
    color: #b6b6b6;
  }
`;
export const Item = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 26px;
  color: #333333;
  svg {
    margin: 0 20px 0 0;
  }
  :hover {
    background-color: #f5fdfe;
    cursor: pointer;
  }
`;
