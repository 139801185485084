import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';

import NestedSideNav from 'src/components/common/nav/side-nav/NestedSideNav';
import { PrivateRoute } from 'src/routes/PrivateRoute';

import {
  IGetPatentSubscription,
  IGetPatentSubscriptionShare,
  IGetPatentCollectionCategory,
} from 'src/types/api/ShowcaseApiTypes';
import Recommend from './Recommend';
import Latest from './Latest';
import Collection from './Collection';
import Track from './Track';
import IndustrialTrends from 'src/components/trend/industrialTrends/IndustrialTrendsLayout';

import { connect } from 'react-redux';
import { ReduxAppState } from 'src/redux/reducers';
import { ITokenPayload } from 'src/apps/auth/Auth';
import Analysis from 'src/components/opendata/Analysis';

const Container = styled.div`
  margin: 0 auto;
  padding: 16px 140px 0 140px;
  max-width: 1440px;
  display: flex;
  align-items: flex-start;
  align-content: space-between;
  flex-flow: row;
`;
const SideNavStyle = styled.div`
  width: 180px;
  display: inline-flex;
`;
const ContentStyle = styled.div`
  padding: 0 0 0 16px;
  width: calc(100% - 180px);
`;

// For whom has no collect or track, including Guest
const EMPTY_COLLECT = [{ id: -1, name: '收藏說明', collected: false }];
const EMPTY_TRACK = [
  {
    id: -1,
    createUser: null,
    createTime: null,
    updateUser: null,
    updateTime: null,
    systemAccountId: null,
    name: '追蹤說明',
    industryTechnologyId: -1,
    industryTechnologyName: null,
    keyword: '無',
    notification: false,
  },
];
const EMPTY_SHARE = [
  {
    id: -1,
    createUser: null,
    createTime: null,
    updateUser: null,
    updateTime: null,
    systemAccountId: null,
    name: '共享說明',
    industryTechnologyId: -1,
    industryTechnologyName: null,
    keyword: '無',
    notification: false,
  },
];

interface IMenu {
  feature: string;
  path: string;
  subfeatures: boolean;
}

interface IReduxMappingProps {
  member: ITokenPayload;
  isLogin: boolean;
}
interface IProps extends RouteComponentProps, IReduxMappingProps {}

function ShowcaseLayout(props: IProps) {
  const sector = '專利專區';
  const features: Array<IMenu> = [
    { feature: '推薦專利', path: '/recommend', subfeatures: false },
    { feature: '最新專利', path: '/new', subfeatures: false },
    { feature: '我的收藏', path: '/collections', subfeatures: true },
    { feature: '專利追蹤', path: '/tracking', subfeatures: true },
    { feature: '共享追蹤', path: '/shares', subfeatures: true },
    { feature: '專利趨勢統計', path: '/industrialTrends', subfeatures: false },
  ];

  const [collects, setCollects] = useState<Array<IGetPatentCollectionCategory>>([]);
  const [tracks, setTracks] = useState<Array<IGetPatentSubscription>>([]);
  const [shares, setShares] = useState<Array<IGetPatentSubscriptionShare>>([]);
  const [fetchFinished, setFetchFinished] = useState(false);
  interface IUpdateInfo {
    activate: boolean;
    path?: string;
    prevLength?: Array<number>;
  }
  const [forceUpdate, setForceUpdate] = useState<IUpdateInfo>({ activate: false });
  const [stopUpdate, setStopUpdate] = useState(false);
  useEffect(() => {
    const fetchPersonalMenu = async (path?: string, prevLength?: Array<number>) => {
      let redirectUrl = '';

      const handleError403 = (error: any) => {
        if (error.response && error.response.status === 403) {
          console.log(error.response.data.resultMessage);
        } else {
          errorHandler(error);
        }
      };

      const handleUrlAfterDelete = (nextPromiseData: Array<any>, prevLength: Array<number>) => {
        const pageArr = ['/showcase/collections', '/showcase/tracking', '/showcase/shares'];
        pageArr.forEach((pageUrl, idx) => {
          if (nextPromiseData[idx].data.length < prevLength[idx]) {
            const nextData = nextPromiseData[idx].data[0];
            redirectUrl = nextData ? pageUrl + '/' + nextData.id : pageUrl;
          }
        });

        // clear parameter
        setForceUpdate(prev => ({ activate: prev.activate }));
      };

      try {
        const promiseCol = Api().get('patent/collection/category');
        const promiseTra = Api().get('patent/subscription');
        const promiseSha = Api().get('patent/subscription/share');
        const resArr = await Promise.all([promiseCol, promiseTra, promiseSha]);

        setCollects(resArr[0].data);
        setTracks(resArr[1].data);
        setShares(resArr[2].data);

        setStopUpdate(true);
        setFetchFinished(true);
        path && props.history.replace(path);
        prevLength && handleUrlAfterDelete(resArr, prevLength);
        redirectUrl !== '' && props.history.replace(redirectUrl);
      } catch (error) {
        handleError403(error);
      }
    };

    if (props.member.info.guest) {
      setFetchFinished(true);
    } else if (!stopUpdate) {
      fetchPersonalMenu(forceUpdate.path, forceUpdate.prevLength);
    }
  }, [forceUpdate, stopUpdate, props.history, props.member.info.guest]);

  const handleSubmit = (option: string, id: number, data: any) => {
    setStopUpdate(false);
    switch (option) {
      case 'EditCollectName':
        setForceUpdate(prev => ({
          activate: !prev.activate,
          path: `/showcase/collections/${id}`,
        }));
        break;
      case 'EditTrackName':
        setForceUpdate(prev => ({
          activate: !prev.activate,
          path: `/showcase/tracking/${id}`,
        }));
        break;
      case 'DeleteCollect':
      case 'DeleteTrack':
      case 'DeleteTrackShare':
        setForceUpdate(prev => ({
          activate: !prev.activate,
          prevLength: [collects.length, tracks.length, shares.length],
        }));
        break;
      case 'EditPatentCollect':
      case 'DeletePatentCollect':
        setForceUpdate(prev => ({ activate: !prev.activate }));
        break;
      default:
        break;
    }
  };

  return (
    <Container>
      {fetchFinished && (
        <>
          <SideNavStyle>
            {
              <NestedSideNav
                sector={sector}
                features={features}
                subFeatArr={[
                  {
                    idx: 2,
                    items: collects.map(item => ({
                      id: item.id,
                      name: item.name,
                    })),
                  },
                  {
                    idx: 3,
                    items: tracks.map(item => ({
                      id: item.id,
                      name: item.name,
                    })),
                  },
                  {
                    idx: 4,
                    items: shares.map(item => ({
                      id: item.id,
                      name: item.name,
                    })),
                  },
                ]}
                {...props}
              />
            }
          </SideNavStyle>

          <ContentStyle>
            <Switch>
              <Route path={props.match.url + features[0].path} component={Recommend} />
              <Route path={props.match.url + features[1].path} component={Latest} />
              {/* 若無資料則顯示收藏說明頁面 */}
              {collects.length === 0 && (
                <PrivateRoute
                  isLogin={props.isLogin}
                  path={props.match.url + features[2].path}
                  exact
                  component={() => (
                    <Collection
                      feature={features[2].feature}
                      name={EMPTY_COLLECT[0].name}
                      id={EMPTY_COLLECT[0].id}
                      handleSubmit={handleSubmit}
                    />
                  )}
                />
              )}
              {/* 若有資料則顯示收藏內容 */}
              {collects.length > 0 &&
                collects.map(item => (
                  <PrivateRoute
                    key={item.id}
                    isLogin={props.isLogin}
                    path={props.match.url + features[2].path + '/' + item.id}
                    exact
                    component={() => (
                      <Collection
                        feature={features[2].feature}
                        name={item.name}
                        id={item.id}
                        handleSubmit={handleSubmit}
                      />
                    )}
                  />
                ))}
              {/* 若無資料則顯示追蹤說明頁面 */}
              {tracks.length === 0 && (
                <PrivateRoute
                  isLogin={props.isLogin}
                  // `:id` 提供 for EDM 在未登入情況下，可停留在原 URL。
                  path={props.match.url + features[3].path + '/:id(\\d+)?'}
                  exact
                  component={() => (
                    <Track
                      isShare={false}
                      feature={features[3].feature}
                      name={EMPTY_TRACK[0].name}
                      keyword={EMPTY_TRACK[0].keyword}
                      trackId={EMPTY_TRACK[0].id}
                      handleSubmit={handleSubmit}
                    />
                  )}
                />
              )}
              {/* 若有資料則顯示追蹤內容 */}
              {tracks.length > 0 &&
                tracks.map(item => (
                  <PrivateRoute
                    key={item.id}
                    isLogin={props.isLogin}
                    path={props.match.url + features[3].path + '/' + item.id}
                    exact
                    component={() => (
                      <Track
                        isShare={false}
                        feature={features[3].feature}
                        name={item.name}
                        keyword={item.keyword}
                        trackId={item.id}
                        handleSubmit={handleSubmit}
                      />
                    )}
                  />
                ))}
              {/* 若無資料則顯示共享追蹤說明頁面 */}
              {shares.length === 0 && (
                <PrivateRoute
                  isLogin={props.isLogin}
                  path={props.match.url + features[4].path}
                  exact
                  component={() => (
                    <Track
                      isShare={true}
                      feature={features[4].feature}
                      name={EMPTY_SHARE[0].name}
                      keyword={EMPTY_SHARE[0].keyword}
                      trackId={EMPTY_SHARE[0].id}
                      handleSubmit={handleSubmit}
                    />
                  )}
                />
              )}
              {/* 若有資料則顯示共享追蹤內容 */}
              {shares.length > 0 &&
                shares.map((item, idx) => (
                  <PrivateRoute
                    key={item.id}
                    isLogin={props.isLogin}
                    path={props.match.url + features[4].path + '/' + item.id}
                    exact
                    component={() => (
                      <Track
                        isShare={true}
                        shareUser={shares[idx].systemAccountName}
                        feature={features[4].feature}
                        name={item.name}
                        keyword={item.keyword}
                        trackId={item.id}
                        handleSubmit={handleSubmit}
                      />
                    )}
                  />
                ))}
              <Route
                path={props.match.url + features[5].path + '/:id'}
                component={IndustrialTrends}
              />
              <Route path={props.match.url + features[5].path} component={IndustrialTrends} />
              <Route path={props.match.url + '/analysis'} component={Analysis} />
              <Route component={() => <Redirect to={props.match.url + features[0].path} />} />
            </Switch>
          </ContentStyle>
        </>
      )}
    </Container>
  );
}

const mapStateToProps = (state: ReduxAppState) => {
  return {
    member: state.memberReducer.memberPayload,
    isLogin: state.memberReducer.authData.isLogin,
  };
};

export default connect(
  mapStateToProps,
  null,
)(ShowcaseLayout);
