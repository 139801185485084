import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';

import useOutSideClick from 'src/hooks/useOutSideClick';
import { IIndustrialClassType } from 'src/types/IndustryTypes';
import {
  blue7,
  iceblue1,
  iceblue6,
  ultrawhite,
  ultrawhitergba,
  ultrablackrgba,
  iceblue2,
  white,
} from 'src/style/theme/Color';
import { zToolbar } from 'src/style/theme/Z-Index';

const DropdownContainer = styled.div`
  position: relative;
  min-width: 0;
  z-index: ${zToolbar};
`;
const DropdownButton = styled.div<{ leftRadius?: boolean; rightArrow?: boolean }>`
  width: 100%;
  height: 60px;
  padding: ${props => (props.rightArrow ? '8px 24px 8px 16px' : '8px 16px')};
  border-radius: ${props => props.leftRadius && '4px 0 0 4px'};
  background-color: ${white};
  backdrop-filter: blur(8px);
  color: ${iceblue1};
  > span {
    display: block;
    font-size: 13px;
    color: ${iceblue6};
  }
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  :hover {
    cursor: pointer;
  }
  ${props =>
    props.rightArrow &&
    css`
      :before,
      :after {
        content: '';
        left: calc(100% - 12px);
        top: 0;
        border: solid transparent;
        height: 0;
        width: 0;
        position: absolute;
        border-width: 30px 0 30px 12px;
      }
      :after {
        border-color: transparent transparent transparent ${white};
      }
      :before {
        border-color: transparent transparent transparent ${iceblue2};
      }
    `}
`;

const CheckedItemNames = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Menu = styled.div`
  position: absolute;
  margin-top: 8px;
  box-shadow: 0 6px 20px 0 ${ultrablackrgba(0.1)};
  background-color: ${ultrawhite};
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
  :after {
    content: '';
    position: absolute;
    background-image: linear-gradient(to bottom, ${ultrawhitergba(0)}, ${ultrawhite});
    height: 30px;
    width: 100%;
    bottom: 0;
  }
`;
const MenuItems = styled.div`
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0 !important;
  }
  ::-ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  padding: 4px 0 16px 0;
`;
const MenuItem = styled.div`
  background-color: ${ultrawhite};
  color: ${iceblue2};
  padding: 8px 16px;
  min-height: 38px;
  : hover {
    background-color: ${blue7};
  }
`;

interface IProps {
  data: Array<IIndustrialClassType>;
  dataTitle: string;
  selectedItem: IIndustrialClassType | null;
  setSelectedItem: (items: IIndustrialClassType) => void;
  // 樣式設定
  withRightArrow?: boolean;
  withLeftRadius?: boolean;
  withSelectAll?: boolean;
}

const Dropdown: React.FC<IProps> = ({
  data,
  dataTitle,
  selectedItem,
  setSelectedItem,
  withRightArrow,
  withLeftRadius,
  withSelectAll,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const node = useOutSideClick({
    show: isOpen,
    setShow: setIsOpen,
  });

  const selectAllItem: Array<IIndustrialClassType> = [
    {
      id: 0,
      description: '所有' + dataTitle,
    },
  ];

  const dropdownData: Array<IIndustrialClassType> = withSelectAll
    ? selectAllItem.concat(...data)
    : data;

  const handleSelect = (item: IIndustrialClassType) => {
    setIsOpen(!isOpen);
    setSelectedItem(item);
  };

  return (
    <DropdownContainer ref={node as any}>
      <DropdownButton
        leftRadius={withLeftRadius}
        rightArrow={withRightArrow}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>{dataTitle}</span>
        <div>
          <CheckedItemNames>
            {selectedItem
              ? selectedItem.description
              : dropdownData.length > 0
              ? dropdownData[0].description
              : null}
          </CheckedItemNames>
          {isOpen ? <FaCaretUp color={iceblue1} /> : <FaCaretDown color={iceblue1} />}
        </div>
      </DropdownButton>
      {isOpen && (
        <Menu>
          <MenuItems>
            {dropdownData.map(item => (
              <MenuItem key={item.id} onClick={() => handleSelect(item)}>
                {item.description}
              </MenuItem>
            ))}
          </MenuItems>
        </Menu>
      )}
    </DropdownContainer>
  );
};
export default Dropdown;
