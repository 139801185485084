import {
  OPEN_ALERT_MODAL,
  CLOSE_ALERT_MODAL,
  OPEN_LOGIN_ALERT_MODAL,
  OPEN_LOGIN_FORM_MODAL,
  CLOSE_LOGIN_MODAL,
  OPEN_CUSTOM_MODAL,
  CLOSE_CUSTOM_MODAL,
  OPEN_REDIRECT_MODAL,
  CLOSE_REDIRECT_MODAL,
  ModalActionTypes,
} from 'src/redux/types/modalTypes';
import { IAlertModal, IRedirectModal, CustomModalType } from 'src/types/ModalTypes';

export function openAlertModal(alertModal: IAlertModal): ModalActionTypes {
  return {
    type: OPEN_ALERT_MODAL,
    alertModal: alertModal,
  };
}

export function closeAlertModal(): ModalActionTypes {
  return {
    type: CLOSE_ALERT_MODAL,
  };
}

export function openLoginAlertModal(isFromRoute: boolean): ModalActionTypes {
  return {
    type: OPEN_LOGIN_ALERT_MODAL,
    isFromRoute: isFromRoute,
  };
}

export function openLoginFormModal(): ModalActionTypes {
  return {
    type: OPEN_LOGIN_FORM_MODAL,
  };
}

export function closeLoginModal(): ModalActionTypes {
  return {
    type: CLOSE_LOGIN_MODAL,
  };
}

export function openCustomModal(
  customModalType: CustomModalType,
  customModalId?: number,
): ModalActionTypes {
  return {
    type: OPEN_CUSTOM_MODAL,
    customModalType: customModalType,
    customModalId: customModalId,
  };
}

export function closeCustomModal(): ModalActionTypes {
  return {
    type: CLOSE_CUSTOM_MODAL,
  };
}

export function openMobileRedirectModal(path: string): ModalActionTypes {
  return openRedirectModal({
    path: path,
    message: '您現在正要前往的頁面可能不適合使用手機瀏覽，建議使用電腦開啟。',
  });
}

export function openRedirectModal(redirectModal: IRedirectModal): ModalActionTypes {
  return {
    type: OPEN_REDIRECT_MODAL,
    redirectModal: redirectModal,
  };
}

export function closeRedirectModal(shouldRedirect?: boolean): ModalActionTypes {
  return {
    type: CLOSE_REDIRECT_MODAL,
    shouldRedirect: !!shouldRedirect,
  };
}
