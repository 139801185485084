import styled, { css } from 'styled-components';
import {
  blue1,
  blue1rgba,
  blue2,
  blue3rgba,
  blue4,
  blue6rgba,
  iceblue1,
  iceblue5,
  iceblue6,
  iceblue8,
  black1,
} from 'src/style/theme/Color';

// Patent right toolbar
export const ToolbarContainer = styled.div<{ iconOnly: boolean }>`
  padding: 8px 0;
  border-radius: 4px;
  box-shadow: 0 5px 20px -5px ${blue1rgba(0.3)};
  background-image: linear-gradient(157deg, #1f8ba3, #323846);
  position: fixed;
  z-index: ${props => (props.iconOnly ? '70' : '50')};
  top: 96px;
  /* TODO: 雙欄模式時又打開蒐藏,筆記,分享之格式調整 */
  right: ${props => (props.iconOnly ? '-100px' : '0px')};
  > div {
    padding: ${props => (props.iconOnly ? '0 8px' : '0 16px')};
  }
  :hover {
    ${props => props.iconOnly && 'right: 0'};
    > div {
      padding: 0 16px;
    }
  }
`;

export const ToolbarItem = styled.div<{ active: boolean; disable?: boolean }>`
  color: #ffffff;
  line-height: 36px;
  background-color: ${props => props.active && blue3rgba(0.2)};
  :hover {
    cursor: pointer;
    background-color: ${blue3rgba(0.2)};
  }
  opacity: ${props => props.disable && 0.4};
`;

export const ToolbarIcon = styled.span`
  vertical-align: middle;
  svg {
    margin-right: 8px;
  }
`;

export const DividingLine = styled.div`
  width: inherit;
  height: 1px;
  margin: 8px 0;
  background-color: rgba(255, 255, 255, 0.3);
  position: relative;
`;

export const DividingLineBlack = styled.div`
  width: inherit;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  position: relative;
`;

export const ToolbarPaneContainer = styled.div<{ offset: string }>`
  width: 304px;
  border-radius: 4px;
  background-image: linear-gradient(153deg, ${blue2}, ${blue1});
  color: #ffffff;
  position: absolute;
  top: 0;
  right: 128px;
  padding: 16px 32px 32px 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: default;
  ::after {
    border-color: transparent transparent transparent
      ${props => (props.offset === '15px' ? '#066981' : '#03586F')};
    border-style: solid;
    border-width: 8px;
    content: '';
    height: 0px;
    right: -16px;
    position: absolute;
    top: ${props => props.offset}; /* 18，56，94 */
    width: 0px;
  }
`;

export const ToolbarPaneBodyDiv = styled.div`
  width: 100%;
`;

export const ToolbarPaneTitle = styled.div`
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
  svg {
    vertical-align: baseline;
  }
`;

export const ToolbarPaneScrollDiv = styled.div<{ maxHeight?: string }>`
  max-height: ${props => (props.maxHeight ? props.maxHeight : 'calc(100vh - 160px)')};
  min-height: 32px;
  overflow-x: hidden;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    box-shadow: inset 0 0 8px 8px ${blue6rgba(0.5)};
    border: solid 2px transparent;
  }
  scrollbar-width: thin;
`;

export const ToolbarPaneField = styled.div`
  margin-bottom: 16px;
  line-height: normal;
  > p {
    margin: 16px 0 4px 0;
  }
  textarea {
    resize: none;
    width: 100%;
    min-height: 230px;
    border-radius: 4px;
    background-color: #ffffff;
    color: ${iceblue1};
    padding: 8px 16px 8px 16px;
    margin-top: 8px;
    border: solid 1px ${iceblue6};
  }
`;

export const ToolbarPaneDescription = styled.div`
  width: 100%;
  opacity: 0.8;
  text-align: left;
  line-height: normal;
  font-size: 14px;
`;

export const ToolbarPaneDividingLine = styled(DividingLine)`
  right: 32px;
  margin: 16px 0;
  width: calc(100% + 64px);
`;

export const ToolbarPaneFooterDiv = styled.div`
  width: 100%;
  > * {
    margin-top: 16px;
  }
`;

export const ToolbarPaneButtonArea = styled.div`
  text-align: center;
`;

export const InputText = styled.div<{ withButton?: boolean; error: boolean }>`
  input[type='text'] {
    width: 100%;
    height: 32px;
    border-radius: 4px;
    border: solid 1px ${props => (props.error ? '#ff9639' : iceblue6)};
    background-color: #ffffff;
    padding: ${props => (props.withButton ? '0 32px 0 8px' : '0 8px')};
    :focus {
      outline: 0;
    }
    &::placeholder {
      color: ${iceblue5};
    }
  }
  /*input內按鈕樣式*/
  > span {
    color: #077790;
    background-color: #ffffff;
    margin-left: -24px;
    cursor: pointer;
  }
  /*下方錯誤訊息樣式*/
  > p {
    color: #ff9639;
  }
`;

export const SpaceBetween = styled.div<{ alignTop?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: ${props => (props.alignTop ? 'flex-start' : 'center')};
`;

export const OpacityMask = styled.div`
  opacity: 0.8;
  cursor: pointer;
`;

export const SelectAllCheckbox = styled.span`
  margin-left: 16px;
  font-weight: bold;
  color: ${blue4};
  cursor: pointer;
`;

export const NotePost = styled.div`
  width: 100%;
  border-radius: 4px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  padding: 8px 16px 16px 16px;
  color: ${blue1};
  margin: 8px 0 16px 0;
  > div {
    margin-bottom: 8px;
  }
`;

export const NoteUser = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin: 0 8px 0 0;
  }
`;

export const NoteDate = styled.div`
  color: ${iceblue5};
`;

export const Stars = styled.div`
  svg {
    font-size: 30px;
    margin: 2px;
  }
`;

// Patent content
export const PatentHalfPageTitle = styled.div`
  width: 100%;
  padding: 24px 32px 0 32px;
  color: ${blue2};
  * {
    font-size: 18px;
    font-weight: 600;
  }
`;

export const TextBox = styled.div`
  padding: 16px 32px 16px 32px;
`;

export const TextBoxTitle = styled.div`
  color: ${blue1};
  font-weight: bold;
  font-size: 18px;
  padding-bottom: 8px;
  border-bottom: 1px solid ${iceblue8};
`;

export const TextBoxContent = styled.div<{ color?: string }>`
  padding-top: 10px;
  line-height: 2;
  word-wrap: break-word;
  color: ${props => props.color || black1};
`;

export const TextBoxOl = styled.ol<{ hideNum?: boolean }>`
  padding: 10px 0 0 24px;
  > li {
    ${props =>
      props.hideNum &&
      css`
        list-style-type: none;
        /*首行凸排，讓原有編號類似li效果 */
        text-indent: -1.5em;
      `};
  }
`;

export const InfoArea = styled.div<{ oneColumn: boolean }>`
  padding: 16px 32px 0 32px;
  width: 100%;
  display: ${props => (props.oneColumn ? 'block' : 'flex')};
  line-height: 2;
`;

export const InfoAreaColumn = styled.div<{ basis?: string }>`
  flex-basis: ${props => props.basis};
`;

export const InfoBox = styled.div`
  display: flex;
`;

export const InfoBoxTitle = styled.div`
  color: ${blue1};
  font-weight: bold;
  min-width: 60px;
`;

export const InfoBoxContent = styled.div`
  p {
    margin: 0;
  }
`;

export const InfoIpcBox = styled.div<{ inline?: boolean }>`
  padding: 0 32px 16px 32px;
  line-height: 2;
  display: ${props => (props.inline ? 'flex' : 'auto')};
`;

export const PatentModalTitle = styled.div`
  width: 100%;
  padding: 0 32px 0 32px;
  color: #003f54;
  * {
    font-size: 24px;
    font-weight: 600;
  }
`;

export const PatentModalImgs = styled.div`
  padding: 16px 25px 0 28px;
  margin: auto;
  img {
    display: inline-block;
    margin-left: 6px;
    width: 158px;
    height: 158px;
    background-color: #ffffff;
    border: 1px solid ${iceblue8};
    object-fit: contain;
  }
`;

export const Anchor = styled.cite`
  display: block;
  position: relative;
  top: -200px;
  visibility: hidden;
`;

export const FuncWrapper = styled.div`
  cursor: pointer;
`;

// Patent compare pane
export const ShowDiv = styled.div<{ show: boolean }>`
  display: ${props => (props.show ? 'auto' : 'none')};
`;

export const StickyHeaderWrapper = styled.div`
  width: inherit;
  position: fixed;
  z-index: 10;
`;

export const ComparedPaneContainer = styled.div<{ notSticky?: boolean; notFound?: boolean }>`
  z-index: 60;
  width: calc(50% - 144px);
  /* 雙欄檢視高度固定為頁面高扣除導覽列高，並fixd確保scroll到任何位置皆可正常顯示內容 */
  height: ${props => (props.notSticky ? '1600px' : 'calc(100vh - 68px)')};
  position: ${props => (props.notSticky ? 'absolute' : 'fixed')};
  border-radius: 4px;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  background-image: ${props => props.notFound && 'linear-gradient(138deg, #2ec3c2, #0cafcc)'};
  p {
    /*錯誤訊息樣式*/
    ${props => props.notFound && 'color:#ffffff !important'}
  }
`;

export const ComparedPaneHeader = styled.div`
  border-radius: 4px 4px 0 0;
  background-image: linear-gradient(93deg, #077790, #003f54);
  color: #ffffff;
  padding: 8px 16px 8px 16px;
  height: 38px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /*禁止標題及按鈕換行*/
  > p {
    flex-shrink: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  > div {
    flex-shrink: 0;
    line-height: 100%;
  }
`;

export const ImgTitleContainer = styled.div`
  display: flex;
  justify-content: center;
  justify-items: center;
  span {
    margin-right: 1em;
  }
`;

export const ComparedPaneSubHeader = styled.div`
  background-color: #edf6f7;
  padding: 8px 16px 8px 16px;
  display: flex;
  min-height: 47px;
  align-items: center;
  > input {
    height: 31px;
    width: 100%;
    border-radius: 4px;
    border: solid 1px #b3b9bc;
    background-color: #ffffff;
    padding: 0 16px;
    :focus {
      outline: none;
    }
  }
`;

export const ComparedPaneSubHeaderTd = styled.div<{ basis?: string }>`
  flex-basis: ${props => props.basis};
  padding: 0 24px;
`;

export const PatentListDropdown = styled.div`
  line-height: 31px;
  width: 100%;
  border-radius: 4px;
  border: solid 1px #b3b9bc;
  background-color: #ffffff;
  padding-left: 8px;
`;

export const FadeoutMask = styled.div`
  width: 100%;
  height: 30px;
  background-image: linear-gradient(to bottom, #ffffff, rgba(255, 255, 255, 0));
`;

export const PatentBody = styled.div`
  height: calc(100% - 86px);
  width: 100%;
  border-radius: 4px;
  margin-top: 86px;
  overflow-x: hidden;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    box-shadow: inset 0 0 8px 8px #b5e4ec;
    border: solid 2px transparent;
  }
  > p {
    padding: 16px 28px;
    width: 100%;
    color: #979ea1;
  }
`;

export const ComparedPaneTable = styled.div`
  width: 100%;
  display: table;
  border-collapse: collapse;
`;

export const ComparedPaneTrTitle = styled(ComparedPaneSubHeader)`
  justify-content: space-between;
  padding: 16px 28px;
  color: #003f54;
  background-color: #f5fdfe;
  font-weight: bold;
  svg {
    margin-right: 8px;
  }
  /* 「顯示全部」連結樣式 */
  > span {
    color: #979ea1;
    font-weight: normal;
    cursor: pointer;
  }
`;

export const ComparedPaneTr = styled.div<{ linkable?: boolean }>`
  background-color: #ffffff;
  display: table-row;
  border-bottom: 1px solid #eceff1;
  cursor: ${props => props.linkable && 'pointer'};
  b {
    /*專利號碼樣式*/
    color: ${props => (props.linkable ? '#077790' : '#5F686C')};
  }
  &:hover {
    background-color: #f5fdfe;
  }
`;

export const ComparedPaneTd = styled.div<{ width?: string }>`
  width: ${props => props.width};
  display: table-cell;
  padding: 16px 28px;
  min-height: 36px;
`;

export const Icon = styled.span<{ margin?: string }>`
  margin: ${props => props.margin};
  line-height: 100%;
  cursor: pointer;
`;

export const ColorSpan = styled.span<{ color: string }>`
  color: ${props => props.color};
`;
