import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router-dom';
import { FaMinus } from 'react-icons/fa';

import Content from 'src/components/ui/content/Content';
import { Row } from 'src/style/PatentSearchStyle';
import DropzoneAndCtrl from 'src/components/search/DropzoneAndCtrl';

import { LocAutocomplete } from 'src/components/search/loc/LocAutocomplete';
import { ILocBasic } from 'src/types/loc';

import { alertMessage } from 'src/utils/ModalUtils';
import { SystemMessage } from 'src/apps/applicationMessages';
import {
  blue6,
  pink6,
  pink8,
  iceblue1,
  iceblue5,
  iceblue6,
  iceblue8,
  ultrawhite,
  blue3,
} from 'src/style/theme/Color';

import Tooltip from '../ui/interactive/Tooltip';
import { Api } from 'src/api/helpers/apiBase';
import { IPatentStatRange } from 'src/types/api/IndexApiTypes';
import { dateFormatter } from 'src/utils/Formatter';

const ButtonTooltip = styled(Tooltip)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  margin-right: 4px;
  :hover {
    cursor: pointer;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  position: absolute;
  left: 101%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
`;

const Label = styled.label`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const InputContainer = styled.div`
  margin: 0 32px 0 0;
  width: 80%;
  min-height: 30px;
  position: relative;
  display: flex;

  > span {
    padding: 0 0 0 17px;
    color: ${pink6};
  }
`;
const Input = styled.input.attrs<{ width: string }>({ type: 'text' })`
  width: ${props => props.width};
  padding: 5px 16px;
  border-radius: 4px;
  border: solid 1px ${iceblue5};
  background-color: ${ultrawhite};
  color: ${iceblue1};
  :placeholder {
    color: ${iceblue6};
  }
  :disabled {
    cursor: not-allowed;
  }
`;
const Column = styled.div<{ width: string }>`
  margin-right: 32px;
  width: ${props => props.width};
  color: ${iceblue1};
`;

const LocItem = styled.div`
  margin: 0 4px 0 0;
  padding: 4px 4px;
  border-radius: 4px;
`;
const Classes = styled(LocItem)`
  flex-shrink: 0;
  background-color: ${pink8};
  text-align: center;
`;
const TwLoc = styled(LocItem)`
  background-color: ${blue6};
`;
const EnLoc = styled(LocItem)`
  margin: 0;
  background-color: ${iceblue8};
`;

export const getLocClasses = (loc: ILocBasic) => {
  if (loc) {
    const locClass = loc.locClasses.substring(0, 2);
    const locSubclass = loc.locClasses.substring(2, 4);
    return `${locClass}-${locSubclass}`;
  }
  return '';
};
export const getLocDescription = (loc: ILocBasic | undefined) => {
  return loc ? (
    <>
      <Classes>{getLocClasses(loc)}</Classes>
      <TwLoc>{loc.twLoc}</TwLoc>
      {loc.enLoc && <EnLoc>{loc.enLoc}</EnLoc>}
    </>
  ) : (
    <></>
  );
};

const SELECTED_LOC_MAXIMUM = 3;

/** 以圖找圖 */
const SearchByImage: React.FC<RouteComponentProps> = props => {
  const [locInput, setLocInput] = useState('');
  const [showLoc, setShowLoc] = useState(false);
  const [locList, setLocList] = useState<Array<ILocBasic>>([]);
  const [dateRange, setDateRange] = useState<IPatentStatRange>();

  useEffect(() => {
    const fetchDateRange = async () => {
      await Api()
        .get('patent/similar/image/dateRange')
        .then(res => setDateRange(res.data));
    };
    fetchDateRange();
  }, []);

  const handleSuggestionClick = (loc: ILocBasic) => {
    // Array.includes 無法僅針對 ILocBasic.locClasses 比較
    if (locList.find(selected => selected.locClasses === loc.locClasses) === undefined) {
      setLocList(prev => [...prev, loc]);
      setLocInput('');
    } else {
      alertMessage(SystemMessage.LOC_SELECTED);
    }
  };
  const handleSuggestionReset = (option: { shouldClearInput: boolean }) => {
    option.shouldClearInput && setLocInput('');
    setLocList([]);
  };

  const locMeetMaximum = locList.length >= SELECTED_LOC_MAXIMUM;
  const dateRangeString =
    dateRange && dateRange.startDate && dateRange.endDate
      ? dateFormatter(dateRange.startDate) + ' ~ ' + dateFormatter(dateRange.endDate)
      : '';

  return (
    <Content
      sector="專利檢索"
      feature="以圖找圖"
      subtitle={`資料範圍：${dateRangeString} 本國設計專利`}
    >
      <>
        <Row>
          <Label>
            <span>LOC =</span>
            <InputContainer>
              <Input
                width="100%"
                value={locInput}
                onChange={event => setLocInput(event.target.value)}
                placeholder={
                  locMeetMaximum
                    ? 'LOC 選取數量已達上限，若要選擇其他分類請先取消任意已選取的分類'
                    : '範例1: 麵包, 範例2: 01-01'
                }
                disabled={locMeetMaximum}
              />
              {!locMeetMaximum && (
                <LocAutocomplete
                  isShow={showLoc}
                  setShow={setShowLoc}
                  inputText={locInput}
                  handleSuggestionClick={handleSuggestionClick}
                />
              )}
            </InputContainer>
          </Label>
        </Row>
        {locList.length > 0 ? (
          locList.map((item, idx) => (
            <Row key={idx}>
              <Label>
                <span />
                <InputContainer>
                  {getLocDescription(item)}
                  <ButtonsContainer>
                    <ButtonTooltip
                      overlay="取消選取"
                      placement="top"
                      onClick={() =>
                        setLocList(prev => prev.filter((locObj, objIdx) => objIdx !== idx))
                      }
                    >
                      <FaMinus size={16} color={blue3} />
                    </ButtonTooltip>
                  </ButtonsContainer>
                </InputContainer>
              </Label>
            </Row>
          ))
        ) : (
          <Row>
            <Label>
              <span />
              <InputContainer>
                <span>尚未選取國際工業設計分類，將會自所有分類中搜尋</span>
              </InputContainer>
            </Label>
          </Row>
        )}
        <Row alignItems="flex-start">
          <Column width="10%">上傳圖片</Column>
          <Column width="80%">
            <DropzoneAndCtrl
              resultPageUrl={'/result/searchByImage'}
              acceptExtension={['png', 'jpeg', 'gif']}
              paramToSubmit={{ locClasses: locList }}
              resetButtonOnClick={() => handleSuggestionReset({ shouldClearInput: true })}
              {...props}
            />
          </Column>
        </Row>
      </>
    </Content>
  );
};

export default SearchByImage;
