import React from 'react';
import styled from 'styled-components';
import useOutSideClick from 'src/hooks/useOutSideClick';

// Menu
const Container = styled.div`
  position: relative;
`;
const DropdownMenu = styled.div`
  z-index: 100;
  padding: 4px 0;
  width: 120px;
  right: -8px;
  position: absolute;
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
`;
const MenuItem = styled.div`
  padding: 0 16px;
  height: 28px;
  color: #424d52;
  font-size: 14px;
  font-style: normal;
  cursor: pointer;
  &:hover {
    background-color: #edf6f7;
  }
`;

interface IProps {
  show: boolean;
  setShow: (show: boolean) => void;
  sortRating: () => void;
  sortDate: () => void;
}

const SortActionMenu: React.FC<IProps> = props => {
  const node = useOutSideClick(props);

  return (
    <Container ref={node as any}>
      <DropdownMenu>
        <MenuItem
          onClick={() => {
            props.sortRating();
            props.setShow(false);
          }}
        >
          評等排序
        </MenuItem>
        <MenuItem
          onClick={() => {
            props.sortDate();
            props.setShow(false);
          }}
        >
          時間排序
        </MenuItem>
      </DropdownMenu>
    </Container>
  );
};

export default SortActionMenu;
