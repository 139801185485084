import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router';
import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import { IErrorResponseData } from 'src/apps/error-handler/ErrorHandler';

import * as Color from 'src/style/theme/Color';
import Content from 'src/components/ui/content/Content';
import Footer from 'src/components/common/Footer';

const Container = styled.div`
  text-align: center;
`;
const ResponseMessage = styled.div<{ success: boolean }>`
  padding: 16px 26px;
  display: inline-block;
  border-radius: 4px;
  background-color: ${props => (props.success ? Color.blue7 : Color.alarm8)};
`;
const Result = styled.div<{ success: boolean }>`
  color: ${props => (props.success ? Color.blue3 : Color.pink1)};
  font-size: 24px;
  font-weight: bold;
`;
const SuggestMessage = styled.div`
  padding: 8px 16px;
  a {
    color: ${Color.blue3};
  }
`;

const FooterStyle = styled.div`
  margin: 20px 0 0;
  width: 100vw;
  left: 0;
  position: absolute;
  border-top: 1px solid ${Color.iceblue8};
  background-color: #ffffff;
`;

interface IProps {
  identifier: string;
}

function EmailConfirm(props: RouteComponentProps<IProps>) {
  let [patchFinished, setPatchFinished] = useState(false);
  let [patchSuccess, setPatchSuccess] = useState(false);
  const [message, setMessage] = useState<string>();

  useEffect(() => {
    const identifier = props.location.search.replace('?', '');
    const SUCCESS_MSG = '已成功確認，歡迎繼續使用本網站。';
    const patchConfirm = async () => {
      try {
        const res = await Api({ 'Content-Type': 'application/json' }).patch(
          'email/confirm',
          identifier,
        );

        if (res.status === 200) {
          setPatchSuccess(true);
          setMessage(SUCCESS_MSG);
        }
      } catch (error) {
        if (error.response && error.response.data) {
          setMessage((error.response.data as IErrorResponseData).resultMessage);
        } else {
          errorHandler(error);
        }
      } finally {
        setPatchFinished(true);
      }
    };

    patchConfirm();
  }, [props.location.search]);

  return (
    <Container>
      {patchFinished && (
        <Content root="會員功能" sector="信件連結確認">
          <>
            <ResponseMessage success={patchSuccess}>
              <Result success={patchSuccess}>{patchSuccess ? '成功' : '失敗'}</Result>
              {message}
            </ResponseMessage>
            <SuggestMessage>
              {!patchSuccess && (
                <>
                  因為上述原因，本次的確認操作失敗了。
                  <br />
                  如有需要、請您與
                  <a href="mailto:tipoeservice@tipo.gov.tw">系統管理員</a>
                  聯繫，我們會儘速為您處理，謝謝。
                </>
              )}
            </SuggestMessage>
          </>
        </Content>
      )}
      <FooterStyle>
        <Footer />
      </FooterStyle>
    </Container>
  );
}

export default EmailConfirm;
