import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { LinkOut } from 'src/utils/LinkOut';
import LogoPic from 'src/assets/images/logo_large.png';

const Container = styled.div`
  padding: 40px 0 0 0;
  height: 200px;
  font-size: 12px;
  @media (min-width: 1280px) {
    font-size: 15px;
  }
`;
const Anchor = styled.div`
  margin: auto;
  width: 90%;
  min-width: 1024px;
  max-width: 1440px;
  display: flex;
  justify-content: center;
`;
const LeftContainer = styled.div`
  padding: 10px;
  width: calc(70%);
  display: flex;
  flex-flow: row;
`;
const RightContainer = styled.div`
  padding: 10px;
  width: calc(30%);
  max-width: 260px;
`;
const Logo = styled.div`
  width: calc(25%);
  img {
    max-width: 100%;
    height: auto;
  }
`;
const Links = styled.div`
  display: flex;
  flex-flow: column;
`;
const Ol = styled.ol`
  margin: 0;
  padding: 0;
  display: flex;
  flex-flow: row;
  list-style: none;
  a {
    display: block;
    color: #000000;
    :hover,
    :active,
    &.active {
      color: #009fcc;
    }
  }
`;
const Li = styled.li`
  margin: 0;
  padding: 0 10px;
  width: auto;
  display: flex;
  align-items: center;
`;
const Copyright = styled.div`
  padding: 10px;
  label {
    color: #077790;
  }
`;
const Contact = styled.div`
  a {
    color: #077790;
  }
`;

function Footer() {
  return (
    <Container>
      <Anchor>
        <LeftContainer>
          <Logo>
            <img src={LogoPic} alt="logo" />
          </Logo>
          <Links>
            <Ol>
              <Li>
                <LinkOut href="https://www.tipo.gov.tw/tw/cp-349-800031-314e6-1.html">
                  <>政府網站資料開放宣告</>
                </LinkOut>
              </Li>
              |
              <Li>
                <LinkOut href="https://www.tipo.gov.tw/tw/cp-349-800032-7950e-1.html">
                  <>隱私權宣告</>
                </LinkOut>
              </Li>
              |
              <Li>
                <LinkOut href="https://www.tipo.gov.tw/tw/cp-349-800033-a09d4-1.html">
                  <>資訊安全政策</>
                </LinkOut>
              </Li>
              |
              <Li>
                <Link to="/cookie-policy">Cookie 政策</Link>
              </Li>
              |
              <Li>
                <Link to="/question/contactus">錯誤通報</Link>
              </Li>
            </Ol>
            <Copyright>
              <div>Copyright©2019 行政院經濟部智慧財產局 版權所有</div>
              <div>本網站支援IE、Firefox及Chrome，最佳瀏覽解析度為1024x768以上</div>
              {/* <div>
                更新日期:&nbsp;<label>{updateTime}</label>
                &emsp;累計瀏覽人次:&nbsp;
                <label>{updateTime}</label>
              </div> */}
            </Copyright>
          </Links>
        </LeftContainer>
        <RightContainer>
          <Contact>
            <LinkOut href="https://goo.gl/maps/eFAXGgUtkEAWa1go8">
              <>10637 臺北市辛亥路2段185號3樓</>
            </LinkOut>
          </Contact>
          <Contact>電話：(02)2738-0007</Contact>
          <Contact>傳真：(02)2377-9875</Contact>
          <Contact>電子申請電話：(02)8176-9009</Contact>
          <Contact>專利客服電話：(02)8176-9009</Contact>
        </RightContainer>
      </Anchor>
    </Container>
  );
}

export default Footer;
