import React from 'react';
import styled from 'styled-components';
import boolean_searchh from 'src/assets/images/manual/patent-search/boolean_search.png';

const ImgContainer = styled.div<{ height: string; imageUrl: string }>`
  position: relative;
  height: ${props => props.height}px;
  padding: 10px 0;
  background-image: url(${props => props.imageUrl});
  background-size: contain;
  background-repeat: no-repeat;
`;
export default function PatentSearchManual() {
  return (
    <>
      <p>
        布林檢索可以選擇不同國別、特定專利欄位、分類條件與日期條件來進行布林運算的檢索。點擊「＋」可以增加檢索欄位。
      </p>
      <ol>
        <li>從功能列點選「專利檢索」。</li>
        <li>點選「布林檢索」功能。</li>
        <li>選擇要檢索的國家範圍，預設為台灣TW。</li>
        <li>
          輸入要檢索的字串並選擇檢索欄位，運算元可選「AND」、「OR」、「NOT」，點選「＋」最多可額外增加
          7組檢索框。
        </li>
        <li>選擇要檢索的分類方式並輸入，運算元可選「AND」、「OR」、「NOT」，可不填選保留空白。</li>
        <li>選擇要檢索的日期類別，預設為公開/公告日。</li>
        <li>點選「查詢」進行查詢</li>
      </ol>
      <ImgContainer height="500" imageUrl={boolean_searchh} />
    </>
  );
}
