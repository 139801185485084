import React from 'react';
import styled from 'styled-components';
import SplitterLayout from 'react-splitter-layout';
import { FaSearch, FaTimes } from 'react-icons/fa';
import { PatentTagMap } from 'src/components/patent/nav-map/PatentTagMap';
import 'react-splitter-layout/lib/index.css';
import Tooltip from 'src/components/ui/interactive/Tooltip';

const NavTool = styled.div`
  width: 256px;
  position: fixed;
  padding: 16px;
  border-radius: 4px;
  background-color: #f9f9f9;
`;
const NavToolTitle = styled.div`
  color: #b3b9bc;
  font-weight: bold;
`;

const AnchorLinks = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
`;

const AnchorLink = styled.div<{ comparedMode?: boolean }>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${props =>
    props.comparedMode &&
    `flex-direction: column;
      text-align: center;
      margin-right: 24px;
      width:auto;`};

  > a {
    display: block;
    margin-top: 8px;
    padding: 4px 16px;
    border-radius: 5px;
    background-color: #edf6f7;
    color: #077790;
    text-decoration: none;
    text-align: center;
    /* &:last-child {
        margin-right: 0;
      } */
    &:hover {
      background-color: #0cafcc;
      color: #ffffff;
    }
  }
  > span {
    font-size: 13px;
  }
`;
const SearchBar = styled.input`
  margin-bottom: 16px;
  width: 100%;
  border-radius: 4px;
  border: solid 1px #b3b9bc;
  padding: 4px 32px 4px 8px;
  outline: none;
  &::placeholder {
    color: #979ea1;
  }
`;
const SplitterLayoutWrapper = styled.div`
  position: relative;
  height: 100vh;
  & .splitter-layout.splitter-layout-vertical > .layout-splitter {
    width: 100%;
    height: 23px;
    margin: auto;
    border-top: 4px solid #f9f9f9;
    border-bottom: 16px solid #f9f9f9;
    border-left: 64px solid #f9f9f9;
    border-right: 64px solid #f9f9f9;
    background-color: #d0d4d6;
  }
  & .layout-pane.layout-pane-primary {
    overflow: hidden;
  }
`;
const SearchResultPanel = styled.div`
  padding-bottom: 16px;
`;
const SearchBarWrapper = styled.div`
  position: relative;
  & > svg {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-100%);
    color: #979ea1;
    cursor: pointer;
  }
`;

const ColorTagOutline = styled.div`
  width: 100%;
  margin-bottom: 16px;
  overflow: auto;
`;
const ColorTagWrapper = styled.div`
  margin-bottom: 4px;

  &:last-child {
    margin-bottom: 0;
  }
`;
const ColorTag = styled.div<{ color?: string }>`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  padding: 8px 16px;
  list-style: none;
  background-color: ${props => props.color || 'transparent'};
  svg {
    width: 15px;
    height: 15px;
    cursor: pointer;
  }
`;
const ColorTagText = styled.div`
  max-width: 10rem;
  font-size: 15px;
  margin-right: 4px;
  line-height: 1.2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
`;

const ColorTagDes = styled.div`
  font-size: 12px;
  line-height: 1.2rem;
  color: #979ea1;
`;

interface IProps {
  tagColorArray: string[];
  searchText: string[];
  inputValue: string;
  addTag: any;
  handleChange: any;
  removeTag: any;
  colorPanel: string[];
  activeComparedPane: string;
}
/** 計算字串長度, 中文佔 2 byte */
const getByteLength = (text: string) => {
  // eslint-disable-next-line
  const arr = text.match(/[^\x00-\xff]/gi);
  return arr == null ? text.length : text.length + arr.length;
};

const PatentNavTool: React.FC<IProps> = props => (
  <NavTool>
    <SplitterLayoutWrapper>
      <SplitterLayout vertical secondaryMinSize={168} secondaryInitialSize={168}>
        <>
          <NavToolTitle>
            內容導航
            <AnchorLinks>
              <AnchorLink comparedMode={props.activeComparedPane === 'ComparedPatent'}>
                {props.activeComparedPane === 'ComparedPatent' && <span>左欄</span>}
                <a href="#abstract">摘要</a>
                <a href="#description">說明</a>
                <a href="#claim">範圍</a>
              </AnchorLink>
              {props.activeComparedPane === 'ComparedPatent' && (
                <AnchorLink comparedMode={true}>
                  <span>右欄</span>
                  <a href="#abstract2">摘要</a>
                  <a href="#description2">說明</a>
                  <a href="#claim2">範圍</a>
                </AnchorLink>
              )}
            </AnchorLinks>
          </NavToolTitle>
          <PatentTagMap colorPanel={props.colorPanel} />
        </>
        <SearchResultPanel>
          <SearchBarWrapper>
            <SearchBar
              type="text"
              placeholder="文內字串檢索"
              //@ts-ignore
              value={props.inputValue}
              onKeyDown={(e: any) => {
                if (e.keyCode === 13) {
                  props.addTag();
                }
              }}
              onChange={props.handleChange}
            />
            <FaSearch size="15px" onClick={props.addTag} />
          </SearchBarWrapper>
          {props.searchText.length !== 0 && (
            <ColorTagOutline>
              {props.searchText.map((text, id) => (
                <ColorTagWrapper key={id}>
                  <ColorTag
                    color={
                      //@ts-ignore
                      props.tagColorArray[text]
                    }
                  >
                    <Tooltip
                      overlay={text}
                      placement="right"
                      offset={32}
                      isHidden={20 >= getByteLength(text)}
                    >
                      <ColorTagText>{text}</ColorTagText>
                    </Tooltip>
                    <FaTimes
                      onClick={() => {
                        props.removeTag(id);
                      }}
                    />
                  </ColorTag>
                </ColorTagWrapper>
              ))}
            </ColorTagOutline>
          )}
          <ColorTagDes>文內檢索以關鍵字高亮檢視方式呈現，最多可使用十筆關鍵字</ColorTagDes>
        </SearchResultPanel>
      </SplitterLayout>
    </SplitterLayoutWrapper>
  </NavTool>
);

export default PatentNavTool;
