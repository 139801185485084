import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { ReduxAppState } from 'src/redux/reducers';
import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import IndustrialClassArrowDropdown from 'src/components/trend/industrialClass/ui/ArrowDropdown';
import { Button } from 'src/style/theme/Common';
import { IIndustrialClassType } from 'src/types/IndustryTypes';
import { IGetIpc } from 'src/types/PatentSearchTypes';
import CodeSearch from './CodeSearch';
import { ultrablackrgba } from 'src/style/theme/Color';

const SearchBar = styled.div`
  display: flex;
  margin-left: 16px;
  width: calc(100% - 68px);
  box-shadow: 2px 4px 8px 1px ${ultrablackrgba(0.1)};
  > div {
    width: calc(100% / 3);
  }
  > button {
    flex-shrink: 0;
  }
`;

interface IReduxProps {
  industrialClassList: Array<IIndustrialClassType>;
}

interface IProps extends IReduxProps {
  handleBackClick: () => void;
  handleIpcCodeList?: (ipcCodeList: string[]) => void;
}

/** 行業別相關 IPC 代碼查詢 */
const IndustrialClassSearch: React.FC<IProps> = ({
  industrialClassList,
  handleBackClick,
  handleIpcCodeList,
}) => {
  const [ipcList, setIpcList] = useState<Array<IGetIpc>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [section, setSection] = useState<IIndustrialClassType>(industrialClassList[0]);
  const [division, setDivision] = useState<IIndustrialClassType | null>(null);
  const [group, setGroup] = useState<IIndustrialClassType | null>(null);

  // TODO: 預設帶入user感興趣的第一個行業別
  useEffect(() => {
    industrialClassList.length !== 0 && fetchIpcList(industrialClassList[0].id);
  }, [industrialClassList]);

  useEffect(() => {
    setDivision(null);
  }, [section]);

  useEffect(() => {
    setGroup(null);
  }, [division]);

  const fetchIpcList = async (industrialClassId: number) => {
    const id = industrialClassId.toString();
    setIsLoading(true);
    try {
      const res = await Api().get(`ipc/industry/${id}`);
      setIpcList(res.data);
    } catch (err) {
      setIpcList([]);
      errorHandler(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = () => {
    const selectedId =
      group === null || group.id === 0
        ? division === null || division.id === 0
          ? section.id
          : division.id
        : group.id;
    fetchIpcList(selectedId);
  };

  return (
    <CodeSearch
      title="行業別相關 IPC 代碼查詢"
      ipcList={ipcList}
      isLoading={isLoading}
      handleBackClick={handleBackClick}
      handleIpcCodeList={handleIpcCodeList}
      searchFormBelowTitle
      searchForm={
        industrialClassList.length !== 0 ? (
          <SearchBar>
            <IndustrialClassArrowDropdown
              data={industrialClassList}
              dataTitle="大類"
              selectedItem={section}
              setSelectedItem={setSection}
              withLeftRadius
              withRightArrow
            />
            <IndustrialClassArrowDropdown
              data={section && section.children ? section.children : []}
              dataTitle="中類"
              selectedItem={division}
              setSelectedItem={setDivision}
              withRightArrow
              withSelectAll
            />
            <IndustrialClassArrowDropdown
              data={division && division.children ? division.children : []}
              dataTitle="小類"
              selectedItem={group}
              setSelectedItem={setGroup}
              withSelectAll
            />
            <Button
              type="submit"
              template="primary-dark"
              margin="0"
              radius="0 4px 4px 0"
              onClick={() => handleSubmit()}
            >
              查詢代碼
            </Button>
          </SearchBar>
        ) : (
          <></>
        )
      }
    />
  );
};
const mapStateToProps = (state: ReduxAppState) => {
  return {
    industrialClassList: state.industrialClassReducer.industrialClassList,
  };
};

export default connect(
  mapStateToProps,
  null,
)(IndustrialClassSearch);
