import React, { useState, useEffect, useRef, Fragment } from 'react';
import * as d3 from 'd3';
import styled from 'styled-components';
import { ILineChartType, ILineChartDataType } from 'src/types/IndustryTypes';
import {
  blue4,
  iceblue1,
  iceblue8,
  iceblue6,
  ultrawhite,
  trendsLineChartColors,
} from 'src/style/theme/Color';
import Tooltip from 'src/components/trend/industrialTrends/chart/Tooltip';
import { numFormatter } from 'src/utils/Formatter';
import { svgId } from 'src/utils/svgDownloadUtils';

const ToolTipOverlay = styled.div`
  color: ${ultrawhite};
`;

const ToolTipNumber = styled.span`
  font-size: 24px;
  font-weight: bold;
  color: ${blue4};
`;

const Legend = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${iceblue1};
  margin-left: 64px;
`;
const LegendDot = styled.span<{ color: string }>`
  width: 12px;
  height: 2px;
  margin: 2px 8px 0 16px;
  background-color: ${props => props.color};
  border-radius: 1px;
`;

const width = 480;
const height = 220;
const padding = { top: 30, right: 50, bottom: 30, left: 70 };
const xAxisWidth = width - padding.left - padding.right;
const yAxisWidth = height - padding.top - padding.bottom;

interface IProps {
  dataset: Array<ILineChartType>;
}

interface ITooltip {
  left: number;
  top: number;
  main: ILineChartDataType | undefined;
  compare: ILineChartDataType | undefined;
}

interface ILegend {
  name: string;
  color: string;
}

/** 折線圖 */
const LineChart: React.FC<IProps> = ({ dataset }) => {
  const [tooltip, setTooltip] = useState<ITooltip>();
  const [legend, setLegend] = useState<Array<ILegend>>([]);
  const svgRef = useRef<SVGSVGElement>(null);
  const getMaxY = (data: Array<ILineChartType>) => {
    let values: Array<number> = [];
    data.forEach(item =>
      item.data.forEach(data => {
        values.push(data.count);
      }),
    );
    const max = Math.max(...values);
    return max < 4 ? 4 : max;
  };

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    svg.selectAll('g').remove();

    // 若僅有1組資料, 該組資料即為主資料; 2組以上資料時將第1組資料作為比較資料, 第2組資料作為主資料
    const defaultData: ILineChartType = { name: '', data: [] };
    const mainData =
      dataset && dataset.length > 1 && dataset[1]
        ? dataset[1]
        : dataset && dataset.length === 1
        ? dataset[0]
        : defaultData;
    const compareData = dataset && dataset.length > 1 ? dataset[0] : defaultData;

    // 設定圖例
    let legendList: Array<ILegend> = [];
    legendList.push({ name: mainData.name, color: trendsLineChartColors[1] });
    compareData.data.length > 0 &&
      legendList.push({ name: compareData.name, color: trendsLineChartColors[0] });
    setLegend(legendList);

    // 設定 X 軸項目 (無論資料長度都顯示12個月), 需注意項目名稱需和資料名稱 (即time) 完全比對符合才能正確繪製
    const xScaleItems = [`1`, `2`, `3`, `4`, `5`, `6`, `7`, `8`, `9`, `10`, `11`, `12`];

    const xScale = d3
      .scaleBand()
      .domain(xScaleItems)
      .rangeRound([0, width - padding.left - padding.right]);

    const yScale = d3
      .scaleLinear()
      .domain([0, getMaxY(dataset)])
      .range([height - padding.top - padding.bottom, 0])
      .nice(5);

    const xAxis = d3
      .axisBottom(xScale)
      .tickFormat((d, i) => xScaleItems[i])
      .tickSize(5);

    const yAxis = d3
      .axisLeft(yScale)
      .ticks(5)
      .tickFormat(d3.format(','))
      .tickSize(-xAxisWidth);

    const linePath = d3
      .line<ILineChartDataType>()
      .x((d: ILineChartDataType, i) => (xScale(d.time) || 0) + xScale.bandwidth() / 2)
      .y((d: ILineChartDataType) => yScale(d.count))
      .curve(d3.curveMonotoneX); // smooth line

    // X軸
    const xAxisG = svg
      .append('g')
      .attr('transform', 'translate(' + padding.left + ',' + (padding.top + yAxisWidth) + ')')
      .style('color', iceblue6)
      .call(xAxis);
    // X軸標題
    xAxisG
      .append('text')
      .attr('x', xAxisWidth + 12)
      .attr('dy', 23)
      .attr('fill', iceblue1)
      .style('font-size', '13px')
      .text('月份');
    // X軸文字
    xAxisG
      .selectAll('.tick text')
      .style('font-size', '15px')
      .style('color', iceblue1)
      .attr('dy', '1em');
    // 刪除X軸-軸線 (橫線以Y軸 ticks 顯示)
    xAxisG.selectAll('path').remove();

    // Y軸
    const yAxisG = svg
      .append('g')
      .attr('transform', 'translate(' + padding.left + ',' + padding.top + ')')
      .style('color', iceblue1)
      .call(yAxis);
    // Y軸標題
    yAxisG
      .append('text')
      .attr('x', 0)
      .attr('dy', -20)
      .attr('fill', iceblue1)
      .style('font-size', '13px')
      .text('件數 (件)');
    // 調整文字與Y軸之距離
    yAxisG.selectAll('text').attr('x', '-0.8em');
    // 刪除Y軸-軸線
    yAxisG.selectAll('path').remove();
    // Y軸 ticks (作為橫格線) 之樣式設定
    yAxisG
      .selectAll('line')
      .attr('fill', 'none')
      .attr('stroke', `${iceblue8}`);

    // 資料點陰影
    const dropShadowFilter = svg
      .append('filter')
      .attr('id', 'dropshadow')
      .attr('filterUnits', 'userSpaceOnUse')
      .attr('width', '250%')
      .attr('height', '250%');
    dropShadowFilter
      .append('svg:feGaussianBlur')
      .attr('in', 'SourceGraphic')
      .attr('stdDeviation', 2)
      .attr('result', 'blur-out');
    dropShadowFilter
      .append('feOffset')
      .attr('in', 'blur')
      .attr('result', 'offsetBlur');
    dropShadowFilter
      .append('feFlood')
      .attr('in', 'offsetBlur')
      .attr('flood-color', '#888888')
      .attr('flood-opacity', '1')
      .attr('result', 'offsetColor');
    dropShadowFilter
      .append('feComposite')
      .attr('in', 'offsetColor')
      .attr('in2', 'offsetBlur')
      .attr('operator', 'in')
      .attr('result', 'offsetBlur');
    dropShadowFilter
      .append('svg:feBlend')
      .attr('in', 'SourceGraphic')
      .attr('in2', 'the-shadow')
      .attr('mode', 'normal');

    const drawLines = (data: Array<ILineChartDataType>, color: string) => {
      const getToolTip = (month: string) => {
        const main = mainData.data.find(e => month === e.time);
        const compare = compareData.data.find(e => month === e.time);
        return {
          left: d3.event.pageX - window.scrollX,
          top: d3.event.pageY - window.scrollY - 32,
          main: main
            ? { time: mainData.name + ' 年 ' + month + ' 月 ', count: main.count }
            : undefined,
          compare: compare
            ? { time: compareData.name + ' 年 ' + month + ' 月 ', count: compare.count }
            : undefined,
        };
      };
      const lineG = svg.append('g');
      // 折線圖-線段
      lineG
        .datum(data)
        .append('path')
        .attr('transform', 'translate(' + padding.left + ',' + padding.top + ')')
        .attr('d', linePath)
        .attr('fill', 'none')
        .attr('stroke-width', '2px')
        .attr('stroke', `${color}`);

      // 折線圖-連接點
      lineG
        .selectAll('circle')
        .data(data)
        .enter()
        .append('circle')
        .attr('cx', d => padding.left + (xScale(d.time) || 0) + xScale.bandwidth() / 2)
        .attr('cy', d => padding.top + yScale(d.count))
        .attr('r', 5)
        .attr('fill', `${color}`)
        .attr('fill-opacity', 0)
        .style('stroke', 'transparent')
        .style('stroke-width', 4)
        .on('mouseover', (d, i) => {
          const order = i + 2;
          svg
            .selectAll('g circle:nth-child(' + order + ')')
            .attr('fill-opacity', 1)
            .style('stroke', '#ffffff')
            .style('filter', 'url(#dropshadow)');

          setTooltip(getToolTip(d.time));
        })
        .on('mousemove', (d, i) => {
          setTooltip(getToolTip(d.time));
        })
        .on('mouseout', (d, i) => {
          const order = i + 2;
          svg
            .selectAll('g circle:nth-child(' + order + ')')
            .attr('fill-opacity', 0)
            .style('stroke', 'transparent')
            .style('filter', 'none');

          setTooltip(undefined);
        });
    };
    compareData.data.length > 0 && drawLines(compareData.data, trendsLineChartColors[0]); // 先繪製比較線, 使其層次在主線之下
    drawLines(mainData.data, trendsLineChartColors[1]);
  }, [dataset]);

  return (
    <>
      <svg id={svgId} ref={svgRef} width={width} height={height} />
      <Legend>
        {legend.map(i => (
          <Fragment key={i.name}>
            <LegendDot color={i.color} />
            {i.name}
          </Fragment>
        ))}
      </Legend>

      {tooltip && (
        <Tooltip
          left={tooltip.left}
          top={tooltip.top}
          overlay={
            <ToolTipOverlay>
              {tooltip.main && (
                <div>
                  {tooltip.main.time}&nbsp;&nbsp;
                  <ToolTipNumber>{numFormatter(tooltip.main.count)}</ToolTipNumber>&nbsp; 件
                </div>
              )}
              {tooltip.compare && (
                <div>
                  {tooltip.compare.time}&nbsp;&nbsp;
                  <ToolTipNumber>{numFormatter(tooltip.compare.count)}</ToolTipNumber>&nbsp; 件
                </div>
              )}
            </ToolTipOverlay>
          }
        />
      )}
    </>
  );
};

export default LineChart;
