import React, { useRef, useState } from 'react';
import OpendataContent from 'src/components/ui/content/Opendata/OpendataContent';
import { Api } from 'src/api/helpers/apiBase';
import { CSVLink } from 'react-csv';
import { Button } from 'src/style/theme/Common';
import { FieldStyle, FormContainer, ItemContainer, ItemInput } from 'src/style/OpendataStyle';
import styled from 'styled-components';
import { Form, Formik, FormikActions, FormikProps } from 'formik';
import { IDownloadTechnicalTermForm } from 'src/types/form/OpendataFormTypes';
import { nonEmptySourceValidator } from 'src/apps/validation/formValidator';
import MiniLoader from 'src/components/ui/interactive/MiniLoader';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import { getCountryName } from 'src/utils/PatentUtils';

interface ITechnicalTermsData {
  WORDS: string;
  IPC_CODE: string;
  COUNTRY: string;
}

const Container = styled(FormContainer)`
  padding: 0;
`;

interface CsvLinkAnchorElement extends HTMLAnchorElement {
  link: {
    click: () => void;
  };
}

const Technical: React.FC = () => {
  const [data, setData] = useState<Array<ITechnicalTermsData>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const csvRef = useRef<CsvLinkAnchorElement>(null);
  const technicalFormInitialValues = {
    country: '',
  } as IDownloadTechnicalTermForm;

  const handleSummit = async (
    values: IDownloadTechnicalTermForm,
    actions: FormikActions<IDownloadTechnicalTermForm>,
  ) => {
    setIsLoading(true);
    try {
      const res = await Api().get('patent/search/technicalTerms?country=' + values.country);
      setData(res.data);

      if (csvRef && csvRef.current && csvRef.current.link) {
        csvRef.current.link.click();
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  // CSV EXPORT
  const csvFileName = '技術辭庫列表.csv';
  const csvHeaders = [
    { label: '技術辭', key: 'WORDS' },
    { label: 'IPC分類', key: 'IPC_CODE' },
    { label: '國別', key: 'COUNTRY' },
  ];
  return (
    <OpendataContent
      sector="開放資料"
      feature="技術辭庫下載"
      foldableDescription={{
        abstract:
          '提供專利技術辭庫資料下載，包含本國(TIPO)、美國(USPTO)、歐盟(EPO)與中國大陸(CNIPA)。',
      }}
    >
      <Container>
        檔案類型：CSV
        <br />
        編碼格式：UTF-8
        <br />
        資料內容說明：
        <br />
        <ul>
          <li>技術辭庫清單 CSV檔。內容包含：技術辭、IPC分類、國別。</li>
        </ul>
        <br />
        <br />
        <Formik
          initialValues={technicalFormInitialValues}
          onSubmit={(values, actions) => handleSummit(values, actions)}
          render={(formikBag: FormikProps<IDownloadTechnicalTermForm>) => (
            <Form>
              <ItemContainer>
                <span>資料來源</span>
                <ItemInput>
                  <FieldStyle
                    name="country"
                    component="select"
                    width="70%"
                    validate={nonEmptySourceValidator}
                  >
                    <option value="">請選擇</option>
                    <option value="TW">{getCountryName('TW')}</option>
                    <option value="US">{getCountryName('US')}</option>
                    <option value="EP">{getCountryName('EP')}</option>
                    <option value="CN">{getCountryName('CN')}</option>
                  </FieldStyle>
                </ItemInput>
              </ItemContainer>
              <ItemContainer>
                <span />
                <ItemInput>
                  <CSVLink
                    ref={csvRef as any}
                    filename={csvFileName}
                    headers={csvHeaders}
                    data={data}
                  />
                  <Button
                    style={{ display: 'flex', alignItems: 'center' }}
                    type="submit"
                    template="primary"
                    disabled={isLoading}
                  >
                    {isLoading && (
                      <MiniLoader diameter="15px" borderWidth="2px" margin="0 8px 0 0" />
                    )}
                    技術辭庫下載
                  </Button>
                </ItemInput>
              </ItemContainer>
            </Form>
          )}
        />
      </Container>
    </OpendataContent>
  );
};

export default Technical;
