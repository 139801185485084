import React from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router';

import PartentNavTool from './nav-map/NavMap';
import PatentMainPane from './PatentMainPane';
import PatentComparePane from './PatentComparePane';
import RightToolBar from './toolbar/Toolbar';

import {
  IGetPatentOcrLabelTypes,
  IGetPtabTypes,
  PatentDataTypes,
} from 'src/types/api/PatentApiTypes';
import { PatentService } from 'src/api/Patent';
import { alertMessage } from 'src/utils/ModalUtils';
import { SystemMessage } from 'src/apps/applicationMessages';

const patentService = PatentService();

const PageContainer = styled.div<{ compareMode: boolean }>`
  display: flex;
  height: ${props => (props.compareMode ? '1600px' : '')};
  @media print {
    display: none;
  }
`;

interface IProps {
  patentId: string;
}

interface IState {
  tagColorArray: string[];
  searchText: string[];
  inputValue: string;
  usedColors: string[];
  isCompareMode: boolean;
  patentData: PatentDataTypes;
  patentDataId: number;
  activeComparedPane: string;
  colorPanel: string[];
  ptab: IGetPtabTypes[];
  lightboxFirstShowImage: number;
  patentOcrLabel: IGetPatentOcrLabelTypes | null;
  isOcrLabelLoading: boolean;
}

class PatentContent extends React.Component<RouteComponentProps<IProps>, IState> {
  constructor(props: RouteComponentProps<IProps>) {
    super(props);
    this.state = {
      tagColorArray: [],
      searchText: [],
      inputValue: '',
      usedColors: [],
      isCompareMode: false,
      patentData: { content: null, ipc: [] },
      patentDataId: 0,
      activeComparedPane: '',
      colorPanel: [
        '#ffcad2',
        '#a8ffb4',
        '#feff86',
        '#a8fff5',
        '#ffcfa5',
        '#b6ceff',
        '#e3bfbf',
        '#f0cdff',
        '#ff8b9f',
        '#d0d4d6',
      ],
      ptab: [],
      lightboxFirstShowImage: 0,
      patentOcrLabel: null,
      isOcrLabelLoading: false,
    };
  }

  handleChange = (e: any) => {
    this.setState({ inputValue: e.target.value.trim() });
  };

  addTag = () => {
    const currentValue = this.state.inputValue;
    if (!currentValue) {
      alertMessage(SystemMessage.PATENT_HIGHLIGHT_KEYWORD_EMPTY);
    } else if (this.state.searchText.length >= 10) {
      alertMessage(SystemMessage.PATENT_HIGHLIGHT_KEYWORD_LIMIT);
    } else if (
      this.state.searchText.find(
        text => text.toLocaleLowerCase() === currentValue.toLocaleLowerCase(),
      )
    ) {
      alertMessage(SystemMessage.PATENT_HIGHLIGHT_KEYWORD_EXIST);
    } else {
      this.setState({
        searchText: [...this.state.searchText, currentValue],
        inputValue: '',
      });
      setTimeout(
        () =>
          this.setHighlightTag(
            this.state.searchText,
            this.state.colorPanel,
            this.state.tagColorArray,
          ),
        0,
      );
    }
  };

  removeTag = (i: number) => {
    const newSearchText = [...this.state.searchText];
    const removedSearchText = newSearchText.splice(i, 1)[0];
    const tempTagColorArray = this.state.tagColorArray;
    const usedColorArray = [...this.state.usedColors];
    usedColorArray.splice(
      //@ts-ignore
      usedColorArray.indexOf(tempTagColorArray[removedSearchText]),
      1,
    );
    //@ts-ignore
    delete tempTagColorArray[removedSearchText];
    this.setState({
      searchText: newSearchText,
      tagColorArray: tempTagColorArray,
      usedColors: [...usedColorArray],
    });
    setTimeout(
      () =>
        this.setHighlightTag(
          this.state.searchText,
          this.state.colorPanel,
          this.state.tagColorArray,
        ),
      0,
    );
  };

  setHighlightTag = (keywords: string[], labelColors: string[], tagColorArray: any) => {
    if (Object.prototype.toString.call(keywords) === '[object Array]') {
      const uniColors = new Set(this.state.usedColors);
      const minus = labelColors.filter(x => !uniColors.has(x));

      keywords.forEach(word => {
        //@ts-ignore
        if (tagColorArray[word] === undefined) {
          //@ts-ignore
          tagColorArray[word] = minus.shift();

          this.setState(prevState => {
            const tempUsedColors = new Set([...prevState.usedColors]);
            tempUsedColors.add(tagColorArray[word]);
            return {
              ...prevState,
              usedColors: [
                //@ts-ignore
                ...tempUsedColors,
              ],
            };
          });
        }
      });

      this.setState({
        // 上面不使用setState直接對tagColorArray做操作，可能因此導致React re-render不可預期。
        // 所以這邊用寫入一份新copy的方式，確保下層組件"PatentContent"可重新渲染讓關鍵字標籤顯示正確顏色。
        tagColorArray: { ...tagColorArray },
      });
    }
  };

  handleCompareModeToggle = () => {
    this.setState({
      activeComparedPane: 'PatentEmpty',
      isCompareMode: !this.state.isCompareMode,
    });
  };

  toggleComparedPane = (activePane: string) => {
    this.setState({
      activeComparedPane: activePane === this.state.activeComparedPane ? '' : activePane,
      isCompareMode: activePane !== this.state.activeComparedPane,
    });
  };

  setLightboxFirstShowImage = (activeImage: number) => {
    this.setState({
      lightboxFirstShowImage: activeImage,
    });
  };

  componentDidMount() {
    patentService.getPatentData(this.props.match.params.patentId).then(data => {
      data.content &&
        this.setState({
          patentData: data,
          patentDataId: data.content.patentDataId,
        });
      if (data.content) {
        patentService
          .getPtab(data.content.bibliographic.applicationReference.documentId.docNumber)
          .then(patb => {
            patb &&
              patb.length > 0 &&
              this.setState({
                ptab: patb,
              });
          });
        this.setState({
          isOcrLabelLoading: true,
        });
        patentService.getPatentOcrLabel(data.content.id).then(patentOcrLabel => {
          patentOcrLabel &&
            this.setState({
              patentOcrLabel: patentOcrLabel,
            });
          this.setState({
            isOcrLabelLoading: false,
          });
        });
      }
    });
  }

  render() {
    return (
      <PageContainer compareMode={this.state.isCompareMode}>
        <PartentNavTool
          tagColorArray={this.state.tagColorArray}
          searchText={this.state.searchText}
          inputValue={this.state.inputValue}
          addTag={this.addTag}
          handleChange={this.handleChange}
          removeTag={this.removeTag}
          colorPanel={this.state.colorPanel}
          activeComparedPane={this.state.activeComparedPane}
        />
        <PatentMainPane
          isCompareMode={this.state.isCompareMode}
          toggleCompareMode={this.handleCompareModeToggle}
          patentData={this.state.patentData}
          searchText={this.state.searchText}
          tagColorArray={this.state.tagColorArray}
          toggleComparedPane={this.toggleComparedPane}
          setLightboxFirstShowImage={this.setLightboxFirstShowImage}
          lightboxFirstShowImage={this.state.lightboxFirstShowImage}
          patentOcrLabel={this.state.patentOcrLabel}
          isOcrLabelLoading={this.state.isOcrLabelLoading}
        />
        <PatentComparePane
          isCompareMode={this.state.isCompareMode}
          activeComparedPane={this.state.activeComparedPane}
          toggleComparedPane={this.toggleComparedPane}
          patentData={this.state.patentData}
          searchText={this.state.searchText}
          tagColorArray={this.state.tagColorArray}
          ptab={this.state.ptab}
          lightboxFirstShowImage={this.state.lightboxFirstShowImage}
          patentOcrLabel={this.state.patentOcrLabel}
          isOcrLabelLoading={this.state.isOcrLabelLoading}
        />
        <RightToolBar
          iconOnly={this.state.isCompareMode}
          activeComparedPane={this.state.activeComparedPane}
          toggleComparedPane={this.toggleComparedPane}
          patentDataId={this.state.patentDataId}
          patentData={this.state.patentData}
          ptab={this.state.ptab}
        />
      </PageContainer>
    );
  }
}

export default PatentContent;
