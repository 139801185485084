import React from 'react';
import styled from 'styled-components';
import { ultrawhite, ultrawhitergba, ultrablackrgba } from 'src/style/theme/Color';

const Container = styled.div`
  border-radius: 4px;
  box-shadow: 0 0 50px 0 ${ultrablackrgba(0.1)};
`;
const Header = styled.div`
  height: 88px;
  display: flex;
  flex-flow: row;
  align-items: center;
  color: ${ultrawhite};
  background-image: linear-gradient(95deg, #2ec3c2, #0cafcc);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;
const HeaderText = styled.div`
  padding: 16px 32px;
`;
const HeaderRoute = styled.div`
  color: ${ultrawhitergba(0.8)};
`;
const Feature = styled.div`
  height: 36px;
  line-height: 36px;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  align-items: flex-end;
  title {
    padding: 0 0 0 8px;
    display: block;
    font-size: 15px;
    line-height: 24px;
    font-weight: normal;
  }
`;
const Subheaders = styled.div`
  height: 38px;
  display: flex;
  align-items: center;
  color: #979ea1;
  background-color: #edf6f7;
`;
const PatentInfo = styled.div`
  padding: 0 0 0 32px;
  width: 80%;
`;
const Lasttime = styled.div`
  padding: 0 16px 0 8px;
  width: 20%;
`;
const Content = styled.div`
  padding: 0 0 24px;
  min-height: 450px;
`;

interface IProps {
  sector: string;
  feature: string;
  children: JSX.Element;
}

const browseLogContent: React.FC<IProps> = props => {
  return (
    <Container>
      <Header>
        <HeaderText>
          <HeaderRoute>
            首頁&nbsp;/&nbsp;{props.sector}&nbsp;/&nbsp;
            {props.feature}
          </HeaderRoute>
          <Feature>{props.feature}</Feature>
        </HeaderText>
      </Header>
      <Subheaders>
        <PatentInfo>專利資訊</PatentInfo>
        <Lasttime>最後閱讀時間</Lasttime>
      </Subheaders>
      <Content>{props.children}</Content>
    </Container>
  );
};

export default browseLogContent;
