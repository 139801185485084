import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FaPrint, FaTimes, FaExternalLinkAlt } from 'react-icons/fa';

import { Backdrop } from 'src/style/ModalStyle';
import { blue3, blue6, iceblue5 } from 'src/style/theme/Color';
import { zModal } from 'src/style/theme/Z-Index';
import { closeCustomModal } from 'src/redux/actions/modalAction';
import { PatentDataTypes } from 'src/types/api/PatentApiTypes';
import PatentContent, { templates } from 'src/components/patent/content/Content';
import { Spinner } from 'src/components/ui/interactive/Spinner';

const Container = styled.div`
  border-radius: 4px;
  background-color: #ffffff;
`;

const Header = styled.div`
  width: 100%;
  text-align: right;
  padding: 32px 32px 16px 32px;
  svg {
    font-size: 20px;
    margin-left: 24px;
    cursor: pointer;
  }
  @media print {
    display: none;
  }
`;

const Body = styled.div`
  width: 720px; /*為了讓看起來的版面跟印出來一樣，寬度寫死*/
  height: calc(100vh - 120px);
  overflow: auto;
  margin: auto;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    box-shadow: inset 0 0 8px 8px ${blue6};
    border: solid 2px transparent;
  }
  @media print {
    height: auto;
  }
`;

interface IReduxMappingProps {
  closeCustomModal: () => void;
}

interface IProps extends IReduxMappingProps {
  printButton?: boolean; //true:右上為列印按鈕 ;false或不指定:右上為新分頁按鈕
  patentData: PatentDataTypes;
  isLoading?: boolean;
}

const modal = document.getElementById('modal-custom');
const portalDiv = modal ? modal : document.createElement('div');

const PatentConentModal: React.FC<IProps> = props => {
  const patentId = props.patentData.content && props.patentData.content.id;
  return ReactDOM.createPortal(
    <Backdrop zIndex={zModal.custom} alignItems="center">
      <Container>
        {props.isLoading ? (
          <>
            <Header>
              <FaTimes color={iceblue5} onClick={() => props.closeCustomModal()} />
            </Header>
            <Body>
              <Spinner width="200px" margin="120px auto" />
            </Body>
          </>
        ) : (
          <>
            <Header>
              {props.printButton ? (
                <FaPrint
                  color={blue3}
                  onClick={() => {
                    window.print();
                  }}
                />
              ) : (
                <Link to={`/patent/${patentId}/view`} target="_blank">
                  <FaExternalLinkAlt color={blue3} />
                </Link>
              )}
              <FaTimes color={iceblue5} onClick={() => props.closeCustomModal()} />
            </Header>
            <Body>
              <PatentContent template={templates.Modal} patentData={props.patentData} />
            </Body>
          </>
        )}
      </Container>
    </Backdrop>,
    portalDiv,
  );
};

const mapDispatchToProps = {
  closeCustomModal,
};

export default connect(
  null,
  mapDispatchToProps,
)(PatentConentModal);
