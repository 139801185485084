import { AxiosResponse, AxiosError } from 'axios';
import { IIndustrialClassType } from 'src/types/IndustryTypes';

export const FETCH_INDUSTRIAL_CLASS_START = 'FETCH_INDUSTRIAL_CLASS_START';
export const FETCH_INDUSTRIAL_CLASS_SUCCESS = 'FETCH_INDUSTRIAL_CLASS_SUCCESS';
export const FETCH_INDUSTRIAL_CLASS_ERROR = 'FETCH_INDUSTRIAL_CLASS_ERROR';

export interface FetchIndustrialClassState {
  isLoading: boolean;
  industrialClassList: Array<IIndustrialClassType>;
}

interface FetchIndustrialClassStartAction {
  type: typeof FETCH_INDUSTRIAL_CLASS_START;
}

interface FetchIndustrialClassSuccessAction {
  type: typeof FETCH_INDUSTRIAL_CLASS_SUCCESS;
  response: AxiosResponse;
}

interface FetchIndustrialClassErrorAction {
  type: typeof FETCH_INDUSTRIAL_CLASS_ERROR;
  error: AxiosError;
}

export type FetchIndustrialClassActionTypes =
  | FetchIndustrialClassStartAction
  | FetchIndustrialClassSuccessAction
  | FetchIndustrialClassErrorAction;
