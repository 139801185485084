export const initData = {
  account: '',
  password: '',
  name: '',
  phone: '',
  email: '',
  industrialClass: [],
  company: '',
  apiToken: '',
};
