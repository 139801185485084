import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FaExternalLinkAlt, FaSearchMinus, FaSearchPlus, FaTimes } from 'react-icons/fa';
import PatentContent, { templates } from 'src/components/patent/content/Content';
import { PatentDataTypes, IGetPatentOcrLabelTypes } from 'src/types/api/PatentApiTypes';
import { Spinner } from 'src/components/ui/interactive/Spinner';
import { white, blue1, blue2, iceblue3 } from 'src/style/theme/Color';
import ImageModal from '../patent/content/ImageModal';
import { PatentService } from 'src/api/Patent';
import { getImgSize } from '../patent/PatentComparePane';
import { getImgUrls } from 'src/utils/PatentUtils';

const Container = styled.div`
  width: 45%;
  margin-top: -12px;
  background-color: ${white};
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
`;

const Header = styled.div`
  border-radius: 4px 4px 0 0;
  background-image: linear-gradient(93deg, ${blue2}, ${blue1});
  color: ${white};
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 10;
`;

const LeftTitle = styled.div`
  display: flex;
`;

const Title = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  margin-right: 16px;
  /* display: flex; */
`;

const IconBox = styled.div`
  display: flex;
  align-items: center;
  svg {
    color: ${white};
    margin-left: 16px;
    :hover {
      cursor: pointer;
    }
  }
`;

const Body = styled.div`
  height: calc(100vh - 105px);
  overflow-x: hidden;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    box-shadow: inset 0 0 8px 8px #b5e4ec;
    border: solid 2px transparent;
  }
`;

const DefaultContent = styled.div`
  padding: 16px 32px;

  div {
    color: ${blue2};
    font-weight: 600;
    margin-bottom: 8px;
  }
  span {
    color: ${iceblue3};
  }
`;

const patentService = PatentService();
interface IProps {
  isLoading: boolean;
  patentData: PatentDataTypes;
  onPaneClose: () => void;
}

const ResultPreviewPane: React.FC<IProps> = props => {
  const patentContent = props.patentData.content;

  const [patentOcrLabel, setPatentOcrLabel] = useState<IGetPatentOcrLabelTypes | null>(null);
  const [patentDataCompareImage, setPatentDataCompareImage] = useState<number>(0);
  const [activeComparedPane, setActiveComparedPane] = useState<string>('PatentPreview');
  const [addSize, setAddSize] = useState<number>(1);

  const handlePatentOcrLabel = useCallback(() => {
    if (patentContent) {
      patentService.getPatentOcrLabel(patentContent.id).then(data => {
        if (data !== null) {
          setPatentOcrLabel(data);
        }
      });
    }
  }, [patentContent]);
  const toggleComparedPane = (activePane: string) => {
    setActiveComparedPane(activePane);
  };

  useEffect(() => {
    // 一進入預覽畫面就查詢OCR辨識結果
    activeComparedPane === 'PatentPreview' && handlePatentOcrLabel();
  }, [activeComparedPane, handlePatentOcrLabel]);

  useEffect(() => {
    // 切換專利則回到圖片預覽介面
    props.patentData && toggleComparedPane('PatentPreview');
  }, [props.patentData]);

  const patentPreviewPane = (
    <Container>
      <Header>
        <Title>
          {!props.isLoading &&
            patentContent &&
            patentContent.bibliographic.inventionTitle.title.join(' | ')}
        </Title>
        <IconBox>
          {patentContent && (
            <Link to={`/patent/${patentContent.id}/view`} target="_blank">
              <FaExternalLinkAlt />
            </Link>
          )}
          <FaTimes onClick={() => props.onPaneClose()} />
        </IconBox>
      </Header>
      <Body>
        {props.isLoading ? (
          <Spinner width="150px" margin="200px auto" />
        ) : (
          <>
            {props.patentData.content ? (
              <PatentContent
                template={templates.HalfPage}
                patentData={props.patentData}
                anchorIndex={2}
                toggleComparedPane={toggleComparedPane}
                setLightboxFirstShowImage={setPatentDataCompareImage}
                lightboxFirstShowImage={patentDataCompareImage}
                patentOcrLabel={patentOcrLabel}
                isPatentCompare={true}
              />
            ) : (
              <DefaultContent>
                <div>請選擇欲瀏覽之專利</div>
                <span>點擊左欄專利標題，即可快速檢視您搜尋的專利內容。</span>
              </DefaultContent>
            )}
          </>
        )}
      </Body>
    </Container>
  );

  const patentPreviewImagePane = patentContent && (
    <Container>
      <Header>
        <LeftTitle>
          <Title>
            <span>圖式</span>
          </Title>
          <IconBox>
            <FaSearchPlus size="16px" onClick={() => setAddSize(getImgSize(addSize, true))} />
            <FaSearchMinus size="16px" onClick={() => setAddSize(getImgSize(addSize, false))} />
          </IconBox>
        </LeftTitle>
        <IconBox>
          <FaTimes onClick={() => toggleComparedPane('PatentPreview')} />
        </IconBox>
      </Header>
      <Body>
        <ImageModal
          images={getImgUrls(patentContent.id, patentContent.img_alias, patentOcrLabel)}
          imageOnDisplay={patentDataCompareImage}
          isOpen={patentContent.img_alias && patentContent.img_alias.length !== 0}
          showThumbnails={true}
          data={patentOcrLabel}
          setAddSize={setAddSize}
          addSize={addSize}
        />
      </Body>
    </Container>
  );

  return (
    <>
      {activeComparedPane === 'PatentPreview' && patentPreviewPane}
      {activeComparedPane === 'PatentCompareOcrLabel' && patentPreviewImagePane}
    </>
  );
};

export default ResultPreviewPane;
