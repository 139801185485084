import { ITokenPayload, IAuthData } from 'src/apps/auth/Auth';
import { MEMBER_DECODE_TOKEN, IReduxMemberActionType, IMemberState } from './type';

const initAuth: IAuthData = {
  token: '',
  refreshToken: '',
  isLogin: false,
};
const initPayload: ITokenPayload = {
  exp: 0,
  info: {
    accountProfileId: -1,
    companyName: null,
    email: '',
    guest: true,
    id: -1,
    industrialClassIds: [],
    name: '',
    parentId: null,
    type: 'P',
    ipkmAccount: true,
  },
  ip: '127.0.0.1',
  nbf: 0,
  permissionLimits: {
    PATENT_COLLECTION: {
      CATEGORY_UPPER: 5,
      ITEM_UPPER: 300,
    },
    PATENT_SUBSCRIPTION: {
      ITEM_UPPER: 3,
    },
    RSS: {
      ITEM_UPPER: 3,
    },
  },
  permissions: [],
};

const initState: IMemberState = {
  memberPayload: initPayload,
  authData: initAuth,
};

export const memberReducer = (state = initState, action: IReduxMemberActionType): IMemberState => {
  switch (action.type) {
    case MEMBER_DECODE_TOKEN:
      return {
        ...state,
        memberPayload: action.payload,
        authData: action.authData,
      };
    default:
      return { ...state };
  }
};

export default memberReducer;
