import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { FaRegEye, FaExternalLinkAlt } from 'react-icons/fa';

import { iceblue1, iceblue8, iceblue2rgba } from 'src/style/theme/Color';
import { Carousel } from 'src/style/ResultGridItemStyle';
import PatentContentModal from 'src/components/ui/interactive/modal/custom/PatentConentModal';
import BasicCarouselContent from './BasicCarouselContent';
import { IGetPatentTypes } from 'src/types/IGetPatentTypes';
import { PatentDataTypes } from 'src/types/api/PatentApiTypes';
import { PatentService } from 'src/api/Patent';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';

import { connect } from 'react-redux';
import { ReduxAppState } from 'src/redux/reducers';
import { openCustomModal } from 'src/redux/actions/modalAction';
import { CustomModalType } from 'src/types/ModalTypes';

const Overlay = styled.div<{ isShow: boolean; isDetailShow: boolean }>`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  ${props =>
    !props.isDetailShow &&
    css`
      display: flex;
      align-items: center;
      justify-content: space-between;
    `}
  background-color: ${iceblue8};
  transition: 0.5s ease;
  opacity: ${props => (props.isShow ? 1 : 0)};
`;

const BaseInfo = styled.div`
  padding: 8px 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${iceblue1};
  font-weight: 500;
`;

const DetailInfo = styled.div`
  padding: 4px 16px 0 16px;
  user-select: text;

  div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${iceblue1};
    margin-bottom: 4px;

    span {
      font-weight: 500;
      margin-right: 4px;
    }
  }
`;

const IconsBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${iceblue2rgba(0.8)};
`;

const IconBox = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  padding: 8px 16px;

  svg {
    margin-right: 4px;
  }

  :hover {
    cursor: pointer;
  }

  a:link,
  a:visited {
    color: inherit;
  }

  /** 窄螢幕時減少 padding 以增加申請人文字顯示空間。 */
  @media screen and (max-width: 1280px) {
    padding: 8px 8px;
  }
`;

interface IReduxMappingProps {
  openCustomModal: (customModalType: CustomModalType, customModalId?: number) => void;
  customModalType: CustomModalType;
  customModalId?: number;
}

interface IProps extends IReduxMappingProps {
  patent: IGetPatentTypes;
  listIndex?: number;
}

/** 帶有 Overlay 的輪播 (for 以圖找圖) */
const OverlayCarousel = ({
  patent,
  listIndex,
  openCustomModal,
  customModalType,
  customModalId,
}: IProps) => {
  const [isOverlayShow, setIsOverlayShow] = useState<boolean>(false);
  const [isOverlayDetailShow, setIsOverlayDetailShow] = useState<boolean>(false);
  const [isPatentDataLoading, setIsPatentDataLoading] = useState<boolean>(false);
  const [patentData, setPatentData] = useState<PatentDataTypes>({ content: null, ipc: [] });

  // 專利相關資訊
  const applicantNames =
    patent.bibliographic.parties &&
    patent.bibliographic.parties.applicant &&
    patent.bibliographic.parties.applicant.addressbook.name;
  const applicationNumber =
    patent.bibliographic.applicationReference &&
    patent.bibliographic.applicationReference.documentId.docNumber;

  const isModalOpen =
    customModalType === CustomModalType.PATENT_PRINT && customModalId === listIndex;

  const handleModalPreview = () => {
    fetchPatentData(patent.id);
    openCustomModal(CustomModalType.PATENT_PRINT, listIndex);
  };

  const fetchPatentData = async (uuid: string) => {
    setIsPatentDataLoading(true);
    try {
      const patentData = await PatentService().getPatentData(uuid);
      setPatentData(patentData);
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsPatentDataLoading(false);
    }
  };

  return (
    <>
      <Carousel
        // onMouseOver、onMouseOut 帶有 propagation 事件，會造成比onMouseEnter、onMouseLeave 更多事件觸發，
        // 但在此處:
        // onMouseOver 可使 Modal 關閉且游標正處於 Carousel 上方時重新show overlay
        // onMouseOut 可讓 滑鼠快速移動時 Overlay 正常隱藏
        onMouseOver={() => setIsOverlayShow(true)}
        onMouseOut={() => setIsOverlayShow(false)}
      >
        <BasicCarouselContent patent={patent} isOverlayDetailShow={isOverlayDetailShow} />
        <Overlay
          isShow={isOverlayShow}
          isDetailShow={isOverlayDetailShow}
          onMouseEnter={() => setIsOverlayDetailShow(true)}
          onMouseLeave={() => setIsOverlayDetailShow(false)}
        >
          {isOverlayDetailShow ? (
            <DetailInfo>
              <div>
                <span>申請人 :</span> {applicantNames && applicantNames[0]}
              </div>
              <div>
                <span>申請號 :</span> {applicationNumber}
              </div>
            </DetailInfo>
          ) : (
            <BaseInfo>{applicantNames && applicantNames[0]}</BaseInfo>
          )}
          <IconsBlock>
            <IconBox onClick={handleModalPreview}>
              <FaRegEye />
              預覽
            </IconBox>
            <IconBox>
              <Link to={`/patent/${patent.id}/view`} target="_blank">
                <FaExternalLinkAlt />
                進入頁面
              </Link>
            </IconBox>
          </IconsBlock>
        </Overlay>
      </Carousel>
      {isModalOpen && (
        <PatentContentModal patentData={patentData} isLoading={isPatentDataLoading} />
      )}
    </>
  );
};

const mapStateToProps = (state: ReduxAppState) => {
  return {
    customModalType: state.modalReducer.customModalType,
    customModalId: state.modalReducer.customModalId,
  };
};

const mapDispatchToProps = {
  openCustomModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OverlayCarousel);
