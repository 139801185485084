import React, { useState, useCallback, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { FaRedo, FaSearchMinus, FaSearchPlus, FaUndo } from 'react-icons/fa';
import ReactImageCrop from 'react-image-crop';
import format from 'date-fns/format';
import 'react-image-crop/dist/ReactCrop.css';

import { Backdrop } from 'src/style/ModalStyle';
import { zModal } from 'src/style/theme/Z-Index';
import { closeCustomModal } from 'src/redux/actions/modalAction';
import { alertMessage } from 'src/utils/ModalUtils';
import { SystemMessage } from 'src/apps/applicationMessages';

import { Button } from 'src/style/theme/Common';
import { blue3, blue6, iceblue1, iceblue7, ultrawhite, iceblue8 } from 'src/style/theme/Color';

import { connect } from 'react-redux';
import { IObjectDetectBlock, IObjectDetectResult } from 'src/types/TrademarkTypes';
import { debounce, isEqual } from 'lodash';

const Container = styled.div`
  border-radius: 4px;
  background-color: ${ultrawhite};
`;
const Header = styled.div`
  width: 100%;
  padding: 16px 32px;
  color: ${iceblue1};
  display: flex;
  justify-content: space-between;
  div {
    min-width: 160px;
    display: flex;
    align-items: center;
  }
  div:nth-child(1) {
    font-size: 20px;
    font-weight: 600;
  }
  div:nth-child(2) {
    text-align: center;
  }
  div:nth-child(3) {
    justify-content: flex-end;
  }
`;
const AdjustBtn = styled.i<{ disable: boolean }>`
  margin: 0 8px 0 0;
  display: block;
  svg {
    margin: 0 8px 0 0;
    font-size: 20px;
    color: ${({ disable }) => (disable ? iceblue7 : blue3)};
    vertical-align: middle;
    cursor: ${({ disable }) => (disable ? 'not-allowed' : 'pointer')};
  }
`;
const Body = styled.div`
  margin: 0 32px;
  width: 928px;
  min-height: 240px;
  height: calc(100vh - 240px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${iceblue8};
`;
const Content = styled.div`
  max-width: calc(100% - 8px);
  max-height: 100%;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    box-shadow: inset 0 0 8px 8px ${blue6};
    border: solid 2px transparent;
  }

  @media (min-width: 1024px) {
    max-width: calc(100vw - 360px);
  }
`;
const Footer = styled.div`
  margin: 16px 32px 32px;
`;

const Block = styled.div<IImgBlock>`
  border: 3px solid red;
  width: ${props => props.width};
  height: ${props => props.height};
  position: absolute;
  left: ${props => props.x};
  top: ${props => props.y};
  ${props => !props.visible && 'display: none;'};
  pointer-events: none;
`;

const BlockInfo = styled.div`
  pointer-events: none;
  position: absolute;
  top: -24px;
  color: white;
  background-color: red;
  left: -3px;
  text-wrap: nowrap;
`;

interface IReduxMappingProps {
  closeCustomModal: () => void;
}

const modal = document.getElementById('modal-custom');
const portalDiv = modal ? modal : document.createElement('div');

interface IImgSize {
  width: number;
  height: number;
}

interface IImgBlock {
  x: string;
  y: string;
  height: string;
  width: string;
  visible: boolean;
}

interface IProps extends IReduxMappingProps {
  imgSrc: string | undefined;
  setCroppedImg: React.Dispatch<React.SetStateAction<string | undefined>>;
  crop: ReactImageCrop.Crop | undefined; // 裁切位置
  setCrop: React.Dispatch<React.SetStateAction<ReactImageCrop.Crop | undefined>>;
  setCroppedImgFile: React.Dispatch<React.SetStateAction<File | undefined>>;
  rotate?: boolean; // 是否提供 rotate 功能
  rotateDeg?: number; // 圖片旋轉角度
  setRotateDeg?: React.Dispatch<React.SetStateAction<number>>;
  multiplier?: number; // 圖片縮放比例
  setMultiplier?: React.Dispatch<React.SetStateAction<number>>;
  detectObject?: (
    image: File,
    scaleX: number,
    scaleY: number,
    originX?: number | undefined,
    originY?: number | undefined,
  ) => Promise<IObjectDetectResult>;
}

const CropImageModal: React.FC<IProps> = ({
  imgSrc,
  setCroppedImg,
  closeCustomModal,
  crop: propsCrop,
  setCrop: setPropsCrop,
  setCroppedImgFile: setPropsCroppedImgFile,
  rotate = false,
  rotateDeg: propsRotateDeg = 0,
  setRotateDeg: setPropsRotateDeg,
  multiplier: propsMultiplier = 1,
  setMultiplier: setPropsMultiplier,
  detectObject: propsDetectObject,
}) => {
  // react-image-crop needs 'HTMLImageElement', not 'Ref.Object<HTMLImageElement>'
  const [imageRef, setImageRef] = useState<HTMLImageElement>();
  const [imgSize, setImgSize] = useState<IImgSize>(); // 根據畫面最大長寬限制調整後的固定大小
  const [adjImgSize, setAdjImgSize] = useState<IImgSize>(); // 經過旋轉縮放調整後的大小
  const [imgCss, setImgCss] = useState<React.CSSProperties>({ backgroundColor: ultrawhite });
  const [crop, setCrop] = useState<ReactImageCrop.Crop | undefined>(propsCrop);
  const [disableZoomOut, setDisableZoomOut] = useState(false);
  const [disableZoomIn, setDisableZoomIn] = useState(false);
  const [imgSrcTemp, setImgSrcTemp] = useState(imgSrc);
  const maxWidth = 880;
  const minHeight = 160;
  const [rotateDeg, setRotateDeg] = useState(propsRotateDeg);
  const [multiplier, setMultiplier] = useState(propsMultiplier);
  const [showBlock, setShowBlock] = useState(true);
  const [imgBlocks, setImgBlocks] = useState<IObjectDetectResult>();
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    setImgBlocks(undefined);
  }, [multiplier, rotateDeg]);

  /* 旋轉圖片。*/
  const rotateImg = (degree: number) => {
    degree = (rotateDeg + degree) % 360;
    rotateAndDrawImage(degree);
    setRotateDeg(degree);
  };

  /* 旋轉並替換 ReactImageCrop 的圖。*/
  const rotateAndDrawImage = (degree: number) => {
    let img = new Image();
    if (imgSrc) {
      img.src = imgSrc;
      let canvas = document.createElement('canvas');
      img.onload = () => {
        const canvasSize = getRotatedSize(degree, {
          width: img.naturalWidth,
          height: img.naturalHeight,
        });
        if (canvasSize) {
          canvas.width = canvasSize.width;
          canvas.height = canvasSize.height;
        }

        var ctx = canvas.getContext('2d');

        if (ctx) {
          ctx.fillStyle = '#fff';
          ctx.fillRect(0, 0, canvas.width, canvas.height);
          ctx.translate(canvas.width / 2, canvas.height / 2); // 將座標中心點移到 canvas 正中央(也就是圖的中心點)
          ctx.rotate(degree * (Math.PI / 180));
          ctx.translate(-img.naturalWidth / 2, -img.naturalHeight / 2); // 將座標中心點移回去
          ctx.drawImage(img, 0, 0); // 將圖片繪製在 canvas 上
        }
        setImgSrcTemp(canvas.toDataURL('image/png'));
      };
    }
  };

  const onImageLoaded = (img: HTMLImageElement) => {
    setImageRef(img);
    let size = imgSize;
    // 只有第一次會執行
    if (imageRef == null) {
      const initMaxWidth = Math.round(window.screen.width * 0.3);
      const initMaxHeight = Math.round(window.screen.height * 0.5);
      //  寬度採用初始最大寬度, 初始高度按照寬度比例縮放
      size = { width: initMaxWidth, height: img.height * (initMaxWidth / img.width) };
      // 如果初始高度大於初始最大高度, 則改用初始最大高度, 寬度按照比例縮放
      if (size.height > initMaxHeight) {
        size.height = initMaxHeight;
        size.width = img.width * (initMaxHeight / img.height);
      }

      if (size.width > maxWidth) {
        size.width = maxWidth;
        size.height = img.height * (maxWidth / img.width);
        setDisableZoomIn(true);
      }
      if (size.height < minHeight) {
        setDisableZoomOut(true);
      }
      rotateAndDrawImage(rotateDeg);
      // imgSize 在圖第一次載入計算後便固定, 不再更動
      setImgSize({ ...size });
    }
    // 取得轉向後寬高
    let newSize = getRotatedSize(rotateDeg, size);
    newSize = getZoomedSize(propsMultiplier, newSize);
    newSize && setAdjImgSize({ ...newSize });
    // imgCss 影響最終畫面上顯示的寬高
    // 一定要直接設成要顯示的模樣(newSize), 如果設成 size，並將圖先放大再 crop 超出原 size 的位置，下次重新開啟裁切視窗時 crop 會跑位)
    newSize &&
      setImgCss({
        ...imgCss,
        width: `${newSize.width}px`,
        height: `${newSize.height}px`,
      });
    debouncedDetect();
  };

  const onCropChange = (cropConfig: ReactImageCrop.Crop) => {
    setCrop({ ...cropConfig });
  };

  /** for IE 11 image transform */
  const handleDataUrltoFile = (dataUrl: string, filename: string) => {
    // dataUrl 範例: 'data:image/png;base64,R0lGODlhCwALAIAAAAAA3pn/ZiH5BAEAAAEALAAAAAALAAsAAAIUhA+hkcuO4lmNVindo7qyrIXiGBYAOw=='
    const arr = dataUrl.split(',');
    const dataUrlArr = arr[0].match(/:(.*?);/);
    if (!dataUrlArr) {
      return new File([], filename, { type: 'image/png' });
    }
    const mime = dataUrlArr[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const getCroppedImg = useCallback(async (image: any, cropConfig: any): Promise<
    [string, File]
  > => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width; // 圖片原始寬度/畫面顯示的寬度
    const scaleY = image.naturalHeight / image.height;
    canvas.width = cropConfig.width * scaleX; // 裁切範圍在原始圖之寬度
    canvas.height = cropConfig.height * scaleY;
    const ctx = canvas.getContext('2d');

    if (ctx) {
      ctx.translate(-cropConfig.x * scaleX, -cropConfig.y * scaleY); //將裁切區塊移置畫布顯示區塊
      ctx.drawImage(
        image,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
      );
    }

    return new Promise<[string, File]>(resolve => {
      const imgFormat = 'png';
      const imgMime = `image/${imgFormat}`;
      const generateFilename = format(new Date(), 'yyyyMMddHHmmssSSS') + `.${imgFormat}`;
      if (!!window.navigator.msSaveBlob) {
        // IE (保存blob作為本地文件，msSaveBlob函數只在IE有效)
        const msDataUrl = canvas.toDataURL(imgMime);
        const msFile = handleDataUrltoFile(msDataUrl, generateFilename);
        if (msFile.size === 0) {
          console.error('Canvas transform fail');
          return;
        }
        resolve([msDataUrl, msFile]);
      } else {
        canvas.toBlob((blob: Blob | null) => {
          if (blob) {
            const dataUrl = window.URL.createObjectURL(blob);
            resolve([dataUrl, new File([blob], generateFilename)]);
          } else {
            console.error('Canvas is empty');
            return;
          }
        }, imgMime);
      }
    });
  }, []);

  const makeClientCrop = useCallback(
    async (inputCropConfig: ReactImageCrop.Crop) => {
      if (imageRef && inputCropConfig.width && inputCropConfig.height) {
        const [url, croppedImgFile] = await getCroppedImg(imageRef, inputCropConfig);
        setPropsCroppedImgFile(croppedImgFile);
        rotate && setPropsRotateDeg && setPropsRotateDeg(rotateDeg);
        setPropsMultiplier && setPropsMultiplier(multiplier);
        // react-image-crop 不會幫忙釋放記憶體, 須自行釋放
        setCroppedImg(prev => {
          prev && window.URL.revokeObjectURL(prev);
          return url;
        });
      }
    },
    [
      imageRef,
      getCroppedImg,
      setPropsCroppedImgFile,
      rotate,
      setPropsRotateDeg,
      rotateDeg,
      setPropsMultiplier,
      multiplier,
      setCroppedImg,
    ],
  );

  const handleBrowserCrop = (cropConfig: ReactImageCrop.Crop) => {
    setPropsCrop({ ...cropConfig }); // 更新至父元素
    cropConfig && makeClientCrop(cropConfig).then(() => closeCustomModal());
  };

  const handleCrop = () => {
    if (crop && crop.height !== 0 && crop.width !== 0) {
      handleBrowserCrop(crop);
    } else {
      alertMessage(SystemMessage.SEARCH_BY_IMAGE_EMPTY_CROP);
    }
  };

  // 選擇全圖
  const handleCropAll = () => {
    if (adjImgSize) {
      const cropAllConfig = {
        x: 0,
        y: 0,
        width: adjImgSize.width,
        height: adjImgSize.height,
        unit: 'px',
      } as ReactImageCrop.Crop;
      setPropsCrop(cropAllConfig); // 更新至父元素
      makeClientCrop(cropAllConfig).then(() => closeCustomModal());
    } else {
      alertMessage(SystemMessage.SEARCH_BY_IMAGE_READER_ERROR);
    }
  };

  /* 計算旋轉後之寬高。*/
  const getRotatedSize = (degree: number, size: IImgSize | undefined) => {
    let rads = (degree * Math.PI) / 180;
    let c = Math.abs(Math.cos(rads));
    let s = Math.abs(Math.sin(rads));
    let result;

    if (size) {
      result = {
        width: size.height * s + size.width * c,
        height: size.height * c + size.width * s,
      };
    }

    return result;
  };

  const handleCancel = () => {
    closeCustomModal();
  };

  const handleZoomIn = () => {
    const multiplied = multiplier * 1.1;
    setMultiplier(multiplied);
    handleZoom(1.1);
  };
  const handleZoomOut = () => {
    const multiplied = multiplier * 0.9;
    setMultiplier(multiplied);
    handleZoom(0.9);
  };

  const getZoomedSize = (multiplierParam: number, currentSize: IImgSize | undefined) => {
    let size;
    if (currentSize) {
      size = {
        width: currentSize.width * multiplierParam,
        height: currentSize.height * multiplierParam,
      };

      // 縮放後寬度不可超過最大寬度
      if (currentSize.width * multiplierParam > maxWidth) {
        size.width = maxWidth;
        size.height = currentSize.height * (maxWidth / currentSize.width);
        setDisableZoomIn(true);
      } else {
        setDisableZoomIn(false);
      }

      setDisableZoomOut(false);
      // 縮放後高度不可小於最小高度
      if (imgSize && currentSize.height * multiplierParam < minHeight) {
        // 如果縮放後高度小於原始圖之高度
        if (imgSize.height > currentSize.height * multiplierParam) {
          setDisableZoomOut(true);
          if (imgSize.height < minHeight) {
            size.width = currentSize.width * (imgSize.height / currentSize.height);
            size.height = imgSize.height;
          } else {
            size.width = currentSize.width;
            size.height = currentSize.height;
          }
        }
      }
    }

    return size;
  };

  const handleZoom = (multiplierParam: number) => {
    setCrop(undefined);
    if (adjImgSize) {
      const newSize = getZoomedSize(multiplierParam, adjImgSize);
      setAdjImgSize(newSize);
      newSize &&
        setImgCss({
          ...imgCss,
          width: `${newSize.width}px`,
          height: `${newSize.height}px`,
        });
      debouncedDetect();
    } else {
      alertMessage(SystemMessage.SEARCH_BY_IMAGE_READER_ERROR);
    }
  };

  const contentRef = useRef<HTMLDivElement>(null);
  const currentCrop = useRef<ReactImageCrop.Crop>();
  const detectObject = async () => {
    if (propsDetectObject && !isDragging) {
      // isDragging 解決加入 debounce與 currentCrop 機制後偶爾會有 crop 在 drag 中途的情況
      if (imageRef != null) {
        try {
          let customCrop =
            crop && crop.height && crop.width
              ? crop
              : {
                  ...crop,
                  x: 0,
                  y: 0,
                  width: imageRef.width,
                  height: imageRef.height,
                };
          currentCrop.current = { ...customCrop };
          let [, cropped] = await getCroppedImg(imageRef, customCrop);
          const result = await propsDetectObject(
            cropped,
            imageRef.width / imageRef.naturalWidth,
            imageRef.height / imageRef.naturalHeight,
            customCrop.x,
            customCrop.y,
          ); // 呼叫父層
          // 如果沒有偵測出物件則框出整個crop
          if (result.blocks.length === 0) {
            result.blocks = [
              {
                x1: customCrop.x || 0,
                y1: customCrop.y || 0,
                x2: (customCrop.x || 0) + (customCrop.width || 0),
                y2: (customCrop.y || 0) + (customCrop.height || 0),
                confidence: 1,
              } as IObjectDetectBlock,
            ];
          }
          if (isEqual(currentCrop.current, customCrop)) {
            // 只有當現行的crop與呼叫API時相同時才改變值，避免下一個API在呼叫途中顯示前一個API結果
            setImgBlocks(result);
          }
        } catch (error) {
          alertMessage(SystemMessage.OBJECT_DETECT_ERROR);
        }
      }
    }
  };

  const detectFuncRef = useRef<() => void>();
  detectFuncRef.current = detectObject; // 為確保detectObject內使用的都是最新值
  const debouncedDetect = useCallback(
    debounce(() => detectFuncRef.current!(), 500),
    [], // debounce 函數只進行一次初始化，否則會失效
  );

  return ReactDOM.createPortal(
    <Backdrop zIndex={zModal.custom} alignItems="center">
      <Container>
        <Header>
          <div>裁切圖片範圍</div>
          <div>{`選取範圍: ${crop && crop.width ? Math.floor(crop.width) : 0}px * ${
            crop && crop.height ? Math.floor(crop.height) : 0
          }px`}</div>
          <div>
            {rotate && (
              <>
                <AdjustBtn disable={disableZoomOut}>
                  <FaUndo onClick={() => rotateImg(-90)} />
                </AdjustBtn>
                <AdjustBtn disable={disableZoomIn}>
                  <FaRedo onClick={() => rotateImg(90)} />
                </AdjustBtn>
              </>
            )}
            <AdjustBtn disable={disableZoomOut}>
              <FaSearchMinus onClick={handleZoomOut} />
            </AdjustBtn>
            <AdjustBtn disable={disableZoomIn}>
              <FaSearchPlus onClick={handleZoomIn} />
            </AdjustBtn>
          </div>
        </Header>
        <Body>
          <Content ref={contentRef as any} onMouseDown={() => setShowBlock(false)}>
            {imgSrcTemp && (
              <>
                <ReactImageCrop
                  src={imgSrcTemp}
                  crop={crop}
                  onImageLoaded={onImageLoaded}
                  onChange={onCropChange}
                  imageStyle={imgCss}
                  onDragStart={() => {
                    setIsDragging(true);
                    if (propsDetectObject) {
                      setShowBlock(false);
                      setImgBlocks(undefined); // 否則拖曳結束後到得到新的結果前會跳出顯示舊的框
                    }
                  }}
                  onDragEnd={() => {
                    setIsDragging(false);
                    debouncedDetect();
                    propsDetectObject && setShowBlock(true);
                  }}
                  ruleOfThirds
                >
                  {imgBlocks &&
                    imgBlocks.blocks.map(block => (
                      <Block
                        visible={showBlock}
                        width={(block.x2 - block.x1).toString() + 'px'}
                        height={(block.y2 - block.y1).toString() + 'px'}
                        x={block.x1.toString() + 'px'}
                        y={block.y1.toString() + 'px'}
                        key={block.x1 + ',' + block.y1 + ',' + block.x2 + ',' + block.y2}
                      >
                        {/* 如果有需要顯示數值 */}
                        <BlockInfo />
                      </Block>
                    ))}
                </ReactImageCrop>
              </>
            )}
          </Content>
        </Body>
        <Footer>
          <Button type="button" template="primary" onClick={handleCrop}>
            裁切
          </Button>
          <Button type="button" template="primary-light" onClick={handleCropAll}>
            選擇全圖
          </Button>
          <Button type="button" onClick={handleCancel}>
            取消
          </Button>
        </Footer>
      </Container>
    </Backdrop>,
    portalDiv,
  );
};

const mapDispatchToProps = {
  closeCustomModal,
};

export default connect(
  null,
  mapDispatchToProps,
)(CropImageModal);
