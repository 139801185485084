import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { IGetPatentViewed } from 'src/types/api/MemberApiTypes';
import { dateFormatter, millisFormatter } from 'src/utils/Formatter';

// Layout
const Container = styled.div`
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  :hover {
    background-color: #f5fdfe;
  }
`;
const PatentInfo = styled.div`
  padding: 0 0 0 32px;
  width: 80%;
  display: flex;
`;
const PatentLeftContainer = styled.div`
  padding: 16px 8px 16px 0;
  width: 70%;
`;
const PatentRightContainer = styled.div`
  width: 30%;
  padding: 16px 0 16px 8px;
  img {
    width: 100%;
    max-height: 200px;
  }
`;
const Lasttime = styled.div`
  padding: 16px 16px 16px 8px;
  width: 20%;
`;

// Elements
const Title = styled.div`
  padding: 0 0 8px 0;
  font-size: 18px;
  a {
    color: #077790;
  }
`;
const Text = styled.div`
  color: #666666;
  padding: 0 0 4px 0;
`;

interface IProps {
  log: IGetPatentViewed;
}

const logItem: React.FC<IProps> = props => {
  const patData = props.log.solrPatentData;
  const documentId = patData.bibliographic.publicationReference.documentId;
  const notKindA =
    documentId.kind &&
    !documentId.kind.includes('A') &&
    !documentId.kind.includes('P1') &&
    !documentId.kind.includes('P4') &&
    !documentId.kind.includes('P9');
  return (
    <Container>
      <PatentInfo>
        <PatentLeftContainer>
          <Title>
            <Link to={`/patent/${props.log.patentId}/view`}>
              {patData.bibliographic.inventionTitle.title.join(' | ')}
            </Link>
          </Title>
          <Text>
            發明人：
            {patData.bibliographic.parties.inventor &&
              patData.bibliographic.parties.inventor.addressbook.name &&
              patData.bibliographic.parties.inventor.addressbook.name.join(' ')}
          </Text>
          <Text>
            申請人：
            {patData.bibliographic.parties.applicant &&
              patData.bibliographic.parties.applicant.addressbook.name &&
              patData.bibliographic.parties.applicant.addressbook.name.join(' ')}
          </Text>
          <Text>
            {notKindA ? '公告號：' : '公開號：'}
            {documentId && documentId.docNumber + documentId.kind}
          </Text>
          <Text>
            {notKindA ? '公告日：' : '公開日：'}
            {documentId && documentId.date ? dateFormatter(documentId.date) : '無日期資訊'}
          </Text>
        </PatentLeftContainer>
        <PatentRightContainer>
          {patData.img_alias && patData.img_alias.length > 0 && (
            <img
              src={
                process.env.REACT_APP_API_URL +
                'patent/' +
                props.log.patentId +
                '/img/' +
                patData.img_alias[0]
              }
              alt=""
            />
          )}
        </PatentRightContainer>
      </PatentInfo>
      <Lasttime>{millisFormatter(props.log.createTime, true)}</Lasttime>
    </Container>
  );
};

export default logItem;
