import React from 'react';
import { FaAngleDown, FaAngleUp, FaTimesCircle } from 'react-icons/fa';

import { numFormatter } from 'src/utils/Formatter';
import { IFacetFieldCount } from 'src/types/api/NewsApiTypes';
import useAccordion from './common/useAccordion';
import {
  Container,
  Header,
  TaggedHeader,
  Title,
  Panel,
  TagWithTooltip,
  FacetLinkBox,
  FacetLinkName,
  FacetLinkCount,
  NoFilteredResult,
} from 'src/style/AccordionStyle';

interface IProps {
  keyCode: string;
  title?: string;
  tag?: string;
  content: Array<IFacetFieldCount>;
  handleFacetClick: (keyCode: string, facetFieldCount?: IFacetFieldCount) => void;
}

/** 篩選-摺疊面板 */
const Accordion: React.FC<IProps> = props => {
  const { keyCode, title, tag, content } = props;

  const {
    isActive,
    searchKeyword,
    filteredContent,
    togglePanel,
    getHighlightedText,
  } = useAccordion(content);

  const isTagged = tag !== undefined;

  return (
    <Container>
      {isTagged ? (
        <TaggedHeader>
          <Title>{title}</Title>
          <TagWithTooltip placement="top" overlay={tag}>
            {tag}
          </TagWithTooltip>
          <FaTimesCircle size={20} onClick={() => props.handleFacetClick(keyCode)} />
        </TaggedHeader>
      ) : (
        <Header onClick={() => togglePanel()}>
          <Title>{title}</Title>
          {isActive ? <FaAngleUp size={20} /> : <FaAngleDown size={20} />}
        </Header>
      )}
      {!isTagged && (
        <Panel isShow={isActive}>
          {searchKeyword !== '' && filteredContent.length === 0 ? (
            <NoFilteredResult>查無 "{searchKeyword}" 的符合結果</NoFilteredResult>
          ) : (
            filteredContent.map(item => (
              <FacetLinkBox
                key={item.identity}
                onClick={() => props.handleFacetClick(keyCode, item)}
              >
                <FacetLinkName>{getHighlightedText(item.name, searchKeyword)}</FacetLinkName>
                <FacetLinkCount>({numFormatter(item.count)})</FacetLinkCount>
              </FacetLinkBox>
            ))
          )}
        </Panel>
      )}
    </Container>
  );
};

export default Accordion;
