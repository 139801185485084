import React from 'react';
import styled from 'styled-components';
import { blue1rgba, blue3 } from 'src/style/theme/Color';

const MiniImg = styled.div<{ bgImg: string; active?: boolean; size?: string }>`
  display: inline-block;
  margin: 4px;
  width: ${props => (props.size ? props.size : '69px')};
  height: ${props => (props.size ? props.size : '69px')};
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  background-image: ${props => 'url(' + props.bgImg + ')'};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border: ${props => props.active && `2px solid ${blue3}`};
  text-align: center;
  vertical-align: middle;
  color: #ffffff;
  cursor: pointer;
`;

const ImgMask = styled.div`
  background-color: ${blue1rgba(0.7)};
  width: inherit;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
`;

interface IProps {
  images: Array<string>;
  totalImg: number;
  openLightbox: any;
  changeDisplay?: any;
  activeImg?: number;
  size?: string;
}

class PatentMiniImage extends React.Component<IProps, {}> {
  render() {
    const miniImgs =
      this.props.activeImg !== undefined ? (
        //預覽模式：點前3張小圖更換大圖(被選到的小圖加框)，點第4張小圖開lightbox
        <div>
          {this.props.images.map((item, idx) => (
            <React.Fragment key={idx}>
              {idx < 3 || this.props.totalImg === 4 ? (
                <MiniImg
                  onClick={(e: any) => this.props.changeDisplay(e)}
                  data-idx={idx}
                  bgImg={item}
                  active={Number(this.props.activeImg) === idx}
                />
              ) : (
                <MiniImg
                  onClick={(e: any) => this.props.openLightbox(e)}
                  data-idx={idx}
                  bgImg={item}
                >
                  <ImgMask>
                    <div>
                      &#43;
                      {this.props.totalImg && this.props.totalImg - 3}
                    </div>
                  </ImgMask>
                </MiniImg>
              )}
            </React.Fragment>
          ))}
        </div>
      ) : (
        //一般模式：點每張小圖皆會開lightbox
        <div>
          {this.props.images.map((item, idx) => (
            <MiniImg
              size={this.props.size}
              onClick={(e: any) => this.props.openLightbox(e)}
              key={idx}
              data-idx={idx}
              bgImg={item}
            >
              {idx === 3 && (
                <ImgMask>
                  <div>
                    &#43;
                    {this.props.totalImg && this.props.totalImg - 3}
                  </div>
                </ImgMask>
              )}
            </MiniImg>
          ))}
        </div>
      );
    return <>{miniImgs}</>;
  }
}

export default PatentMiniImage;
