import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Button } from 'src/style/theme/Common';
import { ISearchRequest, IPatentFacet } from 'src/types/PatentSearchTypes';
import { alertMessage } from 'src/utils/ModalUtils';
import { SystemMessage } from 'src/apps/applicationMessages';
import { connect } from 'react-redux';
import { ReduxAppState } from 'src/redux/reducers';
import { ultrawhite } from 'src/style/theme/Color';
import Checkbox from 'src/components/ui/interactive/checkbox/Checkbox';
import Chart, { ChartType } from './chart/Chart';
import ChartModal from 'src/components/ui/interactive/modal/custom/ChartModal';
import { CustomModalType } from 'src/types/ModalTypes';
import { openCustomModal } from 'src/redux/actions/modalAction';
import {
  changeChartTitle,
  changeSelectedFacetField,
  changeSelectedAnalysisNum,
  changeSelectedChartType,
  clearStatisticsForm,
} from 'src/redux/result/toolbar/statistics/action';
import { patentFacetText } from 'src/constants/PatentSearchConstants';
import { IDropdownProps, ISelectedItem } from 'src/components/common/Dropdown';
import { SearchDropdown } from 'src/components/search/SearchDropdown';
import {
  Field,
  LabelField,
  QueryString,
  ButtonArea,
  Description,
} from 'src/style/ResultToolbarStyle';
import { CSVLink } from 'react-csv';

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const Title = styled.div`
  padding: 2px 0;
  margin-right: 8px;
  font-size: 20px;
  font-weight: 600;
  color: ${ultrawhite};
`;

const InlineField = styled(Field)`
  display: flex;
  align-items: center;
  > span,
  > div {
    margin: 0 8px 0 0;
  }
`;

const ChartTypeField = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 8px;

  > span {
    margin-right: 24px;
  }

  > div {
    flex: 1;
    /* 覆寫 CheckBox 的 style */
    > div {
      margin: 0 0 8px 0;
    }
  }
`;

const NarrowStyle = createGlobalStyle`
  ${Field}, ${ButtonArea} {
    margin-bottom: 16px;
  }
`;

interface IReduxProps {
  searchRequest: ISearchRequest;
  isChartModalOpen: boolean;
  openCustomModal: (customModalType: CustomModalType) => void;
  facets: Array<IPatentFacet>;
  chartTitle: string;
  selectedItem: ISelectedItem;
  selectedChartType: string;
  changeChartTitle: (chartTitle: string) => void;
  changeSelectedFacetField: (facetField: IDropdownProps['activeItem']) => void;
  changeSelectedAnalysisNum: (analysisNum: IDropdownProps['activeItem']) => void;
  changeSelectedChartType: (selectedChartType: string) => void;
  clearStatisticsForm: () => void;
}

interface IProps extends IReduxProps {}

/** 統計分析 */
const SubscriptionPane: React.FC<IProps> = ({
  searchRequest,
  isChartModalOpen,
  openCustomModal,
  facets,
  chartTitle,
  selectedItem,
  selectedChartType,
  changeChartTitle,
  changeSelectedFacetField,
  changeSelectedAnalysisNum,
  changeSelectedChartType,
  clearStatisticsForm,
}) => {
  const unit = 5; // 以 5 為單位
  const analysisNumItemsSize = 6; // 總共 6 個
  /** 分析數量下拉選單列表 */
  const analysisNumItems: IDropdownProps['items'] = Array.from(
    Array(analysisNumItemsSize),
    (e, i) => {
      const num = ((i + 1) * unit).toString();
      return {
        name: num,
        value: num,
      };
    },
  );
  /** 分析項目下拉選單列表 */
  const facetFieldItems: IDropdownProps['items'] = facets.map((item, idx) => ({
    name: patentFacetText.get(item.keyCode) || '',
    value: idx.toString(),
  }));

  /** 被選擇分析項目之統計資料物件 */
  const selectedFacetField = facets[parseInt(selectedItem.facetField.value) || 0];

  const handleChartAnalyze = () => {
    if (selectedItem.facetField.value === '') {
      alertMessage(SystemMessage.STATISTICS_ANALYSIS_ITEM_UNSELECTED);
    } else if (selectedChartType === '') {
      alertMessage(SystemMessage.STATISTICS_CHART_TYPE_UNSELECTED);
    } else {
      openCustomModal(CustomModalType.CHART);
    }
  };

  const getChartDataset = () => {
    const selectedAnalysisNum = parseInt(selectedItem.analysisNum.value);
    const prevData = selectedFacetField.patentFacetFieldCount.slice(0, selectedAnalysisNum);

    if (selectedChartType === ChartType.PIE_MERGE_OTHERS) {
      const postData = selectedFacetField.patentFacetFieldCount.slice(selectedAnalysisNum);
      const otherSum = postData.reduce((acc, cur) => acc + cur.count, 0);
      return otherSum > 0 ? prevData.concat({ name: '其他', count: otherSum }) : prevData;
    } else {
      return prevData;
    }
  };

  const handleCsvLinkClick = () => {
    if (selectedItem.facetField.value === '') {
      alertMessage(SystemMessage.STATISTICS_ANALYSIS_ITEM_UNSELECTED);
      return false;
    } else {
      return true;
    }
  };

  const chartModalTitle = chartTitle !== '' ? chartTitle : selectedChartType;
  const csvFileName = selectedItem.facetField.name + '.csv';
  const csvHeaders = [
    { label: selectedItem.facetField.name, key: 'name' },
    { label: '專利數量', key: 'count' },
  ];

  return (
    <>
      <NarrowStyle />
      <Header>
        <Title>統計分析</Title>
        {(chartTitle !== '' ||
          selectedItem.facetField.value !== '' ||
          selectedChartType !== '') && (
          <Button type="button" template="primary-dark" onClick={clearStatisticsForm}>
            清除
          </Button>
        )}
      </Header>
      <Field>
        <span>分析數據母體</span>
        <QueryString>{searchRequest.queryString}</QueryString>
      </Field>
      <LabelField>
        <span>圖表標題</span>
        <input
          type="text"
          name="chartTitle"
          maxLength={32}
          value={chartTitle}
          onChange={e => changeChartTitle(e.target.value)}
        />
      </LabelField>
      <Field>
        <span>分析項目</span>
        <SearchDropdown
          items={facetFieldItems}
          activeItem={selectedItem.facetField}
          handleOnclick={item => {
            changeSelectedFacetField(item);
          }}
          width="100%"
        />
      </Field>
      {selectedItem.facetField.value !== '' && (
        <InlineField>
          <span>項目數量前</span>
          <SearchDropdown
            items={analysisNumItems}
            activeItem={selectedItem.analysisNum}
            handleOnclick={item => {
              changeSelectedAnalysisNum(item);
            }}
            width="40%"
          />
          <span>項</span>
        </InlineField>
      )}
      <ChartTypeField>
        <span>圖表類型</span>
        <div>
          {Object.values(ChartType).map(item => {
            const checked = selectedChartType === item;
            return (
              <Checkbox
                key={item}
                name={item}
                checked={checked}
                color={ultrawhite}
                themeReverse={true}
              >
                <input
                  type="checkbox"
                  checked={checked}
                  onChange={() => changeSelectedChartType(item)}
                />
              </Checkbox>
            );
          })}
        </div>
      </ChartTypeField>
      <ButtonArea>
        <Button template="primary" onClick={handleChartAnalyze}>
          圖表分析
        </Button>
        <CSVLink
          filename={csvFileName}
          headers={csvHeaders}
          data={selectedFacetField.patentFacetFieldCount}
          onClick={handleCsvLinkClick}
        >
          <Button template="light">下載全部資料</Button>
        </CSVLink>
      </ButtonArea>
      <Description>數據可視化工具是種統計方式，可協助研究者更了解專利數據分布狀態。</Description>

      {isChartModalOpen && (
        <ChartModal
          title={chartModalTitle}
          extraDownloadItem={
            <CSVLink filename={csvFileName} headers={csvHeaders} data={getChartDataset()}>
              <div>CSV 資料表</div>
            </CSVLink>
          }
        >
          <Chart
            type={selectedChartType}
            fieldName={selectedItem.facetField.name}
            dataset={getChartDataset()}
          />
        </ChartModal>
      )}
    </>
  );
};

const mapStateToProps = (state: ReduxAppState) => {
  return {
    searchRequest: state.patentSearchReducer.request,
    isChartModalOpen: state.modalReducer.customModalType === CustomModalType.CHART,
    facets: state.patentFilterReducer.facets,
    chartTitle: state.resultToolbarStatisticsReducer.chartTitle,
    selectedItem: state.resultToolbarStatisticsReducer.selectedItem,
    selectedChartType: state.resultToolbarStatisticsReducer.selectedChartType,
  };
};

const mapDispatchToProps = {
  openCustomModal,
  changeChartTitle,
  changeSelectedFacetField,
  changeSelectedAnalysisNum,
  changeSelectedChartType,
  clearStatisticsForm,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SubscriptionPane);
