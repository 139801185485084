import React, { useState } from 'react';
import styled from 'styled-components';

import { Button } from 'src/style/theme/Common';
import useOutSideClick from 'src/hooks/useOutSideClick';

const EditActionDiv = styled.div`
  padding: 16px 32px;
  width: 300px;
  height: 218px;
  left: -150px;
  top: 36px;
  position: absolute;
  background-color: #eceff1;
  border-radius: 4px;
  box-shadow: 0 5px 50px 0 rgba(0, 63, 84, 0.2);
  z-index: 99;
  cursor: auto;

  &::before {
    content: '';
    position: relative;
    top: -47px;
    left: 154px;
    border-style: solid;
    border-width: 0 16px 16px 16px;
    border-color: transparent transparent #eceff1 transparent;
  }
`;
const TitleDiv = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #333333;
`;
const InputDiv = styled.div`
  margin-top: 16px;
  color: #333333;

  > input {
    width: 240px;
    height: 38px;
    font-size: 15px;
    margin-top: 4px;
    border-radius: 4px;
    border-width: 0px;
    padding: 8px;
  }
`;
const Footer = styled.div`
  padding: 16px 0;
  text-align: center;
`;

interface IProps {
  name: string;
  show: boolean;
  submit: (data: string) => void;
  setShow: (show: boolean) => void;
}

function EditCollection(props: IProps) {
  const [name, setName] = useState(props.name);
  const node = useOutSideClick(props);

  return (
    <EditActionDiv ref={node as any}>
      <TitleDiv>編輯收藏</TitleDiv>
      <InputDiv>
        <span>名稱設定</span>
        <input
          type="text"
          value={name}
          onChange={event => {
            setName(event.target.value);
          }}
        />
      </InputDiv>
      <Footer>
        <Button template="primary" onClick={() => props.submit(name)}>
          修改
        </Button>
        <Button onClick={() => props.setShow(false)}>取消</Button>
      </Footer>
    </EditActionDiv>
  );
}

export default EditCollection;
