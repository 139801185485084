import React from 'react';
import styled from 'styled-components';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';

import MobileMenuModal from 'src/components/mobile/modal/MobileMenuModal';
import { useWindowScroll } from 'src/hooks/useWindowScroll';

import { ReactComponent as LogoWhite } from 'src/assets/images/ipkm-logo-white.svg';
import { blue3, ultrawhite } from 'src/style/theme/Color';
import { zTopNav } from 'src/style/theme/Z-Index';
import { CustomModalType } from 'src/types/ModalTypes';

import { connect } from 'react-redux';
import { ReduxAppState } from 'src/redux/reducers';
import { openCustomModal, closeCustomModal } from 'src/redux/actions/modalAction';

const Container = styled.header<{ hasScrolled?: boolean }>`
  color: ${ultrawhite};
  z-index: ${zTopNav};
  height: ${props => (props.hasScrolled ? '53px' : '92px')};
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-image: linear-gradient(110deg, #2ec3c2, ${blue3});
`;

const Title = styled.div`
  display: flex;
  align-items: center;

  padding: 12px 52px 12px 16px;

  > div {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
      margin-right: 8px;
    }
  }

  > svg {
    margin-right: 16px;
    cursor: pointer;
  }
`;

const ActiveNav = styled.div`
  padding: 0 16px;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  letter-spacing: 4px;
`;

interface IReduxMappingProps {
  isModalOpen: boolean;
  openCustomModal: (customModalType: CustomModalType) => void;
  closeCustomModal: () => void;
}

interface IProps extends IReduxMappingProps, RouteComponentProps {}

const navs = [
  { section: '專利專區', path: '/showcase' },
  { section: '專利檢索', path: '/search' },
  { section: '新聞專區', path: '/news' },
  { section: '開放資料', path: '/data' },
  { section: '專欄文章', path: '/article' },
  { section: '學界專家', path: '/expert' },
  { section: '常見問題', path: '/question' },
];

const MobileNav: React.FC<IProps> = ({
  isModalOpen,
  openCustomModal,
  closeCustomModal,
  location,
}) => {
  const [hasScrolled] = useWindowScroll(0);
  const activeNav = navs.find(item => location.pathname.startsWith('/lite' + item.path));
  const activeNavSection = activeNav && activeNav.section;

  return (
    <Container hasScrolled={hasScrolled}>
      {hasScrolled ? (
        <Title>
          <FaBars size={20} onClick={() => openCustomModal(CustomModalType.MOBILE_MENU)} />
          <ActiveNav>{activeNavSection}</ActiveNav>
        </Title>
      ) : (
        <>
          <Title>
            <FaBars size={20} onClick={() => openCustomModal(CustomModalType.MOBILE_MENU)} />
            <div>
              <LogoWhite /> 產業專利知識平台
            </div>
          </Title>
          <ActiveNav>{activeNavSection}</ActiveNav>
        </>
      )}

      {isModalOpen && (
        <MobileMenuModal activeNavSection={activeNavSection} onClose={closeCustomModal} />
      )}
    </Container>
  );
};

const mapStateToProps = (state: ReduxAppState) => {
  return {
    isModalOpen: state.modalReducer.customModalType === CustomModalType.MOBILE_MENU,
  };
};

const mapDispatchToProps = {
  openCustomModal,
  closeCustomModal,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(MobileNav),
);
