import {
  FETCH_DOCDB_RESULT_START,
  FETCH_DOCDB_RESULT_SUCCESS,
  FETCH_DOCDB_RESULT_ERROR,
  FETCH_DOCDB_NEXT_PAGE_RESULT_ERROR,
  CLEAR_DOCDB_RESULT,
  DocdbResultState,
  DocdbResultActionTypes,
} from 'src/redux/types/docdbResultTypes';
import { defaultPaginatedData } from 'src/types/defaultData/paginatedDataDefault';

const initialState: DocdbResultState = {
  isLoading: false,
  paginatedData: defaultPaginatedData,
};

const docdbResultReducer = (
  state = initialState,
  action: DocdbResultActionTypes,
): DocdbResultState => {
  switch (action.type) {
    case FETCH_DOCDB_RESULT_START:
      return { ...state, isLoading: true, nextPageErrMsg: undefined };
    case FETCH_DOCDB_RESULT_SUCCESS: {
      let { data, status } = action.response;
      if (status === 200) {
        if (data.pageNo === 1) {
          return { ...state, paginatedData: data, isLoading: false };
        } else {
          // 第二頁以後只渲染專利資料List
          const paginatedData = state.paginatedData;
          return {
            ...state,
            paginatedData: {
              ...paginatedData,
              costTime: data.costTime,
              data: paginatedData.data.concat(data.data),
              pageNo: data.pageNo,
            },
            isLoading: false,
          };
        }
      } else {
        return { ...initialState };
      }
    }
    case FETCH_DOCDB_RESULT_ERROR:
      return { ...initialState };
    case FETCH_DOCDB_NEXT_PAGE_RESULT_ERROR:
      return {
        ...state,
        isLoading: false,
        nextPageErrMsg: action.nextPageErrMsg,
      };
    case CLEAR_DOCDB_RESULT:
      return {
        ...initialState,
      };
    default:
      return { ...state };
  }
};

export default docdbResultReducer;
