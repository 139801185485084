import React from 'react';
import styled from 'styled-components';

import { IGetRssLatest } from 'src/types/api/IndexApiTypes';
import useRssFeedNews from 'src/hooks/useRssFeedNews';
import { pink3, blue7, ultrawhite } from 'src/style/theme/Color';
import DEFAULT_IMG from 'src/assets/images/news-default.jpg';

const Header = styled.div<{ url: string }>`
  background-image: url('${props => props.url || DEFAULT_IMG}');
  background-color: ${blue7};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: ${props => (props.url ? 'top' : 'center')};
  border-radius: 4px 4px 0 0;
  height: 180px;
`;
const Body = styled.div`
  padding: 16px 30px 0 30px;
  height: 176px;
`;
const Source = styled.div`
  padding: 3px 0 3px 0;
  height: 20px;
  font-size: 14px;
  opacity: 0.8;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const MultiLineEllipsisText = styled.div`
  margin: 0 -1rem 0 0;
  padding: 0 1rem 0 0;
  width: calc(100% - 1rem);
  max-height: 3.6rem;
  line-height: 1.2rem;
  overflow: hidden;
  position: relative;
  word-break: break-all;
  :before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
  }
  :after {
    content: '';
    position: absolute;
    right: 0;
    width: 1rem;
    height: 1rem;
    margin-top: 0.2rem;
    background: #0f96a9;
  }
`;
const Title = styled(MultiLineEllipsisText)`
  font-weight: 900;
  margin: 12px 0;
  cursor: pointer;
  max-height: 2.4rem;
  :after {
    background: #0b8ba1;
  }
`;
const Abstract = styled(MultiLineEllipsisText)`
  opacity: 0.8;
`;

const Footer = styled.div`
  padding: 0 30px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;
const InspectBtn = styled.a`
  margin: 10px 0 0;
  min-width: 64px;
  height: 32px;
  display: block;
  border-radius: 4px;
  color: ${ultrawhite};
  background-color: ${pink3};
  text-align: center;
  line-height: 32px;
  cursor: pointer;
`;
const Entities = require('html-entities').Html5Entities;
const htmlEntities = new Entities();
const XmlEntities = require('html-entities').XmlEntities;
const xmlEntities = new XmlEntities();
const regex = /(<([^>]+)>)/gi;

interface IProps {
  news: IGetRssLatest;
  gaClass: string;
}

const NewsInspect = ({ news, gaClass }: IProps) => {
  const [handleClick] = useRssFeedNews();
  return (
    <>
      <Header url={news.imageUrl} />
      <Body>
        <Source>{news.feedName || ''}</Source>
        <Title onClick={() => handleClick(news.id, news.link)}>
          {htmlEntities
            .decode(xmlEntities.decode(htmlEntities.decode(news.title)))
            .replace(regex, '')}
        </Title>
        <Abstract>
          {htmlEntities
            .decode(xmlEntities.decode(htmlEntities.decode(news.content)))
            .replace(regex, '')}
        </Abstract>
      </Body>
      <Footer>
        <InspectBtn className={gaClass} onClick={() => handleClick(news.id, news.link)}>
          全文
        </InspectBtn>
      </Footer>
    </>
  );
};

export default NewsInspect;
