import React from 'react';

import OpendataContent from 'src/components/ui/content/Opendata/OpendataContent';
import {
  ApiTypeGet,
  ApiInfoTitleStyle,
  ApiParamThStyle,
  ApiParamTdStyle,
  ApiParamTrStyle,
  ApiOutputStyle,
  apiParamNameWidth,
  FirstLineIndent,
} from 'src/style/OpendataStyle';
import ApiToken from 'src/components/opendata/common/ApiToken';

const Patent: React.FC = () => {
  return (
    <OpendataContent
      sector="開放資料"
      feature="專利說明書 API"
      foldableDescription={{
        abstract:
          '使用者可透過本API查詢「產業專利知識平台」單一案件之專利詳細資料(含書目及說明書)。',
        detail: (
          <>
            <FirstLineIndent indent={-23}>
              註. 查詢單一案件之專利資料唯一識別碼，請使用「專利查詢API」。
            </FirstLineIndent>
            <FirstLineIndent indent={-23}>
              例.
              查詢美國專利公報專利資料唯一識別碼ef45a619-28a0-4c87-a76a-97fa886412ce之專利詳細資料，則可輸入參數
              uuid=“ef45a619-28a0-4c87-a76a-97fa886412ce”，結果可得該案件之專利詳細資料，例如主要IPC為H04L
              12/24、次要IPC為H04L 12/28、摘要為In accordance with embodiments disclosed herein, an
              exemplary system or computer implemented method…..、申請專利範圍為1. A system
              comprising:a control plane interface communicatively coupled with an access ……等等。
            </FirstLineIndent>
          </>
        ),
      }}
    >
      <>
        <p>提供專利說明書查詢 API：</p>
        <ApiTypeGet />
        {process.env.REACT_APP_API_URL + 'open/api/patent/{uuid}'}
        <ApiToken />

        <ApiInfoTitleStyle>輸入參數說明</ApiInfoTitleStyle>
        <ApiParamThStyle>
          <ApiParamTdStyle width={apiParamNameWidth}>Name</ApiParamTdStyle>
          <ApiParamTdStyle>Value</ApiParamTdStyle>
        </ApiParamThStyle>
        <ApiParamTrStyle>
          <ApiParamTdStyle width={apiParamNameWidth}>uuid</ApiParamTdStyle>
          <ApiParamTdStyle>專利資料唯一識別碼</ApiParamTdStyle>
        </ApiParamTrStyle>

        <ApiInfoTitleStyle>輸出結果範例</ApiInfoTitleStyle>
        <ApiOutputStyle>
          {'{'}
          <br />
          &emsp;"id":"2002c9ee-df5d-4a73-9eea-...",
          <br />
          &emsp;"country":"TW",
          <br />
          &emsp;"bibliographic":{'{'}
          <br />
          &emsp;&emsp;"publicationReference":{'{'}
          <br />
          &emsp;&emsp;&emsp;"documentId":{'{'}
          <br />
          &emsp;&emsp;&emsp;&emsp;"date":"20191001",
          <br />
          &emsp;&emsp;&emsp;&emsp;"kind":"A",
          <br />
          &emsp;&emsp;&emsp;&emsp;"docNumber":"201900000"
          <br />
          &emsp;&emsp;&emsp;{'}'}
          <br />
          &emsp;&emsp;{'}'},<br />
          &emsp;&emsp;"applicationReference":{'{'}
          <br />
          &emsp;&emsp;&emsp;"documentId":{'{'}
          <br />
          &emsp;&emsp;&emsp;&emsp;"docNumber":"108000000",
          <br />
          &emsp;&emsp;&emsp;&emsp;"date":"20190101"
          <br />
          &emsp;&emsp;&emsp;{'}'}
          <br />
          &emsp;&emsp;{'}'},<br />
          &emsp;&emsp;"classificationIpc":{'{'}
          <br />
          &emsp;&emsp;&emsp;"mainClassification":"F16H1/32",
          <br />
          &emsp;&emsp;&emsp;"furtherClassification":["B62M25/02"]
          <br />
          &emsp;&emsp;{'}'}
          <br />
          &emsp;&emsp;"parties":{'{'}
          <br />
          &emsp;&emsp;&emsp;"inventor":{'{'}
          <br />
          &emsp;&emsp;&emsp;&emsp;"addressbook":{'{'}
          <br />
          &emsp;&emsp;&emsp;&emsp;&emsp;"lang":["tw","en"],
          <br />
          &emsp;&emsp;&emsp;&emsp;&emsp;"name":["王小明","WANG, MING"]
          <br />
          &emsp;&emsp;&emsp;&emsp;{'}'}
          <br />
          &emsp;&emsp;&emsp;{'}'},<br />
          &emsp;&emsp;&emsp;"applicant":{'{'}
          <br />
          &emsp;&emsp;&emsp;&emsp;"addressbook":{'{'}
          <br />
          &emsp;&emsp;&emsp;&emsp;&emsp;"lang":["tw"],
          <br />
          &emsp;&emsp;&emsp;&emsp;&emsp;"name":["王小明"]
          <br />
          &emsp;&emsp;&emsp;&emsp;{'}'}
          <br />
          &emsp;&emsp;&emsp;{'}'}
          <br />
          &emsp;&emsp;{'}'},<br />
          &emsp;&emsp;"priorityClaim":{'{'}
          <br />
          &emsp;&emsp;&emsp;"country":["南韓"],
          <br />
          &emsp;&emsp;&emsp;"date":["20180101"],
          <br />
          &emsp;&emsp;&emsp;"sequence":["1"],
          <br />
          &emsp;&emsp;&emsp;"docNumber":["10-2018-0000000"]
          <br />
          &emsp;&emsp;{'}'},<br />
          &emsp;&emsp;"inventionTitle":{'{'}
          <br />
          &emsp;&emsp;&emsp;"title":["系統...","SYSTEM..."]
          <br />
          &emsp;&emsp;{'}'},<br />
          &emsp;{'}'},<br />
          &emsp;"inventionSpecification":{'{'}
          <br />
          &emsp;&emsp;"abstract":{'{'}
          <br />
          &emsp;&emsp;&emsp;"text":["本發明涉及..."]
          <br />
          &emsp;&emsp;{'}'},<br />
          &emsp;&emsp;"description":{'{'}
          <br />
          &emsp;&emsp;&emsp;"technicalField":["本發明涉及..."],
          <br />
          &emsp;&emsp;&emsp;"backgroundArt":["通常利用..."]
          <br />
          &emsp;&emsp;&emsp;"disclosure":["本發明的目的在於..."],
          <br />
          &emsp;&emsp;&emsp;"modeForInvention":["下面結合附圖詳述..."],
          <br />
          &emsp;&emsp;&emsp;"descriptionOfDrawings":{'{'}
          <br />
          &emsp;&emsp;&emsp;&emsp;"text":["圖1是顯示本發明..."],
          <br />
          &emsp;&emsp;&emsp;&emsp;"descriptionOfElement":["..."]
          <br />
          &emsp;&emsp;&emsp;{'}'},<br />
          &emsp;&emsp;{'}'},<br />
          &emsp;&emsp;"claim":{'{'}
          <br />
          &emsp;&emsp;&emsp;"claimText":["一種利用..."]
          <br />
          &emsp;&emsp;{'}'}
          <br />
          &emsp;{'}'},<br />
          &emsp;"img_alias":["108000000-A0304-14-0001-1.JPG", ...],
          <br />
          {'}'}
          <br />
        </ApiOutputStyle>
      </>
    </OpendataContent>
  );
};

export default Patent;
