import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';

import SimpleSideNav from 'src/components/common/nav/side-nav/SimpleSideNav';

import Basic from 'src/components/search/Basic';
import Advanced from 'src/components/search/Advanced';
import ByText from 'src/components/search/ByText';
import ByImage from 'src/components/search/ByImage';
import TrademarkVisualSearch from 'src/components/search/trademark/TrademarkVisualSearch';
import FormSearch from 'src/components/search/Form';
import NumberSearch from 'src/components/search/Number';
import BooleanSearch from 'src/components/search/Boolean';
// import DocdbSearch from 'src/components/search/docdb/DocdbSearch';
import SearchPatentTrial from 'src/components/search/SearchPatentTrial';

import { ByImageSideNav } from './ByImageSideNav';

const Container = styled.div`
  margin: 0 auto;
  padding: 16px 140px 0 140px;
  max-width: 1440px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;
const SideNavStyle = styled.div`
  width: 180px;
  display: inline-flex;
`;
const ContentStyle = styled.div`
  padding: 0 0 0 16px;
  width: calc(100% - 180px);
`;

const sector = '專利檢索';
const features = [
  { feature: '基本檢索', path: '/basic' },
  { feature: '進階檢索', path: '/advanced' },
  { feature: '以文找文', path: '/searchByText' },
  { feature: '表格檢索', path: '/form' },
  { feature: '號碼檢索', path: '/number' },
  { feature: '布林檢索', path: '/boolean' },
  // { feature: '全球書目', path: '/docdb' },
  { feature: '美國 PTAB 資料檢索', path: '/patentTrial' },
  { feature: '以圖找圖', path: '/searchByImage', jsxFeature: ByImageSideNav },
];
const TARDEMARK_VISUAL_SEARCH_PATH = '/trademark/visual';

const CLEAR_CHATBOT_SCRIPT_ID = 'clear-chatbot-script';

const SearchLayout: React.FC<RouteComponentProps> = routeProps => {
  useEffect(() => {
    const clearChatbotScript = document.getElementById(CLEAR_CHATBOT_SCRIPT_ID);
    clearChatbotScript && document.body.removeChild(clearChatbotScript);

    const chatbotUrl = process.env.REACT_APP_CHATBOT_URL;
    const script = document.createElement('script');
    script.innerHTML = `
      // 為迴避 CDN 快取，採用動態載入的方式
      function loadScript(url, callback) {
        var scriptTag = document.createElement('script');
        scriptTag.src = url + '?_=' + new Date().getTime();
        scriptTag.onload = function() {
          if (callback) {
            callback();
          }
        };
        document.head.appendChild(scriptTag);
      }
      loadScript('${chatbotUrl}webchat/caiwebchat-config.js', function() {
        loadScript('${chatbotUrl}webchat/caiwebchat-loader.js');
        loadScript('${chatbotUrl}webchat/bots/ipkm/custom.js');
      });

      // 頁面剛載入時 CaiWebChat.init 仍會是 undefined，
      // 故此處採用宣告指定 handler 的方式，於載入完畢後自動呼叫
      window.onCaiWebChatLoaded = function() {
        CaiWebChat.init({ botId: 'ipkm' });
        loadScript('${chatbotUrl}webchat/bots/ipkm/customWebChatLoaded.js');
      };

      // WebChat 連線完要做的事情
      window.onConnectFulfill = function() {
        loadScript('${chatbotUrl}webchat/bots/ipkm/customConnectFulfill.js');
      };
    `;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
      // 離開專利檢索相關頁面 則執行清除chatbot初始化設定,避免重複初始化.
      const clearChatBot = document.createElement('script');
      clearChatBot.id = CLEAR_CHATBOT_SCRIPT_ID;
      clearChatBot.innerHTML = 'CaiWebChat.clear();';
      document.body.appendChild(clearChatBot);
    };
  }, []);

  return (
    <Container>
      <SideNavStyle>
        <SimpleSideNav sector={sector} features={features} {...routeProps} />
      </SideNavStyle>

      <ContentStyle>
        <Switch>
          <Route path={routeProps.match.url + features[0].path} component={Basic} />
          <Route path={routeProps.match.url + features[1].path} component={Advanced} />
          <Route path={routeProps.match.url + features[2].path} component={ByText} />
          <Route path={routeProps.match.url + features[3].path} component={FormSearch} />
          <Route path={routeProps.match.url + features[4].path} component={NumberSearch} />
          <Route path={routeProps.match.url + features[5].path} component={BooleanSearch} />
          {/* <Route path={routeProps.match.url + features[6].path} component={DocdbSearch} /> */}
          <Route path={routeProps.match.url + features[6].path} component={SearchPatentTrial} />
          <Route path={routeProps.match.url + features[7].path} component={ByImage} />
          <Route
            path={routeProps.match.url + TARDEMARK_VISUAL_SEARCH_PATH}
            component={TrademarkVisualSearch}
          />
          <Route component={() => <Redirect to={routeProps.match.url + features[0].path} />} />
        </Switch>
      </ContentStyle>
    </Container>
  );
};

export default SearchLayout;
