import React from 'react';
import { RouteComponentProps } from 'react-router';

import SideNavItem from './item/NavItem';
import NestedNavItem from './item/NestedNavItem';

import { Container, Sector } from 'src/style/SideMenuStyle';

interface INestedNav extends RouteComponentProps {
  sector: string;
  features: Array<{
    feature: string;
    path: string;
    subfeatures: boolean;
  }>;
  subFeatArr: Array<{
    idx: number;
    items: Array<{
      id: number;
      name: string;
      linkable?: boolean; // 第二層 nav 在有第三層時可否作為連結
      disable?: boolean;
      nestedSubfeatures?: boolean;
      nestedSubfeat?: Array<{
        id: number;
        name: string;
        disable?: boolean;
      }>;
    }>;
  }>;
}

const nestedSideNav = (props: INestedNav) => (
  <Container>
    <Sector>{props.sector}</Sector>
    {props.features.map((item, idx) => {
      return item.subfeatures ? (
        <NestedNavItem
          key={item.feature}
          feat={item}
          subfeat={props.subFeatArr[props.subFeatArr.findIndex(item => item.idx === idx)].items}
          {...props}
        />
      ) : (
        <SideNavItem key={item.feature} item={item} {...props} />
      );
    })}
  </Container>
);

export default nestedSideNav;
