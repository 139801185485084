import React, { useState } from 'react';
import styled from 'styled-components';
import { FaAngleDown, FaAngleUp, FaTimesCircle } from 'react-icons/fa';
import { connect } from 'react-redux';
import { ReduxAppState } from 'src/redux/reducers';
import { appendAdvancedKeyword, detachAdvancedKeyword } from 'src/redux/trademark/action';
import Tooltip from 'src/components/ui/interactive/Tooltip';
import { numFormatter } from 'src/utils/Formatter';
import { Spinner } from 'src/components/ui/interactive/Spinner';
import SearchBar from './common/SearchBar';
import {
  Container,
  Header,
  TaggedHeader,
  Title,
  Panel,
  TagWithTooltip,
  FacetLinkBox,
  FacetLinkCount,
  NoFilteredResult,
  FacetLinkName,
} from 'src/style/AccordionStyle';
import { blue2 } from 'src/style/theme/Color';
import { IFacetFieldCount } from 'src/types/PatentSearchTypes';
import useAccordion from './common/useAccordion';
import { ICodeDescriptionMapping } from 'src/types/TrademarkTypes';

const DescriptionTooltip = styled(Tooltip)`
  width: 65%;
  color: ${blue2};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: auto;
`;

const TmFacetLinkName = styled(FacetLinkName)`
  overflow: unset;
  text-overflow: unset;
`;

interface IReduxMappingProps {
  descriptions: ICodeDescriptionMapping;
  isLoading: boolean;
  reduxAppendAdvancedKeyword: (keyCode: string, keyword: string) => void;
  reduxDetachAdvancedKeyword: (keyCode: string) => void;
}

interface IProps extends IReduxMappingProps {
  keyCode: string;
  title?: string;
  tag?: string;
  content: Array<IFacetFieldCount>;
}

/** 商標資訊-摺疊面板 */
const TmAccordion: React.FC<IProps> = ({
  keyCode,
  title,
  tag,
  content,
  reduxAppendAdvancedKeyword,
  reduxDetachAdvancedKeyword,
  isLoading,
  descriptions,
}) => {
  const {
    isActive,
    searchKeyword,
    filteredContent, // 根據篩選項目的搜尋框內容過濾篩選項目
    togglePanel,
    handleKeywordChange,
    getHighlightedText,
  } = useAccordion(content);

  const isTagged = !!tag;
  // 申請人才提供搜尋框
  const isSearchbarShow = !isTagged && isActive && keyCode === 'APPLICANT_NAME';
  // 多於 5 個項目則顯示"顯示全部 x 項結果" 連結
  const showFolded = !isTagged && isActive && content && content.length > 5;
  const [isFolded, setIsFolded] = useState(showFolded);

  const toggleFolder = (): void => {
    setIsFolded(!isFolded);
  };

  return (
    <Container>
      {isTagged ? (
        <TaggedHeader>
          <Title>{title}</Title>
          <TagWithTooltip
            placement="top"
            overlay={tag}
            // 國際分類號不顯示Tooltip
            isHidden={false}
          >
            {tag}
          </TagWithTooltip>
          <FaTimesCircle size={20} onClick={() => reduxDetachAdvancedKeyword(keyCode)} />
        </TaggedHeader>
      ) : (
        <Header onClick={() => togglePanel()}>
          <Title>{title}</Title>
          {isActive ? <FaAngleUp size={20} /> : <FaAngleDown size={20} />}
        </Header>
      )}
      {isSearchbarShow && (
        <SearchBar searchKeyword={searchKeyword} onChange={e => handleKeywordChange(e)} />
      )}
      {isLoading ? (
        <Spinner margin="58px auto" />
      ) : (
        !isTagged && (
          <Panel isShow={isActive} maxHeight="200px">
            {searchKeyword !== '' && filteredContent.length === 0 ? (
              <NoFilteredResult>查無 "{searchKeyword}" 的符合結果</NoFilteredResult>
            ) : (
              <>
                {filteredContent.slice(0, isFolded ? 5 : filteredContent.length).map(item => {
                  const hasDescription = descriptions[item.name.trim()] != null;
                  return (
                    <FacetLinkBox
                      key={item.name}
                      onClick={() => reduxAppendAdvancedKeyword(keyCode, item.name)}
                    >
                      {hasDescription && (
                        <TmFacetLinkName>
                          {getHighlightedText(item.name, searchKeyword)}
                        </TmFacetLinkName>
                      )}
                      <DescriptionTooltip
                        overlay={hasDescription ? descriptions[item.name.trim()] : item.name} // TODO: 抓map
                        placement="right"
                        offset={hasDescription ? 50 : 90}
                      >
                        <span>{hasDescription ? descriptions[item.name.trim()] : item.name}</span>
                      </DescriptionTooltip>

                      <FacetLinkCount>({numFormatter(item.count)})</FacetLinkCount>
                    </FacetLinkBox>
                  );
                })}
                {showFolded && (
                  <FacetLinkBox onClick={() => toggleFolder()}>
                    {isFolded ? '顯示全部 ' + filteredContent.length + ' 項結果' : '隱藏部分結果'}
                  </FacetLinkBox>
                )}
              </>
            )}
          </Panel>
        )
      )}
    </Container>
  );
};

const mapStateToProps = (state: ReduxAppState) => {
  return {
    isLoading: state.trademarkVisualSearchReducer.isFacetLoading,
  };
};

const mapDispatchToProps = {
  reduxAppendAdvancedKeyword: appendAdvancedKeyword,
  reduxDetachAdvancedKeyword: detachAdvancedKeyword,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TmAccordion);
