import React, { useState, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { FaShareAlt, FaPencilAlt, FaUserCircle } from 'react-icons/fa';

import { PatentService } from 'src/api/Patent';
import { CommonService } from 'src/api/Common';
import { IGetPatentNoteShare } from 'src/types/api/PatentApiTypes';
import { IGetPatentNoteTypes } from 'src/types/api/PatentApiTypes';
import { IGetMemberCompanyUsers } from 'src/types/api/CommonApiTypes';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import { alertMessage } from 'src/utils/ModalUtils';
import { SystemMessage } from 'src/apps/applicationMessages';
import { isCompanyMember } from 'src/apps/auth/roleChecker';

import { dateFormatter } from 'src/utils/Formatter';
import { millisFormatter } from 'src/utils/Formatter';
import Checkbox from 'src/components/ui/interactive/checkbox/Checkbox';
import { Button } from 'src/style/theme/Common';
import {
  FuncWrapper,
  NotePost,
  NoteUser,
  NoteDate,
  OpacityMask,
  SelectAllCheckbox,
  SpaceBetween,
  ToolbarPaneScrollDiv,
  ToolbarPaneFooterDiv,
  ToolbarPaneButtonArea,
  ToolbarPaneDividingLine,
  ToolbarPaneField,
  ToolbarPaneContainer,
  ToolbarPaneBodyDiv,
  ToolbarPaneTitle,
  ToolbarPaneDescription,
} from 'src/style/PatentContentStyle';

import { connect } from 'react-redux';
import { ReduxAppState } from 'src/redux/reducers';
import { ITokenPayload } from 'src/apps/auth/Auth';

const patentService = PatentService();
const commonService = CommonService();

interface IReduxMappingProps {
  member?: ITokenPayload;
}
interface IProps extends RouteComponentProps, IReduxMappingProps {
  origNote: IGetPatentNoteTypes | null;
  isCollected: boolean;
  handleSubmitNote: (input: string) => void;
  toggleActivePane: (pane: string) => void;
}

function ToolbarNotePane(props: IProps) {
  const [noteText, setNoteText] = useState('');
  const [editNote, setEditNote] = useState(true);

  const [openSharePane, setOpenSharePane] = useState(false);
  const [fetched, setFetched] = useState(false);
  const [companyUsers, setCompanyUsers] = useState<Array<IGetMemberCompanyUsers>>([]);
  const [usersToShare, setUsersToShare] = useState<Array<number>>([]);
  const [sharedNotes, setSharedNotes] = useState<Array<IGetPatentNoteShare>>([]);
  useEffect(() => {
    const fetchShared = async () => {
      try {
        const notesRes = await patentService.getPatentShareNote(
          props.location.pathname.split('/')[2],
        );
        setSharedNotes(notesRes);

        if (props.origNote) {
          const sharedRes = await patentService.getPatentShareNoteToWhom(props.origNote.id);
          setUsersToShare(sharedRes);
        }
      } catch (error) {
        errorHandler(error);
      }
    };
    const fetchFriends = async () => {
      try {
        // TBD: 上redux?
        const companyUserRes = await commonService.getCompanyMember();
        setCompanyUsers(companyUserRes);
      } catch (error) {
        errorHandler(error);
      } finally {
        setFetched(true);
      }
    };

    fetchShared();
    if (props.origNote) {
      setNoteText(props.origNote.note ? props.origNote.note : '');
      props.origNote.note !== '' && setEditNote(false);

      if (!fetched) {
        fetchFriends();
      }
    }
  }, [props.origNote, fetched, props.location.pathname]);

  const submitNote = () => {
    if (noteText.trim() !== '') {
      props.handleSubmitNote(noteText.trim());
      setEditNote(false);
    } else {
      alertMessage(SystemMessage.PATENT_NOTE_EMPTY);
    }
  };

  const reset = () => {
    setNoteText(props.origNote ? props.origNote.note : '');
    setEditNote(!props.origNote || props.origNote.note.trim() === '');
    props.toggleActivePane('');
  };

  const handleCheckbox = (guid: number) => {
    let arr = usersToShare.slice(0);
    const index = arr.indexOf(guid);
    index > -1 ? arr.splice(index, 1) : arr.push(guid);
    setUsersToShare(arr);
  };

  const handleShareNote = async () => {
    try {
      props.origNote && (await patentService.putPatentShareNote(props.origNote.id, usersToShare));
      alertMessage('分享設定成功');
      setOpenSharePane(prev => !prev);
    } catch (error) {
      errorHandler(error);
    }
  };

  // eslint-disable-next-line no-control-regex
  const newLineRegExp = new RegExp('\r?\n', 'g');
  const textToParagraph = (text: string) =>
    text.split(newLineRegExp).map((item, idx) => <p key={idx}>{item}</p>);

  const sharePane = (
    <>
      <ToolbarPaneTitle>
        <FaShareAlt />
        &nbsp;分享專利筆記
      </ToolbarPaneTitle>
      <ToolbarPaneField>
        <SpaceBetween>
          <div>選擇分享對象</div>
          {companyUsers.length !== 0 && (
            <div>
              <SelectAllCheckbox onClick={() => setUsersToShare(companyUsers.map(item => item.id))}>
                全選
              </SelectAllCheckbox>
              <SelectAllCheckbox onClick={() => setUsersToShare([])}>清除選取</SelectAllCheckbox>
            </div>
          )}
        </SpaceBetween>
        {companyUsers.length > 0 ? (
          <ToolbarPaneScrollDiv maxHeight="calc(100vh - 360px)">
            {companyUsers.map(item => (
              <Checkbox
                key={item.id}
                name={item.systemAccountName}
                checked={usersToShare.includes(item.id)}
                color="#ffffff"
                themeReverse={true}
              >
                <input
                  type="checkbox"
                  value={item.id}
                  checked={usersToShare.includes(item.id)}
                  onChange={() => handleCheckbox(item.id)}
                />
              </Checkbox>
            ))}
          </ToolbarPaneScrollDiv>
        ) : isCompanyMember() ? (
          <ToolbarPaneDescription>目前無同公司其他會員可分享。</ToolbarPaneDescription>
        ) : (
          <ToolbarPaneDescription>此功能僅限公司會員使用。</ToolbarPaneDescription>
        )}
      </ToolbarPaneField>
    </>
  );

  const noteContent = (
    <ToolbarPaneField>
      <SpaceBetween>
        <div>你的筆記</div>
        {!editNote && (
          <OpacityMask onClick={() => setEditNote(true)}>
            <FaPencilAlt size="13px" />
            &nbsp;編輯
          </OpacityMask>
        )}
      </SpaceBetween>
      <ToolbarPaneScrollDiv maxHeight="calc(100vh - 360px)">
        {editNote ? (
          /* 尚無筆記或點選編輯 */
          <textarea
            name=""
            placeholder="輸入文字"
            value={noteText}
            onChange={e => setNoteText(e.currentTarget.value)}
          />
        ) : (
          /* 已有筆記 */
          <NotePost>
            <SpaceBetween>
              <NoteUser>
                <FaUserCircle size="32px" color="#0cafcc" />我
              </NoteUser>
              <NoteDate>{props.origNote && dateFormatter(props.origNote.updateTime)}</NoteDate>
            </SpaceBetween>
            {textToParagraph(noteText)}
          </NotePost>
        )}
        {sharedNotes.length > 0 && (
          <>
            <ToolbarPaneDividingLine />
            {sharedNotes.map(item => (
              <NotePost key={item.systemAccountId}>
                <SpaceBetween>
                  <NoteUser>
                    <FaUserCircle size="32px" color="#0cafcc" />
                    {item.systemAccountName}
                  </NoteUser>
                  <NoteDate>{millisFormatter(item.updateTime)}</NoteDate>
                </SpaceBetween>
                {textToParagraph(item.note)}
              </NotePost>
            ))}
          </>
        )}
      </ToolbarPaneScrollDiv>
    </ToolbarPaneField>
  );

  return (
    <ToolbarPaneContainer offset="56px">
      {openSharePane ? (
        <>
          {sharePane}
          {companyUsers.length > 0 && (
            <ToolbarPaneFooterDiv>
              <ToolbarPaneButtonArea>
                <Button template="primary" onClick={handleShareNote}>
                  分享
                </Button>
                <Button template="light" onClick={() => setOpenSharePane(prev => !prev)}>
                  取消
                </Button>
              </ToolbarPaneButtonArea>
            </ToolbarPaneFooterDiv>
          )}
        </>
      ) : (
        <ToolbarPaneBodyDiv>
          <SpaceBetween>
            <ToolbarPaneTitle>筆記</ToolbarPaneTitle>
            {props.origNote && props.isCollected && isCompanyMember() && (
              <FuncWrapper>
                <FaShareAlt size="20px" onClick={() => setOpenSharePane(prev => !prev)} />
              </FuncWrapper>
            )}
          </SpaceBetween>
          {props.isCollected ? (
            noteContent
          ) : (
            <ToolbarPaneDescription>
              您尚未收藏此篇專利，請先收藏後再編輯筆記。
            </ToolbarPaneDescription>
          )}
        </ToolbarPaneBodyDiv>
      )}
      {editNote && props.isCollected && (
        <ToolbarPaneFooterDiv>
          <ToolbarPaneButtonArea>
            <Button template="primary" onClick={() => submitNote()}>
              儲存
            </Button>
            <Button template="light" onClick={() => reset()}>
              取消
            </Button>
          </ToolbarPaneButtonArea>
        </ToolbarPaneFooterDiv>
      )}
    </ToolbarPaneContainer>
  );
}

const mapStateToProps = (state: ReduxAppState) => {
  return {
    member: state.memberReducer.memberPayload,
  };
};

export default connect(
  mapStateToProps,
  null,
)(withRouter(ToolbarNotePane));
