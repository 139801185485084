import React from 'react';
import styled from 'styled-components';

const Container = styled.div<{ width?: string; margin?: string }>`
  width: ${props => (props.width ? props.width : '60px')};
  height: ${props => (props.width ? props.width : '60px')};
  margin: ${props => (props.margin ? props.margin : '80px auto')};
`;
const CubeGrid = styled.div`
  width: 33%;
  height: 33%;
  background-color: #ffffff;
  float: left;
  animation: blink 1.3s infinite ease-in-out;

  @keyframes blink {
    0%,
    70%,
    100% {
      transform: scale3D(1, 1, 1);
    }
    35% {
      transform: scale3D(0, 0, 1);
    }
  }
`;
const Cube1 = styled(CubeGrid)`
  animation-delay: 0.2s;
  background-color: #50c0ff;
`;
const Cube2 = styled(CubeGrid)`
  animation-delay: 0.3s;
  background-color: #3c7ce8;
`;
const Cube3 = styled(CubeGrid)`
  animation-delay: 0.4s;
  background-color: #434fb3;
`;
const Cube4 = styled(CubeGrid)`
  animation-delay: 0.1s;
  background-color: #3cd6e8;
`;
const Cube5 = styled(CubeGrid)`
  animation-delay: 0.2s;
  background-color: #50c0ff;
`;
const Cube6 = styled(CubeGrid)`
  animation-delay: 0.3s;
  background-color: #3c7ce8;
`;
const Cube7 = styled(CubeGrid)`
  animation-delay: 0s;
  background-color: #42ffdf;
`;
const Cube8 = styled(CubeGrid)`
  animation-delay: 0.1s;
  background-color: #3cd6e8;
`;
const Cube9 = styled(CubeGrid)`
  animation-delay: 0.2s;
  background-color: #50c0ff;
`;

export const Spinner = (props: { width?: string; margin?: string }) => (
  <Container width={props.width} margin={props.margin}>
    <Cube1 />
    <Cube2 />
    <Cube3 />
    <Cube4 />
    <Cube5 />
    <Cube6 />
    <Cube7 />
    <Cube8 />
    <Cube9 />
  </Container>
);
