import React from 'react';
import styled from 'styled-components';
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';
import { PrivateRoute } from 'src/routes/PrivateRoute';

import SimpleSideNav from 'src/components/common/nav/side-nav/SimpleSideNav';

import BrowseLog from './BrowseLog';
import Edit from './Edit';
import ExpertDetailEdit from './ExpertDetailEdit';
import Manage from './Manage';
import Notification, { Category } from './Notification';
import { check, Permission, getAvailableFeatures } from 'src/apps/permission/Permission';

const Container = styled.div`
  margin: 0 auto;
  padding: 16px 140px 0 140px;
  max-width: 1440px;
  display: flex;
  align-items: flex-start;
  align-content: space-between;
  flex-flow: row;
`;
const SideNavStyle = styled.div`
  width: 180px;
  display: inline-flex;
`;
const ContentStyle = styled.div`
  padding: 0 0 0 16px;
  width: calc(100% - 180px);
`;

const MemberLayout: React.FC<RouteComponentProps> = routeProps => {
  const sector = '個人專區';
  const features = [
    { feature: '瀏覽紀錄', path: '/browseLog' },
    { feature: '編輯會員資料', path: '/edit' },
    { feature: '編輯專家履歷', path: '/expertDetailEdit' },
    { feature: '管理會員帳號', path: '/manage' },
    { feature: '通知設定', path: '/notification' },
  ];

  return (
    <Container>
      <SideNavStyle>
        <SimpleSideNav sector={sector} features={getAvailableFeatures(features)} {...routeProps} />
      </SideNavStyle>

      <ContentStyle>
        <Switch>
          <Route path={routeProps.match.url + features[0].path} component={BrowseLog} />
          <Route path={routeProps.match.url + features[1].path} component={Edit} />
          <PrivateRoute
            isLogin={check(Permission.EDIT_EXPERT)}
            path={routeProps.match.url + features[2].path}
            component={ExpertDetailEdit}
          />
          <PrivateRoute
            isLogin={check(Permission.ADD_MEMBER)}
            path={routeProps.match.url + features[3].path}
            component={Manage}
          />
          <Route
            path={routeProps.match.url + features[4].path + '/info'}
            render={() => <Notification tab={Category.WEEKLY_INFO} />}
          />
          <Route path={routeProps.match.url + features[4].path} component={Notification} />

          <Route component={() => <Redirect to={routeProps.match.url + features[0].path} />} />
        </Switch>
      </ContentStyle>
    </Container>
  );
};

export default MemberLayout;
