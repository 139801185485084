/* Personal Route Start */
export const PrivateRouteRedirectUrl = {
  showcase: '/showcase',
  news: '/news',
};

export const PrivateRouteRegExp = [
  new RegExp('^/showcase/collection'),
  new RegExp('^/showcase/tracking'),
  new RegExp('^/news'),
  new RegExp('^/patent'),
  new RegExp('^/data'),
];

export const ReloadResoourceUrlRegExp = [
  new RegExp('/showcase'),
  new RegExp('/news'),
  new RegExp('/patent'),
  new RegExp('/data'),
];
/* Personal Route End */

/* Profile Modified Start */
export const ExcludedRedirectUrlRegExp = [
  new RegExp('^/member/edit'),
  new RegExp('^/email/confirm'),
  // new RegExp('/password/reset'),
];
/* Profile Modified End */
