import React from 'react';
import styled from 'styled-components';
import by_text_search from 'src/assets/images/manual/patent-search/by_text_search.png';

const ImgContainer = styled.div<{ height: string; imageUrl: string }>`
  position: relative;
  height: ${props => props.height}px;
  padding: 10px 0;
  background-image: url(${props => props.imageUrl});
  background-size: contain;
  background-repeat: no-repeat;
`;
export default function PatentSearchManual() {
  return (
    <>
      <p>
        以文找文可以使用文字輸入或檔案上傳方式，選擇不同國別，以及輸入文字或上傳相關文件，來搜尋一段文字的專利資料。
      </p>
      <ol>
        <li>從功能列點選「專利檢索」。</li>
        <li>點選「以文找文」功能。</li>
        <li>選擇要檢索的方式，可選「文字輸入」、「檔案上傳」。</li>
        <li>選擇要檢索的國家範圍，預設為本國TW。</li>
        <li>輸入要檢索的文字，或上傳要檢索的檔案。</li>
        <li>點選「查詢」進行查詢</li>
      </ol>
      <ImgContainer height="350" imageUrl={by_text_search} />
    </>
  );
}
