import React, { useState, useEffect } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import axios from 'axios';
import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';

import { IPaginatedData } from 'src/types/PaginatedDataTypes';
import { IGetPatentRecommend } from 'src/types/api/ShowcaseApiTypes';
import ShowcaseListContent from 'src/components/ui/content/Showcase/ListContent';
import RecommendItem from './item/RecommendItem';
import { Spinner } from 'src/components/ui/interactive/Spinner';
import InfiniteScroll from 'src/components/ui/interactive/InfiniteScroll';

const Latest: React.FC = () => {
  const [lastUpdateDate, setLastUpdateDate] = useState();
  const [activeCountry, setActiveCountry] = useState<string>('TW');
  const [patents, setPatents] = useState<IPaginatedData<IGetPatentRecommend>>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [pageNo, setPageNo] = useState<number>(1);
  const [isNextPageLoading, setIsNextPageLoading] = useState<boolean>(false);
  const [isNextPageError, setIsNextPageError] = useState<boolean>(false);

  const loadClickHandler = () => {
    unstable_batchedUpdates(() => {
      setIsNextPageLoading(true);
      setPageNo(pageNo + 1);
    });
  };

  const reloadClickHandler = () => {
    if (pageNo === 1) {
      setIsLoading(true);
      setPatents(undefined);
    } else {
      setIsNextPageLoading(true);
    }
  };

  const countryClickHandler = (country: string) => {
    if (!(isLoading || isNextPageLoading)) {
      setActiveCountry(country);
      setPatents(undefined);
      setPageNo(1);
      setIsLoading(true);
      setIsNextPageLoading(false);
    }
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const fetchData = async () => {
      try {
        const res = await Api().get('patent/updateDate');
        setLastUpdateDate(res.data);
      } catch (error) {
        errorHandler(error);
      }
    };

    fetchData();

    return () => source.cancel();
  }, []);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const fetchData = async () => {
      try {
        const res = await Api().get('patent/latest', {
          params: {
            country: activeCountry,
            pageNo: pageNo,
          },
          cancelToken: source.token,
        });

        if (isNextPageLoading && patents) {
          setPatents({
            ...patents,
            data: [...patents.data, ...res.data.data],
            pageNo: res.data.pageNo,
          });
        } else {
          setPatents(res.data);
        }

        isNextPageLoading && setIsNextPageError(false);
      } catch (error) {
        errorHandler(error);
        isNextPageLoading && setIsNextPageError(true);
      } finally {
        setIsLoading(false);
        setIsNextPageLoading(false);
      }
    };

    (!patents || pageNo !== patents.pageNo) && (isLoading || isNextPageLoading) && fetchData();

    return () => source.cancel();
  }, [patents, activeCountry, pageNo, isLoading, isNextPageLoading]);

  return (
    <ShowcaseListContent
      sector="專利專區"
      feature="最新專利"
      update={`最新公開或公告的專利。 更新時間 ${lastUpdateDate || ''}`}
      activeCountry={activeCountry}
      countries={['TW', 'US', 'EP', 'JP', 'CN', 'KR']}
      subheader={true}
      click={countryClickHandler}
    >
      {isLoading ? (
        <Spinner width="150px" />
      ) : (
        <>
          {patents && (
            <InfiniteScroll
              isNextPageLoading={isNextPageLoading}
              isNextPageError={isNextPageError}
              errorMessage={'系統發生異常'}
              handleNextPageLoad={loadClickHandler}
              handleReload={reloadClickHandler}
              totalCount={patents.totalCount}
              currentPage={pageNo}
              totalPages={patents.totalPages}
            >
              {patents.data.map(item => (
                <RecommendItem key={item.id} patentData={item} />
              ))}
            </InfiniteScroll>
          )}
        </>
      )}
    </ShowcaseListContent>
  );
};

export default Latest;
