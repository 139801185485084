import { AxiosResponse, AxiosError } from 'axios';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { ReduxAppState } from 'src/redux/reducers';
import {
  FETCH_PATENT_RESULT_START,
  FETCH_PATENT_RESULT_SUCCESS,
  FETCH_PATENT_RESULT_ERROR,
  FETCH_PATENT_NEXT_PAGE_RESULT_ERROR,
  FetchPatentResultActionTypes,
} from 'src/redux/types/patentResultTypes';
import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';

export const fetchPatentResult = (): ThunkAction<
  void,
  ReduxAppState,
  null,
  Action<string>
> => async (dispatch, getState) => {
  dispatch(fetchPatentResultStart());

  const state = getState().patentSearchReducer;
  const searchRequest = state.request;

  try {
    // 寫入檢索紀錄 (以文找文等UuidList查詢不寫)
    if (!state.isUuidListSearch) {
      const { queryString, ...others } = searchRequest;
      Api().post('patent/search/log', {
        keyword: queryString,
        ...others,
      });
    }

    // 查詢
    const response = await Api().post('api/patent/search', searchRequest);

    // 當查詢字串造成solr查詢錯誤時，response.data 會回傳
    // eg. { costTime: 0.006, data: null, pageNo: 1, size: 0, totalCount: -1, totalPages: 0 }
    // 為了避免後續操作陣列產生null pointer，所以替換成空陣列
    !response.data.data && (response.data.data = []);

    dispatch(fetchPatentResultSuccess(response));
  } catch (error) {
    if (searchRequest.pageNo > 1) {
      dispatch(fetchPatentNextPageResultFailure(error));
    } else {
      dispatch(fetchPatentResultFailure(error));
    }
  }
};

export function fetchPatentResultStart(): FetchPatentResultActionTypes {
  return {
    type: FETCH_PATENT_RESULT_START,
  };
}

export function fetchPatentResultSuccess(response: AxiosResponse): FetchPatentResultActionTypes {
  return {
    type: FETCH_PATENT_RESULT_SUCCESS,
    response: response,
  };
}

export function fetchPatentResultFailure(error: AxiosError): FetchPatentResultActionTypes {
  errorHandler(error);
  return {
    type: FETCH_PATENT_RESULT_ERROR,
    error: error,
  };
}

export function fetchPatentNextPageResultFailure(error: AxiosError): FetchPatentResultActionTypes {
  errorHandler(error);
  // TODO: 需要再確認或優化錯誤處理作法
  const errorMessage =
    (error.response && error.response.data && error.response.data.resultMessage) ||
    '網路連線出現異常';
  return {
    type: FETCH_PATENT_NEXT_PAGE_RESULT_ERROR,
    nextPageErrMsg: errorMessage,
  };
}
