/** TODO: 功能識別前綴字 BASE_ACTION_PREFIX 提出為變數的方法? */
/** 切換 Toolbar Pane */
export const CHANGE_ACTIVE_PANE = 'iPKM/result/toolbar/CHANGE_ACTIVE_PANE';
/** 保存 技術功效矩陣 表單 輸入資料 */
export const SAVE_MATRIX_FORM = 'iPKM/result/toolbar/SAVE_MATRIX_FORM';
/** 保存 技術功效矩陣 名稱與檢索式 列表 */
export const SAVE_MATRIX_AXIS_DATA = 'iPKM/result/toolbar/SAVE_MATRIX_AXIS_DATA';
/** 保存 技術功效矩陣 搜尋結果集 */
export const SAVE_MATRIX_DATASET = 'iPKM/result/toolbar/SAVE_MATRIX_DATASET';

export interface IFieldValue {
  name: string;
  keyword: string;
}

export interface IMatrixData {
  technologyList: Array<IFieldValue>;
  functionList: Array<IFieldValue>;
}

export interface IMatrixChart extends IMatrixData {
  dataset: Array<Array<number>>;
}

export interface ToolbarState {
  activePane: string;
  matrixForm: IMatrixData;
  matrixChart: IMatrixChart;
}

interface ChangeActivePaneAction {
  type: typeof CHANGE_ACTIVE_PANE;
  activePane: string;
}

interface SaveMatrixFormAction {
  type: typeof SAVE_MATRIX_FORM;
  matrixForm: IMatrixData;
}

interface SaveMatrixAxisDataAction {
  type: typeof SAVE_MATRIX_AXIS_DATA;
  technologyList: Array<IFieldValue>;
  functionList: Array<IFieldValue>;
}

interface SaveMatrixDatasetAction {
  type: typeof SAVE_MATRIX_DATASET;
  dataset: Array<Array<number>>;
}

export type ToolbarActionTypes =
  | ChangeActivePaneAction
  | SaveMatrixFormAction
  | SaveMatrixAxisDataAction
  | SaveMatrixDatasetAction;
