import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { FaThumbsDown, FaThumbsUp, FaTimes } from 'react-icons/fa';

import { Backdrop } from 'src/style/ModalStyle';
import { blue1, blue2, iceblue1, iceblue5, iceblue8, ultrawhite } from 'src/style/theme/Color';
import { zModal } from 'src/style/theme/Z-Index';
import { closeCustomModal } from 'src/redux/actions/modalAction';
import { Spinner } from 'src/components/ui/interactive/Spinner';
import { TmApplMetadata } from 'src/types/TrademarkTypes';
import Tooltip from '../../Tooltip';
import { FeedbackType } from 'src/api/Trademark';

// 高度由 Body 決定
const Container = styled.div`
  position: relative;
  border-radius: 4px 4px 0 0;
  background-color: #ffffff;
  width: 60%;
  max-width: 900px;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 32px;
  svg {
    font-size: 20px;
    margin-left: 24px;
    cursor: pointer;
  }
`;

const Title = styled.div`
  color: ${blue1};
  font-size: 24px;
  font-weight: bold;
`;

const Body = styled.div`
  width: 100%;
  height: calc(100vh - 300px);
  overflow: auto;
  margin: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-flow: row;
`;

const Table = styled.table`
  margin: 0 32px 32px 32px;
  border-collapse: collapse;
  display: flex;
`;

const Tr = styled.tr`
  line-height: 30px;
`;
const TdContent = styled.td`
  font-size: 16px;
  min-width: 90px;
  vertical-align: top;
`;

const TdTitle = styled(TdContent)`
  color: ${iceblue1};
  font-weight: bold;
`;

const ImageBox = styled.img`
  max-height: 100%;
  padding: 0 32px 16px 0;
`;

const ErrorMsg = styled.div`
  margin: 0 32px 32px 32px;
  font-size: 20px;
`;

const IconWithTooltip = styled(Tooltip)`
  display: inline-block;
  margin: 12px 12px 12px 0;
`;

const FeedbackIcon = styled.div<{ isSelected?: boolean }>`
  background-color: ${ultrawhite};
  border: 1px solid ${iceblue8};
  display: inline-block;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  padding: 6px;
  cursor: pointer;

  svg {
    color: ${props => (props.isSelected ? `${blue2}` : `${iceblue5}`)};
  }
  :hover {
    svg {
      color: ${blue2};
    }
  }
}
`;

interface IReduxMappingProps {
  closeCustomModal: () => void;
}

interface IProps extends IReduxMappingProps {
  printButton?: boolean; //true:右上為列印按鈕 ;false或不指定:右上為新分頁按鈕
  metadata: TmApplMetadata;
  imagePath: string;
  isLoading?: boolean;
  isFailed?: boolean;
  caseNo: string;
  handleFeedbackCheck: (caseNo: string, feedbackType: FeedbackType) => void;
  selectedType: FeedbackType;
}

const modal = document.getElementById('modal-custom');
const portalDiv = modal ? modal : document.createElement('div');

const TmMetadataModal: React.FC<IProps> = props => {
  const {
    metadata,
    imagePath,
    isLoading,
    isFailed,
    caseNo,
    handleFeedbackCheck,
    selectedType,
  } = props;

  return ReactDOM.createPortal(
    <Backdrop zIndex={zModal.custom} alignItems="center">
      <Container>
        {!isFailed && !isLoading ? (
          <>
            <Header>
              <Title>{metadata['tmark-name']}</Title>
              <FaTimes color={iceblue5} onClick={() => props.closeCustomModal()} />
            </Header>
            <Body>
              <>
                <Table>
                  <tbody>
                    <Tr>
                      <TdTitle>申請號：</TdTitle>
                      <TdContent>{metadata['appl-no']}</TdContent>
                    </Tr>
                    <Tr>
                      <TdTitle>申請人：</TdTitle>
                      <TdContent>{metadata['applicant.chinese-name'].join('、')}</TdContent>
                    </Tr>
                    <Tr>
                      <TdTitle>類別：</TdTitle>
                      <TdContent>
                        {metadata['goodsclass-code']
                          .sort((a: string, b: string) => {
                            return a < b ? -1 : 1;
                          })
                          .join('、')}
                      </TdContent>
                    </Tr>
                    <Tr>
                      <TdTitle>圖形路徑：</TdTitle>
                      <TdContent>{metadata['img-class'].join(', ')}</TdContent>
                    </Tr>
                    <Tr>
                      <TdTitle colSpan={2}>
                        <IconWithTooltip overlay="相似度較高" placement="bottom">
                          <FeedbackIcon isSelected={selectedType === FeedbackType.POS}>
                            <FaThumbsUp
                              size={20}
                              onClick={e => handleFeedbackCheck(caseNo, FeedbackType.POS)}
                            />
                          </FeedbackIcon>
                        </IconWithTooltip>
                        <IconWithTooltip overlay="相似度較低" placement="bottom">
                          <FeedbackIcon isSelected={selectedType === FeedbackType.NEG}>
                            <FaThumbsDown
                              size={20}
                              onClick={e => handleFeedbackCheck(caseNo, FeedbackType.NEG)}
                            />
                          </FeedbackIcon>
                        </IconWithTooltip>
                      </TdTitle>
                    </Tr>
                  </tbody>
                </Table>

                <ImageBox src={imagePath} alt="" />
              </>
            </Body>
          </>
        ) : (
          <>
            <Header>
              <Title />
              <FaTimes color={iceblue5} onClick={() => props.closeCustomModal()} />
            </Header>
            <Body>
              {isFailed ? (
                <ErrorMsg>商標資料載入錯誤。</ErrorMsg>
              ) : (
                <Spinner width="200px" margin="120px auto" />
              )}
            </Body>
          </>
        )}
      </Container>
    </Backdrop>,
    portalDiv,
  );
};

const mapDispatchToProps = {
  closeCustomModal,
};

export default connect(
  null,
  mapDispatchToProps,
)(TmMetadataModal);
