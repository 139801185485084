import React from 'react';

import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import OpendataContent from 'src/components/ui/content/Opendata/OpendataContent';
import {
  ApiTypeGet,
  ApiInfoTitleStyle,
  ApiParamThStyle,
  ApiParamTdStyle,
  ApiParamTrStyle,
  apiParamNameWidth,
  FirstLineIndent,
  SampleDataDownloadStyle,
} from 'src/style/OpendataStyle';
import ApiToken from 'src/components/opendata/common/ApiToken';

const InventPubReport: React.FC = () => {
  return (
    <OpendataContent
      sector="開放資料"
      feature="公報ZIP檔下載 API"
      foldableDescription={{
        abstract:
          '使用者可透過本API下載最近1年本國(TIPO)、美國(USPTO)、歐盟(EPO)、日本(JPO)及韓國(KIPO)各期專利公報下的單一案號ZIP檔資料。',
        detail: (
          <>
            <FirstLineIndent indent={-23}>
              註. ZIP檔內含該案號之書目及說明書資料(
              <SampleDataDownloadStyle
                onClick={async () => {
                  try {
                    const res = await Api().get('patent/download/period/sample');
                    window.open(res.data);
                  } catch (error) {
                    errorHandler(error);
                  }
                }}
              >
                範例檔案下載
              </SampleDataDownloadStyle>
              )。
              <br />
              查詢專利公報案號請使用「公報清單查詢API」；若要下載1年以前之資料，請使用「公報 OPEN
              DATA 下載」-「整期下載」。
            </FirstLineIndent>
            <FirstLineIndent indent={-23}>
              例. 下載美國專利公報案號為20190349249、類別碼(kind code)為A1之案號ZIP檔，則可輸入參數
              country=“US”, publicationNumber=“20190349249”, kindCode
              =“A1”查詢下載，結果可得US20190349249A1.zip檔案。
            </FirstLineIndent>
          </>
        ),
      }}
    >
      <>
        <p>提供單篇專利ZIP檔下載 API：</p>
        <ApiTypeGet />
        {process.env.REACT_APP_API_URL + 'open/api/patent/inventPub/report'}
        <ApiToken />

        <ApiInfoTitleStyle>輸入參數說明</ApiInfoTitleStyle>
        <ApiParamThStyle>
          <ApiParamTdStyle width={apiParamNameWidth}>Name</ApiParamTdStyle>
          <ApiParamTdStyle>Value</ApiParamTdStyle>
        </ApiParamThStyle>
        <ApiParamTrStyle>
          <ApiParamTdStyle width={apiParamNameWidth}>country</ApiParamTdStyle>
          <ApiParamTdStyle>
            國別：TW - 本國 | US - 美國 | EP - 歐盟 | JP - 日本 | KR - 韓國
          </ApiParamTdStyle>
        </ApiParamTrStyle>
        <ApiParamTrStyle>
          <ApiParamTdStyle width={apiParamNameWidth}>publicationNumber</ApiParamTdStyle>
          <ApiParamTdStyle>專利公開/公告號</ApiParamTdStyle>
        </ApiParamTrStyle>
        <ApiParamTrStyle>
          <ApiParamTdStyle width={apiParamNameWidth}>kindCode</ApiParamTdStyle>
          <ApiParamTdStyle>專利類別碼</ApiParamTdStyle>
        </ApiParamTrStyle>

        <ApiInfoTitleStyle>輸出結果說明</ApiInfoTitleStyle>
        <ApiParamThStyle>
          <ApiParamTdStyle width={apiParamNameWidth}>Type</ApiParamTdStyle>
          <ApiParamTdStyle>Description</ApiParamTdStyle>
        </ApiParamThStyle>
        <ApiParamTrStyle>
          <ApiParamTdStyle width={apiParamNameWidth}>ZIP file</ApiParamTdStyle>
          <ApiParamTdStyle>單一案件壓縮檔，內含專利內容JSON檔及圖式圖檔。</ApiParamTdStyle>
        </ApiParamTrStyle>
      </>
    </OpendataContent>
  );
};

export default InventPubReport;
