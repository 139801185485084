import React from 'react';
import { Route, Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { PrivateRoute } from 'src/routes/PrivateRoute';

import { Auth } from 'src/apps/auth/Auth';

import IndexLayout from 'src/components/index/IndexLayout';
import ShowcaseLayout from 'src/components/showcase/ShowcaseLayout';
import SearchLayout from 'src/components/search/SearchLayout';
import DocdbData from 'src/components/search/docdb/DocdbData';
import NewsLayout from 'src/components/news/NewsLayout';
import OpendataLayout from 'src/components/opendata/OpendataLayout';
import QuestionLayout from 'src/components/question/QuestionLayout';
import ArticleLayout from 'src/components/article/ArticleLayout';
import ExpertLayout from 'src/components/expert/ExpertLayout';
import ExpertDetail from 'src/components/expert/ExpertDetail';
import ExpertDetailCompare from 'src/components/expert/ExpertDetailCompare';
import MemberLayout from 'src/components/member/MemberLayout';

import ResultLayout from 'src/components/result/ResultLayout';
import PatentLayout from 'src/components/patent/PatentLayout';
import ByImageResult from 'src/components/search/ByImageResult';

import UserLayout from 'src/components/common/user/UserLayout';
import CheckLayout from 'src/components/common/check-profile/CheckLayout';
import TagInfoLayout from 'src/components/common/tag-info/TagInfoLayout';
import CookieLayout from 'src/components/common/cookie/CookieLayout';

import MobileArticleLayout from 'src/components/mobile/article/MobileArticleLayout';

import useLoginChecker from 'src/apps/auth/useLoginChecker';
import TrademarkVisualResult from 'src/components/search/trademark/TrademarkVisualResult';

import { showArticleContent } from 'src/components/article/item/ColumnistArticleItem';

function AppRoute() {
  const isLogin = useLoginChecker();

  return (
    Auth().getAuthData() && (
      <Switch>
        <Route path="/lite/article" component={MobileArticleLayout} />
        <Route path="/" exact component={IndexLayout} />
        <Route path="/showcase" component={ShowcaseLayout} />
        <Route path="/search" component={SearchLayout} />
        <Route path="/news" component={NewsLayout} />
        <PrivateRoute isLogin={isLogin} path="/data" component={OpendataLayout} />
        <Route
          path="/article/columnist/:id/view"
          component={(props: any) => {
            showArticleContent(props.match.params.id);
            return null;
          }}
        />
        <Route path="/article" component={ArticleLayout} />
        <Route path="/expert/:id(\d+)" component={ExpertDetail} />
        <Route path="/experts" component={ExpertDetailCompare} />
        <Route path="/expert" component={ExpertLayout} />
        <Route path="/question" component={QuestionLayout} />
        <PrivateRoute isLogin={isLogin} path="/member" component={MemberLayout} />
        <Route path="/result/searchByImage" component={ByImageResult} />
        <Route path="/result/trademark/visual" component={TrademarkVisualResult} />
        <Route path="/result" component={ResultLayout} />
        <Route path="/patent/:patentId/view" component={PatentLayout} />
        <Route path="/docdb/:docdbId/view" component={DocdbData} />
        <Route path="/user" component={UserLayout} />
        <Route path="/email" component={CheckLayout} />
        <Route path="/password" component={CheckLayout} />
        <Route path="/tag" component={TagInfoLayout} />
        <Route path="/cookie-policy" exact component={CookieLayout} />
        <Route
          path="/refresh"
          component={({ history, location, match }: RouteComponentProps) => {
            history.replace({
              ...location,
              pathname: location.pathname.substring(match.path.length),
            });
            return null;
          }}
        />
        <Route component={() => <Redirect to="/" />} />
      </Switch>
    )
  );
}

export default AppRoute;
