import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import { IGetColumnistArticle } from 'src/types/api/ColumnistArticleApiTypes';
import { SystemMessage } from 'src/apps/applicationMessages';

import ColumnistArticleContent from 'src/components/ui/content/Column/ColumnistArticleContent';
import ColumnistArticleItem from './item/ColumnistArticleItem';
import { Spinner } from 'src/components/ui/interactive/Spinner';
import { blue2, blue8 } from 'src/style/theme/Color';
import axios from 'axios';
import { unstable_batchedUpdates } from 'react-dom';
import InfiniteScroll from 'src/components/ui/interactive/InfiniteScroll';

const NoResult = styled.div`
  margin: 16px 32px;
  padding: 16px 32px;
  background-color: ${blue8};

  div {
    color: ${blue2};
    font-weight: 600;
    margin-bottom: 8px;
  }
`;
const ContentStyle = styled.div`
  padding: 0 0 16px;
  width: 100%;
`;

interface IProps {
  sector: string;
  feature?: string;
  categoryId?: number;
}

const ColumnistArticle: React.FC<IProps> = ({ feature, sector, categoryId }) => {
  const [articles, setArticles] = useState<Array<IGetColumnistArticle>>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [noData, setNoData] = useState(false);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [isNextPageLoading, setIsNextPageLoading] = useState<boolean>(false);
  const [isNextPageError, setIsNextPageError] = useState<boolean>(false);
  const [pageNo, setPageNo] = useState<number>(1);
  const [queryString, setQueryString] = useState<string>();

  const loadClickHandler = () => {
    unstable_batchedUpdates(() => {
      setIsNextPageLoading(true);
      setPageNo(pageNo + 1);
    });
  };
  const reloadClickHandler = () => {
    if (pageNo === 1) {
      setIsLoading(true);
    } else {
      setIsNextPageLoading(true);
    }
  };
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const fetchData = async () => {
      try {
        const res = await Api().get('article/columnist', {
          params: {
            pageNo: pageNo,
            categoryId: categoryId,
            keyword: queryString,
          },
          cancelToken: source.token,
        });

        setTotalPages(res.data.totalPages);
        setTotalCount(res.data.total);
        if (res.data.data && res.data.data.length > 0) {
          setArticles(art => [...(art || []), ...res.data.data]);
          isNextPageLoading && setIsNextPageError(false);
          setNoData(false);
        } else {
          setNoData(true);
        }
      } catch (error) {
        errorHandler(error);
        isNextPageLoading && setIsNextPageError(true);
      } finally {
        setIsLoading(false);
        setIsNextPageLoading(false);
      }
    };

    (isLoading || isNextPageLoading) && fetchData();

    return () => source.cancel();
  }, [pageNo, categoryId, queryString, isLoading, isNextPageLoading]);

  const handleSearch = (queryString: string) => {
    setQueryString(queryString);
    setArticles([]);
    setPageNo(1);
    setIsLoading(true);
  };

  return (
    <ContentStyle>
      <ColumnistArticleContent sector={sector} feature={feature} subtitle="" search={handleSearch}>
        <>
          {isLoading && <Spinner width="150px" />}
          {!isLoading && noData ? (
            <NoResult>
              <div>{SystemMessage.COLUMNIST_ARTICLE_NO_DATA}</div>
            </NoResult>
          ) : (
            <InfiniteScroll
              isNextPageLoading={isNextPageLoading}
              isNextPageError={isNextPageError}
              errorMessage={'系統發生異常'}
              handleNextPageLoad={loadClickHandler}
              handleReload={reloadClickHandler}
              totalCount={totalCount}
              currentPage={pageNo}
              totalPages={totalPages}
            >
              {articles &&
                articles.map(item => <ColumnistArticleItem key={item.id} columnData={item} />)}
            </InfiniteScroll>
          )}
        </>
      </ColumnistArticleContent>
    </ContentStyle>
  );
};

export default ColumnistArticle;
