import React, { memo } from 'react';
import Highlighter from 'react-highlight-words';

const highlightStyle = {
  backgroundColor: 'transparent',
  color: 'inherit',
  fontWeight: 900,
};

interface IProps {
  searchWords: string[];
  textToHighlight: string;
  highlightStyle?: any;
  unhighlightStyle?: any;
}

const ResultHighlighter: React.FC<IProps> = memo(props => {
  return (
    <Highlighter
      className="highlighter"
      highlightClassName="highlight"
      unhighlightClassName="unhighlight"
      activeIndex={-1}
      autoEscape={false}
      highlightStyle={props.highlightStyle ? props.highlightStyle : highlightStyle}
      {...props}
    />
  );
});

export default ResultHighlighter;
