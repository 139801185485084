import { defaultPaginatedData } from 'src/types/defaultData/paginatedDataDefault';
import { TmApplMetadata } from 'src/types/TrademarkTypes';
import {
  NAVIGATE_TO_NEXT_PAGE,
  FETCH_VISUAL_SEARCH_RESULT_START,
  FETCH_VISUAL_SEARCH_RESULT_SUCCESS,
  FETCH_VISUAL_SEARCH_RESULT_ERROR,
  VisualSearchSearchState,
  VisualSearchSearchActionTypes,
  SET_VISUAL_SEARCH_REQUEST,
  DETACH_TM_ADVANCED_KEYWORD,
  APPEND_TM_ADVANCED_KEYWORD,
  FETCH_FILTER_RESULT_SUCCESS,
  FETCH_TM_FACETS_START,
  FETCH_TM_FACETS_SUCCESS,
  FETCH_TM_FACETS_ERROR,
  FETCH_GOODS_CLASS_DESCRIPTIONS_START,
  FETCH_GOODS_CLASS_DESCRIPTIONS_SUCCESS,
  FETCH_GOODS_CLASS_DESCRIPTIONS_ERROR,
  FETCH_FILTER_RESULT_ERROR,
  FETCH_IMG_CLASS_DESCRIPTIONS_START,
  FETCH_IMG_CLASS_DESCRIPTIONS_SUCCESS,
  FETCH_IMG_CLASS_DESCRIPTIONS_ERROR,
  HANDLE_SUGGESTIONS_START,
  HANDLE_SUGGESTIONS_SUCCESS,
  HANDLE_SUGGESTIONS_ERROR,
  FETCH_FILTER_RESULT_START,
  FETCH_TM_METADATAS_START,
  FETCH_TM_METADATAS_SUCCESS,
  FETCH_TM_METADATAS_ERROR,
  FETCH_IMG_CLASS_PREDICT_START,
  FETCH_IMG_CLASS_PREDICT_SUCCESS,
  FETCH_IMG_CLASS_PREDICT_ERROR,
  FETCH_IMG_CLASS_MAPPING_SUCCESS,
} from './type';

const PAGE_SIZE = 60;

const initialState: VisualSearchSearchState = {
  request: {
    caseNo: undefined,
    categoryId: undefined,
    image: undefined,
    featureType: 2,
    shapeAlpha: 0.4,
    readMetadata: true,
  },
  response: defaultPaginatedData,
  paginatedData: { ...defaultPaginatedData, facets: [] },
  filteredData: [],
  isLoading: false,
  isFacetLoading: false,
  isMetadataLoaded: false,
  isSuggestionLoading: false,
  isGoodsClassDescsLoading: false,
  isImgClassDescsLoading: false,
  suggestions: [],
  advancedKeywords: [],
  goodsClassDescriptions: {},
  imgClassDescriptions: {},
  isImgClassPredictLoading: false,
  imgClassPredictions: [],
  imgClassMapping: {},
};

const trademarkVisualSearchReducer = (
  state = initialState,
  action: VisualSearchSearchActionTypes,
): VisualSearchSearchState => {
  const prevPaginatedData = state.paginatedData;
  const prevadvancedKeywords = state.advancedKeywords;
  const prevResponseData = state.response;
  const prevFilteredData = state.filteredData;

  switch (action.type) {
    // 設定以圖找圖索引 request
    case SET_VISUAL_SEARCH_REQUEST:
      return {
        ...state,
        request: action.request,
      };

    // 顯示下一頁
    case NAVIGATE_TO_NEXT_PAGE:
      const newPageNo = prevPaginatedData.pageNo + 1;
      return {
        ...state,
        paginatedData: {
          ...prevPaginatedData,
          pageNo: newPageNo,
          size: PAGE_SIZE,
          data: state.filteredData.slice(0, newPageNo * PAGE_SIZE),
        },
      };

    // 以圖找圖檢索
    case FETCH_VISUAL_SEARCH_RESULT_START:
      return {
        ...state,
        isLoading: true,
        isMetadataLoaded: false,
        isFacetLoading: true,
        isSuggestionLoading: true,
        errorMsg: undefined,
      };
    case FETCH_VISUAL_SEARCH_RESULT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        response: action.response,
        filteredData: action.response.data,
        paginatedData: {
          ...action.response,
          totalPages: Math.ceil(action.response.totalCount / PAGE_SIZE),
          data: action.response.data.slice(0, PAGE_SIZE),
          size: PAGE_SIZE,
          facets: action.response.facets,
        },
      };
    }
    case FETCH_VISUAL_SEARCH_RESULT_ERROR:
      return {
        ...state,
        isLoading: false,
        isFacetLoading: false,
        isSuggestionLoading: false,
        errorMsg: action.errorMsg,
        // 要將資料重置, 避免顯示舊的資料
        response: defaultPaginatedData,
        paginatedData: { ...defaultPaginatedData, facets: [] },
        filteredData: [],
        suggestions: [],
        advancedKeywords: [],
        goodsClassDescriptions: {},
        imgClassDescriptions: {},
      };
    case FETCH_FILTER_RESULT_START:
      return {
        ...state,
        isLoading: true,
        isFacetLoading: true,
        isSuggestionLoading: true,
      };
    // 根據進階檢索條件過濾資料
    case FETCH_FILTER_RESULT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        filteredData: action.data,
        paginatedData: {
          ...prevPaginatedData,
          // 重置成 1, 否則下拉頁數錯誤或甚至無法下拉
          // ex. 第 2 頁時點選篩選bar, 假如篩選結果只有2頁, 則往下拉時因判斷此時頁數(2)已達總頁數, 導致無法下拉
          pageNo: 1,
          totalCount: action.data.length,
          totalPages: Math.ceil(action.data.length / PAGE_SIZE),
          data: action.data.slice(0, PAGE_SIZE),
          size: PAGE_SIZE,
        },
      };
    case FETCH_FILTER_RESULT_ERROR:
      return {
        ...state,
        isLoading: false,
        isFacetLoading: false,
        isSuggestionLoading: false,
      };
    // 分組統計
    case FETCH_TM_FACETS_START:
      return {
        ...state,
        isFacetLoading: true,
      };
    case FETCH_TM_FACETS_SUCCESS:
      return {
        ...state,
        isFacetLoading: false,
        paginatedData: {
          ...prevPaginatedData,
          facets: action.facets,
        },
      };
    case FETCH_TM_FACETS_ERROR:
      return {
        ...state,
        isFacetLoading: false,
      };
    // 詮釋資料讀取
    case FETCH_TM_METADATAS_START:
    case FETCH_TM_METADATAS_ERROR:
      return {
        ...state,
        isMetadataLoaded: false,
      };
    case FETCH_TM_METADATAS_SUCCESS:
      // 轉為 key: 案號-圖檔編號, value: TmApplMetadata 的 map
      const mapping = action.response.reduce(
        (obj, item) => {
          obj[item['appl-no'] + '-' + item['img-no']] = item;
          return obj;
        },
        {} as { [key: string]: TmApplMetadata },
      );

      // 將完整清單跟過濾清單加入詮釋資料
      const full = prevResponseData.data.map(item => {
        return { ...item, metadata: mapping[item.caseNo] };
      });
      const filtered = prevFilteredData.map(item => {
        return { ...item, metadata: mapping[item.caseNo] };
      });

      return {
        ...state,
        isMetadataLoaded: true,
        response: { ...prevResponseData, data: full },
        filteredData: filtered,
        paginatedData: {
          ...prevPaginatedData,
          data: full.slice(0, prevPaginatedData.pageNo * PAGE_SIZE),
        },
      };
    // 商品服務類別中文敘述
    case FETCH_GOODS_CLASS_DESCRIPTIONS_START:
      return { ...state, isGoodsClassDescsLoading: true };
    case FETCH_GOODS_CLASS_DESCRIPTIONS_SUCCESS: {
      return {
        ...state,
        goodsClassDescriptions: { ...action.response, 無商品服務類別: '' },
        isGoodsClassDescsLoading: false,
      };
    }
    case FETCH_GOODS_CLASS_DESCRIPTIONS_ERROR:
      return {
        ...state,
        goodsClassDescriptions: {},
        isGoodsClassDescsLoading: false,
      };

    // 圖形路徑(2階)中文敘述
    case FETCH_IMG_CLASS_DESCRIPTIONS_START:
      return { ...state, isImgClassDescsLoading: true };
    case FETCH_IMG_CLASS_DESCRIPTIONS_SUCCESS: {
      return {
        ...state,
        imgClassDescriptions: { ...action.response, 無圖形路徑: '' },
        isImgClassDescsLoading: false,
      };
    }
    case FETCH_IMG_CLASS_DESCRIPTIONS_ERROR:
      return {
        ...state,
        imgClassDescriptions: {},
        isImgClassDescsLoading: false,
      };

    // 增加進階檢索條件
    case APPEND_TM_ADVANCED_KEYWORD: {
      const advancedKeywords = prevadvancedKeywords;
      const newAdvancedKeyword = {
        keyCode: action.keyCode,
        keyword: action.keyword,
      };
      return {
        ...state,
        advancedKeywords: advancedKeywords
          ? advancedKeywords.concat([newAdvancedKeyword])
          : [newAdvancedKeyword],
      };
    }
    // 移除進階檢索條件
    case DETACH_TM_ADVANCED_KEYWORD: {
      const advancedKeywords = prevadvancedKeywords;
      if (advancedKeywords) {
        const newAdvancedKeywords = advancedKeywords.filter(
          item => item.keyCode !== action.keyCode,
        );
        return {
          ...state,
          advancedKeywords: newAdvancedKeywords,
        };
      } else {
        return {
          ...state,
        };
      }
    }

    // 計算取得商品名稱推薦詞
    case HANDLE_SUGGESTIONS_START:
      return {
        ...state,
        isSuggestionLoading: true,
      };
    case HANDLE_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        isSuggestionLoading: false,
        suggestions: action.suggestions,
      };
    case HANDLE_SUGGESTIONS_ERROR:
      return {
        ...state,
        isSuggestionLoading: false,
      };

    // 圖形路徑預測
    case FETCH_IMG_CLASS_PREDICT_START:
      return {
        ...state,
        isImgClassPredictLoading: true,
      };
    case FETCH_IMG_CLASS_PREDICT_SUCCESS:
      return {
        ...state,
        isImgClassPredictLoading: false,
        imgClassPredictions: action.response,
      };
    case FETCH_IMG_CLASS_PREDICT_ERROR:
      return {
        ...state,
        isImgClassPredictLoading: false,
        imgClassPredictions: [],
      };

    // 圖形路徑對應表
    case FETCH_IMG_CLASS_MAPPING_SUCCESS:
      return {
        ...state,
        imgClassMapping: { ...action.response },
      };
    default:
      return { ...state };
  }
};

export default trademarkVisualSearchReducer;
