import styled from 'styled-components';
import { Field } from 'formik';
import { red1, iceblue7 } from 'src/style/theme/Color';
import DatePicker from 'react-datepicker';

// Layout
export const ContentStyle = styled.div`
  width: 67%;
  margin: auto;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
`;

export const ContentHeaderStyle = styled.div`
  background-image: linear-gradient(95deg, #2ec3c2, #0cafcc);
  border-radius: 4px 4px 0 0;
  padding: 16px 32px 16px 32px;
`;

export const ContentHeaderTitleStyle = styled.div`
  display: flex;
  align-items: center;
  color: #ffffff;
  font-size: 24px;
  font-weight: 600;
  height: 32px;
`;
export const ContentHeaderSubtitleStyle = styled.span`
  font-size: 15px;
  margin-left: 8px;
  font-weight: normal;
`;

export const FormContainer = styled.div`
  padding: 0 32px 0 32px;
  ul {
    padding-left: 24px;
    margin: 0;
  }
`;
export const ContentBodyStyle = styled.div`
  padding: 24px 32px 24px 32px;
  line-height: 1.5em;
`;

export const FirstLineIndent = styled.div<{ indent: number }>`
  text-indent: ${props => props.indent + 'px'};
  margin-left: ${props => (props.indent < 0 ? 0 - props.indent + 'px' : 0)};
  margin-top: 8px;
`;
// eslint-disable-next-line
export const ContentHeaderInfoCollapseStyle = styled.div<{
  isCollapse: boolean;
}>`
  background-color: rgba(255, 255, 255, 0.2);
  color: #ffffff;
  line-height: 1.5em;
  padding: 8px 76px 8px 32px;
  height: ${props => (props.isCollapse ? '41px' : 'auto')};
  text-overflow: ellipsis;
  white-space: ${props => (props.isCollapse ? 'nowrap' : 'warp')};
  overflow: ${props => (props.isCollapse ? 'hidden' : 'visible')};
`;

export const ContentHeaderInfoCollapseButtonStyle = styled.div`
  width: 28px;
  height: 26px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.2);
  position: relative;
  color: #ffffff;
  top: -26px;
  float: right;
  text-align: center;
  margin-right: 24px;
`;

export const FlexStyle = styled.div`
  display: flex;
  margin-top: 8px;
  * {
    margin-right: 16px;
  }
`;
export const TabsStyle = styled.div`
  display: flex;
  margin: 24px 32px 0 32px;
`;
export const TabStyle = styled.div<{ active: boolean }>`
  height: 31px;
  border-radius: 4px 4px 0 0;
  background-color: ${props =>
    props.active ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.3)'};
  text-align: center;
  margin-left: 4px;
  padding: 0 16px 0 16px;
  color: ${props => (props.active ? '#077790' : '#ffffff')};
`;

//Form
export const ItemContainer = styled.div`
  padding: 0 0 16px 0;
  width: 80%;
  display: flex;
  align-items: center;
  > span {
    width: 30%;
  }
`;
export const ItemInput = styled.div`
  width: 70%;
  display: flex;
  align-items: center;
  input,
  select {
    padding: 0 8px;
    width: 100%;
  }
`;
export const FieldStyle = styled(Field)<{ width?: string }>`
  width: ${props => (props.width ? props.width : '60%')};
  height: 36px;
  border-radius: 4px;
  border: solid 1px #979ea1;
  background-color: #ffffff;
  color: #323846;
  padding: 0 16px 0 16px;
`;
export const DatePickerStyle = styled(DatePicker)<{ width?: string }>`
  width: 100%;
  height: 36px;
  border-radius: 4px;
  border: solid 1px #979ea1;
  background-color: #ffffff;
  ::placeholder {
    color: #b3b9bc;
  }
`;
export const IconLabelStyle = styled.label<{ width?: string }>`
  position: relative;
  width: ${props => props.width};
  display: flex;
  align-items: center;

  /* DatePicker 會自己長兩個div出來，必須強制給寬度 */
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__input-container {
    width: 100%;
  }
  .react-datepicker-popper {
    z-index: 2;
  }

  input {
    width: 100%;
    padding: 0 40px 0 16px;
  }

  svg {
    position: absolute;
    right: 12px;
    font-size: 16px;
    :hover {
      cursor: pointer;
    }
    z-index: 1;
  }
`;

export const SymbolStyle = styled.span`
  padding: 0 16px;
  line-height: 36px;
`;

//Buttons
export const ButtonAreaStyle = styled.div`
  width: 70%;
  padding: 28px 0 28px 28%;
`;

export const SampleDataDownloadStyle = styled.span`
  color: ${iceblue7};
  cursor: pointer;
  text-decoration: underline;
`;

//Download Result style
export const DownloadTrStyle = styled.div`
  border-bottom: 1px solid #eceff1;
  display: flex;
  border-bottom: 1px solid #eceff1;
  line-height: 53px;
  padding: 0 32px 0 32px;
`;

export const DownloadThStyle = styled(DownloadTrStyle)`
  background-color: #ffffff;
  line-height: 38px;
  * {
    color: #979ea1;
  }
`;

export const DownloadTdStyle = styled.div<{ width?: string }>`
  width: ${props => props.width};
  padding: 4px 16px;
`;

export const DownloadSearchResultStyle = styled.div`
  background-color: #f9f9f9;
  line-height: 1.5em;
  min-height: 200px;
  padding: 0 0 32px 0;
  border-radius: 0 0 4px 4px;
  > p {
    margin-top: 32px;
    color: #b3b9bc;
    text-align: center;
  }
`;
export const DownloadButton = styled.div`
  width: 100%;
  cursor: pointer;
`;

export const DownloadItemName = styled.span<{ active?: boolean }>`
  ${props => props.active && 'color:#077790;'}
`;

//BilingualTerm style
export const ApiTypeGet = styled.div`
  display: inline-block;
  font-weight: bold;
  text-align: center;
  line-height: 2em;
  border-radius: 4px;
  margin: 8px 8px 0 0;
  width: 72px;
  color: #ffffff;
  background-color: #49cc90;
  ::before {
    content: 'GET';
  }
`;

export const ApiTypePost = styled(ApiTypeGet)`
  background-color: #61affe;
  ::before {
    content: 'POST';
  }
`;

export const ApiTokenStyle = styled.div`
  margin-top: 16px;
  padding: 16px;
  border-radius: 4px;
  background-color: #edf6f7;
  display: flex;
  > * {
    padding: 0 8px 0 8px;
  }
`;

export const ApiTokenCopyStyle = styled.span`
  color: #0cafcc;
  cursor: pointer;
`;

export const ApiInfoTitleStyle = styled.div`
  margin: 32px 0 8px 0;
  font-weight: bold;
`;

export const apiParamNameWidth = '180px';

export const ApiParamTrStyle = styled.div`
  border-bottom: 1px solid #eceff1;
  display: flex;
  line-height: 1.8em;
`;

export const ApiParamThStyle = styled(ApiParamTrStyle)`
  border-bottom: 1px solid #7dd3e2;
  * {
    color: #979ea1;
  }
`;

export const ApiParamTdStyle = styled.div<{ width?: string }>`
  width: ${props => (props.width ? props.width : 'auto')};
  padding: 4px 16px 4px 16px;
`;

export const ApiOutputStyle = styled.div`
  background-color: #f9f9f9;
  line-height: 1.5em;
  padding: 20px;
`;

// ErrorMessage
export const ErrorMsg = styled.div`
  width: 100%;
  color: ${red1};
`;
