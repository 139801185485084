import React from 'react';
import styled from 'styled-components';
import { blue2, white } from 'src/style/theme/Color';

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const BetaIcon = styled.div`
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  background-color: ${white};
  > div {
    margin: 2px 3px 2px 2px;
    color: ${blue2};
    font-size: 9px;
    font-weight: 600;
  }
`;

/** 客製化 以圖找圖 側選單 */
export const ByImageSideNav: JSX.Element = (
  <>
    <Row>本國設計專利</Row>
    <Row>
      以圖找圖&nbsp;
      <BetaIcon>
        <div>BETA</div>
      </BetaIcon>
    </Row>
  </>
);
