import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import LoginBlock from 'src/components/common/login/LoginBlock';

interface IProps extends RouteProps {
  isLogin: boolean;
}

export const PrivateRoute = ({ component: Component, isLogin, render, ...rest }: IProps) => {
  if (render) {
    return (
      <Route
        {...rest}
        render={
          isLogin
            ? render
            : props => (
                <Redirect
                  to={{
                    pathname: '/login',
                    state: { from: props.location },
                  }}
                />
              )
        }
      />
    );
  }
  return (
    <Route
      {...rest}
      render={props =>
        isLogin ? (
          // @ts-ignore
          <Component {...props} {...rest} />
        ) : (
          <LoginBlock />
        )
      }
    />
  );
};
