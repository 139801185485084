import React, { useState } from 'react';
import styled from 'styled-components';
import ItemsCarousel from 'react-items-carousel';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';

import { LinkOut } from 'src/utils/LinkOut';
import { blue5, iceblue1, iceblue4, iceblue6 } from 'src/style/theme/Color';

const Container = styled.div`
  margin: 0 0 0 36px;
  padding: 14px ${props => `${props.chevronSpace}px`};
  width: 100%;
  min-width: 400px;
`;

const chevronSpace = 80;
const chevronWidth = chevronSpace - 32;
const transitionTime = 0.6;
const Chevron = styled.button`
  width: ${chevronWidth}px;
  height: ${chevronWidth}px;
  border: none;
  background-color: transparent;
  outline: none;
  :hover {
    transition: ${transitionTime}s;
    border-color: ${blue5};
  }

  svg {
    display: block;
    color: ${iceblue6};
    font-size: ${chevronWidth}px;
    :hover {
      transition: ${transitionTime}s;
      color: ${blue5};
    }
  }
`;
const ChevronLeft = styled(Chevron)`
  margin: auto auto auto 0;
`;
const ChevronRight = styled(Chevron)`
  margin: auto 0 auto auto;
`;
const Film = styled.div`
  padding: 0 8px;
`;
const Thumbnail = styled.div`
  padding: 1px;
  text-align: center;
  img {
    max-width: 100%;
    height: auto;
  }
`;
const Title = styled.div`
  > a {
    color: ${iceblue1};
    font-weight: bold;
  }
`;
const Description = styled.div`
  > * {
    color: ${iceblue4};
  }
`;

export const FilmCarousel = ({ filmData }) => {
  const [activeItemIndex, setActiveItemIndex] = useState(0);

  const channels = filmData.channels.reduce((result, item) => {
    const key = item.id;
    result[key] = { name: item.name, url: item.url };
    return result;
  }, {});

  return (
    <Container chevronSpace={chevronSpace}>
      <ItemsCarousel
        requestToChangeActive={setActiveItemIndex}
        activeItemIndex={activeItemIndex}
        numberOfCards={3}
        gutter={20}
        firstAndLastGutter={true}
        leftChevron={
          <ChevronLeft>
            <FaAngleLeft />
          </ChevronLeft>
        }
        rightChevron={
          <ChevronRight>
            <FaAngleRight />
          </ChevronRight>
        }
        outsideChevron={true}
        alwaysShowChevrons={false}
        chevronWidth={chevronSpace}
      >
        {filmData.films.map(film => {
          return (
            <Film key={film.pic}>
              <Thumbnail>
                <LinkOut href={film.url}>
                  <img src={film.pic} alt={film.title} />
                </LinkOut>
              </Thumbnail>
              <Title>
                <LinkOut href={film.url}>{film.title}</LinkOut>
              </Title>
              <Description>
                <LinkOut href={channels[film.channelId].url}>
                  {channels[film.channelId].name}
                </LinkOut>
                <span>&nbsp;&nbsp;|&nbsp;&nbsp;{film.createTime}</span>
              </Description>
            </Film>
          );
        })}
      </ItemsCarousel>
    </Container>
  );
};
