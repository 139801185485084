import styled from 'styled-components';
import { ultrablackrgba, iceblue5, blue2, blue7, blue8 } from 'src/style/theme/Color';

export const Container = styled.div`
  width: calc(100% - 10px);
  box-shadow: 0 0 50px 0 ${ultrablackrgba(0.1)};
`;
export const Table = styled.div`
  width: 100%;
  display: table;
  border-collapse: collapse;
`;
const Row = styled.div`
  display: table-row;
`;
export const HRow = styled(Row)`
  height: 38px;
  color: ${iceblue5};
  background-color: ${blue7};
`;
export const TRow = styled(Row)`
  height: 62px;
`;
export const TCell = styled.div`
  display: table-cell;
  vertical-align: middle;
`;
export const HOne = styled(TCell)`
  padding: 0 0 0 32px;
  width: 20%;
`;
export const HTwo = styled(TCell)``;
export const HThree = styled(TCell)`
  padding: 0 32px 0 0;
  width: 20%;
`;

export const HArticleOne = styled(TCell)`
  padding: 0 0 0 32px;
`;
export const HArticleTwo = styled(TCell)`
  width: 20%;
`;
export const HArticleThree = styled(TCell)`
  padding: 0 32px 0 0;
  width: 15%;
`;
export const ReadMore = styled.div`
  width: 100%;
  color: ${blue2};
  background-color: ${blue8};
  line-height: 58px;
  text-align: center;
  cursor: pointer;
`;
