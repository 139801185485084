import React from 'react';
import styled from 'styled-components';
import string_search from 'src/assets/images/manual/patent-assisted-read/string_search.png';

const ImgContainer = styled.div<{ height: string; imageUrl: string }>`
  position: relative;
  height: ${props => props.height}px;
  padding: 10px 0;
  background-image: url(${props => props.imageUrl});
  background-size: contain;
  background-repeat: no-repeat;
`;
export default function PatentAssistedReadManual() {
  return (
    <>
      <p>於文內字串檢索，輸入欲查找字詞，按下查詢文內標示出字詞，於內容導航也可顯示出其分布。</p>
      <br />
      <ImgContainer height="400" imageUrl={string_search} />
    </>
  );
}
