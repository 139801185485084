import React, { useState } from 'react';
import styled from 'styled-components';
import { zToolbar } from 'src/style/theme/Z-Index';
import {
  blue7,
  iceblue1,
  iceblue2,
  iceblue6,
  iceblue7,
  ultrablackrgba,
  ultrawhite,
  ultrawhitergba,
  white,
} from 'src/style/theme/Color';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import { IDropdownItem } from 'src/components/common/Dropdown';
import useOutSideClick from 'src/hooks/useOutSideClick';

const DropDownContainer = styled.div`
  position: relative;
  min-width: 0;
  z-index: ${zToolbar};
`;
const DropDownButton = styled.div<{
  rightBorder?: boolean;
}>`
  width: 100%;
  height: 60px;
  padding: 8px 16px;
  border-radius: 4px 0 0 4px;
  background-color: ${white};
  backdrop-filter: blur(8px);
  border-right: ${props => (props.rightBorder ? `1px solid ${iceblue7}` : null)};
  color: ${iceblue1};
  > span {
    display: block;
    font-size: 13px;
    color: ${iceblue6};
  }
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  :hover {
    cursor: pointer;
  }
`;
const CheckedItemNames = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const Menu = styled.div`
  position: absolute;
  margin-top: 8px;
  box-shadow: 0 6px 20px 0 ${ultrablackrgba(0.1)};
  background-color: ${ultrawhite};
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
  :after {
    content: '';
    position: absolute;
    background-image: linear-gradient(to bottom, ${ultrawhitergba(0)}, ${ultrawhite});
    height: 5px;
    width: 100%;
    bottom: 0;
  }
`;
const MenuItems = styled.div`
  max-height: 250px;
  overflow-x: hidden;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0 !important;
  }
  ::-ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  padding: 4px 0 4px 0;
`;
const MenuItem = styled.div`
  background-color: ${ultrawhite};
  color: ${iceblue2};
  padding: 8px 16px;
  min-height: 38px;
  : hover {
    background-color: ${blue7};
  }
`;

interface IProps {
  data: Array<IDropdownItem>;
  selectedItem: IDropdownItem | null;
  setSelectedItem: (items: IDropdownItem) => void;
  dataTitle: string;

  withRightBorder?: boolean;
}

const DropDown: React.FC<IProps> = ({
  data,
  dataTitle,
  withRightBorder,
  selectedItem,
  setSelectedItem,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const node = useOutSideClick({
    show: isOpen,
    setShow: setIsOpen,
  });
  const handleSelect = (item: IDropdownItem) => {
    setIsOpen(!isOpen);
    setSelectedItem(item);
  };

  return (
    <DropDownContainer ref={node as any}>
      <DropDownButton onClick={() => setIsOpen(!isOpen)} rightBorder={withRightBorder}>
        <span>{dataTitle}</span>
        <div>
          <CheckedItemNames>{selectedItem ? selectedItem.name : data[0].name}</CheckedItemNames>
          {isOpen ? <FaCaretUp color={iceblue1} /> : <FaCaretDown color={iceblue1} />}
        </div>
      </DropDownButton>
      {isOpen && (
        <Menu>
          <MenuItems>
            {data.map(item => (
              <MenuItem key={item.value} onClick={() => handleSelect(item)}>
                {item.name}
              </MenuItem>
            ))}
          </MenuItems>
        </Menu>
      )}
    </DropDownContainer>
  );
};

export default DropDown;
