import React from 'react';
import styled from 'styled-components';
import data_range from 'src/assets/images/manual/search-result-analysis/data_range.png';

const ImgContainer = styled.div<{ height: string; imageUrl: string }>`
  position: relative;
  height: ${props => props.height}px;
  padding: 10px 0;
  background-image: url(${props => props.imageUrl});
  background-size: contain;
  background-repeat: no-repeat;
`;
export default function SearchResultAnalysisManual() {
  return (
    <>
      <p>檢索結果預設範圍為本國專利，可點選切換不同專利局顯示其結果。</p>
      <br />
      <ImgContainer height="500" imageUrl={data_range} />
    </>
  );
}
