import React from 'react';
import styled, { css } from 'styled-components';
import { FaSearch, FaSortAmountDown, FaThLarge, FaThList, FaTimes } from 'react-icons/fa';
import { RouteComponentProps } from 'react-router-dom';
import ResultListItem from './ResultListItem';
import ResultGridItem from './ResultGridItem';
import ResultPreviewPane from './ResultPreviewPane';
import LeftMenu from './left-menu/LeftMenu';
import WaterfallGrid from 'src/components/ui/interactive/WaterfallGrid';
import RightToolbar from './right-toolbar/RightToolbar';
import MatrixFilterTag from 'src/components/result/right-toolbar/matrix/MatrixFilterTag';
import SlideToggle from 'src/components/ui/interactive/SlideToggle';
import Tooltip from 'src/components/ui/interactive/Tooltip';
import { dateRangeItems, sortTypeItems, countries } from 'src/constants/PatentSearchConstants';

import { ISelectedItem } from 'src/components/common/Dropdown';
import FilterDropdown from 'src/components/ui/interactive/dropdown-select/FilterDropdown';
import IndustrialClassDropdown from 'src/components/trend/industrialClass/ui/Dropdown';
import { toDropdownItem } from 'src/utils/IndustryUtils';
import Filter from 'src/components/result/left-menu/Filter';
import Cluster from 'src/components/result/left-menu/Cluster';
import {
  blue2,
  blue2rgba,
  blue3,
  blue5rgba,
  blue7,
  blue8,
  iceblue1,
  iceblue3,
  iceblue8,
  ultrawhite,
  ultrawhitergba,
  white,
  whitergba,
} from 'src/style/theme/Color';
import { connect } from 'react-redux';
import { IIndustrialClassType } from 'src/types/IndustryTypes';
import { ReduxAppState } from 'src/redux/reducers';
import {
  changeSortOrder,
  composeBasicRequest,
  navigateToNextPage,
  selectDateRange,
  appendAdvancedKeywordCountry,
} from 'src/redux/actions/patentSearchAction';
import {
  IBasicRequest,
  IBilingualTermsRequest,
  IFilterTag,
  ISearchRequest,
} from 'src/types/PatentSearchTypes';
import { IPaginatedData } from 'src/types/PaginatedDataTypes';
import { IGetPatentTypes } from 'src/types/IGetPatentTypes';
import { fetchPatentResult } from 'src/redux/actions/patentResultAction';
import { fetchBilingualTerms, fetchFacets } from 'src/redux/actions/patentFilterAction';
import { clearPatentContent, fetchPatentContent } from 'src/redux/actions/patentContentAction';
import { clearStatisticsForm } from 'src/redux/result/toolbar/statistics/action';
import { alertMessage } from 'src/utils/ModalUtils';
import { SystemMessage } from 'src/apps/applicationMessages';
import { PatentDataTypes } from 'src/types/api/PatentApiTypes';
import { numFormatter } from 'src/utils/Formatter';
import { Spinner } from 'src/components/ui/interactive/Spinner';
import { zToolbar } from 'src/style/theme/Z-Index';
import SearchSuggestion from 'src/components/common/search-bar/SearchSuggestion';
import { GaSearchCategory, pushGaSiteSearchEvent } from 'src/utils/GoogleAnalytics';
import ClickOutside from 'react-onclickout';
import InfiniteScroll from 'src/components/ui/interactive/InfiniteScroll';
import { isNotEmpty } from 'src/utils/TextUtils';

const Container = styled.div<{ isPreviewMode: boolean }>`
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  ${props =>
    props.isPreviewMode
      ? css`
          padding: 16px 140px 0 16px;
        `
      : css`
          padding: 16px 140px 0 80px;
        `};
  @media print {
    display: none;
  }
`;

const Content = styled.div<{ isPreviewMode: boolean }>`
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  ${props =>
    props.isPreviewMode
      ? css`
          width: 55%;
        `
      : css`
          width: 100%;
        `};
`;

const HeaderTop = styled.div`
  background-image: linear-gradient(95deg, #2ec3c2, ${blue3});
  border-radius: 4px 4px 0 0;
  padding: 0px 32px;
  display: flex;
  justify-content: space-between;

  /* 為了產業類別下拉選單不被 LeftMenu 中的 SearchBar 蓋住，所以 HeaderTop 改為 relative。
     且 HeaderTop 不要蓋住右側 Toolbar，因此使用相同 z-Index。 */
  position: relative;
  z-index: ${zToolbar};
`;

const HeaderLeft = styled.div<{ isPreviewMode: boolean; isHeaderCollapse: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  ${props =>
    props.isHeaderCollapse
      ? css`
          padding: 8px 0;
          flex-wrap: nowrap;
        `
      : css`
          padding-top: 16px;
          flex-wrap: wrap;
        `};
  form {
    width: 100%;
  }
`;

const HeaderTitle = styled.div<{ isHeaderCollapse: boolean }>`
  color: ${white};
  font-size: 20px;
  font-weight: 600;
  min-width: 80px;
  ${props =>
    props.isHeaderCollapse
      ? css`
          margin-right: 16px;
        `
      : css`
          margin-bottom: 4px;
        `};
`;

const SearchBar = styled.div<{ isHeaderCollapse: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 4px;
  backdrop-filter: blur(8px);
  box-shadow: 0 4px 16px -4px rgba(0, 0, 0, 0.3);
  background-color: rgba(255, 255, 255, 0.9);

  ${props =>
    props.isHeaderCollapse
      ? css`
          margin-bottom: 0;
          input {
            padding: 10px 40px 10px 16px;
          }
        `
      : css`
          margin-bottom: -25px;
          input {
            padding: 20px 40px 20px 16px;
          }
        `};
`;

const InputContainer = styled.div`
  position: relative;
  flex: 1;
  input {
    width: 100%;
    border-radius: 0 4px 4px 0;
    border: none;
    background-color: ${whitergba(0.3)};
    color: ${iceblue1};

    :focus {
      outline: none;
    }
  }
`;

const SearchBarRight = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 16px;
`;

const SearchIcon = styled(FaSearch)`
  margin-left: 16px;
  font-size: 20px;
  color: ${blue2};
  :hover {
    cursor: pointer;
  }
`;

const customStyleDropdownButton = css`
  width: 120px;
  background-color: ${blue5rgba(0.15)};
  border-radius: 4px 0 0 4px;
  border: 0;
  padding: 20px 16px;
`;

const HeaderRight = styled.div<{ isPreviewMode: boolean; isHeaderCollapse: boolean }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  ${props =>
    props.isHeaderCollapse
      ? css`
          padding: 5px 24px;
        `
      : css`
          padding: 16px 24px;
        `};
  ${props =>
    props.isPreviewMode &&
    css`
      padding-left: 0;
      padding-right: 0;
    `}
`;

const ViewSwitchBox = styled.div`
  display: flex;
  align-items: center;
  margin-left: 16px;
`;

const ViewModeTooltip = styled(Tooltip)<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  margin: 0 8px;
  svg {
    color: ${props => whitergba(props.isActive ? 1 : 0.6)};
  }
  :hover {
    cursor: pointer;
  }
`;

const PriviewSwitchText = styled.span`
  color: ${whitergba(0.8)};
  min-width: 60px;
  margin-right: 8px;
`;

const HeaderBottom = styled.div<{ isPreviewMode: boolean; isHeaderCollapse: boolean }>`
  display: flex;
  justify-content: space-between;
  background-color: #edf6f7;

  ${props =>
    props.isPreviewMode
      ? css`
          padding: 0 32px;
        `
      : css`
          /* padding-left = LeftMenu margin-left + LeftMenu width + ResultBlock padding-left  */
          padding: 0 32px 0 calc(32px + 248px + 32px);
        `};
  ${props =>
    props.isHeaderCollapse
      ? css`
          align-items: center;
        `
      : css`
          align-items: flex-end;
        `}
`;

const SearchCostBox = styled.div<{ isHeaderCollapse: boolean }>`
  display: flex;
  font-size: 13px;
  color: ${blue2rgba(0.6)};

  ${props =>
    props.isHeaderCollapse
      ? css`
          align-items: center;
        `
      : css`
          align-items: flex-end;
          margin-bottom: 8px;
        `};
`;

const Body = styled.div`
  display: flex;
  align-items: flex-start;
  /* (100vh - Search Header height - SearchLayout padding-top - Main Header height) */
  min-height: calc(100vh - 150px - 16px - 62px);
`;

const scrollbarStyle = css`
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    box-shadow: inset 0 0 8px 8px #b5e4ec;
    border: solid 2px transparent;
  }
`;

const ResultBlock = styled(InfiniteScroll)<{ isPreviewMode: boolean; isHeaderCollapse: boolean }>`
  ${props =>
    props.isPreviewMode
      ? css`
          width: 100%;
          overflow: auto;
          ${scrollbarStyle}
          ${props =>
            props.isHeaderCollapse
              ? css`
                  /* (100vh - Search Header height - SearchLayout padding-top - Main Header height) */
                  height: calc(100vh - 100px - 16px - 62px);
                `
              : css`
                  /* (100vh - Search Header height - SearchLayout padding-top - Main Header height) */
                  height: calc(100vh - 150px - 16px - 62px);
                `}
        `
      : css`
          /* (100% - LeftMenu width) - LeftMenu margin-left */
          width: calc(100% - 248px - 32px);
        `};
`;

const NoResult = styled.div`
  margin: 16px 32px;
  padding: 16px 32px;
  background-color: ${blue8};

  div {
    color: ${blue2};
    font-weight: 600;
    margin-bottom: 8px;
  }
  span {
    color: ${iceblue3};
  }
`;

// tag 相關 css
const CancelIcon = styled.div`
  padding: 4px;
  padding-top: 5px; /** beacuse FaTimes is not actually at the center */
  border-radius: 50%;
  :hover {
    cursor: pointer;
    color: ${blue3};
    background-color: ${blue7};
  }

  svg {
    display: block;
    margin: auto;
    font-size: 15px;
  }
`;

const TagName = styled.span`
  margin-right: 10px;
  max-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Tag = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 4px;
  padding: 8px 12px 8px 16px;
  border-radius: 4px;
  background-color: #035a76;
  color: ${ultrawhite};
  :hover {
    cursor: pointer;
  }

  &:nth-of-type(1) {
    box-shadow: -32px 0px 32px 0px ${ultrawhitergba(0.9)};
  }
`;
const Subheaders = styled.div`
  height: 60px;
  padding: 0px 32px;
  display: flex;
  align-items: center;
  color: #979ea1;
  background-color: #edf6f7;
  border-bottom: 1px solid ${iceblue8};
`;
const CountryTabs = styled.div`
  width: calc(85% - 98px);
  height: 100%;
  display: flex;
  align-items: flex-end;
  padding-bottom: 16px;
`;
const CountryTab = styled.div<{ active: boolean }>`
  position: relative;
  width: 93px;
  flex-shrink: 0;
  margin-right: 24px;
  font-size: 20px;
  text-align: center;
  color: ${props => (props.active ? '#424242' : '#828f92')};
  font-weight: ${props => props.active && 'bold'};
  :before {
    content: '';
    float: left;
    position: absolute;
    left: -10%;
    width: 120%;
    background: transparent;
    border-radius: 10px 10px 0 0;
    box-shadow: 0 42px 0 0 ${props => (props.active ? '#077790' : 'transparent')};
    height: 3px;
  }
  :hover {
    cursor: pointer;
  }
`;

export interface IAdvancedKeyword {
  keyCode: string;
  keyword: string;
}
interface IProps extends RouteComponentProps {
  composeBasicRequest: (basicRequest: IBasicRequest) => void;
  navigateToNextPage: () => void;
  selectDateRange: (dateRange: string) => void;
  changeSortOrder: (sortByDate: string) => void;
  fetchPatentResult: () => void;
  fetchFacets: () => void;
  searchRequest: ISearchRequest;
  isUuidListSearch: boolean;
  highlightWords: string[];
  paginatedData: IPaginatedData<IGetPatentTypes>;
  isLoading: boolean;
  isNextPageLoading: boolean;
  fetchBilingualTerms: (request: IBilingualTermsRequest) => void;
  isPatentDataLoading: boolean;
  patentData: PatentDataTypes;
  fetchPatentContent: (uuid: string) => void;
  clearPatentContent: () => void;
  nextPageErrMsg?: string;
  filterTag?: IFilterTag;
  isStatisticsFormNotEmpty: boolean;
  clearStatisticsForm: () => void;
  appendAdvancedKeywordCountry: (keyCode: string, keyword: string) => void;
}

interface IState {
  isPreviewMode: boolean;
  isHeaderCollapse: boolean;
  isGridMode: boolean;
  checkedIndustrialClass: Array<IIndustrialClassType>;
  selectedItem: ISelectedItem;
  queryString: string;
  isSuggestionShow: boolean;
  leftMenuActiveTab: number;
  activeCountry: string;
  ptab?: string;
  expert?: { id: number; name: string };
  advanceKeywords?: Array<IAdvancedKeyword>;
}

function getActiveCountry(props: IProps) {
  let result: string;
  if (props.searchRequest.ptab === '1') {
    result = 'US';
  } else {
    result = props.searchRequest.advanceKeywords
      ? props.searchRequest.advanceKeywords[0].keyword
      : '';
  }
  return result;
}

/** 專利搜尋結果 */
class PatentSearchResult extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      isPreviewMode: false,
      isHeaderCollapse: false,
      isGridMode: false,
      // 下拉選單預設選取值
      checkedIndustrialClass: toDropdownItem(props.searchRequest.industrialClassIds),
      selectedItem: {
        dateRange: { name: '不限時間', value: '' },
        sortByDate: { name: '排序', value: '' },
      },
      queryString: !props.isUuidListSearch ? props.searchRequest.queryString : '',
      isSuggestionShow: false,
      leftMenuActiveTab: 0,
      activeCountry: getActiveCountry(props),
    };
  }

  inputRef = React.createRef<HTMLInputElement>();

  expert = this.props.searchRequest.expert;

  // Call API 取得搜尋結果
  fetchData() {
    const { searchRequest, fetchPatentResult, fetchBilingualTerms, fetchFacets } = this.props;

    fetchPatentResult();

    // 篩選統計資料僅需要第一頁時查詢
    if (searchRequest.pageNo === 1) {
      fetchFacets();
      // 未進行雙語檢索時才查雙語詞彙統計欄位
      !searchRequest.bilingualTerm &&
        fetchBilingualTerms({
          keyword: searchRequest.queryString,
          // industrialIds: searchRequest.industrialIds,
          // topicId: searchRequest.topicId,
          // topicSearchType: searchRequest.topicSearchType,
          dateRange: searchRequest.dateRange,
          techQuery: searchRequest.techQuery,
          funcQuery: searchRequest.funcQuery,
          advanceKeywords: searchRequest.advanceKeywords,
        });
    }
  }

  componentDidMount() {
    // 當頁面是經由上一頁/下一頁跳轉而來時不查詢資料
    // 但重新整理或由URL直接進入頁面時，redux state處於初始狀態(paginatedData.pageNo === 0)，須查詢資料。
    if (this.props.history.action !== 'POP' || this.props.paginatedData.pageNo === 0) {
      this.fetchData();
      this.pushGaEvent();
    }
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    // 查詢request有改變時才call API。
    // 這裡的改變不是指值的改變，而是指前後兩個searchRequest是不同物件。
    // 當searchRequest被取代為新的物件時要查詢，而component其他props或state改變則不查詢。
    if (prevProps.searchRequest !== this.props.searchRequest) {
      this.fetchData();

      // 回復產業類別選單選項
      this.resetSelectedIndustry();
      // 更新輸入框中的關鍵字
      this.setState({
        queryString: !this.props.isUuidListSearch ? this.props.searchRequest.queryString : '',
      });
      // 查詢檢索式有變動時(聚類關鍵字疊加)，回復右側下拉選單
      // TODO: 歸納右側下拉選單需要被重設的條件，目前做法可能造成resetSelectItem()被重複呼叫2~3次
      if (prevProps.searchRequest.queryString !== this.props.searchRequest.queryString) {
        this.resetRightSelectedItem();
      }

      // 當雙欄瀏覽開啟且重新查詢時，
      if (this.state.isPreviewMode && this.props.searchRequest.pageNo === 1) {
        // 回復Header狀態
        this.setState({ isHeaderCollapse: false });
        // 將右側資料清空
        this.props.clearPatentContent();
      }

      // 清除統計分析表單輸入資料
      this.props.isStatisticsFormNotEmpty && this.props.clearStatisticsForm();

      // GA
      this.pushGaEvent();
    }

    // 技術功效再檢索或取消時回復右側下拉選單
    if (prevProps.filterTag !== this.props.filterTag) {
      this.resetRightSelectedItem();
    }
  }

  /** 重新設定下拉選單的selectedItem與searchRequest一致 */
  resetSelectedIndustry = () => {
    this.setState((prevState, prevProps) => ({
      ...prevState,
      checkedIndustrialClass: toDropdownItem(prevProps.searchRequest.industrialClassIds),
      selectedItem: { ...prevState.selectedItem },
    }));
  };

  /** 排序、時間範圍下拉選單回復為初始狀態 */
  resetRightSelectedItem = () => {
    this.setState(prevState => ({
      ...prevState,
      selectedItem: {
        ...prevState.selectedItem,
        dateRange: { name: '不限時間', value: '' },
        sortByDate: { name: '排序', value: '' },
      },
    }));
  };

  handleKeywordChange = (e: any) => {
    this.setState({
      queryString: e.target.value,
    });
  };

  handleSuggestionClick = (value?: string) => {
    if (value && value !== '') {
      this.setState({
        queryString: value,
      });
    }
    this.inputRef.current && this.inputRef.current.focus();
  };

  handleSuggestionClose = () => {
    this.setState({ isSuggestionShow: false });
  };

  // 按下ptab tag cancel button 將props中的ptab值改變後重新搜索
  handlePtabCancelClick = (e: any) => {
    this.props.searchRequest.ptab = '';
    this.handleSubmit(e);
  };

  // 按下 expert tag cancel button 將 state 中的 expert 值清除後重新搜索
  handleExpertCancelClick = (e: any) => {
    this.expert = undefined;
    this.handleSubmit(e);
  };

  // 按下國別頁籤後依照國別重新搜索
  handleCountryTabClick = (countryTab: string) => {
    this.setState({ activeCountry: countryTab });
    this.props.appendAdvancedKeywordCountry('PO', countryTab);
  };

  /** 查詢送出 */
  handleSubmit = (e: any) => {
    e.preventDefault();

    // 非讀取資料時才查詢
    if (!this.props.isLoading) {
      const selectedIndustry = this.state.checkedIndustrialClass.map(item => item.id.toString());
      const queryString = this.state.queryString;
      const ptab = this.props.searchRequest.ptab;
      const activeCountry = this.state.activeCountry;
      if (selectedIndustry.length === 0 && queryString === '') {
        alertMessage(SystemMessage.SEARCH_KEYWORD_EMPTY);
      }
      // 若ptab值為1 則在搜索式中加入ptab檢索式
      else if (ptab === '1') {
        this.props.composeBasicRequest({
          queryString: queryString,
          ptab: ptab,
          advanceKeywords: [{ keyCode: 'PO', keyword: 'US' }],
        });
      } else {
        this.props.composeBasicRequest({
          industrialClassIds: selectedIndustry,
          queryString: queryString,
          expert: this.expert,
          advanceKeywords: [{ keyCode: 'PO', keyword: activeCountry }],
        });

        this.resetRightSelectedItem();
      }
    }
  };

  /** 雙欄檢視切換 */
  handlePreviewModeChange = () => {
    if (this.state.isPreviewMode) {
      // 關閉雙欄瀏覽時重設isHeaderCollapse
      this.setState({
        isHeaderCollapse: false,
      });
    } else {
      // 打開雙欄瀏覽時清除可能存在的舊資料
      this.props.clearPatentContent();
    }

    this.setState({
      isPreviewMode: !this.state.isPreviewMode,
    });
  };

  handleLeftMenuTabChange = (idx: number) => {
    this.setState({
      leftMenuActiveTab: idx,
    });
  };

  /** 專利搜尋結果捲軸偵測 */
  handleResultBlockScroll = (resultBlockRef: React.RefObject<HTMLDivElement>) => {
    if (resultBlockRef.current) {
      const scrollTop = resultBlockRef.current.scrollTop;
      if (scrollTop > 0 && !this.state.isHeaderCollapse) {
        this.setState({ isHeaderCollapse: true });
      } else if (scrollTop === 0) {
        this.setState({ isHeaderCollapse: false });
      }
    }
  };

  /** 單筆專利結果是否被預覽 */
  isPatentPreivewing = (patentUuid: string): boolean => {
    const patentData = this.props.patentData;
    return this.state.isPreviewMode && !!patentData.content && patentUuid === patentData.content.id;
  };

  /** 送出 GA 專利搜尋事件 */
  pushGaEvent = () => {
    // 組合檢索關鍵字
    const { queryString, bilingualTerm, advanceKeywords } = this.props.searchRequest;
    let gaQueryString = queryString.trim();

    bilingualTerm && (gaQueryString += ' AND ' + bilingualTerm);
    advanceKeywords &&
      advanceKeywords.forEach(item => {
        gaQueryString += (gaQueryString.length > 0 ? '&' : '') + item.keyCode + '=' + item.keyword;
      });

    // 有關鍵字才將檢索紀錄推到dataLayer
    !this.props.isUuidListSearch &&
      isNotEmpty(gaQueryString) &&
      pushGaSiteSearchEvent(GaSearchCategory.PATENT, gaQueryString);
  };

  render() {
    const {
      isPreviewMode,
      isHeaderCollapse,
      isGridMode,
      selectedItem,
      queryString,
      isSuggestionShow,
      leftMenuActiveTab,
      activeCountry,
    } = this.state;

    const {
      isUuidListSearch,
      highlightWords,
      paginatedData,
      isLoading,
      isPatentDataLoading,
      patentData,
      isNextPageLoading,
      nextPageErrMsg,
    } = this.props;

    const ptab = this.props.searchRequest.ptab;
    let countryList: string[];

    // 若為美國 PTAB 資料檢索，則國別頁籤只顯示 US
    if (ptab === '1') {
      countryList = ['US'];
    } else {
      countryList = countries;
    }

    const countryTabs = (
      <Subheaders>
        <CountryTabs>
          {countryList.map(tab => (
            <CountryTab
              key={tab}
              active={activeCountry === tab}
              onClick={() => this.handleCountryTabClick(tab)}
            >
              {tab}
            </CountryTab>
          ))}
        </CountryTabs>
      </Subheaders>
    );

    const tabs = [{ title: '篩選', panel: <Filter /> }, { title: '聚類', panel: <Cluster /> }];

    return (
      <Container isPreviewMode={isPreviewMode}>
        <Content isPreviewMode={isPreviewMode}>
          <HeaderTop>
            <HeaderLeft isPreviewMode={isPreviewMode} isHeaderCollapse={isHeaderCollapse}>
              <HeaderTitle isHeaderCollapse={isHeaderCollapse}>搜尋結果</HeaderTitle>
              <form onSubmit={this.handleSubmit}>
                <SearchBar isHeaderCollapse={isHeaderCollapse}>
                  <IndustrialClassDropdown
                    checkedItems={this.state.checkedIndustrialClass}
                    setCheckedItems={item => this.setState({ checkedIndustrialClass: item })}
                    customButtonStyle={customStyleDropdownButton}
                  />
                  <ClickOutside onClickOut={() => this.setState({ isSuggestionShow: false })}>
                    <InputContainer>
                      <input
                        type="text"
                        value={queryString}
                        onChange={this.handleKeywordChange}
                        ref={this.inputRef}
                        onFocus={() => this.setState({ isSuggestionShow: true })}
                      />
                      <SearchSuggestion
                        cssTop="68px"
                        isShow={isSuggestionShow}
                        industryIds={this.state.checkedIndustrialClass.map(item => item.id)}
                        inputText={queryString}
                        handleSuggestionClick={this.handleSuggestionClick}
                        handleSuggestionClose={this.handleSuggestionClose}
                      />
                    </InputContainer>
                  </ClickOutside>

                  <SearchBarRight>
                    {/* ptab tag props中ptab值為1時才顯示 */}
                    {ptab === '1' && (
                      <Tag>
                        <TagName>具訴訟紀錄之專利</TagName>
                        <CancelIcon onClick={this.handlePtabCancelClick}>
                          <FaTimes />
                        </CancelIcon>
                      </Tag>
                    )}
                    {this.expert && (
                      <Tag>
                        <TagName>{this.expert.name}</TagName>
                        <CancelIcon onClick={this.handleExpertCancelClick}>
                          <FaTimes />
                        </CancelIcon>
                      </Tag>
                    )}
                    {!isPreviewMode && <MatrixFilterTag />}
                    <SearchIcon onClick={this.handleSubmit} />
                  </SearchBarRight>
                </SearchBar>
              </form>
            </HeaderLeft>
            <HeaderRight isPreviewMode={isPreviewMode} isHeaderCollapse={isHeaderCollapse}>
              <ViewSwitchBox>
                <ViewModeTooltip
                  placement="top"
                  overlay="圖片模式"
                  isActive={isGridMode}
                  onClick={() => this.setState({ isGridMode: true })}
                >
                  <FaThLarge />
                </ViewModeTooltip>
                <ViewModeTooltip
                  placement="top"
                  overlay="列表模式"
                  isActive={!isGridMode}
                  onClick={() => this.setState({ isGridMode: false })}
                >
                  <FaThList />
                </ViewModeTooltip>
              </ViewSwitchBox>
              <ViewSwitchBox>
                <PriviewSwitchText>雙欄瀏覽</PriviewSwitchText>
                <SlideToggle checked={isPreviewMode} change={this.handlePreviewModeChange} />
              </ViewSwitchBox>
            </HeaderRight>
          </HeaderTop>
          <HeaderBottom isPreviewMode={isPreviewMode} isHeaderCollapse={isHeaderCollapse}>
            <SearchCostBox isHeaderCollapse={isHeaderCollapse}>
              {!isLoading &&
                `共 ${numFormatter(paginatedData.totalCount)} 件搜尋結果 花費 ${
                  paginatedData.costTime
                } 秒`}
            </SearchCostBox>
            <HeaderRight isPreviewMode={isPreviewMode} isHeaderCollapse={isHeaderCollapse}>
              <FilterDropdown
                items={dateRangeItems}
                activeItem={selectedItem.dateRange}
                handleOnclick={item => {
                  this.props.selectDateRange(item.value);
                  this.setState(prevState => {
                    return {
                      ...prevState,
                      selectedItem: {
                        ...prevState.selectedItem,
                        dateRange: item,
                      },
                    };
                  });
                }}
                customStyleSvgSize={16}
              />
              <FilterDropdown
                items={sortTypeItems}
                activeItem={selectedItem.sortByDate}
                handleOnclick={item => {
                  this.props.changeSortOrder(item.value);
                  this.setState(prevState => {
                    return {
                      ...prevState,
                      selectedItem: {
                        ...prevState.selectedItem,
                        sortByDate: item,
                      },
                    };
                  });
                }}
                customStyleSvg={<FaSortAmountDown size={16} />}
              />
            </HeaderRight>
          </HeaderBottom>

          <Body>
            {isLoading ? (
              <Spinner width="150px" margin="150px auto" />
            ) : (
              <>
                <LeftMenu
                  isShow={!isPreviewMode}
                  activeTab={leftMenuActiveTab}
                  setActiveTab={this.handleLeftMenuTabChange}
                  tabs={tabs}
                />
                <ResultBlock
                  isPreviewMode={isPreviewMode}
                  isHeaderCollapse={isHeaderCollapse}
                  // InfiniteScroll props
                  customScroll={this.handleResultBlockScroll}
                  isNextPageLoading={isNextPageLoading}
                  isNextPageError={!!nextPageErrMsg}
                  errorMessage={nextPageErrMsg}
                  handleNextPageLoad={this.props.navigateToNextPage}
                  handleReload={this.props.fetchPatentResult}
                  useWindow={!isPreviewMode}
                  totalCount={paginatedData.totalCount}
                  currentPage={paginatedData.pageNo}
                  totalPages={paginatedData.totalPages}
                >
                  {/* 第1頁、0筆資料: 資料有回來，沒找到任何符合的結果 
                      第1頁、-1筆資料: 查詢造成solr出錯，所以查無結果 */}
                  {paginatedData.pageNo === 1 && paginatedData.totalCount <= 0 ? (
                    <>
                      {countryTabs}
                      <NoResult>
                        <div>查無搜尋結果</div>
                        <span>請嘗試變更產業類別或修改關鍵字。</span>
                      </NoResult>
                    </>
                  ) : (
                    <>
                      {isGridMode ? (
                        <>
                          {countryTabs}
                          <WaterfallGrid
                            columns={2}
                            elements={paginatedData.data.map(item => (
                              <ResultGridItem
                                key={item.id}
                                isPreviewMode={isPreviewMode}
                                isPreviewing={this.isPatentPreivewing(item.id)}
                                patent={item}
                              />
                            ))}
                          />
                        </>
                      ) : (
                        <>
                          {countryTabs}
                          {paginatedData.data.map(item => (
                            <ResultListItem
                              key={item.id}
                              isPreviewMode={isPreviewMode}
                              isPreviewing={this.isPatentPreivewing(item.id)}
                              patent={item}
                              highlightWords={highlightWords}
                              ptab={this.props.searchRequest.ptab}
                            />
                          ))}
                        </>
                      )}
                    </>
                  )}
                </ResultBlock>
              </>
            )}
          </Body>
        </Content>
        {isPreviewMode && (
          <ResultPreviewPane
            isLoading={isPatentDataLoading}
            patentData={patentData}
            onPaneClose={() => this.setState({ isPreviewMode: false, isHeaderCollapse: false })}
          />
        )}
        {!isUuidListSearch && <RightToolbar paginatedData={paginatedData} />}
      </Container>
    );
  }
}

const mapStateToProps = (state: ReduxAppState) => {
  return {
    searchRequest: state.patentSearchReducer.request,
    isUuidListSearch: state.patentSearchReducer.isUuidListSearch,
    highlightWords: state.patentSearchReducer.highlightWords,
    paginatedData: state.patentResultReducer.paginatedData,
    isLoading:
      state.patentSearchReducer.request.pageNo === 1 && state.patentResultReducer.isLoading,
    isNextPageLoading:
      state.patentSearchReducer.request.pageNo > 1 && state.patentResultReducer.isLoading,
    isPatentDataLoading: state.patentContentReducer.isLoading,
    patentData: state.patentContentReducer.patentData,
    nextPageErrMsg: state.patentResultReducer.nextPageErrMsg,
    filterTag: state.patentSearchReducer.filterTag,
    isStatisticsFormNotEmpty:
      state.resultToolbarStatisticsReducer.chartTitle !== '' ||
      state.resultToolbarStatisticsReducer.selectedItem.facetField.value !== '' ||
      state.resultToolbarStatisticsReducer.selectedChartType !== '',
  };
};

const mapDispatchToProps = {
  composeBasicRequest,
  navigateToNextPage,
  selectDateRange,
  changeSortOrder,
  fetchPatentResult,
  fetchBilingualTerms,
  fetchFacets,
  fetchPatentContent,
  clearPatentContent,
  clearStatisticsForm,
  appendAdvancedKeywordCountry,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PatentSearchResult);
