import axios from 'axios';
import { Api } from 'src/api/helpers/apiBase';
import { IDocdbData, IGetDocdbData } from 'src/types/DocdbDataTypes';

export const DocdbService = () => {
  const getDocdbContent = async (docdbId: string) => {
    const response = await Api().get(`docdb/${docdbId}`);
    return response.data;
  };
  // 查詢IPC名稱
  const getIpc = async (patentData: IGetDocdbData) => {
    let ipcName: string[] = [];
    let code: string[] = [];
    if (patentData.bibliographic.classificationIpc) {
      //取得代碼
      const ipc = patentData.bibliographic.classificationIpc;
      ipc.mainClassification && code.push(ipc.mainClassification);
      ipc.furtherClassification && (code = code.concat(ipc.furtherClassification));
      //取得名稱
      let nameArr = code.map(async item => {
        const res = await Api().get('ipc/description?code=' + item);
        return item.concat(' ' + res.data.replace(new RegExp('‧', 'g'), ''));
      });
      ipcName = await axios.all(nameArr);
    }
    return ipcName;
  };

  return {
    //用UUID查詢專利資料與IPC分類
    getDocdbData: async (docdbId: string) => {
      const content = await getDocdbContent(docdbId);
      const ipc = await getIpc(content);
      const patentData: IDocdbData = {
        content: content,
        ipc: ipc,
      };
      return patentData;
    },
  };
};
