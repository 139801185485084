import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';

const ExpertBox = styled.span`
  color: #06a4e8;
  :hover {
    cursor: pointer;
  }

  /* for HighlightText */
  span,
  mark {
    color: #06a4e8;
  }
`;

interface IProps {
  country?: string;
  addressbook: { name?: string[]; lang: string[] };
  highlightText: (text: string) => (string | JSX.Element)[];
}

/** 專利發明人姓名。 */
const PatentInventorName: React.FC<IProps> = ({ country, addressbook, highlightText }) => {
  const [expertIds, setExpertIds] = useState<Array<number>>([]);

  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await Api().post('expert/ids', addressbook.name);
        setExpertIds(res.data);
      } catch (error) {
        errorHandler(error);
      }
    };

    addressbook.name && fetch();
  }, [addressbook.name]);

  return (
    <>
      {addressbook.name &&
        addressbook.name.map((item, idx) => {
          const lang = addressbook.lang;
          //國別為TW時，同一人的中文名和英文名不換行
          const newLine =
            country !== 'TW' ||
            lang[idx].toLowerCase() !== 'tw' ||
            lang[idx] === lang[idx + 1] ||
            idx + 1 === lang.length;

          const expertId = expertIds[idx];
          const baseItem = (
            <>
              {highlightText(item)}
              {newLine ? <br /> : '\u2003'}
            </>
          );

          return expertId ? (
            <Link to={`/expert/${expertId}`} key={idx}>
              <ExpertBox>{baseItem}</ExpertBox>
            </Link>
          ) : (
            <span key={idx}>{baseItem}</span>
          );
        })}
    </>
  );
};

export default PatentInventorName;
