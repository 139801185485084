import React, { useState } from 'react';
import { AxiosResponse } from 'axios';
import { Formik, Form, FormikProps } from 'formik';
import { FaCalendarAlt, FaDownload } from 'react-icons/fa';

import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';

import { formatQueryDate } from 'src/utils/TextUtils';

import { IDownloadPeriodForm } from 'src/types/form/OpendataFormTypes';
import { LinkOut } from 'src/utils/LinkOut';
import { numFormatter, dateFormatter } from 'src/utils/Formatter';
import {
  nonEmptySourceValidator,
  startDateValidator,
  endDateValidator,
} from 'src/apps/validation/formValidator';

import { Button } from 'src/style/theme/Common';
import { Spinner } from 'src/components/ui/interactive/Spinner';
import {
  ErrorMsg,
  FormContainer,
  FieldStyle,
  DatePickerStyle,
  IconLabelStyle,
  SymbolStyle,
  ItemContainer,
  ItemInput,
  DownloadSearchResultStyle,
  DownloadTrStyle,
  DownloadThStyle,
  DownloadTdStyle,
  DownloadButton,
  DownloadItemName,
} from 'src/style/OpendataStyle';

interface IResult {
  country: string;
  publicationDate: string;
  publicationNumber: string;
  periodDate: string;
  periodNumber: string;
  rawPathType: string;
  size: number;
  link: string;
}
interface IProps {
  countryList: Array<{ name: string; value: string }>;
}

const formInitValue: IDownloadPeriodForm = {
  country: '',
  beginPubDate: null,
  endPubDate: null,
};

const PeriodDownloadPane: React.FC<IProps> = ({ countryList }) => {
  const [isStartFocused, setIsStartFocused] = useState(false);
  const [isEndFocused, setIsEndFocused] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState<Array<IResult>>([]);

  const handleSubmit = async (values: IDownloadPeriodForm) => {
    try {
      setIsLoading(true);
      const res = (await Api().get(
        'patent/download/period?country=' +
          values.country +
          '&beginPubDate=' +
          (values.beginPubDate && formatQueryDate(values.beginPubDate)) +
          '&endPubDate=' +
          (values.endPubDate && formatQueryDate(values.endPubDate)),
      )) as AxiosResponse<Array<IResult>>;
      setResult(res.data);
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
      setIsSubmitted(true);
    }
  };

  return (
    <>
      <FormContainer>
        檔案類型：ZIP(內含JSON、TIF、JPG)
        <br />
        編碼格式：UTF-8
        <br />
        資料內容說明：
        <br />
        <ul>
          <li>
            索引JSON檔。例如：index.json。內容包含：總筆數、公開/公告號、公開/公告日、ZIP檔名。
          </li>
          <li>多個單一案件ZIP檔。例如： US20170342267.zip、US20170342268.zip等。</li>
        </ul>
        <br />
        <Formik
          initialValues={formInitValue}
          onSubmit={values => handleSubmit(values)}
          render={(formikBag: FormikProps<IDownloadPeriodForm>) => (
            <Form>
              <ItemContainer>
                <span>資料來源</span>
                <ItemInput>
                  <FieldStyle
                    name="country"
                    component="select"
                    width="70%"
                    validate={nonEmptySourceValidator}
                  >
                    <option value="">請選擇</option>
                    {countryList.map(item => (
                      <option value={item.value}>{item.name}</option>
                    ))}
                  </FieldStyle>
                </ItemInput>
              </ItemContainer>
              {formikBag.errors.country && formikBag.touched.country && (
                <ItemContainer>
                  <span />
                  <ItemInput>
                    <ErrorMsg>{formikBag.errors.country}</ErrorMsg>
                  </ItemInput>
                </ItemContainer>
              )}
              <ItemContainer>
                <span>日期區間</span>
                <ItemInput>
                  <IconLabelStyle width="47%">
                    <DatePickerStyle
                      placeholderText="年-月-日"
                      dateFormat="yyyy-MM-dd"
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      yearDropdownItemNumber={5}
                      selected={formikBag.values.beginPubDate}
                      onBlur={() => setIsStartFocused(true)}
                      onChange={date => formikBag.setFieldValue('beginPubDate', date)}
                    />
                    <FaCalendarAlt color="#424d52" />
                  </IconLabelStyle>
                  <SymbolStyle>～</SymbolStyle>
                  <IconLabelStyle width="47%">
                    <DatePickerStyle
                      placeholderText="年-月-日"
                      dateFormat="yyyy-MM-dd"
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      yearDropdownItemNumber={5}
                      selected={formikBag.values.endPubDate}
                      onBlur={() => setIsEndFocused(true)}
                      onChange={date => formikBag.setFieldValue('endPubDate', date)}
                    />
                    <FaCalendarAlt color="#424d52" />
                  </IconLabelStyle>
                </ItemInput>
              </ItemContainer>
              <ItemContainer>
                {((!formikBag.values.beginPubDate && isStartFocused) ||
                  (!formikBag.values.endPubDate && isEndFocused)) && (
                  <>
                    <span />
                    <ItemInput>
                      <ErrorMsg>
                        {isStartFocused && startDateValidator(formikBag.values.beginPubDate)}
                      </ErrorMsg>
                      <SymbolStyle>&emsp;</SymbolStyle>
                      <ErrorMsg>
                        {isEndFocused && endDateValidator(formikBag.values.endPubDate)}
                      </ErrorMsg>
                    </ItemInput>
                  </>
                )}
              </ItemContainer>
              <ItemContainer>
                <span />
                <ItemInput>
                  <Button
                    type="submit"
                    template="primary"
                    onClick={() => {
                      setIsStartFocused(true);
                      setIsEndFocused(true);
                    }}
                  >
                    查詢
                  </Button>
                  <Button
                    type="button"
                    onClick={async () => {
                      try {
                        const res = await Api().get('patent/download/period/sample');
                        window.open(res.data);
                      } catch (error) {
                        errorHandler(error);
                      }
                    }}
                  >
                    範例檔案下載
                  </Button>
                </ItemInput>
              </ItemContainer>
            </Form>
          )}
        />
      </FormContainer>
      <DownloadSearchResultStyle>
        <DownloadThStyle>
          <DownloadTdStyle width="10%" />
          <DownloadTdStyle width="40%">檔案</DownloadTdStyle>
          <DownloadTdStyle width="20%">公開時間</DownloadTdStyle>
          <DownloadTdStyle width="20%">更新時間</DownloadTdStyle>
          <DownloadTdStyle width="10%">下載</DownloadTdStyle>
        </DownloadThStyle>
        {result.length === 0 ? (
          <>
            {isSubmitted && !isLoading && <p>此搜尋條件範圍內尚無資料，您可嘗試修改查詢條件。</p>}
            {!isSubmitted && !isLoading && <p>尚無檔案，請利用上方的查詢功能</p>}
            {isLoading && <Spinner width="120px" margin="40px auto" />}
          </>
        ) : (
          result.map((item, idx) => (
            <DownloadTrStyle key={item.link}>
              <DownloadTdStyle width="10%">{idx + 1}</DownloadTdStyle>
              <DownloadTdStyle width="40%">
                <DownloadItemName active={item.link !== null}>
                  {`${item.country}${item.publicationDate}${item.periodNumber}${item.rawPathType}`}
                  {item.link && ' (' + numFormatter(item.size, true) + 'MB)'}
                </DownloadItemName>
              </DownloadTdStyle>
              <DownloadTdStyle width="20%">{dateFormatter(item.publicationDate)}</DownloadTdStyle>
              <DownloadTdStyle width="20%">{dateFormatter(item.periodDate)}</DownloadTdStyle>
              <DownloadTdStyle width="10%">
                <LinkOut href={item.link}>
                  <DownloadButton>
                    <FaDownload size="15px" color="#0cafcc" />
                  </DownloadButton>
                </LinkOut>
              </DownloadTdStyle>
            </DownloadTrStyle>
          ))
        )}
      </DownloadSearchResultStyle>
    </>
  );
};

export default PeriodDownloadPane;
