import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';

import { handleRoute } from 'src/routes/routeUtils';
import { Container, Sector, Feature } from 'src/style/SideMenuStyle';

interface ISimpleNav extends RouteComponentProps {
  sector: string;
  features: Array<{
    feature: string;
    path: string;
    /** 特殊情況下使用，讓 SideNavItem 顯示 JSX */
    jsxFeature?: JSX.Element;
  }>;
}

function SimpleSideNav(props: ISimpleNav) {
  const [activeNav, setActiveNav] = useState(props.location.pathname);

  useEffect(() => {
    setActiveNav(props.location.pathname);
  }, [props]);

  return (
    <Container>
      <Sector>{props.sector}</Sector>
      {props.features.map(item => {
        return (
          <Link
            key={item.feature}
            to={props.match.url + item.path}
            onClick={e => e.preventDefault()}
          >
            <Feature
              active={activeNav === props.match.url + item.path}
              onClick={() =>
                handleRoute(props.match.url + item.path, props.location, props.history)
              }
            >
              {item.jsxFeature || item.feature}
            </Feature>
          </Link>
        );
      })}
    </Container>
  );
}

export default SimpleSideNav;
