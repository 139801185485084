import React from 'react';
import styled from 'styled-components';
import Shortcuts from './Shortcuts';
import IpcSearch from './codeSearch/IpcSearch';
import IndustrialClassSearch from './codeSearch/IndustrialClassSearch';

const Pane = styled.div<{ isShow: boolean }>`
  display: ${props => !props.isShow && 'none'};
`;

interface IState {
  activePane: string;
  ipcCodeList?: string[];
}

function withShortcut(
  Search: React.ComponentType<{
    ipcCodeList?: string[];
    handleIpcCodeList?: (code?: string[]) => void;
  }>,
) {
  return class extends React.Component<{}, IState> {
    constructor(props: {}) {
      super(props);
      this.state = {
        activePane: '',
      };
    }

    handleShortcutClick = (activePane: string) => {
      this.setState({
        activePane: activePane,
      });
    };

    handleBackClick = () => {
      this.setState({
        activePane: '',
      });
    };

    handleIpcCodeList = (ipcCodeList?: string[]) => {
      this.setState({ ipcCodeList: ipcCodeList });
    };

    renderSwitch(activePane: string) {
      switch (activePane) {
        case 'IPC 代碼':
          return (
            <IpcSearch
              handleBackClick={this.handleBackClick}
              handleIpcCodeList={this.handleIpcCodeList}
            />
          );
        case '行業代碼':
          return (
            <IndustrialClassSearch
              handleBackClick={this.handleBackClick}
              handleIpcCodeList={this.handleIpcCodeList}
            />
          );
        default:
          return null;
      }
    }

    render() {
      const { activePane } = this.state;
      return (
        <>
          <Pane isShow={activePane === ''}>
            <Search
              ipcCodeList={this.state.ipcCodeList}
              handleIpcCodeList={this.handleIpcCodeList}
            />
          </Pane>
          {this.renderSwitch(activePane)}
          <Shortcuts activePane={activePane} handleShortcutClick={this.handleShortcutClick} />
        </>
      );
    }
  };
}

export default withShortcut;
