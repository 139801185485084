import { ultrawhite } from 'src/style/theme/Color';
import canvg from 'canvg';
import jsPDF from 'jspdf';

export const svgId = 'svgId';

// TODO: 使用 canvg 後 svg background-color 應可不加，但若拿掉反而導致部分text的style失效，原因待查。
const svgCss = `
  @import url('https://fonts.googleapis.com/css?family=Noto+Sans+SC&amp;display=swap');
  svg {
    background-color: ${ultrawhite};
  }
  text,
  tspan {
    font-family: 'Noto Sans SC', 'Microsoft JhengHei', 'Apple LiGothic Medium';
    font-size: 15px;
  }
`;

const svgStyleTag = `<defs><style type="text/css">${svgCss}</style></defs>`;

const serializeSvg = (svgId: string) => {
  let result = '';
  const svg = document.getElementById(svgId);

  if (svg) {
    // SVG css style 不會跟著被下載，所以需要將style寫在<svg>內部
    // 若直接讓style也渲染在瀏覽器上過導致載入速度過慢，因此下載時才將加入style設定。
    // IE 不支援 SVG Element 的 insertAdjacentHTML 方法，所以用字串取代
    // 但 svgCss 中的 字型 url 含有 html 特殊字元 `&` 要轉換成 `&amp;` 才不會導致 svg 載入錯誤
    // (原本使用insertAdjacentHTML，serializeToString會一併作此轉換)
    // TODO: 改用程式的作法轉換html特殊字元
    result = new XMLSerializer().serializeToString(svg).replace(/>/, '>' + svgStyleTag);
  }

  return result;
};

export const saveAsSvg = (fileName: string) => {
  // get svg source.
  let source = serializeSvg(svgId);
  // add name spaces.
  if (!source.match(/^<svg[^>]+xmlns="http:\/\/www\.w3\.org\/2000\/svg"/)) {
    source = source.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"');
  }
  if (!source.match(/^<svg[^>]+"http:\/\/www\.w3\.org\/1999\/xlink"/)) {
    source = source.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
  }
  // add xml declaration
  source = '<?xml version="1.0" standalone="no"?>\r\n' + source;
  // convert svg source to URI data scheme.
  const url = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(source);

  // IE
  if (window.navigator.msSaveOrOpenBlob) {
    const svgBlob = new Blob([source], { type: 'image/svg+xml;charset=utf-8' });
    window.navigator.msSaveBlob(svgBlob, fileName + '.svg');

    // Chrome、FireFox
  } else {
    download(fileName + '.svg', url);
  }
};

export const saveAsPng = (fileName: string) => {
  saveAsImage(fileName + '.png', 'image/png');
};

export const saveAsJpeg = (fileName: string) => {
  saveAsImage(fileName + '.jpeg', 'image/jpeg');
};

const saveAsImage = (fileName: string, mimeType: string) => {
  const canvas = getCanvas();
  const dataUrl = canvas.toDataURL(mimeType);

  // IE
  if (window.navigator.msSaveOrOpenBlob) {
    // <a download="xxx"/> 的下載方法在IE中不適用，只能用blob物件進行下載。
    // 但canvas.toBlob方法IE不適用，IE提供的 canvas.msToBlob 又只能轉PNG格式，所以找了將dataUrl轉成Blob的方法
    const blob = dataURItoBlob(dataUrl);
    window.navigator.msSaveBlob(blob, fileName);

    // Chrome、FireFox
  } else {
    download(fileName, dataUrl);
  }
};

const getCanvas = () => {
  const svgString = serializeSvg(svgId);

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  // canvas的toDataUrl、toBlob等方法在IE中會有Security Error，
  // 所以使用套件 canvg 將 svg 渲染至 canvas 上。
  canvg(canvas, svgString);

  // canvg 無法抓 svg 的 background-color，需自行在canvas上畫背景，不然下載下來的圖背景會是透明或黑色
  if (ctx) {
    // set to draw behind current content
    ctx.globalCompositeOperation = 'destination-over';
    // set background color
    ctx.fillStyle = ultrawhite;
    // draw background / rect on entire canvas
    ctx.fillRect(0, 0, canvas.width, canvas.height);
  }

  return canvas;
};

export const saveAsPdf = (fileName: string) => {
  const canvas = getCanvas();
  const dataUrl = canvas.toDataURL('image/jpeg');

  const doc = new jsPDF();
  const width = doc.internal.pageSize.getWidth();
  const height = (width / canvas.width) * canvas.height;

  doc.addImage(dataUrl, 'JPEG', 0, 0, width, height);
  doc.save(fileName + '.pdf');
};

const download = (fileName: string, url: string) => {
  const el = window.document.createElement('a');
  el.href = url;
  el.download = fileName;
  document.body.appendChild(el);
  el.click();
  document.body.removeChild(el);
};

/**
 * @description 參考網路上作法
 * - https://stackoverflow.com/questions/12168909/blob-from-dataurl
 */
function dataURItoBlob(dataURI: string) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataURI.split(',')[1]);
  // separate out the mime component
  const mimeString = dataURI
    .split(',')[0]
    .split(':')[1]
    .split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);
  // create a view into the buffer
  const ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  const blob = new Blob([ia], { type: mimeString });
  return blob;
}
