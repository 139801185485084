import React from 'react';
import styled from 'styled-components';
import Accordion from './accordion/Accordion';
import BilingualAccordion from './accordion/BilingualAccordion';
import IpcAccordion from './accordion/IpcAccordion';
import NoResult from './accordion/common/NoResult';
import { connect } from 'react-redux';
import { ReduxAppState } from 'src/redux/reducers';
import { IAdvancedKeyword, IPatentFacet } from 'src/types/PatentSearchTypes';
import { Spinner } from 'src/components/ui/interactive/Spinner';
import { patentFacetText } from 'src/constants/PatentSearchConstants';
import DedupeAccordion from './accordion/DedupeAccordion';

const SpinnerBox = styled.div`
  border-bottom: 1px solid #dee2e4;
`;

interface IReduxMappingProps {
  isLoading: boolean;
  facets: Array<IPatentFacet>;
  advancedKeywords?: Array<IAdvancedKeyword>;
  isNotEmpty: boolean;
}

/** 篩選 */
const Filter: React.FC<IReduxMappingProps> = props => {
  const { isLoading, facets, advancedKeywords, isNotEmpty } = props;
  const filterFacets = facets && facets.filter(field => field.keyCode !== 'PO');

  return isLoading ? (
    <SpinnerBox>
      <Spinner margin="58px auto" />
    </SpinnerBox>
  ) : isNotEmpty ? (
    <>
      <DedupeAccordion title="檢索去重" />
      <BilingualAccordion title="雙語詞彙" />
      {filterFacets &&
        filterFacets.map(field => {
          const advancedKeyword =
            advancedKeywords && advancedKeywords.find(item => item.keyCode === field.keyCode);
          if (field.keyCode === 'IC') {
            return (
              field.patentFacetFieldCount.length > 0 && (
                <IpcAccordion
                  key={field.keyCode}
                  keyCode={field.keyCode}
                  title={patentFacetText.get(field.keyCode)}
                  tag={advancedKeyword && advancedKeyword.keyword}
                  content={field.patentFacetFieldCount}
                />
              )
            );
          } else {
            return (
              field.patentFacetFieldCount.length > 0 && (
                <Accordion
                  key={field.keyCode}
                  keyCode={field.keyCode}
                  title={patentFacetText.get(field.keyCode)}
                  tag={advancedKeyword && advancedKeyword.keyword}
                  content={field.patentFacetFieldCount}
                />
              )
            );
          }
        })}
    </>
  ) : (
    <NoResult />
  );
};

const mapStateToProps = (state: ReduxAppState) => {
  return {
    isLoading: state.patentFilterReducer.isFacetsLoading,
    facets: state.patentFilterReducer.facets,
    advancedKeywords: state.patentSearchReducer.request.advanceKeywords,
    isNotEmpty:
      (state.patentFilterReducer.facets.length > 0 &&
        state.patentFilterReducer.facets.some(item => item.patentFacetFieldCount.length > 0)) ||
      state.patentFilterReducer.bilingualTerms.length > 0,
  };
};

export default connect(
  mapStateToProps,
  null,
)(Filter);
