import React, { useState, useEffect, useRef, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import ReactImageCrop from 'react-image-crop';
import styled, { css } from 'styled-components';
import {
  blue2,
  blue7,
  blue8,
  iceblue3,
  iceblue5,
  ultrawhite,
  iceblue6,
  black1,
  lightgreen1,
  iceblue1,
  blue3rgba,
} from 'src/style/theme/Color';
import { FaDownload, FaListAlt } from 'react-icons/fa';
import { ReactComponent as SEND } from 'src/assets/images/paper-plane-top.svg';
import ResultGridItem from 'src/components/search/trademark/ResultGridItem';
import WaterfallGrid from 'src/components/ui/interactive/WaterfallGrid';
import InfiniteScroll from 'src/components/ui/interactive/InfiniteScroll';
import Checkbox from 'src/components/ui/interactive/checkbox/Checkbox';
import { Spinner } from 'src/components/ui/interactive/Spinner';
import { numFormatter } from 'src/utils/Formatter';
import { openLoginAlertModal } from 'src/redux/actions/modalAction';

import { connect } from 'react-redux';
import { ReduxAppState } from 'src/redux/reducers';
import { openCustomModal } from 'src/redux/actions/modalAction';
import { fetchImgClassMapping, navigateToNextPage } from 'src/redux/trademark/action';
import CropImageModal from 'src/components/ui/interactive/modal/custom/CropImageModal';
import { CustomModalType, LoginModalType } from 'src/types/ModalTypes';
import {
  IExportData,
  ITrademarkImageCategory,
  IVisualSearchPaginatedData,
  IVisualSearchRequest,
  IVisualSearchResult,
  ISuggestions,
  TmApplMetadata,
  IFeedbackRecord,
  IImgClassPredictRequest,
  ICodeDescriptionMapping,
} from 'src/types/TrademarkTypes';
import { IOverlayDetail } from './ResultGridItem';
import {
  fetchVisualSearchResult,
  setVisualSearchRequest,
  fetchFacets,
  appendAdvancedKeyword,
  detachAdvancedKeyword,
  getMetadatasAndImgClasses,
  fetchImgClassPrediction,
} from 'src/redux/trademark/action';
import { csvFileName, csvHeaders, FeedbackType, TrademarkService } from 'src/api/Trademark';
import { Dropdown, IDropdownItem } from 'src/components/common/Dropdown';
import LeftMenu from 'src/components/result/left-menu/LeftMenu';
import TmFilter from 'src/components/result/left-menu/TmFilter';
import Suggestion from './Suggestion';
import { Button } from 'src/style/theme/Common';
import { Row } from 'src/style/PatentSearchStyle';
import { tmFacetText } from 'src/constants/TmSearchConstants';
import { CSVLink } from 'react-csv';
import { alertMessage } from 'src/utils/ModalUtils';
import { SystemMessage } from 'src/apps/applicationMessages';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import { fetchImage } from 'src/utils/ImageUtils';
import TmCaseNoSearchModal from 'src/components/ui/interactive/modal/custom/TmCaseNoSearchModal';
import { DividingLine } from 'src/style/PatentContentStyle';
import TmFeedbackSearch from '../withShortcut/TmFeedbackSearch';
import useLoginChecker from 'src/apps/auth/useLoginChecker';
import { check, Permission } from 'src/apps/permission/Permission';
import MiniLoader from 'src/components/ui/interactive/MiniLoader';
import Tooltip from 'src/components/ui/interactive/Tooltip';

const Container = styled.div`
  width: calc(100% - 200px);
  margin: 16px auto;
  border-radius: 0 0 5px 5px;
  background-color: ${ultrawhite};
  min-height: calc(100vh - 62px - 32px);
  padding-right: 72px;
`;

const StickyHeader = styled.div`
  z-index: 500;
`;

const Header = styled.div`
  height: 60px;
  color: ${ultrawhite};
  background-image: linear-gradient(95deg, #2ec3c2, #0cafcc);
  border-radius: 4px 4px 0 0;
`;

const Feature = styled.div`
  padding: 16px 32px 0 32px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
`;

const SearchCostBox = styled.div`
  margin-top: 8px;
  min-height: 22px;
  color: ${blue2};
  opacity: 0.6;
`;

const Content = styled.div`
  min-height: 450px;
  align-items: flex-start;
  display: flex;
  margin-top: 24px;
`;

const Subheaders = styled.div`
  padding: 5px 32px 15px 32px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background-color: ${blue7};
  position: relative;
  * {
    z-index: 10;
  }
`;

const Overlap = styled.div`
  height: 42px;
  width: 100%;
  z-index: 9;
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient(95deg, #2ec3c2, #0cafcc);
`;

const HeaderLeft = styled.div`
  flex-basis: 284px;
  padding-right: 24px;
`;

const HeaderMiddle = styled.div`
  flex: 1;
`;

const ResultTop = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  padding: 0px 16px 0px 32px;
`;

const HeaderRight = styled.div`
  position: fixed;
  top: 96px;
  right: 48px;
  border-radius: 4px;
`;

const Group = styled.div<{ isActive?: boolean }>`
  margin-bottom: 8px;
  padding: 8px 0;
  border-radius: 4px;
`;

const GroupBlue = styled(Group)`
  box-shadow: 0 5px 20px -5px rgba(0, 63, 84, 0.3);
  background-image: linear-gradient(127deg, #1f8ba3, #323846);
  opacity: ${props => (props.isActive ? 1 : 0.7)};
`;

const GroupItem = styled.div<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  color: ${ultrawhite};
  :hover {
    cursor: pointer;
  }
  svg {
    margin-right: 8px;
  }
`;

const GroupItemBlue = styled(GroupItem)`
  ${props =>
    props.isActive &&
    css`
      :hover {
        background-color: ${blue3rgba(0.2)};
      }
    `};
  opacity: ${props => (props.isActive ? 1 : 0.4)};
  a {
    color: ${ultrawhite};
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  padding: 0px 8px;
`;

const ImageController = styled.div`
  position: absolute;
  border-radius: 4px;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 63, 84, 0.7);
  width: 100%;
  height: 100%;
  display: none;
  padding: 0 14px;
  left: 0;
  top: 0;

  span {
    padding: 8px 8px;
    color: ${iceblue3};
    background-color: ${blue7};
    border-radius: 4px;
    cursor: pointer;
    :hover {
      color: ${blue2};
    }
  }
`;

const ImageBasis = styled.div<{ shouldFixed: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${iceblue5};
  background-color: ${ultrawhite};
  border-radius: 4px;
  width: 260px;
  height: 192px;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);

  ${props =>
    props.shouldFixed &&
    css`
      position: fixed;
      top: 64px;
    `}

  > span {
    width: 100%;
    text-align: center;
    padding-top: 25%;
  }

  > input {
    display: none;
  }

  img {
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
    max-width: 260px;
    max-height: 192px;
  }

  :hover {
    ${ImageController} {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
`;

const NoResult = styled.div`
  margin: 16px 32px;
  padding: 16px 32px;
  background-color: ${blue8};

  div {
    color: ${blue2};
    font-weight: 600;
    margin-bottom: 8px;
  }
  span {
    color: ${iceblue3};
  }
`;

const SearchArea = styled.div`
  height: 61px;
  width: 100%;
  display: flex;
  border-radius: 4px;
  position: relative;
  line-height: 60px;
  text-align: left;
  border-radius: 4px;
  box-shadow: 0 4px 16px -4px rgba(38, 50, 55, 0.3);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  background-color: rgba(255, 255, 255, 0.9);
`;

const SearchTitle = styled.div`
  font-size: 13px;
  padding-left: 16px;
  color: ${black1};
  border-radius: 4px 0px 0px 4px;
  background-color: ${lightgreen1};
`;

const InputContainer = styled.div`
  height: 61px;
  flex: 1;
  padding-left: 16px;
  position: relative;
  display: flex;
`;

const Input = styled.input.attrs({ type: 'text' })`
  height: 61px;
  padding: 20px 8px;
  padding-right: 48px;
  width: 100%;
  border-radius: 4px;
  color: #333333;
  background-color: transparent;
  border: none;
  outline: none;
  ::placeholder {
    color: ${iceblue6};
  }
  &:disabled {
    cursor: not-allowed;
    color: ${iceblue6};
  }
`;

const SearchFilterContainer = styled.div<{ isShow: boolean }>`
  display: flex;
  flex-flow: row wrap;
  margin-bottom: -8px;
  padding: 0px 8px;
  margin: 8px 0 -8px 0px;
  display: ${props => !props.isShow && 'none'};
  * {
    z-index: 0;
  }
`;

const SearchFilterTitle = styled.div`
  color: ${iceblue5};
  margin-right: 10px;
  padding: 8px 0px;
`;

const customDropdownSelectedItem = css`
  color: inherit;
  padding: 0;
`;

const customDropdownItemBlock = css`
  top: 68px;
  right: 0;
  left: -16px;
  width: fit-content;
  line-height: 40px;
  padding: 0px;
`;

const customStyleItem = css`
  &:hover {
    background-color: ${lightgreen1};
  }
`;

const ResultBlock = styled.div`
  width: calc((100% - 248px) - 32px);
  margin-top: -24px;
`;

const SearchButtonRow = styled(Row)`
  margin-top: 8px;
`;

const ImgClassPredictContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: -8px;
  padding: 0px 8px;
  margin: 8px 0 -8px 0px;
  * {
    z-index: 0;
  }
  justify-content: space-between;
`;

const ImgClassPredictTitle = styled.div`
  color: ${iceblue5};
  width: 20%;
`;

const ImgClassPredictContent = styled.div`
  width: 70%;
  display: flex;
  flex-wrap: wrap;
`;

const DescriptionTooltip = styled(Tooltip)`
  color: ${blue2};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 10px;
`;

const ErrorMsg = styled.div`
  max-width: 350px;
  color: ${blue2};
`;

const visualSearchService = TrademarkService();

export enum SearchType {
  GOODS_NAME = '商品名稱',
  GOODS_GROUP = '類似組群',
}
export const DROP_DOWN_ITEMS: IDropdownItem[] = [
  {
    name: '商品名稱',
    value: SearchType.GOODS_NAME,
  },
  {
    name: '類似組群',
    value: SearchType.GOODS_GROUP,
  },
];

// 匯出評價
export const handleCsvLinkClick = () => {
  // 需檢查權限
  if (!check(Permission.TM_FEEDBACK_EXPORT)) {
    alertMessage(SystemMessage.PERMISSION_FORBIDDEN);
    return false;
  } else {
    return true;
  }
};

interface IReduxMappingProps {
  visualSearchRequest: IVisualSearchRequest;
  isLoading: boolean;
  isMetadataLoaded: boolean;
  isSuggestionLoading: boolean;
  isImgClassPredictLoading: boolean;
  paginatedData: IVisualSearchPaginatedData;
  fullData: Array<IVisualSearchResult>;
  filteredData: Array<IVisualSearchResult>;
  errorMsg?: string;
  isCropImageModalOpen: boolean;
  isSearchCaseNoModalOpen: boolean;
  suggestions: Array<ISuggestions>;
  imgClassPredictions: Array<string>;
  imgClassMapping: ICodeDescriptionMapping;
  navigateToNextPage: () => void;
  fetchVisualSearchResult: () => void;
  fetchFacets: (metadatas: Array<TmApplMetadata>, imgClasses: Array<string>) => void;
  fetchImgClassPrediction: (request: IImgClassPredictRequest) => void;
  fetchImgClassMapping: () => void;
  setVisualSearchRequest: (
    caseNo: string | undefined,
    categoryId: number | undefined,
    image: File,
    shapeAlpha: number,
    featureType: number,
    readMetadata?: boolean,
  ) => void;
  openCustomModal: (customModalType: CustomModalType) => void;
  appendAdvancedKeyword: (keyCode: string, keyword: string) => void;
  detachAdvancedKeyword: (keyCode: string) => void;
  openLoginAlertModal: (isFromRoute: boolean) => void;
}

interface IProps extends IReduxMappingProps, RouteComponentProps {}

const searchResultToOverlayDetails: (data: IVisualSearchResult) => Array<IOverlayDetail> = data => [
  { title: '申請案號', content: data.caseNo },
];

/** 商標以圖找圖查詢結果 */
const TrademarkVisualResult = (props: IProps) => {
  const {
    visualSearchRequest,
    isLoading,
    isMetadataLoaded,
    isSuggestionLoading,
    isImgClassPredictLoading,
    paginatedData,
    fullData,
    filteredData,
    isCropImageModalOpen,
    isSearchCaseNoModalOpen,
    errorMsg,
    suggestions,
    imgClassPredictions,
    imgClassMapping,
    navigateToNextPage: reduxNavigateToNextPage,
    fetchVisualSearchResult: reduxFetchVisualSearchResult,
    fetchFacets: reduxFetchFacets,
    setVisualSearchRequest: redexSetVisualSearchRequest,
    openCustomModal: reduxOpenCustomModal,
    appendAdvancedKeyword: reduxAppendAdvancedKeyword,
    detachAdvancedKeyword: reduxDetachAdvancedKeyword,
    openLoginAlertModal: reduxOpenLoginAlertModal,
    fetchImgClassPrediction: reduxFetchImgClassPrediction,
    fetchImgClassMapping: reduxFetchImgClassMapping,
    history,
    location,
  } = props;

  const [inputText, setInputText] = useState('');

  const [searchType, setSearchType] = useState<IDropdownItem>(DROP_DOWN_ITEMS[0]);
  // 有裁切資訊則非申請號查詢
  const [caseNo, setCaseNo] = useState<string | undefined>(
    location.state.crop ? undefined : location.state.caseNo,
  );
  const [croppedImg, setCroppedImg] = useState<string | undefined>();
  const [crop, setCrop] = useState<ReactImageCrop.Crop | undefined>(location.state.crop);
  const [croppedImgFile, setCroppedImgFile] = useState<File | undefined>(location.state.image); // 用於結果頁左上角之來源圖(裁切後的圖)
  const [imgSrc, setImgSrc] = useState<string>(location.state.imgSrc); // 用於在裁切畫面上顯示(完整圖)
  const [imagePath, setImagePath] = useState<string | undefined>(location.state.imagePath);
  const [featureShape, setFeatureShape] = useState<boolean>(false);
  const [featureColor, setFeatureColor] = useState<boolean>(false);
  const fileUploaderRef = useRef<HTMLInputElement>(null);
  const [leftMenuActiveTab, setLeftMenuActiveTab] = useState(0);
  const [isCleared, setIsCleared] = useState<boolean>(false);
  const [selectedCaseNos, setSelectedCaseNos] = useState<Map<string, FeedbackType>>(new Map());
  const [openFeedbackPane, setOpenFeedbackPane] = useState<boolean>(false);
  const [rotateDeg, setRotateDeg] = useState<number>(location.state.rotateDeg);
  const [multiplier, setMultiplier] = useState<number>(location.state.multiplier);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const isLogin = useLoginChecker();
  const [imgClassRequest, setImgClassRequest] = useState<IImgClassPredictRequest>({
    caseNo: caseNo,
    image: croppedImgFile,
    imgPath: imagePath,
  });
  const [imgClassPredictionDesc, setImgClassPredictionDesc] = useState<ICodeDescriptionMapping>({});
  const [isPredictError, setIsPredictError] = useState<boolean>(false);

  // paginatedData.sameCategoryImageNumber could be null, treat it as undefined here
  const sameCatImages =
    paginatedData.sameCategoryImageNumber === undefined ||
    paginatedData.sameCategoryImageNumber === null
      ? undefined
      : paginatedData.sameCategoryImageNumber;

  // 偵測 input 是否能在畫面上被看到，置頂選單高 60px，在被置頂選單遮住的時候就算看不到
  const onInputNodeChange = useCallback(node => {
    const current = node;
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsInputVisable(entry.isIntersecting);
      },
      { rootMargin: '-60px' },
    );

    current && observer.observe(current);
  }, []);
  const [isInputVisable, setIsInputVisable] = useState<boolean>(true);

  const getPlaceholder = () => {
    if (!isMetadataLoaded && fullData.length > 0) {
      // 以圖找圖API成功且正在讀取圖檔詮釋資料
      return '商標資訊載入中, 請稍後...';
    } else {
      if (searchType.value === SearchType.GOODS_NAME) {
        return '請輸入商品名稱，如：釣魚線, 書籍, 印刷 等，以空格分開';
      } else if (searchType.value === SearchType.GOODS_GROUP) {
        return '請輸入代碼，如：2706, 3112, 092002 等，以空格分開';
      }
    }
  };

  // 非圖形路徑查詢才提供更換圖檔功能
  const selectedCategory: ITrademarkImageCategory | undefined = location.state.category;
  const showImageController = !isLoading && !selectedCategory;

  const shapeAlpha = visualSearchService.DEFAULT_SHAPE_ALPHA;
  const didMountRef = useRef(false);
  const didSearchRef = useRef(false);
  const didPredictRef = useRef(false);

  const clearAllAdvancedKeywords = useCallback(() => {
    for (let facetKeyCode of Array.from(tmFacetText.keys())) {
      reduxDetachAdvancedKeyword(facetKeyCode);
    }
    reduxDetachAdvancedKeyword('GOODS_NAME');
    reduxDetachAdvancedKeyword('GOODS_GROUP');
  }, [reduxDetachAdvancedKeyword]);

  // 清除查詢條件
  const clearSearch = useCallback(() => {
    setIsCleared(true);
    setInputText('');
    setFeatureColor(false);
    setFeatureShape(false);
    setSelectedCaseNos(new Map());
    clearAllAdvancedKeywords();
    // 如果當前的查詢結果是外型或顏色排序的話, 需要重新查詢, 查詢條件除了排序之外其他的不可變
    if (visualSearchRequest.featureType !== 0) {
      visualSearchRequest.image &&
        redexSetVisualSearchRequest(
          visualSearchRequest.caseNo,
          visualSearchRequest.categoryId,
          visualSearchRequest.image,
          visualSearchRequest.shapeAlpha,
          visualSearchService.calculateFeatureType(false, false),
        );
    }
  }, [clearAllAdvancedKeywords, redexSetVisualSearchRequest, visualSearchRequest]);

  useEffect(() => {
    reduxFetchImgClassMapping();
  }, [reduxFetchImgClassMapping]);

  // Mimic componentDidMount
  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true;
      const { image, imgSrc: locationImgSrc } = location.state;

      const prepareImg = async () => {
        fetch(locationImgSrc).catch(() => {
          // 若連結已失效(F5重整會導致失效)，則重新自檔案建立 object url
          // 不跟 croppedImg 採用相同 URL 是因為 croppedImg 則是裁切後會產新的並把舊的刪除
          // 使用相同 URL 會導致裁切後再點選裁切時, imgSrc 使用的 URL 失效而呈現空白
          const newImgSrc = URL.createObjectURL(image);
          setImgSrc(newImgSrc);
        });
        const fileObjectUrl = URL.createObjectURL(image);
        setCroppedImg(fileObjectUrl);
      };
      prepareImg();
      // 從搜尋頁來或F5重新整理(redux store內為initial state), 或結果頁點選上一頁再點選一頁時，要重新發API查詢
      if (location.state) {
        // 清除之前的查詢資料, 否則會自動過濾
        clearSearch();
        image &&
          redexSetVisualSearchRequest(
            caseNo,
            selectedCategory && selectedCategory.id,
            image,
            shapeAlpha,
            visualSearchService.calculateFeatureType(featureShape, featureColor),
          );
        setImgClassRequest({
          caseNo: caseNo,
          image: image,
          imgPath: imagePath,
        });
      }
    }
  }, [
    redexSetVisualSearchRequest,
    reduxFetchVisualSearchResult,
    clearSearch,
    history.action,
    location.state,
    featureShape,
    featureColor,
    shapeAlpha,
    paginatedData.pageNo,
    caseNo,
    imagePath,
    selectedCategory,
    imgClassRequest,
  ]);

  // 有經過裁切則不算申請號查詢, 需移除申請號資訊, 避免使用申請號重查相似圖API
  useEffect(() => {
    if (!!crop) {
      setCaseNo(undefined);
    }
  }, [crop]);

  // 重新查詢或進階查詢
  const handleSearch = (input: string) => {
    if (croppedImgFile) {
      // 檢查圖是否有更改 (不跟 location.state.image 比是因為裁切也算更改)
      const isImageChanged = visualSearchRequest.image !== croppedImgFile;
      if (isImageChanged) {
        history.replace({
          ...location,
          state: {
            ...location.state,
            image: croppedImgFile,
            caseNo: caseNo,
            imgSrc: imgSrc,
            imagePath: imagePath,
            crop: crop,
            rotateDeg: rotateDeg,
            multiplier: multiplier,
          },
        });
      }

      // 是否重新查詢API: 圖或相似度優先條件更改, 或查詢API失敗導致沒資料時需要
      const isFetch =
        visualSearchRequest.featureType !==
          visualSearchService.calculateFeatureType(featureShape, featureColor) ||
        isImageChanged ||
        fullData.length === 0;

      if (isFetch) {
        redexSetVisualSearchRequest(
          caseNo,
          selectedCategory && selectedCategory.id,
          croppedImgFile,
          shapeAlpha,
          visualSearchService.calculateFeatureType(featureShape, featureColor),
        );
        // 重新查詢則先將所有進階檢索條件清空, 之後再把搜尋框當前的drop down加回來
        clearAllAdvancedKeywords();
        setImgClassRequest({
          caseNo: caseNo,
          image: croppedImgFile,
          imgPath: imagePath,
        });
      } else {
        // 類似組群與商品名稱只能選一查詢, 先清掉再重新從當前搜尋框抓; 篩選bar的條件需保留
        reduxDetachAdvancedKeyword('GOODS_NAME');
        reduxDetachAdvancedKeyword('GOODS_GROUP');
      }

      // 將搜尋框內容加入條件
      if (inputText.trim()) {
        const keyCode = searchType.name === SearchType.GOODS_NAME ? 'GOODS_NAME' : 'GOODS_GROUP';
        inputText
          .split(' ')
          .forEach(item => item.trim() && reduxAppendAdvancedKeyword(keyCode, item));
      }
    }
  };

  // 搜尋框下拉選單
  const handleDropDownOnClick = (item: IDropdownItem) => {
    setSearchType(item);
    setInputText('');
    setIsCleared(true);
  };

  useEffect(() => {
    if (isCleared) {
      setIsCleared(false);
    }
  }, [isCleared]);

  /** 當查詢條件改變時重新取得相似圖結果 */
  useEffect(() => {
    // skip first render
    if (didSearchRef.current) {
      visualSearchRequest && reduxFetchVisualSearchResult();
    }
    didSearchRef.current = true;
  }, [reduxFetchVisualSearchResult, visualSearchRequest]);

  useEffect(() => {
    if (isMetadataLoaded) {
      const [metadatas, imgClasses] = getMetadatasAndImgClasses(filteredData);
      reduxFetchFacets(metadatas, imgClasses);
    }
  }, [reduxFetchFacets, filteredData, isMetadataLoaded]);

  useEffect(() => {
    if (didPredictRef.current) {
      reduxFetchImgClassPrediction(imgClassRequest);
    }
    didPredictRef.current = true;
  }, [reduxFetchImgClassPrediction, imgClassRequest]);

  useEffect(() => {
    if (imgClassPredictions && imgClassPredictions.length !== 0) {
      setIsPredictError(false);
      visualSearchService
        .getImgClassDescriptions(imgClassPredictions.map(item => imgClassMapping[item]))
        .then(descs => {
          setImgClassPredictionDesc(descs);
        });
    } else {
      setIsPredictError(true);
    }
  }, [imgClassPredictions, imgClassMapping]);

  const handleReCrop = () => {
    reduxOpenCustomModal(CustomModalType.CROP_IMAGE);
  };

  const handleSuggestionAdd = (value: string) => {
    setInputText(inputText + ' ' + value);
  };

  const handleSuggestionRemove = (value: string) => {
    setInputText(
      inputText
        .split(' ')
        .filter(item => item !== value)
        .join(' '),
    );
  };

  // 重新上傳圖檔
  const handleFileUpload = () => {
    if (fileUploaderRef.current !== null) {
      const files = fileUploaderRef.current.files;
      const uploadedFile = files !== null ? files[0] : undefined;

      if (uploadedFile) {
        setImgSrc(URL.createObjectURL(uploadedFile));
        setCroppedImgFile(uploadedFile);
        setCroppedImg(URL.createObjectURL(uploadedFile));
        setCrop(undefined);
        setCaseNo(undefined);
        // 重置旋轉角度與縮放大小
        setMultiplier(1);
        setRotateDeg(0);
      }
    }
  };

  // 重新根據申請號取得圖檔
  const handleSetCaseNo = (newCaseNo: string) => {
    if (newCaseNo && newCaseNo.trim() !== '') {
      // 如果案號不變, 則無須重新取得圖檔
      if (caseNo !== newCaseNo) {
        if (!newCaseNo.includes('-')) {
          newCaseNo = newCaseNo + '-1';
        }
        visualSearchService
          .getImgPathByCaseNo(newCaseNo)
          .then(data => {
            let imageName = data.split('/').pop();
            fetchImage(visualSearchService.getImageUrl(data), imageName ? imageName : '')
              .then(fetchedImage => {
                const fileObjectUrl = URL.createObjectURL(fetchedImage);
                setCroppedImgFile(fetchedImage);
                // 釋放舊的物件URL, 指向新的
                setCroppedImg(prev => {
                  prev && window.URL.revokeObjectURL(prev);
                  return fileObjectUrl;
                });
                setImgSrc(URL.createObjectURL(fetchedImage));
                setCrop(undefined);
                setCaseNo(newCaseNo);
                setImagePath(data);
              })
              .catch(error => errorHandler(error));
          })
          .catch(error => errorHandler(error));
      }
    } else {
      alertMessage('申請號不得為空');
    }
  };

  // 選取匯出項目
  const handleFeedbackCheck = (targetCaseNo: string, feedbackType: FeedbackType) => {
    if (selectedCaseNos.get(targetCaseNo) === feedbackType) {
      selectedCaseNos.delete(targetCaseNo);
    } else {
      selectedCaseNos.set(targetCaseNo, feedbackType);
    }
    setSelectedCaseNos(new Map(selectedCaseNos));
  };

  // 提交以圖找圖評價
  const saveFeedback = async () => {
    if (visualSearchRequest.featureType !== 0) {
      alertMessage('僅提供對非外型與顏色排序提交評價');
    } else if (selectedCaseNos.size === 0) {
      alertMessage('請至少對一張圖進行評價');
    } else if (!isSaving) {
      // 確認當前沒有在儲存
      setIsSaving(true);
      await visualSearchService.saveFeedbacks({
        caseNo: visualSearchRequest.caseNo,
        image: !visualSearchRequest.caseNo ? visualSearchRequest.image : undefined,
        tmFeedbackRecords: fullData.map((item, idx) => {
          return {
            caseNo: item.caseNo,
            rank: idx + 1,
            type: getFeedbackType(selectedCaseNos, item.caseNo),
          } as IFeedbackRecord;
        }),
      });
      // 為了避免點選評價紀錄並登入後跳回首頁
      history.replace({
        ...location,
        state: {
          ...location.state,
        },
      });
      setIsSaving(false);
      setSelectedCaseNos(new Map());
    }
  };

  const getFeedbackType = (map: Map<string, FeedbackType>, key: string): FeedbackType => {
    let value = map.get(key);
    return value ? value : FeedbackType.NULL;
  };

  // 匯出評價
  const handleExportFeedback = () => {
    return fullData.map((item, idx) => {
      return {
        batchNo: 'X',
        caseNo: visualSearchRequest.caseNo ? visualSearchRequest.caseNo : 'X',
        similarCaseNo: item.caseNo,
        rank: idx + 1,
        feedback: getFeedbackType(selectedCaseNos, item.caseNo),
      } as IExportData;
    });
  };

  // 顯示提交紀錄頁 (限已登入之使用者)
  const handleOpenFeedbackPane = () => {
    setOpenFeedbackPane(true);
    !isLogin && reduxOpenLoginAlertModal(false);
  };

  // 隱藏提交紀錄頁
  const handleCloseFeedbackPane = () => {
    setOpenFeedbackPane(false);
  };

  // 篩選 bar
  const tabs = [
    {
      title: '篩選',
      panel: <TmFilter setSelectedCaseNos={setSelectedCaseNos} />,
    },
  ];
  const handleLeftMenuTabChange = (idx: number) => {
    setLeftMenuActiveTab(idx);
  };

  const paginatedContent = (
    <>
      <LeftMenu
        isShow={true}
        activeTab={leftMenuActiveTab}
        setActiveTab={handleLeftMenuTabChange}
        tabs={tabs}
      />
      <ResultBlock>
        {paginatedData.totalCount <= 0 ? (
          // 無資料時(以圖找圖API失敗或過濾後無資料)
          <NoResult>
            {fullData.length === 0 ? (
              <>
                <div>{errorMsg || '資料載入失敗'}</div>
                <span>
                  如有問題，請聯絡系統管理員。
                  <a href="mailto:tipoeservice@tipo.gov.tw">
                    <Button type="button" template="primary">
                      聯絡系統管理員
                    </Button>
                  </a>
                </span>
              </>
            ) : (
              <>
                <div>查無搜尋結果</div>
                <span>請嘗試修改檢索條件重新進行搜尋。</span>
              </>
            )}
          </NoResult>
        ) : (
          // 有資料時
          <InfiniteScroll
            isNextPageLoading={false}
            isNextPageError={!!errorMsg}
            errorMessage={errorMsg}
            handleNextPageLoad={reduxNavigateToNextPage}
            handleReload={reduxFetchVisualSearchResult}
            totalCount={paginatedData.totalCount}
            currentPage={paginatedData.pageNo}
            totalPages={paginatedData.totalPages}
            stopCount={-1}
          >
            <ResultTop>
              <SearchCostBox>
                {!isLoading &&
                  paginatedData.pageNo !== 0 &&
                  // Check 'sameCatImages !== undefined' instead of only 'sameCatImages', because 0 would be treated as false
                  sameCatImages !== undefined &&
                  `前 200 件搜尋結果中共 ${numFormatter(sameCatImages)} 件為相同圖形路徑`}
                {!isLoading && `共 ${numFormatter(filteredData.length)} 件搜尋結果`}
              </SearchCostBox>
            </ResultTop>
            <WaterfallGrid
              columns={5}
              elements={paginatedData.data.map((item, idx) => (
                <ResultGridItem
                  key={item.caseNo}
                  caseNo={item.caseNo}
                  isPreviewMode={false}
                  isPreviewing={false}
                  details={searchResultToOverlayDetails(item)}
                  listIndex={idx}
                  title={item.name}
                  metadata={item.metadata}
                  handleFeedbackCheck={handleFeedbackCheck}
                  selectedType={getFeedbackType(selectedCaseNos, item.caseNo)}
                  imagePaths={[visualSearchService.getImageUrl(item.imagePath)]}
                />
              ))}
            />
          </InfiniteScroll>
        )}
      </ResultBlock>
    </>
  );

  return (
    <>
      {isLogin && openFeedbackPane ? (
        <TmFeedbackSearch handleBackClick={handleCloseFeedbackPane} />
      ) : (
        <Container>
          <StickyHeader>
            <Header>
              <Feature>
                <Title>商標以圖找圖搜尋結果</Title>
              </Feature>
            </Header>
            <Subheaders>
              <Overlap />
              <HeaderLeft>
                {/* ImageBasis 與 input 同高，當 input 不見即固定 ImageBasis 的位置 */}
                <ImageBasis shouldFixed={!isInputVisable}>
                  <img src={croppedImg} alt="" />
                  <input ref={fileUploaderRef} type="file" onChange={() => handleFileUpload()} />
                  {showImageController && (
                    <ImageController>
                      <span onClick={() => reduxOpenCustomModal(CustomModalType.TM_CASE_NO_SEARCH)}>
                        申請號查詢
                      </span>
                      <span
                        onClick={() =>
                          fileUploaderRef.current !== null && fileUploaderRef.current.click()
                        }
                      >
                        上傳圖檔
                      </span>
                      <span onClick={() => handleReCrop()}>裁切</span>
                    </ImageController>
                  )}
                </ImageBasis>
                <ImgClassPredictContainer>
                  <ImgClassPredictTitle>圖形路徑推薦</ImgClassPredictTitle>
                  {isImgClassPredictLoading ? (
                    <MiniLoader />
                  ) : isPredictError ? (
                    <ErrorMsg>圖形路徑預測失敗</ErrorMsg>
                  ) : (
                    <ImgClassPredictContent>
                      {imgClassPredictions.map(item => {
                        let imgClass = imgClassMapping[item];
                        const hasDescription = imgClassPredictionDesc[imgClass] != null;
                        return (
                          <DescriptionTooltip
                            key={item}
                            overlay={hasDescription ? imgClassPredictionDesc[imgClass] : imgClass}
                            placement="bottom"
                            offset={-12}
                          >
                            <span>{imgClass}</span>
                          </DescriptionTooltip>
                        );
                      })}
                    </ImgClassPredictContent>
                  )}
                </ImgClassPredictContainer>
              </HeaderLeft>
              <HeaderMiddle>
                <SearchArea>
                  <SearchTitle>
                    <Dropdown
                      items={DROP_DOWN_ITEMS}
                      activeItem={searchType}
                      handleOnclick={handleDropDownOnClick}
                      customStyleSelectedItem={customDropdownSelectedItem}
                      customStyleItemBlock={customDropdownItemBlock}
                      customStyleItem={customStyleItem}
                      disabled={!isMetadataLoaded}
                    />
                  </SearchTitle>
                  <InputContainer>
                    <Input
                      ref={onInputNodeChange}
                      value={inputText}
                      onChange={e => setInputText(e.target.value)}
                      placeholder={getPlaceholder()}
                      disabled={!isMetadataLoaded}
                    />
                  </InputContainer>
                </SearchArea>
                <SearchFilterContainer isShow={false}>
                  <SearchFilterTitle>排序</SearchFilterTitle>
                  <CheckboxContainer>
                    <Checkbox name="外型相似優先" checked={featureShape} color={iceblue1}>
                      <input
                        type="checkbox"
                        name="外型相似優先"
                        checked={featureShape}
                        onChange={event => {
                          setFeatureShape(event.target.checked);
                        }}
                      />
                    </Checkbox>
                  </CheckboxContainer>
                  <CheckboxContainer>
                    <Checkbox name="顏色相似優先" checked={featureColor} color={iceblue1}>
                      <input
                        type="checkbox"
                        name="顏色相似優先"
                        checked={featureColor}
                        onChange={event => {
                          setFeatureColor(event.target.checked);
                        }}
                      />
                    </Checkbox>
                  </CheckboxContainer>
                </SearchFilterContainer>
                <SearchFilterContainer isShow={searchType.name === SearchType.GOODS_NAME}>
                  <SearchFilterTitle>標籤</SearchFilterTitle>
                  <>
                    {!isSuggestionLoading &&
                      suggestions.map((item, index) => (
                        <Suggestion
                          key={item.value}
                          value={item.value}
                          handleSuggestionAdd={handleSuggestionAdd}
                          handleSuggestionRemove={handleSuggestionRemove}
                          isCleared={isCleared}
                          inputText={inputText}
                        />
                      ))}
                  </>
                </SearchFilterContainer>
                <SearchFilterContainer isShow={true}>
                  <SearchButtonRow justifyContent="flex-start">
                    <Button
                      template="primary"
                      margin="0px"
                      onClick={() => handleSearch(inputText)}
                      disabled={!isMetadataLoaded && fullData.length !== 0} // 尚未讀完詮釋資料或以圖找圖API失敗時不可按
                    >
                      查詢
                    </Button>
                    <Button type="reset" onClick={clearSearch}>
                      清除
                    </Button>
                  </SearchButtonRow>
                </SearchFilterContainer>
              </HeaderMiddle>
            </Subheaders>
          </StickyHeader>
          <Content>
            {isLoading ? <Spinner width="150px" margin="100px auto" /> : paginatedContent}
          </Content>
          {isCropImageModalOpen && (
            <CropImageModal
              imgSrc={imgSrc}
              setCroppedImg={setCroppedImg}
              crop={crop}
              setCrop={setCrop}
              setCroppedImgFile={setCroppedImgFile}
              rotate={true}
              rotateDeg={rotateDeg}
              setRotateDeg={setRotateDeg}
              multiplier={multiplier}
              setMultiplier={setMultiplier}
              detectObject={TrademarkService().getDetectObjectBlocks}
            />
          )}
          {isSearchCaseNoModalOpen && (
            <TmCaseNoSearchModal
              defaultInput={caseNo}
              handleSearch={handleSetCaseNo}
              notifyMsg="請輸入 {申請號}-{圖檔編號} 並進行搜尋，圖檔編號預設為 1。例如：109002750 或 109002750-1"
            />
          )}
          <HeaderRight>
            {paginatedData && (
              <GroupBlue isActive={true}>
                <GroupItemBlue
                  isActive={!isSaving && visualSearchRequest.featureType === 0}
                  onClick={saveFeedback}
                >
                  <SEND />
                  提交評價
                </GroupItemBlue>
                <GroupItemBlue isActive={true} onClick={handleOpenFeedbackPane}>
                  <FaListAlt size="14px" />
                  評價紀錄
                </GroupItemBlue>
                {check(Permission.TM_FEEDBACK_EXPORT) && (
                  /** 有權限才可看到按鈕 */
                  <>
                    <DividingLine />
                    <GroupItemBlue isActive={true}>
                      <FaDownload size="14px" />
                      <CSVLink
                        filename={csvFileName}
                        headers={csvHeaders}
                        data={handleExportFeedback()}
                        onClick={handleCsvLinkClick}
                      >
                        匯出評價
                      </CSVLink>
                    </GroupItemBlue>
                  </>
                )}
              </GroupBlue>
            )}
          </HeaderRight>
        </Container>
      )}
    </>
  );
};

const mapStateToProps = (state: ReduxAppState) => {
  return {
    visualSearchRequest: state.trademarkVisualSearchReducer.request,
    paginatedData: state.trademarkVisualSearchReducer.paginatedData,
    filteredData: state.trademarkVisualSearchReducer.filteredData,
    fullData: state.trademarkVisualSearchReducer.response.data,
    isLoading: state.trademarkVisualSearchReducer.isLoading,
    isMetadataLoaded: state.trademarkVisualSearchReducer.isMetadataLoaded,
    isSuggestionLoading: state.trademarkVisualSearchReducer.isSuggestionLoading,
    isImgClassPredictLoading: state.trademarkVisualSearchReducer.isImgClassPredictLoading,
    suggestions: state.trademarkVisualSearchReducer.suggestions,
    imgClassPredictions: state.trademarkVisualSearchReducer.imgClassPredictions,
    imgClassMapping: state.trademarkVisualSearchReducer.imgClassMapping,
    errorMsg: state.trademarkVisualSearchReducer.errorMsg,
    isCropImageModalOpen: state.modalReducer.customModalType === CustomModalType.CROP_IMAGE,
    isLoginAlertModalOpen: state.modalReducer.loginModal.type !== LoginModalType.NONE,
    isSearchCaseNoModalOpen:
      state.modalReducer.customModalType === CustomModalType.TM_CASE_NO_SEARCH,
  };
};

const mapDispatchToProps = {
  navigateToNextPage,
  openCustomModal,
  fetchVisualSearchResult,
  fetchFacets,
  setVisualSearchRequest,
  appendAdvancedKeyword,
  detachAdvancedKeyword,
  openLoginAlertModal,
  fetchImgClassPrediction,
  fetchImgClassMapping,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TrademarkVisualResult);
