import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { ultrablack } from 'src/style/theme/Color';
import { ArticleContext } from 'src/components/article/item/ColumnistArticleItem';

const Ul = styled.ul`
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  flex-flow: row;
  align-items: center;
  list-style: none;
  li:nth-child(1) a {
    padding: 16px 10px 16px 0;
  }
`;
const Li = styled.li`
  margin: 0;
  height: 100%;
  display: flex;
  align-items: center;
  a {
    padding: 16px 10px;
    width: 100%;
    height: 100%;
    display: block;
    color: ${ultrablack};
    font-weight: 450;
    white-space: nowrap;
    :hover,
    :active,
    &.active {
      color: #009fcc;
    }
  }
`;

function Sectors() {
  const isArticlePage = React.useContext(ArticleContext);

  const navs = [
    { section: '專利專區', path: '/showcase' },
    { section: '專利檢索', path: '/search' },
    { section: '新聞專區', path: '/news' },
    { section: '開放資料', path: '/data' },
    { section: '專欄文章', path: '/article' },
    { section: '學界專家', path: '/expert' },
    { section: '常見問題', path: '/question' },
  ];

  return (
    <Ul>
      {navs.map(nav => (
        <Li key={nav.section}>
          {isArticlePage ? (
            <a href={nav.path}>{nav.section}</a>
          ) : (
            <NavLink to={nav.path}>{nav.section}</NavLink>
          )}
        </Li>
      ))}
    </Ul>
  );
}

export default Sectors;
