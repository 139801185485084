import React from 'react';
import styled from 'styled-components';
import collect from 'src/assets/images/manual/patent-assisted-read/collect.png';
import note from 'src/assets/images/manual/patent-assisted-read/note.png';

const ImgContainer = styled.div<{ height: string; imageUrl: string }>`
  position: relative;
  height: ${props => props.height}px;
  padding: 10px 0;
  background-image: url(${props => props.imageUrl});
  background-size: contain;
  background-repeat: no-repeat;
`;
export default function PatentAssistedReadManual() {
  return (
    <>
      <ol>
        <li>專利內容點選收藏專利，選擇收藏分類或是新增分類名稱。</li>
        <li>於專利專區的「我的收藏」顯示收藏分類及收藏的專利</li>
        <br />
        <ImgContainer height="350" imageUrl={collect} />
        <br />
        <li>收藏的專利可點選筆記，輸入文字儲存作為個人專利筆記紀錄。</li>
        <br />
        <ImgContainer height="350" imageUrl={note} />
      </ol>
    </>
  );
}
