import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';

import ExpertPatent from './ExpertPatent';
import { Spinner } from 'src/components/ui/interactive/Spinner';

import { Api, baseURL } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import { millisFormatter } from 'src/utils/Formatter';
import { IExpert, IExpertKnowledgeGraph, IKnowledgeGraphItem } from 'src/types/ExpertTypes';

import EXPERT_AVATAR from 'src/assets/images/expert-avatar.png';
import { FaBuilding, FaIdCard, FaProjectDiagram, FaTags, FaUserTie } from 'react-icons/fa';
import MiniLoader from '../ui/interactive/MiniLoader';
import { Link } from 'react-router-dom';
import ExpandableBox from './ExpandableBox';
import OverflowAwareBox from './OverflowAwareBox';
import { Button } from 'src/style/theme/Common';
import { alertMessage } from 'src/utils/ModalUtils';
import { SystemMessage } from 'src/apps/applicationMessages';
import KeywordFeedback from 'src/components/patent/content/KeywordFeedback';
import {
  Container,
  Header,
  Avatar,
  HeaderTitle,
  HeaderInfo,
  Name,
  UpdateTime,
  OverView,
  Profile,
  ProfileColumn,
  Field,
  FieldName,
  FieldValueList,
  NoResult,
  Dot,
  CardContent,
  CardContentNote,
  KnowledgeGraphCard,
  KnowledgeGraphRow,
  KnowledgeGraph,
  CardHeader,
  CardIcon,
  CardTitle,
  RelatedExpertContainer,
  RelatedExpert,
} from 'src/style/ExpertStyle';

interface IRouteProps {
  id: string;
}

interface IProps extends RouteComponentProps<IRouteProps> {
  id?: string;
}

/** 專家履歷 - 個人資訊 */
const ExpertDetail: React.FC<IProps> = ({ id, match, history }) => {
  const [expert, setExpert] = useState<IExpert>();
  const [knowledgeGraph, setKnowledgeGraph] = useState<IExpertKnowledgeGraph>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingGraph, setIsLoadingGraph] = useState<boolean>(false);

  // 若id有值則為 知識圖譜專家比較頁面
  const expertId = id ? id : match.params.id;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const res = await Api().get(`expert/${expertId}`);
        setExpert(res.data);
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [expertId]);

  useEffect(() => {
    const fetchKnowledgeGraphData = async () => {
      setIsLoadingGraph(true);

      try {
        const res = await Api().get(`expert/${expertId}/knowledge/graph`);
        setKnowledgeGraph(res.data);
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsLoadingGraph(false);
      }
    };

    fetchKnowledgeGraphData();

    return () => setKnowledgeGraph(undefined);
  }, [expertId]);

  const getCardContent = (list: Array<IKnowledgeGraphItem> | undefined, isKeyword: boolean) =>
    isLoadingGraph ? (
      <MiniLoader />
    ) : list ? (
      list.map(item => (
        <CardContent key={item.name}>
          {isKeyword ? <></> : <Dot />}
          {isKeyword ? (
            <>
              <KeywordFeedback keywords={[item.name]} dot={true} />
            </>
          ) : (
            <></>
          )}
          <OverflowAwareBox
            lineElement={
              item.note && item.note !== null ? (
                <CardContentNote>{item.note}</CardContentNote>
              ) : (
                undefined
              )
            }
          >
            {isKeyword ? <></> : <span>{item.name}</span>}
          </OverflowAwareBox>
        </CardContent>
      ))
    ) : (
      <NoResult>無法取得資料</NoResult>
    );

  const job = expert
    ? (expert.jobUnit ? expert.jobUnit + ' ' : '') +
      (expert.jobDepartment ? expert.jobDepartment + ' ' : '') +
      (expert.jobTitle ? expert.jobTitle : '')
    : undefined;

  const showAllExperts = () => {
    if (knowledgeGraph && knowledgeGraph.relatedExperts) {
      history.push('/experts', {
        relatedExperts: knowledgeGraph.relatedExperts,
        expertId: expertId,
      });
    } else {
      alertMessage(SystemMessage.EXPERT_GRAPH_RELATED_EXPERTS_ERROR);
    }
  };

  return isLoading && isLoadingGraph ? (
    <Spinner width="200px" margin="150px auto" />
  ) : expert ? (
    <Container
      number={
        id
          ? knowledgeGraph &&
            knowledgeGraph.relatedExperts &&
            knowledgeGraph.relatedExperts.length + 1
          : undefined
      }
    >
      <Header>
        <Avatar>
          <img
            src={
              expert.photo && expert.photo !== ''
                ? `${baseURL}expert/${expert.id}/photo`
                : EXPERT_AVATAR
            }
            alt={expert.name}
          />
        </Avatar>
        <HeaderInfo>
          <HeaderTitle>專家履歷</HeaderTitle>
          <div>
            <Name>{expert.name}</Name>
            <UpdateTime>更新：{millisFormatter(expert.updateTime)}</UpdateTime>
          </div>
        </HeaderInfo>
      </Header>
      <OverView>
        {/* TODO: 這塊包成 component 做無資料判斷等處理 */}
        <Profile>
          {expert.email ||
          expert.phone ||
          expert.expertise ||
          job ||
          (expert.experiences && expert.experiences.length > 0) ||
          (expert.educations && expert.educations.length > 0) ||
          (expert.publications && expert.publications.length > 0) ? (
            <>
              <ProfileColumn>
                <Field>
                  <FieldName>信箱 ：</FieldName>
                  <span>{expert.email}</span>
                </Field>
                <Field>
                  <FieldName>電話 ：</FieldName>
                  <span>{expert.phone}</span>
                </Field>
                <Field>
                  <FieldName>專長 ：</FieldName>
                  <ExpandableBox>
                    <span>{expert.expertise}</span>
                  </ExpandableBox>
                </Field>
                <Field>
                  <FieldName>現職 ：</FieldName>
                  <ExpandableBox>
                    <span>{job}</span>
                  </ExpandableBox>
                </Field>
                <Field>
                  <FieldName>經歷 ：</FieldName>
                  <FieldValueList>
                    <ExpandableBox>
                      {expert.experiences &&
                        expert.experiences.map((item, idx) => (
                          <div key={idx}>
                            {item.startYear && item.endYear && (
                              <span>
                                {item.startYear} - {item.endYear}&nbsp;
                              </span>
                            )}
                            {item.title}
                          </div>
                        ))}
                    </ExpandableBox>
                  </FieldValueList>
                </Field>
                <Field>
                  <FieldName>學歷 ：</FieldName>
                  <FieldValueList>
                    <ExpandableBox>
                      {expert.educations &&
                        expert.educations.map((item, idx) => (
                          <div key={idx}>
                            {item.graduateYear && item.graduateYear + '年 畢業於'}&nbsp;{item.title}
                          </div>
                        ))}
                    </ExpandableBox>
                  </FieldValueList>
                </Field>
              </ProfileColumn>
              <ProfileColumn>
                <Field>
                  <FieldName>著作 ：</FieldName>
                  <FieldValueList>
                    <ExpandableBox>
                      {expert.publications &&
                        expert.publications.map((item, idx) => <div key={idx}>{item.title}</div>)}
                    </ExpandableBox>
                  </FieldValueList>
                </Field>
                <Field>
                  <FieldName>論文 ：</FieldName>
                  <FieldValueList>
                    <ExpandableBox>
                      {expert.theses &&
                        expert.theses.map((item, idx) => <div key={idx}>{item.title}</div>)}
                    </ExpandableBox>
                  </FieldValueList>
                </Field>
              </ProfileColumn>
            </>
          ) : (
            '目前無資料'
          )}
        </Profile>
        <KnowledgeGraph>
          <KnowledgeGraphRow>
            <KnowledgeGraphCard>
              <CardHeader>
                <CardIcon>
                  <FaBuilding />
                </CardIcon>
                <CardTitle>關聯機構</CardTitle>
              </CardHeader>
              {getCardContent(knowledgeGraph && knowledgeGraph.organizations, false)}
            </KnowledgeGraphCard>
            <KnowledgeGraphCard>
              <CardHeader>
                <CardIcon>
                  <FaProjectDiagram />
                </CardIcon>
                <CardTitle>行業領域</CardTitle>
              </CardHeader>
              {getCardContent(knowledgeGraph && knowledgeGraph.industrialClasses, false)}
            </KnowledgeGraphCard>
            <KnowledgeGraphCard>
              <CardHeader>
                <CardIcon>
                  <FaTags />
                </CardIcon>
                <CardTitle>關鍵字</CardTitle>
              </CardHeader>
              {getCardContent(knowledgeGraph && knowledgeGraph.keywords, true)}
            </KnowledgeGraphCard>
          </KnowledgeGraphRow>
          <KnowledgeGraphCard last={true}>
            <CardHeader>
              <CardIcon>
                <FaIdCard />
              </CardIcon>
              <CardTitle>國際分類號/IPC</CardTitle>
            </CardHeader>
            {getCardContent(knowledgeGraph && knowledgeGraph.ipcs, false)}
          </KnowledgeGraphCard>
          {id ? (
            <></>
          ) : (
            <RelatedExpertContainer>
              <FaUserTie />
              相關專家：
              {isLoadingGraph ? (
                <MiniLoader />
              ) : knowledgeGraph ? (
                knowledgeGraph.relatedExperts.map(item => (
                  <RelatedExpert key={item.name} linkable={!!item.id}>
                    {item.id ? <Link to={`/expert/${item.id}`}>{item.name}</Link> : item.name}
                  </RelatedExpert>
                ))
              ) : (
                <NoResult>無法取得資料</NoResult>
              )}
              {id ? (
                <></>
              ) : isLoadingGraph ? (
                <></>
              ) : (
                <Button template="primary" onClick={showAllExperts}>
                  全部顯示
                </Button>
              )}
            </RelatedExpertContainer>
          )}
        </KnowledgeGraph>
      </OverView>
      {id ? <></> : <ExpertPatent expertId={expertId} expert={expert} />}
    </Container>
  ) : (
    <></>
  );
};

export default ExpertDetail;
