import React, { useState, useEffect, useRef } from 'react';
import * as d3 from 'd3';
import styled from 'styled-components';
import { IFacetFieldCount } from 'src/types/PatentSearchTypes';
import { white } from 'src/style/theme/Color';
import Tooltip from './Tooltip';
import { numFormatter } from 'src/utils/Formatter';
import { toEllipsis } from './chartHelper';
import { svgId } from 'src/utils/svgDownloadUtils';

const ToolTipOverlay = styled.div`
  text-align: center;
  color: ${white};
`;

const width = 800;
const height = 420;
const padding = 20;
const outerRadius = Math.min(width - padding, height - padding) / 2.5;
const innerRadius = 0;

// TODO: 與主題知識地圖共用色系
const colors = [
  '#ca0b38',
  '#e64f43',
  '#ffc28c',
  '#fbe7b4',
  '#acdfb2',
  '#077790',
  '#7dd3e2',
  '#7e5aad',
  '#5f686c',
  '#7c4141',
];
// 最多需 30 個色塊 (若包含其他則最多 31 個)
const color = [...colors, ...d3.schemeAccent, ...d3.schemePaired, '#4682b4'];

interface IProps {
  fieldName: string;
  dataset: Array<IFacetFieldCount>;
}

interface ITooltip extends IFacetFieldCount {
  left: number;
  top: number;
}

// TODO: 簡化程式邏輯
/** 圓餅圖 */
const PieChart: React.FC<IProps> = ({ fieldName, dataset }) => {
  const [tooltip, setTooltip] = useState<ITooltip>();
  const [adjustedHeight, setAdjustedHeight] = useState<number>(height);

  const svgRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    const svg = d3.select(svgRef.current);

    const pie = d3
      .pie<IFacetFieldCount>()
      .value((d: IFacetFieldCount) => d.count)
      .sort(null);

    const arc = d3
      .arc<d3.PieArcDatum<IFacetFieldCount>>()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius);

    // 圓弧 Group
    const arcG = svg
      .selectAll('.arc')
      .data(pie(dataset))
      .enter()
      .append('g')
      .attr('class', 'arc')
      .attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')');

    // 連接線 Group (為了讓線和扇形重疊時不被扇形蓋住，連接線用另外的Group放)
    const lingG = svg
      .selectAll('.arc-line')
      .data(pie(dataset))
      .enter()
      .append('g')
      .attr('class', 'arc-line')
      .attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')');

    // 避免文字重疊，先在一開始把所有位置都計算好再畫圖
    const arcInfoList: Array<{ textAnchor: string; posX: number; posY: number }> = [];
    // (讓兩行文字布置於重疊的) 最小文字距離
    const labelSpacing = 15; // 用"font-size:13px"去測得之不讓文字重疊大略間距

    arcG.each((d, i) => {
      // 扇形角度中間值
      const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
      // 文字起始方向。 0 ~ 180 度 由左至右 (start)； 180 ~ 360 度 由右至左 (end)
      const currentTextAnchor = midangle <= Math.PI ? 'start' : 'end';
      const isRightSide = currentTextAnchor === 'start';
      // 原始的文字起始座標
      let currentX = arc.centroid(d)[0] * 2.3;
      let currentY = arc.centroid(d)[1] * 2.3;

      // 第0筆直接紀錄資訊，第1筆之後和前一筆做比較
      if (i > 0) {
        const prevArc = arcInfoList[i - 1];
        const isSameSide = currentTextAnchor === prevArc.textAnchor;
        let dist = 0;

        // 同側文字才比較是否重疊
        if (isSameSide) {
          // 比較Y座標差值
          const delta = currentY - prevArc.posY;
          if (isRightSide) {
            // 右側文字由上至下(Y座標由負到正)
            // 差值大於零才視為有意義的距離。否則視為dist不設定(仍為0)
            // (若差值為負值，表示前面可能有連續多筆文字重疊導致遞移後第i-1筆Y座標超過第i筆資料原始Y座標。)
            delta > 0 && (dist = delta);
          } else {
            // 左側文字由下至上(Y座標由正到負)，其餘同上。
            delta < 0 && (dist = -delta);
          }

          // 相鄰兩筆文字距離小於設定間距時，順向遞移座標
          if (dist < labelSpacing) {
            if (isRightSide) {
              currentY = prevArc.posY + labelSpacing;

              // 右下半圓的文字，考量連接線、靠近6點鐘方向極端情況的呈現，所以X座標往外部移動，
              if (currentY > 0) {
                currentX = prevArc.posX + 1;
              } else if (
                Math.sqrt(Math.pow(currentX, 2) + Math.pow(currentY, 2)) <=
                outerRadius * 1.1
              ) {
                // 位移後距離圓心小於半徑的文字，往外平移適當距離
                currentX = Math.sqrt(Math.pow(outerRadius * 1.2, 2) - Math.pow(currentY, 2));
              }
            } else {
              currentY = prevArc.posY - labelSpacing;

              if (currentY < 0) {
                currentX = prevArc.posX - 1;
              } else if (
                Math.sqrt(Math.pow(currentX, 2) + Math.pow(currentY, 2)) <=
                outerRadius * 1.1
              ) {
                currentX = -Math.sqrt(Math.pow(outerRadius * 1.2, 2) - Math.pow(currentY, 2));
              }
            }
          }
        }
      }

      // 紀錄扇形位置資訊
      arcInfoList.push({
        textAnchor: currentTextAnchor,
        posX: currentX,
        posY: currentY,
      });
    });

    // 文字位置計算完畢後，重新定義SVG高度與位移
    const posYs = arcInfoList.map(item => item.posY);
    const maxY = d3.max(posYs); // 最下方的文字Y座標
    const minY = d3.min(posYs); // 最上方的文字Y座標
    // 若最上方/最下方的文字超出SVG原本高度的一半，高度增加兩者間的差距
    // 因文字Y座標是從文字方塊的左下角開始，以及瀏覽實際顯示上的一些些微差距，
    // 計算時需多考慮一小段空間(此處使用labelSpacing)，才能讓文字完整呈現。
    if (maxY && minY) {
      let newHeight = height;
      if (maxY + labelSpacing > height / 2) {
        newHeight += maxY + labelSpacing - height / 2;
      }
      if (Math.abs(minY) + labelSpacing > height / 2) {
        newHeight += Math.abs(minY) + labelSpacing - height / 2;
        arcG.attr(
          'transform',
          'translate(' + width / 2 + ',' + (Math.abs(minY) + labelSpacing) + ')',
        );
        lingG.attr(
          'transform',
          'translate(' + width / 2 + ',' + (Math.abs(minY) + labelSpacing) + ')',
        );
      }
      setAdjustedHeight(newHeight);
    }

    // 扇形
    arcG
      .append('path')
      .attr('fill', (d, i) => color[i].toString())
      .attr('d', arc)
      .on('mouseover', (d, i, nodes) => {
        d3.select(nodes[i]).style('opacity', 0.5);
        setTooltip({
          left: d3.event.pageX - window.scrollX,
          top: d3.event.pageY - window.scrollY - 16,
          name: d.data.name,
          count: d.data.count,
        });
      })
      .on('mousemove', d => {
        setTooltip({
          left: d3.event.pageX - window.scrollX,
          top: d3.event.pageY - window.scrollY - 16,
          name: d.data.name,
          count: d.data.count,
        });
      })
      .on('mouseout', (d, i, nodes) => {
        d3.select(nodes[i]).style('opacity', 1);
        setTooltip(undefined);
      });

    // 連接線
    lingG
      .append('polyline')
      .attr('fill', 'none')
      .attr('stroke', (d, i) => color[i].toString())
      .attr('points', (d, i) => {
        // 讓連接線的終點與文字保有一小段距離的調整
        const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
        const offset = 5;
        const xOffset = midangle <= Math.PI ? -offset : offset;

        let lineEndX = arcInfoList[i].posX + xOffset;
        const lineEndY = arcInfoList[i].posY - offset;
        const lineMidX = arc.centroid(d)[0] * 2.1;
        // 避免調整後的位置數值比中間點還小，導致線段往反方向延伸。
        if (Math.abs(lineEndX) < Math.abs(lineMidX)) {
          lineEndX = lineMidX;
        }

        return [
          [arc.centroid(d)[0] * 2, arc.centroid(d)[1] * 2],
          [lineMidX, arc.centroid(d)[1] * 2.1],
          [lineEndX, lineEndY],
        ] as any;
      });

    // 分析項目與數值百分比
    arcG
      .append('text')
      .attr(
        'transform',
        (d, i) => 'translate(' + arcInfoList[i].posX + ',' + arcInfoList[i].posY + ')',
      )
      .style('font-size', '13px')
      .style('text-anchor', (d, i) => arcInfoList[i].textAnchor)
      .text(d => {
        const percent = (Number(d.value) / d3.sum(dataset, d => d.count)) * 100;
        return toEllipsis(d.data.name, 10, 0) + ' : ' + percent.toFixed(1) + '%';
      });
  }, [fieldName, dataset]);

  return (
    <>
      <svg id={svgId} ref={svgRef} width={width} height={adjustedHeight} />
      {tooltip && (
        <Tooltip
          left={tooltip.left}
          top={tooltip.top}
          overlay={
            <ToolTipOverlay>
              <div>{tooltip.name}</div>
              <div>專利數量:&nbsp;{numFormatter(tooltip.count)}</div>
            </ToolTipOverlay>
          }
        />
      )}
    </>
  );
};

export default PieChart;
