import { ActionCreator } from 'redux';
import { MEMBER_DECODE_TOKEN, IReduxMemberActionType } from './type';

import { Auth } from 'src/apps/auth/Auth';

export const getMemberAuthAndPayloadFromJwt: ActionCreator<IReduxMemberActionType> = () => {
  return {
    type: MEMBER_DECODE_TOKEN,
    payload: Auth().getPayload(),
    authData: Auth().getAuthData(),
  };
};
