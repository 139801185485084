export const zTooltip = 999;
export const zModal = {
  alert: 990,
  redirect: 980,
  login: 970,
  custom: 960,
};
export const zMatrix = 910;
export const zMobileDropdownMenu = 910; // 手機版下拉選單，必須高於 ScrollToTop。
export const zCookieFooter = 900; // 包含 ScrollToTop
export const zTopNav = 700;
export const zToolbar = 600;

/* 各頁面/區塊 z-index 設定區域開始 */
/* 可設定範圍 0 - 200 */

// Header - TopNav
export const zProfile = 200;

/* 各頁面/區塊 z-index 設定區域結束 */
