import React, { useState, useEffect } from 'react';
import { FaAngleDown, FaTimes } from 'react-icons/fa';
import styled, { css } from 'styled-components';
import { IGetPatentOcrLabelTypes, PartLabel } from 'src/types/api/PatentApiTypes';
import { black1, blue1, blue2, blue3, blue5rgba, blue6rgba, white } from 'src/style/theme/Color';
import Checkbox from 'src/components/ui/interactive/checkbox/Checkbox';

const scrollbarStyle = css`
  /* width */
  ::-webkit-scrollbar {
    width: 2px;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${blue1};
    border-radius: 10px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${blue3};
  }
`;
const Container = styled.div`
  background-color: ${white};
  margin-top: auto;
  bottom: 0px;
  width: 100%;
`;

const Header = styled.div<{ isShow: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${props => (props.isShow ? 'space-between' : 'center')};
  color: ${black1};
  padding: 10px 16px;
  :hover {
    cursor: pointer;
  }
`;

const Title = styled.div`
  margin-left: 8px;
`;

export const Panel = styled.div<{ isShow: boolean; height?: string }>`
  margin: 0 16px 8px 0;
  display: ${props => (props.isShow ? 'block' : 'none')};
  max-height: 25vh;
  overflow-x: hidden;
  overflow-y: auto;
  ${scrollbarStyle}
`;

const List = styled.div`
  padding: 8px;
`;

const LabelItem = styled.div<{ isActive?: boolean; isCurrent: boolean }>`
  display: flex;
  width: 100%;
  padding: 10px 16px;
  background-color: ${props => (props.isActive ? blue5rgba(0.5) : blue5rgba(0))};
  font-weight: ${props => (props.isCurrent ? 'bold' : 'unset')};

  :hover {
    background-color: ${blue6rgba(0.5)};
  }
  div:nth-child(odd) {
    width: 20%;
  }
  div:nth-child(even) {
    width: 80%;
  }
`;
const ItemTitle = styled.div<{ isNotExist?: boolean }>`
  color: ${props => (props.isNotExist ? black1 : blue3)};
`;
const ItemContent = styled.div`
  color: ${black1};
`;

/** 找出第一張有指定圖說的圖片。 */
export const getIsExistCode = (code: string, data: IGetPatentOcrLabelTypes) => {
  let result = null;
  for (var i = 0; i < data.images.length; i++) {
    let img = data.images[i];
    for (var j = 0; j < img.expectedPartCoords.length; j++) {
      if (img.expectedPartCoords[j].afterLabel === code) {
        result = i;
        break;
      }
    }
    if (result !== null) {
      break;
    }
  }
  return result;
};
interface IProps {
  title?: string;
  data: IGetPatentOcrLabelTypes;
  currentImage: number;
  showDescLabel: string;
  setShowDescLabel: (showDescLabel: string) => void;
  isOpen: boolean;
  setIsOpen: (isShowAll: boolean) => void;
}

/** 專利圖示元件-摺疊面板 */
const PatentImageAccordion: React.FC<IProps> = props => {
  const { title, data, currentImage, showDescLabel, setShowDescLabel, isOpen, setIsOpen } = props;
  const [currentImageLabels, setCurrentImageLabels] = useState<string[]>([]);
  const [isShowAll, setIsShowAll] = useState<boolean>(false);
  const [currentShowLabels, setCurrentShowLabels] = useState<Array<PartLabel>>([]);
  const [notExistCodes, setNotExistCodes] = useState<string[]>([]);

  useEffect(() => {
    setNotExistCodes(
      data.partLabels
        .filter(item => getIsExistCode(item.code, data) === null)
        .map(item => item.code),
    );
  }, [data]);

  useEffect(() => {
    const imgLabels = data.images[currentImage].expectedPartCoords.map(item => item.afterLabel);
    setCurrentImageLabels(imgLabels);
    setCurrentShowLabels(data.partLabels.filter(item => imgLabels.includes(item.code)));
  }, [currentImage, data.images, data.partLabels]);

  // 切換當前是否顯示所有標籤
  useEffect(() => {
    if (isShowAll) {
      setCurrentShowLabels(data.partLabels);
    } else {
      setCurrentShowLabels(data.partLabels.filter(item => currentImageLabels.includes(item.code)));
    }
  }, [isShowAll, data.partLabels, currentImageLabels]);

  // 點擊圖說後，清單顯示到該圖說的位置
  useEffect(() => {
    let target = document.getElementsByClassName(showDescLabel)[0];
    target && target.scrollIntoView(true);
  }, [showDescLabel]);

  const togglePanel = (isActive: boolean) => {
    setIsOpen(isActive);
  };

  const handleCheckbox = () => {
    setIsShowAll(!isShowAll);
  };

  return (
    <Container>
      {
        <Header isShow={isOpen} onClick={() => !isOpen && togglePanel(true)}>
          {isOpen ? (
            <>
              <Checkbox
                name={'顯示全部元件'}
                checked={isShowAll}
                color={isShowAll ? blue2 : black1}
                isBold={isShowAll ? true : false}
              >
                <input type="checkbox" checked={isShowAll} onChange={() => handleCheckbox()} />
              </Checkbox>
              <FaTimes size={20} onClick={() => togglePanel(false)} />
            </>
          ) : (
            <>
              <FaAngleDown size={20} /> <Title>{title}</Title>
            </>
          )}
        </Header>
      }

      <Panel isShow={isOpen}>
        <List>
          {currentShowLabels.map(item => {
            return (
              <LabelItem
                key={item.code}
                isCurrent={currentImageLabels.includes(item.code)}
                onClick={() => setShowDescLabel(item.code)}
                isActive={!notExistCodes.includes(item.code) && item.code === showDescLabel}
              >
                <ItemTitle className={item.code} isNotExist={notExistCodes.includes(item.code)}>
                  {item.code}
                </ItemTitle>
                <ItemContent>{item.description}</ItemContent>
              </LabelItem>
            );
          })}
        </List>
      </Panel>
    </Container>
  );
};

export default PatentImageAccordion;
