import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import ItemsCarousel from 'react-items-carousel';
import {
  white,
  ultrablackrgba,
  blue3,
  iceblue2,
  iceblue6,
  blue5rgba,
  ultrawhite,
} from 'src/style/theme/Color';
import ImageMapper from 'react-img-mapper';
import PatentImageAccordion, { getIsExistCode } from './PatentImageAccordion';
import Tooltip from 'src/components/trend/industrialTrends/chart/Tooltip';
import Draggable from 'react-draggable';

const ModalContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 4px;
  overflow: hidden;
  background-color: ${ultrablackrgba(0.8)};
`;
const Backdrop = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  @media print {
    height: auto;
    position: absolute;
    background-color: #ffffff;
  }
`;
const Spinner = styled.div`
  border: 16px solid ${iceblue2};
  border-top: 16px solid ${iceblue6};
  border-radius: 50%;
  margin: 64px 128px;
  width: 120px;
  height: 120px;
  animation: spin 1.5s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
const maxHeight = window.innerHeight * 0.6;
const DisplayImage = styled.div`
  text-align: center;
  margin-top: 38px;
  user-select: none;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${props => (props.isOpen ? maxHeight - 160 + 'px' : maxHeight + 'px')};
  width: 100%;
`;
const ImageContainer = styled.div`
  max-height: ${maxHeight + 'px'};
  max-width: 100%;
  area {
    cursor: pointer;
  }
`;
const Thumbnails = styled.div`
  padding: 0 10px;
  p {
    text-align: center;
    color: ${iceblue6};
    font-size: 10pt;
  }
  @media print {
    display: none;
  }
`;
const ThunbnailContainer = styled.div`
  width: ${props => props.width + 'px'};
  margin: auto;
`;
const Thunbnail = styled.div`
  display: inline-block;
  margin: 2px;
  border-radius: 2px;
  width: 58px;
  height: 60px;
  background-color: #ffffff;
  background-image: ${props => 'url(' + props.bgImg + ')'};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border: ${props => props.active && `2px solid ${blue3}`};
  cursor: pointer;
`;
const ToolTipOverlay = styled.div`
  color: ${ultrawhite};
  display: flex;
`;
const defaultImageLabelMap = {
  shape: 'rect',
  active: false,
  disabled: false,
  fillColor: 'rgba(255, 255, 255, 0)',
  strokeColor: 'rgba(255, 255, 255, 0)',
  preFillColor: blue5rgba(0.5),
};
const expectedTitle = 'expected';
const defaultPosition = { x: 0, y: 0 };

const ImageModal = ({
  images /* string[], 圖片url */,
  imageOnDisplay /* number, 開啟時預設顯示的圖片 */,
  isOpen /* boolean, 是否開啟lightbox */,
  showThumbnails /* boolean, 是否顯示縮圖 */,
  data /* IGetPatentOcrLabelTypes, 圖示元件標記資訊 */,
  setAddSize /* (number)=>void, 設定圖片調整大小 */,
  addSize /* number, 圖片調整大小 */,
}) => {
  const numOfImg = images ? images.length : 0;
  const thumbnailsWidth = numOfImg > 5 ? 330 : 330 - (5 - numOfImg) * 62; // 5張縮圖時寬度330，每少一張減62

  const [currentImage, setCurrentImage] = useState(0);
  // 縮圖的當前圖片，因套件預設為依照activeItemIndex切換視窗框顯示，故若希望切換縮圖顯示但不切換圖片則需另外紀錄縮圖視窗activeItem
  const [currentCarouselImage, setCurrentCarouselImage] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(0);
  const [labels, setLabels] = useState([]);
  // 輸入套件的資料
  const [map, setMap] = useState({
    name: 'patent-img-map',
    areas: labels,
  });
  // 設置初始寬度
  const [imgWidth, setImgWidth] = useState(window.innerWidth * 0.4 - 144);
  const [showDescLabel, setShowDescLabel] = useState('');
  const [showOtherPageLabel, setShowOtherPageLabel] = useState('');
  const [tooltips, setTooltips] = useState([]);
  const [isAccordionOpen, setIsAccordionOpen] = useState(0);
  const [currentImageLoaded, setCurrentImageLoaded] = useState(0);
  const [pos, setPos] = useState(defaultPosition);

  /** 設置顯示圖說標籤 */
  const handleShowDesc = useCallback(
    (currentIdx, code, indices) => {
      if (data) {
        // 取得該順序的area element
        const initialWidth = Number(data.images[currentIdx].width);
        const showDescAreas = data.images[currentIdx].expectedPartCoords;
        // 取得上層元素的絕對位置
        const parentImg = document.getElementById('img-mapper');
        const parentImgCoords = parentImg && parentImg.getBoundingClientRect();
        // 取得圖說
        const currentLabel = data.partLabels.filter(label => label.code === code);
        // 添加tooltip
        let addTooltips = [];
        for (let i = 0; i < showDescAreas.length; i++) {
          if (indices.includes(i)) {
            let coords = showDescAreas[i].coords;
            // 上層元素絕對位置 + area座標 + 微調
            const ratio = Number(imgWidth / initialWidth);
            const top = Number(parentImgCoords.top) + Number(coords[3]) * ratio + 8;
            const left =
              Number(parentImgCoords.left) + ((Number(coords[2]) + Number(coords[0])) * ratio) / 2;
            addTooltips.push(getToolTip(currentLabel, left, top));
          }
        }
        setTooltips(addTooltips);
      }
    },
    [data, imgWidth],
  );

  /** 取得須顯示圖說之標籤 */
  const getDescAreaIndex = useCallback(
    code => {
      let indice = [];
      labels.forEach((item, index) => {
        if (item.title === expectedTitle && item.name === code) {
          indice.push(index);
        }
      });
      return indice;
    },
    [labels],
  );

  /** 計算圖片大小 */
  const getImgWidth = useCallback(
    add => {
      const maxModalHeight = isAccordionOpen ? maxHeight - 160 : maxHeight;
      const maxModalWidth = window.innerWidth * 0.4 - 144;
      let initialWidth;
      let initialHeight;
      if (currentImageLoaded) {
        const imgElement = document.getElementById('img-mapper');
        if (imgElement) {
          initialWidth = Number(imgElement.clientWidth);
          initialHeight = Number(imgElement.clientHeight);
        }
        let maxWidth = maxModalWidth;
        if ((initialHeight * maxModalWidth) / initialWidth > maxModalHeight) {
          maxWidth = (initialWidth * maxModalHeight) / initialHeight;
        }
        setImgWidth(maxWidth * add);
      }
    },
    [isAccordionOpen, currentImageLoaded],
  );

  useEffect(() => {
    setCurrentImage(imageOnDisplay);
  }, [imageOnDisplay]);

  useEffect(() => {
    resetTooltips();
    getImgWidth(addSize);
  }, [currentImage, getImgWidth, addSize]);

  // preload image
  useEffect(() => {
    setImageLoaded(false);
    const img = new Image();
    img.src = images[Number(currentImage)];
    img.onLoad = setImageLoaded(true);
  }, [currentImage, images]);

  useEffect(() => {
    if (currentImageLoaded && data) {
      const currentImgInfo = data.images[currentImage];
      // 插入標籤
      const expectedLabels = currentImgInfo.expectedPartCoords.map(item => {
        return item.afterLabel
          ? {
              ...defaultImageLabelMap,
              preFillColor: blue5rgba(0.5),
              title: expectedTitle,
              name: item.afterLabel,
              coords: item.coords.map(coord => Number(coord)),
            }
          : null;
      });
      // 目前非預期欄位改為不標示，若要標示需再加上currentImgInfo.unexpectedPartCoords的標籤
      const allLabels = expectedLabels.filter(item => item !== null);
      setLabels(allLabels);
    }
  }, [currentImage, data, currentImageLoaded]);

  // 使label變化立即改變map
  useEffect(() => {
    setMap({ name: 'patent-img-map', areas: labels });
  }, [labels]);

  const handleDrag = (e, position) => {
    e.preventDefault();
    const { x, y } = position;
    setPos({ x, y });
  };

  /** 重置圖說toolTip。 */
  const resetTooltips = () => {
    setShowDescLabel('');
    setTooltips([]);
  };

  const initializeImage = useCallback(() => {
    resetTooltips();
    setAddSize(1);
    setPos(defaultPosition);
    setCurrentImageLoaded(false);
  }, [setAddSize]);

  const gotoPrevious = useCallback(() => {
    initializeImage();
    currentImage > 0 && setCurrentImage(Number(currentImage) - 1);
  }, [currentImage, initializeImage]);

  const gotoNext = useCallback(() => {
    initializeImage();
    currentImage < numOfImg - 1 && setCurrentImage(Number(currentImage) + 1);
  }, [currentImage, numOfImg, initializeImage]);

  const gotoImage = useCallback(
    index => {
      initializeImage();
      setCurrentImage(index);
    },
    [initializeImage],
  );

  // 設定Tooltip
  const getToolTip = (label, left, top) => {
    return {
      left: left,
      top: top,
      code: label[0].code,
      description: label[0].description,
    };
  };

  // 監聽鍵盤事件
  useEffect(() => {
    const keyboardFunction = e => {
      if (isOpen) {
        switch (e.keyCode) {
          // 左方向鍵: 上一頁
          case 37:
            gotoPrevious();
            break;
          // 右方向鍵: 下一頁
          case 39:
            gotoNext();
            break;
          default:
            break;
        }
      }
    };
    document.addEventListener('keydown', keyboardFunction, false);

    return () => {
      document.removeEventListener('keydown', keyboardFunction, false);
    };
  }, [isOpen, gotoPrevious, gotoNext]);

  // 選擇顯示圖說的標籤旁邊顯示tooltip
  useEffect(() => {
    // 從data中取得指定顯示的標籤的位置
    if (showDescLabel) {
      const indices = getDescAreaIndex(showDescLabel);
      if (indices.length === 0) {
        //去找別的圖片中有該圖說的
        const toPage = getIsExistCode(showDescLabel, data);
        if (toPage !== null) {
          setShowOtherPageLabel(showDescLabel);
          gotoImage(toPage);
        } else {
          setTooltips([]);
        }
      } else {
        handleShowDesc(currentImage, showDescLabel, indices);
      }
    }
  }, [showDescLabel, data, currentImage, gotoImage, handleShowDesc, getDescAreaIndex]);

  useEffect(() => {
    if (currentImageLoaded && showOtherPageLabel) {
      setShowDescLabel(showOtherPageLabel);
      setShowOtherPageLabel('');
    }
  }, [currentImageLoaded, showOtherPageLabel]);

  return (
    isOpen && (
      <ModalContainer>
        <Backdrop isOpen={isAccordionOpen}>
          <DisplayImage onLoad={() => setImageLoaded(true)} isOpen={isAccordionOpen}>
            {imageLoaded && (
              <Draggable
                defaultPosition={defaultPosition}
                position={pos}
                onDrag={handleDrag}
                onStart={() => {
                  resetTooltips();
                }}
              >
                <ImageContainer>
                  <ImageMapper
                    src={images[currentImage]}
                    map={map}
                    areaKeyName={'id'}
                    responsive={true}
                    parentWidth={imgWidth}
                    onClick={area =>
                      data && area.title === expectedTitle && setShowDescLabel(area.name)
                    }
                    onLoad={() => {
                      setCurrentImageLoaded(true);
                    }}
                  />
                </ImageContainer>
              </Draggable>
            )}
            {!imageLoaded && <Spinner />}
          </DisplayImage>
          {showThumbnails && (
            <Thumbnails>
              <p>{Number(currentImage) + 1 + ' of ' + numOfImg}</p>
              <ThunbnailContainer width={thumbnailsWidth}>
                <ItemsCarousel
                  gutter={0}
                  activePosition={'center'}
                  chevronWidth={36}
                  numberOfCards={numOfImg > 5 ? 5 : numOfImg}
                  slidesToScroll={2}
                  outsideChevron={true}
                  activeItemIndex={Number(currentCarouselImage)}
                  requestToChangeActive={value => setCurrentCarouselImage(value)}
                  rightChevron={<FaAngleRight size={'32'} color={white} />}
                  leftChevron={<FaAngleLeft size={'32'} color={white} />}
                >
                  {images.map((image, idx) => (
                    <Thunbnail
                      key={idx}
                      bgImg={image}
                      active={currentImage === idx}
                      onClick={() => gotoImage(idx)}
                    />
                  ))}
                </ItemsCarousel>
              </ThunbnailContainer>
            </Thumbnails>
          )}

          {data && (
            <PatentImageAccordion
              title="圖式元件符號"
              data={data}
              currentImage={currentImage}
              showDescLabel={showDescLabel}
              setShowDescLabel={setShowDescLabel}
              isOpen={isAccordionOpen}
              setIsOpen={setIsAccordionOpen}
            />
          )}
        </Backdrop>
        {tooltips &&
          tooltips.map((tooltip, index) => {
            return (
              <Tooltip
                key={index}
                left={tooltip.left}
                top={tooltip.top}
                placement={'bottom'}
                overlay={
                  <ToolTipOverlay>
                    <div>{tooltip.code + '：'}</div>
                    <div>{tooltip.description}</div>
                  </ToolTipOverlay>
                }
              />
            );
          })}
      </ModalContainer>
    )
  );
};

export default ImageModal;
