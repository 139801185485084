import React, { CSSProperties } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  z-index: 1;
  top: 96px;
  right: 40px;
  position: fixed;
`;

const GeneralStyle = styled.div`
  margin: 0 0 8px;
  padding: 16px;
  border-radius: 4px;
  color: #ffffff;
  line-height: 20px;
  > div:hover {
    cursor: pointer;
  }
`;
const RedFunctions = styled(GeneralStyle)`
  box-shadow: 0 5px 20px -5px rgba(0, 63, 84, 0.4);
  background-image: linear-gradient(to right, #f5406f, #bf244d 100%);
`;
const BlueFunctions = styled(GeneralStyle)`
  box-shadow: 0 5px 20px -5px rgba(0, 63, 84, 0.3);
  background-image: linear-gradient(127deg, #1f8ba3, #323846);
`;
const CustomizeFunctions = styled(RedFunctions)<{
  backgroundImage: CSSProperties['backgroundImage'];
  boxShadow: CSSProperties['boxShadow'];
}>`
  box-shadow: ${props => props.boxShadow};
  background-image: ${props => props.backgroundImage};
`;

export enum ToolbarTheme {
  BLUE = 'blue',
  RED = 'red',
  CUSTOMIZE = 'customize',
}

export interface IToolbarFunctionProps {
  backgroundImage?: CSSProperties['backgroundImage'];
  boxShadow?: CSSProperties['boxShadow'];
  component: JSX.Element;
  theme: ToolbarTheme;
}

interface IProps {
  redFuncs?: JSX.Element;
  blueFuncs?: JSX.Element;
  functions?: Array<IToolbarFunctionProps>;
}

const rightFloatToolbar: React.FC<IProps> = ({ redFuncs, blueFuncs, functions }) => (
  <Container>
    {redFuncs && <RedFunctions>{redFuncs}</RedFunctions>}
    {blueFuncs && <BlueFunctions>{blueFuncs}</BlueFunctions>}
    {functions &&
      functions.map((func, index) => {
        switch (func.theme) {
          case ToolbarTheme.BLUE:
            return <BlueFunctions key={index}>{func.component}</BlueFunctions>;
          case ToolbarTheme.RED:
            return <RedFunctions key={index}>{func.component}</RedFunctions>;
          case ToolbarTheme.CUSTOMIZE:
            return (
              <CustomizeFunctions
                key={index}
                boxShadow={func.boxShadow}
                backgroundImage={func.backgroundImage}
              >
                {func.component}
              </CustomizeFunctions>
            );

          default:
            return <RedFunctions key={index}>{func.component}</RedFunctions>;
        }
      })}
  </Container>
);

export default rightFloatToolbar;
