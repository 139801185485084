import React, { useState } from 'react';
import styled from 'styled-components';
import { ultrawhite, ultrawhitergba, ultrablackrgba } from 'src/style/theme/Color';
import { SearchBar } from 'src/components/news/SearchBar';
import Filter from 'src/components/news/left-menu/Filter';
import {
  IAdvancedKeyword,
  IRssSearchSuggestion,
  IFacetFieldCount,
} from 'src/types/api/NewsApiTypes';

const Container = styled.div`
  border-radius: 4px;
  box-shadow: 0 0 50px 0 ${ultrablackrgba(0.1)};
  position: relative;
`;
const Header = styled.div`
  width: 100%;
  padding-bottom: 24px;
  display: flex;
  flex-flow: row;
  color: ${ultrawhite};
  background-image: linear-gradient(95deg, #2ec3c2, #0cafcc);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;
const HeaderText = styled.div`
  width: 100%;
  padding: 16px 32px;
  position: relative;
`;
const HeaderRoute = styled.div`
  color: ${ultrawhitergba(0.8)};
`;
const Feature = styled.div`
  margin: 4px 0;
  height: 36px;
  line-height: 36px;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  align-items: flex-end;
  title {
    padding: 0 0 0 8px;
    display: block;
    font-size: 15px;
    line-height: 24px;
    font-weight: normal;
  }
`;
const Subheaders = styled.div<{ displayFilter: boolean }>`
  padding-left: ${props => (props.displayFilter ? '272px' : '72px')};
  padding-top: 30px;
  height: 60px;
  display: flex;
  align-items: center;
  color: #979ea1;
  background-color: #edf6f7;
`;
const Title = styled.div`
  padding: 0 0 8px 32px;
  width: 55%;
  font-size: 16px;
`;
const Body = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 0 0 24px;
  min-height: 450px;
`;
const Content = styled.div<{ displayFilter: boolean }>`
  padding: 0 0 24px;
  min-height: 450px;
  width: calc(100% - ${props => (props.displayFilter ? '272px' : '72px')});
`;

interface IProps {
  tags: Array<IRssSearchSuggestion>;
  sector: string;
  queryString: string;
  children: JSX.Element;
  facets: Array<any>;
  isFacetLoading: boolean;
  advanceKeywords: Array<IAdvancedKeyword>;
  search: (queryString: string) => void;
  handleFacetClick: (keyCode: string, facetFieldCount?: IFacetFieldCount) => void;
  handleSuggestionClick: (suggestion?: IRssSearchSuggestion) => void;
  handleTagCancel: (type: string, value: string) => void;
}

const ResultContent: React.FC<IProps> = props => {
  const [displayFilter, setDisplayFilter] = useState<boolean>(false);

  return (
    <Container>
      <Header>
        <HeaderText>
          <HeaderRoute>首頁&nbsp;/&nbsp;{props.sector}&nbsp;/&nbsp;新聞搜尋</HeaderRoute>
          <Feature>新聞搜尋</Feature>
          <SearchBar
            tags={props.tags}
            queryString={props.queryString}
            handleTagCancel={props.handleTagCancel}
            search={props.search}
            handleSuggestionClick={props.handleSuggestionClick}
          />
        </HeaderText>
      </Header>
      <Subheaders displayFilter={displayFilter}>
        <Title>新聞內容</Title>
      </Subheaders>
      <Body>
        <Filter
          isShow={displayFilter}
          handleDisplay={setDisplayFilter}
          isLoading={props.isFacetLoading}
          facets={props.facets}
          handleFacetClick={props.handleFacetClick}
          advanceKeywords={props.advanceKeywords}
        />
        <Content displayFilter={displayFilter}>{props.children}</Content>
      </Body>
    </Container>
  );
};

export default ResultContent;
