import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { FaTimes, FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import ItemsCarousel from 'react-items-carousel';
import Portal from 'src/components/ui/interactive/Portal';

import { zModal } from 'src/style/theme/Z-Index';
import { white, ultrablackrgba, blue3, iceblue2, iceblue6 } from 'src/style/theme/Color';

const Backdrop = styled.div`
  position: fixed;
  z-index: ${zModal.custom};
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${ultrablackrgba(0.8)};
  display: flex;
  align-items: center;
  justify-content: center;
  @media print {
    height: auto;
    position: absolute;
    background-color: #ffffff;
  }
`;
const Chevron = styled.div`
  position: absolute;
  top: calc(50% - 32px);
  svg {
    height: 100%;
    width: 64px;
    color: ${white};
    cursor: pointer;
  }
`;
const ChevronLeft = styled(Chevron)`
  left: 0;
`;
const ChevronRight = styled(Chevron)`
  right: 0;
`;
const Spinner = styled.div`
  border: 16px solid ${iceblue2};
  border-top: 16px solid ${iceblue6};
  border-radius: 50%;
  margin: 64px 128px;
  width: 120px;
  height: 120px;
  animation: spin 1.5s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
const DisplayImage = styled.div`
  text-align: right;
  margin-bottom: 72px;
  user-select: none;
  svg {
    color: ${white};
    width: 24px;
    cursor: pointer;
  }
  p {
    color: ${iceblue6};
    font-size: 10pt;
  }
  img {
    max-width: 70vw;
    max-height: calc(100vh - 160px);
  }
`;
const Thumbnails = styled.div`
  position: absolute;
  left: calc(50% - ${props => props.width / 2 + 'px'});
  bottom: 8px;
  padding: 0 10px;
  width: ${props => props.width + 'px'}; /* 一定要寫寬度否則ItemsCarousel套件顯示會有問題 */
  @media print {
    display: none;
  }
`;
const Thunbnail = styled.div`
  display: inline-block;
  margin: 2px;
  border-radius: 2px;
  width: 58px;
  height: 60px;
  background-color: #ffffff;
  background-image: ${props => 'url(' + props.bgImg + ')'};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border: ${props => props.active && `2px solid ${blue3}`};
  cursor: pointer;
`;

const Lightbox = ({
  images /* string[], 圖片url */,
  imageOnDisplay /* number, 開啟時預設顯示的圖片 */,
  isOpen /* boolean, 是否開啟lightbox */,
  showThumbnails /* boolean, 是否顯示縮圖 */,
  onClose /* () => void, 關閉lightbox */,
}) => {
  const numOfImg = images ? images.length : 0;
  const thumbnailsWidth = numOfImg > 5 ? 330 : 330 - (5 - numOfImg) * 62; // 5張縮圖時寬度330，每少一張減62

  const [currentImage, setCurrentImage] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(0);
  const [origOverflow, setOrigOverflow] = useState('auto');

  // 取得頁面overflow設定，關閉lightbox才能恢復成原設定
  useEffect(() => {
    setOrigOverflow(window.getComputedStyle(document.body).overflow);
  }, []);

  useEffect(() => {
    setCurrentImage(imageOnDisplay);
  }, [imageOnDisplay]);

  // scroll lock
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }
  }, [isOpen]);

  // preload image
  useEffect(() => {
    setImageLoaded(false);
    const img = new Image();
    img.src = images[currentImage];
    img.onLoad = setImageLoaded(true);
  }, [currentImage, images]);

  const closeLightbox = useCallback(() => {
    document.body.style.overflow = origOverflow;
    onClose();
    setCurrentImage(0);
  }, [onClose, origOverflow]);

  const gotoPrevious = useCallback(() => {
    currentImage > 0 && setCurrentImage(currentImage => Number(currentImage) - 1);
  }, [currentImage]);

  const gotoNext = useCallback(() => {
    currentImage < numOfImg - 1 && setCurrentImage(currentImage => Number(currentImage) + 1);
  }, [currentImage, numOfImg]);

  const gotoImage = useCallback(index => {
    setCurrentImage(index);
  }, []);

  const handleClickImage = () => {
    if (currentImage < numOfImg - 1) {
      gotoNext();
    }
  };

  // 監聽鍵盤事件
  useEffect(() => {
    const keyboardFunction = e => {
      if (isOpen) {
        switch (e.keyCode) {
          // 左方向鍵: 上一頁
          case 37:
            gotoPrevious();
            break;
          // 右方向鍵: 下一頁
          case 39:
            gotoNext();
            break;
          // ESC: 關閉lightbox
          case 27:
            closeLightbox();
            break;
          default:
            break;
        }
      }
    };
    document.addEventListener('keydown', keyboardFunction, false);

    return () => {
      document.removeEventListener('keydown', keyboardFunction, false);
    };
  }, [isOpen, gotoPrevious, gotoNext, closeLightbox]);

  return (
    isOpen && (
      <Portal>
        <Backdrop>
          <ChevronLeft>
            {currentImage > 0 && <FaAngleLeft onClick={() => gotoPrevious()} />}
          </ChevronLeft>
          <DisplayImage>
            <div>
              <FaTimes size={'24'} color={white} onClick={closeLightbox} />
            </div>
            {imageLoaded && (
              <img
                src={images[currentImage]}
                alt={''}
                onClick={() => handleClickImage()}
                onLoad={() => setImageLoaded(true)}
              />
            )}
            {!imageLoaded && <Spinner />}
            <p>{Number(currentImage) + 1 + ' of ' + numOfImg}</p>
          </DisplayImage>
          <ChevronRight>
            {currentImage < numOfImg - 1 && <FaAngleRight onClick={() => gotoNext()} />}
          </ChevronRight>

          {showThumbnails && (
            <Thumbnails width={thumbnailsWidth}>
              <ItemsCarousel
                gutter={0}
                activePosition={'center'}
                chevronWidth={36}
                numberOfCards={numOfImg > 5 ? 5 : numOfImg}
                slidesToScroll={2}
                outsideChevron={true}
                activeItemIndex={currentImage}
                requestToChangeActive={value => setCurrentImage(value)}
                rightChevron={<FaAngleRight size={'32'} color={white} />}
                leftChevron={<FaAngleLeft size={'32'} color={white} />}
              >
                {images.map((image, idx) => (
                  <Thunbnail
                    key={idx}
                    bgImg={image}
                    active={currentImage === idx}
                    onClick={() => gotoImage(idx)}
                  />
                ))}
              </ItemsCarousel>
            </Thumbnails>
          )}
        </Backdrop>
      </Portal>
    )
  );
};

export default Lightbox;
