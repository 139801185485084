import React, { useState, useEffect } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import styled from 'styled-components';
import axios from 'axios';

import MobileColumnistArticleCard from './MobileColumnistArticleCard';
import InfiniteScroll from 'src/components/ui/interactive/InfiniteScroll';

import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import { SystemMessage } from 'src/apps/applicationMessages';
import { Spinner } from 'src/components/ui/interactive/Spinner';
import { blue2, blue8 } from 'src/style/theme/Color';
import { IGetColumnistArticle } from 'src/types/api/ColumnistArticleApiTypes';

const NoResult = styled.div`
  margin: 16px 32px;
  padding: 16px 32px;
  background-color: ${blue8};

  div {
    color: ${blue2};
    font-weight: 600;
    margin-bottom: 8px;
  }
`;

const ContentStyle = styled.div`
  padding: 0 0 16px;
  width: 100%;
`;

interface IProps {
  categoryId?: number;
}

const MobileColumnistArticle: React.FC<IProps> = ({ categoryId }) => {
  const [articles, setArticles] = useState<Array<IGetColumnistArticle>>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [noData, setNoData] = useState(false);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [isNextPageLoading, setIsNextPageLoading] = useState<boolean>(false);
  const [isNextPageError, setIsNextPageError] = useState<boolean>(false);
  const [pageNo, setPageNo] = useState<number>(1);

  const loadClickHandler = () => {
    unstable_batchedUpdates(() => {
      setIsNextPageLoading(true);
      setPageNo(pageNo + 1);
    });
  };
  const reloadClickHandler = () => {
    if (pageNo === 1) {
      setIsLoading(true);
    } else {
      setIsNextPageLoading(true);
    }
  };
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const fetchData = async () => {
      try {
        const res = await Api().get('article/columnist', {
          params: {
            pageNo: pageNo,
            categoryId: categoryId,
          },
          cancelToken: source.token,
        });

        setTotalPages(res.data.totalPages);
        setTotalCount(res.data.total);
        if (res.data.data && res.data.data.length > 0) {
          setArticles(art => [...(art || []), ...res.data.data]);
          isNextPageLoading && setIsNextPageError(false);
          setNoData(false);
        } else {
          setNoData(true);
        }
      } catch (error) {
        errorHandler(error);
        isNextPageLoading && setIsNextPageError(true);
      } finally {
        setIsLoading(false);
        setIsNextPageLoading(false);
      }
    };

    (isLoading || isNextPageLoading) && fetchData();

    return () => source.cancel();
  }, [pageNo, categoryId, isLoading, isNextPageLoading]);

  return (
    <ContentStyle>
      <>
        {isLoading && <Spinner width="150px" />}
        {!isLoading && noData ? (
          <NoResult>
            <div>{SystemMessage.COLUMNIST_ARTICLE_NO_DATA}</div>
          </NoResult>
        ) : (
          <InfiniteScroll
            isNextPageLoading={isNextPageLoading}
            isNextPageError={isNextPageError}
            errorMessage={'系統發生異常'}
            handleNextPageLoad={loadClickHandler}
            handleReload={reloadClickHandler}
            totalCount={totalCount}
            currentPage={pageNo}
            totalPages={totalPages}
            loadMorePadding={'0'}
          >
            {articles &&
              articles.map(item => <MobileColumnistArticleCard key={item.id} columnData={item} />)}
          </InfiniteScroll>
        )}
      </>
    </ContentStyle>
  );
};

export default MobileColumnistArticle;
