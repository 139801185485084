import axios from 'axios';
import { Api } from 'src/api/helpers/apiBase';
import { IGetPatentCaseList, IGetPatentTypes, IPatentBasicIntro } from 'src/types/IGetPatentTypes';
import {
  PatentDataTypes,
  IGetPatentFamilyTypes,
  IGetPatentCitedTypes,
  IGetPatentNoteTypes,
  IGetPatentCollectionCategoryTypes,
  IGetIpcTypes,
  IGetPatentNoteShare,
  IGetPtabTypes,
  IGetPatentOcrLabelTypes,
} from 'src/types/api/PatentApiTypes';

export const PatentService = () => {
  // 查詢專利內文
  const getPatentContent = (patentId: string) => {
    return Api()
      .get(`patent/${patentId}`)
      .then(res => {
        const data: IGetPatentTypes = res.data;
        return data;
      });
  };
  // 查詢雙欄比對
  const getPatentByNumber = (number: string) => {
    return Api()
      .get(`patent/number/${number}`)
      .then(res => {
        const data: IGetPatentTypes = res.data;
        return data;
      });
  };
  // 查詢IPC名稱
  const getIpc = async (patentData: IGetPatentTypes) => {
    let ipcName: string[] = [];
    let code: string[] = [];
    if (patentData.bibliographic && patentData.bibliographic.classificationIpc) {
      //取得代碼
      const ipc = patentData.bibliographic.classificationIpc;
      ipc.mainClassification && code.push(ipc.mainClassification);
      ipc.furtherClassification && (code = code.concat(ipc.furtherClassification));
      //取得名稱
      let nameArr = code.map(item => {
        return Api()
          .get('ipc?code=' + item)
          .then(res => {
            const ipcArr: IGetIpcTypes[] = res.data;
            return ipcArr[0]
              ? item.concat(' ', ipcArr[0].description.replace(new RegExp('‧', 'g'), ''))
              : item;
          });
      });
      ipcName = await axios.all(nameArr);
    }
    return ipcName;
  };

  return {
    //用UUID查詢專利資料與IPC分類
    getPatentData: async (patentId: string) => {
      const content = await getPatentContent(patentId);
      const ipc = await getIpc(content);
      const patentData: PatentDataTypes = {
        content: content,
        ipc: ipc,
      };
      return patentData;
    },
    //用申請號或公開號查詢專利資料與IPC分類
    getPatentDataByNumber: async (number: string) => {
      const content = await getPatentByNumber(number);
      const ipc = await getIpc(content);
      const patentData: PatentDataTypes = {
        content: content,
        ipc: ipc,
      };
      return patentData;
    },
    // 美國專利審查與上訴委員會資料 (PTAB)
    getPtab: (publicationNumber: string) => {
      return Api()
        .get(`ptab/ptabGet?publicationNumber=${publicationNumber}`)
        .then(res => {
          const data: IGetPtabTypes[] = res.data;
          return data;
        });
    },
    // 專利家族
    getPatentFamily: (country: string, publicationNumber: string) => {
      return Api()
        .get(`patent/family?country=${country}&publicationNumber=${publicationNumber}`)
        .then(res => {
          const data: IGetPatentFamilyTypes = res.data;
          return data;
        });
    },
    // 專利引用文獻
    getPatentCited: (country: string, publicationNumber: string) => {
      return Api()
        .get(`patent/cited?country=${country}&publicationNumber=${publicationNumber}`)
        .then(res => {
          const data: IGetPatentCitedTypes[] = res.data;
          return data;
        });
    },
    // 相似專利
    getPatentSimilar: (country: string, patentId: string) => {
      return Api()
        .get(`patent/similar?country=${country}&patentId=${patentId}`)
        .then(res => {
          const data: IPatentBasicIntro[] = res.data;
          return data;
        });
    },
    // 查詢單篇專利標籤
    getPatentTagById: (patentDataId: number) => {
      return Api()
        .get(`patent/tag/${patentDataId}`)
        .then(res => {
          const data: string[] = res.data;
          return data;
        });
    },
    // 更新專利標籤
    putPatentTag: (patentDataId: number, data: Array<string>) => {
      return Api({ 'Content-Type': 'application/json' }).put(`patent/tag/${patentDataId}`, data);
    },
    // 查詢專利筆記
    getPatentNoteById: (patentDataId: number) => {
      return Api()
        .get(`patent/${patentDataId}/note`)
        .then(res => {
          const data: IGetPatentNoteTypes = res.data;
          return data;
        });
    },
    // 更新專利筆記
    putPatentNoteById: (patentDataId: number, data: string) => {
      return Api({ 'Content-Type': 'application/json' }).put(`patent/${patentDataId}/note`, data);
    },
    // 被分享者取得該篇專利共享筆記
    getPatentShareNote: (patentId: string) => {
      return Api()
        .get(`patent/note/share?patentId=${patentId}`)
        .then(res => {
          const data: Array<IGetPatentNoteShare> = res.data;
          return data;
        });
    },
    // 專利筆記擁有者取得該筆記分享對象列表
    getPatentShareNoteToWhom: (patentNoteId: number) => {
      return Api()
        .get(`patent/${patentNoteId}/note/share`)
        .then(res => {
          const data: Array<number> = res.data;
          return data;
        });
    },
    // 專利筆記情報擁有者更新該筆記分享對象
    putPatentShareNote: (patentNoteId: number, shareUserIds: Array<number>) => {
      return Api({ 'Content-Type': 'application/json' }).put(`patent/${patentNoteId}/note/share`, {
        shareUserIds: shareUserIds,
      });
    },
    // 查詢收藏列表
    getPatentCollectionCategory: (patentDataId: number) => {
      return Api()
        .get(`patent/collection/category?patentDataId=${patentDataId}`)
        .then(res => {
          const data: IGetPatentCollectionCategoryTypes[] = res.data;
          return data;
        });
    },
    // 新增收藏分類
    postPatentCollectionCategory: (category: string) => {
      return Api({ 'Content-Type': 'application/json' }).post(
        `patent/collection/category`,
        category,
      );
    },
    // 收藏專利
    putPatentCollectionCategory: (id: number, patentDataId: number) => {
      return Api({ 'Content-Type': 'application/json' }).put(
        `patent/collection/category/${id}/${patentDataId}`,
        '',
      );
    },
    // 移除收藏
    deletePatentCollectionCategory: (id: number, patentDataId: number) => {
      return Api({ 'Content-Type': 'application/json' }).delete(
        `patent/collection/category/${id}/${patentDataId}`,
      );
    },
    // 查詢專利評等
    getPatentRating: (patentDataId: number) => {
      return Api()
        .get(`patent/${patentDataId}/rating`)
        .then(res => {
          const data: number = res.data;
          return data;
        });
    },
    // 更新專利評等
    putPatentRating: (patentDataId: number, data: number) => {
      return Api({ 'Content-Type': 'application/json' }).put(`patent/${patentDataId}/rating`, data);
    },
    // 申請查詢
    getPatentUrlGlobalDossier: (country: string, applicationNumber: string) => {
      return Api()
        .get(`patent/url/global_dossier?country=${country}&applicationNumber=${applicationNumber}`)
        .then(res => {
          const data: string = res.data;
          return data;
        });
    },
    // 查詢專利OCR辨識標記結果
    getPatentOcrLabel: (patentId: string) => {
      return Api()
        .get(`patent/${patentId}/label/img`)
        .then(res => {
          const data: IGetPatentOcrLabelTypes = res.data;
          return data;
        });
    },
    // 查詢專利訴訟/訴願資訊案號清單
    getPatentCaseList: (applicationNumber: string) => {
      return Api()
        .get(`patent/caseList?applicationNumber=${applicationNumber}`)
        .then(res => {
          const data: IGetPatentCaseList = res.data;
          return data;
        });
    },
  };
};
