import React, { useState } from 'react';
import { Form, Formik, FormikProps } from 'formik';

import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';

import { IDownloadTechnicalTrendForm } from 'src/types/form/OpendataFormTypes';

import { numFormatter } from 'src/utils/Formatter';
import { Button } from 'src/style/theme/Common';
import { Spinner } from 'src/components/ui/interactive/Spinner';
import {
  DownloadSearchResultStyle,
  DownloadTdStyle,
  DownloadThStyle,
  DownloadTrStyle,
  FieldStyle,
  FormContainer,
  ItemContainer,
  ItemInput,
} from 'src/style/OpendataStyle';

interface IResult {
  name: string;
  count: string;
}

interface IProps {
  countryList: Array<{ name: string; value: string }>;
  typeList: Array<{ name: string; value: string }>;
}

const formInitValue: IDownloadTechnicalTrendForm = {
  name: 'TW',
  type: 'A',
};

const ApplyNumberPane: React.FC<IProps> = ({ countryList, typeList }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState<Array<IResult>>([]);

  const handleSubmit = async (values: IDownloadTechnicalTrendForm) => {
    try {
      setIsLoading(true);
      const res = await Api().get(
        'trend/industry/apply/country/' + values.name + '/type/' + values.type + '/',
      );
      setResult(res.data);
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
      setIsSubmitted(true);
    }
  };

  return (
    <>
      <FormContainer>
        <br />
        <Formik
          initialValues={formInitValue}
          onSubmit={values => handleSubmit(values)}
          render={(formikBag: FormikProps<IDownloadTechnicalTrendForm>) => (
            <Form>
              <ItemContainer>
                <span>國別</span>
                <ItemInput>
                  <FieldStyle name="name" component="select" width="50%">
                    <option value="">請選擇</option>
                    {countryList.map(item => (
                      <option value={item.value}>{item.name}</option>
                    ))}
                  </FieldStyle>
                </ItemInput>
              </ItemContainer>
              <ItemContainer>
                <span>專利種類</span>
                <ItemInput>
                  <FieldStyle name="type" component="select" width="50%">
                    <option value="">請選擇</option>
                    {typeList.map(item => (
                      <option value={item.value}>{item.name}</option>
                    ))}
                  </FieldStyle>
                </ItemInput>
              </ItemContainer>
              <ItemContainer>
                <span />
                <ItemInput>
                  <Button type="submit" template="primary">
                    查詢
                  </Button>
                </ItemInput>
              </ItemContainer>
            </Form>
          )}
        />
      </FormContainer>
      <DownloadSearchResultStyle>
        <DownloadThStyle>
          <DownloadTdStyle width="10%" />
          <DownloadTdStyle width="40%">年度</DownloadTdStyle>
          <DownloadTdStyle width="40%">件數</DownloadTdStyle>
        </DownloadThStyle>
        {result.length === 0 ? (
          <>
            {isSubmitted && !isLoading && <p>此搜尋條件範圍內尚無資料，您可嘗試修改查詢條件。</p>}
            {!isSubmitted && !isLoading && <p>尚無查詢結果，請利用上方的查詢功能</p>}
            {isLoading && <Spinner width="120px" margin="40px auto" />}
          </>
        ) : (
          result.map((item, idx) => (
            <DownloadTrStyle key={item.name}>
              <DownloadTdStyle width="10%">{idx + 1}</DownloadTdStyle>
              <DownloadTdStyle width="40%">{item.name}</DownloadTdStyle>
              <DownloadTdStyle width="40%">{numFormatter(item.count)}</DownloadTdStyle>
            </DownloadTrStyle>
          ))
        )}
      </DownloadSearchResultStyle>
    </>
  );
};

export default ApplyNumberPane;
