import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';
import { Backdrop } from 'src/style/ModalStyle';
import {
  blue5,
  blue6,
  iceblue1,
  iceblue5,
  iceblue7,
  iceblue8,
  ultrawhite,
  ultrawhitergba,
} from 'src/style/theme/Color';
import { zModal } from 'src/style/theme/Z-Index';

const Container = styled.div`
  position: relative;
  border-radius: 4px;
  background-color: #ffffff;
  width: 60%;
  max-width: 800px;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 32px;
  svg {
    font-size: 20px;
    margin-left: 24px;
    cursor: pointer;
  }
`;

const Title = styled.div`
  color: ${iceblue1};
  font-size: 20px;
  font-weight: bold;
`;

const Body = styled.div`
  height: calc(100vh - 200px);
  overflow: auto;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    box-shadow: inset 0 0 8px 8px ${blue6};
    border: solid 2px Transparent;
  }
`;

const Table = styled.table`
  width: calc(100% - 64px);
  margin: 0 32px 32px 32px;
  border-collapse: collapse;
  margin: 0 32px 32px 32px;
`;
const Tr = styled.tr`
  border-bottom: 1px solid ${iceblue8};
`;
const HeadTr = styled(Tr)`
  color: ${iceblue5};
  border-color: ${blue5};
`;
const BodyTr = styled(Tr)`
  height: 86px;
`;
const DivisionLine = styled(Tr)`
  border-color: ${iceblue7};
`;
const Td = styled.td`
  padding: 8px 16px;
  font-size: 16px;
  min-width: 160px;
`;
const HeadTd = styled(Td)`
  padding-bottom: 8px;
  min-height: 0;
`;
const FadeoutMask = styled.div`
  position: absolute;
  bottom: 0;
  height: 30px;
  width: 100%;
  border-radius: 0 0 4px 4px;
  background-image: linear-gradient(to bottom, ${ultrawhitergba(0)}, ${ultrawhite});
`;

interface IProps {
  onClose: () => void;
}

const modal = document.getElementById('modal-custom');
const portalDiv = modal ? modal : document.createElement('div');

const IndustrialClassSpecModal: React.FC<IProps> = ({ onClose }) => {
  const divisionLine = (
    <DivisionLine>
      <td />
      <td />
      <td />
      <td />
      <td />
    </DivisionLine>
  );

  return ReactDOM.createPortal(
    <Backdrop zIndex={zModal.custom} alignItems="center">
      <Container>
        <Header>
          <Title>行業別說明簡表</Title>
          <FaTimes color={iceblue5} onClick={() => onClose()} />
        </Header>
        <Body>
          <Table>
            <tbody>
              <HeadTr>
                <HeadTd>行業別</HeadTd>
                <HeadTd>說明 - 對應主計總處行業別</HeadTd>
              </HeadTr>
              <BodyTr>
                <Td>金屬機電工業</Td>
                <Td>
                  機械設備製造業(29)；產業用機械設備維修及安裝業(34)；金屬製品製造業(25)；基本金屬製造業(24)；汽車及其零件製造業(30)；電力設備及配備製造業(28)；其他運輸工具及其零件製造業(31)；
                </Td>
              </BodyTr>
              {divisionLine}
              <BodyTr>
                <Td>資訊電子工業</Td>
                <Td>
                  半導體製造業(261)；電子零組件製造業(26)；電腦、電子產品及光學製品製造業(27)；
                </Td>
              </BodyTr>
              {divisionLine}
              <BodyTr>
                <Td>電腦及資訊服務業</Td>
                <Td>電腦程式設計、諮詢及相關服務業(62)；資訊服務業(63)；</Td>
              </BodyTr>
              {divisionLine}
              <BodyTr>
                <Td>電信業</Td>
                <Td>電信業(61)；</Td>
              </BodyTr>
              {divisionLine}
              <BodyTr>
                <Td>生技醫藥業</Td>
                <Td>
                  藥品及醫用化學製品製造業(20)；輻射及電子醫學設備製造業(276)；醫療器材及用品製造業(332)；
                </Td>
              </BodyTr>
              {divisionLine}
              <BodyTr>
                <Td>化學工業</Td>
                <Td>
                  化學原材料、肥料、氮化合物、塑橡膠原料及人造纖維製造業(18)；其他化學製品製造業(19)；橡膠製品製造業(21)；塑膠製品製造業(22)；石油及煤製品製造業(17)；皮革、毛皮及其製品製造業(13)；紙漿、紙及紙製品製造業(15)；印刷及資料儲存媒體複製業(16)；
                </Td>
              </BodyTr>
              {divisionLine}
              <BodyTr>
                <Td>民生工業</Td>
                <Td>
                  食品及飼品製造業(08)；飲料製造業(09)；菸草製造業(10)；紡織業(11)；成衣及服飾品製造業(12)；木竹製品製造業(14)；非金屬礦物製品製造業(23)；家具製造業(32)；
                </Td>
              </BodyTr>
              {divisionLine}
              <BodyTr>
                <Td>電力及燃氣供應業</Td>
                <Td>電力及燃氣供應業(35)；</Td>
              </BodyTr>
              {divisionLine}
              <BodyTr>
                <Td>用水供應及污染整治業</Td>
                <Td>
                  用水供應業(36)；廢水及污水處理業(37)；廢棄物清除、處理及資源回收處理業(38)；
                </Td>
              </BodyTr>
              {divisionLine}
              <BodyTr>
                <Td>農林漁牧業</Td>
                <Td>農、牧業(01)；林業(02)；漁業(03)；</Td>
              </BodyTr>
              {divisionLine}
              <BodyTr>
                <Td>營建工程業</Td>
                <Td>建築工程業(41)；土木工程業(42)；</Td>
              </BodyTr>
              {divisionLine}
              <BodyTr>
                <Td>礦業及土石採取業</Td>
                <Td>石油及天然氣礦業(05)；砂、石採取及其他礦業(06)；</Td>
              </BodyTr>
              {divisionLine}
              <BodyTr>
                <Td>專業技術服務業</Td>
                <Td>研究發展服務業(72)；個人及家庭用品維修業(95)；</Td>
              </BodyTr>
              {divisionLine}
              <BodyTr>
                <Td>影視娛樂休閒服務業</Td>
                <Td>廣播、電視節目編排及傳播業(60)；</Td>
              </BodyTr>
              {divisionLine}
            </tbody>
          </Table>
          <FadeoutMask />
        </Body>
      </Container>
    </Backdrop>,
    portalDiv,
  );
};

export default IndustrialClassSpecModal;
