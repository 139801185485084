import React, { useState } from 'react';
import { Button } from 'src/style/theme/Common';
import { ISearchRequest } from 'src/types/PatentSearchTypes';
import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import { alertMessage } from 'src/utils/ModalUtils';
import { SystemMessage } from 'src/apps/applicationMessages';
import { connect } from 'react-redux';
import { ReduxAppState } from 'src/redux/reducers';
import {
  Title,
  Field,
  LabelField,
  QueryString,
  ButtonArea,
  Description,
} from 'src/style/ResultToolbarStyle';

const subscriptionUrl = 'patent/subscription';

interface IReduxProps {
  searchRequest: ISearchRequest;
}

interface IProps extends IReduxProps {
  closePane: () => void;
}

/** 專利追蹤 */
const SubscriptionPane: React.FC<IProps> = props => {
  const [subscriptionName, setSubscriptionName] = useState('');

  const handleSubscriptionNameChange = (e: any) => {
    setSubscriptionName(e.target.value);
  };

  const handleSubscriptionSend = async () => {
    const { searchRequest } = props;
    if (subscriptionName.trim() !== '') {
      try {
        const response = await Api().post(subscriptionUrl, {
          name: subscriptionName,
          industryId: 0,
          keyword: searchRequest.queryString,
          bilingualTerm: searchRequest.bilingualTerm,
          advanceKeywords: searchRequest.advanceKeywords,
        });

        if (response.status === 200) {
          alertMessage(SystemMessage.PATENT_SUBSCRIPTION_SUCCESS);
          props.closePane();
        }
      } catch (error) {
        errorHandler(error);
      }
    } else {
      alertMessage(SystemMessage.PATENT_SUBSCRIPTION_NAME_EMPTY);
    }
  };

  return (
    <>
      <Title>專利追蹤</Title>
      <LabelField>
        <span>名稱設定</span>
        <input
          type="text"
          name="subscriptionName"
          value={subscriptionName}
          onChange={e => handleSubscriptionNameChange(e)}
        />
      </LabelField>
      <Field>
        <span>追蹤條件</span>
        <QueryString>{props.searchRequest.queryString}</QueryString>
      </Field>
      <ButtonArea>
        <Button template="primary" onClick={handleSubscriptionSend}>
          追蹤
        </Button>
        <Button template="light" onClick={props.closePane}>
          取消
        </Button>
      </ButtonArea>
      <Description>
        追蹤該專利搜尋條件後可在「專利專區」>「專利追蹤」內隨時追蹤最新狀態，不用每次搜尋呦！
      </Description>
    </>
  );
};

const mapStateToProps = (state: ReduxAppState) => {
  return {
    searchRequest: state.patentSearchReducer.request,
  };
};

export default connect(
  mapStateToProps,
  null,
)(SubscriptionPane);
