import React, { memo } from 'react';
import { FaRss } from 'react-icons/fa';

import Toolbar, {
  IToolbarFunctionProps,
  ToolbarTheme,
} from 'src/components/ui/interactive/Toolbar';
import { blue5, blue6 } from 'src/style/theme/Color';

interface IProps {
  displayPane: boolean;
  toggleDiplayPane: () => void;
}

const SubscribeToolbar = (props: IProps) => {
  const functions: Array<IToolbarFunctionProps> = [
    {
      theme: ToolbarTheme.CUSTOMIZE,
      backgroundImage: props.displayPane
        ? `linear-gradient(114deg, ${blue6}, ${blue5} 99%)`
        : 'linear-gradient(114deg, #1f8ba3, #323846 99%)',
      boxShadow: props.displayPane
        ? '0 5px 20px -5px rgba(0, 63, 84, 0.4)'
        : '0 5px 20px -5px rgba(0, 63, 84, 0.3)',
      component: (
        <div onClick={props.toggleDiplayPane}>
          <FaRss /> 自訂頻道
        </div>
      ),
    },
  ];

  return <Toolbar functions={functions} />;
};

const MemorizedSubscribeToolbar = memo(SubscribeToolbar);

export default MemorizedSubscribeToolbar;
