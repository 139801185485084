import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { RouteComponentProps, Switch, Route, Redirect } from 'react-router-dom';
import ColumnistArticle from 'src/components/article/ColumnistArticle';
import Video from './Video';
import NestedSideNav from '../common/nav/side-nav/NestedSideNav';
import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import { IGetColumnistArticleCategory } from 'src/types/api/ColumnistArticleApiTypes';
import { isAndroid, isIOS } from 'src/apps/browser/UserAgent';

const Container = styled.div`
  margin: 0 auto;
  padding: 16px 140px 0;
  max-width: 1440px;
  display: flex;
  align-items: flex-start;
  align-content: space-between;
  flex-flow: row;
`;
const SideNavStyle = styled.div`
  width: 180px;
  display: inline-flex;
`;
const ContentStyle = styled.div`
  padding: 0 0 0 16px;
  width: calc(100% - 180px);
`;

const ArticleLayout: React.FC<RouteComponentProps> = routeProps => {
  const sector = '專欄文章';
  const features = [
    { feature: '專欄文章', path: '/columnist', subfeatures: true },
    { feature: '影片專區', path: '/video', subfeatures: false },
  ];

  const [categories, setCategories] = useState<Array<IGetColumnistArticleCategory>>();

  useEffect(() => {
    if ((isAndroid || isIOS) && routeProps.location.pathname.startsWith('/article')) {
      routeProps.history.replace('/lite/article');
    }
  }, [routeProps.history, routeProps.location.pathname]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Api()
          .get('/article/columnist/categories')
          .then(res => setCategories(res.data));
      } catch (error) {
        errorHandler(error);
      }
    };

    categories === undefined && fetchData();
  }, [categories]);

  return (
    <Container>
      <SideNavStyle>
        <NestedSideNav
          sector={sector}
          features={features}
          subFeatArr={[
            {
              idx: 0,
              items: categories
                ? categories.map(category => ({ id: category.id, name: category.name }))
                : [],
            },
          ]}
          {...routeProps}
        />
      </SideNavStyle>

      <ContentStyle>
        <Switch>
          {categories && categories.length > 0
            ? categories
                .map(category => (
                  <Route
                    exact
                    path={routeProps.match.url + features[0].path + '/' + category.id}
                    component={() => (
                      <ColumnistArticle
                        sector={sector}
                        feature={category.name}
                        categoryId={category.id}
                      />
                    )}
                  />
                ))
                .concat([
                  <Route
                    path={routeProps.match.url + features[1].path}
                    render={() => <Video feature={features[1].feature} />}
                  />,
                  <Route
                    component={() => (
                      <Redirect
                        to={routeProps.match.url + features[0].path + '/' + categories[0].id}
                      />
                    )}
                  />,
                ])
            : [
                <Route
                  path={routeProps.match.url + features[0].path}
                  render={() => <ColumnistArticle sector={sector} feature={features[0].feature} />}
                />,
                <Route
                  path={routeProps.match.url + features[1].path}
                  render={() => <Video feature={features[1].feature} />}
                />,
              ]}

          <Route component={() => <Redirect to={routeProps.match.url + features[0].path} />} />
        </Switch>
      </ContentStyle>
    </Container>
  );
};

export default ArticleLayout;
