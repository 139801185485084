import { AxiosResponse } from 'axios';
import { Formik, Form, FormikActions, FieldArray } from 'formik';
import React, { useState, useEffect } from 'react';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import Content from 'src/components/ui/content/Content';
import { ItemContainer, ItemInput, FieldStyle } from 'src/style/MemberStyle';
import { IExpertForm } from 'src/types/form/ExpertFormTypes';
import { Api } from 'src/api/helpers/apiBase';
import { phoneValidator } from 'src/apps/validation/formValidator';

import styled from 'styled-components';
import { FaPlus, FaTimes } from 'react-icons/fa';
import * as Color from 'src/style/theme/Color';
import { Button } from 'src/style/theme/Common';
import { IGetExpert } from 'src/types/api/ExpertApiTypes';
import { initData } from 'src/types/defaultData/ExpertFormInit';
import { alertMessage } from 'src/utils/ModalUtils';
import { getStatus } from 'src/utils/ExpertStatusUtils';
import * as yup from 'yup';
import _ from 'lodash';

/** 樣式設定 */
const BtnContainer = styled(ItemContainer)`
  padding: 16px 0 36px;
`;
const Message = styled.div`
  color: ${Color.red1};
`;
const JobItemInput = styled(ItemInput)`
  justify-content: space-between;
  input {
    width: 32%;
  }
`;

const YearItems = styled(ItemInput)`
  margin-bottom: 8px;
  flex-wrap: nowrap;
`;

const YearItem = styled(FieldStyle)`
  width: 120px !important;
  margin-right: 16px;
`;

const EduItemInput = styled(ItemInput)`
  width: 100%;
  flex-wrap: nowrap;
`;

const Ol = styled.ol`
  display: inline-block;
  width: 70%;
  li {
    width: 100%;
    margin-bottom: 16px;
    div {
      width: 100%;
    }
  }
`;

const ItemButtonWrapper = styled.div`
  display: flex;
`;

const ItemAddButton = styled.div`
  color: #077790;
  border-radius: 4px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 8px;
  }

  :hover {
    cursor: pointer;
  }
`;
const ItemRemoveButton = styled(ItemAddButton)`
  color: #eb5000;
`;

const NotifyMsg = styled.div<{ focus?: boolean; fontSize?: number }>`
  width: 70%;
  color: ${props => (props.focus ? Color.pink3 : Color.iceblue2)};
  font-size: ${props => (props.fontSize ? `${props.fontSize}px` : null)};
  line-height: ${props => (props.fontSize ? `15px` : null)};
`;

/** 專家編輯資料 */
const ExpertDetailEdit: React.FC = () => {
  const maxItemSize = 3;
  const [expertData, setExpertData] = useState<IExpertForm>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const expertRes = await Api().get('expert/expertDetail');
        const expertData = expertRes.data as IGetExpert;

        if (expertData) {
          setExpertData({
            id: expertData.id,
            status: expertData.status,
            name: expertData.name,
            photo: expertData.photo,
            phone: expertData.phone,
            email: expertData.email,
            expertise: expertData.expertise,
            jobDepartment: expertData.jobDepartment,
            jobTitle: expertData.jobTitle,
            jobUnit: expertData.jobUnit,
            experiences: expertData.experiences,
            educations: expertData.educations,
            publications: expertData.publications,
            theses: expertData.theses,
          });
        } else {
          // 是否需改成警告未設定專家
          setExpertData(initData);
        }
      } catch (error) {
        errorHandler(error);
      }
    };

    fetchData();
  }, []);

  /** 更新專家履歷資訊(不含圖) */
  const handleSubmit = (values: IExpertForm, actions: FormikActions<IExpertForm>) => {
    actions.setSubmitting(false);
    const switchApi = async (): Promise<AxiosResponse<any>> => {
      const apiInstance = Api({ 'Content-Type': 'application/json' });
      return apiInstance.patch('expert/expertDetail', mapPatchData(values));
    };
    const submit = _.throttle(
      async () => {
        try {
          const res = await switchApi();
          if (res.status === 200) {
            alertMessage('已更新，可至「學界專家」頁面查看專家履歷');
          }
        } catch (error) {
          alert('年度有輸入時，說明不可空白');
        } finally {
          actions.setSubmitting(true);
        }
      },
      2000,
      { leading: true },
    );

    submit();
  };

  /** 專家履歷更新資料 */
  const mapPatchData = (value: IExpertForm) => {
    return {
      id: value.id,
      name: value.name,
      phone: value.phone,
      email: value.email,
      expertise: value.expertise,
      jobDepartment: value.jobDepartment,
      jobTitle: value.jobTitle,
      jobUnit: value.jobUnit,
      experiences: value.experiences,
      educations: value.educations,
      publications: value.publications,
      theses: value.theses,
    };
  };

  // 驗證項目
  const validateSchema = yup.object().shape({
    email: yup
      .string()
      .email('無效的電子郵件')
      .required('信箱不可空白'),
  });

  /** 年度選單, optionPresent 決定是否顯示'迄今'選項 */
  const yearSelect = (name: string, text: string, optionPresent?: boolean, startYear?: number) => {
    const start = startYear || 1900;
    const years = Array.from(
      new Array(new Date().getFullYear() - start),
      (val, index) => start + index,
    );
    return (
      <YearItem name={name} component="select">
        <option value="" key="na">
          {text}
        </option>
        {years.map(year => (
          <option value={year} key={year}>
            {year}
          </option>
        ))}
        {optionPresent && (
          <option value="迄今" key="present">
            迄今
          </option>
        )}
      </YearItem>
    );
  };

  return (
    <Content sector="個人專區" feature="編輯專家履歷">
      <>
        {expertData && (
          <Formik
            initialValues={expertData}
            onSubmit={(values, actions) => handleSubmit(values, actions)}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={validateSchema}
            render={props => (
              <Form id="expertDetailForm">
                <FieldStyle type="text" name="id" hidden />
                <ItemContainer>
                  <label>狀態</label>
                  <ItemInput disabled>
                    <FieldStyle
                      type="text"
                      name="status"
                      value={getStatus(props.values.status)}
                      disabled
                    />
                  </ItemInput>
                </ItemContainer>
                <ItemContainer>
                  <label>姓名</label>
                  <ItemInput disabled>
                    <FieldStyle type="text" name="name" disabled />
                  </ItemInput>
                </ItemContainer>
                <ItemContainer>
                  <label>連絡電話</label>
                  <ItemInput>
                    <FieldStyle
                      type="text"
                      name="phone"
                      placeholder="請輸入數字及「-」「#」"
                      validate={phoneValidator}
                      maxLength="20"
                    />
                  </ItemInput>
                </ItemContainer>
                {props.errors.phone && props.touched.phone && (
                  <ItemContainer>
                    <label />
                    <Message>{props.errors.phone}</Message>
                  </ItemContainer>
                )}
                <ItemContainer>
                  <label />
                  <NotifyMsg fontSize={12}>請輸入數字及「-」「#」</NotifyMsg>
                </ItemContainer>
                <ItemContainer>
                  <label>email</label>
                  <ItemInput>
                    <FieldStyle type="email" name="email" />
                  </ItemInput>
                </ItemContainer>
                {props.errors.email && props.touched.email && (
                  <ItemContainer>
                    <label />
                    <Message>{props.errors.email}</Message>
                  </ItemContainer>
                )}
                <ItemContainer>
                  <label>專長</label>
                  <ItemInput>
                    <FieldStyle component="textarea" name="expertise" maxLength="1000" />
                  </ItemInput>
                </ItemContainer>
                <ItemContainer>
                  <label>現職</label>
                  <JobItemInput>
                    <FieldStyle type="text" name="jobUnit" placeholder="現職單位" maxLength="64" />
                    <FieldStyle
                      type="text"
                      name="jobDepartment"
                      placeholder="現職系所"
                      maxLength="64"
                    />
                    <FieldStyle type="text" name="jobTitle" placeholder="現職職稱" maxLength="64" />
                  </JobItemInput>
                </ItemContainer>
                <ItemContainer>
                  <label>學歷</label>
                  <FieldArray name="educations">
                    {({ push, remove }) => (
                      <Ol>
                        {props.values.educations.map((item, index) => (
                          <li key={index}>
                            <EduItemInput>
                              {yearSelect(`educations[${index}].graduateYear`, '畢業年度')}
                              <FieldStyle
                                type="text"
                                name={`educations[${index}].title`}
                                placeholder="學歷說明"
                                maxLength="1000"
                              />
                            </EduItemInput>
                          </li>
                        ))}
                        <ItemButtonWrapper>
                          {props.values.educations.length < maxItemSize && (
                            <ItemAddButton onClick={() => push({})}>
                              <FaPlus />
                              <span>新增學歷</span>
                            </ItemAddButton>
                          )}
                          {props.values.educations.length !== 0 && (
                            <ItemRemoveButton
                              onClick={() => remove(props.values.educations.length - 1)}
                            >
                              <FaTimes />
                              <span>移除學歷</span>
                            </ItemRemoveButton>
                          )}
                        </ItemButtonWrapper>
                      </Ol>
                    )}
                  </FieldArray>
                </ItemContainer>
                {props.errors.educations && props.touched.educations && (
                  <ItemContainer>
                    <label />
                    {props.errors.educations.map(item => (
                      <Message>{item !== undefined && item.graduateYear}</Message>
                    ))}
                  </ItemContainer>
                )}
                <ItemContainer>
                  <label>經歷</label>
                  <FieldArray name="experiences">
                    {({ push, remove }) => (
                      <Ol>
                        {props.values.experiences.map((item, index) => (
                          <li key={index}>
                            <YearItems>
                              {yearSelect(`experiences[${index}].startYear`, '開始年度')}
                              {yearSelect(`experiences[${index}].endYear`, '結束年度', true)}
                            </YearItems>
                            <ItemInput>
                              <FieldStyle
                                type="text"
                                name={`experiences[${index}].title`}
                                placeholder="經歷說明"
                                maxLength="1000"
                              />
                            </ItemInput>
                          </li>
                        ))}
                        <ItemButtonWrapper>
                          {props.values.experiences.length < maxItemSize && (
                            <ItemAddButton onClick={() => push({})}>
                              <FaPlus />
                              <span>新增經歷</span>
                            </ItemAddButton>
                          )}
                          {props.values.experiences.length !== 0 && (
                            <ItemRemoveButton
                              onClick={() => remove(props.values.experiences.length - 1)}
                            >
                              <FaTimes />
                              <span>移除經歷</span>
                            </ItemRemoveButton>
                          )}
                        </ItemButtonWrapper>
                      </Ol>
                    )}
                  </FieldArray>
                </ItemContainer>
                <ItemContainer>
                  <label>著作</label>
                  <FieldArray name="publications">
                    {({ push, remove }) => (
                      <Ol>
                        {props.values.publications.map((item, index) => (
                          <li key={index}>
                            <ItemInput>
                              <FieldStyle
                                component="textarea"
                                name={`publications[${item}].title`}
                                placeholder="著作名稱"
                                maxLength="1000"
                              />
                            </ItemInput>
                          </li>
                        ))}
                        <ItemButtonWrapper>
                          {props.values.publications.length < maxItemSize && (
                            <ItemAddButton onClick={() => push({})}>
                              <FaPlus />
                              <span>新增著作</span>
                            </ItemAddButton>
                          )}
                          {props.values.publications.length !== 0 && (
                            <ItemRemoveButton
                              onClick={() => remove(props.values.publications.length - 1)}
                            >
                              <FaTimes />
                              <span>移除著作</span>
                            </ItemRemoveButton>
                          )}
                        </ItemButtonWrapper>
                      </Ol>
                    )}
                  </FieldArray>
                </ItemContainer>
                <ItemContainer>
                  <label>論文</label>
                  <FieldArray name="theses">
                    {({ push, remove }) => (
                      <Ol>
                        {props.values.theses.map((item, index) => (
                          <li key={index}>
                            <ItemInput>
                              <FieldStyle
                                component="textarea"
                                name={`theses[${index}].title`}
                                placeholder="論文名稱"
                                maxLength="1000"
                              />
                            </ItemInput>
                          </li>
                        ))}
                        <ItemButtonWrapper>
                          {props.values.theses.length < maxItemSize && (
                            <ItemAddButton onClick={() => push({})}>
                              <FaPlus />
                              <span>新增論文</span>
                            </ItemAddButton>
                          )}
                          {props.values.theses.length !== 0 && (
                            <ItemRemoveButton
                              onClick={() => remove(props.values.theses.length - 1)}
                            >
                              <FaTimes />
                              <span>移除論文</span>
                            </ItemRemoveButton>
                          )}
                        </ItemButtonWrapper>
                      </Ol>
                    )}
                  </FieldArray>
                </ItemContainer>
                <BtnContainer>
                  <label />
                  <ItemInput>
                    <Button type="submit" template="primary">
                      儲存
                    </Button>
                    <Button type="reset">重設</Button>
                  </ItemInput>
                </BtnContainer>
              </Form>
            )}
          />
        )}
      </>
    </Content>
  );
};
export default ExpertDetailEdit;
