import React from 'react';
import styled from 'styled-components';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { ErrorCode } from 'src/apps/error-handler/ErrorCode';

import { Button } from 'src/style/theme/Common';
import { Spinner } from 'src/components/ui/interactive/Spinner';
import {
  Container,
  Table,
  HRow,
  // HOne,
  HTwo,
  HThree,
} from 'src/style/IndexTabStyle';

import { connect } from 'react-redux';
import { ReduxAppState } from 'src/redux/reducers';
import { closeLoginModal, openLoginFormModal } from 'src/redux/actions/modalAction';
import { LoginModalType } from 'src/types/ModalTypes';

/** 隱藏產業類別暫時使用，之後須顯示類別時請移除再改回使用原本的 HTwo。 */
const TempHTwo = styled(HTwo)`
  padding-left: 32px;
`;

const Content = styled.div`
  padding: 16px 32px;
  height: 370px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const MsgAncher = styled.div`
  padding: 0 0 56px;
  text-align: center;
`;
const Message = styled.div`
  padding: 0 0 32px;
`;

interface IProps extends RouteComponentProps {
  tab: string;
  header: Array<string>;
  error: boolean;
  noData: boolean;
  isLogin: boolean;
  isLoading: boolean;
  openLoginFormModal: () => void;
}

const exceptionPanel: React.FC<IProps> = ({
  tab,
  header,
  error,
  noData,
  isLogin,
  isLoading,
  openLoginFormModal,
  history,
}) => {
  const handleTutorial = () => {
    let path = '';
    switch (tab) {
      case '專利情報':
        path = '/showcase/tracking';
        break;
      default:
        throw new Error(ErrorCode.UNHANDLED_CASE_IN_SWITCH);
    }
    history.push(path);
  };

  const switchContent = () => {
    if (isLoading) {
      return <Spinner width="150px" />;
    } else {
      if (isLogin && noData && tab === '專利情報') {
        return (
          <>
            <Message>您目前未訂閱任何{tab}來源，設定訂閱即可享有快報功能。</Message>
            <Button type="button" template="primary" onClick={handleTutorial}>
              {tab}訂閱說明
            </Button>
          </>
        );
      } else if (error || (noData && (tab === '最新專利' || tab === '推薦專利'))) {
        return (
          <>
            <Message>目前{tab}服務似乎出了點小問題，如有需要，請聯絡系統管理員。</Message>
            <a href="mailto:tipoeservice@tipo.gov.tw">
              <Button type="button" template="primary">
                聯絡系統管理員
              </Button>
            </a>
          </>
        );
      } else {
        return (
          <>
            <Message>{tab}為會員功能，加入會員即可獲得最新資訊！</Message>
            <Button type="button" template="primary" onClick={openLoginFormModal}>
              登入
            </Button>
          </>
        );
      }
    }
  };

  return (
    <Container>
      <Table>
        <HRow>
          {/* <HOne>{header[0]}</HOne> */}
          <TempHTwo>{header[1]}</TempHTwo>
          <HThree>{header[2]}</HThree>
        </HRow>
      </Table>
      <Content>
        <MsgAncher>{switchContent()}</MsgAncher>
      </Content>
    </Container>
  );
};

const mapStateToProps = (state: ReduxAppState) => {
  return {
    isOpen: state.modalReducer.loginModal.type === LoginModalType.ALERT,
    isFromRoute: state.modalReducer.loginModal.isFromRoute,
  };
};

const mapDispatchToProps = {
  closeLoginModal,
  openLoginFormModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(exceptionPanel));
