import React, { useState, useEffect, useRef } from 'react';
import * as d3 from 'd3';
import styled from 'styled-components';
import { IFacetFieldCount } from 'src/types/PatentSearchTypes';
import { white, blue1, blue3, iceblue1, iceblue5, iceblue8 } from 'src/style/theme/Color';
import Tooltip from './Tooltip';
import { numFormatter } from 'src/utils/Formatter';
import { toEllipsis } from './chartHelper';
import { svgId } from 'src/utils/svgDownloadUtils';

const ToolTipOverlay = styled.div`
  text-align: center;
  color: ${white};
`;

const width = 750;
const height = 350;
const padding = { top: 50, right: 50, bottom: 120, left: 100 };
const xAxisWidth = width - padding.left - padding.right;
const yAxisWidth = height - padding.top - padding.bottom;

interface IProps {
  fieldName: string;
  dataset: Array<IFacetFieldCount>;
}

interface ITooltip extends IFacetFieldCount {
  left: number;
  top: number;
}

/** 折線圖 */
const LineChart: React.FC<IProps> = ({ fieldName, dataset }) => {
  const [tooltip, setTooltip] = useState<ITooltip>();

  const svgRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    const xScale = d3
      .scaleBand()
      .domain(dataset.map((item, idx) => `${idx}`))
      .rangeRound([0, width - padding.left - padding.right]);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(dataset, d => d.count as any)])
      .range([height - padding.top - padding.bottom, 0])
      .nice(5);

    const xAxis = d3
      .axisBottom(xScale)
      .tickFormat((d, i) => toEllipsis(dataset[i].name, 5, 0))
      .tickSize(5);

    const yAxis = d3
      .axisLeft(yScale)
      .ticks(5)
      .tickFormat(d3.format(','))
      .tickSize(-xAxisWidth);

    const linePath = d3
      .line<IFacetFieldCount>()
      .x((d: IFacetFieldCount, i) => (xScale(`${i}`) || 0) + xScale.bandwidth() / 2)
      .y((d: IFacetFieldCount) => yScale(d.count));

    const svg = d3.select(svgRef.current);

    // X軸
    const xAxisG = svg
      .append('g')
      .attr('transform', 'translate(' + padding.left + ',' + (padding.top + yAxisWidth) + ')')
      .style('color', iceblue1)
      .call(xAxis);

    // 逆時針旋轉文字並調整與X軸之距離
    xAxisG
      .selectAll('text')
      .style('text-anchor', 'end')
      .style('font-size', '13px')
      .attr('dx', '-1em')
      .attr('transform', `rotate(-65)`);
    // 刪除X軸-軸線 (橫線以Y軸 ticks 顯示)
    xAxisG.selectAll('path').remove();

    // Y軸
    const yAxisG = svg
      .append('g')
      .attr('transform', 'translate(' + padding.left + ',' + padding.top + ')')
      .style('color', iceblue1)
      .call(yAxis);

    // 調整文字與Y軸之距離
    yAxisG.selectAll('text').attr('x', '-0.8em');
    // 刪除Y軸-軸線
    yAxisG.selectAll('path').remove();
    // Y軸 ticks (作為橫格線) 之樣式設定
    yAxisG
      .selectAll('line')
      .attr('fill', 'none')
      .attr('stroke', `${iceblue8}`);

    // X軸 - 座標軸標題
    svg
      .append('text')
      .attr('transform', 'translate(' + width / 2 + ' ,' + (height - 2) + ')')
      .attr('fill', `${iceblue5}`)
      .style('text-anchor', 'middle')
      .text(fieldName);

    // Y軸 - 座標軸標題
    svg
      .append('text')
      .attr('transform', 'rotate(-90)')
      .attr('y', 0)
      .attr('x', 0 - height / 2)
      .attr('dy', '1em')
      .attr('fill', `${iceblue5}`)
      .style('text-anchor', 'middle')
      .text('專利數量');

    // 折線圖-線段
    svg
      .datum(dataset)
      .append('path')
      .attr('transform', 'translate(' + padding.left + ',' + padding.top + ')')
      .attr('d', linePath)
      .attr('fill', 'none')
      .attr('stroke-width', '1px')
      .attr('stroke', `${blue1}`);

    // 折線圖-連接點
    svg
      .selectAll('circle')
      .data(dataset)
      .enter()
      .append('circle')
      .attr('class', 'circle')
      .attr('fill', `${blue1}`)
      .attr('cx', (d, i) => padding.left + (xScale(`${i}`) || 0) + xScale.bandwidth() / 2)
      .attr('cy', d => padding.top + yScale(d.count))
      .attr('r', 4)
      .on('mouseover', (d, i, nodes) => {
        d3.select(nodes[i])
          .attr('r', 5)
          .style('fill', `${blue3}`);

        setTooltip({
          left: d3.event.pageX - window.scrollX,
          top: d3.event.pageY - window.scrollY - 32,
          name: d.name,
          count: d.count,
        });
      })
      .on('mousemove', d => {
        setTooltip({
          left: d3.event.pageX - window.scrollX,
          top: d3.event.pageY - window.scrollY - 32,
          name: d.name,
          count: d.count,
        });
      })
      .on('mouseout', (d, i, nodes) => {
        d3.select(nodes[i])
          .attr('r', 4)
          .style('fill', `${blue1}`);

        setTooltip(undefined);
      });
  }, [fieldName, dataset]);

  return (
    <>
      <svg id={svgId} ref={svgRef} width={width} height={height} />
      {tooltip && (
        <Tooltip
          left={tooltip.left}
          top={tooltip.top}
          overlay={
            <ToolTipOverlay>
              <div>{tooltip.name}</div>
              <div>專利數量:&nbsp;{numFormatter(tooltip.count)}</div>
            </ToolTipOverlay>
          }
        />
      )}
    </>
  );
};

export default LineChart;
