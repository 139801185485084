import React from 'react';
import styled from 'styled-components';
import { Route, RouteComponentProps } from 'react-router-dom';

import EmailConfirm from 'src/components/common/check-profile/EmailConfirm';
import PwdReset from 'src/components/common/check-profile/PwdReset';

const Container = styled.div`
  margin: auto;
  padding: 16px 0 0;
  width: 75%;
`;

const checkLayout: React.FC<RouteComponentProps> = props => {
  return (
    <Container>
      {/* <Route path={props.match.url + '/confirm/:identifier([0-9a-z]+)'} component={Confirm} /> */}
      <Route path="/email/confirm" component={EmailConfirm} />
      <Route path="/password/reset" component={PwdReset} />
    </Container>
  );
};

export default checkLayout;
