import React, { useState } from 'react';
import { FormikProps } from 'formik';
import { Row, IconLabel, TextLabel, Symbol, StyledField } from 'src/style/PatentSearchStyle';
import {
  CONDITION_AND,
  cpcFields,
  ipcFields,
  locFields,
  logicItems,
} from 'src/constants/PatentSearchConstants';
import { ISelectedItem } from 'src/components/common/Dropdown';
import { SearchDropdown } from 'src/components/search/SearchDropdown';
import { IAdvancedSearch, IBooleanSearch } from 'src/types/form/SearchFormTypes';

interface IProps {
  formikBag: FormikProps<IAdvancedSearch | IBooleanSearch>;
  ipcCodeList?: string[];
  handleIpcCodeList?: (code?: string[]) => void;
}

/**
 * 分類號代碼檢索
 * @description
 * 對應 Formik 欄位名稱:
 * "ipcCondition"、"cpcCondition"、"locCondition"、
 * "ipcText"、"cpcText"、"locText"。
 */
const ClassificationCode: React.FC<IProps> = props => {
  // 下拉選單預設選取值
  const [selectedItem, setSelectedItem] = useState<ISelectedItem>({
    ipcCondition: { name: 'AND', value: CONDITION_AND },
    cpcCondition: { name: 'AND', value: CONDITION_AND },
    locCondition: { name: 'AND', value: CONDITION_AND },
  });
  const [selectedField, setSelectedField] = useState<ISelectedItem>({
    ipcField: { name: 'IPC', value: 'IC' },
    cpcField: { name: 'CPC', value: 'CS' },
    locField: { name: 'LOC', value: 'IQ' },
  });
  const { formikBag } = props;

  const handleIpcCode = (code: string[]) => {
    props.handleIpcCodeList && props.handleIpcCodeList(code);
    return code.toString().replace(/,/g, ' OR ');
  };

  return (
    <>
      <Row>
        <SearchDropdown
          items={logicItems}
          activeItem={selectedItem.ipcCondition}
          handleOnclick={item => {
            setSelectedItem(prevState => ({
              ...prevState,
              ipcCondition: item,
            }));
            formikBag.setFieldValue('ipcCondition', item.value);
          }}
          width="18%"
        />
        <Symbol />
        <SearchDropdown
          items={ipcFields}
          activeItem={selectedField.ipcField}
          handleOnclick={item => {
            setSelectedField(prevState => ({
              ...prevState,
              ipcField: item,
            }));
            formikBag.setFieldValue('ipcField', item.value);
          }}
          width="15%"
        />
        <Symbol>=</Symbol>
        <TextLabel width="57%">
          <IconLabel width="100%">
            <StyledField
              width="100%"
              type="text"
              name="ipcText"
              value={props.ipcCodeList && handleIpcCode(props.ipcCodeList)}
              onChange={(event: { target: any }) => {
                props.handleIpcCodeList &&
                  props.handleIpcCodeList(event.target.value.split(' OR '));
              }}
            />
          </IconLabel>
        </TextLabel>
      </Row>
      <Row>
        <SearchDropdown
          items={logicItems}
          activeItem={selectedItem.cpcCondition}
          handleOnclick={item => {
            setSelectedItem(prevState => ({
              ...prevState,
              cpcCondition: item,
            }));
            formikBag.setFieldValue('cpcCondition', item.value);
          }}
          width="18%"
        />
        <Symbol />
        <SearchDropdown
          items={cpcFields}
          activeItem={selectedField.cpcField}
          handleOnclick={item => {
            setSelectedField(prevState => ({
              ...prevState,
              cpcField: item,
            }));
            formikBag.setFieldValue('cpcField', item.value);
          }}
          width="15%"
        />
        <Symbol>=</Symbol>
        <TextLabel width="57%">
          <StyledField width="100%" type="text" name="cpcText" />
        </TextLabel>
      </Row>
      <Row>
        <SearchDropdown
          items={logicItems}
          activeItem={selectedItem.locCondition}
          handleOnclick={item => {
            setSelectedItem(prevState => ({
              ...prevState,
              locCondition: item,
            }));
            formikBag.setFieldValue('locCondition', item.value);
          }}
          width="18%"
        />
        <Symbol />
        <SearchDropdown
          disabled={true}
          items={locFields}
          activeItem={selectedField.locField}
          handleOnclick={item => {
            setSelectedField(prevState => ({
              ...prevState,
              locField: item,
            }));
            formikBag.setFieldValue('locField', item.value);
          }}
          width="15%"
        />
        <Symbol>=</Symbol>
        <TextLabel width="57%">
          <StyledField width="100%" type="text" name="locText" />
        </TextLabel>
      </Row>
    </>
  );
};

export default ClassificationCode;
