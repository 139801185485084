import React from 'react';
import { Switch, Route } from 'react-router-dom';

import LoginLayout from 'src/components/common/login/LoginLayout';
import { InvalidIp } from 'src/components/common/status-page/InvalidIp';
import App from 'src/App';
import ChatbotGirl from 'src/components/chatbotDemo/ChatbotGirl';

import { NewsService } from 'src/api/News';

export const LoginRoute = () => (
  <Switch>
    <Route path="/chatbot/girl" exact component={ChatbotGirl} />
    <Route path="/login" exact component={LoginLayout} />
    <Route path="/invalid-ip" exact component={InvalidIp} />
    {/** 配合電子報, 新聞轉址不需登入即可使用 */}
    <Route
      path="/news/:uuid/view"
      component={(props: any) => {
        NewsService()
          .getNewsLink(props.match.params.uuid)
          .then(data => window.location.replace(data.toString()));
        return null;
      }}
    />
    <Route component={App} />
  </Switch>
);
