import React, { Component } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';

/** 在頁面的<body>區塊最尾端插入<div> */
export default class Portal extends Component {
  constructor() {
    super();
    this.portalElement = null;
  }
  componentDidMount() {
    const p = document.createElement('div');
    document.body.appendChild(p);
    this.portalElement = p;
    this.componentDidUpdate();
  }
  componentDidUpdate() {
    render(<div>{this.props.children}</div>, this.portalElement);
  }
  componentWillUnmount() {
    unmountComponentAtNode(this.portalElement);
    document.body.removeChild(this.portalElement);
  }
  render() {
    return null;
  }
}
