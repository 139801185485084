import { Api } from 'src/api/helpers/apiBase';
import { SystemMessage } from 'src/apps/applicationMessages';
import { IDropdownItem } from 'src/components/common/Dropdown';
import { IPaginatedData } from 'src/types/PaginatedDataTypes';

import {
  ICodeDescriptionMapping,
  IFeedbackHistory,
  IFeedbackSaveRequest,
  IImgClassPredictRequest,
  ITrademarkImageCategory,
  IVisualSearchEvalResult,
  IVisualSearchRequest,
  IVisualSearchResult,
  TmApplMetadata,
  IImgClassPredictResult,
  IObjectDetectResult,
  IObjectDetectBlock,
} from 'src/types/TrademarkTypes';
import { alertMessage } from 'src/utils/ModalUtils';

const DEFAULT_FEATURE_TYPE = 0;
const DEFAULT_SHAPE_ALPHA = 0.7;
const DEFAULT_READ_METADATA = false;

export enum SearchType {
  CASE_NO = '申請案號',
  CATEGORY = '圖形路徑',
}
export const DROP_DOWN_ITEMS: IDropdownItem[] = [
  {
    name: '申請案號',
    value: SearchType.CASE_NO,
  },
  {
    name: '圖形路徑',
    value: SearchType.CATEGORY,
  },
];

// 商標以圖找圖回饋類型
export enum FeedbackType {
  POS = 'POS',
  NEG = 'NEG',
  NULL = 'NULL',
}

// CSV 匯出評價
export const csvFileName = '商標以圖找圖回饋評價.csv';
export const csvHeaders = [
  { label: 'BatchNo', key: 'batchNo' },
  { label: 'CaseNo', key: 'caseNo' },
  { label: 'SearchResult', key: 'similarCaseNo' },
  { label: 'Rank', key: 'rank' },
  { label: 'Feedback', key: 'feedback' },
];

export const TrademarkService = () => {
  // 取得 商標圖形路徑 自動完成
  const getImagePathAutocomplete = async (path: string) => {
    const res = await Api({ 'Content-Type': 'application/json' }).post(
      'trademark/imgClass/autocomplete',
      {
        path: path,
        level: 2,
      },
    );
    return res.data as Array<ITrademarkImageCategory>;
  };

  /** 取得 visualSearchEval 結果 */
  const visualSearchEval = async (
    category: ITrademarkImageCategory,
    featureType: number = DEFAULT_FEATURE_TYPE,
    shapeAlpha: number = DEFAULT_SHAPE_ALPHA,
  ) => {
    const res = await Api({ 'Content-Type': 'application/json' }).post('trademark/imgClass/eval', {
      categoryId: category.id,
      featureType: featureType,
      shapeAlpha: shapeAlpha,
    });
    return res.data as IVisualSearchEvalResult;
  };
  /** 取得 visualSearch 結果 */
  const visualSearch = async (request: IVisualSearchRequest) => {
    const {
      caseNo,
      categoryId,
      image,
      featureType = DEFAULT_FEATURE_TYPE,
      shapeAlpha = DEFAULT_SHAPE_ALPHA,
      readMetadata = DEFAULT_READ_METADATA,
    } = request;

    const formData = new FormData();
    if (caseNo) {
      formData.append('caseNo', caseNo);
      categoryId && formData.append('categoryId', categoryId.toString());
    } else {
      image && formData.append('image', image);
    }
    formData.append('featureType', featureType.toString());
    formData.append('shapeAlpha', shapeAlpha.toString());
    formData.append('readMetadata', readMetadata.toString());

    const res = await Api({ 'Content-Type': 'application/json' }).post(
      'api/trademark/similar',
      formData,
    );
    return res.data as IPaginatedData<IVisualSearchResult>;
  };

  /** 根據圖檔路徑取得詮釋資料 */
  const getTmMetadatas = async (imgPaths: Array<string>) => {
    const res = await Api().post('api/trademark/metadata/view', imgPaths);
    return res.data as Array<TmApplMetadata>;
  };

  const getImgPathByCaseNo = async (caseNo: string) => {
    const res = await Api({ 'Content-Type': 'application/json' }).get(
      `trademark/imgPath/${caseNo}`,
    );

    return res.data as string;
  };

  const getGoodsClassDescriptions = async () => {
    const res = await Api({ 'Content-Type': 'application/json' }).get('trademark/goodsClassDescs');
    return res.data as ICodeDescriptionMapping;
  };

  const getImgClassDescriptions = async (categories: Array<string>) => {
    const res = await Api().post('trademark/imgClassDescs', categories);
    return res.data as ICodeDescriptionMapping;
  };

  /** 圖形路徑預測 */
  const predictImgClass = async (request: IImgClassPredictRequest) => {
    const { caseNo, image, imgPath } = request;

    const formData = new FormData();

    let url = 'api/trademark/imgClass/predict';
    if (caseNo) {
      formData.append('caseNo', caseNo);
      imgPath && formData.append('imgPath', imgPath);
    } else {
      // 上傳圖檔或圖檔經過裁切
      image && formData.append('image', image);
    }

    const res = await Api({ 'Content-Type': 'application/json' }).post(url, formData);
    return res.data as IImgClassPredictResult;
  };

  /** 取得圖形路徑 imgClass 與 cateogry 之對應表 */
  const getImgClassMapping = async () => {
    const res = await Api({ 'Content-Type': 'application/json' }).post('trademark/imgClassMapping');
    return res.data as ICodeDescriptionMapping;
  };

  /** 提交評價: 儲存商標以圖找圖回饋資料 */
  const saveFeedbacks = async (request: IFeedbackSaveRequest) => {
    let batchNo = null;
    const { caseNo, image, tmFeedbackRecords } = request;

    const formData = new FormData();
    if (caseNo) {
      formData.append('caseNo', caseNo);
    } else {
      // 上傳圖檔或圖檔經過裁切
      image && formData.append('image', image);
    }

    for (var i = 0; i < tmFeedbackRecords.length; i++) {
      formData.append('tmFeedbackRecords[' + i + '].caseNo', tmFeedbackRecords[i].caseNo);
      formData.append('tmFeedbackRecords[' + i + '].rank', tmFeedbackRecords[i].rank.toString());
      formData.append('tmFeedbackRecords[' + i + '].type', tmFeedbackRecords[i].type);
    }

    try {
      let res = await Api({ 'Content-Type': 'application/json' }).post(
        'trademark/feedback',
        formData,
      );
      batchNo = res.data;
      alertMessage(SystemMessage.SEND_FEEDBACK_SUCCESS);
    } catch {
      alertMessage(SystemMessage.SEND_FEEDBACK_ERROR);
    }
    return batchNo;
  };

  /** 取得使用者回饋紀錄(不含明細) */
  const getFeedbacks = async (sourceCaseNo: string) => {
    const res = await Api({ 'Content-Type': 'application/json' }).get('trademark/feedback', {
      params: {
        sourceCaseNo: sourceCaseNo,
      },
    });
    return res.data as Array<IFeedbackHistory>;
  };

  /** 取得使用者回饋紀錄(含明細) */
  const getFeedbackDetails = async (feedbackIds: Array<number>) => {
    const res = await Api({ 'Content-Type': 'application/json' }).post(
      'trademark/feedback/detail',
      feedbackIds,
    );
    return res.data as Array<IFeedbackHistory>;
  };

  /** 物件偵測取得特徵區塊資訊 */
  const detectObject = async (image: File) => {
    const formData = new FormData();
    formData.append('image', image);
    const res = await Api({ 'Content-Type': 'application/json' }).post(
      'api/trademark/objectDetect',
      formData,
    );
    return res.data as IObjectDetectResult;
  };

  const calculateFeatureType: (shape: boolean, color: boolean) => number = (
    shape = false,
    color = false,
  ) => {
    let result = 0;
    if (shape) {
      result += 2;
    }
    if (color) {
      result += 1;
    }

    return result;
  };
  const getImageUrl = (imagePath: string) =>
    process.env.REACT_APP_API_URL + 'api/trademark/img?imagePath=' + encodeURIComponent(imagePath);

  const getDetectObjectBlocks = async (
    image: File,
    scaleX: number,
    scaleY: number,
    originX?: number,
    originY?: number,
  ) => {
    const result = await detectObject(image);
    originX = originX || 0;
    result.blocks = result.blocks.map(
      item =>
        ({
          ...item,
          x1: (originX || 0) + item.x1 * scaleX,
          x2: (originX || 0) + item.x2 * scaleX,
          y1: (originY || 0) + item.y1 * scaleY,
          y2: (originY || 0) + item.y2 * scaleY,
        } as IObjectDetectBlock),
    );
    return result;
  };

  return {
    getImagePathAutocomplete,
    visualSearchEval,
    visualSearch,
    getTmMetadatas,
    getImgPathByCaseNo,
    getGoodsClassDescriptions,
    getImgClassDescriptions,
    getImgClassMapping,
    calculateFeatureType,
    getImageUrl,
    saveFeedbacks,
    getFeedbacks,
    getFeedbackDetails,
    predictImgClass,
    detectObject,
    getDetectObjectBlocks,
    DEFAULT_FEATURE_TYPE,
    DEFAULT_SHAPE_ALPHA,
  };
};
