import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import DeleteModal from 'src/components/ui/interactive/modal/custom/DeleteModal';
import EditCollectionModal from 'src/components/ui/interactive/modal/custom/EditCollectionModal';
import useOutSideClick from 'src/hooks/useOutSideClick';
import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import { connect } from 'react-redux';
import { ReduxAppState } from 'src/redux/reducers';
import { openCustomModal, closeCustomModal } from 'src/redux/actions/modalAction';
import { CustomModalType } from 'src/types/ModalTypes';

// Menu
const PatentMenuContainer = styled.div`
  position: relative;
`;
const PatentMenu = styled.div`
  z-index: 100;
  padding: 4px 0;
  width: 120px;
  right: -8px;
  position: absolute;
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
`;
const PatentMenuItem = styled.div`
  padding: 0 16px;
  height: 28px;
  color: #424d52;
  font-size: 14px;
  font-style: normal;
  cursor: pointer;
  &:hover {
    background-color: #edf6f7;
  }
`;

interface IReduxMappingProps {
  isEditCollectionModalOpen: boolean;
  isDeleteCollectionModalOpen: boolean;
  openCustomModal: (customModalType: CustomModalType) => void;
  closeCustomModal: () => void;
}

interface IProps extends IReduxMappingProps {
  show: boolean;
  setShow: (show: boolean) => void;
  collectId: number;
  patentDataId: number;
  submit: (option: string, data: any) => void;
}

const CollectActionMenu: React.FC<IProps> = props => {
  const [collect, setCollect] = useState<
    Array<{
      id: number;
      name: string;
      collected: boolean;
    }>
  >([]);
  let [useEffectTrigger, setUseEffectTrigger] = useState(false);

  useEffect(() => {
    const fetchCollectData = async () => {
      try {
        const res = await Api().get(`patent/collection/category`, {
          params: { patentDataId: props.patentDataId },
        });
        setCollect(res.data);
      } catch (error) {
        errorHandler(error);
      }
    };

    fetchCollectData();
  }, [props.patentDataId, useEffectTrigger]);

  const node = useOutSideClick(props);

  const submitEditCollect = async (data: any) => {
    const newData = data.filter(
      (item: { id: any; collected: any }) =>
        !collect.some(prev => prev.id === item.id && prev.collected === item.collected),
    );

    const result = await newData.map(async (item: { collected: any; id: any }) => {
      try {
        if (item.collected) {
          await Api().put(`patent/collection/category/${item.id}/${props.patentDataId}`);
        } else {
          await Api().delete(`patent/collection/category/${item.id}/${props.patentDataId}`);
        }
      } catch (error) {
        errorHandler(error);
      }
    });

    props.submit('EditPatentCollect', result);
  };

  const submitRemove = async () => {
    try {
      const res = await Api().delete(
        `patent/collection/category/${props.collectId}/${props.patentDataId}`,
      );

      if (res.status === 200) {
        props.submit('DeletePatentCollect', null);
        props.closeCustomModal();
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const submitAddCollect = () => {
    setUseEffectTrigger(prev => !prev);
  };

  return (
    <PatentMenuContainer ref={node as any}>
      <PatentMenu>
        <PatentMenuItem onClick={() => props.openCustomModal(CustomModalType.EDIT_COLLECTION)}>
          變更收藏分類
        </PatentMenuItem>
        {props.isEditCollectionModalOpen && collect && (
          <EditCollectionModal
            patentDataId={props.patentDataId}
            collectData={collect}
            submit={submitEditCollect}
            submitAddCollect={submitAddCollect}
          />
        )}

        <PatentMenuItem onClick={() => props.openCustomModal(CustomModalType.DELETE_COLLECTION)}>
          刪除
        </PatentMenuItem>
        {props.isDeleteCollectionModalOpen && (
          <DeleteModal
            message="刪除收藏後，您已分享的筆記與已註記的標籤均會消失，請問是否要刪除收藏？"
            description="若重新收藏此篇專利，已註記的筆記與標籤仍會恢復資料。"
            submit={submitRemove}
          />
        )}
      </PatentMenu>
    </PatentMenuContainer>
  );
};

const mapStateToProps = (state: ReduxAppState) => {
  return {
    isEditCollectionModalOpen:
      state.modalReducer.customModalType === CustomModalType.EDIT_COLLECTION,
    isDeleteCollectionModalOpen:
      state.modalReducer.customModalType === CustomModalType.DELETE_COLLECTION,
  };
};

const mapDispatchToProps = {
  openCustomModal,
  closeCustomModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CollectActionMenu);
