import React from 'react';
import styled from 'styled-components';
import cluster from 'src/assets/images/manual/search-result-analysis/cluster.png';

const ImgContainer = styled.div<{ height: string; imageUrl: string }>`
  position: relative;
  height: ${props => props.height}px;
  padding: 10px 0;
  background-image: url(${props => props.imageUrl});
  background-size: contain;
  background-repeat: no-repeat;
`;
export default function SearchResultAnalysisManual() {
  return (
    <>
      <p>
        檢索結果中，依各專利文件關鍵詞彙所佔權重，計算關聯出主題知識地圖架構，點選詞彙進一步限縮檢索結果。
      </p>
      <br />
      <ImgContainer height="700" imageUrl={cluster} />
    </>
  );
}
