import { AxiosResponse, AxiosError } from 'axios';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { ReduxAppState } from 'src/redux/reducers';
import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import {
  NAVIGATE_TO_NEXT_PAGE,
  SELECT_DATE_RANGE,
  CHANGE_SORT_ORDER,
  FETCH_APP_NUM_LIST_START,
  FETCH_APP_NUM_LIST_SUCCESS,
  FETCH_APP_NUM_LIST_ERROR,
  ByImageSearchActionTypes,
} from './type';
import { ILocBasic } from 'src/types/loc';
import { getLocClasses } from 'src/components/search/ByImage';

export function navigateToNextPage(): ByImageSearchActionTypes {
  return {
    type: NAVIGATE_TO_NEXT_PAGE,
  };
}

export function selectDateRange(dateRange: string): ByImageSearchActionTypes {
  return {
    type: SELECT_DATE_RANGE,
    dateRange: dateRange,
  };
}

export function changeSortOrder(sortByDate: string): ByImageSearchActionTypes {
  return {
    type: CHANGE_SORT_ORDER,
    sortByDate: sortByDate,
  };
}

export const fetchAppNumList = (
  image: File,
  locClasses: Array<ILocBasic>,
): ThunkAction<void, ReduxAppState, null, Action<string>> => async dispatch => {
  dispatch(fetchAppNumListStart());

  try {
    let formData = new FormData();
    formData.append('image', image);
    formData.append('locClasses', locClasses.map(item => getLocClasses(item)).join(','));

    const response = await Api({
      'Content-Type': 'multipart/form-data',
    }).post('patent/similar/image/list', formData);

    dispatch(fetchAppNumListSuccess(response));
  } catch (error) {
    dispatch(fetchAppNumListFailure(error));
  }
};

function fetchAppNumListStart(): ByImageSearchActionTypes {
  return {
    type: FETCH_APP_NUM_LIST_START,
  };
}

function fetchAppNumListSuccess(response: AxiosResponse): ByImageSearchActionTypes {
  return {
    type: FETCH_APP_NUM_LIST_SUCCESS,
    response: response,
  };
}

function fetchAppNumListFailure(error: AxiosError): ByImageSearchActionTypes {
  errorHandler(error);
  return {
    type: FETCH_APP_NUM_LIST_ERROR,
    error: error,
  };
}
