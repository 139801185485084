import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import {
  FaPencilAlt,
  FaTrashAlt,
  FaShareAlt,
  // FaAngleDoubleUp,
  // FaAngleDoubleDown,
} from 'react-icons/fa';

import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import { isCompanyMember } from 'src/apps/auth/roleChecker';

import DeleteModal from 'src/components/ui/interactive/modal/custom/DeleteModal';
import EditShareForm from 'src/components/ui/interactive/dropdown/EditShareForm';
import { ultrawhite, ultrawhitergba, ultrablackrgba } from 'src/style/theme/Color';

import { connect } from 'react-redux';
import { ReduxAppState } from 'src/redux/reducers';
import { openCustomModal, closeCustomModal } from 'src/redux/actions/modalAction';
import { CustomModalType } from 'src/types/ModalTypes';
import { ITokenPayload } from 'src/apps/auth/Auth';
import useLoginChecker from 'src/apps/auth/useLoginChecker';
import SubscribeSlideToggle from 'src/components/ui/interactive/checkbox/SubscribeCheckbox';

// interface IFoldProps {
//   fold: boolean;
//   collapse: boolean;
// }

const Container = styled.div`
  border-radius: 4px;
  box-shadow: 0 0 50px 0 ${ultrablackrgba(0.1)};
`;
const Header = styled.div`
  padding: 0 0 16px;
  color: ${ultrawhite};
  background-image: linear-gradient(95deg, #2ec3c2, #0cafcc);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;
const UnFoldableArea = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
`;
const HeaderText = styled.div`
  padding: 16px 0 0px 32px;
  width: 70%;
`;
const HeaderRoute = styled.div`
  font-size: ${ultrawhitergba(0.8)};
`;
const Feature = styled.div`
  height: 36px;
  line-height: 36px;
  font-size: 24px;
  font-weight: bold;
  span {
    font-weight: normal;
  }
`;

// const FoldableInfoDiv = styled.div<IFoldProps>`
//   padding: ${props =>
//     props.fold
//       ? props.collapse
//         ? '8px 76px 8px 32px'
//         : '16px 76px 16px 32px'
//       : '8px 76px 8px 32px'};
//   height: ${props => props.collapse && '40px'};
//   display: flex;
//   position: relative;
//   color: #ffffff;
//   background-color: ${props =>
//     props.fold ? 'rgba(255, 255, 255, 0.2)' : null};
//   line-height: 24px;
//   span {
//     display: block;
//     white-space: nowrap;
//   }
// `;
// const SourceListStyle = styled.div<IFoldProps>`
//   overflow: ${props => (props.collapse ? 'hidden' : 'visible')};
//   white-space: ${props => (props.fold ? null : 'nowrap')};
//   text-overflow: ellipsis;
// `;
// const BtnFold = styled.div<{ fold: boolean }>`
//   float: right;
//   display: ${props => (props.fold ? 'block' : 'none')};
//   width: 28px;
//   height: 26px;
//   bottom: 0;
//   right: 24px;
//   position: absolute;
//   border-top-left-radius: 4px;
//   border-top-right-radius: 4px;
//   color: #ffffff;
//   background-color: rgba(255, 255, 255, 0.2);
//   text-align: center;
//   cursor: pointer;
//   svg {
//     margin: 6px 0 0;
//   }
// `;
const Anchor = styled.div`
  width: 100%;
  padding: 0 56px 0 0;
`;
const HeaderFunctions = styled.div`
  float: right;
  display: flex;
  position: relative;
`;
const HeaderFunction = styled.div`
  margin: 0 0 0 20px;
  display: flex;
  cursor: pointer;
  i {
    display: block;
    margin: 2px 8px 0 0;
  }
  > span {
    margin-right: 10px;
  }
`;
const Subheaders = styled.div`
  height: 38px;
  display: flex;
  align-items: center;
  color: #979ea1;
  background-color: #edf6f7;
  span {
    width: calc(100% - 56px);
    margin: 20px 32px 20px 0;
  }
`;
const Title = styled.div`
  padding: 0 0 0 32px;
  width: 55%;
`;
const Content = styled.div`
  padding: 0 0 24px;
  min-height: 450px;
`;

interface IReduxMappingProps {
  member: ITokenPayload;
  isDeleteRssFollowModalOpen: boolean;
  openCustomModal: (customModalType: CustomModalType) => void;
  closeCustomModal: () => void;
}

interface IProps extends IReduxMappingProps {
  sector: string;
  feature: string;
  keyword: string;
  srcs: string[];
  children: JSX.Element;
  subscribeId: number;
  notification: boolean;
  noSubscribe: boolean;
  handleSubscribePaneDisplay: (displaySubscribePane: boolean, subscribeIdToEdit: number) => void;
  submitSubscribe: (option: string, newData: any) => void;
  isShare: boolean;
  shareUser?: string;
  subscribeName: string;
}

function SubscribeContent(props: IProps) {
  const isLogin = !!useLoginChecker();
  const [share, setShare] = useState(false);

  const {
    notification,
    subscribeId,
    isShare,
    handleSubscribePaneDisplay,
    submitSubscribe,
    closeCustomModal: propsCloseCustomModal,
  } = props;
  /** Phase2 function */
  // let [collapse, setCollapse] = useState(false);
  // const collapseHandler = () => {
  //   setCollapse(prev => !prev);
  // };

  // let [fold, setFold] = useState(false);
  // const srcRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  // const lineRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  // const FoldableInfo = (props: {
  //   lineRef: React.MutableRefObject<HTMLDivElement>;
  //   srcRef: React.MutableRefObject<HTMLDivElement>;
  //   srcs: string[];
  // }) => (
  //   <div ref={props.lineRef}>
  //     <FoldableInfoDiv fold={fold} collapse={collapse}>
  //       <span>範圍：</span>
  //       <SourceListStyle fold={fold} collapse={collapse}>
  //         <div ref={props.srcRef}>{props.srcs.join('、')}</div>
  //       </SourceListStyle>
  //       <BtnFold fold={fold} onClick={collapseHandler}>
  //         {collapse ? <FaAngleDoubleDown /> : <FaAngleDoubleUp />}
  //       </BtnFold>
  //     </FoldableInfoDiv>
  //   </div>
  // );
  // useEffect(() => {
  //   setFold(
  //     srcRef.current.scrollWidth > lineRef.current.clientWidth * 0.8,
  //   );
  //   window.addEventListener('resize', () => {
  //     setTimeout(() => {
  //       srcRef.current &&
  //         setFold(
  //           srcRef.current.scrollWidth >
  //             lineRef.current.clientWidth * 0.8,
  //         );
  //     }, 500);
  //   });
  // }, [srcRef, lineRef]);

  const submitNotify = useCallback(async () => {
    try {
      const patchBody = !notification;
      const response = await Api({ 'Content-Type': 'application/json' }).patch(
        `rss/follow/${subscribeId}/${isShare ? 'share' : 'notify'}`,
        patchBody,
      );
      if (response.status === 200) {
        submitSubscribe('EditNotification', patchBody);
      }
    } catch (error) {
      errorHandler(error);
    }
  }, [notification, subscribeId, isShare, submitSubscribe]);

  const submitRemove = useCallback(async () => {
    try {
      if (isShare) {
        await Api().delete(`rss/follow/${subscribeId}/share`);
        submitSubscribe('DeleteSubscribeShare', null);
      } else {
        await Api().delete(`rss/follow/${subscribeId}`);
        submitSubscribe('DeleteSubscribe', null);
      }

      propsCloseCustomModal();
    } catch (error) {
      errorHandler(error);
    }
  }, [subscribeId, isShare, submitSubscribe, propsCloseCustomModal]);

  return (
    <Container>
      <Header>
        <UnFoldableArea>
          <HeaderText>
            <HeaderRoute>
              首頁&nbsp;/&nbsp;{props.sector}&nbsp;/&nbsp;
              {props.feature}&nbsp;/&nbsp;{props.subscribeName}
            </HeaderRoute>
            <Feature>
              {props.subscribeName}&ensp;
              {props.shareUser && <span>來自{props.shareUser}的分享</span>}
            </Feature>
          </HeaderText>
          <Anchor>
            {!props.noSubscribe && (
              <HeaderFunctions>
                {!isShare && (
                  <>
                    <HeaderFunction onClick={() => handleSubscribePaneDisplay(true, subscribeId)}>
                      <i>
                        <FaPencilAlt />
                      </i>
                      編輯
                    </HeaderFunction>
                    {isCompanyMember() && (
                      <>
                        <HeaderFunction onClick={() => setShare(prev => !prev)}>
                          <i>
                            <FaShareAlt />
                          </i>
                          分享
                        </HeaderFunction>
                        {share && (
                          <EditShareForm show={share} setShow={setShare} id={subscribeId} />
                        )}
                      </>
                    )}
                  </>
                )}
                <HeaderFunction
                  onClick={() =>
                    props.openCustomModal(
                      isShare
                        ? CustomModalType.DELETE_RSS_FOLLOW_SHARE
                        : CustomModalType.DELETE_RSS_FOLLOW,
                    )
                  }
                >
                  <i>
                    <FaTrashAlt />
                  </i>
                  刪除
                </HeaderFunction>
                {isLogin && (
                  <HeaderFunction>
                    <span>訂閱電子報</span>
                    <SubscribeSlideToggle
                      type={'newsNotify'}
                      idx={subscribeId}
                      checked={notification}
                      change={submitNotify}
                    />
                  </HeaderFunction>
                )}
                {props.isDeleteRssFollowModalOpen && (
                  <DeleteModal
                    message="刪除此自訂頻道後，您在內自訂的頻道新聞均會消失，請問是否要刪除頻道？"
                    submit={submitRemove}
                  />
                )}
              </HeaderFunctions>
            )}
          </Anchor>
        </UnFoldableArea>
        {/** Phase2 function */
        /* <FoldableInfo
          lineRef={lineRef}
          srcRef={srcRef}
          srcs={props.srcs}
        /> */}
      </Header>
      {!props.noSubscribe && (
        <Subheaders>
          <Title>新聞內容</Title>
        </Subheaders>
      )}
      <Content>{props.children}</Content>
    </Container>
  );
}

const mapStateToProps = (state: ReduxAppState) => {
  return {
    isDeleteRssFollowModalOpen:
      state.modalReducer.customModalType === CustomModalType.DELETE_RSS_FOLLOW ||
      state.modalReducer.customModalType === CustomModalType.DELETE_RSS_FOLLOW_SHARE,
    member: state.memberReducer.memberPayload,
  };
};

const mapDispatchToProps = {
  openCustomModal,
  closeCustomModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SubscribeContent);
