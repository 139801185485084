import { AxiosResponse, AxiosError } from 'axios';
import { IPatentFacet, IPostIpcDescriptions, IBilingualTerm } from 'src/types/PatentSearchTypes';

// 分組統計資料
export const FETCH_FACETS_START = 'FETCH_FACETS_START';
export const FETCH_FACETS_SUCCESS = 'FETCH_FACETS_SUCCESS';
export const FETCH_FACETS_ERROR = 'FETCH_FACETS_ERROR';
// IPC 中文敘述
export const FETCH_IPC_DESCRIPTIONS_START = 'FETCH_IPC_DESCRIPTIONS_START';
export const FETCH_IPC_DESCRIPTIONS_SUCCESS = 'FETCH_IPC_DESCRIPTIONS_SUCCESS';
export const FETCH_IPC_DESCRIPTIONS_ERROR = 'FETCH_IPC_DESCRIPTIONS_ERROR';
// 雙語詞彙
export const FETCH_BILINGUAL_TERMS_START = 'FETCH_BILINGUAL_TERMS_START';
export const FETCH_BILINGUAL_TERMS_SUCCESS = 'FETCH_BILINGUAL_TERMS_SUCCESS';
export const FETCH_BILINGUAL_TERMS_ERROR = 'FETCH_BILINGUAL_TERMS_ERROR';

export interface PatentFilterState {
  isFacetsLoading: boolean;
  facets: Array<IPatentFacet>;
  isIpcDescriptionsLoading: boolean;
  ipcDescriptions: IPostIpcDescriptions;
  isBilingualTermsLoading: boolean;
  bilingualTerms: Array<IBilingualTerm>;
}

interface FetchFacetsStartAction {
  type: typeof FETCH_FACETS_START;
}

interface FetchFacetsSuccessAction {
  type: typeof FETCH_FACETS_SUCCESS;
  response: AxiosResponse;
}

interface FetchFacetsErrorAction {
  type: typeof FETCH_FACETS_ERROR;
  error: AxiosError;
}

interface FetchIpcDescriptionsStartAction {
  type: typeof FETCH_IPC_DESCRIPTIONS_START;
}

interface FetchIpcDescriptionsSuccessAction {
  type: typeof FETCH_IPC_DESCRIPTIONS_SUCCESS;
  response: AxiosResponse;
}

interface FetchIpcDescriptionsErrorAction {
  type: typeof FETCH_IPC_DESCRIPTIONS_ERROR;
  error: AxiosError;
}

interface FetchBilingualTermsStartAction {
  type: typeof FETCH_BILINGUAL_TERMS_START;
}

interface FetchBilingualTermsSuccessAction {
  type: typeof FETCH_BILINGUAL_TERMS_SUCCESS;
  response: AxiosResponse;
}

interface FetchBilingualTermsErrorAction {
  type: typeof FETCH_BILINGUAL_TERMS_ERROR;
  error: AxiosError;
}

export type PatentFilterActionTypes =
  | FetchFacetsStartAction
  | FetchFacetsSuccessAction
  | FetchFacetsErrorAction
  | FetchIpcDescriptionsStartAction
  | FetchIpcDescriptionsSuccessAction
  | FetchIpcDescriptionsErrorAction
  | FetchBilingualTermsStartAction
  | FetchBilingualTermsSuccessAction
  | FetchBilingualTermsErrorAction;
