import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';
import { Backdrop } from 'src/style/ModalStyle';
import {
  blue5,
  blue6,
  iceblue1,
  iceblue5,
  iceblue7,
  iceblue8,
  ultrawhite,
  ultrawhitergba,
} from 'src/style/theme/Color';
import { Spinner } from 'src/components/ui/interactive/Spinner';
import { zModal } from 'src/style/theme/Z-Index';
import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import { IPatentStatRangeMap } from 'src/types/api/IndexApiTypes';
import { dateFormatter, yearFormatter } from 'src/utils/Formatter';
import { getCountryName, getPatentKindName } from 'src/utils/PatentUtils';

const Container = styled.div`
  position: relative;
  border-radius: 4px;
  background-color: #ffffff;
  width: 60%;
  max-width: 800px;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 32px;
  svg {
    font-size: 20px;
    margin-left: 24px;
    cursor: pointer;
  }
`;

const Title = styled.div`
  color: ${iceblue1};
  font-size: 20px;
  font-weight: bold;
`;

const Body = styled.div`
  height: calc(100vh - 200px);
  overflow: auto;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    box-shadow: inset 0 0 8px 8px ${blue6};
    border: solid 2px Transparent;
  }
`;

const Table = styled.table`
  width: calc(100% - 64px);
  margin: 0 32px 32px 32px;
  border-collapse: collapse;
`;
const Tr = styled.tr`
  border-bottom: 1px solid ${iceblue8};
`;
const TitleTr = styled(Tr)`
  color: ${iceblue5};
  border-color: ${blue5};
`;
const DivisionLine = styled(Tr)`
  border-color: ${iceblue7};
`;
const Td = styled.td`
  padding: 6px 30px 6px 60px;
  font-size: 16px;
`;
const CountryTd = styled(Td)`
  text-align: center;
  padding-left: 16px;
`;
const CodeTd = styled(Td)`
  padding: 6px 0 6px 28px;
  width: 133px;
`;

const FadeoutMask = styled.div`
  position: absolute;
  bottom: 0;
  height: 30px;
  width: 100%;
  border-radius: 0 0 4px 4px;
  background-image: linear-gradient(to bottom, ${ultrawhitergba(0)}, ${ultrawhite});
`;

interface IProps {
  onClose: () => void;
}

const modal = document.getElementById('modal-custom');
const portalDiv = modal ? modal : document.createElement('div');

const PatentDataScopeModal: React.FC<IProps> = ({ onClose }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [patentStatRange, setPatentStatRange] = useState<IPatentStatRangeMap>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Api()
          .get('patent/stat/range')
          .then(res => {
            const data: IPatentStatRangeMap = res.data;
            return data;
          });
        setPatentStatRange(response);
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsLoading(false);
      }
    };

    isLoading && fetchData();
  }, [isLoading]);

  const divisionLine = (
    <DivisionLine>
      <td />
      <td />
      <td />
      <td />
      <td />
    </DivisionLine>
  );

  return ReactDOM.createPortal(
    <Backdrop zIndex={zModal.custom} alignItems="center">
      <Container>
        <Header>
          <Title>專利資料範圍</Title>

          <FaTimes color={iceblue5} onClick={() => onClose()} />
        </Header>
        <Body>
          {isLoading ? (
            <Spinner width={'160px'} />
          ) : (
            <>
              <Table>
                <thead>
                  <TitleTr>
                    <Td>國家</Td>
                    <CodeTd>資料內容</CodeTd>
                    <CodeTd>資料起訖年</CodeTd>
                    <Td>最新進度</Td>
                  </TitleTr>
                </thead>
                <tbody>
                  {patentStatRange &&
                    patentStatRange.TW.map(
                      data =>
                        data.country === 'TW' && (
                          <Tr key={data.country + '_' + data.kindCode}>
                            {data.kindCode === 'A' && (
                              <CountryTd rowSpan={patentStatRange.TW.length}>
                                {getCountryName(data.country)}
                              </CountryTd>
                            )}

                            <CodeTd>{getPatentKindName(data.kindCode)}</CodeTd>
                            <CodeTd>
                              {yearFormatter(data.startDate)} ~ {yearFormatter(data.endDate)}
                            </CodeTd>
                            <Td>{dateFormatter(data.endDate)}</Td>
                          </Tr>
                        ),
                    )}
                  {divisionLine}

                  {patentStatRange &&
                    patentStatRange.US.map(
                      data =>
                        data.country === 'US' && (
                          <Tr key={data.country + '_' + data.kindCode}>
                            {data.kindCode === 'A' && (
                              <CountryTd rowSpan={patentStatRange.US.length}>
                                {getCountryName(data.country)}
                              </CountryTd>
                            )}

                            <CodeTd>{getPatentKindName(data.kindCode)}</CodeTd>
                            <CodeTd>
                              {yearFormatter(data.startDate)} ~ {yearFormatter(data.endDate)}
                            </CodeTd>
                            <Td>{dateFormatter(data.endDate)}</Td>
                          </Tr>
                        ),
                    )}
                  {divisionLine}

                  {patentStatRange &&
                    patentStatRange.EP.map(
                      data =>
                        data.country === 'EP' && (
                          <Tr key={data.country + '_' + data.kindCode}>
                            {data.kindCode === 'A' && (
                              <CountryTd rowSpan={patentStatRange.EP.length}>
                                {getCountryName(data.country)}
                              </CountryTd>
                            )}

                            <CodeTd>{getPatentKindName(data.kindCode)}</CodeTd>
                            <CodeTd>
                              {yearFormatter(data.startDate)} ~ {yearFormatter(data.endDate)}
                            </CodeTd>
                            <Td>{dateFormatter(data.endDate)}</Td>
                          </Tr>
                        ),
                    )}
                  {divisionLine}

                  {patentStatRange &&
                    patentStatRange.JP.map(
                      data =>
                        data.country === 'JP' && (
                          <Tr key={data.country + '_' + data.kindCode}>
                            {data.kindCode === 'A' && (
                              <CountryTd rowSpan={patentStatRange.JP.length}>
                                {getCountryName(data.country)}
                              </CountryTd>
                            )}

                            <CodeTd>{getPatentKindName(data.kindCode)}</CodeTd>
                            <CodeTd>
                              {yearFormatter(data.startDate)} ~ {yearFormatter(data.endDate)}
                            </CodeTd>
                            <Td>{dateFormatter(data.endDate)}</Td>
                          </Tr>
                        ),
                    )}
                  {divisionLine}

                  {patentStatRange &&
                    patentStatRange.CN.map(
                      data =>
                        data.country === 'CN' && (
                          <Tr key={data.country + '_' + data.kindCode}>
                            {data.kindCode === 'A' && (
                              <CountryTd rowSpan={patentStatRange.CN.length}>
                                {getCountryName(data.country)}
                              </CountryTd>
                            )}

                            <CodeTd>{getPatentKindName(data.kindCode)}</CodeTd>
                            <CodeTd>
                              {yearFormatter(data.startDate)} ~ {yearFormatter(data.endDate)}
                            </CodeTd>
                            <Td>{dateFormatter(data.endDate)}</Td>
                          </Tr>
                        ),
                    )}
                  {divisionLine}

                  {patentStatRange &&
                    patentStatRange.KR.map(
                      data =>
                        data.country === 'KR' && (
                          <Tr key={data.country + '_' + data.kindCode}>
                            {data.kindCode === 'A' && (
                              <CountryTd rowSpan={patentStatRange.KR.length}>
                                {getCountryName(data.country)}
                              </CountryTd>
                            )}

                            <CodeTd>{getPatentKindName(data.kindCode)}</CodeTd>
                            <CodeTd>
                              {yearFormatter(data.startDate)} ~ {yearFormatter(data.endDate)}
                            </CodeTd>
                            <Td>{dateFormatter(data.endDate)}</Td>
                          </Tr>
                        ),
                    )}
                  {divisionLine}
                </tbody>
              </Table>
              <FadeoutMask />
            </>
          )}
        </Body>
      </Container>
    </Backdrop>,
    portalDiv,
  );
};

export default PatentDataScopeModal;
