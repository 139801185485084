import React from 'react';
import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components';
import { FaArrowLeft } from 'react-icons/fa';

import LogoPng from 'src/assets/images/ipkm-logo-beta.png';

import { Backdrop } from 'src/style/ModalStyle';
import { iceblue1, blue2, blue6rgba, ultrawhite } from 'src/style/theme/Color';
import { zModal } from 'src/style/theme/Z-Index';

import { connect } from 'react-redux';
import { ReduxAppState } from 'src/redux/reducers';
import { openMobileRedirectModal } from 'src/redux/actions/modalAction';

const Container = styled.div`
  position: relative;
  background-color: ${ultrawhite};
  width: 70%;
  height: 100%;
  max-width: 312px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 20px 48px;

  svg {
    font-size: 20px;
    cursor: pointer;
  }
`;

const Logo = styled.figcaption`
  padding: 6px 0;
  img {
    width: 96px;
    height: 48px;
  }

  :hover {
    cursor: pointer;
  }
`;

const MenuItem = styled.div<{ isActive?: boolean }>`
  ${props =>
    props.isActive
      ? css`
          color: ${blue2};
          font-weight: 600;
        `
      : css`
          color: ${iceblue1};
        `};

  font-size: 16px;
  padding: 16px 20px 16px 48px;

  :hover {
    cursor: pointer;
    background-color: ${blue6rgba(0.4)};
  }
`;

interface IReduxMappingProps {
  openMobileRedirectModal: (path: string) => void;
}

interface IProps extends IReduxMappingProps {
  onClose: () => void;
  activeNavSection?: string;
}

const modal = document.getElementById('modal-custom');
const portalDiv = modal ? modal : document.createElement('div');

const navs = [
  { section: '專利專區', path: '/showcase' },
  { section: '專利檢索', path: '/search' },
  { section: '新聞專區', path: '/news' },
  { section: '開放資料', path: '/data' },
  { section: '專欄文章', path: '/article' },
  { section: '學界專家', path: '/expert' },
  { section: '常見問題', path: '/question' },
];

const MobileMenuModal: React.FC<IProps> = ({
  activeNavSection,
  onClose,
  openMobileRedirectModal,
}) => {
  return ReactDOM.createPortal(
    <Backdrop zIndex={zModal.custom} justifyContent="flex-start">
      <Container>
        <Header>
          <Logo onClick={() => openMobileRedirectModal('/')}>
            <img src={LogoPng} alt="Logo" />
          </Logo>
          <FaArrowLeft color={iceblue1} onClick={onClose} />
        </Header>
        <div>
          {navs.map(item => (
            <MenuItem
              key={item.section}
              isActive={item.section === activeNavSection}
              onClick={() => openMobileRedirectModal(item.path)}
            >
              {item.section}
            </MenuItem>
          ))}
        </div>
      </Container>
    </Backdrop>,
    portalDiv,
  );
};

const mapStateToProps = (state: ReduxAppState) => {
  return {
    redirectModal: state.modalReducer.redirectModal,
  };
};

const mapDispatchToProps = {
  openMobileRedirectModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MobileMenuModal);
