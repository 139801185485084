import { Api } from 'src/api/helpers/apiBase';
import { ILocBasic } from 'src/types/loc';

export const LocService = () => {
  // 取得 LOC 自動推薦
  const getLocAutocomplete = async (twLoc: string) => {
    const res = await Api({ 'Content-Type': 'application/json' }).post('loc/autocomplete', {
      twLoc: twLoc,
    });
    return res.data as Array<ILocBasic>;
  };
  // 取得 LOC 列表
  const getLoc = async (locClasses: string) => {
    const res = await Api({ 'Content-Type': 'application/json' }).post('loc/list', {
      locClasses: locClasses,
    });
    return res.data as ILocBasic;
  };

  return {
    getLocAutocomplete,
    getLoc,
  };
};
