import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 16px 16px 16px 32px;
`;

const Column = styled.div<{ columns: number }>`
  flex: 1;
  max-width: calc(100% / ${props => props.columns} - 16px);
  margin-right: 16px;
`;

const ItemBox = styled.div`
  flex: 1;
  margin-bottom: 16px;
`;

interface IProps {
  columns: number;
  elements: Array<JSX.Element>;
}

/** 瀑布流網格布局 */
const WaterfallGrid: React.FC<IProps> = props => {
  const { columns } = props;

  const renderColumn = (cols: number): Array<JSX.Element> => {
    let columnList = [];
    for (let i = 0; i < cols; i++) {
      columnList.push(
        <Column key={i} columns={columns}>
          {props.elements.map(
            (item, idx) => idx % cols === i && <ItemBox key={idx}>{item}</ItemBox>,
          )}
        </Column>,
      );
    }
    return columnList;
  };

  return <Container>{renderColumn(columns)}</Container>;
};

export default WaterfallGrid;
