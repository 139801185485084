import React from 'react';
import styled from 'styled-components';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import {
  IGetRecommendPatents,
  IGetPatentIntroLatest,
  IGetPatentIntroSubscribed,
} from 'src/types/api/IndexApiTypes';

import { dateFormatter } from 'src/utils/Formatter';

import {
  Container,
  Table,
  HRow,
  // HOne,
  HTwo,
  HThree,
  TRow,
  ReadMore,
} from 'src/style/IndexTabStyle';
import {
  blue8,
  iceblue6,
  iceblue8,
  ultrawhite,
  // fontblack1
} from 'src/style/theme/Color';

const ACTIVE_BG_COLOR = blue8;

/** 隱藏產業類別暫時使用，之後須顯示類別時請移除再改回使用原本的 HTwo。 */
const TempHTwo = styled(HTwo)`
  padding-left: 32px;
`;

const ListContainer = styled(Container)`
  width: 100%;
`;
// const HCategory = styled(HOne)``;
const HTitle = styled(TempHTwo)``;
const HDate = styled(HThree)``;
const Item = styled(TRow)<{ active: boolean }>`
  border-bottom: 1px solid ${iceblue8};
  background-color: ${props => (props.active ? ACTIVE_BG_COLOR : ultrawhite)};
  cursor: pointer;
`;
// const Category = styled(HOne)`
//   white-space: nowrap;
//   color: ${fontblack1};
// `;
const Title = styled(TempHTwo)``;
const MultiLineEllipsisText = styled.div<{ active: boolean }>`
  margin: 0 -1rem 0 0;
  padding: 0 1rem 0 0;
  width: calc(100% - 1rem);
  max-height: 2.4rem;
  line-height: 1.2rem;
  overflow: hidden;
  position: relative;
  word-break: break-all;
  :before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
  }
  :after {
    content: '';
    position: absolute;
    right: 0;
    width: 1rem;
    height: 1rem;
    margin-top: 0.2rem;
    background: ${props => (props.active ? ACTIVE_BG_COLOR : ultrawhite)};
  }
`;
const Date = styled(HThree)`
  color: ${iceblue6};
`;

interface IProps extends RouteComponentProps {
  header: string[];
  patents: Array<IGetRecommendPatents | IGetPatentIntroLatest | IGetPatentIntroSubscribed>;
  hover: number;
  setHover: (idx: number) => void;
  loading: boolean;
  gaClass: string;
  readMoreUrl: string;
}

const ListItems: React.FC<IProps> = props => {
  const handleGoToPatentView = (id: string) => {
    props.history.push(`/patent/${id}/view`);
  };
  return (
    <ListContainer>
      <Table>
        <HRow>
          {/* <HCategory>{props.header[0]}</HCategory> */}
          <HTitle>{props.header[1]}</HTitle>
          <HDate>{props.header[2]}</HDate>
        </HRow>
        {props.patents.map((patent, idx) => {
          const activeBool = idx === props.hover;
          return idx < 5 ? (
            <Item
              key={patent.id}
              active={activeBool}
              onMouseOver={() => props.setHover(idx)}
              onClick={() => handleGoToPatentView(patent.id)}
              className={props.gaClass}
            >
              {/* <Category>{patent.industryName}</Category> */}
              <Title>
                <MultiLineEllipsisText active={activeBool} className="title">
                  {patent.bibliographic.inventionTitle.title.join(' | ')}
                </MultiLineEllipsisText>
              </Title>
              <Date>
                {patent.bibliographic.publicationReference &&
                patent.bibliographic.publicationReference.documentId &&
                patent.bibliographic.publicationReference.documentId.date
                  ? dateFormatter(patent.bibliographic.publicationReference.documentId.date)
                  : '無日期資訊'}
              </Date>
            </Item>
          ) : (
            ''
          );
        })}
      </Table>
      <ReadMore onClick={() => props.history.push(props.readMoreUrl)} className={props.gaClass}>
        顯示全部
      </ReadMore>
    </ListContainer>
  );
};

export default withRouter(ListItems);
