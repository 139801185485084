import React, { useState, useRef, useLayoutEffect, useEffect } from 'react';
import { white2 } from 'src/style/theme/Color';
import styled, { css } from 'styled-components';

const Container = styled.div<{ willOverflow: boolean }>`
  position: relative;
`;

const ContentBox = styled.div<{
  config?: { maxLineNumber: number; currentLineHeight: number };
}>`
  position: relative;
  overflow: hidden;
  max-height: ${props =>
    props.config ? `${props.config.currentLineHeight * props.config.maxLineNumber}em` : '6em'};
`;

const OverflowContent = styled.span<{ willOverflow: boolean }>`
  position: relative;
  background-color: ${white2};
  z-index: 7;
  ${props =>
    props.willOverflow &&
    css`
      position: absolute;
      right: 0;
      bottom: 0;
      padding-left: 2em;
      background: linear-gradient(to right, transparent, ${white2} 1.5em);
    `}
`;

interface IProps {
  config?: { maxLineNumber: number; currentLineHeight: number };
  lineElement?: JSX.Element;
}

/**
 * @param props Default = { config: { maxLineNumber: 3, currentLineHeight: 2}}
 */
const OverflowAwareBox: React.FC<IProps> = props => {
  const { config, lineElement } = props;
  const [willOverflow, setWillOverflow] = useState<boolean>(true);
  const boxRef = useRef<HTMLDivElement>(null);
  const overflowContentRef = useRef<HTMLSpanElement>(null);
  const [overflowContentY, setOverflowContentY] = useState<number>(0);
  const [contentBoxY, setContentBoxY] = useState<number>(0);

  // 為了等到 boxRef 確實與 ContentBox 連接，且 component size 已確定（以判斷是否大於 max height -> will overflow）
  // 故不能使用 useEffect
  useLayoutEffect(() => {
    boxRef.current !== null &&
      setWillOverflow(boxRef.current.offsetHeight < boxRef.current.scrollHeight);
  }, []);

  useLayoutEffect(() => {
    if (overflowContentRef.current !== null && boxRef.current !== null) {
      const contentRect: any = boxRef.current.getBoundingClientRect();
      const overflowRect: any = overflowContentRef.current.getBoundingClientRect();
      setContentBoxY(Math.round(contentRect.y));
      setOverflowContentY(Math.round(overflowRect.y));
    }
  }, [contentBoxY, overflowContentY]);

  useEffect(() => {
    if (
      overflowContentY - contentBoxY >
      (config ? config.maxLineNumber * config.currentLineHeight * 15 : 90) // 90 = default = 3 * 2 * 15
    ) {
      setWillOverflow(true);
    }
  }, [contentBoxY, overflowContentY, config]);

  return (
    <Container willOverflow={willOverflow}>
      <ContentBox ref={boxRef as any} config={config}>
        {lineElement} {props.children}
        <OverflowContent ref={overflowContentRef as any} willOverflow={willOverflow}>
          {willOverflow && '...'}
        </OverflowContent>
      </ContentBox>
    </Container>
  );
};

export default OverflowAwareBox;
