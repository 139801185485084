export const initData = {
  id: '',
  account: '',
  status: 'D',
  name: '',
  photo: '',
  phone: '',
  email: '',
  expertise: '',
  jobDepartment: '',
  jobTitle: '',
  jobUnit: '',
  experiences: [],
  educations: [],
  publications: [],
  theses: [],
};
