import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { createGlobalStyle } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import axios from 'axios';
import { respInterceptorLogic, errorInterceptorLogic } from 'src/apps/error-handler/ErrorHandler';
import ModalRoot from 'src/components/ui/interactive/modal/ModalRoot';

import { Provider } from 'react-redux';
import store from 'src/redux/store';

import { LoginRoute } from 'src/routes/LoginRoute';
import { Auth } from 'src/apps/auth/Auth';
import TagManager from 'react-gtm-module';

// IE 11 canvas .toBlob() polyfill
import toBlob from 'canvas-to-blob';
toBlob.init();

axios.defaults.withCredentials = true;

axios.interceptors.request.use(
  request => request,
  error => errorInterceptorLogic(error, 'Request'),
);
axios.interceptors.response.use(
  response => respInterceptorLogic(response),
  error => errorInterceptorLogic(error, 'Response'),
);

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Noto+Sans+SC&display=swap');
   *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 15px;
    font-family: 'Noto Sans SC', sans-serif;
  }

  a {
    text-decoration: none;
  }
  
  button {
    cursor: pointer;
  }

  /* google translator style */
  .goog-te-gadget-simple {
    border-radius: 4px;
  }
  .goog-te-menu-value {
    span {
      font-size: 10pt;
      line-height: 24px;
      color: #767676;
    }
  }
  iframe[class*="goog-te-banner-frame"] { 
    top: 62px;
    height: 40px;
    box-shadow: none;
    border-bottom: 1px solid #d3e5e8;
    z-index: 50;
  }
  
  .chat-box {
      z-index: 1000;
    }
  
`;

// GA 初始化
const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID ? process.env.REACT_APP_GTM_ID : '',
  auth: process.env.REACT_APP_GTM_AUTH ? process.env.REACT_APP_GTM_AUTH : '',
  preview: process.env.REACT_APP_GTM_ENV ? process.env.REACT_APP_GTM_ENV : '',
  dataLayer: {
    envManual: process.env.REACT_APP_GTM_ENV ? process.env.REACT_APP_GTM_ENV : '',
    userId: Auth().getPayload() && Auth().getPayload().info.id,
  },
  dataLayerName: 'PageDataLayer',
};
TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <GlobalStyle />
      <LoginRoute />
      <ModalRoot />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
