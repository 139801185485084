import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import { TrademarkService } from 'src/api/Trademark';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';

import { Container, Item } from 'src/style/SearchDropdownStyle';
import { ITrademarkImageCategory } from 'src/types/TrademarkTypes';
import { convertTrademarkImagePathToJSX } from './TrademarkVisualSearch';

const trademarkService = TrademarkService();

const DropdownItem = styled(Item)`
  > div {
    display: flex;
  }
`;

interface IProps {
  cssTop?: string;
  inputText: string;
  handleSuggestionClick: (category: ITrademarkImageCategory) => void;
}

export const PicPathAutoComplete: React.FC<IProps> = ({
  cssTop,
  inputText,
  handleSuggestionClick,
}) => {
  const [show, setShow] = useState<boolean>(false);
  const [suggestions, setSuggestions] = useState<Array<ITrademarkImageCategory>>([]);
  const [prevInput, setPrevInput] = useState<string>('');
  const [shouldFetch, setShouldFetch] = useState<boolean>(false);
  const isLoading = useRef<boolean>(false);
  const triggerFetch = () => setShouldFetch(true);
  const debounce = useRef(_.debounce(triggerFetch, 300));

  useEffect(() => {
    const fetchSuggestion = async () => {
      isLoading.current = true;
      trademarkService
        .getImagePathAutocomplete(inputText)
        .then(response => setSuggestions(response))
        .catch(error => errorHandler(error))
        .finally(() => {
          setShouldFetch(false);
          isLoading.current = false;
        });
    };

    // useEffect logic
    setPrevInput(inputText);

    if (inputText === '') {
      debounce.current.cancel();
      setShow(false);
      setSuggestions([]);
    } else {
      if (inputText !== prevInput) {
        setShow(true);
        debounce.current();
      }
    }
    !isLoading.current && shouldFetch && fetchSuggestion();
  }, [inputText, setShow, prevInput, suggestions, shouldFetch]);

  return suggestions.length > 0 && show ? (
    <Container cssTop={cssTop}>
      {suggestions.map(suggestion => (
        <DropdownItem
          key={suggestion.enName}
          onClick={e => {
            e.stopPropagation();
            handleSuggestionClick(suggestion);
          }}
        >
          <div>{convertTrademarkImagePathToJSX(suggestion)}</div>
        </DropdownItem>
      ))}
    </Container>
  ) : null;
};
