import decode from 'jwt-decode';

export interface IAuthData {
  token: string;
  refreshToken: string;
  isLogin: boolean;
}

export interface ITokenPayload {
  exp: number;
  info: IAuthInfo;
  ip: string;
  nbf: number;
  permissionLimits: IPermissionLimits;
  permissions: string[];
}

interface IPermissionLimits {
  PATENT_COLLECTION: {
    CATEGORY_UPPER: number;
    ITEM_UPPER: number;
  };
  PATENT_SUBSCRIPTION: {
    ITEM_UPPER: number;
  };
  RSS: {
    ITEM_UPPER: number;
  };
}

interface IAuthInfo {
  accountProfileId: number;
  companyName: string | null;
  email: string;
  guest: boolean;
  id: number;
  industrialClassIds: Array<number>;
  name: string;
  parentId: number | null;
  type: string;
  ipkmAccount: boolean;
}

export const Auth = () => {
  const STORAGE_AUTH_KEY = '@iPKM:authData';
  const STORAGE_PAYLOAD_KEY = '@iPKM:tokenPayload';

  return {
    setAuthToken: (authData: IAuthData, longAuth: boolean) => {
      const decodedToken: ITokenPayload = decode(authData.token);
      if (longAuth) {
        sessionStorage.removeItem(STORAGE_AUTH_KEY);
        sessionStorage.removeItem(STORAGE_PAYLOAD_KEY);
        localStorage.setItem(STORAGE_AUTH_KEY, JSON.stringify(authData));
        localStorage.setItem(STORAGE_PAYLOAD_KEY, JSON.stringify(decodedToken));
      } else {
        sessionStorage.setItem(STORAGE_AUTH_KEY, JSON.stringify(authData));
        sessionStorage.setItem(STORAGE_PAYLOAD_KEY, JSON.stringify(decodedToken));
      }
    },
    getAuthData(): IAuthData {
      const authData =
        localStorage.getItem(STORAGE_AUTH_KEY) || sessionStorage.getItem(STORAGE_AUTH_KEY);
      return authData != null
        ? JSON.parse(authData)
        : {
            token: '',
            refreshToken: '',
            isLogin: false,
          };
    },
    getToken: () => {
      return Auth().getAuthData().token;
    },
    getPayload(): ITokenPayload {
      const payload =
        localStorage.getItem(STORAGE_PAYLOAD_KEY) || sessionStorage.getItem(STORAGE_PAYLOAD_KEY);
      return payload != null ? JSON.parse(payload) : null;
    },
    getOrigin: () => {
      return window.location.protocol + '//' + window.location.host;
    },
    removeAllToken: () => {
      localStorage.removeItem(STORAGE_AUTH_KEY);
      localStorage.removeItem(STORAGE_PAYLOAD_KEY);
      sessionStorage.removeItem(STORAGE_AUTH_KEY);
      sessionStorage.removeItem(STORAGE_PAYLOAD_KEY);
    },
  };
};
