import React, { useState } from 'react';
import styled from 'styled-components';

import { ErrorCode } from 'src/apps/error-handler/ErrorCode';

import { Dropdown, IDropdownProps } from 'src/components/common/Dropdown';
import PatentsPanel from 'src/components/index/tabs/PatentsPanel';
import NewsPanel from 'src/components/index/tabs/NewsPanel';
import ColumnistAritclePanel from 'src/components/index/tabs/ColumnistAritclePanel';
import { blue1, blue2, ultrawhite, ultrawhitergba } from 'src/style/theme/Color';
import { IMainTabSrc } from 'src/types/api/IndexApiTypes';
import { getCountryName } from 'src/utils/PatentUtils';

import { connect } from 'react-redux';
import { ReduxAppState } from 'src/redux/reducers';
import { ITokenPayload } from 'src/apps/auth/Auth';

const Container = styled.div`
  margin: 20px 0 80px;
  padding: 30px 50px 0;
  height: 436px;
`;
const Anchor = styled.div`
  margin: auto;
  width: 90%;
  max-width: 1440px;
`;
const TabBar = styled.div`
  width: calc(100% - 10px);
  height: 40px;
  border-top-left-radius: 4px;
  background-image: linear-gradient(92deg, ${blue2}, ${blue1});
`;
const TabAncher = styled.div`
  padding-left: 12px;
  width: 65%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const TabContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-end;
`;
const Tab = styled.div<{ active: boolean }>`
  margin: 0 2px 0 0;
  width: 94px;
  height: 32px;
  color: ${props => (props.active ? blue2 : ultrawhite)};
  background-color: ${props => (props.active ? ultrawhite : ultrawhitergba(0.3))};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  text-align: center;
  line-height: 32px;
  cursor: pointer;
`;
const ListContainer = styled.div`
  height: 396px;
`;

const initTabs: IMainTabSrc[] = [
  {
    tab: '精選新聞',
    apiUrl: '/rss/featured',
    readMoreUrl: '/news/latest/0',
  },
  {
    tab: '推薦專利',
    apiUrl: '/patent/intro/recommend?country=',
    readMoreUrl: '/showcase/recommend',
  },
  {
    tab: '最新專利',
    apiUrl: '/patent/intro/latest',
    readMoreUrl: '/showcase/new',
  },
  {
    tab: '專欄文章',
    apiUrl: '/article/columnist/latest',
    readMoreUrl: '/article/columnist',
  },
  {
    tab: '專利情報',
    apiUrl: '/patent/intro/subscribed',
    readMoreUrl: 'showcase/tracking/',
  },
];

const recommendIpcs = [
  { name: getCountryName('TW'), value: 'TW' },
  { name: getCountryName('US'), value: 'US' },
  { name: getCountryName('EP'), value: 'EP' },
  { name: getCountryName('CN'), value: 'CN' },
];

interface IReduxMappingProps {
  member: ITokenPayload;
}

function MainTabsLayout({ member }: IReduxMappingProps) {
  const [activeTab, setActiveTab] = useState(initTabs[0].tab);
  const [activeRecCountry, setActiveRecCountry] = useState(recommendIpcs[0]);
  const handleActiveRecIpc = (item: IDropdownProps['activeItem']) => setActiveRecCountry(item);

  const contentSwitch = (tab: string) => {
    const industrialClassIds = member.info.industrialClassIds;
    let content = null;
    switch (tab) {
      case '精選新聞':
        content = <NewsPanel src={initTabs[0]} />;
        break;
      case '推薦專利':
        content = (
          <PatentsPanel
            src={initTabs[1]}
            userIndustrialClassIds={industrialClassIds}
            activeRecCountry={activeRecCountry}
          />
        );
        break;
      case '最新專利':
        content = <PatentsPanel src={initTabs[2]} userIndustrialClassIds={industrialClassIds} />;
        break;
      case '專欄文章':
        content = <ColumnistAritclePanel src={initTabs[3]} />;
        break;
      case '專利情報':
        content = <PatentsPanel src={initTabs[4]} userIndustrialClassIds={industrialClassIds} />;
        break;
      default:
        throw new Error(ErrorCode.UNHANDLED_CASE_IN_SWITCH);
    }
    return content;
  };

  return (
    <Container>
      <Anchor>
        <TabBar>
          <TabAncher>
            <TabContainer>
              {initTabs.map(item => (
                <Tab
                  key={item.tab}
                  active={activeTab === item.tab}
                  onClick={() => setActiveTab(item.tab)}
                >
                  {item.tab}
                </Tab>
              ))}
            </TabContainer>
            {activeTab === '推薦專利' && (
              <Dropdown
                activeItem={activeRecCountry}
                items={recommendIpcs}
                handleOnclick={handleActiveRecIpc}
              />
            )}
          </TabAncher>
        </TabBar>
        <ListContainer>{contentSwitch(activeTab)}</ListContainer>
      </Anchor>
    </Container>
  );
}

const mapStateToProps = (state: ReduxAppState) => {
  return {
    member: state.memberReducer.memberPayload,
  };
};

export default connect(
  mapStateToProps,
  null,
)(MainTabsLayout);
