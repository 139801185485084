import React from 'react';
import styled from 'styled-components';
import { ultrawhite, ultrawhitergba, ultrablackrgba, iceblue5, blue7 } from 'src/style/theme/Color';
import { SearchBar } from 'src/components/article/SearchBar';

const Container = styled.div`
  border-radius: 4px;
  box-shadow: 0 0 50px 0 ${ultrablackrgba(0.1)};
`;
const Header = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  color: ${ultrawhite};
  background-image: linear-gradient(95deg, #2ec3c2, #0cafcc);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding-bottom: 24px;
`;
const HeaderText = styled.div`
  position: relative;
  width: 100%;
  padding: 16px 32px;
`;
const HeaderRoute = styled.div`
  color: ${ultrawhitergba(0.8)};
`;
const Feature = styled.div`
  height: 36px;
  line-height: 36px;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  align-items: flex-end;
  span {
    padding: 0 0 0 8px;
    display: block;
    font-size: 15px;
    line-height: 24px;
    font-weight: normal;
  }
`;
const Subheaders = styled.div`
  padding: 30px 32px 8px 32px;
  display: flex;
  color: ${iceblue5};
  background-color: ${blue7};
`;
const Article = styled.div`
  flex: 1 1 100%;
`;

const Content = styled.div`
  padding: 0 0 24px;
  min-height: 450px;
`;

interface IProps {
  root?: string;
  sector: string;
  feature?: string;
  subtitle?: string;
  search: (queryString: string) => void;
  children: JSX.Element;
}

const content: React.FC<IProps> = props => {
  return (
    <Container>
      <Header>
        <HeaderText>
          <HeaderRoute>
            {props.root ? props.root : '首頁'}&nbsp;/&nbsp;{props.sector}
            {props.feature && ` / ${props.feature}`}
          </HeaderRoute>
          <Feature>
            {props.feature ? props.feature : props.sector}
            <span>{props.subtitle}</span>
          </Feature>
          <SearchBar search={props.search} />
        </HeaderText>
      </Header>
      <Subheaders>
        <Article>文章資訊</Article>
      </Subheaders>
      <Content>{props.children}</Content>
    </Container>
  );
};

export default content;
