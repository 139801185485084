import { IIndustrialTrendType, ILineChartDataType } from 'src/types/IndustryTypes';

export const getTrendsTitleKindText = (kind: string) => {
  switch (kind) {
    case 'A':
      return '發明專利申請數';
    case 'B':
      return '發明專利發證數';
    default:
      return '專利件數';
  }
};

export const getTrendsTitleClassText = (industrialClassIds: Array<any>) => {
  return industrialClassIds && industrialClassIds.length !== 0 ? ' 指定行業 ' : '';
};

export const getTrendsTitlePartyText = (kind: string) => {
  switch (kind) {
    case 'A':
      return '申請人';
    case 'B':
      return '專利權人';
    default:
      return '申請人';
  }
};

/** 將 yyyyMM 轉為 yyyy/MM。 */
export const formatYearMonth = (yyyyMM: string) => {
  return yyyyMM.replace(/(.{4})(.{2})(.*)/, '$1/$2');
};

/** 取得折線圖統計起始年月 yyyyMM。 */
const getStatStartYearMonth = (statTimeRange: Array<string> | undefined) => {
  return statTimeRange && statTimeRange.length === 2
    ? statTimeRange[0]
    : getStatEndYear(statTimeRange) - 5 + '01';
};

/** 取得折線圖統計結束年月 yyyyMM。 */
const getStatEndYearMonth = (statTimeRange: Array<string> | undefined) => {
  return statTimeRange && statTimeRange.length === 2
    ? statTimeRange[1]
    : new Date(new Date().setDate(0)) // 預設值為現在的前1個月
        .toISOString()
        .slice(0, 8)
        .replace(/-/g, '');
};

/** 取得折線圖統計起始年度 yyyy。 */
export const getStatStartYear = (statTimeRange: Array<string> | undefined) => {
  return parseInt(getStatStartYearMonth(statTimeRange).substring(0, 4));
};

/** 取得折線圖統計結束年度 yyyy。 */
export const getStatEndYear = (statTimeRange: Array<string> | undefined) => {
  return parseInt(getStatEndYearMonth(statTimeRange).substring(0, 4));
};

/** 取得近五年統計時間範圍 yyyy/MM - yyyy/MM。 */
export const getStatRangeText = (statTimeRange: Array<string> | undefined) => {
  return statTimeRange && statTimeRange.length === 2
    ? formatYearMonth(statTimeRange[0]) + ' - ' + formatYearMonth(statTimeRange[1])
    : '';
};

/** 將 API 回傳資料轉為折線圖所需格式。 */
export const convertToLineChartData = (
  origData: Array<IIndustrialTrendType>,
  origDataYear?: number,
  statTimeRange?: Array<string>,
) => {
  let result: Array<ILineChartDataType> = [];
  const dataYear = origDataYear
    ? origDataYear
    : origData && origData.length > 0 && origData[0].category.substring(0, 4);
  // 資料統計結束年月
  // 若為最新年度資料，結束月份為統計截止月份；否則為12個月(無資料處補0)
  const endYearMonth = getStatEndYearMonth(statTimeRange);
  const endYear = parseInt(endYearMonth.substring(0, 4));
  const endMonth = dataYear === endYear ? parseInt(endYearMonth.substring(4, 6)) : 12;
  // 資料統計開始年月
  const startYearMonth = getStatStartYearMonth(statTimeRange);
  const startYear = parseInt(startYearMonth.substring(0, 4));
  const startMonth =
    dataYear === startYear && origData.findIndex(item => item.category.substring(4) === '01') < 0
      ? parseInt(startYearMonth.substring(4, 6))
      : 1;

  if (dataYear >= startYear) {
    for (let i = startMonth; i <= endMonth; i++) {
      const month = i < 10 ? '0' + i : i.toString();
      const find = origData.find(item => item.category.substring(4) === month);
      result.push({ time: i.toString(), count: find ? find.count : 0 });
    }
  }
  return result;
};
