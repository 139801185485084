import React from 'react';
import styled from 'styled-components';
import { baseURL } from 'src/api/helpers/apiBase';
import { IGetColumnistArticle } from 'src/types/api/ColumnistArticleApiTypes';
import { handleArticleAncherOnClick } from 'src/components/article/item/ColumnistArticleItem';
import { pink3, ultrawhite } from 'src/style/theme/Color';

const Header = styled.div<{ url?: string }>`
  border-radius: 4px 4px 0 0;
  height: 300px;
`;
const Body = styled.div`
  padding: 20px 30px 0 30px;
  height: 140px;
`;

const HeaderImage = styled.div<{ url?: string }>`
  position: relative;
  top: 10px;
  background-image: url('${props => props.url}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 5px;
  width: 90%;
  aspect-ratio: 1/1;
  margin: auto;
`;

const ArticleDescribe = styled.div`
  padding: 3px 0 3px 0;
  height: 20px;
  font-size: 14px;
  > div {
    opacity: 0.8;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
const MultiLineEllipsisText = styled.div`
  margin: 0 -1rem 0 0;
  padding: 0 1rem 0 0;
  width: calc(100% - 1rem);
  max-height: 3.6rem;
  line-height: 1.2rem;
  overflow: hidden;
  position: relative;
  word-break: break-all;
  :before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
  }
  :after {
    content: '';
    position: absolute;
    right: 0;
    width: 1rem;
    height: 1rem;
    margin-top: 0.2rem;
    background: #0f96a9;
  }
`;
const Title = styled(MultiLineEllipsisText)`
  font-weight: 900;
  margin: 12px 0;
  cursor: pointer;
  max-height: 1.2rem;
  :after {
    background: #0b8ba1;
  }
`;
const Abstract = styled(MultiLineEllipsisText)`
  opacity: 0.8;
`;

const Footer = styled.div`
  padding: 0 30px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;
const Declaration = styled.div`
  padding: 0 16px 0 0;
  opacity: 0.8;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const InspectBtn = styled.a`
  margin: 10px 0 0;
  min-width: 64px;
  height: 32px;
  display: block;
  border-radius: 4px;
  color: ${ultrawhite};
  background-color: ${pink3};
  text-align: center;
  line-height: 32px;
  cursor: pointer;
`;

interface IProps {
  article: IGetColumnistArticle;
  gaClass: string;
}

const ColumnistArticleInspect = ({ article, gaClass }: IProps) => (
  <>
    <Header>
      <HeaderImage
        url={
          article.mainImage
            ? baseURL + 'article/columnist/' + article.id + '/image/' + article.mainImage.id
            : ''
        }
      />
    </Header>
    <Body>
      <ArticleDescribe>
        <div>{article.columnistArticleCategory ? article.columnistArticleCategory.name : ''}</div>
      </ArticleDescribe>

      <Title onClick={e => handleArticleAncherOnClick(e, article.id)}>{article.title}</Title>

      <Abstract>{article.summary}</Abstract>
      {/* <ArticleDescribe>
        <div>作者：{article.author}</div>
        <div>發佈日期：{dateFormatter(article.startDate)}</div>
      </ArticleDescribe> */}
    </Body>
    <Footer>
      <Declaration>
        {article.columnistArticleCategory && article.columnistArticleCategory.id === 2
          ? ''
          : '僅分享專家個人經驗，不代表本局意見'}
      </Declaration>
      <InspectBtn
        className={gaClass}
        href={baseURL + 'article/columnist/' + article.id + '/html'}
        target="_blank"
        onClick={e => handleArticleAncherOnClick(e, article.id)}
      >
        全文
      </InspectBtn>
    </Footer>
  </>
);

export default ColumnistArticleInspect;
