import React, { useEffect, useState } from 'react';
import { FaInfoCircle } from 'react-icons/fa';

import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';

import { PieChartArea } from 'src/components/trend/industrialTrends/chart/PieChart';
import LineChart from 'src/components/trend/industrialTrends/chart/LineChart';
import Tooltip from 'src/components/trend/industrialTrends/chart/Tooltip';
import { Spinner } from 'src/components/ui/interactive/Spinner';
import { CN, EU, KR, JP, TW, US } from 'src/components/ui/Flag';
import {
  IIndustrialTrendType,
  ILineChartType,
  IIndustrialTrendApiParamType,
} from 'src/types/IndustryTypes';
import { getCountryName } from 'src/utils/PatentUtils';
import {
  getTrendsTitleKindText,
  getTrendsTitleClassText,
  getStatStartYear,
  getStatEndYear,
  getStatRangeText,
  convertToLineChartData,
} from 'src/components/trend/TrendsHelper';
import {
  BlockTitle,
  Flexbox,
  StatLeftContainer,
  GraphTitle,
  RightGraphTitle,
  LineChartToggler,
  ArrowLeft,
  ArrowRight,
  StatRightContainer,
  LineChartLoader,
  NoResult,
  ToolTipTitle,
  ToolTipContent,
} from 'src/style/IndustrialTrendStyle';

interface IProps {
  apiParams: IIndustrialTrendApiParamType;
  statTimeRange: Array<string>;
}
// 國別順序列表
const countryList = ['TW', 'US', 'EP', 'JP', 'CN', 'KR'];
const defaultPieChartCount = [0, 0];

const StatCountry: React.FC<IProps> = ({ apiParams, statTimeRange }) => {
  const [activeItem, setActiveItem] = useState(countryList[0]);
  const [apiData, setApiData] = useState<Array<IIndustrialTrendType>>([]);
  const [pieCharData, setPieCharData] = useState<Map<String, number[]>>(new Map());
  const [lineChartData, setLineChartData] = useState<Array<ILineChartType>>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [tooltipPosition, setTooltipPosition] = useState<{ top: number; left: number } | undefined>(
    undefined,
  );
  const minYear = getStatStartYear(statTimeRange);
  const maxYear = getStatEndYear(statTimeRange);
  const [selectedYear, setSelectedYear] = useState(maxYear);

  useEffect(() => {
    // 行業別改變時先清空所有資料
    setActiveItem(countryList[0]);
    setSelectedYear(maxYear);
    setApiData([]);
    setPieCharData(new Map());
    setLineChartData([]);
    setIsLoading(true);

    const getPieChartData = (data: Array<IIndustrialTrendType>) => {
      let result: Map<String, number[]> = new Map();
      countryList.forEach(country => {
        const find = data.find(item => item.category === country);
        const counts = find ? [find.count, find.total - find.count] : defaultPieChartCount;
        result.set(country, counts);
      });
      return result;
    };

    const fetchData = async () => {
      try {
        const apiRes = await Api().post('trend/industry/country', {
          industrialClassIds: apiParams.industrialClassIds,
          kind: apiParams.kind,
        });
        setApiData(apiRes.data);
        setPieCharData(getPieChartData(apiRes.data));
        setIsLoading(false);
      } catch (error) {
        errorHandler(error);
      }
    };
    fetchData();
  }, [apiParams, maxYear]);

  useEffect(() => {
    // 指定國家改變時, 先清空折線圖資料
    setLineChartData([]);
    const fetchData = async () => {
      try {
        const past = await Api().post('trend/industry/country', {
          industrialClassIds: apiParams.industrialClassIds,
          kind: apiParams.kind,
          country: activeItem,
          year: selectedYear - 1,
        });
        const current = await Api().post('trend/industry/country', {
          industrialClassIds: apiParams.industrialClassIds,
          kind: apiParams.kind,
          country: activeItem,
          year: selectedYear,
        });
        let result: Array<ILineChartType> = [];
        result.push({
          name: (selectedYear - 1).toString(),
          data: convertToLineChartData(past.data, selectedYear - 1, statTimeRange),
        });
        result.push({
          name: selectedYear.toString(),
          data: convertToLineChartData(current.data, selectedYear, statTimeRange),
        });
        setLineChartData(result);
      } catch (error) {
        errorHandler(error);
      }
    };
    fetchData();
  }, [apiParams, activeItem, selectedYear, statTimeRange]);

  const getCountryFlag = (countryCode: string) => {
    switch (countryCode) {
      case 'CN':
        return CN;
      case 'EP':
        return EU;
      case 'KR':
        return KR;
      case 'JP':
        return JP;
      case 'TW':
        return TW;
      case 'US':
        return US;
      default:
        return '';
    }
  };

  /** 取得Tooltip的顯示座標位置 */
  function handleToolTip(element: any) {
    const offset = 8; // icon與tooltip間距
    const rect = element.getBoundingClientRect();
    setTooltipPosition({ left: rect.right - 5, top: rect.top - offset });
  }

  const tooltip = tooltipPosition && (
    <Tooltip
      left={tooltipPosition.left}
      top={tooltipPosition.top}
      overlay={
        <>
          <ToolTipTitle>數據統計說明</ToolTipTitle>
          <ToolTipContent>
            <p>統計時間：{getStatRangeText(statTimeRange)}</p>
            <p>件數（數字）：指定行業之近五年{getTrendsTitleKindText(apiParams.kind)}。</p>
            <p>佔比（圓形線段）：指定行業之專利件數，於各國總專利總件數中的佔比。</p>
          </ToolTipContent>
        </>
      }
    />
  );

  return (
    <>
      <BlockTitle>國家趨勢</BlockTitle>
      {tooltip}
      {apiData.length !== 0 ? (
        <Flexbox>
          <StatLeftContainer>
            <GraphTitle>
              各國{getTrendsTitleClassText(apiParams.industrialClassIds)}
              近五年{getTrendsTitleKindText(apiParams.kind)}及佔比
              <FaInfoCircle
                onMouseOver={(e: any) => handleToolTip(e.currentTarget)}
                onMouseLeave={() => setTooltipPosition(undefined)}
              />
            </GraphTitle>
            <Flexbox>
              {countryList.map(country => (
                <PieChartArea
                  key={country}
                  statData={pieCharData.get(country) || defaultPieChartCount}
                  name={getCountryName(country)}
                  src={getCountryFlag(country)}
                  onClick={() => setActiveItem(country)}
                  active={activeItem === country}
                />
              ))}
            </Flexbox>
          </StatLeftContainer>
          <StatRightContainer>
            <RightGraphTitle>
              {getCountryName(activeItem)}
              {getTrendsTitleClassText(apiParams.industrialClassIds)}
              {getTrendsTitleKindText(apiParams.kind)}年度趨勢
            </RightGraphTitle>
            <LineChartToggler>
              <ArrowLeft
                disabled={selectedYear <= minYear}
                onClick={() => {
                  if (selectedYear > minYear) {
                    setSelectedYear(selectedYear - 1);
                  }
                }}
              />
              {selectedYear} 年
              <ArrowRight
                disabled={selectedYear >= maxYear}
                onClick={() => {
                  if (selectedYear < maxYear) {
                    setSelectedYear(selectedYear + 1);
                  }
                }}
              />
            </LineChartToggler>
            {lineChartData.length !== 0 ? (
              <LineChart dataset={lineChartData} />
            ) : (
              <LineChartLoader />
            )}
          </StatRightContainer>
        </Flexbox>
      ) : isLoading ? (
        <Spinner width="200px" margin="84px auto" />
      ) : (
        <NoResult>
          <div>查無資料</div>
          <span>請嘗試變更行業別。</span>
        </NoResult>
      )}
    </>
  );
};
export default StatCountry;
