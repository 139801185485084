import React, { CSSProperties } from 'react';
import { css } from 'styled-components';
import { Dropdown, IDropdownProps } from 'src/components/common/Dropdown';
import { iceblue1, iceblue5, ultrawhite } from 'src/style/theme/Color';

const customStyleContainer = (width: CSSProperties['width']) => css`
  width: ${width};
`;

const customStyleSelectedItem = css`
  border-radius: 4px;
  border: solid 1px ${iceblue5};
  background-color: ${ultrawhite};
  color: ${iceblue1};
  padding: 5px 12px;

  &:hover {
    background-color: ${ultrawhite};
  }
`;

const customStyleItemBlock = css`
  left: 0;
  color: ${iceblue1};
`;

const customStyleItem = css`
  padding: 6px 16px;
`;

interface IProps extends IDropdownProps {
  width: CSSProperties['width'];
}

export const SearchDropdown: React.FC<IProps> = props => {
  return (
    <Dropdown
      items={props.items}
      activeItem={props.activeItem}
      handleOnclick={props.handleOnclick}
      disabled={props.disabled}
      customStyleContainer={customStyleContainer(props.width)}
      customStyleSelectedItem={customStyleSelectedItem}
      customStyleItemBlock={customStyleItemBlock}
      customStyleItem={customStyleItem}
    />
  );
};
