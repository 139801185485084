import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { ReduxAppState } from 'src/redux/reducers';
import { closeAlertModal } from 'src/redux/actions/modalAction';
import { Button } from 'src/style/theme/Common';
import { Backdrop, Container, Body, Message, Description, Footer } from 'src/style/ModalStyle';
import { IAlertModal } from 'src/types/ModalTypes';
import { zModal } from 'src/style/theme/Z-Index';

interface IReduxMappingProps {
  alertModals: Array<IAlertModal>;
  closeAlertModal: () => void;
}

const modal = document.getElementById('modal-alert');
const portalDiv = modal ? modal : document.createElement('div');

const AlertModal: React.FC<IReduxMappingProps> = props => {
  const isOpen = props.alertModals.length > 0;

  return ReactDOM.createPortal(
    isOpen && (
      <Backdrop zIndex={zModal.alert}>
        <Container>
          <Body hasDescription={!!props.alertModals[0].description}>
            <Message>{props.alertModals[0].message}</Message>
            <Description>{props.alertModals[0].description}</Description>
          </Body>
          <Footer>
            <Button template="primary" onClick={() => props.closeAlertModal()}>
              確定
            </Button>
          </Footer>
        </Container>
      </Backdrop>
    ),
    portalDiv,
  );
};

const mapStateToProps = (state: ReduxAppState) => {
  return {
    alertModals: state.modalReducer.alertModals,
  };
};

const mapDispatchToProps = {
  closeAlertModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AlertModal);
