import React from 'react';
import styled from 'styled-components';
import operant from 'src/assets/images/manual/patent-search/operant.png';
import patent_column from 'src/assets/images/manual/patent-search/patent_column.png';

const ImgContainer = styled.div<{ height: string; imageUrl: string }>`
  position: relative;
  height: ${props => props.height}px;
  padding: 10px 0;
  background-image: url(${props => props.imageUrl});
  background-size: contain;
  background-repeat: no-repeat;
`;
export default function PatentSearchManual() {
  return (
    <>
      <p>參照－運算元</p>
      <ImgContainer height="300" imageUrl={operant} />
      <br />
      <p>參照－檢索欄位代號與名稱</p>
      <ImgContainer height="500" imageUrl={patent_column} />
    </>
  );
}
