import React, { useState, useCallback } from 'react';

import NotifyContent from 'src/components/ui/content/Member/NotifyContent';
import Subscription from './NotificationSubscription';
import WeeklyInfo from './NotificationWeeklyInfo';

export enum Category {
  SUBSCRIPTION = 'SUBSCRIPTION',
  WEEKLY_INFO = 'WEEKLY_INFO',
}

interface IProps {
  tab?: Category;
}

const Notification: React.FC<IProps> = ({ tab }) => {
  const [category, setCategory] = useState<Category>(tab || Category.SUBSCRIPTION);

  const handleCategory = useCallback(
    (displayCategory: Category) => setCategory(displayCategory),
    [],
  );

  return (
    <NotifyContent
      sector="個人專區"
      feature="通知設定"
      category={category}
      handleCategory={handleCategory}
    >
      <>
        {category === Category.SUBSCRIPTION && <Subscription />}
        {category === Category.WEEKLY_INFO && <WeeklyInfo />}
      </>
    </NotifyContent>
  );
};

export default Notification;
