import React, { useState, useCallback, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { FaPaperclip } from 'react-icons/fa';
import ReactImageCrop from 'react-image-crop';

import { Button } from 'src/style/theme/Common';
import { Row } from 'src/style/PatentSearchStyle';
import CropImageModal from 'src/components/ui/interactive/modal/custom/CropImageModal';

import { alertMessage } from 'src/utils/ModalUtils';
import { SystemMessage } from 'src/apps/applicationMessages';
import { iceblue2, iceblue6, white } from 'src/style/theme/Color';

import { connect } from 'react-redux';
import { ReduxAppState } from 'src/redux/reducers';
import { openCustomModal } from 'src/redux/actions/modalAction';
import { CustomModalType } from 'src/types/ModalTypes';
import { replaceLast } from 'src/utils/TextUtils';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import { fetchImage } from 'src/utils/ImageUtils';
import { TrademarkService } from 'src/api/Trademark';

const DropZoneStyle = styled.div`
  width: 100%;
  height: 150px;
  line-height: 150px;
  text-align: center;
  border-radius: 4px;
  border: dashed 2px ${iceblue6};
  background-color: ${white};
  color: ${iceblue6};

  span {
    margin-right: 16px;
  }
`;

const DropzoneMsg = styled.span<{ color?: string }>`
  color: ${props => props.color};
`;

const StyledFaPaperclip = styled(FaPaperclip)`
  transform: rotateX(180deg);
`;

interface IReduxMappingProps extends RouteComponentProps {
  isCropImageModalOpen: boolean;
  openCustomModal: (customModalType: CustomModalType) => void;
}

interface IDropzoneOptions {
  minSize?: number;
  maxSize?: number;
  acceptExtension: Array<string>;
}

interface IDropzoneAndCtrl extends IReduxMappingProps, IDropzoneOptions {
  resultPageUrl: string;
  paramToSubmit?: Object;
  ableToModifyImage?: boolean;
  canSubmit?: boolean;
  resetButtonOnClick?: () => void;
  imageSrc?: string;
  imageName?: string;
  maxWidth?: string;
}
const noClick = true;

const DropzoneAndCtrlForTm = ({
  resultPageUrl,
  paramToSubmit = {},
  resetButtonOnClick: onClickReset,
  imageSrc: propsImageSrc,
  imageName: propsImageName,
  isCropImageModalOpen,
  openCustomModal: openReduxCustomModal,
  history,
  minSize = 0,
  maxSize = 5242880,
  maxWidth = '100%',
  acceptExtension,
  ableToModifyImage = true,
  canSubmit = true,
  location,
}: IDropzoneAndCtrl) => {
  const [imageSrc, setImageSrc] = useState<string>();
  const [imgName, setImgName] = useState<string>();
  const [croppedImg, setCroppedImg] = useState<string>();
  const [crop, setCrop] = useState<ReactImageCrop.Crop>();
  const [croppedImgFile, setCroppedImgFile] = useState<File>();
  const [rotateDeg, setRotateDeg] = useState<number>(0);
  const [multiplier, setMultiplier] = useState<number>(1);

  const onDrop = useCallback((acceptedFiles: Array<File>) => {
    const reader = new FileReader();
    const file = acceptedFiles[0];

    reader.onerror = () => {
      alertMessage(SystemMessage.SEARCH_BY_IMAGE_READER_ERROR);
    };
    reader.onload = () => {
      if (file) {
        // 需檢查否則 file.type 會異常
        const binaryStr = reader.result as string;
        const strArr = binaryStr.split(';');
        setImageSrc(`data:${file.type};${strArr[1]}`);
        setImgName(file.name);
        const fileObjectUrl = URL.createObjectURL(file);
        setCroppedImg(prev => {
          prev && window.URL.revokeObjectURL(prev);
          return fileObjectUrl;
        });
        setCroppedImgFile(file);
      } else {
        // 沒有符合可接受格式的檔案
        alertMessage(SystemMessage.FILE_TYPE_ERROR);
      }
    };
    reader.readAsDataURL(new Blob([file]));
  }, []);

  // 當來源圖檔路徑或名稱改變時
  useEffect(() => {
    if (propsImageSrc && propsImageName) {
      fetchImage(propsImageSrc, propsImageName)
        .then(fetchedImage => {
          onDrop([fetchedImage]);
        })
        .catch(error => errorHandler(error));
    }
  }, [propsImageSrc, propsImageName, onDrop]);

  // 彈出裁切視窗
  const openCropModal = useCallback(() => {
    openReduxCustomModal(CustomModalType.CROP_IMAGE);
  }, [openReduxCustomModal]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    rejectedFiles,
    open,
  } = useDropzone({
    accept: acceptExtension.map(mime => 'image/' + mime),
    onDrop,
    minSize,
    maxSize,
    noClick,
  });

  const isImageTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;

  // 上傳查詢
  const handleSubmit = () => {
    if (croppedImgFile) {
      history.push(resultPageUrl, {
        imgSrc: imageSrc,
        image: croppedImgFile,
        crop: crop,
        rotateDeg: rotateDeg,
        multiplier: multiplier,
        imagePath: undefined,
        ...paramToSubmit,
      });
    } else {
      alertMessage(SystemMessage.SEARCH_CONDITION_INCOMPLETE);
    }
  };

  // 裁切圖片
  const handleReCrop = () => {
    openCropModal();
  };

  // 清除鈕
  const handleReset = () => {
    setCroppedImg(prev => {
      prev && window.URL.revokeObjectURL(prev);
      return undefined;
    });
    setCrop(undefined);
    setImageSrc(undefined);
    setImgName(undefined);
    setCroppedImgFile(undefined);
    setRotateDeg(0);
    setMultiplier(1);
    onClickReset && onClickReset();
  };

  const imgRef = useRef<HTMLImageElement>(null);
  const dragZoneMsg = imgName ? (
    <DropzoneMsg color={iceblue2}>{imgName}</DropzoneMsg>
  ) : (
    <span>將檔案拖放到這裡，或</span>
  );
  const imgStyle = {
    maxWidth: maxWidth,
  };

  return (
    <>
      <Row>
        {croppedImg ? (
          <img ref={imgRef} alt="cropped" style={imgStyle} src={croppedImg} />
        ) : (
          <DropZoneStyle {...getRootProps()}>
            <input {...getInputProps()} />
            {ableToModifyImage && !isDragActive && (
              <>
                {dragZoneMsg}
                <Button template="primary" onClick={open}>
                  <StyledFaPaperclip />
                  選擇檔案
                </Button>
              </>
            )}
            {isDragAccept && <DropzoneMsg color="#32CD32">請將圖片放到這裡</DropzoneMsg>}
            {isDragReject && <DropzoneMsg color="#E57F7F">請選擇符合格式的圖片</DropzoneMsg>}
            {isImageTooLarge && <DropzoneMsg color="#E57F7F">上傳的圖片過大</DropzoneMsg>}
          </DropZoneStyle>
        )}
      </Row>
      <Row>建議圖片裁切後大小&ensp;242px&nbsp;*&nbsp;242px&ensp;以上</Row>
      {croppedImg ? (
        crop && (
          <Row>
            目前裁切範圍:&ensp;{Math.floor(crop.width || 0)}px&nbsp;*&nbsp;
            {Math.floor(crop.height || 0)}px
          </Row>
        )
      ) : (
        <Row>
          檔案大小限制：{`${maxSize / 1024 / 1024}mb`}
          &emsp;檔案種類限制：{replaceLast(acceptExtension.join(', '), ',', ' 和')}
        </Row>
      )}
      <Row justifyContent="flex-start">
        {croppedImg ? (
          <>
            {canSubmit && (
              <Button type="button" template="primary" onClick={handleSubmit}>
                上傳查詢
              </Button>
            )}
            {ableToModifyImage && (
              <Button type="button" template="primary-light" onClick={handleReCrop}>
                裁切
              </Button>
            )}
          </>
        ) : (
          ableToModifyImage && (
            <Button type="button" template="primary" disabled={!imageSrc} onClick={openCropModal}>
              裁切圖片
            </Button>
          )
        )}
        {croppedImg && (
          <Button type="button" onClick={handleReset}>
            清除
          </Button>
        )}
      </Row>
      {isCropImageModalOpen && (
        <CropImageModal
          imgSrc={imageSrc}
          setCroppedImg={setCroppedImg}
          crop={crop}
          setCrop={setCrop}
          setCroppedImgFile={setCroppedImgFile}
          rotate={true}
          rotateDeg={rotateDeg}
          setRotateDeg={setRotateDeg}
          multiplier={multiplier}
          setMultiplier={setMultiplier}
          detectObject={TrademarkService().getDetectObjectBlocks}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: ReduxAppState) => ({
  isCropImageModalOpen: state.modalReducer.customModalType === CustomModalType.CROP_IMAGE,
});

const mapDispatchToProps = {
  openCustomModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DropzoneAndCtrlForTm);
