import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import { alertMessage } from 'src/utils/ModalUtils';
import { CommonService } from 'src/api/Common';
import { IGetMemberCompanyUsers } from 'src/types/api/CommonApiTypes';

import useOutSideClick from 'src/hooks/useOutSideClick';
import Checkbox from 'src/components/ui/interactive/checkbox/Checkbox';
import { Button } from 'src/style/theme/Common';

const Container = styled.div`
  width: 300px;
  position: absolute;
  left: -150px;
  top: 36px;
  border-radius: 4px;
  box-shadow: 0 5px 50px 0 rgba(0, 63, 84, 0.2);
  background-color: #eceff1;
  padding: 32px;
  padding-top: 16px;

  &::before {
    content: '';
    position: relative;
    top: -47px;
    left: 230px;
    border-style: solid;
    border-width: 0 16px 16px 16px;
    border-color: transparent transparent #eceff1 transparent;
  }
`;
const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: #333333;
`;
const Body = styled.div`
  margin-top: 16px;
  color: #263237;
`;
const ActionMenu = styled.span`
  display: flex;

  > span:first-child {
    color: #333333;
  }

  > span:nth-child(2) {
    margin-right: 16px;
    margin-left: auto;
  }
`;
const Action = styled.span`
  color: #0cafcc;
  cursor: pointer;
`;
const DataArea = styled.div`
  max-height: 424px;
  overflow: scroll;

  > div {
    margin-top: 8px;
  }
`;
const Footer = styled.div`
  text-align: center;
  margin-top: 24px;
`;

const setting = [
  { feature: 'Track', title: '專利追蹤', pathnameRegex: new RegExp('^/showcase/tracking/') },
  { feature: 'Subscribe', title: '我的頻道', pathnameRegex: new RegExp('^/news/subscribe/') },
];

const commonService = CommonService();

interface IProps {
  show: boolean;
  setShow: (show: boolean) => void;
  id: number;
}

function EditShareForm(props: IProps) {
  let activeSetting = '';
  let modalTitle = '';
  for (let i = 0; i < setting.length; ++i) {
    if (window.location.pathname.match(setting[i].pathnameRegex)) {
      activeSetting = setting[i].feature;
      modalTitle = setting[i].title;
    }
  }

  const [companyUsers, setCompanyUsers] = useState<Array<IGetMemberCompanyUsers>>([]);
  const [usersToShare, setUsersToShare] = useState<Array<number>>([]);
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        // TBD: 上redux?
        const companyUserRes = await commonService.getCompanyMember();
        setCompanyUsers(companyUserRes);

        if (activeSetting === 'Track') {
          const sharedRes = await Api().get(`patent/subscription/${props.id}/share/whom`);
          setUsersToShare(sharedRes.data);
        } else if (activeSetting === 'Subscribe') {
          const sharedRes = await Api().get(`rss/follow/${props.id}/share/whom`);
          setUsersToShare(sharedRes.data);
        }
      } catch (error) {
        errorHandler(error);
      }
    };

    fetchUsers();
  }, [activeSetting, props.id]);

  const handleCheckbox = (id: number) => {
    let arr = usersToShare.slice(0);
    const index = arr.indexOf(id);
    index > -1 ? arr.splice(index, 1) : arr.push(id);
    setUsersToShare(arr);
  };

  const handleShareNote = async () => {
    const reqBody = { shareUserIds: usersToShare };
    try {
      if (activeSetting === 'Track') {
        await Api({ 'Content-Type': 'application/json' }).put(
          `patent/subscription/${props.id}/share`,
          reqBody,
        );
      } else if (activeSetting === 'Subscribe') {
        await Api({ 'Content-Type': 'application/json' }).put(
          `rss/follow/${props.id}/share`,
          reqBody,
        );
      }
      alertMessage('分享設定成功');
      props.setShow(false);
    } catch (error) {
      errorHandler(error);
    }
  };

  const node = useOutSideClick(props);

  return (
    <Container ref={node as any}>
      <Title>分享{modalTitle}</Title>
      <Body>
        <ActionMenu>
          <span>選擇分享對象</span>
          <Action onClick={() => setUsersToShare(companyUsers.map(item => item.id))}>全選</Action>
          <Action onClick={() => setUsersToShare([])}>清除選取</Action>
        </ActionMenu>
        <DataArea>
          {companyUsers.length > 0 &&
            companyUsers.map(item => (
              <Checkbox
                key={item.id}
                name={item.systemAccountName}
                checked={usersToShare.includes(item.id)}
              >
                <input
                  type="checkbox"
                  value={item.id}
                  checked={usersToShare.includes(item.id)}
                  onChange={() => handleCheckbox(item.id)}
                />
              </Checkbox>
            ))}
        </DataArea>
      </Body>
      <Footer>
        <Button template="primary" onClick={handleShareNote}>
          分享
        </Button>
        <Button onClick={() => props.setShow(false)}>取消</Button>
      </Footer>
    </Container>
  );
}

export default EditShareForm;
