import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import TmAccordion from './accordion/TmAccordion';
import NoResult from './accordion/common/NoResult';
import { connect } from 'react-redux';
import { ReduxAppState } from 'src/redux/reducers';
import { IAdvancedKeyword, ITrademarkFacet, IVisualSearchResult } from 'src/types/TrademarkTypes';
import { Spinner } from 'src/components/ui/interactive/Spinner';
import { tmFacetText } from 'src/constants/TmSearchConstants';
import { filterAndUpdateFacets } from 'src/redux/trademark/action';
import { FeedbackType } from 'src/api/Trademark';

const SpinnerBox = styled.div`
  border-bottom: 1px solid #dee2e4;
`;

interface IReduxMappingProps {
  isLoading: boolean;
  isMetadataLoaded: boolean;
  facets?: Array<ITrademarkFacet>;
  advancedKeywords?: Array<IAdvancedKeyword>;
  goodsClassDescriptions: {};
  imgClassDescriptions: {};
  fullData: Array<IVisualSearchResult>;
  reduxFilterAndUpdateFacets: () => void;
}

interface IProps extends IReduxMappingProps {
  setSelectedCaseNos: (selectedCaseNos: Map<string, FeedbackType>) => void;
}

/** 篩選 */
const TmFilter: React.FC<IProps> = props => {
  const {
    isLoading,
    isMetadataLoaded,
    facets,
    advancedKeywords,
    goodsClassDescriptions,
    imgClassDescriptions,
    fullData,
    reduxFilterAndUpdateFacets,
    setSelectedCaseNos,
  } = props;

  // 取得國家代碼對應敘述
  const getCountryDescriptions = useMemo(() => {
    let desc: { [key: string]: string } = {};
    if (isMetadataLoaded) {
      // 用 fullData 是希望不需要一直重抓
      fullData.forEach((item: IVisualSearchResult) => {
        try {
          if (item.metadata['applicant.country-code']) {
            for (var i = 0; i < item.metadata['applicant.country-code'].length; i++) {
              desc[item.metadata['applicant.country-code'][i]] =
                item.metadata['applicant.chinese-country-name'][i];
            }
          }
        } catch {
          console.log(item);
        }
      });
    }
    return desc;
  }, [fullData, isMetadataLoaded]);

  /** 當進階檢索條件改變時重新過濾與統計分組 */
  useEffect(() => {
    reduxFilterAndUpdateFacets();
    // 這邊要偵測 fullData 是因為如果使用者搜尋框內容沒變但有值, 點了排序重查後, 也需要對新結果做搜尋框內容的過濾
  }, [reduxFilterAndUpdateFacets, advancedKeywords, fullData, isMetadataLoaded]);

  /** 當檢索條件改變後, 需將匯出checkbox重置。 */
  useEffect(() => {
    setSelectedCaseNos(new Map());
  }, [advancedKeywords, setSelectedCaseNos]);

  return isLoading ? (
    <SpinnerBox>
      <Spinner margin="58px auto" />
    </SpinnerBox>
  ) : facets && facets.length > 0 ? (
    <>
      {facets &&
        facets.map(field => {
          const advancedKeyword =
            advancedKeywords && advancedKeywords.find(item => item.keyCode === field.keyCode);
          let codeDescriptionMapping = {};
          if (field.keyCode === 'GOODS_CLASS') {
            codeDescriptionMapping = goodsClassDescriptions;
          } else if (field.keyCode === 'IMG_CLASS') {
            codeDescriptionMapping = imgClassDescriptions;
          } else if (field.keyCode === 'APPLICANT_COUNTRY') {
            codeDescriptionMapping = getCountryDescriptions;
          }
          return (
            <TmAccordion
              key={field.keyCode}
              keyCode={field.keyCode}
              title={tmFacetText.get(field.keyCode)}
              tag={advancedKeyword && advancedKeyword.keyword}
              content={field.tmFacetFieldCount}
              descriptions={codeDescriptionMapping}
            />
          );
        })}
    </>
  ) : (
    <NoResult message={'本次搜尋結果無資料，請嘗試變更查詢條件。'} />
  );
};

const mapStateToProps = (state: ReduxAppState) => {
  return {
    isLoading:
      state.trademarkVisualSearchReducer.isFacetLoading ||
      state.trademarkVisualSearchReducer.isGoodsClassDescsLoading ||
      state.trademarkVisualSearchReducer.isImgClassDescsLoading,
    isMetadataLoaded: state.trademarkVisualSearchReducer.isMetadataLoaded,
    facets: state.trademarkVisualSearchReducer.paginatedData.facets,
    advancedKeywords: state.trademarkVisualSearchReducer.advancedKeywords,
    goodsClassDescriptions: state.trademarkVisualSearchReducer.goodsClassDescriptions,
    imgClassDescriptions: state.trademarkVisualSearchReducer.imgClassDescriptions,
    fullData: state.trademarkVisualSearchReducer.response.data,
  };
};

const mapDispatchToProps = {
  reduxFilterAndUpdateFacets: filterAndUpdateFacets,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TmFilter);
