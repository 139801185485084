import React from 'react';

import OpendataContent from 'src/components/ui/content/Opendata/OpendataContent';
import {
  ApiTypeGet,
  ApiInfoTitleStyle,
  ApiParamThStyle,
  ApiParamTdStyle,
  ApiParamTrStyle,
  ApiOutputStyle,
  apiParamNameWidth,
  FirstLineIndent,
} from 'src/style/OpendataStyle';
import ApiToken from 'src/components/opendata/common/ApiToken';

const Bilingual: React.FC = () => {
  return (
    <OpendataContent
      sector="開放資料"
      feature="雙語詞彙 API"
      foldableDescription={{
        abstract: '使用者可透過本API查詢技術詞彙之繁體中文 / 英文 / 簡體中文同義詞或近似詞。',
        detail: (
          <FirstLineIndent indent={-23}>
            例. 查詢「人工智慧」之中文 / 英文 / 簡體中文同義詞，則可輸入參數 term=“人工智慧”,
            lang=“TW”, fuzzy=“1”，結果可得「人工智能」、 「AI」、 「artificial
            intelligence」等同義詞或近似詞。
          </FirstLineIndent>
        ),
      }}
    >
      <>
        <p>提供專利技術詞彙查詢 API：</p>
        <ApiTypeGet />
        {process.env.REACT_APP_API_URL + 'open/api/bilingual'}
        <ApiToken />

        <ApiInfoTitleStyle>輸入參數說明</ApiInfoTitleStyle>
        <ApiParamThStyle>
          <ApiParamTdStyle width={apiParamNameWidth}>Name</ApiParamTdStyle>
          <ApiParamTdStyle>Value</ApiParamTdStyle>
        </ApiParamThStyle>
        <ApiParamTrStyle>
          <ApiParamTdStyle width={apiParamNameWidth}>term</ApiParamTdStyle>
          <ApiParamTdStyle>輸入的詞彙</ApiParamTdStyle>
        </ApiParamTrStyle>
        <ApiParamTrStyle>
          <ApiParamTdStyle width={apiParamNameWidth}>lang</ApiParamTdStyle>
          <ApiParamTdStyle>輸入詞彙的語系：TW - 繁中 | EN - 英文 | CN - 簡中</ApiParamTdStyle>
        </ApiParamTrStyle>
        <ApiParamTrStyle>
          <ApiParamTdStyle width={apiParamNameWidth}>fuzzy</ApiParamTdStyle>
          <ApiParamTdStyle>是否為近似查詢：0 - 精準 | 1 - 近似</ApiParamTdStyle>
        </ApiParamTrStyle>
        <ApiInfoTitleStyle>輸出結果範例</ApiInfoTitleStyle>
        <ApiOutputStyle>
          [<br />
          &emsp;{'{'}
          <br />
          &emsp;&emsp;"term":"big data",
          <br />
          &emsp;&emsp;"lang":"EN",
          <br />
          &emsp;&emsp;"bilingual":[
          <br />
          &emsp;&emsp;&emsp;{'{'}
          <br />
          &emsp;&emsp;&emsp;&emsp;"lang":"TW",
          <br />
          &emsp;&emsp;&emsp;&emsp;"term":"大數據"
          <br />
          &emsp;&emsp;&emsp;{'}'},<br />
          &emsp;&emsp;&emsp;{'{'}
          <br />
          &emsp;&emsp;&emsp;&emsp;"lang":"EN",
          <br />
          &emsp;&emsp;&emsp;&emsp;"term":"big data"
          <br />
          &emsp;&emsp;&emsp;{'}'},<br />
          &emsp;&emsp;&emsp;{'{'}
          <br />
          &emsp;&emsp;&emsp;&emsp;"lang":"CN",
          <br />
          &emsp;&emsp;&emsp;&emsp;"term":"大数据"
          <br />
          &emsp;&emsp;&emsp;{'}'}
          <br />
          &emsp;&emsp;]
          <br />
          &emsp;{'}'}
          <br />
          ]<br />
        </ApiOutputStyle>
      </>
    </OpendataContent>
  );
};

export default Bilingual;
