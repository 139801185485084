import React from 'react';
import styled from 'styled-components';
import { FaPrint, FaAngleDoubleDown } from 'react-icons/fa';

import PatentContent, { templates } from './content/Content';
import PatentMiniImage from './content/PatentMiniImage';
import PatentTags from './content/PatentTags';
import SlideToggle from 'src/components/ui/interactive/SlideToggle';
import { IGetPatentOcrLabelTypes, PatentDataTypes } from 'src/types/api/PatentApiTypes';
import { connect } from 'react-redux';
import { ReduxAppState } from 'src/redux/reducers';
import { openCustomModal, closeCustomModal } from 'src/redux/actions/modalAction';
import { CustomModalType } from 'src/types/ModalTypes';
import PatentConentModal from '../ui/interactive/modal/custom/PatentConentModal';
import GoogleTranslate from 'src/utils/GoogleTranslate';
import { getImgUrls } from 'src/utils/PatentUtils';

const StickyHeaderWrapper = styled.div<{ sticky: boolean }>`
  position: ${props => (props.sticky ? 'fixed' : 'relative')};
  width: ${props => (props.sticky ? 'inherit' : '100%')};
  ${props => props.sticky && 'z-index: 30'};
`;

const PatentContainer = styled.div<{ compareMode: boolean }>`
  width: ${props => (props.compareMode ? 'calc(50% - 144px)  ' : 'calc(100% - 280px - 140px)')};
  margin: ${props => (props.compareMode ? '16px 0 10px 256px' : '16px 10px 10px 280px')};
  background-color: #ffffff;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
`;

const HeaderMarginTop = styled.div`
  height: 16px;
  width: 100%;
  background-color: #f9f9f9;
  position: fixed;
  z-index: 50;
`;

const PatentHeader = styled.div<{ compareMode: boolean }>`
  width: 100%;
  padding: ${props => (props.compareMode ? '8px 32px' : '16px 32px')};
  border-radius: ${props => (props.compareMode ? '4px 0 0 0' : '4px 4px 0 0')};
  background-image: linear-gradient(95deg, #2ec3c2, #0cafcc);
  display: flex;
  justify-content: space-between;
  color: #ffffff;
`;

const HeaderTitle = styled.div<{ collapse: boolean }>`
  display: flex;
  align-items: center;
  overflow: hidden;
  /* 必須帶具體數字才能讓transition有效果，故加上max-height */
  max-height: ${props => (props.collapse ? '40px' : '320px')};
  transition: all 0.3s ease-in-out;
  p {
    font-weight: bold;
    font-size: 20px;
    white-space: ${props => props.collapse && 'nowrap'};
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const HeaderButtons = styled.div<{ compareMode?: boolean }>`
  margin-left: 16px;
  display: flex;
  flex-direction: ${props => (props.compareMode ? 'column' : 'row')};
  justify-content: ${props => (props.compareMode ? 'flex-start' : 'flex-end')};
  align-items: center;
  flex-shrink: 0;
  > * {
    display: flex;
    align-items: center;
    margin: 4px 8px;
  }
`;

const HeaderButton = styled.div<{ hide: boolean; opacity?: number }>`
  cursor: pointer;
  opacity: ${props => props.opacity || 0.5};
  ${props => props.hide && 'display: none'};
`;

const CompareModeButton = styled.div<{ hide: boolean }>`
  padding: 0;
  ${props => props.hide && 'display: none'};
  > span {
    opacity: 0.5;
    margin-right: 4px;
  }
`;

const PatentBody = styled.div<{ compareMode: boolean }>`
  border-radius: 0 0 4px 4px;
  padding-bottom: 16px;
  background-color: #ffffff;
  overflow: ${props => (props.compareMode ? 'auto' : 'visible')};
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    box-shadow: inset 0 0 8px 8px #b5e4ec;
    border: solid 2px transparent;
  }
`;

const Subheader = styled.div`
  background-color: #edf6f7;
  padding: 0 32px 0 32px;
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Tags = styled.div<{ width?: string }>`
  width: ${props => props.width};
`;

interface IReduxMappingProps {
  isPrintModalOpen: boolean;
  openCustomModal: (customModalType: CustomModalType) => void;
  closeCustomModal: () => void;
}

interface IProps extends IReduxMappingProps {
  isCompareMode: boolean;
  toggleCompareMode: any;
  patentData: PatentDataTypes;
  searchText: string[];
  tagColorArray: string[];
  toggleComparedPane: (activePane: string) => void;
  setLightboxFirstShowImage: (lightboxFirstShowImage: number) => void;
  lightboxFirstShowImage: number;
  patentOcrLabel: IGetPatentOcrLabelTypes | null;
  isOcrLabelLoading: boolean;
}

interface IState {
  sticky: boolean;
  collapse: boolean;
}

const mapStateToProps = (state: ReduxAppState) => {
  return {
    isPrintModalOpen: state.modalReducer.customModalType === CustomModalType.PATENT_PRINT,
  };
};

const mapDispatchToProps = {
  openCustomModal,
  closeCustomModal,
};

class PatentMainPane extends React.Component<IProps, IState> {
  private patentContent = React.createRef<PatentContent>();
  private patentTitle: any = React.createRef<HTMLDivElement>();
  constructor(props: IProps) {
    super(props);
    this.state = {
      sticky: false,
      collapse: false,
    };
  }

  /** 整個視窗scrollbar滾動時判斷是否摺疊標題 */
  handleStickyHeader = () => {
    // 若標題列高度大於一行(40px)才需要摺疊
    const titleDiv = this.patentTitle.current;
    const needToCollapse = titleDiv && titleDiv.offsetHeight > 40;

    if (window.scrollY === 0) {
      this.setState({
        sticky: false,
        collapse: false,
      });
    } else if (window.scrollY > 240) {
      !this.state.sticky &&
        this.setState({
          sticky: true,
        });
      !this.state.collapse &&
        needToCollapse &&
        this.setState({
          collapse: true,
        });
    }
  };

  /** 雙欄模式內部scrollbar滾動時判斷是否摺疊標題 */
  handleCompareModeStickyHeader = (e: any) => {
    if (e.target.scrollTop === 0 && window.scrollY === 0) {
      this.setState({
        sticky: false,
        collapse: false,
      });
    } else {
      !this.state.sticky &&
        this.setState({
          sticky: true,
        });
      !this.state.collapse &&
        this.setState({
          collapse: true,
        });
    }
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleStickyHeader);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleStickyHeader);
  }

  render() {
    const headerButtons = (
      <HeaderButtons compareMode={this.props.isCompareMode && !this.state.collapse}>
        {/* 雙欄比對開關: 雙欄模式且header展開 */}
        <CompareModeButton
          hide={!this.props.isCompareMode || (this.props.isCompareMode && this.state.collapse)}
        >
          <span>雙欄比對&nbsp;</span>
          <SlideToggle checked={this.props.isCompareMode} change={this.props.toggleCompareMode} />
        </CompareModeButton>
        {/* 翻譯按鈕 */}
        <HeaderButton hide={this.state.collapse} opacity={1}>
          <GoogleTranslate />
        </HeaderButton>
        {/* 列印按鈕 */}
        <HeaderButton
          hide={this.state.collapse}
          onClick={() => this.props.openCustomModal(CustomModalType.PATENT_PRINT)}
        >
          <FaPrint size="15px" />
          &nbsp;列印
        </HeaderButton>
        {/* Header展開按鈕 */}
        <HeaderButton
          hide={!this.state.collapse}
          onClick={() => this.setState({ collapse: false })}
        >
          <FaAngleDoubleDown size="15px" />
          &nbsp;展開此區塊
        </HeaderButton>
        {/* 雙欄比對開關: 非雙欄模式 或 雙欄模式且header收折 */}
        <CompareModeButton hide={this.props.isCompareMode && !this.state.collapse}>
          <span>雙欄比對</span>
          <SlideToggle checked={this.props.isCompareMode} change={this.props.toggleCompareMode} />
        </CompareModeButton>
      </HeaderButtons>
    );
    const subheader = this.props.patentData.content && (
      <Subheader>
        <Tags
          width={
            this.props.isCompareMode || this.state.sticky
              ? 'calc(100% - 200px)'
              : 'calc(100% - 416px)'
          }
        >
          <PatentTags patentDataId={this.props.patentData.content.patentDataId} />
        </Tags>
        {this.state.sticky && (
          <PatentMiniImage
            images={
              this.props.patentData.content.img_alias
                ? getImgUrls(
                    this.props.patentData.content.id,
                    this.props.patentData.content.img_alias,
                    this.props.patentOcrLabel,
                  ).slice(0, 4)
                : []
            }
            openLightbox={
              this.patentContent.current && this.patentContent.current.handleMiniImgClick
            }
            totalImg={
              this.props.patentData.content.img_alias
                ? this.props.patentData.content.img_alias.length
                : 0
            }
            size="35px"
          />
        )}
      </Subheader>
    );
    return (
      <>
        <HeaderMarginTop />
        {this.props.patentData.content !== null && (
          <PatentContainer
            compareMode={this.props.isCompareMode}
            onScroll={e => this.handleCompareModeStickyHeader(e)}
          >
            <StickyHeaderWrapper sticky={this.state.sticky}>
              <PatentHeader compareMode={this.props.isCompareMode}>
                <HeaderTitle
                  collapse={this.state.collapse}
                  ref={this.patentTitle}
                  className="translate"
                >
                  <p>
                    {this.props.patentData.content.bibliographic.inventionTitle.title.join(' | ')}
                  </p>
                </HeaderTitle>
                {headerButtons}
              </PatentHeader>
              {subheader}
            </StickyHeaderWrapper>
            <PatentBody compareMode={this.props.isCompareMode}>
              <PatentContent
                ref={this.patentContent}
                template={
                  this.props.isCompareMode ? templates.HalfPageWithoutTitle : templates.FullPage
                }
                patentData={this.props.patentData}
                searchText={this.props.searchText}
                tagColorArray={this.props.tagColorArray}
                toggleComparedPane={this.props.toggleComparedPane}
                setLightboxFirstShowImage={this.props.setLightboxFirstShowImage}
                lightboxFirstShowImage={this.props.lightboxFirstShowImage}
                patentOcrLabel={this.props.patentOcrLabel}
                isOcrLabelLoading={this.props.isOcrLabelLoading}
              />
            </PatentBody>
          </PatentContainer>
        )}
        {this.props.isPrintModalOpen && (
          <PatentConentModal patentData={this.props.patentData} printButton />
        )}
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PatentMainPane);
