import React from 'react';
import styled from 'styled-components';
import searcher from 'src/assets/images/manual/patent-search/searcher.png';

const ImgContainer = styled.div<{ height: string; imageUrl: string }>`
  position: relative;
  height: ${props => props.height}px;
  padding: 10px 0;
  background-image: url(${props => props.imageUrl});
  background-size: contain;
  background-repeat: no-repeat;
`;
export default function PatentSearchManual() {
  return (
    <>
      <p>檢索入口共有3處，點選任一入口即可開始進行專利檢索。</p>
      <ol>
        <li>首頁搜尋欄</li>
        <li>功能列「專利檢索」專區</li>
        <li>專區列右方放大鏡圖示</li>
      </ol>
      <ImgContainer height="150" imageUrl={searcher} />
    </>
  );
}
