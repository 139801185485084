import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router-dom';

import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import { IPostTrendIndustryTagPatent } from 'src/types/api/IndexApiTypes';

import TagContent from 'src/components/ui/content/Tag/TagContent';
import PatentTagsItem from 'src/components/common/tag-info/item/PatentTagsItem';
import { blue2, blue8, iceblue3 } from 'src/style/theme/Color';

const NoResult = styled.div`
  margin: 16px 32px;
  padding: 16px 32px;
  display: inline-block;
  background-color: ${blue8};

  div {
    color: ${iceblue3};
    font-weight: 600;
    margin-bottom: 8px;
    span {
      color: ${blue2};
    }
  }
`;

function TagRanking(props: RouteComponentProps) {
  const [, setLoading] = useState(true);
  const [queryResult, setQueryResult] = useState<IPostTrendIndustryTagPatent>();
  useEffect(() => {
    window.scrollTo(0, 0);
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const fetchData = async () => {
      try {
        const res = await Api().post('trend/industry/tag/patent', props.location.state, {
          cancelToken: source.token,
        });
        setQueryResult(res.data);
      } catch (error) {
        errorHandler(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    return () => source.cancel();
  }, [props.location.state]);

  const queryUiStack = () => {
    if (queryResult && queryResult.data.length > 0) {
      return queryResult.data.map(solrResult => (
        <PatentTagsItem
          key={solrResult.id}
          solrResult={solrResult}
          targetTag={props.location.state.tag}
        />
      ));
    } else {
      return (
        <NoResult>
          <div>
            目前查無與&nbsp;<span>{props.location.state.tag}</span>&nbsp;標籤有關的專利
          </div>
        </NoResult>
      );
    }
  };

  return (
    <TagContent feature="標籤排行" tag={props.location.state.tag} patents={queryResult}>
      <>{queryUiStack()}</>
    </TagContent>
  );
}

export default TagRanking;
