import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';

import { FeatureLv2, FeatureLv3, FaStyle } from 'src/style/SideMenuStyle';
import { handleRoute } from 'src/routes/routeUtils';

interface IProps extends RouteComponentProps {
  active: boolean;
  disable: boolean;
  subfeature: string;
  path: string;
  nestedSubfeatures: boolean;
  linkable: boolean;
  nestedSubfeat: Array<{
    id: number;
    name: string;
    disable?: boolean;
  }>;
}

function SubNestedNavItem(props: IProps) {
  const [folded, setFolded] = useState(true);
  const [featureActive, setFeatureActive] = useState(false);
  const [collapsible, setCollapsible] = useState(true);
  const featurePath = `${props.match.url}${props.path}`;
  const linkable = props.linkable && !props.disable;

  useEffect(() => {
    const featurePathRegex = new RegExp('^' + featurePath + '$');
    const subFeaturePathRegex = new RegExp('^' + featurePath);
    if (props.location.pathname.match(featurePathRegex)) {
      // 選到本項時, 設為 avtive 並展開子項(仍可折疊)
      setFeatureActive(true);
      setCollapsible(true);
      setFolded(false);
    } else if (props.location.pathname.match(subFeaturePathRegex)) {
      // 選到本項底下的子項時, 取消 avtive 並展開子項(不可折疊)
      setFeatureActive(false);
      setCollapsible(false);
      setFolded(false);
    } else {
      // 選到其他項目時, 取消 avtive 並折疊子項
      setFeatureActive(false);
      setCollapsible(true);
      setFolded(true);
    }
  }, [featurePath, props.location.pathname]);

  const foldButton = (
    <FaStyle
      pointer={collapsible}
      onClick={(e: any) => {
        e.stopPropagation();
        e.preventDefault();
        collapsible && setFolded(prev => !prev);
      }}
    >
      {props.nestedSubfeat.length > 0 &&
        !props.disable &&
        (folded ? <FaChevronDown /> : <FaChevronUp />)}
    </FaStyle>
  );

  return (
    <>
      {// 階層2項目可連結時(本身有實際功能), 點擊箭頭才可折疊展開; 不可連結時(僅作為分類項目), 點擊整個範圍皆可折疊展開
      linkable ? (
        <Link to={featurePath}>
          <FeatureLv2
            active={featureActive}
            onClick={() => handleRoute(featurePath, props.location, props.history)}
          >
            {props.subfeature}
            {props.nestedSubfeat.length > 0 && foldButton}
          </FeatureLv2>
        </Link>
      ) : (
        <FeatureLv2
          active={featureActive}
          disable={props.disable}
          onClick={() => {
            props.nestedSubfeat.length > 0 && !featureActive && setFolded(prev => !prev);
          }}
        >
          {props.subfeature}
          {props.nestedSubfeat.length > 0 && foldButton}
        </FeatureLv2>
      )}
      {props.nestedSubfeat.length > 0 &&
        !folded &&
        !props.disable &&
        props.nestedSubfeat.map(item => {
          const itemPath = `${props.match.url}${props.path}/${item.id}`;
          const itemPathRegex = new RegExp('^' + itemPath + '$|^' + itemPath + '/');
          return item.disable ? (
            <FeatureLv3 key={item.id} disable={true} active={false}>
              {item.name}
            </FeatureLv3>
          ) : (
            <Link key={item.id} to={itemPath}>
              <FeatureLv3
                key={item.id}
                disable={false}
                active={props.location.pathname.match(itemPathRegex) ? true : false}
                onClick={() => handleRoute(itemPath, props.location, props.history)}
              >
                {item.name}
              </FeatureLv3>
            </Link>
          );
        })}
    </>
  );
}

export default SubNestedNavItem;
