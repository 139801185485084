import React, { useState, useEffect, useCallback, useMemo } from 'react';
import styled from 'styled-components';

import ExpertResultContent from 'src/components/ui/content/expert/ExpertResultContent';
import ExpertResultItem from './ExpertResultItem';
import InfiniteScroll from 'src/components/ui/interactive/InfiniteScroll';
import { Spinner } from 'src/components/ui/interactive/Spinner';

import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import { IIndustrialClassType } from 'src/types/IndustryTypes';
import { IPaginatedData } from 'src/types/PaginatedDataTypes';
import { IExpertBrief, IExpertRequest } from 'src/types/ExpertTypes';
import { IDropdownItem, ISelectedItem } from 'src/components/common/Dropdown';
import { defaultPaginatedData } from 'src/types/defaultData/paginatedDataDefault';
import { blue2, blue8, iceblue3 } from 'src/style/theme/Color';
import { ReduxAppState } from 'src/redux/reducers';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { GaSearchCategory, pushGaSiteSearchEvent } from 'src/utils/GoogleAnalytics';
import { escapeInvalidRegex, isNotEmpty } from 'src/utils/TextUtils';

const NoResult = styled.div`
  margin: 16px 32px;
  padding: 16px 32px;
  background-color: ${blue8};

  div {
    color: ${blue2};
    font-weight: 600;
    margin-bottom: 8px;
  }
  span {
    color: ${iceblue3};
  }
`;
interface IReduxProps extends RouteComponentProps {
  industrialClassList: Array<IIndustrialClassType>;
}

/** 專家履歷 - 搜尋結果 */
const ExpertResult: React.FC<IReduxProps> = props => {
  // 資料更新時間
  const [lastUpdateDate, setLastUpdateDate] = useState<string>('');
  // 取得最後更新時間
  useEffect(() => {
    const fetchExpertData = async () => {
      try {
        const res = await Api().get('expert/updateDate');
        setLastUpdateDate(res.data);
      } catch (error) {
        errorHandler(error);
      }
    };

    fetchExpertData();
  }, []);

  // 主要頁面資料:
  // 資料讀取狀態
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  // 頁面控制項目
  const defaultSelectedItem = {
    dateRange: { name: '不限時間', value: '' },
    sortBasis: { name: '排序', value: '' },
  };
  const [checkedIndustrialClass, setCheckedIndustrialClass] = useState<number[]>([]);
  const [selectedItem, setSelectedItem] = useState<ISelectedItem>(defaultSelectedItem);
  const [keyword, setKeyword] = useState<string>('');
  const [quiredKeyword, setQuiredKeyword] = useState<string>('');
  const [searchMode, setSearchMode] = useState<IDropdownItem>({ name: '關鍵字', value: 'KEYWORD' });
  const [subSearchMode, setSubSearchMode] = useState<IDropdownItem>({
    name: '專家履歷',
    value: 'EXPERT',
  });
  // API 查詢參數
  const [reqParams, setReqParams] = useState<IExpertRequest>({
    industrialClassIds: [],
    keyword: '',
    pageNo: 1,
  });
  // API 回傳結果
  const [resData, setResData] = useState<IPaginatedData<IExpertBrief>>(defaultPaginatedData);

  /** 發送 API 取得專家列表。 */
  const fetchData = useCallback(async () => {
    setIsLoading(true);
    setIsError(false);

    // 送出 GA 專家搜尋事件(有使用關鍵字查詢才記錄)
    isNotEmpty(reqParams.keyword) &&
      pushGaSiteSearchEvent(GaSearchCategory.EXPERT, reqParams.keyword);

    try {
      const res = await Api().post('expert/search', reqParams);
      if (res.data.pageNo > 1) {
        setResData(prev => ({
          ...prev,
          data: [...prev.data, ...res.data.data],
          costTime: res.data.costTime,
          pageNo: res.data.pageNo,
        }));
      } else {
        setResData(res.data);
      }
      setQuiredKeyword(reqParams.keyword);
    } catch (error) {
      errorHandler(error);
      setIsError(true);
      reqParams.pageNo === 1 && setResData(defaultPaginatedData);
    } finally {
      setIsLoading(false);
    }
  }, [reqParams]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  /** 查詢表單送出。 */
  const handleSubmit = (
    e: React.FormEvent<HTMLFormElement> | React.MouseEvent<SVGElement, MouseEvent>,
  ) => {
    e.preventDefault();

    // 重置時間範圍與排序
    setSelectedItem(prev => ({
      ...prev,
      dateRange: defaultSelectedItem.dateRange,
      sortBasis: defaultSelectedItem.sortBasis,
    }));
    if (searchMode && searchMode.value === 'KEYWORD') {
      setReqParams({
        industrialClassIds: [],
        keyword: keyword,
        searchType: subSearchMode.value,
        pageNo: 1,
      });
    } else if (searchMode && searchMode.value === 'INDUSTRIAL_CLASS') {
      setReqParams({
        industrialClassIds: checkedIndustrialClass,
        keyword: '',
        searchType: searchMode.value,
        pageNo: 1,
      });
    }
  };

  /** 下一頁讀取觸發。 */
  const handleNextPageLoad = () => {
    setReqParams(prev => ({
      ...prev,
      pageNo: prev.pageNo + 1,
    }));
  };

  const searchWords = useMemo(() => (keyword ? escapeInvalidRegex(keyword).split(' ') : []), [
    keyword,
  ]);

  return (
    <ExpertResultContent
      sector="學界專家"
      feature="專家履歷"
      subtitle={`提供您最新的專家資訊。 更新時間 ${lastUpdateDate}`}
      isLoading={isLoading}
      totalCount={resData.totalCount}
      costTime={resData.costTime}
      selectedItem={selectedItem}
      setSelectedItem={setSelectedItem}
      checkedIndustrialClass={checkedIndustrialClass}
      setCheckedIndustrialClass={setCheckedIndustrialClass}
      keyword={keyword}
      setKeyword={setKeyword}
      searchMode={searchMode}
      setSearchMode={setSearchMode}
      subSearchMode={subSearchMode}
      setSubSearchMode={setSubSearchMode}
      industrialClassList={props.industrialClassList}
      handleSubmit={handleSubmit}
      setReqParams={setReqParams}
    >
      {isLoading && reqParams.pageNo === 1 ? (
        <Spinner width="150px" margin="100px auto" />
      ) : resData.data.length > 0 ? (
        <InfiniteScroll
          isNextPageLoading={isLoading && reqParams.pageNo > 1}
          isNextPageError={isError && reqParams.pageNo > 1}
          errorMessage={'系統發生異常'}
          handleNextPageLoad={handleNextPageLoad}
          handleReload={fetchData}
          totalCount={resData.totalCount}
          currentPage={resData.pageNo}
          totalPages={resData.totalPages}
          stopCount={-1}
          threshold={document.documentElement.offsetHeight / 3}
        >
          {resData.data.map(item => (
            <ExpertResultItem
              key={item.id}
              expert={item}
              searchWords={searchWords}
              keyword={quiredKeyword}
              {...props}
            />
          ))}
        </InfiniteScroll>
      ) : (
        <NoResult>
          <div>無相關學界專家</div>
          <span>請嘗試變更搜尋條件。</span>
        </NoResult>
      )}
    </ExpertResultContent>
  );
};
const mapStateToProps = (state: ReduxAppState) => {
  return {
    industrialClassList: state.industrialClassReducer.industrialClassList,
  };
};
export default connect(
  mapStateToProps,
  null,
)(ExpertResult);
