import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Auth } from 'src/apps/auth/Auth';

/** TODO: 等待 @types/react-redux 完成 types 定義 */
function useLoginChecker() {
  const reduxState = useSelector(state => state.memberReducer.authData.isLogin);
  const sessionState = Auth().getAuthData().isLogin;
  const [isLogin, setIsLogin] = useState(reduxState || sessionState);

  useEffect(() => {
    setIsLogin(reduxState || sessionState);
  }, [reduxState, sessionState]);

  return isLogin;
}

export default useLoginChecker;
