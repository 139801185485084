import {
  IAdvancedKeyword,
  ICodeDescriptionMapping,
  ITrademarkFacet,
  IVisualSearchPaginatedData,
  IVisualSearchRequest,
  IVisualSearchResult,
  ISuggestions,
  TmApplMetadata,
} from 'src/types/TrademarkTypes';

/** 設定 redux 內的 visual search request */
export const SET_VISUAL_SEARCH_REQUEST = 'SET_VISUAL_SEARCH_REQUEST';
/** 下一頁 */
export const NAVIGATE_TO_NEXT_PAGE = 'NAVIGATE_TO_NEXT_PAGE';

/** 發送API取回商標以圖找圖結果*/
export const FETCH_VISUAL_SEARCH_RESULT_START = 'FETCH_VISUAL_SEARCH_RESULT_START';
export const FETCH_VISUAL_SEARCH_RESULT_SUCCESS = 'FETCH_VISUAL_SEARCH_RESULT_SUCCESS';
export const FETCH_VISUAL_SEARCH_RESULT_ERROR = 'FETCH_VISUAL_SEARCH_RESULT_ERROR';

/** 發送API取回商標詮釋資料*/
export const FETCH_TM_METADATAS_START = 'FETCH_TM_METADATAS_START';
export const FETCH_TM_METADATAS_SUCCESS = 'FETCH_TM_METADATAS_SUCCESS';
export const FETCH_TM_METADATAS_ERROR = 'FETCH_TM_METADATAS_ERROR';

/** 過濾檢索結果 */
export const FETCH_FILTER_RESULT_START = 'FETCH_FILTER_RESULT_START';
export const FETCH_FILTER_RESULT_SUCCESS = 'FETCH_FILTER_RESULT_SUCCESS';
export const FETCH_FILTER_RESULT_ERROR = 'FETCH_FILTER_RESULT_ERROR';

// 分組統計資料
export const FETCH_TM_FACETS_START = 'FETCH_TM_FACETS_START';
export const FETCH_TM_FACETS_SUCCESS = 'FETCH_TM_FACETS_SUCCESS';
export const FETCH_TM_FACETS_ERROR = 'FETCH_TM_FACETS_ERROR';

/** 再檢索-篩選 */
export const APPEND_TM_ADVANCED_KEYWORD = 'APPEND_TM_ADVANCED_KEYWORD';
/** 取消再檢索-篩選 */
export const DETACH_TM_ADVANCED_KEYWORD = 'DETACH_TM_ADVANCED_KEYWORD';

// 商品服務類別 中文敘述
export const FETCH_GOODS_CLASS_DESCRIPTIONS_START = 'FETCH_GOODS_CLASS_DESCRIPTIONS_START';
export const FETCH_GOODS_CLASS_DESCRIPTIONS_SUCCESS = 'FETCH_GOODS_CLASS_DESCRIPTIONS_SUCCESS';
export const FETCH_GOODS_CLASS_DESCRIPTIONS_ERROR = 'FETCH_GOODS_CLASS_DESCRIPTIONS_ERROR';

// 圖形路徑 中文敘述
export const FETCH_IMG_CLASS_DESCRIPTIONS_START = 'FETCH_IMG_CLASS_DESCRIPTIONS_START';
export const FETCH_IMG_CLASS_DESCRIPTIONS_SUCCESS = 'FETCH_IMG_CLASS_DESCRIPTIONS_SUCCESS';
export const FETCH_IMG_CLASS_DESCRIPTIONS_ERROR = 'FETCH_IMG_CLASS_DESCRIPTIONS_ERROR';

// 計算取得商品名稱推薦詞
export const HANDLE_SUGGESTIONS_START = 'HANDLE_SUGGESTIONS_START';
export const HANDLE_SUGGESTIONS_SUCCESS = 'HANDLE_SUGGESTIONS_SUCCESS';
export const HANDLE_SUGGESTIONS_ERROR = 'HANDLE_SUGGESTIONS_ERROR';

// 圖形路徑預測
export const FETCH_IMG_CLASS_PREDICT_START = 'FETCH_IMG_CLASS_PREDICT_START';
export const FETCH_IMG_CLASS_PREDICT_SUCCESS = 'FETCH_IMG_CLASS_PREDICT_SUCCESS';
export const FETCH_IMG_CLASS_PREDICT_ERROR = 'FETCH_IMG_CLASS_PREDICT_ERROR';

// 圖形路徑對應表
export const FETCH_IMG_CLASS_MAPPING_START = 'FETCH_IMG_CLASS_MAPPING_START';
export const FETCH_IMG_CLASS_MAPPING_SUCCESS = 'FETCH_IMG_CLASS_MAPPING_SUCCESS';
export const FETCH_IMG_CLASS_MAPPING_ERROR = 'FETCH_IMG_CLASS_MAPPING_ERROR';

export interface VisualSearchSearchState {
  request: IVisualSearchRequest;
  isLoading: boolean;
  isFacetLoading: boolean;
  isMetadataLoaded: boolean;
  isSuggestionLoading: boolean;
  isGoodsClassDescsLoading: boolean;
  isImgClassDescsLoading: boolean;
  isImgClassPredictLoading: boolean;
  errorMsg?: string;
  response: IVisualSearchPaginatedData;
  paginatedData: IVisualSearchPaginatedData;
  filteredData: Array<IVisualSearchResult>;
  advancedKeywords: Array<IAdvancedKeyword>;
  suggestions: Array<ISuggestions>;
  goodsClassDescriptions: ICodeDescriptionMapping;
  imgClassDescriptions: ICodeDescriptionMapping;
  imgClassPredictions: Array<string>;
  imgClassMapping: ICodeDescriptionMapping;
}

interface SetVisualSearchRequestAction {
  type: typeof SET_VISUAL_SEARCH_REQUEST;
  request: IVisualSearchRequest;
}

interface NavigateToNextPageAction {
  type: typeof NAVIGATE_TO_NEXT_PAGE;
}
interface FetchVisualSearchResultStartAction {
  type: typeof FETCH_VISUAL_SEARCH_RESULT_START;
}

interface FetchVisualSearchResultSuccessAction {
  type: typeof FETCH_VISUAL_SEARCH_RESULT_SUCCESS;
  response: IVisualSearchPaginatedData;
}

interface FetchVisualSearchResultErrorAction {
  type: typeof FETCH_VISUAL_SEARCH_RESULT_ERROR;
  errorMsg: string;
}

interface FetchTmMetadatasStartAction {
  type: typeof FETCH_TM_METADATAS_START;
}

interface FetchTmMetadatasSuccessAction {
  type: typeof FETCH_TM_METADATAS_SUCCESS;
  response: Array<TmApplMetadata>;
}

interface FetchTmMetadatasErrorAction {
  type: typeof FETCH_TM_METADATAS_ERROR;
}

interface FetchFilterResultStartAction {
  type: typeof FETCH_FILTER_RESULT_START;
}

interface FetchFilterResultSuccessAction {
  type: typeof FETCH_FILTER_RESULT_SUCCESS;
  data: IVisualSearchResult[];
  sameCategoryImageNumber?: number;
}

interface FetchFilterResultErrorAction {
  type: typeof FETCH_FILTER_RESULT_ERROR;
}

interface FetchFacetsStartAction {
  type: typeof FETCH_TM_FACETS_START;
}

interface FetchFacetsSuccessAction {
  type: typeof FETCH_TM_FACETS_SUCCESS;
  facets: Array<ITrademarkFacet>;
}

interface FetchFacetsErrorAction {
  type: typeof FETCH_TM_FACETS_ERROR;
}

interface AppendAdvancedKeywordAction {
  type: typeof APPEND_TM_ADVANCED_KEYWORD;
  keyCode: string;
  keyword: string;
}

interface DetachAdvancedKeywordAction {
  type: typeof DETACH_TM_ADVANCED_KEYWORD;
  keyCode: string;
}

interface FetchGoodsClassDescriptionsStartAction {
  type: typeof FETCH_GOODS_CLASS_DESCRIPTIONS_START;
}

interface FetchGoodsClassDescriptionsSuccessAction {
  type: typeof FETCH_GOODS_CLASS_DESCRIPTIONS_SUCCESS;
  response: ICodeDescriptionMapping;
}

interface FetchGoodsClassDescriptionsErrorAction {
  type: typeof FETCH_GOODS_CLASS_DESCRIPTIONS_ERROR;
}

interface FetchImgClassDescriptionsStartAction {
  type: typeof FETCH_IMG_CLASS_DESCRIPTIONS_START;
}

interface FetchImgClassDescriptionsSuccessAction {
  type: typeof FETCH_IMG_CLASS_DESCRIPTIONS_SUCCESS;
  response: ICodeDescriptionMapping;
}

interface FetchImgClassDescriptionsErrorAction {
  type: typeof FETCH_IMG_CLASS_DESCRIPTIONS_ERROR;
}

interface HandleSuggestionsStartAction {
  type: typeof HANDLE_SUGGESTIONS_START;
}

interface HandleSuggestionsSuccessAction {
  type: typeof HANDLE_SUGGESTIONS_SUCCESS;
  suggestions: Array<ISuggestions>;
}

interface HandleSuggestionsErrorAction {
  type: typeof HANDLE_SUGGESTIONS_ERROR;
}

interface FetchImgClassPredictStartAction {
  type: typeof FETCH_IMG_CLASS_PREDICT_START;
}

interface FetchImgClassPredictSuccessAction {
  type: typeof FETCH_IMG_CLASS_PREDICT_SUCCESS;
  response: Array<string>;
}

interface FetchImgClassPredictErrorAction {
  type: typeof FETCH_IMG_CLASS_PREDICT_ERROR;
}

interface FetchImgClassMappingSuccessAction {
  type: typeof FETCH_IMG_CLASS_MAPPING_SUCCESS;
  response: ICodeDescriptionMapping;
}

export type VisualSearchSearchActionTypes =
  | SetVisualSearchRequestAction
  | NavigateToNextPageAction
  | FetchVisualSearchResultStartAction
  | FetchVisualSearchResultSuccessAction
  | FetchVisualSearchResultErrorAction
  | FetchTmMetadatasStartAction
  | FetchTmMetadatasSuccessAction
  | FetchTmMetadatasErrorAction
  | FetchFilterResultStartAction
  | FetchFilterResultSuccessAction
  | FetchFilterResultErrorAction
  | FetchFacetsStartAction
  | FetchFacetsSuccessAction
  | FetchFacetsErrorAction
  | AppendAdvancedKeywordAction
  | DetachAdvancedKeywordAction
  | FetchGoodsClassDescriptionsStartAction
  | FetchGoodsClassDescriptionsSuccessAction
  | FetchGoodsClassDescriptionsErrorAction
  | FetchImgClassDescriptionsStartAction
  | FetchImgClassDescriptionsSuccessAction
  | FetchImgClassDescriptionsErrorAction
  | HandleSuggestionsStartAction
  | HandleSuggestionsSuccessAction
  | HandleSuggestionsErrorAction
  | FetchImgClassPredictStartAction
  | FetchImgClassPredictSuccessAction
  | FetchImgClassPredictErrorAction
  | FetchImgClassMappingSuccessAction;
