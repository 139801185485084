import React from 'react';
import styled from 'styled-components';

import { IGetFilm } from 'src/types/api/FilmApiTypes';

const Container = styled.div`
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  :hover {
    background-color: #f5fdfe;
  }
`;
const LeftContainer = styled.div`
  padding: 8px 8px 8px 32px;
  width: 20%;
  img {
    margin: 12px 0;
    width: 100%;
    max-height: 160px;
    cursor: pointer;
  }
`;
const CellContainer = styled.div`
  width: 80%;
  padding: 32px 32px 0 15px;
`;

const Title = styled.a`
  padding: 0 0 8px 0;
  display: block;
  color: #077790;
  font-size: 18px;
  cursor: pointer;
`;
const Text = styled.div`
  color: #666666;
  padding: 0 0 4px 0;
`;
const Channel = styled.span`
  cursor: pointer;
`;

export const handleArticleAncherOnClick = async (
  e: React.MouseEvent<HTMLElement, MouseEvent>,
  url: string,
) => {
  e.preventDefault();
  try {
    window.open(url);
  } catch (error) {
    console.error(error);
  }
};

interface IProps {
  columnData: IGetFilm;
}

//影片專區List模式
const VideoListItem = ({ columnData }: IProps) => (
  <Container>
    {
      <>
        <LeftContainer>
          {columnData && columnData.pic && (
            <img
              src={columnData.pic}
              alt="no pic"
              onClick={e => handleArticleAncherOnClick(e, columnData.url)}
            />
          )}
        </LeftContainer>
        <CellContainer>
          <Title onClick={e => handleArticleAncherOnClick(e, columnData.url)}>
            {columnData.title}
          </Title>
          <Text>
            <Channel onClick={e => handleArticleAncherOnClick(e, columnData.channelUrl)}>
              {columnData.channelName}
            </Channel>
            <span>&nbsp;&nbsp;|&nbsp;&nbsp;{columnData.createTime}</span>
          </Text>
        </CellContainer>
      </>
    }
  </Container>
);

export default VideoListItem;
