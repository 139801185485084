import { AxiosResponse, AxiosError } from 'axios';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { ReduxAppState } from 'src/redux/reducers';
import {
  FETCH_BY_IMAGE_RESULT_START,
  FETCH_BY_IMAGE_RESULT_SUCCESS,
  FETCH_BY_IMAGE_RESULT_ERROR,
  FETCH_BY_IMAGE_NEXT_PAGE_RESULT_ERROR,
  FetchByImageResultActionTypes,
} from './type';
import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import { IGetPatentTypes } from 'src/types/IGetPatentTypes';

export const fetchByImageResult = (): ThunkAction<
  void,
  ReduxAppState,
  null,
  Action<string>
> => async (dispatch, getState) => {
  dispatch(fetchByImageResultStart());

  const state = getState().byImageSearchReducer;
  const searchRequest = state.request;

  try {
    // 查詢
    const response = await Api().post('patent/similar/image/patent', {
      ...searchRequest,
    });

    // 當查詢字串造成solr查詢錯誤時，response.data 會回傳
    // eg. { costTime: 0.006, data: null, pageNo: 1, size: 0, totalCount: -1, totalPages: 0 }
    // 為了避免後續操作陣列產生null pointer，所以替換成空陣列
    !response.data.data && (response.data.data = []);

    // 將相似度最高的圖片順序換至第一張
    (response.data.data as Array<IGetPatentTypes>).forEach(item => {
      const byImageResponse = state.response.find(
        patent =>
          patent.patent_name === item.bibliographic.applicationReference.documentId.docNumber,
      );
      const picName = byImageResponse ? byImageResponse.patent_pic_name : undefined;
      if (picName) {
        const picNamePrefix = picName.substr(0, picName.lastIndexOf('.') + 1);
        // img_alias 有值才 sort
        item.img_alias &&
          item.img_alias.sort((img1, img2) => {
            var result = 0;
            if (img1.indexOf('/' + picNamePrefix) >= 0 || img1.indexOf(picNamePrefix) >= 0) {
              result = -1;
            } else if (img2.indexOf('/' + picNamePrefix) >= 0 || img2.indexOf(picNamePrefix) >= 0) {
              result = 1;
            }
            return result;
          });
      }
      return { ...item };
    });

    dispatch(fetchByImageResultSuccess(response));
  } catch (error) {
    if (searchRequest.pageNo > 1) {
      dispatch(fetchByImageNextPageResultFailure(error));
    } else {
      dispatch(fetchByImageResultFailure(error));
    }
  }
};

function fetchByImageResultStart(): FetchByImageResultActionTypes {
  return {
    type: FETCH_BY_IMAGE_RESULT_START,
  };
}

function fetchByImageResultSuccess(response: AxiosResponse): FetchByImageResultActionTypes {
  return {
    type: FETCH_BY_IMAGE_RESULT_SUCCESS,
    response: response,
  };
}

function fetchByImageResultFailure(error: AxiosError): FetchByImageResultActionTypes {
  errorHandler(error);
  return {
    type: FETCH_BY_IMAGE_RESULT_ERROR,
    error: error,
  };
}

function fetchByImageNextPageResultFailure(error: AxiosError): FetchByImageResultActionTypes {
  errorHandler(error);
  // TODO: 需要再確認或優化錯誤處理作法
  const errorMessage =
    (error.response && error.response.data && error.response.data.resultMessage) ||
    '網路連線出現異常';
  return {
    type: FETCH_BY_IMAGE_NEXT_PAGE_RESULT_ERROR,
    nextPageErrMsg: errorMessage,
  };
}
