import React, { useEffect } from 'react';

const ChatbotGirl = () => {
  useEffect(() => {
    const script = document.createElement('script');

    script.innerHTML = 'var webChat = CaiWebChat.Init();';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
      const bot = document.getElementsByClassName('minimizable-web-chat');
      bot && bot[0] && bot[0].parentNode && document.body.removeChild(bot[0].parentNode);
      window.location.reload();
    };
  }, []);

  return <></>;
};

export default ChatbotGirl;
