import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { connect } from 'react-redux';
import { ReduxAppState } from 'src/redux/reducers';
import { closeRedirectModal } from 'src/redux/actions/modalAction';

import { Button } from 'src/style/theme/Common';
import { Backdrop, Body, Message, Footer } from 'src/style/ModalStyle';
import { IRedirectModal } from 'src/types/ModalTypes';
import { zModal } from 'src/style/theme/Z-Index';
import { ultrawhite, ultrablackrgba } from 'src/style/theme/Color';

const Container = styled.div`
  width: 85vw;
  min-width: 275px;
  max-width: 465px;
  margin: 216px 0;
  padding: 32px;
  border-radius: 4px;
  box-shadow: 0 0 50px 0 ${ultrablackrgba(0.1)};
  background-color: ${ultrawhite};
`;

interface IReduxMappingProps {
  redirectModal?: IRedirectModal;
  closeRedirectModal: (shouldRedirect?: boolean) => void;
}

interface IProps extends IReduxMappingProps, RouteComponentProps {}

const modal = document.getElementById('modal-redirect');
const portalDiv = modal ? modal : document.createElement('div');

const RedirectModal: React.FC<IProps> = ({ redirectModal, closeRedirectModal, history }) => {
  return ReactDOM.createPortal(
    !!redirectModal && (
      <Backdrop zIndex={zModal.redirect}>
        <Container>
          <Body>
            <Message>{redirectModal && redirectModal.message}</Message>
          </Body>
          <Footer>
            <Button
              template="primary"
              onClick={() => {
                closeRedirectModal(true);
                // 頁面跳轉
                const redirectPath = redirectModal && redirectModal.path;
                redirectPath && history.push(redirectPath);

                window.scrollTo(0, 0);
              }}
            >
              確定前往
            </Button>
            <Button template="alarm" onClick={() => closeRedirectModal()}>
              返回原頁
            </Button>
          </Footer>
        </Container>
      </Backdrop>
    ),
    portalDiv,
  );
};

const mapStateToProps = (state: ReduxAppState) => {
  return {
    redirectModal: state.modalReducer.redirectModal,
  };
};

const mapDispatchToProps = {
  closeRedirectModal,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(RedirectModal),
);
