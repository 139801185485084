export const rssFacetText = new Map([
  ['KEYWORD', '關鍵字'],
  ['DATE', '發布時間'],
  ['SOURCE', '資料來源'],
]);
export const rssSuggestionText = new Map([
  ['TOPIC', '主題'],
  ['SOURCE', '來源'],
  ['QUERY_STRING', '檢索式'],
]);

export const NEWS_KEYWORDS_NUMBER = 3;
