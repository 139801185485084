import React from 'react';
import styled from 'styled-components';
import basic_search from 'src/assets/images/manual/patent-search/basic_search.png';

const ImgContainer = styled.div<{ height: string; imageUrl: string }>`
  position: relative;
  height: ${props => props.height}px;
  padding: 10px 0;
  background-image: url(${props => props.imageUrl});
  background-size: contain;
  background-repeat: no-repeat;
`;
export default function PatentSearchManual() {
  return (
    <>
      <p>基本檢索可以選擇不同國別、行業別以及輸入關鍵字，來進行專利檢索。</p>
      <ol>
        <li>從功能列點選「專利檢索」。</li>
        <li>點選「基本檢索」功能。</li>
        <li>選擇要檢索的國家範圍，預設為台灣TW。</li>
        <li>選擇要檢索的行業別範圍，預設為不分行業。</li>
        <li>輸入要檢索的字串，可直接輸入關鍵字，或參照運算元與檢索欄位列表。</li>
        <li>點選右方放大鏡圖示進行查詢</li>
      </ol>
      <ImgContainer height="500" imageUrl={basic_search} />
    </>
  );
}
