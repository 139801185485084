import styled from 'styled-components';
import { iceblue8 } from 'src/style/theme/Color';

export const Carousel = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 120px;
  border-top: solid 1px ${iceblue8};
  /** 避免使用者連點造成反白 */
  user-select: none;
`;
