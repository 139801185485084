import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { IoEarth } from 'react-icons/io5';
import * as Flag from 'src/components/ui/Flag';
import BG_IMG from 'src/assets/images/rule-bg.svg';
import { LinkOut } from 'src/utils/LinkOut';
import { blue1, blue8, iceblue1, black1, ultrablackrgba, ultrawhite } from 'src/style/theme/Color';
import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';

const Container = styled.div`
  margin: 80px 0;
  padding: 56px 50px;
  min-height: 388px;
  background-image: url(${BG_IMG});
  background-repeat: no-repeat;
`;
const Anchor = styled.div`
  margin: auto;
  width: 90%;
  height: 100%;
  max-width: 1440px;
`;
const AreaTitle = styled.div`
  padding: 8px 0;
  color: #003f54;
  font-size: 36px;
  font-weight: bold;
`;
const AreaDescription = styled.div`
  margin: 0 0 24px 0;
  max-width: 768px;
  color: ${iceblue1};
`;
const FlexDiv = styled.div`
  display: flex;
  align-items: flex-end;
`;
const Countries = styled.div`
  margin: 0 0 8px 0;
  max-width: 1024px;
  display: flex;
  flex-flow: row wrap;
  align-content: space-between;
  a {
    color: ${black1};
  }
`;
const Country = styled.div`
  margin: 0 48px 0 0;
  padding: 8px;
  width: 154px;
  height: 56px;
  display: flex;
  border-radius: 4px;
  border: 1px solid ${ultrablackrgba(0)};
  align-items: center;
  :hover {
    background-color: ${blue8};
    border: 1px solid ${ultrablackrgba(0.05)};
  }

  img {
    display: block;
    border: 1px solid ${ultrablackrgba(0.1)};
    background-color: ${ultrawhite};
  }
  span {
    display: block;
    padding: 0 0 0 6px;
  }
  svg {
    color: ${blue1};
  }
`;

const img = new Map([
  [1, Flag.AU],
  [2, Flag.SG],
  [3, Flag.PH],
  [4, Flag.IN],
  [5, Flag.VN],
  [6, Flag.KH],
  [7, Flag.CR],
  [8, Flag.DE],
  [9, Flag.MY],
  [10, Flag.ID],
  [11, Flag.US],
  [12, Flag.CN],
  [13, Flag.JP],
  [14, Flag.KR],
  [15, Flag.EU],
]);

interface IRegulation {
  id: number;
  country: string;
  displayOrder: number;
}

const Regulation: React.FC = () => {
  const [regulation, setRegulation] = useState<Array<IRegulation>>();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await Api().get('regulation/list');
        res.data && setRegulation(res.data);
      } catch (error) {
        errorHandler(error);
      }
    };
    fetchData();
  }, []);
  return (
    <Container>
      <Anchor>
        <AreaTitle>各國專利制度</AreaTitle>
        <AreaDescription>
          本平台資料僅供參考，不作為相關法規准駁及法律訴訟之依據，所有資料以各國政府或組織公告為準。
          <br />
          各國資料為 2018 年公告時資料，後續異動更新資料請至各國網站頁面瀏覽。
        </AreaDescription>
        <FlexDiv>
          <Countries>
            {regulation &&
              regulation.map(
                item =>
                  img.get(item.id) && (
                    <LinkOut
                      key={item.country}
                      href={process.env.REACT_APP_REGULATION_URL + 'country/' + item.id + '/'}
                    >
                      <Country>
                        <IoEarth />
                        <span>{item.country}</span>
                      </Country>
                    </LinkOut>
                  ),
              )}
          </Countries>
        </FlexDiv>
      </Anchor>
    </Container>
  );
};

export default Regulation;
