import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import Checkbox from 'src/components/ui/interactive/checkbox/Checkbox';
import { IGetRssFollow, IRssFeed } from 'src/types/api/NewsApiTypes';
import { Button } from 'src/style/theme/Common';
import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import { alertMessage } from 'src/utils/ModalUtils';
import PreviewItem from 'src/components/news/item/PreviewItem';
import { IGetRssLatest } from 'src/types/api/NewsApiTypes';
import { Spinner } from 'src/components/ui/interactive/Spinner';
import { setTimeout, clearTimeout } from 'timers';
import {
  blue2,
  blue3,
  blue7rgba,
  blue8,
  iceblue3,
  iceblue5,
  iceblue6,
  iceblue8,
  white,
  white2,
} from 'src/style/theme/Color';
import { FaAngleLeft, FaPlus, FaTimes } from 'react-icons/fa';
import InfiniteScroll from '../ui/interactive/InfiniteScroll';
import { unstable_batchedUpdates } from 'react-dom';

const Container = styled.div`
  z-index: 1;
  top: 0;
  left: 16px;
  width: calc(100% - 16px);
  position: absolute;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  background-color: ${white2};
`;

const Header = styled.div`
  display: flex;
  height: 87px;
  border-radius: 4px 4px 0 0;
  background-image: linear-gradient(95deg, #2ec3c2, ${blue3} 100%);
  font-size: 24px;
  font-weight: 600;
  color: ${white2};
  align-items: center;
`;
const PrevIconBox = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  padding: 0 16px;
  margin-right: 16px;
  :hover {
    background-color: ${blue7rgba(0.2)};
    cursor: pointer;
  }
`;
const Content = styled.div`
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  cursor: auto;
  overflow: hidden;
  padding-bottom: 8px;
`;

const Editor = styled.div`
  padding: 25px 32px;
  color: #565656;
  cursor: auto;
`;

const HeaderName = styled.div`
  color: ${iceblue5};
  font-size: 16px;
`;

const SubHeaders = styled.div`
  padding: 8px 0;
  display: flex;
  background-color: ${white2};
  align-items: center;
  border-bottom: ${iceblue8} 1px solid;

  ${HeaderName}:first-of-type {
    padding: 0 0 0 32px;
    width: 55%;
  }
  ${HeaderName}:nth-of-type(2) {
    padding: 0 0 0 16px;
    width: 22.5%;
  }
  ${HeaderName}:last-of-type {
    padding: 0 32px 0 0;
    width: 22.5%;
  }
`;

const InlineInput = styled.div`
  display: flex;
  padding: 0 0 24px;
  line-height: 28px;
  justify-content: start;

  > label {
    flex-basis: 20%;
  }
  > div {
    flex-basis: 80%;
  }

  > input {
    flex-basis: 40%;
    padding: 5px 16px;

    height: 30px;
    display: block;
    font-size: 15px;
    border-radius: 4px;
    border: solid 1px ${iceblue6};
    color: #333333;
  }

  :focus-within {
    input {
      border: solid 1px ${blue3};
      outline: none;
    }
    label {
      color: ${blue2};
    }
  }
`;

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Tag = styled.span`
  padding: 4px 28px 4px 16px;
  margin-right: 8px;
  margin-bottom: 8px;
  color: ${iceblue3};
  background-color: ${blue8};
  border-radius: 4px;
`;

const TagCancel = styled.span`
  cursor: pointer;
  margin-left: 12px;
  font-size: 13px;
  :hover {
    color: ${blue3};
  }
  position: relative;
  > svg {
    position: absolute;
    top: 2px;
  }
`;

const SelectorOpener = styled.span`
  color: ${blue2};
  cursor: pointer;
  :hover {
    color: #44c1d7;
  }
  > svg {
    margin-right: 8px;
    padding-top: 3px;
  }
`;

const RangeSelectHelper = styled.div`
  padding-bottom: 8px;
  color: #44c1d7;
  text-align: right;
  span {
    margin-left: 8px;
    cursor: pointer;
    :hover {
      color: ${blue2};
    }
  }
`;

const RangeSelector = styled.div`
  padding: 8px 0 10px;
  width: 90%;
`;

const Controller = styled.div`
  margin-top: 32px;
  padding-left: calc(20% - 4px);
  width: 100%;
`;

const NoResult = styled.div`
  background-color: ${white};
  text-align: center;
  padding: 16px;
  border-bottom: ${iceblue8} 1px solid;
  color: ${iceblue6};
`;

interface IProps {
  currentSubscribe?: IGetRssFollow;
  keyword: string;
  show: boolean;
  setShow: (show: boolean) => void;
  submit: (option: string, id: number, data: any) => void;
}

function SubscribePane(props: IProps) {
  const { setShow: propsSetShow, submit: propsSubmit, currentSubscribe } = props;

  const [name, setName] = useState(currentSubscribe ? currentSubscribe.name : '');
  const [keyword, setKeyword] = useState<string>(
    currentSubscribe ? currentSubscribe.keyword : props.keyword,
  );
  const [checkedData, setCheckedData] = useState<Array<IRssFeed>>([]);
  const [displaySelector, setDisplaySelector] = useState<boolean>(false);
  const [newsList, setNewsList] = useState<Array<IGetRssLatest>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [pageNo, setPageNo] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [isNextPageLoading, setIsNextPageLoading] = useState<boolean>(false);
  const [isNextPageError, setIsNextPageError] = useState<boolean>(false);

  const timer = useRef<NodeJS.Timeout>();
  const firstRender = useRef<boolean>(true);
  // 確認 checkedData 的初始值已取得，避免顯示預覽時因觸發 useEffect 而造成畫面閃爍
  const initFinished = useRef<boolean>(false);
  const nameInputRef = useRef<HTMLInputElement>(null);
  const keywordInputRef = useRef<HTMLInputElement>(null);

  const focusNameInput = () => {
    nameInputRef.current !== null && nameInputRef.current.focus();
  };
  const focusKeywordInput = () => {
    keywordInputRef.current !== null && keywordInputRef.current.focus();
  };

  const loadClickHandler = useCallback(() => {
    unstable_batchedUpdates(() => {
      setIsNextPageLoading(true);
      setPageNo(prev => prev + 1);
    });
  }, []);

  const reloadClickHandler = useCallback(() => {
    if (pageNo === 1) {
      setIsLoading(true);
      setNewsList([]);
    } else {
      setIsNextPageLoading(true);
    }
  }, [pageNo]);

  const submit = useCallback(
    async (data: any) => {
      try {
        const res = currentSubscribe
          ? await Api().patch(`rss/follow/${currentSubscribe.id}`, data)
          : await Api().post('rss/follow', data);

        if (res.status === 200) {
          propsSetShow(false);
          currentSubscribe
            ? propsSubmit('EditSubscribe', currentSubscribe.id, data)
            : propsSubmit('AddNewSubscribe', res.data, data);
        }
      } catch (error) {
        errorHandler(error);
      }
    },
    [propsSetShow, propsSubmit, currentSubscribe],
  );

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const fetchData = async () => {
      try {
        await Api()
          .get('rss/feed', {
            params: {
              followedId: currentSubscribe ? currentSubscribe.id : null,
            },
            cancelToken: source.token,
          })
          .then(res => {
            // 全部未選擇(新訂閱)則預設為全選
            let data = res.data as Array<IRssFeed>;
            if (data.filter(item => item.followed).length === 0) {
              data = data.map(item => ({ ...item, followed: true }));
            }
            setCheckedData(data);
          });
      } catch (error) {
        errorHandler(error);
      }
    };

    fetchData();

    return () => source.cancel();
  }, [currentSubscribe]);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const fetchData = async () => {
      try {
        const sourceToFollow = checkedData.filter(item => item.followed);

        if (sourceToFollow.length === 0) {
          setNewsList([]);
          setTotalPages(0);
          setTotalCount(0);
          setPageNo(1);
        } else {
          isLoading && setPageNo(1);
          const res = await Api().post(
            'rss/topic/search',
            {
              pageNo: pageNo,
              paginationSize: 10,
              queryString: keyword,
              ids: sourceToFollow.map(item => item.id),
            },
            { cancelToken: source.token },
          );
          setNewsList(prev => [...prev, ...res.data.data]);
          setTotalPages(res.data.totalPages);
          setTotalCount(res.data.total);
        }
        setIsNextPageError(false);
      } catch (error) {
        errorHandler(error);
        isNextPageLoading && setIsNextPageError(true);
      } finally {
        setIsLoading(false);
        setIsNextPageLoading(false);
      }
    };

    (isLoading || isNextPageLoading) && checkedData.length > 0 && fetchData();
    return () => source.cancel();
  }, [isLoading, isNextPageLoading, pageNo, keyword, checkedData]);

  useEffect(() => {
    // 在 checkedData 初始值設定完成前，不 reset 自動讀取預覽的 timer ，以避免畫面閃爍
    if (firstRender.current && checkedData.length > 0) {
      firstRender.current = false;
      initFinished.current = true;
    } else if (initFinished.current) {
      timer.current && clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        setNewsList([]);
        setPageNo(1);
        setIsLoading(true);
      }, 600);
    }
  }, [keyword, checkedData]);

  const followAll = useCallback(
    (toFollow: boolean) =>
      setCheckedData(prev => prev.map(item => ({ ...item, followed: toFollow }))),
    [],
  );

  return (
    <Container>
      <Header>
        <PrevIconBox onClick={() => props.setShow(false)}>
          <FaAngleLeft size="24px" />
        </PrevIconBox>
        {currentSubscribe ? '編輯' : '新增'}我的頻道
        {currentSubscribe && ` - ${currentSubscribe.name}`}
      </Header>
      <Editor>
        <InlineInput onClick={() => focusNameInput()}>
          <label>頻道名稱</label>
          <input
            ref={nameInputRef}
            type="text"
            value={name}
            onChange={event => setName(event.target.value)}
          />
        </InlineInput>
        <InlineInput onClick={() => focusKeywordInput()}>
          <label>頻道條件設定</label>
          <input
            ref={keywordInputRef}
            type="text"
            value={keyword}
            onChange={event => setKeyword(event.target.value)}
          />
        </InlineInput>
        <InlineInput>
          <label>設定範圍</label>
          <div>
            <TagContainer>
              {checkedData
                .filter(item => item.followed)
                .map(item => (
                  <Tag>
                    {item.feedName}
                    <TagCancel
                      onClick={() =>
                        setCheckedData(prev =>
                          prev.map(prevItem =>
                            prevItem.id === item.id
                              ? {
                                  ...prevItem,
                                  followed: false,
                                }
                              : { ...prevItem },
                          ),
                        )
                      }
                    >
                      <FaTimes />
                    </TagCancel>
                  </Tag>
                ))}
            </TagContainer>
            <RangeSelector>
              {displaySelector ? (
                <>
                  <RangeSelectHelper>
                    <span onClick={() => followAll(true)}>全選</span>
                    <span onClick={() => followAll(false)}>清除選取</span>
                    <span onClick={() => setDisplaySelector(false)}>關閉</span>
                  </RangeSelectHelper>
                  {checkedData.map((item, index) => (
                    <div key={item.feedName}>
                      <Checkbox
                        key={item.feedName}
                        name={item.feedName}
                        checked={item.followed}
                        color="#565656"
                      >
                        <input
                          id={item.feedName}
                          type="checkbox"
                          name={item.feedName}
                          checked={item.followed}
                          onChange={event => {
                            const targetObj = checkedData[index];
                            targetObj.followed = event.target.checked;
                            checkedData[index] = targetObj;

                            setCheckedData([...checkedData]);
                          }}
                        />
                      </Checkbox>
                    </div>
                  ))}
                </>
              ) : (
                <SelectorOpener onClick={() => setDisplaySelector(true)}>
                  <FaPlus />
                  新增範圍
                </SelectorOpener>
              )}
            </RangeSelector>
          </div>
        </InlineInput>

        <Controller>
          <Button
            template="primary"
            onClick={() => {
              const sourceToFollow = checkedData.filter(item => item.followed);

              if (/^\s*$/.test(name.trim())) {
                alertMessage('請輸入頻道名稱');
              } else if (sourceToFollow.length === 0) {
                alertMessage('請選擇來源範圍');
              } else {
                submit({
                  name: name,
                  keyword: keyword,
                  ids: sourceToFollow.map(item => item.id),
                });
              }
            }}
          >
            {currentSubscribe ? '儲存' : '新增'}
          </Button>
          <Button template="default" onClick={() => props.setShow(false)}>
            取消
          </Button>
        </Controller>
      </Editor>
      <SubHeaders>
        <HeaderName>新聞名稱</HeaderName>
        <HeaderName>來源</HeaderName>
        <HeaderName>發布日期</HeaderName>
      </SubHeaders>
      <Content>
        {isLoading ? (
          <Spinner width="150px" />
        ) : newsList.length > 0 ? (
          <InfiniteScroll
            isNextPageLoading={isNextPageLoading}
            isNextPageError={isNextPageError}
            errorMessage={'系統發生異常'}
            handleNextPageLoad={loadClickHandler}
            handleReload={reloadClickHandler}
            totalCount={totalCount}
            currentPage={pageNo}
            totalPages={totalPages}
          >
            {newsList.map(news => (
              <PreviewItem key={news.id} newsData={news} />
            ))}
          </InfiniteScroll>
        ) : (
          <NoResult>查無資料，請嘗試變更頻道條件或範圍</NoResult>
        )}
      </Content>
    </Container>
  );
}

export default SubscribePane;
