import React from 'react';
import styled from 'styled-components';
import { Route } from 'react-router-dom';

import Policy from 'src/components/common/cookie/Policy';

const Container = styled.div`
  margin: auto;
  padding: 16px 0 0;
  width: 75%;
`;

const CookieLayout: React.FC = () => {
  return (
    <Container>
      <Route path="/cookie-policy" exact component={Policy} />
    </Container>
  );
};

export default CookieLayout;
