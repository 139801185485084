import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { ReduxAppState } from 'src/redux/reducers';
import { RouteComponentProps, withRouter } from 'react-router';
import LoginCore from 'src/components/common/login/LoginCore';
import { zModal } from 'src/style/theme/Z-Index';
import { Backdrop } from 'src/style/ModalStyle';
import { LoginModalType } from 'src/types/ModalTypes';

interface IReduxMappingProps {
  isOpen: boolean;
  isFromRoute: boolean;
}

interface IProps extends RouteComponentProps, IReduxMappingProps {}

const modal = document.getElementById('modal-login');
const portalDiv = modal ? modal : document.createElement('div');

const LoginFormModal: React.FC<IProps> = props => {
  return ReactDOM.createPortal(
    props.isOpen && (
      <Backdrop zIndex={zModal.login} alignItems="center">
        <LoginCore {...props} />
      </Backdrop>
    ),
    portalDiv,
  );
};

const mapStateToProps = (state: ReduxAppState) => {
  return {
    isOpen: state.modalReducer.loginModal.type === LoginModalType.FORM,
    isFromRoute: state.modalReducer.loginModal.isFromRoute,
  };
};

export default connect(
  mapStateToProps,
  null,
)(withRouter(LoginFormModal));
