import {
  CLOSE_ALERT_MODAL,
  CLOSE_CUSTOM_MODAL,
  CLOSE_LOGIN_MODAL,
  CLOSE_REDIRECT_MODAL,
  ModalActionTypes,
  ModalState,
  OPEN_ALERT_MODAL,
  OPEN_CUSTOM_MODAL,
  OPEN_LOGIN_ALERT_MODAL,
  OPEN_LOGIN_FORM_MODAL,
  OPEN_REDIRECT_MODAL,
} from 'src/redux/types/modalTypes';
import { CustomModalType, LoginModalType } from 'src/types/ModalTypes';

const initialState: ModalState = {
  alertModals: [],
  loginModal: {
    type: LoginModalType.NONE,
    isFromRoute: false,
  },
  customModalType: CustomModalType.NONE,
};

const modalReducer = (state = initialState, action: ModalActionTypes): ModalState => {
  switch (action.type) {
    case OPEN_ALERT_MODAL:
      return {
        ...state,
        alertModals: state.alertModals.concat(action.alertModal),
      };
    case CLOSE_ALERT_MODAL: {
      let newAlertModals = state.alertModals.slice();
      const deletedModal = newAlertModals.splice(0, 1)[0];
      deletedModal.callback && deletedModal.callback();
      return {
        ...state,
        alertModals: newAlertModals,
      };
    }
    case OPEN_LOGIN_ALERT_MODAL:
      return {
        ...state,
        loginModal: {
          type: LoginModalType.ALERT,
          isFromRoute: action.isFromRoute,
        },
      };
    case OPEN_LOGIN_FORM_MODAL:
      return {
        ...state,
        loginModal: {
          type: LoginModalType.FORM,
          isFromRoute: state.loginModal.isFromRoute,
        },
      };
    case CLOSE_LOGIN_MODAL:
      return {
        ...state,
        loginModal: {
          type: LoginModalType.NONE,
          isFromRoute: false,
        },
      };
    case OPEN_CUSTOM_MODAL:
      return {
        ...state,
        customModalType: action.customModalType,
        customModalId: action.customModalId,
      };
    case CLOSE_CUSTOM_MODAL:
      return {
        ...state,
        customModalType: CustomModalType.NONE,
        customModalId: undefined,
      };
    case OPEN_REDIRECT_MODAL:
      return {
        ...state,
        redirectModal: action.redirectModal,
      };
    case CLOSE_REDIRECT_MODAL: {
      if (action.shouldRedirect) {
        return {
          ...initialState,
        };
      } else {
        return {
          ...state,
          redirectModal: undefined,
        };
      }
    }
    default:
      return { ...state };
  }
};

export default modalReducer;
