import React from 'react';
import { FaAngleDown, FaAngleUp, FaTimesCircle } from 'react-icons/fa';
import {
  Container,
  Header,
  TaggedHeader,
  Title,
  Panel,
  TagWithTooltip,
  FacetLinkBox,
  FacetLinkName,
} from 'src/style/AccordionStyle';
import { connect } from 'react-redux';
import { ReduxAppState } from 'src/redux/reducers';
import { toggleDedupe } from 'src/redux/actions/patentSearchAction';
import { IFacetFieldCount } from 'src/types/PatentSearchTypes';
import useAccordion from './common/useAccordion';

interface IReduxMappingProps {
  tag?: boolean;
  content: Array<IFacetFieldCount>;
  toggleDedupe: (dedupe: boolean) => void;
}

interface IProps extends IReduxMappingProps {
  title: string;
}

const dedupeContent: Array<IFacetFieldCount> = [{ name: '檢索去重', count: 0 }];

/** 雙語詞彙-摺疊面板 */
const DedupeAccordion: React.FC<IProps> = props => {
  const { title, tag, content, toggleDedupe } = props;
  const { isActive, togglePanel } = useAccordion(content);

  const isTagged = tag === true;

  return (
    <Container>
      {isTagged ? (
        <TaggedHeader>
          <Title>{title}</Title>
          <TagWithTooltip placement="top" overlay={'檢索去重'}>
            檢索去重
          </TagWithTooltip>
          <FaTimesCircle size={20} onClick={() => toggleDedupe(false)} />
        </TaggedHeader>
      ) : (
        <Header onClick={() => togglePanel()}>
          <Title>{title}</Title>
          {isActive ? <FaAngleUp size={20} /> : <FaAngleDown size={20} />}
        </Header>
      )}
      {!isTagged && (
        <Panel isShow={isActive}>
          <FacetLinkBox onClick={() => toggleDedupe(true)}>
            <FacetLinkName>檢索去重</FacetLinkName>
          </FacetLinkBox>
        </Panel>
      )}
    </Container>
  );
};

const mapStateToProps = (state: ReduxAppState) => {
  return {
    tag: state.patentSearchReducer.request.dedupe,
    content: dedupeContent,
  };
};

const mapDispatchToProps = {
  toggleDedupe,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DedupeAccordion);
