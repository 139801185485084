import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import { LocService } from 'src/api/loc';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import { ILocBasic } from 'src/types/loc';
import { getLocDescription } from 'src/components/search/ByImage';

import { Container, Item } from 'src/style/SearchDropdownStyle';

const locService = LocService();
const regexLocClasses = new RegExp('[0-9]{2}-{0,1}[0-9]{2}');

const DropdownItem = styled(Item)`
  > div {
    display: flex;
  }
`;

interface IProps {
  isShow: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  cssTop?: string;
  inputText: string;
  handleSuggestionClick: (loc: ILocBasic) => void;
}

export const LocAutocomplete: React.FC<IProps> = ({
  isShow,
  setShow,
  cssTop,
  inputText,
  handleSuggestionClick,
}) => {
  const [suggestions, setSuggestions] = useState<Array<ILocBasic>>([]);
  const [prevInput, setPrevInput] = useState<string>('');
  const [shouldFetch, setShouldFetch] = useState<boolean>(false);
  const isLoading = useRef<boolean>(false);
  const triggerFetch = () => setShouldFetch(true);
  const debounce = useRef(_.debounce(triggerFetch, 300));

  useEffect(() => {
    const fetchSuggestion = async () => {
      isLoading.current = true;
      try {
        if (regexLocClasses.test(inputText)) {
          const res = await locService.getLoc(inputText.replace('-', ''));

          if (!res) {
            setSuggestions([]);
          } else if (res.twLoc && res.twLoc !== '') {
            setSuggestions([res]);
          }
        } else {
          const res = await locService.getLocAutocomplete(inputText);
          setSuggestions(res);
        }
      } catch (error) {
        errorHandler(error);
      } finally {
        setShouldFetch(false);
        isLoading.current = false;
      }
    };

    // useEffect logic
    setPrevInput(inputText);

    if (inputText === '') {
      debounce.current.cancel();
      setShow(false);
      setSuggestions([]);
    } else {
      if (inputText !== prevInput) {
        setShow(true);
        debounce.current();
      }
    }
    !isLoading.current && shouldFetch && fetchSuggestion();
  }, [inputText, setShow, prevInput, suggestions, shouldFetch]);

  return suggestions.length > 0 && isShow ? (
    <Container cssTop={cssTop}>
      {suggestions.map(loc => (
        <DropdownItem
          key={loc.enLoc}
          onClick={e => {
            e.stopPropagation();
            handleSuggestionClick(loc);
          }}
        >
          <div>{getLocDescription(loc)}</div>
        </DropdownItem>
      ))}
    </Container>
  ) : null;
};
