import React, { useState } from 'react';
import styled from 'styled-components';
import SubMemberSetting from 'src/components/ui/interactive/dropdown/SubMemberSetting';
import { IGetMemberSub } from 'src/types/api/MemberApiTypes';
import * as Color from 'src/style/theme/Color';
import { dateFormatter } from 'src/utils/Formatter';
import MiniLoader from 'src/components/ui/interactive/MiniLoader';

const Container = styled.div`
  padding: 16px 0;
  display: flex;
  border-bottom: 1px solid #eceff1;
`;
const Name = styled.div`
  padding: 0 0 0 32px;
  width: 25%;
`;
const Mail = styled.div`
  width: 40%;
`;
const ActivateTime = styled.div`
  width: 10%;
`;
const Status = styled.div<{ statusCode: string }>`
  width: 15%;
  color: ${props => {
    switch (props.statusCode) {
      case 'A':
        return '#12ad46';
      case 'U':
        return Color.alarm4;
      case 'D':
        return '#cc0000';
      default:
        return '#333333';
    }
  }};
  text-align: center;
`;
const Setting = styled.div`
  padding: 0 32px 0 0;
  width: 10%;
  text-align: center;
`;
const convertCodeToMsg = (statusCode: string) => {
  switch (statusCode) {
    case 'A':
      return '啟用';
    case 'U':
      return '未啟用';
    case 'D':
      return '停用';
    default:
      return '未知狀態';
  }
};

interface IProps extends IGetMemberSub {
  updateRecordStatus: (id: number, recordStatus: string) => void;
  handleEditClick: (subMemberId: number) => void;
}

const ManageItem: React.FC<IProps> = props => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Container>
      <Name>{props.name}</Name>
      <Mail>{props.email}</Mail>
      <ActivateTime>{dateFormatter(props.createTime)}</ActivateTime>
      <Status statusCode={props.recordStatus}>
        {isLoading ? (
          <MiniLoader diameter="20px" borderWidth="2px" margin="0 auto" />
        ) : (
          convertCodeToMsg(props.recordStatus)
        )}
      </Status>
      <Setting>
        <SubMemberSetting
          id={props.id}
          name={props.name}
          recordStatus={props.recordStatus}
          updateRecordStatus={props.updateRecordStatus}
          handleEditClick={props.handleEditClick}
          setIsLoading={setIsLoading}
        />
      </Setting>
    </Container>
  );
};

export default ManageItem;
