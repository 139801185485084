import React, { useEffect, useState } from 'react';

import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';

import MiniLoader from 'src/components/ui/interactive/MiniLoader';
import SlideToggle from 'src/components/ui/interactive/SlideToggle';
import {
  Container,
  Subject,
  Description,
  Inform,
  SubContainer,
  SubItem,
} from 'src/style/MemberNotificationStyle';

const NotificationWeeklyInfo: React.FC = () => {
  const [infoSwitch, setInfoSwitch] = useState<boolean>();
  const [switchLoading, setSwitchLoading] = useState<boolean>(true);
  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await Api().get('edm/info/notification');
        res.data !== undefined && setInfoSwitch(res.data);
      } catch (error) {
        errorHandler(error);
      } finally {
        setSwitchLoading(false);
      }
    };
    fetch();
  }, []);

  const handleSwitch = () => {
    setSwitchLoading(true);
    console.log('infoSwitch:', infoSwitch);
    const infoStatus = !infoSwitch;
    console.log('infoStatus:', infoStatus);
    Api({ 'Content-Type': 'application/json' })
      .patch(`edm/info/notification`, infoStatus.toString())
      .then(() => {
        setInfoSwitch(infoStatus);
        setSwitchLoading(false);
      });
  };

  const subjectWidth: string = '160px';

  return (
    <>
      <Container>
        <Subject>電子報訂閱</Subject>
        <Description>分享每週專利新訊息，掌握周遭資訊讓您專利準備更有力！</Description>
        <Inform>
          {switchLoading ? (
            <MiniLoader diameter={'24px'} borderWidth={'2px'} margin={'0 4px'} />
          ) : (
            <SlideToggle checked={infoSwitch || false} change={() => handleSwitch()} />
          )}
        </Inform>
      </Container>
      <SubContainer>
        <SubItem>
          <Subject width={subjectWidth} inactive>
            精選新聞
          </Subject>
          <Description>推薦您本周精選的專利新聞</Description>
        </SubItem>
      </SubContainer>
      <SubContainer>
        <SubItem>
          <Subject width={subjectWidth} inactive>
            國際新知
          </Subject>
          <Description>每週國際新知識，更新您的國際觀點</Description>
        </SubItem>
      </SubContainer>
      <SubContainer>
        <SubItem>
          <Subject width={subjectWidth} inactive>
            專欄文章
          </Subject>
          <Description>當週新增的文章</Description>
        </SubItem>
        <SubItem child={true}>
          <Subject width={subjectWidth} inactive>
            專家文章
          </Subject>
          <Description>邀請專家撰寫文章，分享智權保護資訊或運用經驗</Description>
        </SubItem>
        <SubItem child={true}>
          <Subject width={subjectWidth} inactive>
            國際創新研發報告
          </Subject>
          <Description>提供國際上可運用的資源參考</Description>
        </SubItem>
        <SubItem child={true}>
          <Subject width={subjectWidth} inactive>
            產業輔導分享
          </Subject>
          <Description>邀請企業分享導入IPKM平台的經驗</Description>
        </SubItem>
        <SubItem child={true}>
          <Subject width={subjectWidth} inactive>
            智權知識懶人包
          </Subject>
          <Description>提供智慧財產權知識懶人包</Description>
        </SubItem>
        <SubItem child={true}>
          <Subject width={subjectWidth} inactive>
            智權知識問答集
          </Subject>
          <Description>提供外部常見智權問題及對應的解答</Description>
        </SubItem>
        <SubItem child={true}>
          <Subject width={subjectWidth} inactive>
            智權知識連載漫畫
          </Subject>
          <Description>以「中小企業智權管理」為主題製作連載漫畫系列</Description>
        </SubItem>
        <SubItem child={true}>
          <Subject width={subjectWidth} inactive>
            IP手札 mini !
          </Subject>
          <Description>智慧財產權知識四格漫畫系列</Description>
        </SubItem>
      </SubContainer>
      <SubContainer>
        <SubItem>
          <Subject width={subjectWidth} inactive>
            專利影片
          </Subject>
          <Description>當週發布的精選專利影片</Description>
        </SubItem>
      </SubContainer>
      <SubContainer>
        <SubItem>
          <Subject width={subjectWidth} inactive>
            各國專利制度
          </Subject>
          <Description>當週發布的各國專利制度更動調整</Description>
        </SubItem>
      </SubContainer>
    </>
  );
};

export default NotificationWeeklyInfo;
