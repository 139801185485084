import React, { useEffect, useState } from 'react';

import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';

import GroupBarChart from 'src/components/trend/industrialTrends/chart/GroupBarChart';
import {
  IIndustrialTrendApiParamType,
  IGroupBarChartType,
  IIndustrialTrendRankType,
} from 'src/types/IndustryTypes';
import { getCountryName } from 'src/utils/PatentUtils';
import { getTrendsTitleKindText } from 'src/components/trend/TrendsHelper';

import { Spinner } from 'src/components/ui/interactive/Spinner';
import {
  BlockTitle,
  Flexbox,
  StatSummaryTextContainer,
  StatSummaryGraphContainer,
  StatSummaryTextName,
  SummaryGraphTitle,
  NoResult,
} from 'src/style/IndustrialTrendStyle';

interface IProps {
  apiParams: IIndustrialTrendApiParamType;
}

const defaultRank: IIndustrialTrendRankType = {
  IPC: { name: '', count: 0 },
  COUNTRY: { name: '', count: 0 },
  PARTY: { name: '', count: 0 },
};

const StatSummary: React.FC<IProps> = ({ apiParams }) => {
  const [apiData, setApiData] = useState<Array<IGroupBarChartType>>([]);
  const [rankData, setRankData] = useState<IIndustrialTrendRankType>(defaultRank);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // 行業別改變時先清空所有資料
    setApiData([]);
    setIsLoading(true);

    const fetchData = async () => {
      try {
        const apiRes = await Api().post('trend/industry/summary', {
          industrialClassIds: apiParams.industrialClassIds,
          kind: apiParams.kind,
        });
        const rankRes = await Api().post('trend/industry/topOne', {
          industrialClassIds: apiParams.industrialClassIds,
          kind: apiParams.kind,
        });
        setApiData(apiRes.data);
        setRankData(rankRes.data);
        setIsLoading(false);
      } catch (error) {
        errorHandler(error);
      }
    };
    fetchData();
  }, [apiParams]);

  return (
    <>
      <BlockTitle>總覽</BlockTitle>
      {apiData.length !== 0 ? (
        <Flexbox justifyContent={'space-between'}>
          <StatSummaryTextContainer>
            <p>最多專利的國家</p>
            <StatSummaryTextName>
              {rankData && rankData['COUNTRY'] && getCountryName(rankData['COUNTRY'].name)}
            </StatSummaryTextName>
            <p>最多專利的 IPC 代碼</p>
            <StatSummaryTextName>
              {rankData && rankData['IPC'] && rankData['IPC'].name}
            </StatSummaryTextName>
            <p>最多專利的專利權人</p>
            <StatSummaryTextName>
              {rankData && rankData['PARTY'] && rankData['PARTY'].name}
            </StatSummaryTextName>
          </StatSummaryTextContainer>
          <StatSummaryGraphContainer>
            <SummaryGraphTitle>
              {getTrendsTitleKindText(apiParams.kind)}近五年統計圖
            </SummaryGraphTitle>
            <GroupBarChart dataset={apiData} kind={apiParams.kind} />
          </StatSummaryGraphContainer>
        </Flexbox>
      ) : isLoading ? (
        <Spinner width="200px" margin="84px auto" />
      ) : (
        <NoResult>
          <div>查無資料</div>
          <span>請嘗試變更行業別或時間區間。</span>
        </NoResult>
      )}
    </>
  );
};
export default StatSummary;
