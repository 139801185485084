import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FaTimes, FaTags } from 'react-icons/fa';

import EditTag from 'src/components/ui/interactive/dropdown/EditTag';
import { PatentService } from 'src/api/Patent';
import { alertMessage } from 'src/utils/ModalUtils';

import useLoginChecker from 'src/apps/auth/useLoginChecker';

const patentService = PatentService();

const TagsContainer = styled.div`
  color: #b3b9bc;
  display: flex;
  align-items: center;
  > span {
    flex-shrink: 0;
  }
`;
const Tags = styled.div`
  overflow: hidden;
`;
const Tag = styled.span`
  color: #ffffff;
  background-color: #0cafcc;
  border-radius: 4px;
  padding: 1px 8px;
  margin-left: 8px;
  white-space: nowrap;
  overflow: hidden;
  &:hover {
    background-color: #b5e4ec;
    color: #5f686c;
  }
`;
const TagIcon = styled.div`
  position: relative;
  margin-left: 8px;
  * {
    vertical-align: middle;
  }
  > i {
    display: block;
    position: relative;
    width: 36px;
    height: 36px;
    line-height: 32px;
    text-align: center;
    /* padding: 2px 0 0 12px; */
    color: #0cafcc;

    font-size: 16px;
    cursor: pointer;

    &:hover {
      background-color: #f5fdfe;
      border-radius: 18px;

      &::before {
        position: absolute;
        right: -100px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 92px;
        height: 31px;
        border-radius: 4px;
        background-color: #003f54;
        font-size: 15px;
        color: #ffffff;
        font-style: normal;
        content: '管理標籤';
      }
    }
  }
`;

const EditTagForm = styled.div`
  position: absolute;
  top: 45px;
  right: 0px;
`;
const TagButton = styled.span`
  margin-left: 4px;
  vertical-align: middle;
  cursor: pointer;
`;

interface IProps {
  patentDataId: number;
}

function PatentTags(props: IProps) {
  const isLogin = useLoginChecker();
  const [tags, setTags] = useState<Array<string>>([]);
  const [editTag, setEditTag] = useState(false);

  useEffect(() => {
    isLogin && patentService.getPatentTagById(props.patentDataId).then(data => setTags(data));
  }, [isLogin, props.patentDataId]);

  const handleDeleteTag = (e: any) => {
    const targetName = e.currentTarget.getAttribute('data-name');
    const targetIdx = e.currentTarget.getAttribute('data-idx');
    let tagArr = tags;
    tagArr.splice(targetIdx, 1);
    // TODO: 需要頁面跳轉或延後執行部分程式的作法需再研議
    alertMessage(`將刪除專利標籤「${targetName}」`);
    patentService.putPatentTag(props.patentDataId, tagArr).then(
      () => {
        setTags([...tagArr]);
      },
      err => {
        // TODO: 評估是否由errorHandler處理
        alertMessage(err.response.data.resultMessage);
      },
    );
  };

  const handleEditTags = () => {
    patentService.getPatentTagById(props.patentDataId).then(
      data => {
        setTags(data);
        setEditTag(false);
      },
      err => {
        // TODO: 評估是否由errorHandler處理
        alertMessage(err.response.data.resultMessage);
      },
    );
  };

  return (
    <TagsContainer>
      <span>標籤</span>
      <Tags>
        {tags.map((item, idx) => (
          <Tag key={item}>
            {item}
            <TagButton>
              <FaTimes
                size="15px"
                data-name={item}
                data-idx={idx}
                onClick={(e: any) => handleDeleteTag(e)}
              />
            </TagButton>
          </Tag>
        ))}
      </Tags>
      <TagIcon>
        <i onClick={() => setEditTag(!editTag)}>
          <FaTags />
        </i>
        {editTag && (
          <EditTagForm>
            <EditTag
              show={editTag}
              setShow={setEditTag}
              patentDataId={props.patentDataId}
              patentTags={tags}
              submitEditTags={handleEditTags}
            />
          </EditTagForm>
        )}
      </TagIcon>
    </TagsContainer>
  );
}

export default PatentTags;
