import {
  NAVIGATE_TO_NEXT_PAGE,
  SELECT_DATE_RANGE,
  CHANGE_SORT_ORDER,
  FETCH_APP_NUM_LIST_START,
  FETCH_APP_NUM_LIST_SUCCESS,
  FETCH_APP_NUM_LIST_ERROR,
  ByImageSearchState,
  ByImageSearchActionTypes,
} from './type';

const initialState: ByImageSearchState = {
  request: {
    appNumList: [],
    pageNo: 0,
  },
  response: [],
  isAppNumListLoading: false,
};

const patentSearchReducer = (
  state = initialState,
  action: ByImageSearchActionTypes,
): ByImageSearchState => {
  const prevRequest = state.request;

  switch (action.type) {
    case NAVIGATE_TO_NEXT_PAGE:
      return {
        ...state,
        request: {
          ...prevRequest,
          pageNo: prevRequest.pageNo + 1,
        },
      };
    case SELECT_DATE_RANGE:
      return {
        ...state,
        request: {
          ...prevRequest,
          dateRange: action.dateRange,
          pageNo: 1,
        },
      };
    case CHANGE_SORT_ORDER:
      return {
        ...state,
        request: {
          ...prevRequest,
          sortByDate: action.sortByDate,
          pageNo: 1,
        },
      };
    case FETCH_APP_NUM_LIST_START:
      return {
        ...state,
        isAppNumListLoading: true,
      };
    case FETCH_APP_NUM_LIST_SUCCESS:
      return {
        isAppNumListLoading: false,
        request: {
          appNumList: action.response.data.map((item: { patent_name: string }) => item.patent_name),
          pageNo: 1, // 預設查詢第一頁
        },
        response: action.response.data,
      };
    case FETCH_APP_NUM_LIST_ERROR:
      return {
        ...initialState,
      };
    default:
      return { ...state };
  }
};

export default patentSearchReducer;
