import React from 'react';
import styled from 'styled-components';

import { Row, Term, TermTag, TermText } from 'src/style/PatentSearchStyle';
import { iceblue5 } from 'src/style/theme/Color';
import { DescriptionData } from 'src/types/DescriptionTypes';

const Container = styled.div`
  margin-top: 16px;
  padding: 16px 16px;
`;

interface IProps {
  descriptionData?: DescriptionData[];
}

const Description: React.FC<IProps> = ({ descriptionData }) => {
  const queryOperators = [
    { op: '""', text: '查詢完整字辭' },
    { op: '*', text: '萬用字元，替代缺漏字' },
    { op: '?', text: '萬用字元，替代1個缺漏字' },
    { op: 'AND', text: '資料中同時有A和B' },
    { op: 'OR', text: '資料中有A、B兩者其中之一即可' },
    { op: 'NOT', text: '資料中有A但是不含B' },
    {
      op: '()',
      text: '調整運算優先順序',
      description: '(A OR B) AND C 表示資料是有A或B，再有C',
    },
    {
      op: '[n,m]',
      text: '鄰近運算',
      description: 'A [1,5] B 表示 B 在 A 後面 1 至 5 個字',
    },
    {
      op: '@',
      text: '模糊查詢',
      description: "(王)@IN 查詢 '王' 在發明人任意位置的資料，相當於 IN=*王*",
    },
    {
      op: '@A,B',
      text: '跨欄位檢索',
      description: '同時在欄位 A 及欄位 B 進行欄位檢索',
    },
  ];

  return (
    <Container>
      <Row flexWrap="wrap" hasBorderBottom>
        {queryOperators.map(item => (
          <Term key={item.text} width={item.description ? '100%' : '50%'}>
            <TermTag>{item.op}</TermTag>
            <TermText>{item.text}</TermText>
            {item.description && <TermText color={iceblue5}>{item.description}</TermText>}
          </Term>
        ))}
      </Row>
      <Row flexWrap="wrap">
        {descriptionData &&
          descriptionData.map(item => (
            <Term key={item.code} width={item.singleRow ? '100%' : '50%'}>
              <TermTag>{item.code}</TermTag>
              <TermText>{item.text}</TermText>
              {item.description && <TermText color={iceblue5}>{item.description}</TermText>}
            </Term>
          ))}
      </Row>
    </Container>
  );
};

export default Description;
