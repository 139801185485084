import React from 'react';
import { css } from 'styled-components';

import { Dropdown, IDropdownItem } from 'src/components/common/Dropdown';

import { blue2, blue3, iceblue2 } from 'src/style/theme/Color';

const cssDropdownContainer = css`
  min-width: 84px;
  margin-left: 8px;
`;
// Dropdown.tsx 當中 的 isOpen 會做為 active 屬性傳入 DropdownSelectedItem。
// 這裡的 cssDropdownSelectedItem 也會一路傳遞到 DropdownSelectedItem，
// 因此可藉由 active 來控制相對應樣式。
const cssDropdownSelectedItem = css<{ active: boolean }>`
  padding: 3px 0;
  color: ${props => (props.active ? blue2 : blue3)};
  /* 覆蓋 Dropdown.tsx 原始的 background-color 設定。 */
  background-color: inherit;
  :hover {
    color: ${blue2};
    /* 同上述之 hover 設定。 */
    background-color: inherit;
  }
  div {
    text-align: right;
  }
  svg {
    margin-left: 8px;
  }
`;
const cssDropdownItemBlock = css`
  min-width: 100%;
  right: 0;
  top: 32px;
  color: ${iceblue2};
`;

interface IProps {
  items: IDropdownItem[];
  activeItem: IDropdownItem;
  handleOnclick: (item: IDropdownItem) => void;
  customStyleSvgSize?: number;
  customStyleSvg?: JSX.Element;
}

/** Dropdown 客制化樣式 - 篩選功能 */
const FilterDropdown: React.FC<IProps> = ({
  items,
  activeItem,
  handleOnclick,
  customStyleSvgSize,
  customStyleSvg,
}) => {
  return (
    <Dropdown
      items={items}
      activeItem={activeItem}
      handleOnclick={handleOnclick}
      customStyleContainer={cssDropdownContainer}
      customStyleSelectedItem={cssDropdownSelectedItem}
      customStyleItemBlock={cssDropdownItemBlock}
      customStyleSvgSize={customStyleSvgSize}
      customStyleSvg={customStyleSvg}
    />
  );
};

export default FilterDropdown;
