import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components';
import { Backdrop } from 'src/style/ModalStyle';
import { zModal } from 'src/style/theme/Z-Index';
import { connect } from 'react-redux';
import { closeCustomModal } from 'src/redux/actions/modalAction';
import {
  ultrablackrgba,
  ultrawhite,
  iceblue1,
  iceblue3,
  iceblue5,
  blue2,
  blue3,
  blue6rgba,
  blue7,
  blue8,
} from 'src/style/theme/Color';
import { FaPrint, FaTimes, FaDownload } from 'react-icons/fa';
import { formatDateTime } from 'src/utils/Formatter';
import ClickOutside from 'react-onclickout';
import { saveAsSvg, saveAsPng, saveAsJpeg } from 'src/utils/svgDownloadUtils';
import { Spinner } from 'src/components/ui/interactive/Spinner';

const Container = styled.div`
  padding: 32px;
  border-radius: 4px;
  box-shadow: 0 0 50px 0 ${ultrablackrgba(0.1)};
  background-color: ${ultrawhite};
  color: ${iceblue1};
  min-width: 914px;

  @media print {
    box-shadow: none;
    /** 讓 chrome 列印時顯示 background-color */
    -webkit-print-color-adjust: exact;
  }
`;

const Header = styled.div`
  padding-left: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 4px;
  max-width: 80%;
  word-break: break-all;
`;

const MenuBar = styled.div`
  @media print {
    display: none;
  }
`;

const MenuIcon = styled.i`
  display: inline-flex;
  align-items: center;
  padding: 12px;
  :hover {
    cursor: pointer;
  }
  svg {
    font-size: 20px;
  }
`;

const DownloadIcon = styled(MenuIcon)<{ isPopup: boolean }>`
  ${props =>
    props.isPopup &&
    css`
      border-radius: 50%;
      background-color: ${blue7};
    `}
`;

const DownloadButton = styled.div`
  display: inline-block;
  position: relative;
`;

const DownloadPopup = styled.div`
  position: absolute;
  top: 52px;
  right: 0;
  padding: 4px 0;
  background-color: ${ultrawhite};
  box-shadow: 0 5px 20px 0 ${ultrablackrgba(0.1)};
  border-radius: 4px;

  div {
    white-space: nowrap;
    padding: 4px 16px;
    :hover {
      cursor: pointer;
      background-color: ${blue7};
    }
  }

  > a {
    color: inherit;
  }
`;

const Time = styled.div`
  padding-left: 24px;
  span {
    margin-right: 4px;
  }
  margin-bottom: 8px;
`;

const scrollbarStyle = css`
  ::-webkit-scrollbar {
    height: 8px; /* height of horizontal scrollbar */
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    box-shadow: inset 0 0 8px 8px ${blue6rgba(0.8)};
    border: solid 2px transparent;
  }
`;

const Body = styled.div`
  max-height: 72.5vh;
  overflow: auto;
  ${scrollbarStyle}
  @media print {
    max-height: none;
  }
`;

const ErrorBox = styled.div`
  margin: 16px 32px;
  padding: 16px 32px;
  background-color: ${blue8};
  border-radius: 4px;

  div {
    color: ${blue2};
    font-weight: 600;
    margin-bottom: 8px;
  }
  span {
    color: ${iceblue3};
  }
`;

interface IReduxMappingProps {
  closeCustomModal: () => void;
}

interface IProps extends IReduxMappingProps {
  title: string;
  extraDownloadItem?: JSX.Element;
  isDataLoading?: boolean;
  isError?: boolean;
}

const modal = document.getElementById('modal-custom');
const portalDiv = modal ? modal : document.createElement('div');

const ChartModal: React.FC<IProps> = props => {
  const [createTime, setCreateTime] = useState<string>(formatDateTime(new Date(), true, true));
  const [isDownloadPopup, setIsDownloadPopup] = useState<boolean>(false);

  useEffect(() => {
    // 有進行API取資料後才選染的圖表，更新產生時間。
    !props.isDataLoading && setCreateTime(formatDateTime(new Date(), true, true));
  }, [props.isDataLoading]);

  const renderTemplate = (content: JSX.Element) => (
    <>
      <Header>
        <Title>{props.title}</Title>
        <MenuBar>
          <MenuIcon onClick={() => props.closeCustomModal()}>
            <FaTimes color={iceblue5} />
          </MenuIcon>
        </MenuBar>
      </Header>
      <Body>{content}</Body>
    </>
  );

  return ReactDOM.createPortal(
    <Backdrop zIndex={zModal.custom} alignItems="center">
      <Container>
        {props.isError ? (
          renderTemplate(
            <ErrorBox>
              <div>很抱歉，目前服務發生異常。</div>
              <span>請確認您的設備連線或嘗試變更檢索內容或稍後再試，謝謝。</span>
            </ErrorBox>,
          )
        ) : props.isDataLoading ? (
          renderTemplate(<Spinner width="200px" margin="120px auto" />)
        ) : (
          <>
            <Header>
              <Title>{props.title}</Title>
              <MenuBar>
                <MenuIcon
                  onClick={() => {
                    window.print();
                  }}
                >
                  <FaPrint color={blue3} />
                </MenuIcon>
                <DownloadButton>
                  <ClickOutside onClickOut={() => setIsDownloadPopup(false)}>
                    <DownloadIcon
                      isPopup={isDownloadPopup}
                      onClick={() => setIsDownloadPopup(prev => !prev)}
                    >
                      <FaDownload color={blue3} />
                    </DownloadIcon>
                    {isDownloadPopup && (
                      <DownloadPopup>
                        <div onClick={() => saveAsPng(props.title)}>PNG 格式</div>
                        <div onClick={() => saveAsJpeg(props.title)}>JPG 格式</div>
                        <div onClick={() => saveAsSvg(props.title)}>SVG 格式</div>
                        {props.extraDownloadItem}
                      </DownloadPopup>
                    )}
                  </ClickOutside>
                </DownloadButton>
                <MenuIcon onClick={() => props.closeCustomModal()}>
                  <FaTimes color={iceblue5} />
                </MenuIcon>
              </MenuBar>
            </Header>
            <Time>
              <span>表格產生時間：</span>
              <span>{createTime}</span>
            </Time>
            <Body>{props.children}</Body>
          </>
        )}
      </Container>
    </Backdrop>,
    portalDiv,
  );
};

const mapDispatchToProps = {
  closeCustomModal,
};

export default connect(
  null,
  mapDispatchToProps,
)(ChartModal);
