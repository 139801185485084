import React, { useCallback, useEffect, useState } from 'react';
import { FaInfoCircle } from 'react-icons/fa';

import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';

import LineChart from 'src/components/trend/industrialTrends/chart/LineChart';
import BubbleChart from 'src/components/trend/industrialTrends/chart/BubbleChart';
import { Dropdown } from 'src/components/common/Dropdown';
import Tooltip from 'src/components/trend/industrialTrends/chart/Tooltip';

import { Spinner } from 'src/components/ui/interactive/Spinner';
import {
  IIndustrialTrendApiParamType,
  ILineChartType,
  IBubbleChartDataType,
  IIndustrialTrendType,
} from 'src/types/IndustryTypes';
import { getCountryName } from 'src/utils/PatentUtils';
import {
  getTrendsTitleKindText,
  getTrendsTitleClassText,
  getStatStartYear,
  getStatEndYear,
  getStatRangeText,
  convertToLineChartData,
} from 'src/components/trend/TrendsHelper';
import {
  BlockTitle,
  Flexbox,
  StatLeftContainer,
  GraphTitle,
  RightGraphTitle,
  LineChartToggler,
  ArrowLeft,
  ArrowRight,
  StatRightContainer,
  LineChartLoader,
  NoResult,
  customStyleSelectedItemStyle,
  ToolTipTitle,
  ToolTipContent,
} from 'src/style/IndustrialTrendStyle';
import { CustomModalType } from 'src/types/ModalTypes';
import styled from 'styled-components';
import { blue4, ultrawhite } from 'src/style/theme/Color';
import { closeCustomModal, openCustomModal } from 'src/redux/actions/modalAction';
import { connect } from 'react-redux';
import { ReduxAppState } from 'src/redux/reducers';
import IndustrialClassIpcRangeModal from 'src/components/ui/interactive/modal/custom/IndustrialClassIpcRangeModal';

const Row = styled.div<{ margin?: string; justify?: string }>`
  margin: ${({ margin }) => margin};
  padding: 0 48px;
  width: 100%;
  display: flex;
  justify-content: ${({ justify }) => (justify ? justify : 'center')};
  align-items: center;
  flex-flow: row wrap;
  color: ${ultrawhite};
  font-size: 40px;
  text-align: center;
  font-weight: bold;
`;
const MoreStat = styled.div`
  color: ${blue4};
  font-size: 16px;
  cursor: pointer;
`;

const Bubble = styled.div`
  text-align: center;
`;

interface IReduxMappingProps {
  isModalOpen: boolean;
  openCustomModal: (customModalType: CustomModalType) => void;
  closeCustomModal: () => void;
}

interface IProps extends IReduxMappingProps {
  apiParams: IIndustrialTrendApiParamType;
  statTimeRange: Array<string>;
  title: string;
}

// 國別下拉選單
const countryDropdown = [
  { name: getCountryName('TW'), value: 'TW' },
  { name: getCountryName('US'), value: 'US' },
  { name: getCountryName('EP'), value: 'EP' },
  { name: getCountryName('JP'), value: 'JP' },
  { name: getCountryName('CN'), value: 'CN' },
  { name: getCountryName('KR'), value: 'KR' },
];

const StatIpc: React.FC<IProps> = ({
  apiParams,
  statTimeRange,
  isModalOpen,
  openCustomModal,
  closeCustomModal,
  title,
}) => {
  const [activeItem, setActiveItem] = useState('');
  const [selectedCountry, setSelectedCountry] = useState(countryDropdown[0]);
  const [apiData, setApiData] = useState<Array<IIndustrialTrendType>>([]);
  const [bubbleCharData, setBubbleCharData] = useState<Array<IBubbleChartDataType>>([]);
  const [lineChartData, setLineChartData] = useState<Array<ILineChartType>>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [tooltipPosition, setTooltipPosition] = useState<{ top: number; left: number } | undefined>(
    undefined,
  );
  const minYear = getStatStartYear(statTimeRange);
  const maxYear = getStatEndYear(statTimeRange);
  const [selectedYear, setSelectedYear] = useState(maxYear);
  const onClickCallBack = useCallback((code: string) => {
    setActiveItem(code);
  }, []);

  useEffect(() => {
    // 行業別/專利類別/國別改變時, 先清空所有資料
    setSelectedYear(maxYear);
    setApiData([]);
    setBubbleCharData([]);
    setLineChartData([]);
    setIsLoading(true);

    const fetchData = async () => {
      try {
        const apiRes = await Api().post('trend/industry/ipc', {
          industrialClassIds: apiParams.industrialClassIds,
          kind: apiParams.kind,
          country: selectedCountry.value,
        });
        setApiData(apiRes.data);
        apiRes.data.length > 0 && setActiveItem(apiRes.data[0].category);
        setBubbleCharData(
          apiRes.data.map((item: IIndustrialTrendType) => {
            return {
              code: item.category,
              description: item.description,
              count: item.count,
            };
          }),
        );
        setIsLoading(false);
      } catch (error) {
        errorHandler(error);
      }
    };
    fetchData();
  }, [apiParams, selectedCountry, maxYear]);

  useEffect(() => {
    // 指定IPC改變時, 先清空折線圖資料
    setLineChartData([]);
    const fetchData = async () => {
      try {
        const past = await Api().post('trend/industry/ipc', {
          industrialClassIds: apiParams.industrialClassIds,
          kind: apiParams.kind,
          country: selectedCountry.value,
          ipcCode: activeItem,
          year: selectedYear - 1,
        });
        const current = await Api().post('trend/industry/ipc', {
          industrialClassIds: apiParams.industrialClassIds,
          kind: apiParams.kind,
          country: selectedCountry.value,
          ipcCode: activeItem,
          year: selectedYear,
        });
        let result: Array<ILineChartType> = [];
        result.push({
          name: (selectedYear - 1).toString(),
          data: convertToLineChartData(past.data, selectedYear - 1, statTimeRange),
        });
        result.push({
          name: selectedYear.toString(),
          data: convertToLineChartData(current.data, selectedYear, statTimeRange),
        });
        setLineChartData(result);
      } catch (error) {
        errorHandler(error);
      }
    };
    fetchData();
  }, [apiParams, selectedCountry, selectedYear, activeItem, statTimeRange]);

  /** 取得Tooltip的顯示座標位置 */
  function handleToolTip(element: any) {
    const offset = 8; // icon與tooltip間距
    const rect = element.getBoundingClientRect();
    setTooltipPosition({ left: rect.right - 5, top: rect.top - offset });
  }

  const tooltip = tooltipPosition && (
    <Tooltip
      left={tooltipPosition.left}
      top={tooltipPosition.top}
      overlay={
        <>
          <ToolTipTitle>數據統計說明</ToolTipTitle>
          <ToolTipContent>
            <p>統計時間 : {getStatRangeText(statTimeRange)}</p>
            <p>件數(數字) : 指定行業之 IPC 代碼近五年{getTrendsTitleKindText(apiParams.kind)}。</p>
          </ToolTipContent>
        </>
      }
    />
  );

  return (
    <>
      <BlockTitle>IPC 趨勢</BlockTitle>
      {tooltip}
      {apiData.length !== 0 ? (
        <Flexbox>
          <StatLeftContainer>
            <GraphTitle>
              <Dropdown
                activeItem={selectedCountry}
                items={countryDropdown}
                handleOnclick={setSelectedCountry}
                customStyleSelectedItem={customStyleSelectedItemStyle}
              />
              {getTrendsTitleClassText(apiParams.industrialClassIds).length > 0
                ? getTrendsTitleClassText(apiParams.industrialClassIds) + ' 近五年件數比較'
                : 'IPC 近五年件數比較'}
              <FaInfoCircle
                onMouseOver={(e: any) => handleToolTip(e.currentTarget)}
                onMouseLeave={() => setTooltipPosition(undefined)}
              />
            </GraphTitle>
            <Bubble>
              <BubbleChart
                dataset={bubbleCharData}
                activeItem={activeItem}
                onClick={onClickCallBack}
                width={320}
                height={240}
                size={5}
              />
            </Bubble>

            <Row margin="16px 0 0 0">
              <MoreStat onClick={() => openCustomModal(CustomModalType.INDUSTRIAL_CLASS_IPC_RANGE)}>
                顯示近五年所有 IPC 件數
              </MoreStat>
            </Row>
            {isModalOpen && (
              <IndustrialClassIpcRangeModal
                onClose={() => closeCustomModal()}
                data={bubbleCharData}
                statTimeRange={getStatRangeText(statTimeRange)}
                country={selectedCountry.name}
                title={title}
                apiParams={apiParams}
              />
            )}
          </StatLeftContainer>
          <StatRightContainer>
            <RightGraphTitle>
              {selectedCountry.name}&nbsp;
              {activeItem}&nbsp;
              {getTrendsTitleClassText(apiParams.industrialClassIds)}
              {getTrendsTitleKindText(apiParams.kind)}年度趨勢
            </RightGraphTitle>
            <LineChartToggler>
              <ArrowLeft
                disabled={selectedYear <= minYear}
                onClick={() => {
                  if (selectedYear > minYear) {
                    setSelectedYear(selectedYear - 1);
                  }
                }}
              />
              {selectedYear} 年
              <ArrowRight
                disabled={selectedYear >= maxYear}
                onClick={() => {
                  if (selectedYear < maxYear) {
                    setSelectedYear(selectedYear + 1);
                  }
                }}
              />
            </LineChartToggler>
            {lineChartData.length !== 0 ? (
              <LineChart dataset={lineChartData} />
            ) : (
              <LineChartLoader />
            )}
          </StatRightContainer>
        </Flexbox>
      ) : isLoading ? (
        <Spinner width="200px" margin="84px auto" />
      ) : (
        <NoResult>
          <div>查無資料</div>
          <span>請嘗試變更行業別或時間區間。</span>
        </NoResult>
      )}
    </>
  );
};

const mapStateToProps = (state: ReduxAppState) => {
  return {
    isModalOpen: state.modalReducer.customModalType === CustomModalType.INDUSTRIAL_CLASS_IPC_RANGE,
  };
};

const mapDispatchToProps = {
  openCustomModal,
  closeCustomModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StatIpc);
