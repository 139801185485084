import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ultrawhite, blue2 } from 'src/style/theme/Color';
import { Api } from 'src/api/helpers/apiBase';
import { IGetIndustrialTopic } from 'src/types/IndustrialTopicTypes';
//TODO 樣式待調整，api串接待調整，連結待調整
const BUTTON_TEXT_WEIGHT = 500;
const BUTTON_DARK_COLOR = blue2;
const BUTTON_LIGHT_COLOR = ultrawhite;
// const LOCAL_HOST_URL = 'http://localhost:3001'; //TODO 待刪除
const API_URL = 'industry/topic';
const PAGE_URL = 'topic';

const NavButton = styled.a`
  margin: auto 0px;
  border-radius: 4px;
  background-color: ${BUTTON_LIGHT_COLOR};
  font-weight: ${BUTTON_TEXT_WEIGHT}
  vertical-align: middle;
  padding: 4px 12px 4px 12px;
  color: ${BUTTON_DARK_COLOR};
  :hover {
    color: ${BUTTON_LIGHT_COLOR};
    background-color: ${BUTTON_DARK_COLOR};
  }
`;

const Container = styled.div`
  margin-top: 1em;
  display: flex;
  gap: 1em;
`;

const Title = styled.span`
  font-size: 1em;
  padding-top: 4px;
  vertical-align: middle;
`;

//首頁產業專區連結
const IndustrialTopicLinks = () => {
  const [topics, setTopics] = useState<Array<IGetIndustrialTopic>>([]);
  useEffect(() => {
    Api()
      .get(`${API_URL}`)
      .then(resp => setTopics(resp.data));
  }, []);

  return (
    <Container>
      {topics.length > 0 && (
        <>
          <Title>主題專區:</Title>
          {topics.map(topic => (
            <NavButton
              key={topic.id}
              href={`${process.env.REACT_APP_MOBILE_URL + PAGE_URL}/${topic.id}`}
            >
              {topic.name}
            </NavButton>
          ))}
        </>
      )}
    </Container>
  );
};

export default IndustrialTopicLinks;
