import {
  CHANGE_ACTIVE_PANE,
  SAVE_MATRIX_FORM,
  SAVE_MATRIX_AXIS_DATA,
  SAVE_MATRIX_DATASET,
  ToolbarActionTypes,
  IFieldValue,
  IMatrixData,
} from './types';

export function changeActivePane(activePane: string): ToolbarActionTypes {
  return {
    type: CHANGE_ACTIVE_PANE,
    activePane: activePane,
  };
}

export function saveMatrixForm(matrixForm: IMatrixData): ToolbarActionTypes {
  return {
    type: SAVE_MATRIX_FORM,
    matrixForm: matrixForm,
  };
}

export function saveMatrixAxisData(
  technologyList: Array<IFieldValue>,
  functionList: Array<IFieldValue>,
): ToolbarActionTypes {
  return {
    type: SAVE_MATRIX_AXIS_DATA,
    technologyList: technologyList,
    functionList: functionList,
  };
}

export function saveMatrixDataset(dataset: Array<Array<number>>): ToolbarActionTypes {
  return {
    type: SAVE_MATRIX_DATASET,
    dataset: dataset,
  };
}
