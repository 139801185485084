import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { Backdrop } from 'src/style/ModalStyle';
import {
  blue1,
  iceblue1,
  iceblue5,
  iceblue6,
  pink6,
  ultrablack,
  ultrawhite,
  white,
} from 'src/style/theme/Color';
import { zModal } from 'src/style/theme/Z-Index';
import { closeCustomModal } from 'src/redux/actions/modalAction';
import { Button } from 'src/style/theme/Common';

// 高度由 Body 決定
const Container = styled.div`
  position: relative;
  border-radius: 4px 4px 0 0;
  background-color: #ffffff;
  max-width: 900px;
  width: 750px;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 32px;
  svg {
    font-size: 20px;
    margin-left: 24px;
    cursor: pointer;
  }
`;

const Title = styled.div`
  color: ${blue1};
  font-size: 24px;
  font-weight: bold;
`;

const Body = styled.div`
  width: 100%;
  overflow: auto;
  margin: auto;
  padding: 16px 32px;
`;

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-flow: row;
`;

const Label = styled.div`
  color: ${ultrablack};
  font-size: 16px;
  font-weight: bold;
  padding: 5px 0;
`;

const NotifyMsg = styled.span<{ width: string }>`
  width: ${props => props.width};
  color: ${pink6};
`;

const Footer = styled.div`
  margin: 16px 32px 32px;
`;

interface IReduxMappingProps {
  closeCustomModal: () => void;
}

interface IProps extends IReduxMappingProps {
  defaultInput?: string;
  handleSearch: (input: string) => void;
  notifyMsg?: string;
}

const Input = styled.input.attrs<{ width: string }>({ type: 'text' })`
  width: ${props => props.width};
  padding: 5px 16px;
  border-radius: 4px;
  border: solid 1px ${iceblue5};
  background-color: ${ultrawhite};
  color: ${iceblue1};
  :placeholder {
    color: ${iceblue6};
  }
  :disabled {
    background-color: ${white};
    cursor: not-allowed;
    color: ${iceblue6};
  }
`;

const modal = document.getElementById('modal-custom');
const portalDiv = modal ? modal : document.createElement('div');

const TmCaseNoSearchModal: React.FC<IProps> = props => {
  const {
    handleSearch,
    notifyMsg,
    defaultInput = '',
    closeCustomModal: reduxCloseCustomModal,
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);

  const handleSearchInput = () => {
    handleSearch(inputRef.current ? inputRef.current.value : '');
    reduxCloseCustomModal();
  };

  return ReactDOM.createPortal(
    <Backdrop zIndex={zModal.custom} alignItems="center">
      <Container>
        <>
          <Header>
            <Title>重新輸入申請案號</Title>
          </Header>
          <Body>
            <Row>
              <Label>申請案號</Label>
              <Input
                width="80%"
                ref={inputRef as any}
                defaultValue={defaultInput}
                placeholder="輸入案號並點選確認鈕取得對應商標圖後，需點選查詢鈕重新進行查詢"
              />
            </Row>
            <Row>
              <Label />
              <NotifyMsg width="80%">{notifyMsg && <span>{notifyMsg}</span>}</NotifyMsg>
            </Row>
          </Body>
          <Footer>
            <Button
              type="button"
              template="primary"
              margin={'0 0'}
              onClick={() => handleSearchInput()}
            >
              確認
            </Button>
            <Button onClick={() => reduxCloseCustomModal()}>取消</Button>
          </Footer>
        </>
      </Container>
    </Backdrop>,
    portalDiv,
  );
};

const mapDispatchToProps = {
  closeCustomModal,
};

export default connect(
  null,
  mapDispatchToProps,
)(TmCaseNoSearchModal);
