import React, { useEffect } from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router-dom';

import MobileNav from 'src/components/mobile/nav/MobileNav';

import useJwtManager from 'src/apps/auth/useJwtManager';
import TopNav from 'src/components/common/nav/top-nav/TopNav';
import AppRoute from 'src/routes/AppRoute';
import CookieFooter from 'src/components/common/CookieFooter';

// import TagManager from 'react-gtm-module';
// import { getUserIndustry } from 'src/utils/IndustryUtils';

import { connect } from 'react-redux';
import { fetchIndustrialClass } from 'src/redux/actions/industrialClassAction';
import { getMemberAuthAndPayloadFromJwt } from 'src/redux/member/action';
import { isMobile } from 'src/apps/browser/UserAgent';

const Container = styled.div<{ isMobile?: boolean }>`
  padding-top: ${props => (props.isMobile ? 'calc(92px + 48px)' : '62px')};
  font-size: 15px;
`;

interface IProps extends RouteComponentProps {
  fetchIndustrialClass: () => void;
  getMemberAuthAndPayloadFromJwt: () => void;
}

const App = ({ fetchIndustrialClass, getMemberAuthAndPayloadFromJwt, location }: IProps) => {
  const hasToken = useJwtManager();

  const isMobileUrl = location.pathname.startsWith('/lite');
  const isMobileAgent = isMobile;

  useEffect(() => {
    const mobileUrl = process.env.REACT_APP_MOBILE_URL;
    const articleViewPathRegExp: RegExp = /^article\/columnist\/\d+\/view$/;
    const articleListPathRegExp: RegExp = /^article\/columnist\/\d$/;
    const articleLiteListPathRegExp: RegExp = /^lite\/article\/columnist\/\d$/;
    let redirectUrl = mobileUrl;
    let currentPath = window.location.pathname.slice(1);

    switch (true) {
      // 單篇文章轉向行動版對應文章
      case articleViewPathRegExp.test(currentPath):
        redirectUrl = mobileUrl + currentPath.replace(/\/view/gi, '');
        break;
      // 文章列表轉向電腦版lite文章列表
      case articleListPathRegExp.test(currentPath):
        redirectUrl = '/lite/' + currentPath;
        break;
    }
    // 電腦版手機裝置轉向行動版, 但排除電腦版lite文章列表頁面
    if (isMobileAgent && mobileUrl && redirectUrl && !articleLiteListPathRegExp.test(currentPath)) {
      window.location.href = redirectUrl;
    }
  }, [isMobileAgent]);
  // 將產業別傳至GA (待reducer存入產業清單後才能抓到正確值, GA會以最後一次回傳值代表整個工作階段)
  // const tagManagerArgs = {
  //   dataLayer: {
  //     industryTechnology: getUserIndustry().name,
  //   },
  //   dataLayerName: 'PageDataLayer',
  // };
  // TagManager.dataLayer(tagManagerArgs);

  useEffect(() => {
    // Redux
    if (hasToken) {
      // Asynchronous
      fetchIndustrialClass();
      // Synchronous
      getMemberAuthAndPayloadFromJwt();
    }
  }, [hasToken, fetchIndustrialClass, getMemberAuthAndPayloadFromJwt]);

  return (
    <>
      {hasToken && (
        <>
          {isMobileUrl ? <MobileNav /> : <TopNav />}
          <Container isMobile={isMobileUrl}>
            <AppRoute />
          </Container>
        </>
      )}
      <CookieFooter />
    </>
  );
};

const mapDispatchToProps = {
  fetchIndustrialClass,
  getMemberAuthAndPayloadFromJwt,
};

export default connect(
  null,
  mapDispatchToProps,
)(App);
