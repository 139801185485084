import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FaSortAmountDown } from 'react-icons/fa';

import FilterDropdown from 'src/components/ui/interactive/dropdown-select/FilterDropdown';
import IndustrialClassArrowDropdown from 'src/components/trend/industrialClass/ui/ArrowDropdown';
import { IDropdownItem, IDropdownProps, ISelectedItem } from 'src/components/common/Dropdown';
import ExpertDropdown from 'src/components/ui/content/Search/Dropdown';
import { IIndustrialClassType } from 'src/types/IndustryTypes';
import { IExpertRequest } from 'src/types/ExpertTypes';
import { dateRangeItems } from 'src/constants/PatentSearchConstants';
import {
  whitergba,
  ultrawhite,
  ultrawhitergba,
  ultrablackrgba,
  blue7,
  blue2rgba,
  iceblue1,
  iceblue6,
  white,
} from 'src/style/theme/Color';
import { Button } from 'src/style/theme/Common';
import { numFormatter } from 'src/utils/Formatter';

const Container = styled.div`
  min-width: 750px;
  border-radius: 4px;
  box-shadow: 0 0 50px 0 ${ultrablackrgba(0.1)};
`;

const Header = styled.div`
  color: ${ultrawhite};
  background-image: linear-gradient(95deg, #2ec3c2, #0cafcc);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 16px 32px;
  form {
    margin-bottom: -44px;
  }
  /* 避免產業類別下拉選單展開時 被下方專家照片蓋住。 */
  position: relative;
  z-index: 2;
`;

const HeaderText = styled.div`
  margin-bottom: 8px;
`;

const HeaderRoute = styled.div`
  color: ${ultrawhitergba(0.8)};
  margin-bottom: 4px;
`;

const Feature = styled.div`
  font-size: 24px;
  font-weight: 600;
  display: flex;
  align-items: flex-end;

  span {
    font-weight: normal;
    margin-left: 8px;
  }
`;

const Content = styled.div`
  min-height: 450px;
  z-index: 0;
`;
const IpcStyle = styled.div`
  display: flex;
  > div {
    min-width: 320px;
  }
`;
const SearchBar = styled.div<{ isHeaderCollapse: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 4px;
  backdrop-filter: blur(8px);
  box-shadow: 2px 4px 8px 1px ${ultrablackrgba(0.1)};
  background-color: ${white};
  margin-bottom: -25px;
  input {
    padding: 20px 40px 20px 16px;
  }
  > button {
    flex-shrink: 0;
    min-height: 64px;
    margin-left: auto;
  }
  > div:not(${IpcStyle}) {
    width: calc(100% / 6);
  }
`;

const InputContainer = styled.div`
  position: relative;
  flex: 1;
  input {
    width: 100%;
    border-radius: 0 4px 4px 0;
    border: none;
    background-color: ${whitergba(0.3)};
    color: ${iceblue1};

    ::placeholder {
      color: ${iceblue6};
    }

    :focus {
      outline: 0;
    }
  }
`;

const Subheaders = styled.div`
  padding: 32px 32px 8px 32px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  background-color: ${blue7};
  position: relative;
  z-index: 1;
`;

const SearchCostBox = styled.div`
  display: flex;
  font-size: 13px;
  color: ${blue2rgba(0.6)};
  align-items: center;
`;

const HeaderRight = styled.div`
  width: 23.5%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 24px;
`;

interface IProps {
  root?: string;
  sector: string;
  feature?: string;
  subtitle?: string;
  children: JSX.Element;
  isLoading: boolean;
  totalCount: number;
  costTime: number;
  selectedItem: ISelectedItem;
  setSelectedItem: React.Dispatch<React.SetStateAction<ISelectedItem>>;
  checkedIndustrialClass: Array<number>;
  setCheckedIndustrialClass: React.Dispatch<React.SetStateAction<Array<number>>>;
  keyword: string;
  setKeyword: React.Dispatch<React.SetStateAction<string>>;
  searchMode: IDropdownItem;
  setSearchMode: React.Dispatch<React.SetStateAction<IDropdownItem>>;
  subSearchMode: IDropdownItem;
  setSubSearchMode: React.Dispatch<React.SetStateAction<IDropdownItem>>;
  industrialClassList: Array<IIndustrialClassType>;
  handleSubmit: (
    e: React.FormEvent<HTMLFormElement> | React.MouseEvent<SVGElement, MouseEvent>,
  ) => void;
  setReqParams: React.Dispatch<React.SetStateAction<IExpertRequest>>;
}

/** 專家履歷 - 頁面基本框架 */
const ExpertResultContent: React.FC<IProps> = ({
  root,
  sector,
  feature,
  subtitle,
  children,
  isLoading,
  totalCount,
  costTime,
  selectedItem,
  setSelectedItem,
  checkedIndustrialClass,
  setCheckedIndustrialClass,
  keyword,
  setKeyword,
  searchMode,
  setSearchMode,
  subSearchMode,
  setSubSearchMode,
  industrialClassList,
  handleSubmit,
  setReqParams,
}) => {
  const sortTypeItems: IDropdownProps['items'] = [
    { name: '相關度', value: 'SCORE' },
    { name: '專利數', value: 'COUNT' },
  ];

  const handleKeywordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
  };

  const numberTypeItems: IDropdownProps['items'] = [
    { name: '專家履歷', value: 'EXPERT' },
    { name: '相關專利', value: 'PATENT' },
  ];
  const searchTypeItems: IDropdownProps['items'] = [
    { name: '關鍵字', value: 'KEYWORD' },
    { name: '行業別', value: 'INDUSTRIAL_CLASS' },
  ];
  const [section, setSection] = useState<IIndustrialClassType>(industrialClassList[0]);
  const [division, setDivision] = useState<IIndustrialClassType | null>(null);

  useEffect(() => {
    setDivision(null);
  }, [section]);

  useEffect(() => {
    const selectedId =
      division === null || (division && division.id === 0) ? section && section.id : division.id;
    setCheckedIndustrialClass([selectedId]);
  }, [section, division, setCheckedIndustrialClass]);

  return (
    <Container>
      <Header>
        <HeaderText>
          <HeaderRoute>
            {root || '首頁'}&nbsp;/&nbsp;{sector}
            {feature && ` / ${feature}`}
          </HeaderRoute>
          <Feature>
            {feature || sector}
            <span>{subtitle}</span>
          </Feature>
        </HeaderText>
        <form onSubmit={handleSubmit}>
          <SearchBar isHeaderCollapse={false}>
            <ExpertDropdown
              data={searchTypeItems}
              dataTitle={'檢索方式'}
              selectedItem={searchMode}
              setSelectedItem={setSearchMode}
              withRightBorder
            />
            {searchMode && searchMode.value === 'KEYWORD' && (
              <>
                <ExpertDropdown
                  data={numberTypeItems}
                  dataTitle={'檢索項目'}
                  selectedItem={subSearchMode}
                  setSelectedItem={setSubSearchMode}
                  withRightBorder
                />
                <InputContainer>
                  <input
                    type="text"
                    value={keyword}
                    onChange={handleKeywordChange}
                    placeholder="請輸入關鍵字"
                  />
                </InputContainer>
              </>
            )}
            {searchMode && searchMode.value === 'INDUSTRIAL_CLASS' && (
              <IpcStyle>
                <IndustrialClassArrowDropdown
                  data={industrialClassList}
                  dataTitle="大類"
                  selectedItem={section}
                  setSelectedItem={setSection}
                  withLeftRadius
                  withRightArrow
                />
                <IndustrialClassArrowDropdown
                  data={section && section.children ? section.children : []}
                  dataTitle="中類"
                  selectedItem={division}
                  setSelectedItem={setDivision}
                  withSelectAll
                />
              </IpcStyle>
            )}
            <Button
              type="submit"
              template="primary-dark"
              margin="0"
              radius="0 4px 4px 0"
              onClick={() => handleSubmit}
            >
              查詢專家
            </Button>
          </SearchBar>
        </form>
      </Header>
      <Subheaders>
        <SearchCostBox>{!isLoading && `共 ${numFormatter(totalCount)} 件搜尋結果`}</SearchCostBox>
        <HeaderRight>
          <FilterDropdown
            items={dateRangeItems}
            activeItem={selectedItem.dateRange}
            handleOnclick={item => {
              setReqParams(prev => ({
                ...prev,
                dateRange: item.value,
                pageNo: 1,
              }));
              setSelectedItem(prev => ({
                ...prev,
                dateRange: item,
              }));
            }}
            customStyleSvgSize={16}
          />
          <FilterDropdown
            items={sortTypeItems}
            activeItem={selectedItem.sortBasis}
            handleOnclick={item => {
              setReqParams(prev => ({
                ...prev,
                sortBasis: item.value,
                pageNo: 1,
              }));
              setSelectedItem(prev => ({
                ...prev,
                sortBasis: item,
              }));
            }}
            customStyleSvg={<FaSortAmountDown size={16} />}
          />
        </HeaderRight>
      </Subheaders>
      <Content>{children}</Content>
    </Container>
  );
};

export default ExpertResultContent;
