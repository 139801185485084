import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { ReduxAppState } from 'src/redux/reducers';

import { Api } from 'src/api/helpers/apiBase';
import { Spinner } from 'src/components/ui/interactive/Spinner';
import { ClusterAccordionsTitle, ClusterMapContainer, Container } from 'src/style/AccordionStyle';
import { ICluster, IClusterMap, IClusterResp } from 'src/types/PatentSearchTypes';
import { numFormatter } from 'src/utils/Formatter';
import ClusterAccordion from './accordion/ClusterAccordion';
import NoResult from './accordion/common/NoResult';
import ClusterTree from './chart/ClusterTree';

interface IReduxMappingProps {
  country?: string;
  queryString: string;
}
// 顯示在主題知識地圖中央圓圈內的預設文字
const MAP_CENTER_TEXT = '檢索條件';

/** 聚類分析 */
const Cluster: React.FC<IReduxMappingProps> = props => {
  const [clusterResp, setClusterResp] = useState<IClusterResp | null>(null);
  const [apiFailure, setApiFailure] = useState(false);

  const totalCount = useSelector(
    (state: ReduxAppState) => state.patentResultReducer.paginatedData.totalCount,
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await Api().get(
          `patent/search/cluster?country=${props.country}&query=${
            props.queryString
          }&totalCount=${totalCount}`,
        );

        setClusterResp(res.data);
      } catch (error) {
        setApiFailure(true);
      }
    };

    props.queryString.trim() !== '' && fetchData();
  }, [props.country, props.queryString, totalCount]);

  if (props.queryString.trim() === '') {
    return <NoResult message="本次檢索結果未進行聚類分析，請先輸入關鍵字。" />;
  }

  if (clusterResp && totalCount > clusterResp.dataMax) {
    return (
      <NoResult
        message={`本次檢索結果超過 ${numFormatter(clusterResp.dataMax)} 筆
          ，超過聚類上限，請嘗試變更檢索條件。`}
      />
    );
  }

  if (!clusterResp && props.queryString.trim() !== '') {
    return apiFailure ? (
      <NoResult message="聚類分析服務發生錯誤，請稍後再嘗試重新查詢。" />
    ) : (
      <Container>
        <Spinner margin="58px auto" />
      </Container>
    );
  }

  if (clusterResp && clusterResp.clusters && clusterResp.clusters.length !== 0) {
    return (
      <>
        <ClusterMapContainer>
          <ClusterTree mapData={getMapData(clusterResp.clusters)} toolTipText={props.queryString} />
        </ClusterMapContainer>
        <Container>
          <ClusterAccordionsTitle>主題知識地圖架構</ClusterAccordionsTitle>
        </Container>
        {clusterResp.clusters.map((item, idx) => {
          return <ClusterAccordion key={item.keyword} content={item} colorIndex={idx} />;
        })}
      </>
    );
  } else {
    return (
      <NoResult message="本次檢索結果主題性不足或是查無資料，請嘗試變更產業類別或修改關鍵字。" />
    );
  }
};

const getMapData = (clusterData: ICluster[]): IClusterMap => {
  let clusters: IClusterMap[] = [];
  clusterData.forEach(kw1 => {
    // 第1層關鍵字
    let cluster: IClusterMap = { text: kw1.keyword, parentText: MAP_CENTER_TEXT, level: 1 };
    if (kw1.subClusters) {
      let subClusters: IClusterMap[] = [];
      kw1.subClusters.forEach(kw2 => {
        // 第2層關鍵字
        let subCluster: IClusterMap = { text: kw2.keyword, parentText: kw1.keyword, level: 2 };
        if (kw2.subKeywords) {
          subCluster.children = kw2.subKeywords.map(kw3 => {
            // 第3層關鍵字
            return { text: kw3, parentText: kw2.keyword, level: 3 };
          });
        }
        subClusters.push(subCluster);
      });
      cluster.children = subClusters;
    }
    clusters.push(cluster);
  });
  return { text: MAP_CENTER_TEXT, children: clusters, level: 0 };
};

const mapStateToProps = (state: ReduxAppState) => {
  const advanceKeyword =
    state.patentSearchReducer.request.advanceKeywords &&
    state.patentSearchReducer.request.advanceKeywords.find(item => item.keyCode === 'PO');
  return {
    country: advanceKeyword && advanceKeyword.keyword,
    queryString: state.patentSearchReducer.request.queryString,
  };
};

export default connect(
  mapStateToProps,
  null,
)(Cluster);
