import styled, { css } from 'styled-components';
import {
  blue1,
  blue2,
  blue3,
  blue5,
  blue6,
  blue7,
  blue8,
  iceblue2,
  iceblue3,
  iceblue4,
  alarm4,
  alarm5,
  alarm7,
} from 'src/style/theme/Color';

// 按鈕樣式：放在深色背景中的按紐default樣式要用style='light'
interface ButtonProps {
  template?: string;
  width?: number;
  height?: number;
  fontSize?: number;
  margin?: string;
  radius?: string;
}
export const Button = styled.button<ButtonProps>`
  margin: ${props => props.margin || '0 4px'};
  padding: 8px 16px;
  ${props => props.width && 'width: ' + props.width + 'px'};
  ${props => props.fontSize && 'font-size: ' + props.fontSize + 'px'};
  min-height: ${props => (props.height ? props.height + 'px' : '31px')};
  line-height: 1;
  border: none;
  border-radius: ${props => props.radius || '4px'};
  ${props =>
    (props.template === 'light' && buttonLight) ||
    (props.template === 'primary' && buttonPrimary) ||
    (props.template === 'alarm' && buttonAlarm) ||
    (props.template === 'primary-light' && buttonPrimaryLight) ||
    (props.template === 'primary-dark' && buttonPrimaryDark) ||
    buttonDefault}

  :disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
  :focus {
    outline: 0;
  }
`;

const buttonDefault = css`
  background-color: transparent;
  color: ${iceblue3};
  :hover:enabled {
    color: ${iceblue4};
  }
  :active:enabled {
    color: ${iceblue2};
  }
`;

const buttonLight = css`
  background-color: transparent;
  color: #ffffff;
  :hover:enabled {
    background-color: rgba(255, 255, 255, 0.2);
  }
  :active:enabled {
    background-color: rgba(255, 255, 255, 0.3);
  }
`;

const buttonPrimary = css`
  background-color: ${blue3};
  color: ${blue8};
  :hover:enabled {
    background-color: ${blue2};
  }
  :active:enabled {
    background-color: ${blue1};
  }
`;

const buttonAlarm = css`
  background-color: ${alarm5};
  color: #ffffff;
  :hover:enabled {
    background-color: ${alarm7};
  }
  :active:enabled {
    background-color: ${alarm4};
  }
`;

const buttonPrimaryLight = css`
  background-color: ${blue7};
  color: ${iceblue3};
  :hover:enabled {
    background-color: ${blue6};
  }
  :active:enabled {
    background-color: ${blue5};
  }
`;

const buttonPrimaryDark = css`
  background-color: ${blue2};
  color: ${blue8};
  :hover:enabled {
    background-color: ${blue1};
  }
  :active:enabled {
    background-color: ${blue1};
  }
`;
