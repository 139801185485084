import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { IFacetFieldCount } from 'src/types/PatentSearchTypes';
import { blue1, iceblue1, iceblue5, iceblue8 } from 'src/style/theme/Color';
import { numFormatter } from 'src/utils/Formatter';
import { toEllipsis } from './chartHelper';
import { svgId } from 'src/utils/svgDownloadUtils';

const width = 750;
const height = 350;
const padding = { top: 50, right: 50, bottom: 120, left: 100 };
const xAxisWidth = width - padding.left - padding.right;
const yAxisWidth = height - padding.top - padding.bottom;

interface IProps {
  fieldName: string;
  dataset: Array<IFacetFieldCount>;
}

/** 長條圖 (直式) */
const ColumnChart: React.FC<IProps> = ({ fieldName, dataset }) => {
  const svgRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    const rectWidth = (width / dataset.length) * 0.5; // 長條圖的寬度

    const xScale = d3
      .scaleBand()
      // domain若有兩個重複值會導致繪圖重疊，故使用idx。後續再利用tickFormat在axis顯示對應idx的文字。
      .domain(dataset.map((item, idx) => `${idx}`))
      .rangeRound([0, width - padding.left - padding.right]);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(dataset, d => d.count as any)])
      .range([height - padding.top - padding.bottom, 0])
      .nice(5);

    const xAxis = d3
      .axisBottom(xScale)
      .tickFormat((d, i) => toEllipsis(dataset[i].name, 5, 0))
      .tickSize(5);

    const yAxis = d3
      .axisLeft(yScale)
      .ticks(5)
      .tickFormat(d3.format(','))
      .tickSize(-xAxisWidth);

    const svg = d3.select(svgRef.current);

    // X軸
    const xAxisG = svg
      .append('g')
      .attr('transform', 'translate(' + padding.left + ',' + (padding.top + yAxisWidth) + ')')
      .style('color', iceblue1)
      .call(xAxis);

    // 逆時針旋轉文字並調整與X軸之距離
    xAxisG
      .selectAll('text')
      .style('text-anchor', 'end')
      .style('font-size', '13px')
      .attr('dx', '-1em')
      .attr('transform', `rotate(-65)`);
    // 刪除X軸-軸線 (橫線以Y軸 ticks 顯示)
    xAxisG.selectAll('path').remove();

    // Y軸
    const yAxisG = svg
      .append('g')
      .attr('transform', 'translate(' + padding.left + ',' + padding.top + ')')
      .style('color', iceblue1)
      .call(yAxis);

    // 調整文字與Y軸之距離
    yAxisG.selectAll('text').attr('x', '-0.8em');
    // 刪除Y軸-軸線
    yAxisG.selectAll('path').remove();
    // Y軸 ticks (作為橫格線) 之樣式設定
    yAxisG
      .selectAll('line')
      .attr('fill', 'none')
      .attr('stroke', `${iceblue8}`);

    // X軸 - 座標軸標題
    svg
      .append('text')
      .attr('transform', 'translate(' + width / 2 + ' ,' + (height - 2) + ')')
      .attr('fill', `${iceblue5}`)
      .style('text-anchor', 'middle')
      .text(fieldName);

    // Y軸 - 座標軸標題
    svg
      .append('text')
      .attr('transform', 'rotate(-90)')
      .attr('y', 0)
      .attr('x', 0 - height / 2)
      .attr('dy', '1em')
      .attr('fill', `${iceblue5}`)
      .style('text-anchor', 'middle')
      .text('專利數量');

    // 長條圖
    svg
      .selectAll('.rect')
      .data(dataset)
      .enter()
      .append('path')
      .attr('fill', `${blue1}`)
      .attr('d', (d, i) =>
        topRoundedRect(
          padding.left + (xScale(`${i}`) || 0) + xScale.bandwidth() / 2 - rectWidth / 2,
          padding.top + yScale(d.count),
          rectWidth,
          yAxisWidth - yScale(d.count),
          4,
        ),
      );

    // 長條過於擁擠時避免數值互相重疊，故不顯示
    if (dataset.length <= 15) {
      // 數值
      svg
        .selectAll('.rect-text')
        .data(dataset)
        .enter()
        .append('text')
        .attr('fill', `${blue1}`)
        .attr('text-anchor', 'middle')
        .style('font-size', '13px') // 使用attr會被全域(*)的CSS設定蓋掉
        .attr(
          'x',
          (d, i) => padding.left + (xScale(`${i}`) || 0) + xScale.bandwidth() / 2 - rectWidth / 2,
        )
        .attr('y', d => padding.top + yScale(d.count))
        .attr('dx', rectWidth / 2)
        .attr('dy', '-0.6em')
        .text(d => numFormatter(d.count));
    }
  }, [fieldName, dataset]);

  return <svg id={svgId} ref={svgRef} width={width} height={height} />;
};

export default ColumnChart;

function topRoundedRect(x: number, y: number, width: number, height: number, radius: number) {
  return (
    'M' +
    (x + radius) +
    ',' +
    y +
    'h' +
    (width - 2 * radius) +
    'a' +
    radius +
    ',' +
    radius +
    ' 0 0 1 ' +
    radius +
    ',' +
    radius +
    'v' +
    (height - 2 * radius) +
    'v' +
    radius +
    'h' +
    -radius +
    'h' +
    (2 * radius - width) +
    'h' +
    -radius +
    'v' +
    -radius +
    'v' +
    (2 * radius - height) +
    'a' +
    radius +
    ',' +
    radius +
    ' 0 0 1 ' +
    radius +
    ',' +
    -radius +
    'z'
  );
}
