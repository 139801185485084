import React from 'react';
import styled from 'styled-components';
import configuration from 'src/assets/images/manual/search-result-analysis/configuration.png';

const ImgContainer = styled.div<{ height: string; imageUrl: string }>`
  position: relative;
  height: ${props => props.height}px;
  padding: 10px 0;
  background-image: url(${props => props.imageUrl});
  background-size: contain;
  background-repeat: no-repeat;
`;
export default function SearchResultAnalysisManual() {
  return (
    <>
      <p>以下為搜尋結果分析功能配置</p>
      <ol>
        <li>篩選、聚類</li>
        <li>資料範圍</li>
        <li>顯示模式</li>
        <li>時間範圍及排序</li>
        <li>專利追蹤</li>
        <li>統計分析、功效矩陣、專利匯出</li>
      </ol>
      <br />
      <ImgContainer height="350" imageUrl={configuration} />
    </>
  );
}
