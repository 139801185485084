import styled from 'styled-components';
import { Field } from 'formik';

// Layout
export const ContentStyle = styled.div`
  width: 67%;
  margin: auto;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
`;

export const ContentHeaderStyle = styled.div`
  background-image: linear-gradient(95deg, #2ec3c2, #0cafcc);
  border-radius: 4px 4px 0 0;
  padding: 16px 32px 16px 32px;
`;

export const ContentHeaderTitleStyle = styled.div`
  display: flex;
  align-items: center;
  color: #ffffff;
  font-size: 24px;
  font-weight: 600;
  height: 32px;
`;
export const ContentBodyStyle = styled.div``;

//Header
export const ContentHeaderSubtitleStyle = styled.span`
  font-size: 15px;
  margin-left: 8px;
  font-weight: normal;
`;

//Form
export const ItemContainer = styled.div`
  padding: 0 0 16px 0;
  width: 80%;
  display: flex;
  align-items: center;
  label {
    width: 30%;
    display: block;
    color: #333333;
  }
`;
export const ItemInput = styled.div<{ disabled?: boolean }>`
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  input,
  textarea,
  select {
    padding: 0 8px;
    width: 100%;
    display: block;
    background-color: ${props => (props.disabled ? '#d0d4d6' : null)};
  }
  textarea {
    height: 72px;
  }
`;

export const FieldStyle = styled(Field)<{ width?: string }>`
  width: ${props => (props.width ? props.width : '60%')};
  height: 36px;
  border-radius: 4px;
  border: solid 1px #979ea1;
  background-color: #ffffff;
  color: #323846;
`;

//Table
export const TrStyle = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4px 32px 4px 32px;
  line-height: 52px;
  border-bottom: 1px solid #eceff1;
  &:hover {
    background-color: #f5fdfe;
  }
`;

export const ThStyle = styled(TrStyle)`
  background-color: #edf6f7;
  line-height: 38px;
  border: none;
  > * {
    color: #979ea1;
  }
  &:hover {
    background-color: #edf6f7;
  }
`;

export const TdStyle = styled.div<{ width?: string }>`
  width: ${props => (props.width ? props.width : '')};
  display: flex;
`;

export const TablePatentTitleStyle = styled.p`
  font-size: 18px;
  font-weight: 500;
  color: #077790;
  line-height: normal;
  margin: 0 0 8px 0;
`;
export const TablePatentInfoStyle = styled.div`
  color: #666666;
  line-height: 1.5em;
  margin: 20px 0 20px 0;
  width: calc(100% - 180px);
`;
export const TablePatentImageStyle = styled.div<{ bgImg?: string }>`
  width: 180px;
  height: 138px;
  margin: 20px;
  background-image: ${props => props.bgImg};
  background-size: contain;
`;
export const ValidStatusStyle = styled.span<{ valid: boolean }>`
  color: ${props => (props.valid ? '#12ad46' : '#eb5000')};
`;

//Buttons
export const ButtonAreaStyle = styled.div`
  width: 70%;
  padding: 28px 0 28px 28%;
`;
