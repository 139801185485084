import TagManager from 'react-gtm-module';

/** 欄位空白時預設值 */
const EMPTY_DEFAULT: string = '(not set)';

/** GA 站內搜尋類別 */
export enum GaSearchCategory {
  PATENT = '專利',
  EXPERT = '專家',
  NEWS = '新聞',
}

/** 將 GA 站內搜尋事件推到 GTM dataLayer */
export const pushGaSiteSearchEvent = (gaCategory: GaSearchCategory, gaQueryString: string) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'siteSearch',
      searchCategory: gaCategory,
      searchKeyword: gaQueryString.length > 0 ? gaQueryString : EMPTY_DEFAULT,
    },
    dataLayerName: 'PageDataLayer',
  };
  TagManager.dataLayer(tagManagerArgs);
};
