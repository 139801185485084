import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import styled from 'styled-components';
import { IBubbleChartDataType } from 'src/types/IndustryTypes';
import { blue2, blue6rgba, blue7, iceblue1, ultrawhite } from 'src/style/theme/Color';
import Tooltip from 'src/components/trend/industrialTrends/chart/Tooltip';
import { numFormatter } from 'src/utils/Formatter';

const ToolTipOverlay = styled.div`
  color: ${ultrawhite};
`;

interface IProps {
  dataset: Array<IBubbleChartDataType>;
  activeItem: string;
  onClick: (code: string) => void;
  width: number;
  height: number;
  // 需顯示的 Bubble 數量
  size?: number;
}

interface ITooltip extends IBubbleChartDataType {
  left: number;
  top: number;
}

/** 折線圖 */
const BubbleChart: React.FC<IProps> = ({ dataset, activeItem, onClick, width, height, size }) => {
  const [tooltip, setTooltip] = useState<ITooltip>();
  const svgRef = useRef<SVGSVGElement>(null);
  const forceStrength = 0.05;
  const centre = { x: width * 0.5, y: height * 0.5 };
  // 若使用者沒有自訂 size 則顯示全部資料
  const sliceData = size === undefined ? dataset : dataset.slice(0, size);

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    svg.selectAll('g').remove();
    // 設定泡泡尺寸
    const maxCount = d3.max(sliceData, d => +d.count) || 0;
    const maxBubbleRadius = width * 0.35 * (maxCount / d3.sum(sliceData, d => +d.count));

    const radiusScale = d3
      .scaleSqrt()
      .domain([0, maxCount])
      .range([30, maxBubbleRadius < 40 ? 40 : maxBubbleRadius]);
    const nodes = sliceData.map(d => ({
      ...d,
      radius: radiusScale(+d.count),
      size: +d.count,
      x: maxBubbleRadius + Math.random() * (width - maxBubbleRadius * 3),
      y: maxBubbleRadius + Math.random() * (height - maxBubbleRadius * 3),
    }));

    // 設定力模型
    const charge = (d: any) => {
      return Math.pow(d.radius, 2.0) * 0.01; // 半徑較大者離中心較近
    };
    const simulation = d3
      .forceSimulation()
      .force('charge', d3.forceManyBody().strength(charge))
      .force(
        'x',
        d3
          .forceX()
          .strength(forceStrength)
          .x(centre.x),
      )
      .force(
        'y',
        d3
          .forceY()
          .strength(forceStrength * 1.8)
          .y(centre.y),
      )
      .force('collision', d3.forceCollide().radius((d: any) => d.radius + 1));
    simulation.stop();

    // 設定Tooltip
    const getToolTip = (d: any) => {
      const pos = svg.node();
      return {
        left: pos
          ? pos.getBoundingClientRect().left + d.x + d.radius + 8
          : d3.event.pageX - window.scrollX,
        top: pos ? pos.getBoundingClientRect().top + d.y : d3.event.pageY - window.scrollY - 32,
        count: d.count,
        code: d.code,
        description: d.description,
      };
    };

    // 繪製
    const node = svg
      .selectAll('.bubble')
      .data(nodes, (d: any) => d.code)
      .enter()
      .append('g')
      .style('cursor', 'pointer')
      .on('mouseover', (d, i) => {
        setTooltip(getToolTip(d));
      })
      .on('mousemove', d => {
        setTooltip(getToolTip(d));
      })
      .on('mouseout', (d, i) => {
        setTooltip(undefined);
      })
      .on('click', (d, i, nodes) => {
        onClick(d.code);
        d3.selectAll('circle').attr('fill', blue7);
        d3.select(nodes[i])
          .select('circle')
          .attr('fill', blue6rgba(0.6));
      });

    const bubbles = node
      .append('circle')
      .attr('r', d => d.radius)
      .attr('fill', d => (activeItem === d.code ? blue6rgba(0.6) : blue7));

    const labels = node.append('text');
    labels
      .append('tspan')
      .attr('dx', '-1.2em')
      .attr('dy', '-0.2em')
      .style('font-size', d => d.radius * 0.4)
      .attr('fill', iceblue1)
      .text(d => d.code);
    labels
      .append('tspan')
      .attr('dx', d => -d.radius * 0.9)
      .attr('dy', d => (d.radius > 40 ? '1.5em' : '1em'))
      .style('font-size', '15px')
      .style('font-weight', 'bold')
      .attr('fill', blue2)
      .text(d => numFormatter(d.count));

    simulation
      .nodes(nodes)
      .on('tick', () => {
        bubbles.attr('cx', d => d.x).attr('cy', d => d.y);
        labels.attr('x', d => d.x).attr('y', d => d.y);
      })
      .restart();
    // TODO: Resolve missing dependencies warning.
    // eslint-disable-next-line
  }, [dataset, onClick]);

  return (
    <>
      <svg ref={svgRef} width={width} height={height} />

      {tooltip && (
        <Tooltip
          left={tooltip.left}
          top={tooltip.top}
          placement={'right'}
          overlay={
            <ToolTipOverlay>
              <div>{tooltip.code + ' - 共' + numFormatter(tooltip.count) + ' 件'}</div>
              <div>{tooltip.description}</div>
            </ToolTipOverlay>
          }
        />
      )}
    </>
  );
};

export default BubbleChart;
