import React from 'react';
import styled from 'styled-components';

import Logo from 'src/components/ui/Logo';
import { pink1, pink8 } from 'src/style/theme/Color';

const Header = styled.div`
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;
const Content = styled.div`
  display: flex;
  justify-content: center;
`;
const MsgContainer = styled.div`
  padding: 24px 36px 8px;
  border-radius: 4px;
  background-color: ${pink8};
`;
const Msg = styled.div`
  padding: 0 0 16px;
  color: ${pink1};
  font-weight: 600;
  text-align: center;
`;

interface IProps {
  textList: Array<string>;
}

export const StatusPage = ({ textList }: IProps) => (
  <>
    <Header>
      <Logo />
    </Header>
    <Content>
      <MsgContainer>
        {textList.map((text, idx) => (
          <Msg key={idx}>{text}</Msg>
        ))}
      </MsgContainer>
    </Content>
  </>
);
