import React from 'react';

interface IProps {
  href: string | undefined;
  name?: string;
  target?: string;
  children?: JSX.Element;
}

export const LinkOut: React.FC<IProps> = props => (
  <a href={props.href} target={props.target ? props.target : '_blank'} rel="noopener noreferrer">
    {props.name ? props.name : props.children}
  </a>
);
