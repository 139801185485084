import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { AxiosResponse } from 'axios';
import { Formik, Form, FormikActions } from 'formik';
import { FaCheck, FaTimes, FaPlus } from 'react-icons/fa';
import _ from 'lodash';

import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import { alertMessage } from 'src/utils/ModalUtils';
import { toDropdownItem } from 'src/utils/IndustryUtils';
import { IGetMember } from 'src/types/api/MemberApiTypes';
import { IMemberForm } from 'src/types/form/MemberFormTypes';
import { IIndustrialClassType } from 'src/types/IndustryTypes';
import { initData } from 'src/types/defaultData/MemberFormInit';

import { getGuestJwt, refreshJwt } from 'src/apps/auth/LoginUtils';
import {
  accountValidator,
  pwdValidator,
  nameValidator,
  phoneValidator,
  mailValidator,
  industrialClassValidator,
} from 'src/apps/validation/formValidator';

import Content from 'src/components/ui/content/Content';
import IndustrialClassModal from 'src/components/ui/interactive/modal/custom/IndustrialClassMenuModal';
import * as Color from 'src/style/theme/Color';
import { Button } from 'src/style/theme/Common';
import { ItemContainer, ItemInput, FieldStyle } from 'src/style/MemberStyle';

import { connect } from 'react-redux';
import { ReduxAppState } from 'src/redux/reducers';
import { ITokenPayload } from 'src/apps/auth/Auth';
import { openCustomModal, closeCustomModal } from 'src/redux/actions/modalAction';
import { CustomModalType } from 'src/types/ModalTypes';

const BtnContainer = styled(ItemContainer)`
  padding: 16px 0 36px;
`;
const Message = styled.div`
  color: ${Color.red1};
`;
const NotifyMsg = styled.div<{ focus?: boolean; fontSize?: number }>`
  width: 70%;
  color: ${props => (props.focus ? Color.pink3 : Color.iceblue2)};
  font-size: ${props => (props.fontSize ? `${props.fontSize}px` : null)};
  line-height: ${props => (props.fontSize ? `15px` : null)};
`;
const NotifyGrid = styled.div`
  display: table;
  color: ${Color.iceblue2};
`;
const GridRow = styled.div`
  display: table-row;
`;
const GridCell = styled.div<{ confirmed: boolean }>`
  display: table-cell;
  font-size: 12px;
  svg {
    margin: 0 4px 0 16px;
    color: ${props => (props.confirmed ? Color.green1 : Color.iceblue8)};
    font-size: 12px;
  }
`;

const IndustrialClassTag = styled.div`
  border-radius: 4px;
  padding: 4px 16px;
  margin: 4px 8px 4px 0;
  background-color: ${Color.blue8};
  color: ${Color.iceblue3};
  user-select: none;
  display: flex;
  align-items: center;
  svg {
    cursor: pointer;
    width: 15px;
    margin-left: 6px;
  }
`;
const IndustrialClassEdit = styled.div`
  color: ${Color.blue2};
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  min-height: 30px;
  svg {
    width: 15px;
    margin-right: 6px;
  }
`;

interface IReduxMappingProps {
  member: ITokenPayload;
  isModalOpen: boolean;
  openCustomModal: (customModalType: CustomModalType) => void;
  closeCustomModal: () => void;
}
interface IProps extends IReduxMappingProps {
  isSubMember?: boolean;
  subMemberId?: number;
  handlePageSwitch?: () => void;
  toolbar: JSX.Element;
}

const Edit: React.FC<IProps> = ({
  member,
  isSubMember,
  subMemberId,
  handlePageSwitch,
  toolbar,
  isModalOpen,
  openCustomModal,
  closeCustomModal,
}) => {
  const [memberData, setMemberData] = useState<IMemberForm>();
  const isNewSub = !subMemberId;

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isSubMember) {
          if (isNewSub) {
            setMemberData({
              account: '',
              password: '',
              name: '',
              phone: '',
              email: '',
              industrialClass: toDropdownItem(
                member.info.industrialClassIds.map(id => id.toString()),
              ),
              company: member.info.companyName,
              apiToken: '',
            });
          } else {
            const subMemberRes = await Api().get('member/sub/' + subMemberId);
            const subMemberData = subMemberRes.data as IGetMember;
            setMemberData({
              account: subMemberData.loginId,
              // 密碼預設為空, 後端接收到空值會視為沒有修改
              password: '',
              name: subMemberData.accountProfile.name,
              phone: subMemberData.accountProfile.contactPhoneNumber,
              email: subMemberData.accountProfile.email,
              industrialClass: subMemberData.accountProfile.industrialClass,
              company: subMemberData.accountProfile.companyName || '',
              apiToken: subMemberData.apiToken || '',
            });
          }
        } else {
          const memberRes = await Api().get('member');
          const memberData = memberRes.data as IGetMember;
          if (memberData) {
            setMemberData({
              account: memberData.loginId,
              // 密碼預設為空, 後端接收到空值會視為沒有修改
              password: '',
              name: memberData.accountProfile.name,
              phone: memberData.accountProfile.contactPhoneNumber,
              email: memberData.accountProfile.email,
              industrialClass: memberData.accountProfile.industrialClass,
              company: memberData.accountProfile.companyName || '',
              apiToken: memberData.apiToken || '',
            });
          } else {
            setMemberData(initData);
          }
        }
      } catch (error) {
        errorHandler(error);
      }
    };

    fetchData();
  }, [isSubMember, subMemberId, isNewSub, member.info.industrialClassIds, member.info.companyName]);

  const [focusMail, setFocusMail] = useState(false);

  const handleSubmit = (values: IMemberForm, actions: FormikActions<IMemberForm>) => {
    actions.setSubmitting(false);

    const switchApi = async (): Promise<AxiosResponse<any>> => {
      const apiInstance = Api({ 'Content-Type': 'application/json' });
      if (isSubMember) {
        if (isNewSub) {
          return apiInstance.post('member', mapPostData(values));
        } else {
          return apiInstance.patch('member/sub', mapPatchSubMemberData(values));
        }
      } else {
        return apiInstance.patch('member', mapPatchData(values), {});
      }
    };

    const submit = _.throttle(
      async () => {
        try {
          const res = await switchApi();
          if (res.status === 200) {
            if (isSubMember) {
              handlePageSwitch && handlePageSwitch();
              alertMessage(`已${isNewSub ? '新增' : '更新'}子會員 ${values.account}`);
            } else {
              if (values.password === '') {
                await refreshJwt({ force: true });
                // TODO: 強制更新ProfileDropdown, 失敗, 無法獲得最新資料的Token
                alertMessage('已更新，設定值將於下次登入後生效');
              } else {
                // TODO: 需要頁面跳轉或延後執行部分程式的作法需再研議
                getGuestJwt();
                alertMessage(
                  '已更新，請用新密碼登入。',
                  '',
                  () =>
                    (window.location.href = `${window.location.protocol}//${
                      window.location.hostname
                    }${window.location.port && ':' + window.location.port}/login`),
                );
              }
            }
          }
        } catch (error) {
          // TODO: 評估是否由errorHandler處理
          const errMsg = isSubMember && isNewSub ? '新增' : '更新';
          alertMessage(`${errMsg}失敗`);
        } finally {
          actions.setSubmitting(true);
        }
      },
      2000,
      { leading: true },
    );

    submit();
  };

  /** 會員更新資料 */
  const mapPatchData = (value: IMemberForm) => {
    return {
      id: member.info.id,
      accountProfileId: member.info.accountProfileId,
      industrialClassIds: value.industrialClass.map(item => item.id),
      password: value.password,
      name: value.name,
      phoneNumber: value.phone,
      email: value.email,
    };
  };

  /** 子會員新增資料 */
  const mapPostData = (value: IMemberForm) => {
    return {
      loginId: value.account,
      industrialClassIds: value.industrialClass.map(item => item.id),
      password: value.password,
      name: value.name,
      phoneNumber: value.phone,
      email: value.email,
    };
  };

  /** 子會員更新資料 */
  const mapPatchSubMemberData = (value: IMemberForm) => {
    return {
      id: subMemberId,
      industrialClassIds: value.industrialClass.map(item => item.id),
      password: value.password,
      name: value.name,
      phoneNumber: value.phone,
      email: value.email,
    };
  };

  // pwdRuleChecker
  const ruleAlphabet = (value: string) => /(?=.*[a-z])/.test(value);
  const ruleCapital = (value: string) => /(?=.*[A-Z])/.test(value);
  const ruleNumber = (value: string) => /(?=.*\d)/.test(value);
  const ruleLength = (value: string) => /^.{8,}$/.test(value);

  const isIpkmAccount = member.info.ipkmAccount;

  return (
    <Content
      sector={isSubMember ? '個人專區 / 管理會員帳號' : '個人專區'}
      feature={`${isSubMember ? `${isNewSub ? '新增' : '編輯'}子會員` : `編輯會員資料`}`}
      subtitle={
        isIpkmAccount
          ? isSubMember && isNewSub
            ? '全為必填項目'
            : undefined
          : 'e 網通會員除行業別偏好外，其餘資料請至 e 網通進行編輯'
      }
    >
      <>
        {memberData && (
          <Formik
            initialValues={memberData}
            onSubmit={(values, actions) => handleSubmit(values, actions)}
            render={props => (
              <Form>
                <ItemContainer>
                  <label>帳號</label>
                  <ItemInput disabled={!(isSubMember && isNewSub && isIpkmAccount)}>
                    <FieldStyle
                      type="text"
                      name="account"
                      disabled={!(isSubMember && isNewSub)}
                      validate={isSubMember && isNewSub && accountValidator}
                    />
                  </ItemInput>
                </ItemContainer>
                {props.errors.account && props.touched.account && (
                  <ItemContainer>
                    <label />
                    <Message>{props.errors.account}</Message>
                  </ItemContainer>
                )}
                <ItemContainer>
                  <label />
                  <NotifyMsg fontSize={12}>
                    請輸入6-32位的字母、數字、英文句號「.」或底線「_」組合
                  </NotifyMsg>
                </ItemContainer>
                <ItemContainer>
                  <label>密碼</label>
                  <ItemInput disabled={!isIpkmAccount}>
                    <FieldStyle
                      type="password"
                      name="password"
                      placeholder={isSubMember && isNewSub ? '' : '若未修改，則沿用舊密碼。'}
                      disabled={!isIpkmAccount}
                      validate={() =>
                        pwdValidator(props.values.password, !(isSubMember && isNewSub))
                      }
                    />
                  </ItemInput>
                </ItemContainer>
                {props.errors.password && props.touched.password && (
                  <ItemContainer>
                    <label />
                    <Message>{props.errors.password}</Message>
                  </ItemContainer>
                )}
                <ItemContainer>
                  <label />
                  <NotifyGrid>
                    <GridRow>
                      <GridCell confirmed={true}>密碼需含有</GridCell>
                      <GridCell confirmed={ruleLength(props.values.password)}>
                        <FaCheck />
                        至少八個字元
                      </GridCell>
                      <GridCell confirmed={ruleAlphabet(props.values.password)}>
                        <FaCheck />
                        一個英文小寫
                      </GridCell>
                    </GridRow>
                    <GridRow>
                      <GridCell confirmed={true} />
                      <GridCell confirmed={ruleCapital(props.values.password)}>
                        <FaCheck />
                        一個英文大寫
                      </GridCell>
                      <GridCell confirmed={ruleNumber(props.values.password)}>
                        <FaCheck />
                        一個數字
                      </GridCell>
                    </GridRow>
                  </NotifyGrid>
                </ItemContainer>
                <ItemContainer>
                  <label>姓名/聯絡人</label>
                  <ItemInput disabled={!isIpkmAccount}>
                    <FieldStyle
                      type="text"
                      name="name"
                      disabled={!isIpkmAccount}
                      validate={nameValidator}
                    />
                  </ItemInput>
                </ItemContainer>
                {props.errors.name && props.touched.name && (
                  <ItemContainer>
                    <label />
                    <Message>{props.errors.name}</Message>
                  </ItemContainer>
                )}
                <ItemContainer>
                  <label />
                  <NotifyMsg fontSize={12}>供分享時顯示資訊使用。</NotifyMsg>
                </ItemContainer>
                <ItemContainer>
                  <label>連絡電話/行動電話</label>
                  <ItemInput
                    // e網通會員若個人電話為空,則開放修改
                    disabled={
                      !isIpkmAccount &&
                      !(memberData.phone === null || memberData.phone.length === 0)
                    }
                  >
                    <FieldStyle
                      type="text"
                      name="phone"
                      disabled={
                        !isIpkmAccount &&
                        !(memberData.phone === null || memberData.phone.length === 0)
                      }
                      validate={phoneValidator}
                    />
                  </ItemInput>
                </ItemContainer>
                {props.errors.phone && props.touched.phone && (
                  <ItemContainer>
                    <label />
                    <Message>{props.errors.phone}</Message>
                  </ItemContainer>
                )}
                <ItemContainer>
                  <label />
                  <NotifyMsg fontSize={12}>請輸入數字及「-」「#」</NotifyMsg>
                </ItemContainer>
                <ItemContainer>
                  <label>Email</label>
                  <ItemInput disabled={!isIpkmAccount}>
                    <FieldStyle
                      type="email"
                      name="email"
                      onFocus={() => setFocusMail(true)}
                      disabled={!isIpkmAccount}
                      validate={mailValidator}
                    />
                  </ItemInput>
                </ItemContainer>
                {props.errors.email && props.touched.email && (
                  <ItemContainer>
                    <label />
                    <Message>{props.errors.email}</Message>
                  </ItemContainer>
                )}
                {!(isSubMember && isNewSub) && (
                  <ItemContainer>
                    <label />
                    <NotifyMsg focus={focusMail}>
                      更改Email將發送會員啟用確認信，點擊信件中的確認連結
                      完成會員啟用，才可使用產業專利知識平台完整功能
                    </NotifyMsg>
                  </ItemContainer>
                )}
                <ItemContainer>
                  <label>行業別偏好</label>
                  <ItemInput>
                    <FieldStyle
                      type="hidden"
                      name="industrialClass"
                      validate={industrialClassValidator}
                    />
                    {props.values.industrialClass.map(item => (
                      <IndustrialClassTag key={item.id}>
                        {item.description}
                        <FaTimes
                          onClick={() => {
                            props.setValues({
                              ...props.values,
                              industrialClass: [
                                ...props.values.industrialClass.filter(e => e.id !== item.id),
                              ],
                            });
                          }}
                        />
                      </IndustrialClassTag>
                    ))}
                    <IndustrialClassEdit
                      onClick={() => openCustomModal(CustomModalType.INDUSTRIAL_CLASS_MANU)}
                    >
                      <FaPlus />
                      新增行業別
                    </IndustrialClassEdit>
                    {isModalOpen && (
                      <IndustrialClassModal
                        checkedItems={props.values.industrialClass}
                        onSubmit={(industrialClass: IIndustrialClassType[]) => {
                          props.setValues({ ...props.values, industrialClass: industrialClass });
                        }}
                        onClose={() => closeCustomModal()}
                      />
                    )}
                  </ItemInput>
                </ItemContainer>
                {props.errors.industrialClass && (
                  <ItemContainer>
                    <label />
                    <Message>{props.errors.industrialClass}</Message>
                  </ItemContainer>
                )}
                {props.values.industrialClass.length === 0 && !props.errors.industrialClass && (
                  <ItemContainer>
                    <label />
                    <Message>請選擇至少一項行業別</Message>
                  </ItemContainer>
                )}
                <ItemContainer>
                  <label>公司名稱</label>
                  <ItemInput disabled={true}>
                    <FieldStyle type="text" name="company" disabled />
                  </ItemInput>
                </ItemContainer>
                <ItemContainer>
                  <label>API Key</label>
                  <ItemInput disabled={true}>
                    <FieldStyle type="text" name="apiToken" disabled />
                  </ItemInput>
                </ItemContainer>
                <BtnContainer>
                  <label />
                  <ItemInput>
                    <Button type="submit" template="primary">
                      儲存
                    </Button>
                    <Button type="reset">重設</Button>
                  </ItemInput>
                </BtnContainer>
              </Form>
            )}
          />
        )}
        {isSubMember && toolbar}
      </>
    </Content>
  );
};

const mapStateToProps = (state: ReduxAppState) => {
  return {
    member: state.memberReducer.memberPayload,
    isModalOpen: state.modalReducer.customModalType === CustomModalType.INDUSTRIAL_CLASS_MANU,
  };
};

const mapDispatchToProps = {
  openCustomModal,
  closeCustomModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Edit);
