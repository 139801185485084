import React from 'react';
import styled from 'styled-components';

import LogoPng from 'src/assets/images/ipkm-logo-beta.png';

const Logo = styled.figcaption`
  padding: 6px 0;
  height: 100%;
  position: relative;
  img {
    width: 96px;
    height: 48px;
  }
`;

const logo: React.FC = () => (
  <Logo>
    <img src={LogoPng} alt="Logo" />
  </Logo>
);

export default logo;
