import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';
import { Backdrop } from 'src/style/ModalStyle';
import { numFormatter } from 'src/utils/Formatter';
import {
  blue5,
  blue6,
  iceblue1,
  iceblue5,
  iceblue7,
  iceblue8,
  ultrawhite,
  ultrawhitergba,
} from 'src/style/theme/Color';
import { zModal } from 'src/style/theme/Z-Index';
import { IBubbleChartDataType, IIndustrialTrendApiParamType } from 'src/types/IndustryTypes';
import { getTrendsTitleKindText } from 'src/components/trend/TrendsHelper';

const Container = styled.div`
  position: relative;
  border-radius: 4px;
  background-color: #ffffff;
  width: 60%;
  max-width: 800px;
`;
const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 32px 32px 16px 32px;
  svg {
    font-size: 20px;
    margin-left: 24px;
    cursor: pointer;
  }
`;
const SubHeader = styled.div`
  width: 100%;
  padding: 0px 32px 8px 32px;
  display: flex;
`;
const Title = styled.div`
  color: ${iceblue1};
  font-size: 24px;
  font-weight: bold;
`;
const Body = styled.div`
  height: calc(100vh - 200px);
  overflow: auto;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    box-shadow: inset 0 0 8px 8px ${blue6};
    border: solid 2px Transparent;
  }
`;
const Table = styled.table`
  width: calc(100% - 64px);
  margin: 0 32px 32px 32px;
  border-collapse: collapse;
`;
const Tr = styled.tr`
  border-bottom: 1px solid ${iceblue8};
`;
const TitleTr = styled(Tr)`
  color: ${iceblue5};
  border-color: ${blue5};
`;
const DivisionLine = styled(Tr)`
  border-color: ${iceblue7};
`;
const Td = styled.td`
  padding: 6px 30px 6px 60px;
  font-size: 16px;
  min-width: 120px;
`;
const CodeTd = styled(Td)`
  padding: 6px 0 6px 36px;
  min-width: 120px;
  text-align: center;
`;
const DescriptionTd = styled(Td)`
  padding: 6px 0 6px 36px;
  min-width: 120px;
`;
const FadeoutMask = styled.div`
  position: absolute;
  bottom: 0;
  height: 30px;
  width: 100%;
  border-radius: 0 0 4px 4px;
  background-image: linear-gradient(to bottom, ${ultrawhitergba(0)}, ${ultrawhite});
`;

interface IProps {
  onClose: () => void;
  data: Array<IBubbleChartDataType>;
  statTimeRange: string;
  country: string;
  title: string;
  apiParams: IIndustrialTrendApiParamType;
}

const modal = document.getElementById('modal-custom');
const portalDiv = modal ? modal : document.createElement('div');

const IndustrialClassIpcRangeModal: React.FC<IProps> = ({
  onClose,
  data,
  statTimeRange,
  country,
  title,
  apiParams,
}) => {
  const divisionLine = (
    <DivisionLine>
      <td />
      <td />
      <td />
      <td />
      <td />
    </DivisionLine>
  );

  return ReactDOM.createPortal(
    <Backdrop zIndex={zModal.custom} alignItems="center">
      <Container>
        <Header>
          <Title>
            {title} 之 IPC 代碼近五年 {getTrendsTitleKindText(apiParams.kind)}
          </Title>
          <FaTimes color={iceblue5} onClick={() => onClose()} />
        </Header>
        <SubHeader>國別 : {country}</SubHeader>
        <SubHeader>統計時間 : {statTimeRange}</SubHeader>
        <Body>
          <>
            <Table>
              <thead>
                <TitleTr>
                  <CodeTd>IPC 代號</CodeTd>
                  <CodeTd>件數</CodeTd>
                  <CodeTd>IPC 描述</CodeTd>
                </TitleTr>
              </thead>
              <tbody>
                {data &&
                  data.map(
                    item =>
                      item && (
                        <Tr key={item.code}>
                          <CodeTd>{item.code}</CodeTd>
                          <CodeTd>{numFormatter(item.count)}</CodeTd>
                          <DescriptionTd>{item.description}</DescriptionTd>
                        </Tr>
                      ),
                  )}
                {divisionLine}
              </tbody>
            </Table>
            <FadeoutMask />
          </>
        </Body>
      </Container>
    </Backdrop>,
    portalDiv,
  );
};

export default IndustrialClassIpcRangeModal;
