import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';

import { Feature, FeatureUnselectable, FeatureLv2, FaStyle } from 'src/style/SideMenuStyle';
import { handleRoute } from 'src/routes/routeUtils';
import SubNestedNavItem from './SubNestedNavItem';

interface IProps extends RouteComponentProps {
  feat: {
    feature: string;
    path: string;
    subfeatures: boolean;
  };
  subfeat: Array<{
    id: number;
    name: string;
    linkable?: boolean;
    disable?: boolean;
    nestedSubfeatures?: boolean;
    nestedSubfeat?: Array<{
      id: number;
      name: string;
      disable?: boolean;
    }>;
  }>;
}

function NestedNavItem(props: IProps) {
  const [folded, setFolded] = useState(true);
  const [featureActive, setFeatureActive] = useState(false);
  const featurePath = `${props.match.url}${props.feat.path}`;
  const featurePathRegex = new RegExp('^' + featurePath);
  useEffect(() => {
    if (props.location.pathname.match(featurePathRegex)) {
      setFeatureActive(true);
      setFolded(false);
    } else if (!props.location.pathname.match(featurePathRegex) && featureActive) {
      setFeatureActive(false);
      setFolded(true);
    }
  }, [featurePathRegex, featureActive, props.location.pathname, props.subfeat]);

  return (
    <>
      {props.subfeat.length > 0 ? (
        <FeatureUnselectable
          active={featureActive}
          onClick={() => {
            !featureActive && setFolded(prev => !prev);
          }}
        >
          {props.feat.feature}
          <FaStyle>
            {props.subfeat.length > 0 && (folded ? <FaChevronDown /> : <FaChevronUp />)}
          </FaStyle>
        </FeatureUnselectable>
      ) : (
        <Link to={featurePath} onClick={e => e.preventDefault()}>
          <Feature
            active={featureActive}
            onClick={() => handleRoute(featurePath, props.location, props.history)}
          >
            {props.feat.feature}
          </Feature>
        </Link>
      )}
      {props.subfeat.length > 0 &&
        !folded &&
        props.subfeat.map(item => {
          const itemPath = `${props.match.url}${props.feat.path}/${item.id}`;
          const itemPathRegex = new RegExp('^' + itemPath + '$|^' + itemPath + '/');

          return item.nestedSubfeatures ? (
            <SubNestedNavItem
              key={item.id}
              disable={item.disable ? true : false}
              active={props.location.pathname.match(itemPathRegex) ? true : false}
              subfeature={item.name}
              path={props.feat.path + '/' + item.id.toString()}
              nestedSubfeatures={item.nestedSubfeat ? item.nestedSubfeat.length > 0 : false}
              nestedSubfeat={item.nestedSubfeat ? item.nestedSubfeat : []}
              linkable={item.linkable ? true : false}
              {...props}
            />
          ) : item.disable ? (
            <FeatureLv2 key={item.id} active={false} disable={true}>
              {item.name}
            </FeatureLv2>
          ) : (
            <Link key={item.id} to={itemPath} onClick={e => e.preventDefault()}>
              <FeatureLv2
                key={item.id}
                active={props.location.pathname.match(itemPathRegex) ? true : false}
                onClick={() => handleRoute(itemPath, props.location, props.history)}
              >
                {item.name}
              </FeatureLv2>
            </Link>
          );
        })}
    </>
  );
}

export default NestedNavItem;
