import React, { useState } from 'react';
import withShortcut from 'src/components/search/withShortcut/withShortcut';
import { Formik, Form, FormikProps, FormikActions } from 'formik';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import Content from 'src/components/ui/content/Content';
import { IAdvancedSearch } from 'src/types/form/SearchFormTypes';
import { Row } from 'src/style/PatentSearchStyle';
import { Button } from 'src/style/theme/Common';
import { CONDITION_AND, countries } from 'src/constants/PatentSearchConstants';
import { getRangeQuery, getFieldPrefix, appendCondition, isNotEmpty } from 'src/utils/TextUtils';
import { connect } from 'react-redux';
import { composeBasicRequest } from 'src/redux/actions/patentSearchAction';
import { IBasicRequest } from 'src/types/PatentSearchTypes';
import { alertMessage } from 'src/utils/ModalUtils';
import { SystemMessage } from 'src/apps/applicationMessages';
import QueryCombination from 'src/components/search/partialForm/QueryCombination';
import DateRange from 'src/components/search/partialForm/DateRange';
import ClassificationCode from 'src/components/search/partialForm/ClassificationCode';
import CountryTabs from 'src/components/search/CountryTabs';

interface IReduxProps {
  composeBasicRequest: (basicRequest: IBasicRequest) => void;
  ipcCodeList?: string[];
  handleIpcCodeList?: (code?: string[]) => void;
}

interface IProps extends IReduxProps, RouteComponentProps {}

/** 進階檢索 */
const AdvancedSearch: React.FC<IProps> = props => {
  const handleSubmit = (values: IAdvancedSearch, actions: FormikActions<IAdvancedSearch>) => {
    actions.setSubmitting(false);

    // 判斷文字欄位有無填寫
    const excludeFields = [
      'dateType',
      'dateStart',
      'dateEnd',
      'ipcCondition',
      'cpcCondition',
      'locCondition',
    ];
    const filteredTextFields = Object.keys(values).filter(
      key => !excludeFields.includes(key) && isNotEmpty(values[key]),
    );

    // 日期區間是否填寫完整
    const dateStart = values.dateStart;
    const dateEnd = values.dateEnd;
    const isDateRangeValid = dateStart && dateEnd;

    // 串接request檢索字串
    if (filteredTextFields.length === 0 && !isDateRangeValid) {
      alertMessage(SystemMessage.SEARCH_CONDITION_INCOMPLETE);
    } else {
      let submitQuery = '';

      // 日期範圍
      if (dateStart && dateEnd) {
        submitQuery +=
          appendCondition(submitQuery.length, CONDITION_AND) +
          getRangeQuery(values.dateType, dateStart, dateEnd);
      }
      // IPC (將整段IPC檢所式用大括號框住，便於複數IPC查詢功能)
      if (isNotEmpty(values.ipcText && values.ipcText.trim())) {
        submitQuery +=
          appendCondition(submitQuery.length, values.ipcCondition) +
          '(' +
          getFieldPrefix('IC') +
          values.ipcText.trim() +
          ')';
      }
      // textarea 檢索式
      if (isNotEmpty(values.queryString)) {
        submitQuery +=
          appendCondition(submitQuery.length, CONDITION_AND) + values.queryString.trim();
      }

      // 送出request並跳到搜尋結果頁
      props.composeBasicRequest({
        queryString: submitQuery,
        advanceKeywords: [{ keyCode: 'PO', keyword: activeTab }],
      });
      props.history.push('/result');
    }
  };

  const formInitialValues = {
    dateType: 'ID',
    dateStart: null,
    dateEnd: null,
    ipcCondition: CONDITION_AND,
    ipcText: '',
    cpcCondition: CONDITION_AND,
    cpcText: '',
    locCondition: CONDITION_AND,
    locText: '',
    queryString: '',
  };

  const handleIpcText = (ipcText: string) => {
    ipcText = ipcText.replace('OR ', 'OR IC=');
    ipcText = ipcText.replace('AND ', 'AND IC=');
    ipcText = ipcText.replace('NOT ', 'NOT IC=');
    ipcText = ipcText.replace(/,/g, ' OR IC=');
    return ipcText;
  };

  const [activeTab, setActiveTab] = useState(countries[0]);

  return (
    <Content sector="專利檢索" feature="進階檢索">
      <>
        <CountryTabs activeTab={activeTab} click={tab => setActiveTab(tab)} />
        <Formik
          initialValues={formInitialValues}
          onSubmit={(values, actions) => {
            if (props.ipcCodeList && isNotEmpty(props.ipcCodeList.toString())) {
              values.ipcText = props.ipcCodeList.toString().trim();
            }
            values.ipcText = handleIpcText(values.ipcText);
            handleSubmit(values, actions);
          }}
          render={(formikBag: FormikProps<IAdvancedSearch>) => (
            <Form>
              <DateRange formikBag={formikBag} />
              <ClassificationCode
                formikBag={formikBag}
                ipcCodeList={props.ipcCodeList}
                handleIpcCodeList={props.handleIpcCodeList}
              />
              <QueryCombination formikBag={formikBag} />
              <Row justifyContent="flex-start">
                <Button type="submit" template="primary">
                  查詢
                </Button>
                <Button type="reset">清除</Button>
              </Row>
            </Form>
          )}
        />
      </>
    </Content>
  );
};

const mapDispatchToProps = {
  composeBasicRequest,
};

export default withShortcut(
  withRouter(
    connect(
      null,
      mapDispatchToProps,
    )(AdvancedSearch),
  ),
);
