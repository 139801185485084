import React from 'react';
import styled from 'styled-components';
import MatrixBubbleChart from './MatrixBubbleChart';
import { numFormatter } from 'src/utils/Formatter';
import {
  ultrawhite,
  blue1,
  blue2,
  iceblue1,
  iceblue2,
  iceblue4,
  iceblue5,
  iceblue6,
  iceblue8,
} from 'src/style/theme/Color';
import { connect } from 'react-redux';
import { closeCustomModal } from 'src/redux/actions/modalAction';
import { toggleTechFuncQuery } from 'src/redux/actions/patentSearchAction';
import { changeActivePane } from 'src/redux/result/toolbar/action';
import { IFieldValue } from 'src/redux/result/toolbar/types';
import { IFilterTag } from 'src/types/PatentSearchTypes';

const Container = styled.div`
  padding: 0 24px;
`;

const ChartBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
`;

const Body = styled.div`
  @media print {
    display: none;
  }
`;

const Table = styled.div`
  display: table;
  border-collapse: collapse;
  border-radius: 4px;
  overflow: hidden;
  margin: 32px auto;

  background-color: ${iceblue8};
  color: ${iceblue1};
`;

const Tr = styled.div`
  display: table-row;

  &:nth-child(2) {
    background-color: ${iceblue6};
  }
`;

const Tcell = styled.div`
  display: table-cell;
  width: 132.5px;
  word-break: break-all;
  vertical-align: middle;
  text-align: center;
  padding: 10px;
  border: 1px solid ${iceblue8};
`;

const Th = styled(Tcell)`
  background-color: ${iceblue4};
  color: ${ultrawhite};

  &:nth-child(1) {
    background-color: ${ultrawhite};
    border: none;
  }

  &:nth-child(2) {
    border-top-left-radius: 8px;
    border-top: 1px solid ${ultrawhite};
    border-left: 1px solid ${ultrawhite};
  }
`;

const Td = styled(Tcell)`
  &:nth-child(1) {
    background-color: ${iceblue4};
    color: ${ultrawhite};
  }

  &:nth-child(2) {
    background-color: ${iceblue6};
  }

  ${Tr}:nth-child(2) > &:nth-child(1) {
    border-top-left-radius: 8px;
    border-top: 1px solid ${ultrawhite};
    border-left: 1px solid ${ultrawhite};
  }

  ${Tr}:nth-child(2) > &:nth-child(2) {
    background-color: ${iceblue2};
    color: ${ultrawhite};
  }
`;

const Footnote = styled.div`
  color: ${iceblue5};
  @media print {
    display: none;
  }
`;

const TableCaption = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 4px;
`;

const QueryString = styled.div`
  color: ${blue1};
  margin-bottom: 8px;
  span {
    margin-right: 4px;
  }
`;

const SearchLink = styled.div`
  color: ${blue2};
  text-decoration: underline;
  :hover {
    cursor: pointer;
  }
`;

interface IReduxProps {
  closeCustomModal: () => void;
  toggleTechFuncQuery: (techQuery?: string, funcQuery?: string, filterTag?: IFilterTag) => void;
  changeActivePane: (pane: string) => void;
}

interface IProps extends IReduxProps {
  queryString: string;
  technologyList: Array<IFieldValue>;
  functionList: Array<IFieldValue>;
  dataset: Array<Array<number>>;
}

const Chart: React.FC<IProps> = ({
  queryString,
  technologyList,
  functionList,
  dataset,
  closeCustomModal,
  toggleTechFuncQuery,
  changeActivePane,
}) => {
  const handleCountLinkClick = (
    techQuery: string,
    funcQuery: string,
    techName: string,
    funcName: string,
    count: number,
  ) => {
    closeCustomModal();
    changeActivePane('');
    toggleTechFuncQuery(techQuery, funcQuery, {
      techName: techName,
      funcName: funcName,
      count: count,
    });
  };

  return (
    <Container>
      <ChartBox>
        <MatrixBubbleChart
          xAxisLabels={technologyList.map(item => item.name)}
          yAxisLabels={functionList.map(item => item.name)}
          dataset={dataset}
        />
      </ChartBox>
      <Body>
        <TableCaption>矩陣結果</TableCaption>
        <QueryString>
          <span>分析母體：</span>
          {queryString}
        </QueryString>
        <Table>
          <Tr>
            <Th />
            <Th>技術</Th>
            {technologyList.map((item, techIdx) => (
              <Th key={techIdx}>{item.name}</Th>
            ))}
          </Tr>
          <Tr>
            <Td>功效</Td>
            <Td>檢索條件</Td>
            {technologyList.map((item, techIdx) => (
              <Td key={techIdx}>{item.keyword}</Td>
            ))}
          </Tr>
          {functionList.map((funcItem, funcIdx) => (
            <Tr key={funcIdx}>
              <Td>{funcItem.name}</Td>
              <Td>{funcItem.keyword}</Td>
              {technologyList.map((techItem, techIdx) => {
                const count = dataset[funcIdx][techIdx];
                return (
                  <Td key={techIdx}>
                    {count > 0 ? (
                      <SearchLink
                        onClick={() =>
                          handleCountLinkClick(
                            techItem.keyword,
                            funcItem.keyword,
                            techItem.name,
                            funcItem.name,
                            count,
                          )
                        }
                      >
                        {numFormatter(count)}
                      </SearchLink>
                    ) : (
                      <span>0</span>
                    )}
                  </Td>
                );
              })}
            </Tr>
          ))}
        </Table>
      </Body>
      <Footnote>
        <div>本系統書目資料為公告時原始資料，後續異動更新請至「權利異動」頁面瀏覽。</div>
        <div>本系統資料僅供參考，不作為懷薄之依據，所有資料以經濟部智慧財產局公告為準。</div>
      </Footnote>
    </Container>
  );
};

const mapDispatchToProps = {
  closeCustomModal,
  toggleTechFuncQuery,
  changeActivePane,
};

export default connect(
  null,
  mapDispatchToProps,
)(Chart);
