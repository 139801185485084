import React from 'react';
import styled from 'styled-components';
import { Formik, Field } from 'formik';

import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import { alertMessage } from 'src/utils/ModalUtils';

import { mailValidator, describeValidator } from 'src/apps/validation/formValidator';

import Content from 'src/components/ui/content/Content';
import { Button } from 'src/style/theme/Common';

import { connect } from 'react-redux';
import { ReduxAppState } from 'src/redux/reducers';
import { ITokenPayload } from 'src/apps/auth/Auth';

const Container = styled.div`
  color: #333333;
`;
const Anchor = styled.div`
  display: flex;
`;
const Label = styled.div`
  margin: 0 0 0 -8px;
  padding: 20px 0 0 0;
  width: 120px;
  display: inline-flex;
`;
const RequireDot = styled.div`
  margin: 4px 3px 0 0;
  width: 5px;
  height: 5px;
  display: inline-flex;
  border-radius: 3px;
  background-color: #e7154d;
`;
const FormElement = styled.div`
  padding: 12px 0;
  width: calc(100% - 120px);
  display: inline-flex;
  select,
  textarea,
  input {
    width: 100%;
    border: 1px solid #979ea1;
    border-radius: 4px;
    font-size: 15px;
  }
  select,
  input {
    height: 40px;
  }
  textarea {
    resize: none;
  }
`;
const BtnAnchor = styled.div`
  margin: auto;
  padding: 20px 0 0 0;
`;
const Message = styled.div`
  margin: auto;
  color: #ff0000;
`;

interface IReduxMappingProps {
  member: ITokenPayload;
}

const contactUs = ({ member }: IReduxMappingProps) => (
  <Content sector="常見問題" feature="聯絡我們">
    <Container>
      <div>
        如果您發現有任何資料錯誤，或有其他建議，請依下列欄位提供詳實資訊，我們將於收信後儘快為您回覆與處理。
      </div>

      <Formik
        initialValues={{
          type: 'Q',
          content: '',
          email: member.info.guest ? '' : member.info.email,
        }}
        onSubmit={async (values, actions) => {
          try {
            await Api().post('qa', values);
            alertMessage('信件已寄出');
          } catch (error) {
            errorHandler(error);
          }
        }}
        render={props => (
          <form onSubmit={props.handleSubmit} onReset={props.handleReset}>
            <Anchor>
              <Label>
                <RequireDot />
                類別
              </Label>
              <FormElement>
                <Field name="type" component="select">
                  <option value="Q">問題反應</option>
                  <option value="S">系統建議</option>
                  <option value="E">資料錯誤通報</option>
                </Field>
              </FormElement>
            </Anchor>
            <Anchor>
              <Label>
                <RequireDot />
                問題描述
              </Label>
              <FormElement>
                <Field name="content" component="textarea" rows={8} validate={describeValidator} />
              </FormElement>
            </Anchor>
            <Anchor>
              <Label>
                <RequireDot />
                收信&nbsp;Email
              </Label>
              <FormElement>
                <Field name="email" type="email" validate={mailValidator} />
              </FormElement>
            </Anchor>
            {(props.errors.email && props.touched.email && (
              <Anchor>
                <Message>{props.errors.email}</Message>
              </Anchor>
            )) ||
              (props.errors.content && props.touched.content && (
                <Anchor>
                  <Message>{props.errors.content}</Message>
                </Anchor>
              ))}
            <Anchor>
              <BtnAnchor>
                <Button type="submit" template="primary">
                  送出
                </Button>
                <Button type="reset">取消</Button>
              </BtnAnchor>
            </Anchor>
          </form>
        )}
      />
    </Container>
  </Content>
);

const mapStateToProps = (state: ReduxAppState) => {
  return {
    member: state.memberReducer.memberPayload,
  };
};

export default connect(
  mapStateToProps,
  null,
)(contactUs);
