import React from 'react';
import styled from 'styled-components';
import application_inquiry from 'src/assets/images/manual/patent-assisted-read/application_inquiry.png';
import family_literature_similarity from 'src/assets/images/manual/patent-assisted-read/family_literature_similarity.png';
import petition_litigation from 'src/assets/images/manual/patent-assisted-read/petition_litigation.png';

const ImgContainer = styled.div<{ height: string; imageUrl: string }>`
  position: relative;
  height: ${props => props.height}px;
  padding: 10px 0;
  background-image: url(${props => props.imageUrl});
  background-size: contain;
  background-repeat: no-repeat;
`;
export default function PatentAssistedReadManual() {
  return (
    <>
      <ol>
        <li>申請查詢：連結至「專利公開資訊網站」查詢該專利案件歷程記錄</li>
        <br />
        <ImgContainer height="400" imageUrl={application_inquiry} />
        <li>專利家族：開啟顯示專利家族相關資訊</li>
        <li>引用文獻：開啟顯示專利相關引用文獻</li>
        <li>相似專利：開啟顯示其他相似專利文件</li>
        <br />
        <ImgContainer height="350" imageUrl={family_literature_similarity} />
        <li>
          訴願訴訟資料：若專利案件有訴願訴訟紀錄，可點選連結至「專利公開資訊網站」該專利案件歷程記錄。
        </li>
        <li>美國PTAB資料：若美國專利有相關審理訴願資訊，可點選開啟顯示。</li>
        <br />
        <ImgContainer height="300" imageUrl={petition_litigation} />
      </ol>
    </>
  );
}
