import styled from 'styled-components';
import * as Color from 'src/style/theme/Color';

export const TutorialContainer = styled.div`
  padding: 16px 32px;
`;

export const TutorialTitle = styled.div`
  padding: 8px 0;
  color: ${Color.blue2};
  font-size: 18px;
  font-weight: bold;
`;

export const TutorialContent = styled.div`
  padding: 8px 0;
  color: ${Color.iceblue3};
`;

export const TutorialHighlight = styled.div`
  padding: 16px 26px;
  display: inline-block;
  border-radius: 4px;
  background-color: ${Color.blue7};
`;
