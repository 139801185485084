import React, { useEffect } from 'react';
import styled from 'styled-components';
import pagemap from 'src/components/patent/nav-map/patentPageMap.util.js';

const NavMap = styled.div`
  width: 100%;
  & > canvas {
    max-width: 100%;
    min-width: 100%;
    max-height: 60vh;
  }
`;

export const PatentTagMap = ({ colorPanel }: any) => {
  useEffect(() => {
    const canvas = document.querySelector('#tagMap');
    const formatColor = colorPanel.map((color: any) => {
      return color.replace('#', '');
    });
    if (canvas && canvas.parentNode) {
      //專利載入前後會重複加入listener，故先清除所有listener
      canvas.parentNode.replaceChild(canvas.cloneNode(true), canvas);
      pagemap(document.querySelector('#tagMap'), {
        viewport: null,
        styles: {
          'header,footer,section,article': 'rgba(0, 0, 0, 0.10)',
          div: 'rgba(0, 0, 0, 0.04)',
          'h1,a': 'rgba(0, 0, 0, 0.12)',
          'h2,h3,h4': 'rgba(0, 0, 0, 0.10)',
          [`.highlight-${formatColor[0]}`]: `${colorPanel[0]}`,
          [`.highlight-${formatColor[1]}`]: `${colorPanel[1]}`,
          [`.highlight-${formatColor[2]}`]: `${colorPanel[2]}`,
          [`.highlight-${formatColor[3]}`]: `${colorPanel[3]}`,
          [`.highlight-${formatColor[4]}`]: `${colorPanel[4]}`,
          [`.highlight-${formatColor[5]}`]: `${colorPanel[5]}`,
          [`.highlight-${formatColor[6]}`]: `${colorPanel[6]}`,
          [`.highlight-${formatColor[7]}`]: `${colorPanel[7]}`,
          [`.highlight-${formatColor[8]}`]: `${colorPanel[8]}`,
          [`.highlight-${formatColor[9]}`]: `${colorPanel[9]}`,
          '.highlight-default': '#5F686C',
          '#abstract, #description, #claim': '#077790',
        },
        back: 'rgba(0, 0, 0, 0.02)',
        view: 'rgba(12, 175, 204, 0.2)',
        drag: 'rgba(12, 175, 204, 0.4)',
        interval: null,
      });
    }
  }, [colorPanel]);

  return (
    <NavMap>
      <canvas id="tagMap" />
    </NavMap>
  );
};
