import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import { ErrorCode } from 'src/apps/error-handler/ErrorCode';
import {
  IGetRecommendPatents,
  IGetPatentIntroLatest,
  IGetPatentIntroSubscribed,
  IMainTabSrc,
} from 'src/types/api/IndexApiTypes';

import INSPECT_BG from 'src/assets/images/inspect-bg.png';
import Inspect from './Inspect';
import ListItems from './PatentItems';
import ExceptionPanel from './ExceptionPanel';
import { IDropdownProps } from 'src/components/common/Dropdown';
import { getMainTabGaClassName } from 'src/components/index/tabs/getGaClassName';
import { ultrawhite } from 'src/style/theme/Color';

import useLoginChecker from 'src/apps/auth/useLoginChecker';

// Layout
const Container = styled.div`
  display: flex;
`;
const LeftContainer = styled.div`
  width: 65%;
  display: flex;
`;
const RightLoadingStyle = styled.div`
  height: 348px;
  background-image: url(${INSPECT_BG});
  background-size: cover;
`;
const RightContainer = styled(RightLoadingStyle)`
  margin: -20px -8px -8px 0;
  padding: 30px 46px 40px 30px;
  width: 35%;
  height: 436px;
  border-radius: 4px;
  color: ${ultrawhite};
  box-shadow: rgba(38, 50, 55, 0.3) -4px 4px 20px 0px;
`;

const PATENT_HEADER = ['類別', '專利名稱', '公開/公告日'];

interface IProps {
  src: IMainTabSrc;
  userIndustrialClassIds: Array<number>;
  activeRecCountry?: IDropdownProps['activeItem'];
}

function PatentsPanel({ src, userIndustrialClassIds, activeRecCountry }: IProps) {
  const isLogin = useLoginChecker();
  const [stateUrl, setStateUrl] = useState('');
  const [hover, setHover] = useState(0);
  const [patents, setPatents] = useState<
    Array<IGetRecommendPatents | IGetPatentIntroLatest | IGetPatentIntroSubscribed>
  >([]);
  const [subscriptionId, setSubscriptionId] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [noData, setNoData] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        let res;
        switch (src.tab) {
          case '最新專利':
            setStateUrl(src.apiUrl);
            res = await Api().post(src.apiUrl, {
              industrialClassIds: userIndustrialClassIds,
            });
            break;
          case '推薦專利':
            activeRecCountry && setStateUrl(src.apiUrl + activeRecCountry.value);
            res = await Api().get(src.apiUrl + (activeRecCountry && activeRecCountry.value));
            break;
          case '專利情報':
            getSubscriptionId();
            setStateUrl(src.apiUrl);
            res = await Api().get(src.apiUrl);
            break;
          default:
            res = await Api().get(src.apiUrl);
            throw new Error(ErrorCode.UNHANDLED_CASE_IN_SWITCH);
        }
        if (res.data.length > 0) {
          setPatents(res.data);
          setNoData(false);
        } else {
          setNoData(true);
        }
        setError(false);
      } catch (error) {
        setError(true);
        errorHandler(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (isLoading) {
      fetchData();
    } else if (
      (activeRecCountry && src.apiUrl + activeRecCountry.value !== stateUrl) ||
      (!activeRecCountry && src.apiUrl !== stateUrl)
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [src, stateUrl, userIndustrialClassIds, activeRecCountry, isLoading]);

  const getSubscriptionId = async () => {
    const res = await Api().get('patent/subscription');
    if (res.data.length > 0) {
      setSubscriptionId(res.data[0].id);
    }
  };

  const switchContent = () => {
    const tab = src.tab;
    if (isLoading || error || noData || (tab === '專利情報' && !isLogin)) {
      return (
        <ExceptionPanel
          tab={tab}
          header={PATENT_HEADER}
          error={error}
          noData={noData}
          isLogin={isLogin}
          isLoading={isLoading}
        />
      );
    } else if (!isLoading && patents && patents.length > 0) {
      const gaClass = getMainTabGaClassName(tab);
      return (
        <>
          <LeftContainer>
            <ListItems
              header={PATENT_HEADER}
              patents={patents}
              hover={hover}
              setHover={setHover}
              loading={isLoading}
              gaClass={gaClass}
              readMoreUrl={src.readMoreUrl + (subscriptionId !== 0 ? subscriptionId : '')}
            />
          </LeftContainer>
          <RightContainer>
            <Inspect patent={patents[hover]} gaClass={gaClass} />
          </RightContainer>
        </>
      );
    }
  };

  return <Container>{switchContent()}</Container>;
}

export default PatentsPanel;
