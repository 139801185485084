import { AxiosError } from 'axios';
import { PatentDataTypes } from 'src/types/api/PatentApiTypes';
export const FETCH_PATENT_CONTENT_START = 'FETCH_PATENT_CONTENT_START';
export const FETCH_PATENT_CONTENT_SUCCESS = 'FETCH_PATENT_CONTENT_SUCCESS';
export const FETCH_PATENT_CONTENT_ERROR = 'FETCH_PATENT_CONTENT_ERROR';
export const CLEAR_PATENT_CONTENT = 'CLEAR_PATENT_CONTENT';

export interface PatentContentState {
  isLoading: boolean;
  patentData: PatentDataTypes;
}

interface FetchPatentContentStartAction {
  type: typeof FETCH_PATENT_CONTENT_START;
}

interface FetchPatentContentSuccessAction {
  type: typeof FETCH_PATENT_CONTENT_SUCCESS;
  patentData: PatentDataTypes;
}

interface FetchPatentContentErrorAction {
  type: typeof FETCH_PATENT_CONTENT_ERROR;
  error: AxiosError;
}

interface ClearPatentContentAction {
  type: typeof CLEAR_PATENT_CONTENT;
}

export type PatentContentActionTypes =
  | FetchPatentContentStartAction
  | FetchPatentContentSuccessAction
  | FetchPatentContentErrorAction
  | ClearPatentContentAction;
