import React, { useState } from 'react';
import styled from 'styled-components';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import { iceblue1 } from 'src/style/theme/Color';

import RelatedNewsItem from 'src/components/news/item/RelatedNewsItem';
import { IGetRelatedNews } from 'src/types/api/NewsApiTypes';

const Container = styled.div`
  margin-top: 12px;
  padding: 12px 8px;
`;

const Header = styled.span`
  cursor: pointer;
`;

const RelatedNewsContainer = styled.div`
  padding-left: 21px;
`;

const FaStyle = styled.i`
  margin: 0 8px 0 0;

  svg {
    font-size: 13px;
    color: ${iceblue1};
  }
`;

interface IProps {
  relatedNews: Array<IGetRelatedNews>;
  searchWords?: Array<string>;
}

const RelatedNews: React.FC<IProps> = props => {
  const { relatedNews, searchWords } = props;
  const [display, setDisplay] = useState<boolean>(true);

  return (
    <Container>
      <Header onClick={() => setDisplay(prev => !prev)}>
        <FaStyle>{display ? <FaChevronUp /> : <FaChevronDown />}</FaStyle>
        <span>相關新聞推薦</span>
      </Header>
      {display && (
        <RelatedNewsContainer>
          {relatedNews.map(news => (
            <RelatedNewsItem
              key={news.id}
              newsData={news}
              searchWords={searchWords ? searchWords : []}
            />
          ))}
        </RelatedNewsContainer>
      )}
    </Container>
  );
};

export default RelatedNews;
