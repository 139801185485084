import React, { useState } from 'react';
import { AxiosResponse } from 'axios';
import { Formik, Form, FormikProps } from 'formik';
import { FaDownload } from 'react-icons/fa';

import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import { dateFormatter } from 'src/utils/Formatter';

import { IDownloadSingleForm } from 'src/types/form/OpendataFormTypes';
import { LinkOut } from 'src/utils/LinkOut';
import {
  nonEmptySourceValidator,
  nonEmptyPubNumValidator,
} from 'src/apps/validation/formValidator';
import { numFormatter } from 'src/utils/Formatter';

import { Button } from 'src/style/theme/Common';
import { Spinner } from 'src/components/ui/interactive/Spinner';
import {
  ErrorMsg,
  FormContainer,
  FieldStyle,
  ItemContainer,
  ItemInput,
  DownloadSearchResultStyle,
  DownloadTrStyle,
  DownloadThStyle,
  DownloadTdStyle,
  DownloadButton,
  DownloadItemName,
} from 'src/style/OpendataStyle';

interface IResult {
  country: string;
  kindCode: string;
  publicationDate: string;
  publicationNumber: string;
  periodDate: string;
  periodNumber: string;
  size: number;
  link: string;
}
interface IProps {
  countryList: Array<{ name: string; value: string }>;
}

const formInitValue: IDownloadSingleForm = {
  country: '',
  pubNumber: '',
};

const SingleDownloadPane: React.FC<IProps> = ({ countryList }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState<Array<IResult>>([]);

  const handleSubmit = async (values: IDownloadSingleForm) => {
    try {
      setIsLoading(true);
      const res = (await Api().get(
        'patent/download?country=' + values.country + '&pubNumber=' + values.pubNumber.trim(),
      )) as AxiosResponse<Array<IResult>>;
      setResult(res.data);
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
      setIsSubmitted(true);
    }
  };

  return (
    <>
      <FormContainer>
        檔案類型：ZIP(內含JSON、TIF、JPG)
        <br />
        編碼格式：UTF-8
        <br />
        資料內容說明：
        <br />
        <ul>
          <li>
            公報資料JSON檔。例如：
            US20210199343A1.json。內容包含專利名稱、申請日、公開/公告日、公開/公告號、發明人資訊、申請人資訊、代理人資訊、發明摘要、圖式檔名等項目。
          </li>
          <li>圖式圖檔。例如：US20210199343A1-20210701-D00000.TIF。</li>
        </ul>
        <br />
        <Formik
          initialValues={formInitValue}
          onSubmit={values => handleSubmit(values)}
          render={(formikBag: FormikProps<IDownloadSingleForm>) => (
            <Form>
              <ItemContainer>
                <span>資料來源</span>
                <ItemInput>
                  <FieldStyle
                    name="country"
                    component="select"
                    width="70%"
                    validate={nonEmptySourceValidator}
                  >
                    <option value="">請選擇</option>
                    {countryList.map(item => (
                      <option value={item.value}>{item.name}</option>
                    ))}
                  </FieldStyle>
                </ItemInput>
              </ItemContainer>
              {formikBag.errors.country && formikBag.touched.country && (
                <ItemContainer>
                  <span />
                  <ItemInput>
                    <ErrorMsg>{formikBag.errors.country}</ErrorMsg>
                  </ItemInput>
                </ItemContainer>
              )}
              <ItemContainer>
                <span>案件公開/公告號</span>
                <ItemInput>
                  <FieldStyle
                    name="pubNumber"
                    component="input"
                    width="70%"
                    validate={nonEmptyPubNumValidator}
                  />
                </ItemInput>
              </ItemContainer>
              {formikBag.errors.pubNumber && formikBag.touched.pubNumber && (
                <ItemContainer>
                  <span />
                  <ItemInput>
                    <ErrorMsg>{formikBag.errors.pubNumber}</ErrorMsg>
                  </ItemInput>
                </ItemContainer>
              )}
              <ItemContainer>
                <span />
                <ItemInput>
                  <Button type="submit" template="primary">
                    查詢
                  </Button>
                  <Button
                    type="button"
                    onClick={async () => {
                      try {
                        const res = await Api().get('patent/download/sample');
                        window.open(res.data);
                      } catch (error) {
                        errorHandler(error);
                      }
                    }}
                  >
                    範例檔案下載
                  </Button>
                </ItemInput>
              </ItemContainer>
            </Form>
          )}
        />
      </FormContainer>
      <DownloadSearchResultStyle>
        <DownloadThStyle>
          <DownloadTdStyle width="10%" />
          <DownloadTdStyle width="50%">檔案</DownloadTdStyle>
          <DownloadTdStyle width="30%">更新時間</DownloadTdStyle>
          <DownloadTdStyle width="120px">下載</DownloadTdStyle>
        </DownloadThStyle>
        {result.length === 0 ? (
          <>
            {isSubmitted && !isLoading && <p>此搜尋條件範圍內尚無資料，您可嘗試修改查詢條件。</p>}
            {!isSubmitted && !isLoading && <p>尚無檔案，請利用上方的查詢功能</p>}
            {isLoading && <Spinner width="120px" margin="40px auto" />}
          </>
        ) : (
          result.map((item, idx) => (
            <>
              <DownloadTrStyle key={item.link}>
                <DownloadTdStyle width="10%">{idx + 1}</DownloadTdStyle>
                <DownloadTdStyle width="50%">
                  <DownloadItemName active={item.link !== null}>
                    {item.country}
                    {item.publicationNumber}
                    {item.kindCode}
                    {item.link && ' (' + numFormatter(item.size, true) + 'MB)'}
                  </DownloadItemName>
                </DownloadTdStyle>
                <DownloadTdStyle width="30%">{dateFormatter(item.periodDate)}</DownloadTdStyle>
                <DownloadTdStyle width="120px">
                  {item.link ? (
                    <LinkOut href={item.link}>
                      <DownloadButton>
                        <FaDownload size="15px" color="#0cafcc" />
                      </DownloadButton>
                    </LinkOut>
                  ) : (
                    <span>不提供下載</span>
                  )}
                </DownloadTdStyle>
              </DownloadTrStyle>
              {!item.link && (
                <p>
                  [單一案件下載]只能下載最近1年內的資料，您可改至[整期下載]下載歷史公報資料(1期1個ZIP檔)。
                </p>
              )}
            </>
          ))
        )}
      </DownloadSearchResultStyle>
    </>
  );
};

export default SingleDownloadPane;
