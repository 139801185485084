import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router';
import { DocdbService } from 'src/api/Docdb';
import { IGetDocdbData } from 'src/types/DocdbDataTypes';
import { dateFormatter } from 'src/utils/Formatter';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';

import {
  TextBox,
  TextBoxTitle,
  TextBoxContent,
  InfoArea,
  InfoAreaColumn,
  InfoBox,
  InfoBoxTitle,
  InfoBoxContent,
  InfoIpcBox,
  Anchor,
} from 'src/style/PatentContentStyle';
import { Spinner } from 'src/components/ui/interactive/Spinner';

const Container = styled.div`
  width: calc(100% - 280px - 140px);
  margin: 16px auto;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  min-height: calc(100vh - 62px - 32px);
`;

const Header = styled.div`
  border-radius: 4px 4px 0 0;
  background-image: linear-gradient(95deg, #2ec3c2, #0cafcc);
  display: flex;
  justify-content: space-between;
  color: #ffffff;
`;

const HeaderTitle = styled.div`
  padding: 16px 32px;
  font-weight: bold;
  font-size: 20px;
`;

const Body = styled.div`
  background-color: #ffffff;
`;

const docdbService = DocdbService();

interface IProps {
  docdbId: string;
}

/** DOCDB 單篇內容 */
const DocdbData: React.FC<RouteComponentProps<IProps>> = props => {
  const [isLoading, setIsLoading] = useState(true);
  const [docdbData, setDocdbData] = useState<IGetDocdbData>();
  const [ipcDescriptions, setIpcDescriptions] = useState<Array<String>>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await docdbService.getDocdbData(props.match.params.docdbId);
        if (data.content) {
          setDocdbData(data.content);
          setIpcDescriptions(data.ipc);
        }
      } catch (error) {
        errorHandler(error);
      }
      setIsLoading(false);
    };

    isLoading && fetchData();
  }, [props.match.params.docdbId, isLoading]);

  const processNames = (addressbook: { name: string[] }) => {
    return addressbook.name.map((item, idx) => (
      <span key={idx}>
        {item}
        <br />
      </span>
    ));
  };

  return isLoading ? (
    <Spinner width="200px" margin="150px auto" />
  ) : docdbData ? (
    <Container>
      <Header>
        <HeaderTitle>
          {docdbData.bibliographic.inventionTitle &&
            docdbData.bibliographic.inventionTitle.title.join(' | ')}
        </HeaderTitle>
      </Header>

      <Body>
        {docdbData.inventionSpecification &&
          docdbData.inventionSpecification.abstract &&
          docdbData.inventionSpecification.abstract.text && (
            <TextBox>
              <Anchor />
              <TextBoxTitle>摘要</TextBoxTitle>
              {docdbData.inventionSpecification.abstract.text.map((item, idx) => (
                <TextBoxContent key={idx}>
                  {item.split('\n').map((text, key) => {
                    return <p key={key}>{text}</p>;
                  })}
                </TextBoxContent>
              ))}
            </TextBox>
          )}
        <InfoArea oneColumn={false}>
          <InfoAreaColumn basis="45%">
            <InfoBox>
              <InfoBoxTitle>國&emsp;別：</InfoBoxTitle>
              <InfoBoxContent>{docdbData.country}</InfoBoxContent>
            </InfoBox>
            <InfoBox>
              <InfoBoxTitle>優先權：</InfoBoxTitle>
              <InfoBoxContent>
                {docdbData.bibliographic.priorityClaim &&
                  docdbData.bibliographic.priorityClaim.country.map((item, idx) => (
                    <p key={idx}>
                      {item}
                      &nbsp;
                      {docdbData.bibliographic.priorityClaim &&
                        docdbData.bibliographic.priorityClaim.docNumber[idx]}
                      &nbsp;
                      {docdbData.bibliographic.priorityClaim &&
                        dateFormatter(docdbData.bibliographic.priorityClaim.date[idx])}
                    </p>
                  ))}
              </InfoBoxContent>
            </InfoBox>
            <InfoBox>
              <InfoBoxTitle>申請號：</InfoBoxTitle>
              <InfoBoxContent>
                {docdbData.bibliographic.applicationReference.documentId.docNumber}
              </InfoBoxContent>
            </InfoBox>
            <InfoBox>
              <InfoBoxTitle>申請日：</InfoBoxTitle>
              <InfoBoxContent>
                {dateFormatter(docdbData.bibliographic.applicationReference.documentId.date)}
              </InfoBoxContent>
            </InfoBox>
            <InfoBox>
              {/* TODO: API需回傳kind code，判斷顯示為公開或公告 */}
              <InfoBoxTitle>公開號：</InfoBoxTitle>
              <InfoBoxContent>
                {docdbData.bibliographic.publicationReference.documentId.docNumber}
                {docdbData.bibliographic.publicationReference.documentId.kind}
              </InfoBoxContent>
            </InfoBox>
            <InfoBox>
              <InfoBoxTitle>公開日：</InfoBoxTitle>
              <InfoBoxContent>
                {docdbData.bibliographic.publicationReference &&
                docdbData.bibliographic.publicationReference.documentId &&
                docdbData.bibliographic.publicationReference.documentId.date
                  ? dateFormatter(docdbData.bibliographic.publicationReference.documentId.date)
                  : '無日期資訊'}
              </InfoBoxContent>
            </InfoBox>
          </InfoAreaColumn>
          <InfoAreaColumn basis="50%">
            <InfoBox>
              <InfoBoxTitle>申請人：</InfoBoxTitle>
              <InfoBoxContent>
                {docdbData.bibliographic.parties &&
                  docdbData.bibliographic.parties.applicant &&
                  processNames(docdbData.bibliographic.parties.applicant.addressbook)}
              </InfoBoxContent>
            </InfoBox>
            <InfoBox>
              <InfoBoxTitle>發明人：</InfoBoxTitle>
              <InfoBoxContent>
                {docdbData.bibliographic.parties &&
                  docdbData.bibliographic.parties.inventor &&
                  processNames(docdbData.bibliographic.parties.inventor.addressbook)}
              </InfoBoxContent>
            </InfoBox>
          </InfoAreaColumn>
        </InfoArea>
        <InfoIpcBox>
          <InfoBoxTitle>國際分類號/IPC：</InfoBoxTitle>
          {ipcDescriptions.map((item, idx) => (
            <InfoBoxContent key={idx}>{item}</InfoBoxContent>
          ))}
        </InfoIpcBox>
      </Body>
    </Container>
  ) : (
    // 若因任何因素(例如網址列uuid不正確)導致docdbData無資料，則什麼都不顯示
    <></>
  );
};

export default DocdbData;
