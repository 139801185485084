import React from 'react';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

interface ILoader {
  diameter?: string;
  borderWidth?: string;
  margin?: string;
}

const Loader = styled.div<ILoader>`
  margin: ${props => props.margin || '4px auto'};
  border: ${props => props.borderWidth || '5px'} solid #f3f3f3;
  border-radius: 50%;
  border-top: ${props => props.borderWidth || '5px'} solid #555555;
  width: ${props => props.diameter || '50px'};
  height: ${props => props.diameter || '50px'};
  /* TODO: Check this one. */
  // @ts-ignore
  animation: ${rotate} 1s linear infinite;
`;

interface IProps extends ILoader {}

const MiniLoader: React.FC<IProps> = props => {
  return <Loader {...props} />;
};

export default MiniLoader;
