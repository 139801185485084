import React, { useState } from 'react';
import withShortcut from 'src/components/search/withShortcut/withShortcut';
import { Formik, Form, FormikProps, FormikActions, FieldArray } from 'formik';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import Content from 'src/components/ui/content/Content';
import { Button } from 'src/style/theme/Common';
import { Row, TextLabel, StyledField } from 'src/style/PatentSearchStyle';
import { IFormSearch } from 'src/types/form/SearchFormTypes';
import {
  getRangeQuery,
  getFieldPrefix,
  appendCondition,
  isNotEmpty,
  enclose,
  getFuzzyFieldPostfix,
} from 'src/utils/TextUtils';
import { CONDITION_AND, countries, excludeItems } from 'src/constants/PatentSearchConstants';
import { connect } from 'react-redux';
import { composeBasicRequest } from 'src/redux/actions/patentSearchAction';
import { IBasicRequest } from 'src/types/PatentSearchTypes';
import { alertMessage } from 'src/utils/ModalUtils';
import { SystemMessage } from 'src/apps/applicationMessages';
import QueryCombination from 'src/components/search/partialForm/QueryCombination';
import DateRange from 'src/components/search/partialForm/DateRange';
import CountryTabs from 'src/components/search/CountryTabs';

interface IReduxProps {
  composeBasicRequest: (basicRequest: IBasicRequest) => void;
  ipcCodeList?: string[];
  handleIpcCodeList?: (code?: string[]) => void;
}

interface IProps extends IReduxProps, RouteComponentProps {}

/** 表格檢索 */
const FormSearch: React.FC<IProps> = props => {
  const handleSubmit = (values: IFormSearch, actions: FormikActions<IFormSearch>) => {
    actions.setSubmitting(false);

    // 過濾FieldArray (取queryList有填寫的欄位)
    const filteredFieldList = values.fieldList.filter(item => isNotEmpty(item.text));

    // 日期區間是否填寫完整
    const dateStart = values.dateStart;
    const dateEnd = values.dateEnd;
    const isDateRangeValid = dateStart && dateEnd;

    const handleIpcText = (ipcText: string) => {
      ipcText = ipcText.replace('OR ', 'OR IC=');
      ipcText = ipcText.replace('AND ', 'AND IC=');
      ipcText = ipcText.replace('NOT ', 'NOT IC=');
      ipcText = ipcText.replace(/,/g, ' OR IC=');
      return ipcText;
    };

    // 串接request檢索字串
    if (filteredFieldList.length === 0 && !isDateRangeValid && values.queryString.trim() === '') {
      alertMessage(SystemMessage.SEARCH_CONDITION_INCOMPLETE);
    } else {
      let submitQuery = '';

      // 日期範圍
      if (dateStart && dateEnd) {
        submitQuery +=
          appendCondition(submitQuery.length, CONDITION_AND) +
          getRangeQuery(values.dateType, dateStart, dateEnd);
      }

      // 其餘欄位
      values.fieldList.forEach(item => {
        isNotEmpty(item.text) &&
          (submitQuery +=
            appendCondition(submitQuery.length, CONDITION_AND) +
            (item.code === 'IC'
              ? ' (' + getFieldPrefix(item.code) + handleIpcText(item.text.trim()) + ') '
              : excludeItems.includes(item.code)
              ? getFieldPrefix(item.code) + item.text.trim()
              : enclose(item.text.trim()) + getFuzzyFieldPostfix(item.code)));
      });

      // textarea 檢索式
      if (isNotEmpty(values.queryString)) {
        submitQuery +=
          appendCondition(submitQuery.length, CONDITION_AND) + values.queryString.trim();
      }

      // 送出request並跳到搜尋結果頁
      props.composeBasicRequest({
        queryString: submitQuery,
        advanceKeywords: [{ keyCode: 'PO', keyword: activeTab }],
      });
      props.history.push('/result');
    }
  };

  const patentFacetFields: { [key: string]: string } = {
    PN: '公告/公開號',
    AN: '申請號',
    IC: 'IPC',
    FC: '第一 IPC',
    CS: 'CPC',
    TS: '第一 CPC',
    IQ: 'LOC',
    // UC: 'USPC',
    // FI: 'FI',
    FT: 'F-Term',
    IR: 'D-Term',
    PA: '申請人',
    IN: '發明人',
    LX: '代理人',
    PR: '優先權',
    'CI,CE': '引用專利及非專利', // 實際有此欄位時，簡碼可能需要再處理過(去逗號或其他做法)
    TI: '專利名稱',
    AB: '摘要',
    CL: '申請範圍',
    DE: '專利說明',
    // EX: '審查委員',
  };

  const formInitialValues = {
    queryString: '',
    dateType: 'ID',
    dateStart: null,
    dateEnd: null,
    fieldList: Object.keys(patentFacetFields).map(key => ({ code: key, text: '' })),
  };

  const handleIpcCode = (code: string[]) => {
    props.handleIpcCodeList && props.handleIpcCodeList(code);
    return code.toString().replace(/,/g, ' OR ');
  };
  const [activeTab, setActiveTab] = useState(countries[0]);

  return (
    <Content sector="專利檢索" feature="表格檢索">
      <>
        <CountryTabs activeTab={activeTab} click={tab => setActiveTab(tab)} />
        <Formik
          initialValues={formInitialValues}
          onSubmit={(values, actions) => {
            if (props.ipcCodeList && isNotEmpty(props.ipcCodeList.toString())) {
              values.fieldList.forEach(item => {
                if (item.code === 'IC' && props.ipcCodeList) {
                  item.text = props.ipcCodeList.toString().trim();
                }
              });
            }
            handleSubmit(values, actions);
          }}
          render={(formikBag: FormikProps<IFormSearch>) => (
            <Form>
              <QueryCombination
                formikBag={formikBag}
                // ipcCodeList={props.ipcCodeList}
                // handleIpcCodeList={props.handleIpcCodeList}
              />
              <DateRange formikBag={formikBag} />
              <FieldArray
                name="fieldList"
                render={() => (
                  <Row flexWrap="wrap">
                    {formikBag.values.fieldList.map((item, idx) => (
                      <TextLabel key={idx} width="48%" marginBottom="10px">
                        <span>
                          {item.code}
                          &nbsp;:&nbsp;
                          {item.code === 'CI,CE' && <br />}
                          {patentFacetFields[item.code]}
                        </span>
                        {/* {item.code === 'IQ' ? (
                        <IconLabel width="60%">
                          <StyledField width="100%" type="text" name={`fieldList.${idx}.text`} />
                          <FaSearch color={iceblue2} />
                        </IconLabel>
                      ) : ( */}
                        {item.code === 'IC' ? (
                          <StyledField
                            width="60%"
                            type="text"
                            name={`fieldList.${idx}.text`}
                            value={props.ipcCodeList && handleIpcCode(props.ipcCodeList)}
                            onChange={(event: { target: any }) => {
                              props.handleIpcCodeList &&
                                props.handleIpcCodeList(event.target.value.split(' OR '));
                            }}
                          />
                        ) : (
                          <StyledField width="60%" type="text" name={`fieldList.${idx}.text`} />
                        )}
                        {/* )} */}
                      </TextLabel>
                    ))}
                  </Row>
                )}
              />
              <Row justifyContent="flex-start">
                <Button type="submit" template="primary">
                  查詢
                </Button>
                <Button type="reset">清除</Button>
              </Row>
            </Form>
          )}
        />
      </>
    </Content>
  );
};

const mapDispatchToProps = {
  composeBasicRequest,
};

export default withShortcut(
  withRouter(
    connect(
      null,
      mapDispatchToProps,
    )(FormSearch),
  ),
);
