import React from 'react';
import styled from 'styled-components';
import Autocomplete from './Autocomplete';
import { IRssSearchSuggestion } from 'src/types/api/NewsApiTypes';

const Container = styled.div<{ isShow: boolean }>`
  display: ${props => !props.isShow && 'none'};
`;

interface IProps {
  isShow: boolean;
  inputText: string;
  tags: Array<IRssSearchSuggestion>;
  cssTop?: string;
  handleSuggestionClick: (input?: IRssSearchSuggestion) => void;
  handleSuggestionClose: () => void;
}

const SearchSuggestion: React.FC<IProps> = props => {
  const { isShow, inputText, cssTop, handleSuggestionClick } = props;

  return (
    <Container isShow={isShow}>
      <Autocomplete
        cssTop={cssTop}
        inputText={inputText}
        handleSuggestionClick={handleSuggestionClick}
        tags={props.tags}
      />
    </Container>
  );
};

export default SearchSuggestion;
