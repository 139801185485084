import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { ILineChartType, ILineChartDataType } from 'src/types/IndustryTypes';
import { trendsLineChartColors } from 'src/style/theme/Color';
import { svgId } from 'src/utils/svgDownloadUtils';

const width = 170;
const height = 70;
const offsetY = 4;
const peddingTop = -2;

interface IProps {
  dataset: Array<ILineChartType>;
}

/** 折線圖 */
const StaticLineChart: React.FC<IProps> = ({ dataset }) => {
  const svgRef = useRef<SVGSVGElement>(null);

  const getMaxY = (data: Array<ILineChartType>) => {
    let values: Array<number> = [];
    data.forEach(item =>
      item.data.forEach(data => {
        values.push(data.count);
      }),
    );
    const max = Math.max(...values);
    return max === 0 ? 1 : max;
  };

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    svg.selectAll('g').remove();

    const xScale = d3
      .scaleBand()
      .domain(dataset[0].data.map((item, idx) => `${idx}`))
      .rangeRound([0, width]);

    const yScale = d3
      .scaleLinear()
      .domain([0, getMaxY(dataset)])
      .range([height - offsetY, 0])
      .nice(5);

    const linePath = d3
      .line<ILineChartDataType>()
      .x((d: ILineChartDataType, i) => (xScale(`${i}`) || 0) + xScale.bandwidth() / 2)
      .y((d: ILineChartDataType) => yScale(d.count) + offsetY)
      .curve(d3.curveMonotoneX); // smooth line

    dataset.forEach((item, idx) => {
      const color = trendsLineChartColors[idx];
      const lineG = svg.append('g');
      // 折線圖-線段
      lineG
        .datum(item.data)
        .append('path')
        .attr('transform', 'translate(0,' + peddingTop + ')')
        .attr('d', linePath)
        .attr('fill', 'none')
        .attr('stroke-width', '2px')
        .attr('stroke', `${color}`);

      // 折線圖-連接點 (只有一筆資料時, 為避免看不到線段才顯示資料點)
      dataset[idx].data.length === 1 &&
        lineG
          .selectAll('circle')
          .data(item.data)
          .enter()
          .append('circle')
          .attr('cx', d => (xScale(d.time) || 0) - xScale.bandwidth() / 2)
          .attr('cy', d => offsetY + peddingTop + yScale(d.count))
          .attr('r', 2)
          .attr('fill', `${color}`)
          .style('stroke', 'transparent')
          .style('stroke-width', 4);
    });
  }, [dataset]);

  return <svg id={svgId} ref={svgRef} width={width} height={height} />;
};

export default StaticLineChart;
