import React from 'react';
import styled from 'styled-components';
import { FaUserCircle } from 'react-icons/fa';

import { getAvailableFeatures } from 'src/apps/permission/Permission';
import ProfileItem from './DropdownItem';
import {
  blue3,
  iceblue2,
  iceblue5,
  iceblue8,
  ultrawhite,
  ultrablackrgba,
  alarm5,
} from 'src/style/theme/Color';

import { connect } from 'react-redux';
import { ReduxAppState } from 'src/redux/reducers';
import { ITokenPayload } from 'src/apps/auth/Auth';

const Container = styled.div`
  border-radius: 4px;
  box-shadow: 0 5px 20px 0 ${ultrablackrgba(0.1)};
  background-color: ${ultrawhite};
`;
const Description = styled.div`
  padding: 16px 16px;
  border-bottom: 1px solid ${iceblue8};
  display: flex;
  align-items: center;
  div {
    overflow: hidden;
  }
`;
const MemberInfo = styled.div`
  color: ${iceblue5};
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const MemberName = styled(MemberInfo)`
  color: ${iceblue2};
  font-weight: bold;
`;
const PicStyle = styled.div`
  margin: -16px 20px 0 0;
  min-width: 62px;
  color: ${blue3};
`;
const Functions = styled.div`
  padding: 4px 0;
  a {
    display: block;
  }
`;

interface IReduxMappingProps {
  member: ITokenPayload;
  isLogin: boolean;
}
interface IProps extends IReduxMappingProps {
  setDropdown: React.Dispatch<React.SetStateAction<boolean>>;
}

function ProfileDropdown({ member, isLogin, setDropdown }: IProps) {
  const features = [
    { feature: '瀏覽紀錄', path: '/member/browseLog' },
    { feature: '編輯會員資料', path: '/member/edit' },
    { feature: '編輯專家履歷', path: '/member/expertDetailEdit' },
    { feature: '管理會員帳號', path: '/member/manage' },
    { feature: '通知設定', path: '/member/notification' },
    { feature: '登出', path: '/' },
  ];

  return (
    <Container>
      {isLogin && (
        <>
          <Description>
            <PicStyle>
              <FaUserCircle size={62} />
            </PicStyle>
            <div>
              <MemberName>{member.info.name}</MemberName>
              <MemberInfo>{member.info.email}</MemberInfo>
              <MemberInfo>{member.info.companyName}</MemberInfo>
            </div>
          </Description>
          <Functions>
            {getAvailableFeatures(features).map(item => (
              <ProfileItem
                key={item.feature}
                name={item.feature}
                path={item.path}
                setDropdown={setDropdown}
                color={item.feature === '登出' ? alarm5 : undefined}
              />
            ))}
          </Functions>
        </>
      )}
    </Container>
  );
}

const mapStateToProps = (state: ReduxAppState) => {
  return {
    member: state.memberReducer.memberPayload,
    isLogin: state.memberReducer.authData.isLogin,
  };
};

export default connect(
  mapStateToProps,
  null,
)(ProfileDropdown);
