import React, { useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { FaRegImage } from 'react-icons/fa';

import Lightbox from './Lightbox';
import { getInlineImgUrl } from 'src/utils/PatentUtils';
import { blue2, blue3, blue6, blue8 } from 'src/style/theme/Color';
import { zTooltip } from 'src/style/theme/Z-Index';

const ImgIcon = styled.span`
  width: 24px;
  height: 24px;
  margin: 0 4px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  border-radius: 12px;
  background-color: ${blue3};
  line-height: 24px;
  cursor: pointer;
  svg {
    color: ${blue8};
  }
  :hover {
    /* icon圖片變淺 */
    svg {
      opacity: 0.5;
    }
  }
  /* 利用focus讓按過的icon顏色變深，點擊其他地方後恢復,
     由於span預設無focus，故需搭配tabIndex屬性 */
  :focus {
    background-color: ${blue2};
    outline: none;
  }
`;
const PreviewTooltip = styled.div<{ left: number; top: number }>`
  background-color: ${blue3};
  color: ${blue6};
  line-height: 1.33em;
  width: 200px;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(12, 175, 204, 0.3);
  text-align: left;
  position: fixed;
  z-index: ${zTooltip};
  top: ${props => props.top}px;
  left: ${props => props.left}px;
  > img {
    width: 100%;
    margin-bottom: 4px;
  }
  > span {
    word-wrap: break-word;
  }
`;

// 取得.html檔中要渲染內容的DOM物件，不存在的話自己建一個div(for typescript check)
const tooltip = document.getElementById('tooltip');
const portalDiv = tooltip ? tooltip : document.createElement('div');

interface IProps {
  uuid: string;
  fileName: string;
}

const useFocus = (): [any, () => void] => {
  const htmlElRef = useRef<HTMLElement>();
  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus();
  };
  return [htmlElRef, setFocus];
};

/** 專利內文圖片 */
const PatentInlineImage: React.FC<IProps> = props => {
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [showLightbox, setShowLightbox] = useState<boolean>(false);
  const [imageNotFound, setImageNotFound] = useState<boolean>(false);
  const [left, setLeft] = useState<number>(0);
  const [top, setTop] = useState<number>(0);
  const [iconRef, setIconFocus] = useFocus();

  const handleMouseEnter = (e: any) => {
    setShowPreview(true);
    const pos = getPosition(e.currentTarget);
    setLeft(pos.x);
    setTop(pos.y);
  };

  const handleMouseLeave = () => {
    setShowPreview(false);
  };

  const handleLightboxClose = () => {
    setShowLightbox(false);
    setIconFocus();
  };

  const imageUrl = getInlineImgUrl(props.uuid, props.fileName);
  // 圖片載入錯誤時將imageNotFound設為true，之後就不再重複request縮圖、也無法打開lightbox
  const previewImage = !imageNotFound && (
    <img src={imageUrl} alt={''} onError={() => setImageNotFound(true)} />
  );

  return (
    <ImgIcon
      tabIndex={0}
      ref={iconRef}
      onMouseEnter={e => handleMouseEnter(e)}
      onMouseLeave={() => handleMouseLeave()}
      onClick={() => {
        setShowLightbox(!imageNotFound);
      }}
    >
      <FaRegImage size="12px" />
      {showPreview &&
        ReactDOM.createPortal(
          <PreviewTooltip left={left} top={top}>
            {previewImage}
            <span>{props.fileName}</span>
          </PreviewTooltip>,
          portalDiv,
        )}
      {showLightbox && (
        <Lightbox
          images={[imageUrl]}
          imageOnDisplay={0}
          isOpen={showLightbox}
          showThumbnails={false}
          onClose={handleLightboxClose}
        />
      )}
    </ImgIcon>
  );
};

/** 取得Tooltip的顯示座標位置 */
function getPosition(element: any) {
  let x = 0;
  let y = 0;
  const offset = 8; // icon與tooltip間距
  const tooltipWidth = 200; // tooltip寬度

  const windowWidth = document.body.clientWidth;
  const rect = element.getBoundingClientRect();

  x += rect.right + offset;
  y += rect.top;

  // 預設Tooltip往右展開，但若空間不足時改為往左
  if (tooltipWidth > windowWidth - x) {
    x = rect.left - tooltipWidth - offset;
  }

  return { x: x, y: y };
}

export default PatentInlineImage;
