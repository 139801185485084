import { IBasicRequest, ISearchRequest, IFilterTag } from 'src/types/PatentSearchTypes';

/** 基本檢索 */
export const COMPOSE_BASIC_REQUEST = 'COMPOSE_BASIC_REQUEST';
/** UUID List 檢索 */
export const COMPOSE_UUID_LIST_REQUEST = 'COMPOSE_UUID_LIST_REQUEST';
/** 下一頁 */
export const NAVIGATE_TO_NEXT_PAGE = 'NAVIGATE_TO_NEXT_PAGE';
/** 選擇日期範圍 */
export const SELECT_DATE_RANGE = 'SELECT_DATE_RANGE';
/** 查詢美國上訴專利 */
export const SEARCH_PTAB_DATA = 'SEARCH_PTAB_DATA';
/** 改變排序方式 */
export const CHANGE_SORT_ORDER = 'CHANGE_SORT_ORDER';
/** 再檢索-篩選 */
export const APPEND_ADVANCED_KEYWORD = 'APPEND_ADVANCED_KEYWORD';
/** 取消再檢索-篩選 */
export const DETACH_ADVANCED_KEYWORD = 'DETACH_ADVANCED_KEYWORD';
/** 檢索-加入國別條件 */
export const APPEND_ADVANCED_KEYWORD_COUNTRY = 'APPEND_ADVANCED_KEYWORD_COUNTRY';
/** 再檢索-聚類 */
export const APPEND_CLUSTER_KEYWORD = 'APPEND_CLUSTER_KEYWORD';
/** 雙語詞彙再檢索-篩選 (參數傳入undefined表示取消) */
export const TOGGLE_BILINGUAL_TERM = 'TOGGLE_BILINGUAL_TERM';
/** 檢索去重 (參數傳入非 true 表示取消) */
export const TOGGLE_DEDUPE = 'TOGGLE_DEDUPE';
/** 再檢索-技術功效分析 (參數皆傳入undefined表示取消) */
export const TOGGLE_TECH_FUNC_QUERY = 'TOGGLE_TECH_FUNC_QUERY';

export interface PatentSearchState {
  request: ISearchRequest;
  isUuidListSearch: boolean;
  highlightWords: string[];
  filterTag?: IFilterTag;
  ptabTag?: boolean;
}

interface ComposeBasicRequestAction {
  type: typeof COMPOSE_BASIC_REQUEST;
  basicRequest: IBasicRequest;
  highlightWords: string[];
}

interface ComposeUuidListRequestAction {
  type: typeof COMPOSE_UUID_LIST_REQUEST;
  basicRequest: IBasicRequest;
}

interface NavigateToNextPageAction {
  type: typeof NAVIGATE_TO_NEXT_PAGE;
}

interface SelectDateRangeAction {
  type: typeof SELECT_DATE_RANGE;
  dateRange: string;
}

interface SearchPtabDataAction {
  type: typeof SEARCH_PTAB_DATA;
  ptab: string;
}

interface ChangeSortOrderAction {
  type: typeof CHANGE_SORT_ORDER;
  sortByDate: string;
}

interface AppendAdvancedKeywordAction {
  type: typeof APPEND_ADVANCED_KEYWORD;
  keyCode: string;
  keyword: string;
}

interface DetachAdvancedKeywordAction {
  type: typeof DETACH_ADVANCED_KEYWORD;
  keyCode: string;
}

interface AppendAdvancedKeywordCountryAction {
  type: typeof APPEND_ADVANCED_KEYWORD_COUNTRY;
  keyCode: string;
  keyword: string;
}

interface AppendClusterKeywordAction {
  type: typeof APPEND_CLUSTER_KEYWORD;
  clusterKeyword: string;
}

interface ToggleBilingualTermAction {
  type: typeof TOGGLE_BILINGUAL_TERM;
  bilingualTerm?: string;
}

interface ToggleDedupeAction {
  type: typeof TOGGLE_DEDUPE;
  dedupe: boolean;
}

interface ToggleTechFuncQueryAction {
  type: typeof TOGGLE_TECH_FUNC_QUERY;
  techQuery?: string;
  funcQuery?: string;
  filterTag?: IFilterTag;
}

export type PatentSearchActionTypes =
  | ComposeBasicRequestAction
  | ComposeUuidListRequestAction
  | NavigateToNextPageAction
  | SelectDateRangeAction
  | ChangeSortOrderAction
  | AppendAdvancedKeywordAction
  | DetachAdvancedKeywordAction
  | AppendAdvancedKeywordCountryAction
  | AppendClusterKeywordAction
  | ToggleBilingualTermAction
  | ToggleDedupeAction
  | ToggleTechFuncQueryAction
  | SearchPtabDataAction;
