import React from 'react';
import styled from 'styled-components';
import { FaArrowUp } from 'react-icons/fa';
import { blue1, blue2 } from 'src/style/theme/Color';

const Container = styled.div<{ showCookie?: boolean }>`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 32px;
  top: -105px;
  // top: ${props => (props.showCookie === null ? '-105px' : '-25px')};
  bottom: ${props => !props.showCookie && '32px'};
  position: ${props => (props.showCookie ? 'absolute' : 'fixed')};
  border-radius: 50%;
  background-image: linear-gradient(134deg, ${blue2}, ${blue1});
  cursor: pointer;
  svg {
    color: #ffffff;
  }
  /* ScrollToTop 不穿透 */
  pointer-events: auto;
`;

const handleScroll = () => {
  let count = 0;
  const distance = window.scrollY;
  const scrollInterval = setInterval(() => {
    const scrollStep = (-distance * Math.pow(count, 2)) / (distance * 15);
    if (window.scrollY !== 0) {
      window.scrollBy(0, scrollStep);
      ++count;
    } else {
      clearInterval(scrollInterval);
    }
  }, 10);
};

interface IProps {
  showCookie?: boolean;
}

export const ScrollToTop = (props: IProps) => (
  <Container showCookie={props.showCookie} onClick={handleScroll}>
    <FaArrowUp size={20} />
  </Container>
);
