import React from 'react';
import styled from 'styled-components';
import by_image_search from 'src/assets/images/manual/patent-search/by_image_search.png';

const ImgContainer = styled.div<{ height: string; imageUrl: string }>`
  position: relative;
  height: ${props => props.height}px;
  padding: 10px 0;
  background-image: url(${props => props.imageUrl});
  background-size: contain;
  background-repeat: no-repeat;
`;
export default function PatentSearchManual() {
  return (
    <>
      <p>
        本國設計專利以圖找圖可輸入國際工業設計分類，空白預設為所有分類，上傳圖片選擇裁切範圍後查詢。
      </p>
      <ol>
        <li>從功能列點選「專利檢索」。</li>
        <li>點選「本國設計專利以圖找圖」功能。</li>
        <li>輸入國際工業設計分類，未輸入預設為不分類。</li>
        <li>點選「選擇檔案」上傳要檢索的圖片，進行裁切或選擇全圖。</li>
        <li>點選「查詢」進行查詢</li>
      </ol>
      <ImgContainer height="350" imageUrl={by_image_search} />
    </>
  );
}
