import React from 'react';
import styled from 'styled-components';
import ColumnChart from './ColumnChart';
import LineChart from './LineChart';
import PieChart from './PieChart';
import { IFacetFieldCount } from 'src/types/PatentSearchTypes';
import { numFormatter } from 'src/utils/Formatter';
import { ultrawhite, iceblue1, iceblue4, iceblue5, iceblue8 } from 'src/style/theme/Color';
import { toEllipsis } from './chartHelper';

const Container = styled.div`
  padding: 0 24px;
  max-width: 960px;
`;

const ChartBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Table = styled.div`
  display: table;
  border-collapse: collapse;
  border-radius: 4px;
  overflow: hidden;
  margin: 32px auto;
  @media print {
    display: none;
  }
`;

const Tr = styled.div`
  display: table-row;
`;

const Tcell = styled.div`
  display: table-cell;
  width: 132.5px;
  min-width: 88px;
  vertical-align: middle;
  text-align: center;
  padding: 10px;
  border: 1px solid ${iceblue8};

  /* 首欄為標題不換行。 */
  :nth-child(1) {
    white-space: nowrap;
  }
`;

const Th = styled(Tcell)`
  background-color: ${iceblue4};
  color: ${ultrawhite};
`;

const Td = styled(Tcell)`
  background-color: ${iceblue8};
  color: ${iceblue1};
`;

const Footnote = styled.div`
  text-align: right;
  color: ${iceblue5};
  @media print {
    display: none;
  }
`;

export enum ChartType {
  /** 長條圖 */
  COLUMN = '長條圖',
  /** 折線圖 */
  LINE = '折線圖',
  /** 圓餅圖 */
  PIE = '圓餅圖',
  /** 圓餅圖 - 超出項目計入其他 */
  PIE_MERGE_OTHERS = '圓餅圖 - 超出項目計入其他',
}

interface IProps {
  type: string;
  fieldName: string;
  dataset: Array<IFacetFieldCount>;
}

const Chart: React.FC<IProps> = ({ type, fieldName, dataset }) => {
  const renderSwitch = () => {
    switch (type) {
      case ChartType.COLUMN:
        return <ColumnChart fieldName={fieldName} dataset={dataset} />;
      case ChartType.LINE:
        return <LineChart fieldName={fieldName} dataset={dataset} />;
      case ChartType.PIE:
      case ChartType.PIE_MERGE_OTHERS:
        return <PieChart fieldName={fieldName} dataset={dataset} />;
      default:
        return null;
    }
  };

  return (
    <Container>
      <ChartBox>{renderSwitch()}</ChartBox>
      <Table>
        <Tr>
          <Th>{fieldName}</Th>
          {dataset.map((item, idx) => (
            <Th key={idx}>{toEllipsis(item.name, 10, 0)}</Th>
          ))}
        </Tr>
        <Tr>
          <Td>專利數量</Td>
          {dataset.map((item, idx) => (
            <Td key={idx}>{numFormatter(item.count)}</Td>
          ))}
        </Tr>
      </Table>
      <Footnote>專利圖表分析之統計資料 係以當時資料庫資料量分析</Footnote>
    </Container>
  );
};

export default Chart;
