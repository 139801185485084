import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { RouteComponentProps, Switch, Route, Redirect } from 'react-router-dom';

import DropdownMenu from 'src/components/mobile/dropdown-menu/DropdownMenu';
import MobileColumnistArticle from 'src/components/mobile/article/MobileColumnistArticle';
import MobileVideo from 'src/components/mobile/article//MobileVideo';

import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import { IGetColumnistArticleCategory } from 'src/types/api/ColumnistArticleApiTypes';

const Container = styled.div`
  margin: 0 auto;
  max-width: 600px;
`;

const ContentStyle = styled.div`
  padding: 20px;
`;

const features = [
  { feature: '專欄文章', path: '/columnist', subfeatures: true },
  { feature: '影片專區', path: '/video', subfeatures: false },
];

const MobileArticleLayout: React.FC<RouteComponentProps> = routeProps => {
  const [categories, setCategories] = useState<Array<IGetColumnistArticleCategory>>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await Api().get('/article/columnist/categories');
        setCategories(res.data);
      } catch (error) {
        errorHandler(error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <DropdownMenu
        features={features}
        subFeatArr={[
          {
            idx: 0,
            items: categories.map(category => ({ id: category.id, name: category.name })),
          },
        ]}
        {...routeProps}
      />
      <Container>
        <ContentStyle>
          <Switch>
            {categories.length > 0
              ? categories
                  .map(category => (
                    <Route
                      key={routeProps.match.url + features[0].path + '/' + category.id}
                      exact
                      path={routeProps.match.url + features[0].path + '/' + category.id}
                      // TODO: 原本的 ColumnistArticle (at ArticleLayout.tsx:116) 就存在相同問題。
                      // Warning: Can't perform a React state update on an unmounted component.
                      // This is a no-op, but it indicates a memory leak in your application.
                      // To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.
                      // in MobileColumnistArticle (at MobileArticleLayout.tsx:97)  in component (created by Context.Consumer)
                      component={() => <MobileColumnistArticle categoryId={category.id} />}
                    />
                  ))
                  .concat([
                    <Route
                      key={routeProps.match.url + features[1].path}
                      path={routeProps.match.url + features[1].path}
                      render={() => <MobileVideo />}
                    />,
                    <Route
                      key={routeProps.match.url + 'redirect'}
                      component={() => (
                        <Redirect
                          to={routeProps.match.url + features[0].path + '/' + categories[0].id}
                        />
                      )}
                    />,
                  ])
              : [
                  <Route
                    key={routeProps.match.url + features[0].path}
                    path={routeProps.match.url + features[0].path}
                    render={() => <MobileColumnistArticle />}
                  />,
                  <Route
                    key={routeProps.match.url + features[1].path}
                    path={routeProps.match.url + features[1].path}
                    render={() => <MobileVideo />}
                  />,
                ]}

            <Route component={() => <Redirect to={routeProps.match.url + features[0].path} />} />
          </Switch>
        </ContentStyle>
      </Container>
    </>
  );
};

export default MobileArticleLayout;
