import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';

import RankingPanel from 'src/components/index/tabs/RankingPanel';
import { Dropdown, IDropdownItem } from 'src/components/common/Dropdown';
import { Button } from 'src/style/theme/Common';
import { Spinner } from 'src/components/ui/interactive/Spinner';
import {
  IIndustrialClassType,
  IIndustrialRankingApiParamType,
  TimePeriod,
} from 'src/types/IndustryTypes';
import {
  black1,
  blue1,
  blue2,
  blue4,
  blue6,
  iceblue1,
  iceblue6,
  iceblue7,
  ultrawhite,
  ultrawhitergba,
  ultrablackrgba,
} from 'src/style/theme/Color';

import { connect } from 'react-redux';
import { ReduxAppState } from 'src/redux/reducers';
import { composeBasicRequest } from 'src/redux/actions/patentSearchAction';
import { IBasicRequest } from 'src/types/PatentSearchTypes';

const Area = styled.div`
  margin-top: 40px;
  padding: 0 50px;
  background-size: cover;
`;
const Anchor = styled.div`
  margin: auto;
  width: 90%;
  height: 100%;
  max-width: 1440px;
`;
const AreaTitle = styled.p`
  color: ${blue1};
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 16px;
`;
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 440px;
`;
const PatentRankingContainer = styled.div`
  box-shadow: -4px 4px 20px 0px rgba(38, 50, 55, 0.2);
  flex-grow: 1;
`;
const WordRankingContainer = styled.div`
  flex: 0 0 320px;
  margin-left: 20px;
  padding: 8px 24px;
  background-color: ${blue1};
  border-radius: 4px;
  box-shadow: 0 24px 30px -26px rgba(67, 67, 75, 0.8);
`;
const TabBar = styled.div`
  padding: 8px 0 0 12px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  border-radius: 4px 4px 0 0;
  background-image: linear-gradient(92deg, ${blue2}, ${blue1});
`;
const TabContainer = styled.div`
  display: flex;
`;
const Tab = styled.div<{ active: boolean }>`
  margin: 0 2px 0 0;
  padding: 0 20px;
  height: 32px;
  color: ${props => (props.active ? blue2 : ultrawhite)};
  background-color: ${props => (props.active ? ultrawhite : ultrawhitergba(0.3))};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  text-align: center;
  line-height: 32px;
  cursor: pointer;
`;
const WordBlock = styled.div`
  margin: 12px;
  min-height: 190px;
`;
const WordTitle = styled.p`
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: bold;
  color: ${ultrawhite};
`;
const WordItem = styled.div<{ sequence: number }>`
  padding: 4px 0;
  color: ${ultrawhite};
  cursor: pointer;
  :before {
    content: '${props => 'No.' + props.sequence}';
    margin-right: 16px;
    opacity: 0.6;
    font-size: 13px;
  }
  :hover{
    color: ${blue4};
    :before {
      color: ${ultrawhite};
      opacity: 1;
    }
  }
`;
const WordNoData = styled.p`
  opacity: 0.8;
  color: ${ultrawhite};
`;

// Search bar 樣式
const SearchBar = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  box-shadow: 0 4px 16px 0 ${ultrablackrgba(0.2)};
  margin-bottom: 20px;
`;
const customDropdownContainer = (width?: number) => {
  return css`
    height: 100%;
    min-width: 200px;
    text-align: left;
    flex-shrink: 0;
    background-color: ${ultrawhitergba(0.9)};
    backdrop-filter: blur(8px);
    ${width
      ? css`
          width: ${width}px;
          border-left: 1px solid ${iceblue7};
          flex-grow: 0;
        `
      : css`
          border-radius: 4px 0 0 4px;
          flex-grow: 1;
        `}
  `;
};
const customDropdownSelectedItem = (content: string) => {
  return css`
    font-size: 18px;
    padding: 18px 16px;
    color: ${iceblue1};
    > div {
      font-size: 18px;
    }
    :before {
      content: '${content}';
      font-size: 18px;
      color: ${iceblue6};
      margin-right: 12px;
    }
  `;
};
const customDropdownItemBlock = css`
  width: 100%;
  right: 0;
  top: 69px;
  color: ${black1};
  max-height: 310px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    box-shadow: inset 0 0 8px 8px ${blue6};
    border: solid 2px transparent;
  }
`;
const customDropdownItem = css`
  padding: 8px 24px;
  min-height: 38px;
`;

// 下拉選單
const sectionDropdownDefault: Array<IDropdownItem> = [{ name: '不分行業', value: '0' }];
const timePeriodDropdownList: Array<IDropdownItem> = Object.keys(TimePeriod).map(key => {
  return { name: TimePeriod[key as keyof typeof TimePeriod], value: key };
});

// 專利排行 tab
const initTabs = [
  {
    tab: '瀏覽排行',
    src: 'trend/industry/view',
    countsColumnName: '觀看次數',
  },
  {
    tab: '收藏排行',
    src: 'trend/industry/collect',
    countsColumnName: '收藏次數',
  },
];

interface IProps extends RouteComponentProps {
  industrialClassList: Array<IIndustrialClassType>;
  composeBasicRequest: (basicRequest: IBasicRequest) => void;
}

const Ranking: React.FC<IProps> = ({ industrialClassList, composeBasicRequest, history }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [apiParams, setApiParams] = useState<IIndustrialRankingApiParamType>({
    industrialClassId: null,
    timePeriod: 'U',
  });

  // Search bar
  const [sectionDropdown, setSectionDropdown] = useState<IDropdownItem[]>(sectionDropdownDefault);
  const [activeSection, setActiveSection] = useState<IDropdownItem>(sectionDropdownDefault[0]);
  const [activePeriod, setActivePeriod] = useState<IDropdownItem>(timePeriodDropdownList[0]);

  // Fetch contents
  const [loading, setLoading] = useState(true);
  const [keywords, setKeywords] = useState<Array<string>>([]);
  const [tags, setTags] = useState<Array<string>>([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const keywordRes = await Api().post('trend/industry/keyword', apiParams);
        const tagRes = await Api().post('trend/industry/tag', apiParams);
        setKeywords(keywordRes.data);
        setTags(tagRes.data);
      } catch (error) {
        errorHandler(error);
      } finally {
        setLoading(false);
      }
    };

    // 還原設定
    setLoading(true);
    setKeywords([]);
    setTags([]);

    fetchData();
  }, [apiParams]);

  // 待行業別清單完成載入後設為下拉選單選項
  useEffect(() => {
    if (industrialClassList && industrialClassList.length !== 0) {
      const items: Array<IDropdownItem> = [];
      industrialClassList.forEach(section => {
        items.push({ name: section.description, value: section.id.toString() });
      });
      setSectionDropdown(sectionDropdownDefault.concat(items));
    }
  }, [industrialClassList]);

  const handleKeywordClick = (word: string) => {
    history.push('/result');
    composeBasicRequest({
      queryString: word,
      industrialClassIds: [],
    });
  };

  const handleTagClick = (word: string) => {
    history.push({
      pathname: '/tag/info',
      state: {
        tag: word,
        timePeriod: 'T',
      },
    });
  };

  const noDataContent = <WordNoData>排行資料不足，請嘗試變更行業別或時間區間。</WordNoData>;

  const WordRanking = (
    <>
      <WordBlock>
        <WordTitle>關鍵字排行</WordTitle>
        {!loading && keywords && keywords.length !== 0
          ? keywords.map((word, idx) => (
              <WordItem key={word} sequence={idx + 1} onClick={() => handleKeywordClick(word)}>
                {word}
              </WordItem>
            ))
          : noDataContent}
      </WordBlock>
      <WordBlock>
        <WordTitle>標籤排行</WordTitle>
        {!loading && tags && tags.length !== 0
          ? tags.map((word, idx) => (
              <WordItem key={word} sequence={idx + 1} onClick={() => handleTagClick(word)}>
                {word}
              </WordItem>
            ))
          : noDataContent}
      </WordBlock>
    </>
  );
  const searchBar = (
    <SearchBar>
      <Dropdown
        items={sectionDropdown}
        activeItem={activeSection}
        handleOnclick={item => setActiveSection(item)}
        customStyleContainer={customDropdownContainer()}
        customStyleSelectedItem={customDropdownSelectedItem('行業別')}
        customStyleItemBlock={customDropdownItemBlock}
        customStyleItem={customDropdownItem}
      />
      <Dropdown
        items={timePeriodDropdownList}
        activeItem={activePeriod}
        handleOnclick={item => setActivePeriod(item)}
        customStyleContainer={customDropdownContainer(280)}
        customStyleSelectedItem={customDropdownSelectedItem('時間區間')}
        customStyleItemBlock={customDropdownItemBlock}
        customStyleItem={customDropdownItem}
      />
      <Button
        type="submit"
        template="primary-dark"
        margin="0"
        radius="0 4px 4px 0"
        fontSize={18}
        width={112}
        onClick={() =>
          setApiParams({
            industrialClassId: activeSection.value === '0' ? null : activeSection.value,
            timePeriod: activePeriod.value as keyof typeof TimePeriod,
          })
        }
      >
        查看排行
      </Button>
    </SearchBar>
  );

  return (
    <Area>
      <Anchor>
        <AreaTitle>專利排行</AreaTitle>
        {searchBar}
        <Container>
          <PatentRankingContainer>
            <TabBar>
              <TabContainer>
                {initTabs.map((item, idx) => (
                  <Tab key={item.tab} active={activeTab === idx} onClick={() => setActiveTab(idx)}>
                    {item.tab}
                  </Tab>
                ))}
              </TabContainer>
            </TabBar>
            <RankingPanel
              ajaxUrl={initTabs[activeTab].src}
              countsColumnName={initTabs[activeTab].countsColumnName}
              apiParams={apiParams}
            />
          </PatentRankingContainer>
          <WordRankingContainer>
            {loading ? <Spinner width="100px" margin="150px auto" /> : WordRanking}
          </WordRankingContainer>
        </Container>
      </Anchor>
    </Area>
  );
};

const mapStateToProps = (state: ReduxAppState) => {
  return {
    industrialClassList: state.industrialClassReducer.industrialClassList,
  };
};

const mapDispatchToProps = {
  composeBasicRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Ranking));
