import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FaTags, FaEllipsisH } from 'react-icons/fa';

import { IGetPatentCollectionCategoryId } from 'src/types/api/ShowcaseApiTypes';
import { dateFormatter } from 'src/utils/Formatter';
import Stars from 'src/components/ui/interactive/rating/Stars';
import EditTag from 'src/components/ui/interactive/dropdown/EditTag';
import CollectActionMenu from 'src/components/ui/interactive/dropdown/CollectActionMenu';
// import _ from 'lodash';

// Layout
const Container = styled.div`
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  :hover {
    background-color: #f5fdfe;
  }
`;
const LeftContainer = styled.div`
  padding: 20px 8px 20px 32px;
  width: 70%;
`;
const RightContainer = styled.div`
  width: 30%;
  padding: 20px 32px 20px 8px;
  display: flex;
  align-items: flex-start;
  img {
    display: block;
    width: 90%;
    max-height: 200px;
  }
  i {
    margin: 0 0 0 4px;
    display: block;
    color: #979ea1;
    font-size: 20px;
    cursor: pointer;
  }
`;

// Elements
const Title = styled.div`
  padding: 0 0 8px 0;
  font-size: 18px;
  a {
    color: #077790;
  }
`;
const Text = styled.div`
  color: #666666;
  padding: 0 0 4px 0;
`;
const TagList = styled.div`
  padding: 6px 0 0 0;
  color: #979ea1;
  display: flex;
  align-items: baseline;
  position: relative;
`;
const TagIcon = styled.div`
  position: relative;
  > i {
    display: block;
    position: relative;
    width: 37px;
    height: 37px;
    padding: 2px 0 0 12px;
    color: #0cafcc;
    font-size: 16px;
    cursor: pointer;

    &:hover {
      background-color: #edf6f7;
      border-radius: 18px;

      &::before {
        position: absolute;
        top: -41px;
        left: -30px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 92px;
        height: 31px;
        border-radius: 4px;
        background-color: #003f54;
        font-size: 15px;
        color: #ffffff;
        font-style: normal;
        content: '管理標籤';
      }
    }
  }

  svg {
    position: relative;
    top: 5px;
  }
`;
const EditTagForm = styled.div`
  position: absolute;
  top: -42px;
  left: 45px;
`;

const ActionMenu = styled.div`
  position: relative;
`;

interface IProps {
  collectData: IGetPatentCollectionCategoryId;
  submit: (option: string, data: any) => void;
  collectId: number;
  submitEditTags: () => void;
}
function CollectionItem(props: IProps) {
  let [activeItemMenu, setActiveItemMenu] = useState(false);
  const [editTag, setEditTag] = useState(false);

  const handleEditTags = () => {
    props.submitEditTags();
    setEditTag(false);
  };
  const documentId = props.collectData.solrResult.bibliographic.publicationReference.documentId;
  const notKindA =
    documentId.kind &&
    !documentId.kind.includes('A') &&
    !documentId.kind.includes('P1') &&
    !documentId.kind.includes('P4') &&
    !documentId.kind.includes('P9');

  return (
    <Container>
      <LeftContainer>
        <Title>
          <Link to={`/patent/${props.collectData.patentId}/view`}>
            {props.collectData.solrResult.bibliographic.inventionTitle.title.join(' | ')}
          </Link>
        </Title>
        <Text>
          <Stars stars={props.collectData.rating} />
        </Text>
        <Text>
          發明人：
          {props.collectData.solrResult.bibliographic.parties.inventor &&
            props.collectData.solrResult.bibliographic.parties.inventor.addressbook.name &&
            props.collectData.solrResult.bibliographic.parties.inventor.addressbook.name.join(' ')}
        </Text>
        <Text>
          申請人：
          {props.collectData.solrResult.bibliographic.parties.applicant &&
            props.collectData.solrResult.bibliographic.parties.applicant.addressbook.name &&
            props.collectData.solrResult.bibliographic.parties.applicant.addressbook.name.join(' ')}
        </Text>
        <Text>
          {notKindA ? '公告號：' : '公開號：'}
          {documentId && documentId.docNumber + documentId.kind}
        </Text>
        <Text>
          {notKindA ? '公告日：' : '公開日：'}
          {documentId && documentId.date ? dateFormatter(documentId.date) : '無日期資訊'}
        </Text>
        <TagList>
          標籤：
          {props.collectData.patentTag.map(pat => pat.tag).join('、')}
          <TagIcon>
            <i onClick={() => setEditTag(!editTag)}>
              <FaTags />
            </i>
            {editTag && (
              <EditTagForm>
                <EditTag
                  show={editTag}
                  setShow={setEditTag}
                  patentDataId={props.collectData.patentDataId}
                  patentTags={props.collectData.patentTag.map(pat => pat.tag)}
                  submitEditTags={handleEditTags}
                />
              </EditTagForm>
            )}
          </TagIcon>
        </TagList>
      </LeftContainer>
      <RightContainer>
        {props.collectData.solrResult.img_alias && (
          <img
            src={
              process.env.REACT_APP_API_URL +
              'patent/' +
              props.collectData.patentId +
              '/img/' +
              props.collectData.solrResult.img_alias[0]
            }
            alt=""
          />
        )}
        <ActionMenu>
          <i onClick={() => setActiveItemMenu(!activeItemMenu)}>
            <FaEllipsisH />
          </i>
          {activeItemMenu && (
            <CollectActionMenu
              show={activeItemMenu}
              setShow={setActiveItemMenu}
              collectId={props.collectId}
              patentDataId={props.collectData.patentDataId}
              submit={props.submit}
            />
          )}
        </ActionMenu>
      </RightContainer>
    </Container>
  );
}

export default CollectionItem;
