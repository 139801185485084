import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';

import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import { IGetColumnistArticle } from 'src/types/api/ColumnistArticleApiTypes';
import { IMainTabSrc } from 'src/types/api/IndexApiTypes';

import INSPECT_BG from 'src/assets/images/inspect-bg.png';
import ColumnistArticleInspect from './ColumnistArticleInspect';
import ListItems from './ColumnistArticleItems';
import ExceptionGuestPanel from './ExceptionGuestPanel';
import { getMainTabGaClassName } from 'src/components/index/tabs/getGaClassName';
import { ultrawhite } from 'src/style/theme/Color';

// Layout
const Container = styled.div`
  display: flex;
`;
const LeftContainer = styled.div`
  width: 80%;
  display: flex;
`;
const RightLoadingStyle = styled.div`
  height: 348px;
  background-image: url(${INSPECT_BG});
  background-size: cover;
`;
const RightContainer = styled(RightLoadingStyle)`
  margin: -50px -8px -8px 0;
  width: 340px;
  height: 500px;
  border-radius: 4px;
  color: ${ultrawhite};
  box-shadow: rgba(38, 50, 55, 0.3) -4px 4px 20px 0px;
`;

const COLUMNIST_ARTICLE_HEADER = ['類別', '標題', '作者', '發佈時間'];

interface IProps {
  src: IMainTabSrc;
}

function ColumnistAritclePanel({ src }: IProps) {
  const [hover, setHover] = useState(0);
  const [articles, setArticles] = useState<Array<IGetColumnistArticle>>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [noData, setNoData] = useState(false);
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const fetchData = async () => {
      try {
        const res = await Api().get(src.apiUrl, { cancelToken: source.token });
        if (res.data.length > 0) {
          setArticles(res.data);
          setNoData(false);
        } else {
          setNoData(true);
        }
        setError(false);
      } catch (error) {
        setError(true);
        errorHandler(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    return () => source.cancel();
  }, [src]);

  const switchContent = () => {
    if (loading || error || noData) {
      return (
        <ExceptionGuestPanel
          tab={src.tab}
          header={COLUMNIST_ARTICLE_HEADER}
          error={error}
          noData={noData}
          isLoading={loading}
        />
      );
    } else if (articles && articles.length > 0) {
      const gaClass = getMainTabGaClassName(src.tab);
      return (
        <>
          <LeftContainer>
            <ListItems
              header={COLUMNIST_ARTICLE_HEADER}
              articles={articles}
              hover={hover}
              setHover={setHover}
              loading={loading}
              gaClass={gaClass}
              readMoreUrl={src.readMoreUrl}
            />
          </LeftContainer>
          <RightContainer>
            <ColumnistArticleInspect article={articles[hover]} gaClass={gaClass} />
          </RightContainer>
        </>
      );
    }
  };

  return <Container>{switchContent()}</Container>;
}

export default ColumnistAritclePanel;
