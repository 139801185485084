import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { FaBuilding, FaIdCard, FaProjectDiagram, FaTags } from 'react-icons/fa';

import { Api, baseURL } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import { millisFormatter } from 'src/utils/Formatter';
import {
  IExpert,
  IExpertKnowledgeGraph,
  IKnowledgeGraphItem,
  IKnowledgeGraphRelatedExpert,
} from 'src/types/ExpertTypes';

import ExpandableBox from './ExpandableBox';
import OverflowAwareBox from './OverflowAwareBox';
import { Spinner } from 'src/components/ui/interactive/Spinner';
import MiniLoader from 'src/components/ui/interactive/MiniLoader';
import EXPERT_AVATAR from 'src/assets/images/expert-avatar.png';
import {
  Header,
  Avatar,
  HeaderInfo,
  Name,
  UpdateTime,
  FieldName,
  FieldValueList,
  NoResult,
  Dot,
  CardContent,
  CardContentNote,
  KnowledgeGraphCard,
  CardHeader,
  CardIcon,
  CardTitle,
  ExpertCompareTable,
  Th,
  Td,
} from 'src/style/ExpertStyle';

interface IProps {
  id: string;
}

/** 專家比較 - 知識圖譜資訊比較 */
const ExpertDetailCompare: React.FC<RouteComponentProps<IProps>> = props => {
  const { location } = props;
  const [relatedExperts] = useState<IKnowledgeGraphRelatedExpert[]>(location.state.relatedExperts);
  const [expertId] = useState<number>(location.state.expertId);
  const [experts, setExperts] = useState<Array<IExpert>>([]);
  const [knowledgeGraphs, setKnowledgeGraphs] = useState<Map<number, IExpertKnowledgeGraph>>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingGraph, setIsLoadingGraph] = useState<boolean>(false);

  useEffect(() => {
    const ids = [+expertId];
    relatedExperts.forEach(expert => expert.id && ids.push(expert.id));

    const fetchExpertData = async () => {
      setIsLoading(true);
      try {
        const responses = await Promise.all(ids.map(id => Api().get(`expert/${id}`)));
        setExperts(responses.map(res => res && res.data));
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchKnowledgeGraphData = async () => {
      setIsLoadingGraph(true);
      try {
        const temp: Map<number, IExpertKnowledgeGraph> = new Map();
        await Promise.all(
          ids.map(id =>
            Api()
              .get(`expert/${id}/knowledge/graph`)
              .then(res => temp.set(id, res.data)),
          ),
        ).then(() => setKnowledgeGraphs(temp));
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsLoadingGraph(false);
      }
    };

    fetchExpertData();
    fetchKnowledgeGraphData();
  }, [expertId, relatedExperts]);

  const getCardContent = (list: Array<IKnowledgeGraphItem> | undefined, isKeyword: boolean) =>
    isLoadingGraph ? (
      <MiniLoader />
    ) : list ? (
      list.map(item => (
        <CardContent key={item.name}>
          {isKeyword ? <></> : <Dot />}
          {item.note && item.note !== null ? (
            <OverflowAwareBox
              lineElement={
                <>
                  <CardContentNote>{item.note}</CardContentNote>
                  <span>{item.name}</span>
                </>
              }
            />
          ) : (
            <span>{item.name}</span>
          )}
        </CardContent>
      ))
    ) : (
      <NoResult>無法取得資料</NoResult>
    );
  return isLoading && isLoadingGraph ? (
    <Spinner width="200px" margin="150px auto" />
  ) : experts.length !== 0 ? (
    <ExpertCompareTable>
      <thead>
        <tr>
          {experts.map(expert => (
            <Th numOfCol={experts.length} key={expert.id}>
              <Header>
                <Avatar size={72}>
                  <img
                    src={
                      expert.photo && expert.photo !== ''
                        ? `${baseURL}expert/${expert.id}/photo`
                        : EXPERT_AVATAR
                    }
                    alt={expert.name}
                  />
                </Avatar>
                <HeaderInfo>
                  <Name>{expert.name}</Name>
                  <br />
                  <UpdateTime>更新：{millisFormatter(expert.updateTime)}</UpdateTime>
                </HeaderInfo>
              </Header>
            </Th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          {experts.map(expert => (
            <Td key={expert.id}>
              <FieldName>信箱 ：</FieldName>
              <span>{expert.email}</span>
            </Td>
          ))}
        </tr>
        <tr>
          {experts.map(expert => (
            <Td key={expert.id}>
              <FieldName>電話 ：</FieldName>
              <span>{expert.phone}</span>
            </Td>
          ))}
        </tr>
        <tr>
          {experts.map(expert => (
            <Td key={expert.id}>
              <FieldName>專長 ：</FieldName>
              <ExpandableBox>
                <span>{expert.expertise}</span>
              </ExpandableBox>
            </Td>
          ))}
        </tr>
        <tr>
          {experts.map(expert => {
            const job = expert
              ? (expert.jobUnit ? expert.jobUnit + ' ' : '') +
                (expert.jobDepartment ? expert.jobDepartment + ' ' : '') +
                (expert.jobTitle ? expert.jobTitle : '')
              : undefined;
            return (
              <Td key={expert.id}>
                <FieldName>現職 ：</FieldName>
                <ExpandableBox>
                  <span>{job}</span>
                </ExpandableBox>
              </Td>
            );
          })}
        </tr>
        <tr>
          {experts.map(expert => (
            <Td key={expert.id}>
              <FieldName>經歷 ：</FieldName>
              <FieldValueList>
                <ExpandableBox>
                  {expert.experiences &&
                    expert.experiences.map((item, idx) => (
                      <div key={idx}>
                        {item.startYear && item.endYear && (
                          <span>
                            {item.startYear} - {item.endYear}&nbsp;
                          </span>
                        )}
                        {item.title}
                      </div>
                    ))}
                </ExpandableBox>
              </FieldValueList>
            </Td>
          ))}
        </tr>
        <tr>
          {experts.map(expert => (
            <Td key={expert.id}>
              <FieldName>學歷 ：</FieldName>
              <FieldValueList>
                <ExpandableBox>
                  {expert.educations &&
                    expert.educations.map((item, idx) => (
                      <div key={idx}>
                        {item.graduateYear && item.graduateYear + '年 畢業於'}&nbsp;{item.title}
                      </div>
                    ))}
                </ExpandableBox>
              </FieldValueList>
            </Td>
          ))}
        </tr>
        <tr>
          {experts.map(expert => (
            <Td key={expert.id}>
              <FieldName>著作 ：</FieldName>
              <FieldValueList>
                <ExpandableBox>
                  {expert.publications &&
                    expert.publications.map((item, idx) => <div key={idx}>{item.title}</div>)}
                </ExpandableBox>
              </FieldValueList>
            </Td>
          ))}
        </tr>
        <tr>
          {experts.map(expert => (
            <Td key={expert.id}>
              <FieldName>論文 ：</FieldName>
              <FieldValueList>
                <ExpandableBox>
                  {expert.theses &&
                    expert.theses.map((item, idx) => <div key={idx}>{item.title}</div>)}
                </ExpandableBox>
              </FieldValueList>
            </Td>
          ))}
        </tr>
        <tr>
          {experts.map(expert => {
            const graph = knowledgeGraphs && knowledgeGraphs.get(expert.id);
            return (
              <Td key={expert.id} padding={'4px'}>
                <KnowledgeGraphCard>
                  <CardHeader>
                    <CardIcon>
                      <FaBuilding />
                    </CardIcon>
                    <CardTitle>關聯機構</CardTitle>
                  </CardHeader>
                  {getCardContent(graph && graph.organizations, false)}
                </KnowledgeGraphCard>
              </Td>
            );
          })}
        </tr>
        <tr>
          {experts.map(expert => {
            const graph = knowledgeGraphs && knowledgeGraphs.get(expert.id);
            return (
              <Td key={expert.id} padding={'4px'}>
                <KnowledgeGraphCard>
                  <CardHeader>
                    <CardIcon>
                      <FaProjectDiagram />
                    </CardIcon>
                    <CardTitle>行業領域</CardTitle>
                  </CardHeader>
                  {getCardContent(graph && graph.industrialClasses, false)}
                </KnowledgeGraphCard>
              </Td>
            );
          })}
        </tr>
        <tr>
          {experts.map(expert => {
            const graph = knowledgeGraphs && knowledgeGraphs.get(expert.id);
            return (
              <Td key={expert.id} padding={'4px'}>
                <KnowledgeGraphCard>
                  <CardHeader>
                    <CardIcon>
                      <FaTags />
                    </CardIcon>
                    <CardTitle>關鍵字</CardTitle>
                  </CardHeader>
                  {getCardContent(graph && graph.keywords, false)}
                </KnowledgeGraphCard>
              </Td>
            );
          })}
        </tr>
        <tr>
          {experts.map(expert => {
            const graph = knowledgeGraphs && knowledgeGraphs.get(expert.id);
            return (
              <Td key={expert.id} padding={'4px'}>
                <KnowledgeGraphCard>
                  <CardHeader>
                    <CardIcon>
                      <FaIdCard />
                    </CardIcon>
                    <CardTitle>國際分類號/IPC</CardTitle>
                  </CardHeader>
                  {getCardContent(graph && graph.ipcs, false)}
                </KnowledgeGraphCard>
              </Td>
            );
          })}
        </tr>
      </tbody>
    </ExpertCompareTable>
  ) : (
    <></>
  );
};

export default ExpertDetailCompare;
