import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components';
import { FaTimes } from 'react-icons/fa';

import { Backdrop } from 'src/style/ModalStyle';
import { iceblue1, iceblue5, iceblue7 } from 'src/style/theme/Color';
import { zModal } from 'src/style/theme/Z-Index';
import { IIndustrialClassType } from 'src/types/IndustryTypes';
import Menu from 'src/components/trend/industrialClass/ui/Menu';

const Container = styled.div`
  position: relative;
  border-radius: 4px 4px 0 0;
  background-color: #ffffff;
  width: 70%;
  max-width: 1024px;
  margin-bottom: 450px;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 32px;
  svg {
    font-size: 20px;
    margin-left: 24px;
    cursor: pointer;
  }
`;

const Title = styled.div`
  color: ${iceblue1};
  font-size: 20px;
  font-weight: bold;
`;

const menuStyle = css`
  box-shadow: none;
  margin: 0;
  border-top: 1px solid ${iceblue7};
  div:first-child div {
    border-radius: 0;
  }
`;

interface IProps {
  checkedItems: Array<IIndustrialClassType>;
  onSubmit: (items: Array<IIndustrialClassType>) => void;
  onClose: () => void;
}

const modal = document.getElementById('modal-custom');
const portalDiv = modal ? modal : document.createElement('div');

const IndustrialTrendsBarChartModal: React.FC<IProps> = ({ checkedItems, onSubmit, onClose }) => {
  const [newCheckedItems, setNewCheckedItems] = useState<IIndustrialClassType[]>(checkedItems);

  return ReactDOM.createPortal(
    <Backdrop zIndex={zModal.custom} alignItems="center">
      <Container>
        <Header>
          <Title>請選擇行業別偏好</Title>
          <FaTimes color={iceblue5} onClick={() => onClose()} />
        </Header>
        <Menu
          checkedItems={newCheckedItems}
          setCheckedItems={setNewCheckedItems}
          onSubmit={() => {
            onSubmit([...newCheckedItems]);
            onClose();
          }}
          onReset={onClose}
          css={menuStyle}
        />
      </Container>
    </Backdrop>,
    portalDiv,
  );
};

export default IndustrialTrendsBarChartModal;
