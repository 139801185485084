import * as d3 from 'd3';

/**
 * @description
 * 參考網路上的文字斷行作法，並改為依字元數斷行
 * - https://bl.ocks.org/mbostock/7555321
 * - https://github.com/plouc/nivo/issues/353
 * @param texts 帶有文字的 d3 Selection 物件
 * @param width 物件寬度(px), 超過寬度時換行
 * @param patternSize 判斷是否斷行的最小單位字數, 預設5個字為一組
 */
export function wrap(
  texts: d3.Selection<d3.BaseType, any, any, any>,
  width: number,
  patternSize?: number,
) {
  texts.each(function(this: d3.BaseType) {
    const size = patternSize !== undefined ? patternSize : 5;
    const pattern = new RegExp('(.{1,' + size + '}W)|(.{1,' + size + '})', 'gm');

    const text = d3.select(this),
      words = (text.text().match(pattern) || []).reverse(),
      lineHeight = 1.2, // ems
      y = text.attr('y') || '0',
      dy = parseFloat(text.attr('dy') || '0');

    let word,
      line: string[] = [],
      lineNumber = 0,
      tspan = text
        .text(null)
        .append('tspan')
        .attr('x', 0)
        .attr('y', y)
        .attr('dy', dy + 'em');

    while ((word = words.pop())) {
      line.push(word);
      tspan.text(line.join(''));

      const tspanNode = tspan.node();
      if (tspanNode && tspanNode.getComputedTextLength() > width) {
        line.pop();
        tspan.text(line.join(''));
        line = [word];
        tspan = text
          .append('tspan')
          .attr('x', 0)
          .attr('y', y)
          .attr('dy', ++lineNumber * lineHeight + dy + 'em')
          .text(word);
      }
    }
  });
}

/**
 * 字串過長取代為省略號(...)
 * - https://www.rgagnon.com/javadetails/java-ellipse-a-string.html
 */
export function toEllipsis(text: string, maxCharacters: number, charactersAfterEllipse: number) {
  const ELLIPSE = '...';

  if (text == null || text.length <= maxCharacters) {
    return text;
  }
  return (
    text.substring(0, maxCharacters - charactersAfterEllipse) +
    ELLIPSE +
    text.substring(text.length - charactersAfterEllipse)
  );
}
