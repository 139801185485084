import React from 'react';
import styled from 'styled-components';

import { IGetFilm } from 'src/types/api/FilmApiTypes';
import { blue2, iceblue6 } from 'src/style/theme/Color';

const Container = styled.div`
  box-shadow: 0 12px 50px 0 rgba(170, 170, 170, 0.2);
  margin-bottom: 16px;
  :hover {
    background-color: #f5fdfe;
  }
`;

const ImageBox = styled.div`
  img {
    width: 100%;
  }
`;

const Body = styled.div`
  padding: 16px;
`;

const Title = styled.a`
  padding: 2px 0;
  margin-bottom: 8px;
  font-size: 16px;
  color: ${blue2};
  cursor: pointer;
`;

const Text = styled.div`
  font-size: 14px;
  color: ${iceblue6};
`;

const handleArticleAncherOnClick = (url: string) => {
  window.open(url);
};

interface IProps {
  columnData: IGetFilm;
}

const MobileVideoCard = ({ columnData }: IProps) => (
  <Container>
    <ImageBox>
      {columnData && columnData.pic && (
        <img
          src={columnData.pic}
          alt="no pic"
          onClick={() => handleArticleAncherOnClick(columnData.url)}
        />
      )}
    </ImageBox>
    <Body>
      <Title onClick={() => handleArticleAncherOnClick(columnData.url)}>{columnData.title}</Title>
      <Text>
        <span onClick={e => handleArticleAncherOnClick(columnData.channelUrl)}>
          {columnData.channelName}
        </span>
        <span>&nbsp;&nbsp;|&nbsp;&nbsp;{columnData.createTime}</span>
      </Text>
    </Body>
  </Container>
);

export default MobileVideoCard;
