import React from 'react';
import styled from 'styled-components';
import remove_duplication from 'src/assets/images/manual/search-result-analysis/remove_duplication.png';
import bilingual_vocabulary from 'src/assets/images/manual/search-result-analysis/bilingual_vocabulary.png';
import patent_type from 'src/assets/images/manual/search-result-analysis/patent_type.png';
import international_classification_number from 'src/assets/images/manual/search-result-analysis/international_classification_number.png';
import inventor_applicant_publication from 'src/assets/images/manual/search-result-analysis/inventor_applicant_publication.png';

const ImgContainer = styled.div<{ height: string; imageUrl: string }>`
  position: relative;
  height: ${props => props.height}px;
  padding: 10px 0;
  background-image: url(${props => props.imageUrl});
  background-size: contain;
  background-repeat: no-repeat;
`;
export default function SearchResultAnalysisManual() {
  return (
    <>
      <p>以下為篩選條件</p>
      <ol>
        <li>檢索去重：檢索結果同一筆案件有公開/公告案時，點選「檢索去重」將留存公告案專利。</li>
        <br />
        <ImgContainer height="500" imageUrl={remove_duplication} />
        <li>雙語詞彙：檢索結果之關鍵字有對照之雙語詞彙，點選該詞彙進行檢索。</li>
        <br />
        <ImgContainer height="400" imageUrl={bilingual_vocabulary} />
        <li>
          專利種類：檢索結果中有各專利類型，點選發明公開、發明公告、新型公告、設計公告將留存該專利類型。
        </li>
        <br />
        <ImgContainer height="500" imageUrl={patent_type} />
        <li>國際分類號：檢索結果中分布國際專利分類號(3階)，點選可限縮屬該國際分類號之專利。</li>
        <br />
        <ImgContainer height="500" imageUrl={international_classification_number} />
        <li>發明人：檢索結果中分布之發明人，點選可限縮該發明人之專利。</li>
        <li>申請人：檢索結果中分布之申請人，點選可限縮該申請人之專利。</li>
        <li>公開日：檢索結果中分布之專利公開日年度，點選可限縮該公開年度之專利。</li>
        <br />
        <ImgContainer height="500" imageUrl={inventor_applicant_publication} />
      </ol>
    </>
  );
}
