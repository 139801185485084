import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';

import { handleRoute } from 'src/routes/routeUtils';
import { Feature } from 'src/style/SideMenuStyle';

interface IProps extends RouteComponentProps {
  item: { feature: string; path?: string };
}

const navItem: React.FC<IProps> = props => {
  const itemPath = `${props.match.url}${props.item.path}`;
  let result;

  if (props.item.path) {
    result = (
      <Link to={itemPath} onClick={e => e.preventDefault()}>
        <Feature
          active={props.location.pathname === itemPath}
          onClick={() => handleRoute(itemPath, props.location, props.history)}
        >
          {props.item.feature}
        </Feature>
      </Link>
    );
  } else {
    result = <></>;
  }

  return result;
};

export default navItem;
