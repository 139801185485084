import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Api, baseURL } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import {
  blue2,
  blue3,
  blue5,
  blue7,
  blue8,
  iceblue1,
  iceblue3,
  iceblue3rgba,
  iceblue5,
  iceblue5rgba,
  iceblue8,
} from 'src/style/theme/Color';
import ResultHighlighter from 'src/components/result/ResultHighlighter';
import { IGetPatentTypes } from 'src/types/IGetPatentTypes';
import { connect } from 'react-redux';
import { fetchPatentContent } from 'src/redux/actions/patentContentAction';
import { dateFormatter } from 'src/utils/Formatter';
import { PatentService } from 'src/api/Patent';
import { IGetPtabTypes } from 'src/types/api/PatentApiTypes';

const Container = styled.div<{ isPreviewMode: boolean; isPreviewing: boolean }>`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${iceblue8};
  padding: 16px 32px;
  background-color: ${props => props.isPreviewing && blue7};
  :hover {
    background-color: ${blue8};
    cursor: ${props => props.isPreviewMode && 'pointer'};
  }
`;

const LeftContent = styled.div<{ hasTranslation: boolean }>`
  width: ${props => (props.hasTranslation ? '64%' : '70%')};
`;

const PatentTitleBox = styled.div`
  max-width: 90%;
  margin-bottom: 16px;
  font-weight: 500;
  color: ${blue2};
  :hover {
    cursor: pointer;
  }

  .highlighter,
  .unhighlight,
  .highlight {
    font-size: 18px;
  }
`;

const PatentClaimTextBox = styled.div`
  color: ${iceblue1};
  margin-bottom: 8px;
`;

const PatentRemainInfoBox = styled.div`
  max-width: 90%;
  color: ${iceblue5};
  margin-bottom: 16px;
  div {
    display: flex;
    margin-bottom: 2px;
  }
`;

const PatentRemainInfoFieldName = styled.span`
  min-width: 60px;
  margin-right: 4px;
`;

const TranslatedText = styled.div`
  display: inline-block;
  padding: 4px 8px;
  margin-top: 8px;
  color: ${iceblue3};
  background-color: ${iceblue8};
  border-radius: 4px;
`;

const RightContent = styled.div`
  width: 25%;
`;

const ImageBox = styled.div`
  img {
    width: 100%;
    max-height: 200px;
  }
`;
const Ptab = styled.div`
  padding: 20px 20px;
  border-radius: 4px;
  color: #077790;
  background-color: #edf6f7;
  width: 1000px;
  div {
    display: block;
    margin-bottom: 2px;
  }
  a {
    color: #077790;
  }
`;
const TranslateButtonContainer = styled.div<{ isDisabled: boolean }>`
  min-width: 42px;
  button {
    padding: 4px 8px;
    background: none;
    color: ${props => (props.isDisabled ? iceblue3rgba(0.5) : blue3)};
    border: 1px solid ${props => (props.isDisabled ? iceblue5rgba(0.5) : blue5)};
    border-radius: 4px;
    text-align: center;
    font-size: 13px;
    :hover {
      cursor: ${props => (props.isDisabled ? 'auto' : 'pointer')};
    }
    :focus {
      outline: none;
    }
  }
`;

interface ITranslations {
  title: string;
  abstractText: string;
}

interface IReduxMappingProps {
  fetchPatentContent: (uuid: string) => void;
}

interface IProps extends RouteComponentProps, IReduxMappingProps {
  isPreviewMode: boolean;
  isPreviewing: boolean;
  patent: IGetPatentTypes;
  highlightWords: string[];
  ptab?: string;
}

const patentService = PatentService();

/** 單筆查詢結果-列表模式 */
const ResultListItem: React.FC<IProps> = props => {
  const [isTranslated, setIsTranslated] = useState<boolean>(false);
  const [translations, setTranslations] = useState<ITranslations>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { patent, isPreviewMode, isPreviewing } = props;
  const hasTranslation = patent.country !== 'TW';
  const [ptabName, setPtabName] = useState<IGetPtabTypes[] | null>(null);

  // 專利相關資訊
  const title = patent.bibliographic.inventionTitle.title;
  const abstractText = patent.inventionSpecification && patent.inventionSpecification.abstract.text;
  const inventorNames =
    patent.bibliographic.parties &&
    patent.bibliographic.parties.inventor &&
    patent.bibliographic.parties.inventor.addressbook.name;
  const applicantNames =
    patent.bibliographic.parties &&
    patent.bibliographic.parties.applicant &&
    patent.bibliographic.parties.applicant.addressbook.name;
  const documentId =
    patent.bibliographic.publicationReference &&
    patent.bibliographic.publicationReference.documentId;

  const notKindA =
    documentId.kind &&
    !documentId.kind.includes('A') &&
    !documentId.kind.includes('P1') &&
    !documentId.kind.includes('P4') &&
    !documentId.kind.includes('P9');

  const applicationId =
    patent.bibliographic.applicationReference &&
    patent.bibliographic.applicationReference.documentId;

  // 取得美國專利上訴文件
  useEffect(() => {
    const ptab = props.ptab;
    const handlePtab = () => {
      if (ptab && patent && applicationId && applicationId.docNumber) {
        patentService.getPtab(applicationId.docNumber).then(data => {
          if (data && data.length > 0) {
            const ptab: IGetPtabTypes[] = [];
            data.forEach(ptabDoc => {
              ptab.push(ptabDoc);
            });
            setPtabName(ptab);
          }
        });
      }
    };
    handlePtab();
  }, [isLoading, applicationId, patent, props.ptab]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Api().post('patent/search/translate', {
          title: title && title.join(' | '),
          abstractText: abstractText && abstractText.join(' '),
        });

        setTranslations(response.data);
        setIsTranslated(prevIsTranslated => !prevIsTranslated);
        setIsLoading(false);
      } catch (error) {
        errorHandler(error);
        setIsLoading(false);
      }
    };

    isLoading && fetchData();
  }, [isLoading, title, abstractText]);

  const handleTranslationClick = (e: any) => {
    // 阻止事件繼續冒泡。避免雙欄瀏覽下，點翻譯觸發查資料。
    e.stopPropagation();

    // 1. translations無資料 => 沒有點過翻譯功能或查詢錯誤
    // 2. translations有資料 => 有標題但無標題翻譯/有摘要但無摘要翻譯(表示因某種原因造成資料不完整)。
    // 上述兩種情況且未翻譯狀態下，需發送API查詢。
    if (
      !isTranslated &&
      (!translations ||
        (title && translations.title === '') ||
        (abstractText && translations.abstractText === ''))
    ) {
      setIsLoading(true);
    } else {
      setIsTranslated(!isTranslated);
    }
  };

  const handleRedirect = () => {
    !isPreviewMode && window.open(`/patent/${patent.id}/view`);
  };

  return (
    <Container
      isPreviewMode={isPreviewMode}
      isPreviewing={isPreviewing}
      onClick={() => isPreviewMode && props.fetchPatentContent(patent.id)}
    >
      <LeftContent hasTranslation={hasTranslation}>
        <PatentTitleBox onClick={() => handleRedirect()}>
          <ResultHighlighter
            searchWords={props.highlightWords}
            textToHighlight={title ? title.join(' | ') : ''}
          />
          {isTranslated && translations && translations.title !== '' && (
            <div>
              <TranslatedText>{translations.title}</TranslatedText>
            </div>
          )}
        </PatentTitleBox>
        <PatentClaimTextBox>
          <ResultHighlighter
            searchWords={props.highlightWords}
            textToHighlight={abstractText ? abstractText.join(' ') : ''}
          />
          {isTranslated && translations && translations.abstractText !== '' && (
            <div>
              <TranslatedText>{translations.abstractText}</TranslatedText>
            </div>
          )}
        </PatentClaimTextBox>
        <PatentRemainInfoBox>
          <div>
            <PatentRemainInfoFieldName>發明人 :</PatentRemainInfoFieldName>
            <div>
              <ResultHighlighter
                searchWords={props.highlightWords}
                textToHighlight={inventorNames ? inventorNames.join(', ') : ''}
              />
            </div>
          </div>
          <div>
            <PatentRemainInfoFieldName>申請人 :</PatentRemainInfoFieldName>
            <div>
              <ResultHighlighter
                searchWords={props.highlightWords}
                textToHighlight={applicantNames ? applicantNames.join(', ') : ''}
              />
            </div>
          </div>
          <div>
            <PatentRemainInfoFieldName>
              {notKindA ? '公告號：' : '公開號：'}
            </PatentRemainInfoFieldName>
            <div>{documentId && documentId.docNumber + documentId.kind}</div>
          </div>
          <div>
            <PatentRemainInfoFieldName>
              {notKindA ? '公告日：' : '公開日：'}
            </PatentRemainInfoFieldName>
            <div>
              {documentId && documentId.date ? dateFormatter(documentId.date) : '無日期資訊'}
            </div>
          </div>
          <div>
            <PatentRemainInfoFieldName>國&emsp;別 :</PatentRemainInfoFieldName>
            <div>{patent.country}</div>
          </div>

          {ptabName && (
            <div>
              <Ptab>
                <div>
                  <PatentClaimTextBox>美國專利審查與上訴委員會資料 (PTAB)</PatentClaimTextBox>
                  {ptabName &&
                    ptabName.map(p =>
                      p.downloaded ? (
                        <>
                          <a href={baseURL + 'ptab/' + p.ptabDocNumber + '/file'}>{p.docName}</a>
                          <br />
                        </>
                      ) : (
                        <>
                          <>{p.docName}</>
                          <br />
                        </>
                      ),
                    )}
                </div>
              </Ptab>
            </div>
          )}
        </PatentRemainInfoBox>
      </LeftContent>
      <RightContent>
        {patent.img_alias && patent.img_alias.length > 0 && (
          <ImageBox>
            <img src={`${baseURL}patent/${patent.id}/img/${patent.img_alias[0]}`} alt="" />
          </ImageBox>
        )}
      </RightContent>
      {hasTranslation && (
        <TranslateButtonContainer isDisabled={isLoading}>
          <button onClick={e => handleTranslationClick(e)} disabled={isLoading}>
            {isTranslated ? '原文' : '翻譯'}
          </button>
        </TranslateButtonContainer>
      )}
    </Container>
  );
};

const mapDispatchToProps = {
  fetchPatentContent,
};

export default connect(
  null,
  mapDispatchToProps,
)(withRouter(ResultListItem));
