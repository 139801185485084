import React from 'react';
import styled from 'styled-components';
import two_column_compare from 'src/assets/images/manual/patent-assisted-read/two_column_compare.png';

const ImgContainer = styled.div<{ height: string; imageUrl: string }>`
  position: relative;
  height: ${props => props.height}px;
  padding: 10px 0;
  background-image: url(${props => props.imageUrl});
  background-size: contain;
  background-repeat: no-repeat;
`;
export default function PatentAssistedReadManual() {
  return (
    <>
      <p>開啟雙欄比對，點選相似專利或是輸入申請案號，顯示專利內容，可同時瀏覽2筆專利。</p>
      <br />
      <ImgContainer height="350" imageUrl={two_column_compare} />
    </>
  );
}
