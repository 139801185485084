const nonEmptyRule = (value: string, inputMethod: string, testField: string) => {
  let errorMessage;
  if (value === '') {
    errorMessage = `請${inputMethod}${testField}`;
  }
  return errorMessage;
};
export const nonEmptyAccountValidator = (value: string) => nonEmptyRule(value, '輸入', '帳號');
export const nonEmptyPwdValidator = (value: string) => nonEmptyRule(value, '輸入', '密碼');
export const nonEmptyCaptchaValidator = (value: string) => nonEmptyRule(value, '輸入', '驗證碼');

export const accountValidator = (value: string) => {
  let errorMessage;
  if (value.trim() === '') {
    errorMessage = '帳號不得為空';
  } else if (!/^(?=.*[a-z])(?=.*[A-Z])[a-zA-Z\d._]{6,32}$/i.test(value)) {
    errorMessage = '無效的帳號';
  }
  return errorMessage;
};

export const pwdValidator = (value: string, acceptNull: boolean) => {
  let errorMessage;
  if (
    !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d()[\]{}&lt;&gt;+-\\*/?,.:;&quot;'_\\|~`!@#$%^&=]{8,32}$/.test(
      value,
    )
  ) {
    errorMessage = '無效的密碼, 請輸入8到32位包含英文大小寫字母，以及數字';

    if (value.trim().length !== value.length) {
      errorMessage = '頭尾不得為空白';
    } else if (value === '') {
      if (acceptNull) {
        errorMessage = undefined;
      } else {
        errorMessage = '請輸入密碼';
      }
    }
  }

  return errorMessage;
};

export const nameValidator = (value: string) => {
  let errorMessage;
  if (!/^.{1,400}$/.test(value)) {
    errorMessage = '無效的名稱';
  }
  return errorMessage;
};

export const phoneValidator = (value: string) => {
  let errorMessage;
  // 可能會變動, 詳查電信網路編碼計畫
  if (!/^[0-9-#]{8,20}$/.test(value)) {
    errorMessage = '無效的電話, 請輸入數字及「-」「#」, 8-20字元';
  }
  return errorMessage;
};

export const mailValidator = (value: string) => {
  let errorMessage;
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    errorMessage = '無效的電子郵件';
  }
  return errorMessage;
};

export const industrialClassValidator = (value: Array<any>) => {
  let errorMessage;
  if (value.length === 0) {
    errorMessage = '請選擇至少一項行業別';
  }
  return errorMessage;
};

export const describeValidator = (value: string) => {
  let errorMessage;
  if (!value || value.length === 0 || value.trim() === '') {
    errorMessage = '請描述問題';
  }
  return errorMessage;
};

const dateValidator = (value: Date | null, type: string) => {
  let errorMessage;
  if (!value) {
    errorMessage = `請輸入${type}日期`;
  }
  return errorMessage;
};
export const startDateValidator = (value: Date | null) => dateValidator(value, '起始');
export const endDateValidator = (value: Date | null) => dateValidator(value, '結束');
export const nonEmptySourceValidator = (value: string) => nonEmptyRule(value, '選擇', '資料來源');
export const nonEmptyPubNumValidator = (value: string) =>
  nonEmptyRule(value, '輸入', '案件公開/公告號');

export const yearValidator = (value: string) => {
  let errorMessage;
  if (value && !/^[\d]{4}$/.test(value)) {
    errorMessage = `請輸入四位數字`;
  }
  return errorMessage;
};
