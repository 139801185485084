import {
  FETCH_INDUSTRIAL_CLASS_START,
  FETCH_INDUSTRIAL_CLASS_SUCCESS,
  FETCH_INDUSTRIAL_CLASS_ERROR,
  FetchIndustrialClassState,
  FetchIndustrialClassActionTypes,
} from 'src/redux/types/industrialClassTypes';
import { IIndustrialClassType } from 'src/types/IndustryTypes';

const initialState: FetchIndustrialClassState = {
  isLoading: false,
  industrialClassList: [],
};

const industrialClassReducer = (
  state = initialState,
  action: FetchIndustrialClassActionTypes,
): FetchIndustrialClassState => {
  switch (action.type) {
    case FETCH_INDUSTRIAL_CLASS_START: {
      return { ...state, isLoading: true };
    }
    case FETCH_INDUSTRIAL_CLASS_SUCCESS: {
      let { data, status } = action.response;
      let industrialClassList: Array<IIndustrialClassType> = data;
      return status === 200
        ? { ...state, industrialClassList, isLoading: false }
        : { ...initialState };
    }
    case FETCH_INDUSTRIAL_CLASS_ERROR:
      return { ...initialState };
    default:
      return { ...state };
  }
};

export default industrialClassReducer;
