import React from 'react';
import styled from 'styled-components';

import { blue7, blue8, iceblue7 } from 'src/style/theme/Color';
import { Carousel } from 'src/style/ResultGridItemStyle';
import BasicCarouselContent from './grid-item/BasicCarouselContent';
import OverlayCarousel from './grid-item/OverlayCarousel';
import { TmApplMetadata } from 'src/types/TrademarkTypes';
import { FeedbackType } from 'src/api/Trademark';

const Container = styled.div<{ isPreviewMode: boolean; isPreviewing: boolean }>`
  position: relative;
  border-radius: 4px;
  border: solid 1px ${iceblue7};
  background-color: ${props => props.isPreviewing && blue7};
  :hover {
    background-color: ${blue8};
    cursor: ${props => props.isPreviewMode && 'pointer'};
  }
`;

export interface IOverlayDetail {
  title: string;
  content: string;
}

export interface IOverlay {
  onClickToPreview?: () => void;
  detailPageUrl?: string;
  details: Array<IOverlayDetail>;
}

interface IProps extends IOverlay {
  isPreviewMode: boolean;
  isPreviewing: boolean;
  onClickTitle?: () => void;
  onClickContainer?: () => void;
  handleFeedbackCheck: (caseNo: string, feedbackType: FeedbackType) => void;
  title: string;
  metadata: TmApplMetadata;
  caseNo: string;
  imagePaths: Array<string>;
  listIndex?: number;
  selectedType: FeedbackType;
}

/** 單筆查詢結果-圖片模式 */
const ResultGridItem: React.FC<IProps> = ({
  details,
  detailPageUrl,
  title,
  metadata,
  caseNo,
  listIndex,
  onClickTitle,
  onClickContainer,
  onClickToPreview,
  handleFeedbackCheck,
  imagePaths,
  isPreviewMode,
  isPreviewing,
  selectedType,
}) => {
  return (
    <Container
      isPreviewMode={isPreviewMode}
      isPreviewing={isPreviewing}
      onClick={() => isPreviewMode && onClickContainer && onClickContainer()}
    >
      {imagePaths &&
        imagePaths.length > 0 &&
        (details && details.length > 0 ? (
          <OverlayCarousel
            caseNo={caseNo}
            imagePaths={imagePaths}
            details={details}
            onClickToPreview={onClickToPreview}
            detailPageUrl={detailPageUrl}
            metadata={metadata}
            listIndex={listIndex}
            handleFeedbackCheck={handleFeedbackCheck}
            selectedType={selectedType}
          />
        ) : (
          <Carousel>
            <BasicCarouselContent imagePaths={imagePaths} />
          </Carousel>
        ))}
    </Container>
  );
};

export default ResultGridItem;
