import React from 'react';
import { FaStar } from 'react-icons/fa';
import styled from 'styled-components';

const Color = styled.div`
  &:hover > span {
    color: #ffa500;
  }
  > span:hover ~ span {
    color: rgba(245, 253, 254, 0.3);
  }
`;

const StarSpan = styled.span<{ light: boolean }>`
  color: ${props => (props.light ? '#ffa500' : 'rgba(245,253,254,0.3)')};
  cursor: pointer;
`;

const StarsRating: React.FC<{
  stars: number;
  setRatingStars: (rating: number) => void;
}> = props => {
  const number_array = Array.from(Array(5).keys());

  return (
    <Color>
      {number_array.map(i =>
        i < props.stars ? (
          <StarSpan key={`star${i}`} light={true} onClick={() => props.setRatingStars(i + 1)}>
            <FaStar />
          </StarSpan>
        ) : (
          <StarSpan key={`star${i}`} light={false} onClick={() => props.setRatingStars(i + 1)}>
            <FaStar />
          </StarSpan>
        ),
      )}
    </Color>
  );
};

export default StarsRating;
