import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa';

import Content from 'src/components/ui/content/Content';
import withShortcut from 'src/components/search/withShortcut/withShortcut';

import { connect } from 'react-redux';
import { Auth } from 'src/apps/auth/Auth';
import { IIndustrialClassType } from 'src/types/IndustryTypes';
import { composeBasicRequest } from 'src/redux/actions/patentSearchAction';
import { IBasicRequest } from 'src/types/PatentSearchTypes';
import { alertMessage } from 'src/utils/ModalUtils';
import { toDropdownItem } from 'src/utils/IndustryUtils';
import { SystemMessage } from 'src/apps/applicationMessages';
import { blue2, iceblue6 } from 'src/style/theme/Color';
import SearchSuggestion from 'src/components/common/search-bar/SearchSuggestion';
import IndustrialClassDropdown from 'src/components/trend/industrialClass/ui/Dropdown';
import useOutSideClick from 'src/hooks/useOutSideClick';
import Description from 'src/components/search/docdb/Description';
import CountryTabs from 'src/components/search/CountryTabs';
import { countries } from 'src/constants/PatentSearchConstants';

const SearchBar = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 32px;
`;

const InputContainer = styled.div`
  position: relative;
  flex: 1;
  input {
    border: solid 1px ${iceblue6};
    border-radius: 0 4px 4px 0;
    border-left: 0px;
    padding: 8px 40px 8px 16px;
    width: 100%;
    ::placeholder {
      color: ${iceblue6};
    }
  }
`;

const SearchIcon = styled(FaSearch)`
  position: absolute;
  right: 16px;
  font-size: 16px;
  color: ${blue2};
  :hover {
    cursor: pointer;
  }
`;

export const SolrFields = [
  { code: 'TI', text: '專利名稱', description: 'TI=電腦 或 (電腦)@TI' },
  { code: 'AB', text: '摘要', description: 'AB=電腦 或 (電腦)@AB' },
  { code: 'CL', text: '申請範圍', description: 'CL=電腦 或 (電腦)@CL' },
  { code: 'DE', text: '專利說明', description: 'DE=專利說明 或 (專利說明)@DE' },
  { code: 'PN', text: '公開/公告號', description: 'PN=201644354' },
  { code: 'AN', text: '申請號', description: 'AN=104139433' },
  { code: 'IC', text: '國際分類號', description: 'IC=C01B31/02' },
  { code: 'FC', text: '第一國際分類號', description: 'FC=C01B31/02' },
  { code: 'CS', text: 'CPC', description: 'CS=C08J5' },
  { code: 'TS', text: '第一 CPC', description: 'TS=C08J5' },
  { code: 'IQ', text: 'LOC', description: 'IQ=01-01' },
  { code: 'FT', text: '日本分類號', description: 'FT=2H04B' },
  { code: 'IR', text: '設計分類號', description: 'IR=F4-713A' },
  { code: 'PB', text: '優先權號', description: 'PB=2012-001904' },
  { code: 'AG', text: '優先權國', description: 'AG=TW' },
  { code: 'CI', text: '引用專利公開/公告號', description: 'CI=D1341378' },
  { code: 'CE', text: '引用非專利', description: 'CE=非專利 或 (非專利)@CE' },
  { code: 'IN', text: '發明人', description: 'IN=王小明 或 (王小明)@IN' },
  { code: 'IN,_L', text: '發明人(開頭)', description: '(王小明)@IN,_L' },
  { code: 'PA', text: '申請人', description: 'PA=王小明 或 (王小明)@PA' },
  { code: 'PA,_L', text: '申請人(開頭)', description: '(王小明)@PA,_L' },
  { code: 'LX', text: '代理人', description: 'LX=王小明 或 (王小明)@LX' },
  { code: 'LX,_L', text: '代理人(開頭)', description: '(王小明)@LX,_L' },
  {
    code: 'ID',
    text: '公開/公告日',
    description: '接受 yyyy、yyyyMM、yyyyMMdd 三種格式，ID=[20180101 TO 20200101] 或 ID=2018:2019',
    singleRow: true,
  },
  {
    code: 'AD',
    text: '申請日',
    description: '接受 yyyy、yyyyMM、yyyyMMdd 三種格式，AD=[20180101 TO 20200101] 或 AD=2018:2019',
    singleRow: true,
  },
];

interface IReduxProps {
  composeBasicRequest: (basicRequest: IBasicRequest) => void;
  ipcCodeList?: string[];
  handleIpcCodeList?: (code?: string[]) => void;
}

interface IProps extends IReduxProps, RouteComponentProps {}

/** 基本檢索 */
const BasicSearch: React.FC<IProps> = props => {
  const [keyword, setKeyword] = useState<string>('');
  const [isSuggestionShow, setIsSuggestionShow] = useState(false);
  const [checkedIndustrialClass, setCheckedIndustrialClass] = useState<IIndustrialClassType[]>(
    toDropdownItem(
      Auth()
        .getPayload()
        .info.industrialClassIds.map(id => id.toString()),
    ),
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const [activeTab, setActiveTab] = useState(countries[0]);

  // 當ipcCodeList變化時(IPC列表勾選確認後)，將陣列中的值轉為string，並分別加上IC=，並用大括號框住整段IPC檢索式
  const handleIpcCode = (code?: string[]) => {
    code && setKeyword(prev => prev + ' (IC=' + code.join(' OR IC=') + ')');
  };
  useEffect(() => {
    handleIpcCode(props.ipcCodeList);
  }, [props.ipcCodeList]);

  const handleKeyWordChange = (e: any) => {
    setKeyword(e.target.value);
  };

  const handleSuggestionClick = (value?: string) => {
    if (value && value !== '') {
      setKeyword(value);
    }
    inputRef.current && inputRef.current.focus();
  };

  const handleSuggestionClose = () => {
    setIsSuggestionShow(false);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (checkedIndustrialClass.length === 0 && keyword.trim() === '') {
      alertMessage(SystemMessage.SEARCH_KEYWORD_EMPTY);
    } else {
      // 送出request並跳到搜尋結果頁
      props.composeBasicRequest({
        industrialClassIds: checkedIndustrialClass.map(item => item.id.toString()),
        queryString: keyword.trim(),
        advanceKeywords: [{ keyCode: 'PO', keyword: activeTab }],
      });
      props.history.push('/result');
    }
  };

  const node = useOutSideClick({
    show: isSuggestionShow,
    setShow: setIsSuggestionShow,
  });

  return (
    <Content sector="專利檢索" feature="基本檢索">
      <>
        <form onSubmit={handleSubmit}>
          <CountryTabs activeTab={activeTab} click={tab => setActiveTab(tab)} />
          <SearchBar>
            <IndustrialClassDropdown
              checkedItems={checkedIndustrialClass}
              setCheckedItems={setCheckedIndustrialClass}
            />
            <InputContainer ref={node as any}>
              <input
                type="text"
                placeholder="請輸入關鍵字 / 專利號 / 申請人...."
                value={keyword}
                onChange={handleKeyWordChange}
                ref={inputRef}
                onFocus={() => setIsSuggestionShow(true)}
              />
              <SearchSuggestion
                cssTop="48px"
                isShow={isSuggestionShow}
                industryIds={checkedIndustrialClass.map(item => item.id)}
                inputText={keyword}
                handleSuggestionClick={handleSuggestionClick}
                handleSuggestionClose={handleSuggestionClose}
              />
            </InputContainer>
            <SearchIcon onClick={handleSubmit} />
          </SearchBar>
        </form>
        <Description descriptionData={SolrFields} />
      </>
    </Content>
  );
};

const mapDispatchToProps = {
  composeBasicRequest,
};

export default withShortcut(
  withRouter(
    connect(
      null,
      mapDispatchToProps,
    )(BasicSearch),
  ),
);
