import React from 'react';

import Banner from 'src/components/index/Banner';
import MainTabs from 'src/components/index/MainTabs';
import Statistic from 'src/components/index/Statistic';
import Ranking from 'src/components/index/Ranking';
import Regulation from 'src/components/index/Regulation';
import Videos from 'src/components/index/Video';
import PatentStat from 'src/components/index/PatentStat';
import Sitemap from 'src/components/index/Sitemap';
import Footer from 'src/components/common/Footer';

const indexLayout: React.FC = () => {
  return (
    <>
      <Banner />
      <MainTabs />
      <Statistic />
      <Ranking />
      <Regulation />
      <Videos />
      <PatentStat />
      <Sitemap />
      <Footer />
    </>
  );
};

export default indexLayout;
