import React, { useState, useEffect, useRef } from 'react';
import * as d3 from 'd3';
import styled from 'styled-components';

import {
  generateXGrid,
  generateYGrid,
} from 'src/components/result/right-toolbar/matrix/gridHelper';
import { ultrawhite, white, iceblue1, iceblue8 } from 'src/style/theme/Color';
import { numFormatter } from 'src/utils/Formatter';
import { toEllipsis } from '../statistics/chart/chartHelper';
import Tooltip from '../statistics/chart/Tooltip';
import { svgId } from 'src/utils/svgDownloadUtils';

const ToolTipOverlay = styled.div`
  color: ${white};
`;

interface ITooltip {
  left: number;
  top: number;
  technology: string;
  function: string;
  count: number;
}

const width = 820;
const height = 600;
const padding = { top: 8, right: 8, bottom: 108, left: 88 };

interface IProps {
  xAxisLabels: Array<string>;
  yAxisLabels: Array<string>;
  dataset: Array<Array<number>>;
}

const MatrixBubbleChart = ({ xAxisLabels, yAxisLabels, dataset }: IProps) => {
  dataset.reverse();
  yAxisLabels.reverse();

  const [tooltip, setTooltip] = useState<ITooltip>();

  const svgRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    // 取得 SVG DOM, 以利後續操作
    const svg = d3.select(svgRef.current);

    // 設定畫布背景
    svg.style('background-color', ultrawhite);
    // 攤平巢狀陣列
    const flattenData = ([] as Array<number>).concat(...dataset);
    // 設定 HTML 元素之對應資料
    const groupWithData = svg.selectAll('circle').data(flattenData);

    // Settings
    /** y 軸條件數 */
    const rows = dataset.length;
    /** x 軸條件數 */
    const cols = dataset[0].length;
    /** x 軸座標區長度 */
    const xAxisLength = width - (padding.left + padding.right);
    /** x 軸最大可容納圓直徑 */
    const xMaxCircleDiameter = xAxisLength / cols;
    /** y 軸座標區長度 */
    const yAxisLength = height - (padding.top + padding.bottom);
    /** y 軸最大可容納圓直徑 */
    const yMaxCircleDiameter = yAxisLength / rows;
    /** 取 x, y 方向間距最小值做直徑並轉為半徑 */
    const maxRadius = Math.min(xMaxCircleDiameter, yMaxCircleDiameter) / 2;
    // 依 Settings 建立 HTML 元素
    /** 坐標軸內部背景 */
    svg
      .append('rect')
      .attr('x', padding.left)
      .attr('y', padding.top)
      .attr('width', xAxisLength)
      .attr('height', yAxisLength)
      .attr('fill', white);
    const gGroup = svg.append('g').attr('transform', `translate(${padding.left}, ${padding.top})`);
    const gxAxis = gGroup
      .append('g')
      .attr('transform', `translate(0, ${yAxisLength})`)
      .style('color', iceblue1);
    const gyAxis = gGroup.append('g').style('color', iceblue1);
    const gxGrid = gGroup
      .append('g')
      .attr('transform', `translate(0, ${yAxisLength})`)
      .style('color', iceblue8);
    const gyGrid = gGroup.append('g').style('color', iceblue8);

    // Scale
    /** 半徑換算 */
    const scaleRadius = d3
      .scaleLinear()
      // 定義域
      .domain([0, d3.max(flattenData) as number])
      // 值域
      .range([0, maxRadius * 0.8]); // 設定圓形最大僅占整個方格 4/5
    /** x 軸 Scale (Categoric axis) */
    const scaleX = d3
      .scaleBand()
      // 輸出標籤
      .domain(dataset[0].map((item, idx) => `x${idx + 1}`))
      // 軸長
      .range([0, xAxisLength]);
    /** y 軸 Scale (Categoric axis) */
    const scaleY = d3
      .scaleBand()
      .domain(dataset.map((item, idx) => `y${idx + 1}`))
      // d3 位置是從(左)上算到(右)下, 與座標系統不同所以此處需要反轉
      .range([yAxisLength, 0]);

    // Coordination
    /** 計算圓心 x 座標 */
    const getCx = (idx: number) => padding.left + xMaxCircleDiameter * ((idx % cols) + 0.5);
    /** 計算圓心 y 座標 */
    const getCy = (idx: number) =>
      yAxisLength + padding.top - yMaxCircleDiameter * (Math.floor(idx / cols) + 0.5);
    /** 依 scaleX 建立 x 軸樣式 */
    const xAxis = d3
      .axisBottom(scaleX)
      .tickFormat((d, i) => toEllipsis(xAxisLabels[i], 8, 0))
      .tickSize(0)
      .tickPadding(16);
    /** 依 scaleY 建立 y 軸樣式 */
    const yAxis = d3
      .axisLeft(scaleY)
      .tickFormat((d, i) => toEllipsis(yAxisLabels[i], 8, 0))
      .tickSize(0)
      .tickPadding(16);
    /** 依 scaleX, yAxisLength 建立 x 軸格線 */
    const xGrid = generateXGrid(scaleX, -yAxisLength).tickSizeOuter(0);
    /** 依 scaleY, xAxisLength 建立 y 軸格線 */
    const yGrid = generateYGrid(scaleY, -xAxisLength).tickSizeOuter(0);

    /** Circle Color */
    const circleColor = d3
      .scaleQuantize<string>()
      // 定義域
      .domain([0, yAxisLength])
      // 值域
      .range(['#7DD3E2', '#95B4C9', '#AC95AF', '#C47796', '#DB587C', '#F33963']);
    // 移除沒有資料可配對的物件
    groupWithData.exit().remove();
    // 為沒有物件可配對的資料新增物件
    const groupWithUpdate = groupWithData.enter().append('circle');
    // 此時 HTML 元素數 = 資料筆數

    // 依據資料作圖
    groupWithUpdate
      .attr('cx', (d, i) => getCx(i))
      .attr('cy', (d, i) => getCy(i))
      .style('opacity', '0.6')
      .transition()
      .duration(2000)
      .ease(d3.easeExpOut)
      .attr('r', d => scaleRadius(d))
      .attr('fill', (d, i) => circleColor(getCy(i)));
    // Circle Hover Event
    groupWithUpdate
      .on('mouseover', (d, i, nodes) => {
        d3.select(nodes[i]).style('opacity', 0.8);
        const pos = calculateLocation(i, cols);
        setTooltip({
          left: d3.event.pageX - window.scrollX,
          top: d3.event.pageY - window.scrollY - 16,
          technology: xAxisLabels[pos.x],
          function: yAxisLabels[pos.y],
          count: d,
        });
      })
      .on('mousemove', (d, i) => {
        const pos = calculateLocation(i, cols);
        setTooltip({
          left: d3.event.pageX - window.scrollX,
          top: d3.event.pageY - window.scrollY - 16,
          technology: xAxisLabels[pos.x],
          function: yAxisLabels[pos.y],
          count: d,
        });
      })
      .on('mouseout', (d, i, nodes) => {
        d3.select(nodes[i]).style('opacity', 0.6);
        setTooltip(undefined);
      });

    // 繪製座標軸、座標格線
    gxAxis.call(xAxis);
    gyAxis.call(yAxis);
    gxGrid.call(xGrid);
    gyGrid.call(yGrid);
    // 座標軸文字調整
    gxAxis
      .selectAll('text')
      .style('font-size', '13px')
      .style('text-anchor', 'end')
      .attr('transform', `rotate(-55)`)
      .attr('dx', '-0.5em')
      .attr('dy', '-0.5em');
    gyAxis
      .selectAll('text')
      .style('font-size', '13px')
      .attr('transform', `rotate(-55)`)
      .attr('dx', '0.6em')
      .attr('dy', '-0.6em');
    // 刪除座標軸、格線之軸線(第一條ticks)
    gxAxis.select('.domain').remove();
    gyAxis.select('.domain').remove();
    gxGrid.select('.domain').remove();
    gyGrid.select('.domain').remove();
  }, [xAxisLabels, yAxisLabels, dataset]);

  return (
    <>
      <svg id={svgId} ref={svgRef} width={width} height={height} />
      {tooltip && (
        <Tooltip
          left={tooltip.left}
          top={tooltip.top}
          overlay={
            <ToolTipOverlay>
              <div>技術:&nbsp;{tooltip.technology}</div>
              <div>功效:&nbsp;{tooltip.function}</div>
              <div>專利數量:&nbsp;{numFormatter(tooltip.count)}</div>
            </ToolTipOverlay>
          }
        />
      )}
    </>
  );
};

export default MatrixBubbleChart;

/** 計算座標位置 (從零起算) */
function calculateLocation(i: number, xAxisNum: number) {
  return {
    x: i % xAxisNum,
    y: Math.floor(i / xAxisNum),
  };
}
