import React from 'react';
import styled from 'styled-components';
import useRssFeedNews from 'src/hooks/useRssFeedNews';

import { IGetRelatedNews } from 'src/types/api/NewsApiTypes';
import { dateFormatter } from 'src/utils/Formatter';
import ResultHighlighter from 'src/components/result/ResultHighlighter';
import { pink1 } from 'src/style/theme/Color';
import { NEWS_KEYWORDS_NUMBER } from 'src/constants/RssSearchConstants';

// Layout
const Container = styled.div`
  padding: 8px 0;
  align-items: center;
  * {
    font-size: 13px;
  }
`;
const Title = styled.div`
  color: #1f8ba3;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`;

const Info = styled.div`
  padding-top: 4px;
  color: #979ea1;
`;

const highlightStyle = {
  color: `${pink1}`,
  backgroundColor: 'transparent',
};

interface IProps {
  newsData: IGetRelatedNews;
  searchWords?: Array<string>;
}

const Entities = require('html-entities').Html5Entities;
const htmlEntities = new Entities();
const XmlEntities = require('html-entities').XmlEntities;
const xmlEntities = new XmlEntities();

const RelatedNewsItem: React.FC<IProps> = props => {
  const [handleClick] = useRssFeedNews();
  const { explorerKeywords } = props.newsData;

  return (
    <Container>
      <Title onClick={() => handleClick(props.newsData.id, props.newsData.link)}>
        <ResultHighlighter
          highlightStyle={highlightStyle}
          searchWords={props.searchWords ? props.searchWords : []}
          textToHighlight={htmlEntities.decode(
            xmlEntities.decode(htmlEntities.decode(props.newsData.title)),
          )}
        />
      </Title>
      <Info>
        {props.newsData.feedName}
        &nbsp;&nbsp;|&nbsp;&nbsp;
        {dateFormatter(props.newsData.releaseDate)}
      </Info>
      {explorerKeywords && explorerKeywords.length > 0 && (
        <Info>
          關鍵字：
          {explorerKeywords.slice(0, NEWS_KEYWORDS_NUMBER).join('、')}
        </Info>
      )}
    </Container>
  );
};

export default RelatedNewsItem;
