import { AxiosResponse, AxiosError } from 'axios';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { ReduxAppState } from 'src/redux/reducers';
import {
  FETCH_INDUSTRIAL_CLASS_START,
  FETCH_INDUSTRIAL_CLASS_SUCCESS,
  FETCH_INDUSTRIAL_CLASS_ERROR,
  FetchIndustrialClassActionTypes,
} from 'src/redux/types/industrialClassTypes';
import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';

export const fetchIndustrialClass = (): ThunkAction<
  void,
  ReduxAppState,
  null,
  Action<string>
> => async dispatch => {
  dispatch(fetchIndustrialClassStart());
  try {
    const response = await Api().get('industry/list');
    dispatch(fetchIndustrialClassSuccess(response));
  } catch (error) {
    dispatch(fetchIndustrialClassFailure(error));
  }
};

export function fetchIndustrialClassStart(): FetchIndustrialClassActionTypes {
  return {
    type: FETCH_INDUSTRIAL_CLASS_START,
  };
}

export function fetchIndustrialClassSuccess(
  response: AxiosResponse,
): FetchIndustrialClassActionTypes {
  return {
    type: FETCH_INDUSTRIAL_CLASS_SUCCESS,
    response: response,
  };
}

export function fetchIndustrialClassFailure(error: AxiosError): FetchIndustrialClassActionTypes {
  errorHandler(error);
  return {
    type: FETCH_INDUSTRIAL_CLASS_ERROR,
    error: error,
  };
}
