import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Api } from 'src/api/helpers/apiBase';

import { IGetPatentCollectionCategoryId } from 'src/types/api/ShowcaseApiTypes';
import ShowcaseCollectContent from 'src/components/ui/content/Showcase/CollectContent';
import CollectItem from 'src/components/showcase/item/CollectionItem';
import { Spinner } from 'src/components/ui/interactive/Spinner';
import {
  TutorialContainer,
  TutorialTitle,
  TutorialContent,
  TutorialHighlight,
} from 'src/style/TutorialStyle';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';

interface IProps {
  feature: string;
  name: string;
  id: number;
  handleSubmit: (option: string, id: number, data: any) => void;
}

function Collection(props: IProps) {
  const [tagData, setTagData] = useState([]);
  const [activeTag, setActiveTag] = useState<Array<string>>([]);
  const tagHandler = (tag: string) => {
    if (activeTag.includes(tag)) {
      const data = [...activeTag];
      const dataIndex = activeTag.indexOf(tag);
      data.splice(dataIndex, 1);

      setActiveTag(data);
    } else {
      setActiveTag([...activeTag, tag]);
    }
  };

  let [noCollections, setNoCollections] = useState(false);
  let [patents, setPatents] = useState<Array<IGetPatentCollectionCategoryId>>();
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const fetchData = async () => {
      try {
        const res = await Api().get('patent/collection/category/' + props.id, {
          cancelToken: source.token,
        });
        setPatents(res.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        errorHandler(error);
      }
    };

    if (props.id !== -1 && !patents) {
      setLoading(true);
      fetchData();
    } else if (props.id === -1) {
      setNoCollections(true);
      setLoading(false);
    } else {
      setLoading(false);
    }

    return () => source.cancel();
  }, [patents, props.id]);

  let [loading, setLoading] = useState(true);
  // TODO: REFACTOR THIS GARBAGE ASAP, implement better data structure or something
  const sortByRatingHandler = () => {
    if (patents) {
      const five = patents.filter(it => it.rating === 5);
      const four = patents.filter(it => it.rating === 4);
      const three = patents.filter(it => it.rating === 3);
      const two = patents.filter(it => it.rating === 2);
      const one = patents.filter(it => it.rating === 1);
      const zero = patents.filter(it => it.rating === 0);

      const sortedArr = [];
      sortedArr.push(...five);
      sortedArr.push(...four);
      sortedArr.push(...three);
      sortedArr.push(...two);
      sortedArr.push(...one);
      sortedArr.push(...zero);
      setPatents(sortedArr);
    }
  };
  const sortByDateHandler = () => {
    if (patents) {
      const dataMap = patents.map(patent => ({
        date: `${patent.solrResult.date}`,
        patent: patent,
      }));

      const sorted = dataMap.sort((a, b) => -a.date.localeCompare(b.date));

      setPatents(sorted.map(item => item.patent));
    }
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const fetchTagData = async () => {
      try {
        const res = await Api().get('patent/tag', {
          cancelToken: source.token,
        });
        const tagList = res.data.map((item: { tag: string }) => item.tag);
        setTagData(tagList);
      } catch (error) {
        errorHandler(error);
      }
    };

    fetchTagData();
    return () => source.cancel();
  }, []);

  const handleSubmit = (option: string, data: any) => {
    props.handleSubmit(option, props.id, data);
  };

  const handleSubmitEditTags = () => {
    const fetchData = async () => {
      try {
        const res = await Api().get('patent/collection/category/' + props.id);
        setLoading(false);
        setPatents(res.data);
      } catch (error) {
        errorHandler(error);
      }
    };
    fetchData();
  };

  return (
    <ShowcaseCollectContent
      sector="專利專區"
      feature={props.feature}
      name={props.name}
      activeTag={activeTag}
      tags={tagData}
      click={tagHandler}
      collectId={props.id}
      submit={handleSubmit}
      sortRating={sortByRatingHandler}
      sortDate={sortByDateHandler}
      noCollections={noCollections}
    >
      <>
        {loading && <Spinner width="150px" />}
        {noCollections ? (
          <TutorialContainer>
            <TutorialTitle>建立新的收藏</TutorialTitle>
            <TutorialContent>
              看到本頁代表您尚未建立專利收藏，您可以在專利內容檢視頁面右方工具列收藏您喜歡的專利。
            </TutorialContent>
            <TutorialHighlight>
              1. 點擊收藏專利
              <br />
              2. 在新增分類的白色輸入框輸入分類名稱，點擊右方加號新增
              <br />
              3. 在選擇收藏分類中，選擇本專利儲存的分類
              <br />
              4. 點擊上方星星給予平等
              <br />
              5. 按收藏按鈕即可完成收藏
            </TutorialHighlight>
          </TutorialContainer>
        ) : (
          patents &&
          patents
            .filter(collects => {
              if (activeTag.length) {
                return activeTag.every(tag => collects.patentTag.some(data => data.tag === tag));
              } else {
                return true;
              }
            })
            .map(collects => (
              <CollectItem
                key={collects.id}
                collectData={collects}
                collectId={props.id}
                submit={handleSubmit}
                submitEditTags={handleSubmitEditTags}
              />
            ))
        )}
      </>
    </ShowcaseCollectContent>
  );
}

export default Collection;
