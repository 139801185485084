import React, { useState, useRef, useLayoutEffect } from 'react';
import { blue3, blue5 } from 'src/style/theme/Color';
import styled, { css } from 'styled-components';

const Container = styled.div<{ willOverflow: boolean; expanded: boolean }>`
  position: relative;
  margin-bottom: ${props => props.willOverflow && !props.expanded && '1em'};
`;

const ContentBox = styled.div<{
  config?: { maxLineNumber: number; currentLineHeight: number };
  expanded: boolean;
}>`
  position: relative;
  overflow: hidden;
  ${props =>
    !props.expanded &&
    css`
      max-height: ${props.config
        ? `${props.config.currentLineHeight * props.config.maxLineNumber}em`
        : '6em'};
    `}
`;

const Switch = styled.span<{ expanded: boolean }>`
  color: ${blue3};
  cursor: pointer;

  :hover {
    color: ${blue5};
  }

  ${props =>
    props.expanded
      ? css`
          display: inline-block;
        `
      : css`
          position: absolute;
          right: 0;
          bottom: -1.5em;
        `}
`;

interface IProps {
  config?: { maxLineNumber: number; currentLineHeight: number };
}

/**
 * @param props Default = { config: { maxLineNumber: 3, currentLineHeight: 2}}
 */
const ExpandableBox: React.FC<IProps> = props => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [willOverflow, setWillOverflow] = useState<boolean>(true);
  const boxRef = useRef<any>(null);

  // 為了等到 boxRef 確實與 ContentBox 連接，且 component size 已確定（以判斷是否大於 max height -> will overflow）
  // 故不能使用 useEffect
  // 針對傳入內容有變動時，先關閉 expand 狀態後，重新計算 overflow
  useLayoutEffect(() => {
    closeExpanded().then(() => {
      setWillOverflow(boxRef.current.offsetHeight < boxRef.current.scrollHeight);
    });
  }, [props.children]);

  const closeExpanded = async () => {
    setExpanded(false);
  };

  return (
    <Container willOverflow={willOverflow} expanded={expanded}>
      <ContentBox ref={boxRef} config={props.config} expanded={expanded}>
        {props.children}
      </ContentBox>
      {willOverflow && (
        <Switch expanded={expanded} onClick={() => setExpanded(prev => !prev)}>
          {expanded ? '較少內容' : '... 更多內容'}
        </Switch>
      )}
    </Container>
  );
};

export default ExpandableBox;
