import React from 'react';
import styled from 'styled-components';
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';

import ExpertResult from './ExpertResult';
import SimpleSideNav from 'src/components/common/nav/side-nav/SimpleSideNav';

const Container = styled.div`
  margin: 0 auto;
  padding: 16px 140px 0 140px;
  max-width: 1440px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;
const SideNavStyle = styled.div`
  width: 180px;
  display: inline-flex;
`;
const ContentStyle = styled.div`
  padding: 0 0 0 16px;
  width: calc(100% - 180px);
`;

/** 學界專家 */
const ExpertLayout: React.FC<RouteComponentProps> = routeProps => {
  const sector = '學界專家';
  const features = [{ feature: '專家履歷', path: '/result' }];

  return (
    <Container>
      <SideNavStyle>
        <SimpleSideNav sector={sector} features={features} {...routeProps} />
      </SideNavStyle>

      <ContentStyle>
        <Switch>
          <Route path={routeProps.match.url + features[0].path} component={ExpertResult} />
          <Route component={() => <Redirect to={routeProps.match.url + features[0].path} />} />
        </Switch>
      </ContentStyle>
    </Container>
  );
};

export default ExpertLayout;
