import {
  FETCH_FACETS_START,
  FETCH_FACETS_SUCCESS,
  FETCH_FACETS_ERROR,
  FETCH_IPC_DESCRIPTIONS_START,
  FETCH_IPC_DESCRIPTIONS_SUCCESS,
  FETCH_IPC_DESCRIPTIONS_ERROR,
  FETCH_BILINGUAL_TERMS_START,
  FETCH_BILINGUAL_TERMS_SUCCESS,
  FETCH_BILINGUAL_TERMS_ERROR,
  PatentFilterState,
  PatentFilterActionTypes,
} from 'src/redux/types/patentFilterTypes';

const defaultFacetsState = {
  isFacetsLoading: false,
  facets: [],
};

const defaultIpcDescriptionsState = {
  isIpcDescriptionsLoading: false,
  ipcDescriptions: {},
};

const defaultBilingualTermsState = {
  isBilingualTermsLoading: false,
  bilingualTerms: [],
};

const initialState: PatentFilterState = {
  ...defaultFacetsState,
  ...defaultIpcDescriptionsState,
  ...defaultBilingualTermsState,
};

const facetsReducer = (
  state = initialState,
  action: PatentFilterActionTypes,
): PatentFilterState => {
  switch (action.type) {
    // 分組統計資料
    case FETCH_FACETS_START:
      return { ...state, isFacetsLoading: true };
    case FETCH_FACETS_SUCCESS: {
      let { data, status } = action.response;
      return status === 200
        ? { ...state, facets: data, isFacetsLoading: false }
        : { ...state, ...defaultFacetsState };
    }
    case FETCH_FACETS_ERROR:
      return { ...state, ...defaultFacetsState };
    // IPC中文敘述
    case FETCH_IPC_DESCRIPTIONS_START:
      return { ...state, isIpcDescriptionsLoading: true };
    case FETCH_IPC_DESCRIPTIONS_SUCCESS: {
      let { data, status } = action.response;
      return status === 200
        ? { ...state, ipcDescriptions: data, isIpcDescriptionsLoading: false }
        : { ...state, ...defaultIpcDescriptionsState };
    }
    case FETCH_IPC_DESCRIPTIONS_ERROR:
      return { ...state, ...defaultIpcDescriptionsState };
    // 雙語詞彙
    case FETCH_BILINGUAL_TERMS_START:
      return { ...state, isBilingualTermsLoading: true };
    case FETCH_BILINGUAL_TERMS_SUCCESS: {
      let { data, status } = action.response;
      return status === 200
        ? { ...state, bilingualTerms: data, isBilingualTermsLoading: false }
        : { ...state, ...defaultBilingualTermsState };
    }
    case FETCH_BILINGUAL_TERMS_ERROR:
      return { ...state, ...defaultBilingualTermsState };
    default:
      return { ...state };
  }
};

export default facetsReducer;
