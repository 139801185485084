import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Api } from 'src/api/helpers/apiBase';
import _ from 'lodash';
import { Container, Item } from 'src/style/SearchDropdownStyle';
import { IRssSearchSuggestion } from 'src/types/api/NewsApiTypes';
import styled from 'styled-components';
import { rssSuggestionText } from 'src/constants/RssSearchConstants';

const Type = styled.span`
  color: #b6b6b6;
  width: 70px;
`;

interface IProps {
  cssTop?: string;
  inputText: string;
  tags: Array<IRssSearchSuggestion>;
  handleSuggestionClick: (input?: IRssSearchSuggestion) => void;
}

const Autocomplete: React.FC<IProps> = props => {
  const { cssTop, inputText, handleSuggestionClick } = props;

  const [suggestions, setSuggestions] = useState<Array<IRssSearchSuggestion>>([]);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const fetchSuggestion = async () => {
      await Api({
        'Content-Type': 'application/json',
      })
        .post(
          '/rss/search/suggestion',
          {
            inputText: inputText,
            existedTags: props.tags.map(tag => tag.type),
          },
          { cancelToken: source.token },
        )
        .then(res => setSuggestions(res.data))
        .catch(e => {
          if (!axios.isCancel(e)) {
            throw e;
          }
        });
    };

    if (inputText !== '') {
      const debounce = _.debounce(fetchSuggestion, 300);
      debounce();
    }

    return () => source.cancel();
  }, [inputText, props.tags]);

  return suggestions &&
    suggestions.length > 0 &&
    (suggestions[0].displayName !== inputText || suggestions[0].type !== 'QUERY_STRING') ? (
    <Container cssTop={cssTop} onClick={() => handleSuggestionClick()}>
      {suggestions.map(term => (
        <Item
          key={term.type + term.value}
          onClick={e => {
            e.stopPropagation();
            handleSuggestionClick(term);
          }}
        >
          <Type>{rssSuggestionText.get(term.type)}</Type>
          <span>{term.displayName}</span>
        </Item>
      ))}
    </Container>
  ) : null;
};

export default Autocomplete;
