import React from 'react';
import styled from 'styled-components';
import display_mode from 'src/assets/images/manual/search-result-analysis/display_mode.png';
import two_column_browse from 'src/assets/images/manual/search-result-analysis/two_column_browse.png';

const ImgContainer = styled.div<{ height: string; imageUrl: string }>`
  position: relative;
  height: ${props => props.height}px;
  padding: 10px 0;
  background-image: url(${props => props.imageUrl});
  background-size: contain;
  background-repeat: no-repeat;
`;
export default function SearchResultAnalysisManual() {
  return (
    <>
      <p>檢索結果可顯示為圖片或列表模式。</p>
      <br />
      <ImgContainer height="500" imageUrl={display_mode} />
      <br />
      <p>開啟「雙欄瀏覽」可點選專利以便同步瀏覽專利內容。</p>
      <br />
      <ImgContainer height="500" imageUrl={two_column_browse} />
    </>
  );
}
