import {
  CHANGE_ACTIVE_PANE,
  SAVE_MATRIX_FORM,
  SAVE_MATRIX_AXIS_DATA,
  SAVE_MATRIX_DATASET,
  ToolbarState,
  ToolbarActionTypes,
} from './types';
import { formInitialValues } from 'src/types/defaultData/matrixFormDefault';

const initialState: ToolbarState = {
  activePane: '',
  matrixForm: { ...formInitialValues },
  matrixChart: {
    dataset: [[]],
    technologyList: [],
    functionList: [],
  },
};

const resultToolbarReducer = (state = initialState, action: ToolbarActionTypes): ToolbarState => {
  switch (action.type) {
    case CHANGE_ACTIVE_PANE:
      return {
        ...state,
        activePane: action.activePane,
      };
    case SAVE_MATRIX_FORM:
      return {
        ...state,
        matrixForm: action.matrixForm,
      };
    case SAVE_MATRIX_AXIS_DATA: {
      const prevMatrixChart = state.matrixChart;
      return {
        ...state,
        matrixChart: {
          ...prevMatrixChart,
          technologyList: action.technologyList,
          functionList: action.functionList,
        },
      };
    }
    case SAVE_MATRIX_DATASET: {
      const prevMatrixChart = state.matrixChart;
      return {
        ...state,
        matrixChart: {
          ...prevMatrixChart,
          dataset: action.dataset,
        },
      };
    }
    default:
      return { ...state };
  }
};

export default resultToolbarReducer;
