import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { Auth } from 'src/apps/auth/Auth';
import { respInterceptorLogic, errorInterceptorLogic } from 'src/apps/error-handler/ErrorHandler';

export const baseURL = process.env.REACT_APP_API_URL;

/**
 * @name ApiBaseCreator
 * @desc with baseurl and token headers
 * @example
 * const instance = ApiBase(); // in order to await token
 * const res = await instance.get(url);
 */
export const Api = (
  headers: any = {},
  timeout?: number,
  responseType?: AxiosRequestConfig['responseType'],
): AxiosInstance => {
  const instance = axios.create({
    baseURL,
    timeout: timeout ? timeout : 0, // 0 means no timeout
    headers: {
      Authorization: 'Bearer ' + Auth().getToken(),
      'Access-Control-Allow-Origin': Auth().getOrigin(),
      ...headers,
    },
    responseType: responseType,
    maxRedirects: 0,
    validateStatus(status) {
      return status >= 200 && status < 303; // 300
    },
  });

  instance.interceptors.request.use(
    request => request,
    error => errorInterceptorLogic(error, 'Request'),
  );
  instance.interceptors.response.use(
    response => respInterceptorLogic(response),
    error => errorInterceptorLogic(error, 'Response'),
  );

  return instance;
};
