import { IDropdownProps } from 'src/components/common/Dropdown';

export const CONDITION_AND = ' AND ';
export const CONDITION_OR = ' OR ';
export const CONDITION_NOT = ' NOT ';

export const logicItems: IDropdownProps['items'] = [
  { name: 'AND', value: CONDITION_AND },
  { name: 'OR', value: CONDITION_OR },
  { name: 'NOT', value: CONDITION_NOT },
];

export const ipcFields: IDropdownProps['items'] = [
  { name: 'IPC', value: 'IC' },
  { name: '第一 IPC', value: 'FC' },
];
export const cpcFields: IDropdownProps['items'] = [
  { name: 'CPC', value: 'CS' },
  { name: '第一 CPC', value: 'TS' },
];

export const locFields: IDropdownProps['items'] = [{ name: 'LOC', value: 'IQ' }];

export const sortTypeItems: IDropdownProps['items'] = [
  { name: '相關度', value: 'false' },
  { name: '日期', value: 'true' },
];

export const dateRangeItems: IDropdownProps['items'] = [
  { name: '全部', value: '' },
  { name: '月', value: '1' },
  { name: '季', value: '3' },
  { name: '半年', value: '6' },
  { name: '一年 ', value: '12' },
];

export const patentFacetText = new Map([
  ['TI', '專利名稱'],
  ['AB', '摘要'],
  ['CL', '申請範圍'],
  ['DE', '專利說明'],
  ['PN', '公開號'],
  ['AN', '申請號'],
  ['IC', '國際分類號'],
  ['IN', '發明人'],
  ['PA', '申請人'],
  ['LX', '代理人'],
  ['ID', '公開日'],
  ['AD', '申請日'],
  ['PO', '國別'],
  ['KC', '專利種類'],
]);

export const countries = ['TW', 'CN', 'JP', 'EP', 'US', 'KR'];
export const countryItems: IDropdownProps['items'] = [
  { name: '本國', value: 'TW' },
  { name: '中國大陸', value: 'CN' },
  { name: '日本', value: 'JP' },
  { name: '歐盟', value: 'EP' },
  { name: '美國', value: 'US' },
  { name: '韓國', value: 'KR' },
];

/** 不需要包覆引號的欄位 */
export const excludeItems = ['', 'PN', 'AN', 'IC', 'FC', 'CS', 'TS', 'IQ', 'FT', 'IR'];

export const classificationCode = ['ipc', 'cpc', 'loc'];
