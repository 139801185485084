import React from 'react';
import styled from 'styled-components';
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';

import SimpleSideNav from 'src/components/common/nav/side-nav/SimpleSideNav';

import Sitemap from 'src/components/question/Sitemap';
// import Usage from 'src/components/question-layout/Usage/Usage';
import Faq from 'src/components/question/Faq';
import ContactUs from 'src/components/question/ContactUs';

const Container = styled.div`
  margin: 0 auto;
  padding: 16px 140px 0 140px;
  max-width: 1440px;
  display: flex;
  align-items: flex-start;
  align-content: space-between;
  flex-flow: row;
`;
const SideNavStyle = styled.div`
  width: 180px;
  display: inline-flex;
`;
const ContentStyle = styled.div`
  padding: 0 0 0 16px;
  width: calc(100% - 180px);
`;

const questionLayout: React.FC<RouteComponentProps> = routeProps => {
  const sector = '常見問題';
  const features = [
    { feature: '網站導覽', path: '/sitemap' },
    // { feature: '使用教學', path: '/usage' },
    { feature: 'FAQ', path: '/faq' },
    { feature: '聯絡我們', path: '/contactus' },
  ];

  return (
    <Container>
      <SideNavStyle>
        <SimpleSideNav sector={sector} features={features} {...routeProps} />
      </SideNavStyle>

      <ContentStyle>
        <Switch>
          <Route path={routeProps.match.url + features[0].path} component={Sitemap} />
          {/* <Route path={routeProps.match.url + features[1].path} component={Usage} /> */}
          <Route path={routeProps.match.url + features[1].path + '/:quest'} component={Faq} />
          <Route path={routeProps.match.url + features[1].path} component={Faq} />
          <Route path={routeProps.match.url + features[2].path} component={ContactUs} />
          <Route component={() => <Redirect to={routeProps.match.url + features[0].path} />} />
        </Switch>
      </ContentStyle>
    </Container>
  );
};

export default questionLayout;
