/**
 * 從狀態代碼轉換成狀態名稱
 */
export const getStatus = (StatusCode: string) => {
  switch (StatusCode) {
    case 'A':
      return '啟用';
    case 'D':
      return '停用';
  }
};
