import React from 'react';
import ReactDOM from 'react-dom';
import { Button } from 'src/style/theme/Common';
import { Backdrop, Container, Body, Message, Description, Footer } from 'src/style/ModalStyle';
import { zModal } from 'src/style/theme/Z-Index';
import { connect } from 'react-redux';
import { closeCustomModal } from 'src/redux/actions/modalAction';

interface IReduxMappingProps {
  closeCustomModal: () => void;
}

interface IProps extends IReduxMappingProps {
  message: string;
  description?: string;
  deleteButtonText?: string;
  submit: () => void;
}

const modal = document.getElementById('modal-custom');
const portalDiv = modal ? modal : document.createElement('div');

const DeleteModal: React.FC<IProps> = props =>
  ReactDOM.createPortal(
    <Backdrop zIndex={zModal.custom}>
      <Container>
        <Body hasDescription={true}>
          <Message>{props.message}</Message>
          <Description>{props.description || '此動作不可復原。'}</Description>
        </Body>
        <Footer>
          <Button template="alarm" onClick={() => props.submit()}>
            {props.deleteButtonText || '刪除'}
          </Button>
          <Button onClick={() => props.closeCustomModal()}>取消</Button>
        </Footer>
      </Container>
    </Backdrop>,
    portalDiv,
  );

const mapDispatchToProps = {
  closeCustomModal,
};

export default connect(
  null,
  mapDispatchToProps,
)(DeleteModal);
