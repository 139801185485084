import React from 'react';
import styled from 'styled-components';
import { ultrawhite, ultrawhitergba, ultrablackrgba } from 'src/style/theme/Color';

const Container = styled.div`
  border-radius: 4px;
  box-shadow: 0 0 50px 0 ${ultrablackrgba(0.1)};
`;
const Header = styled.div`
  height: 88px;
  display: flex;
  flex-flow: row;
  align-items: center;
  color: ${ultrawhite};
  background-image: linear-gradient(95deg, #2ec3c2, #0cafcc);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;
const HeaderText = styled.div`
  padding: 16px 32px;
`;
const HeaderRoute = styled.div`
  color: ${ultrawhitergba(0.8)};
`;
const Feature = styled.div`
  height: 36px;
  line-height: 36px;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  align-items: flex-end;
`;
const Subheaders = styled.div`
  padding: 0 32px;
  height: 60px;
  display: flex;
  align-items: center;
  color: #979ea1;
  background-color: #edf6f7;
`;
const Functions = styled.div`
  width: calc(85% - 36px);
  display: flex;
  div:nth-child(1) {
    margin-left: 0;
  }
`;
const Function = styled.div<{ active: boolean }>`
  margin: 0 12px;
  padding: 4px 0 0 0;
  width: 88px;
  height: 60px;
  border-bottom: 4px solid ${props => (props.active ? '#077790' : 'none')};
  color: #333333;
  font-size: 20px;
  text-align: center;
  line-height: 60px;
  cursor: pointer;
`;
const Content = styled.div`
  padding: 32px;
  min-height: 450px;
`;

interface IProps {
  sector: string;
  feature: string;
  activeTab: number;
  tabs: string[];
  click: (tab: number) => void;
  children: JSX.Element;
}

const listContent: React.FC<IProps> = props => (
  <Container>
    <Header>
      <HeaderText>
        <HeaderRoute>
          首頁&nbsp;/&nbsp;{props.sector}&nbsp;/&nbsp;
          {props.feature}
        </HeaderRoute>
        <Feature>{props.feature}</Feature>
      </HeaderText>
    </Header>
    <Subheaders>
      <Functions>
        {props.tabs.map((tab, idx) => (
          <Function key={tab} active={props.activeTab === idx} onClick={() => props.click(idx)}>
            {tab}
          </Function>
        ))}
      </Functions>
    </Subheaders>
    <Content>{props.children}</Content>
  </Container>
);

export default listContent;
