import { createStore, applyMiddleware } from 'redux';
import rootReducer from 'src/redux/reducers';
// import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';

// 開發用，需使用時打開註解即可
// const logger = createLogger();

const store = createStore(
  rootReducer,
  applyMiddleware(
    // logger,
    thunk,
  ),
);

export default store;
