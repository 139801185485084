import { IGetPatentOcrLabelTypes } from 'src/types/api/PatentApiTypes';

/** 取得專利圖片url */
export function getAllImgUrls(uuid: string, img_alias: string[]) {
  if (img_alias && img_alias.length !== 0) {
    const imgPrefix = process.env.REACT_APP_API_URL + 'patent/' + uuid + '/img/';
    let imgUrls = img_alias.map(imgName => {
      return imgPrefix + imgName;
    });
    return imgUrls;
  }
  return [];
}

/** 取得ImageModal的圖片網址。*/
export const getImgUrls = (
  uuid: string,
  patentImgAlias: string[],
  data: IGetPatentOcrLabelTypes | null,
) => {
  let img_alias;
  if (data) {
    img_alias = data.images.map(image => {
      return image.path;
    });
  } else {
    img_alias = patentImgAlias;
  }
  return getAllImgUrls(uuid, img_alias);
};

/** 取得單張專利內文圖片url */
export function getInlineImgUrl(uuid: string, fileName: string) {
  fileName = fileName.replace('.tif', '.PNG').replace('.TIF', '.PNG');
  return process.env.REACT_APP_API_URL + 'patent/' + uuid + '/img/' + fileName;
}

/** 將國別代碼(TW/US/EP/JP/CN/KR)轉換為中文名稱 */
export const getCountryName = (countryCode: string) => {
  switch (countryCode) {
    case 'CN':
      return '中國大陸';
    case 'EP':
      return '歐盟';
    case 'KR':
      return '韓國';
    case 'JP':
      return '日本';
    case 'TW':
      return '本國';
    case 'US':
      return '美國';
    case '':
      return '各國';
    default:
      return countryCode;
  }
};

/** 將專利類別代碼(A/B/U/D)轉換為中文名稱 */
export const getPatentKindName = (kindCode: string) => {
  switch (kindCode) {
    case 'A':
      return '公開';
    case 'B':
      return '發明';
    case 'U':
      return '新型';
    case 'D':
      return '設計';
    default:
      return kindCode;
  }
};
