import React from 'react';
import styled from 'styled-components';
import statistical_analysis from 'src/assets/images/manual/search-result-analysis/statistical_analysis.png';

const ImgContainer = styled.div<{ height: string; imageUrl: string }>`
  position: relative;
  height: ${props => props.height}px;
  padding: 10px 0;
  background-image: url(${props => props.imageUrl});
  background-size: contain;
  background-repeat: no-repeat;
`;
export default function SearchResultAnalysisManual() {
  return (
    <>
      <p>提供分析結果統計圖表，選擇分析項目及圖表類型等，產生統計圖表。</p>
      <br />
      <ImgContainer height="400" imageUrl={statistical_analysis} />
    </>
  );
}
