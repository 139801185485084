import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { openLoginAlertModal } from 'src/redux/actions/modalAction';

interface IReduxMappingProps {
  openLoginAlertModal: (isFromRoute: boolean) => void;
}

const LoginBlock: React.FC<IReduxMappingProps> = props => {
  useEffect(() => {
    props.openLoginAlertModal(true);
  });

  return null;
};

const mapDispatchToProps = {
  openLoginAlertModal,
};

export default connect(
  null,
  mapDispatchToProps,
)(LoginBlock);
