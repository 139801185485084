import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import InputDropdown from 'src/components/common/search-bar/InputDropdown';
import Autocomplete from 'src/components/common/search-bar/Autocomplete';
import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import { IGetPatentSubscription } from 'src/types/api/SearchBarApiTypes';
import { connect } from 'react-redux';
import { ReduxAppState } from 'src/redux/reducers';
import { ITokenPayload } from 'src/apps/auth/Auth';

const Container = styled.div<{ isShow: boolean }>`
  display: ${props => !props.isShow && 'none'};
`;

interface IReduxProps {
  member: ITokenPayload;
}

interface IProps extends IReduxProps {
  isShow: boolean;
  industryIds: Array<string | number>;
  inputText: string;
  cssTop?: string;
  handleSuggestionClick: (value?: string) => void;
  handleSuggestionClose: () => void;
}

const SearchSuggestion: React.FC<IProps> = props => {
  const {
    member,
    isShow,
    industryIds,
    inputText,
    cssTop,
    handleSuggestionClick,
    handleSuggestionClose,
  } = props;

  const [recentSearch, setRecentSearch] = useState<Array<string>>();
  const [patentSubscribe, setPatentSubscribe] = useState<Array<IGetPatentSubscription>>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await Api().get('patent/search/keyword');
        setRecentSearch(res.data);
      } catch (error) {
        errorHandler(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await Api().get('patent/subscription');
        setPatentSubscribe(res.data);
      } catch (error) {
        errorHandler(error);
      }
    };

    if (member.info.guest) {
      setPatentSubscribe([]);
    } else {
      fetchData();
    }
  }, [member.info.guest]);

  return (
    <Container isShow={isShow}>
      {inputText === '' ? (
        <InputDropdown
          cssTop={cssTop}
          recentSearch={recentSearch}
          patentSubscribe={patentSubscribe}
          handleSuggestionClick={handleSuggestionClick}
          handleSuggestionClose={handleSuggestionClose}
        />
      ) : (
        <Autocomplete
          cssTop={cssTop}
          inputText={inputText}
          industryIds={industryIds}
          handleSuggestionClick={handleSuggestionClick}
        />
      )}
    </Container>
  );
};

const mapStateToProps = (state: ReduxAppState) => {
  return {
    member: state.memberReducer.memberPayload,
  };
};

export default connect(
  mapStateToProps,
  // null,
)(SearchSuggestion);
