import { Api } from 'src/api/helpers/apiBase';
import { Auth } from 'src/apps/auth/Auth';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import _ from 'lodash';
import store from 'src/redux/store';
import { openLoginAlertModal } from 'src/redux/actions/modalAction';

export const getGuestJwt = _.throttle(async (): Promise<number> => {
  Auth().removeAllToken();

  try {
    const res = await Api().post('login/guest');
    Auth().setAuthToken({ ...res.data, isLogin: false }, false);

    return res.status;
  } catch (error) {
    console.error(error);

    return error.response ? error.response.status : -1;
  }
}, 2000);

interface IRefreshJwt {
  force: boolean;
}
export const refreshJwt = async (param?: IRefreshJwt) => {
  try {
    const expire = Auth().getPayload().exp;
    const remaining = expire - Math.floor(Date.now() / 1000);
    const commonExpireTime = 60 * 60;

    if (remaining < commonExpireTime || (param && param.force)) {
      const res = await Api().post('auth/refresh', Auth().getAuthData().refreshToken);
      const data = { ...res.data, isLogin: Auth().getAuthData().isLogin };

      Auth().removeAllToken();
      Auth().setAuthToken(data, param && remaining > commonExpireTime ? true : false);
    }
  } catch (error) {
    errorHandler(error);
  }
};

export const logout = async () => {
  await getGuestJwt();
  window.location.href = '/';
};

// TODO: 確認作法
export const checkLogin = (func: Function) => {
  if (Auth().getAuthData().isLogin) {
    func();
  } else {
    store.dispatch(openLoginAlertModal(false));
  }
};
