import React from 'react';
import styled from 'styled-components';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { IGetColumnistArticle } from 'src/types/api/ColumnistArticleApiTypes';

import { dateFormatter } from 'src/utils/Formatter';

import {
  Container,
  Table,
  HRow,
  TRow,
  HOne,
  HTwo,
  HThree,
  ReadMore,
} from 'src/style/IndexTabStyle';
import { blue8, iceblue6, iceblue8, ultrawhite } from 'src/style/theme/Color';
import { handleArticleAncherOnClick } from 'src/components/article/item/ColumnistArticleItem';

const ACTIVE_BG_COLOR = blue8;

const ListContainer = styled(Container)`
  width: 100%;
`;
const HCategory = styled(HOne)`
  width: 22%;
`;
const HTitle = styled(HTwo)`
  width: 42%;
`;
const HAuthor = styled(HTwo)`
  width: 18%;
`;
const HStartDate = styled(HThree)`
  width: 18%;
`;
const Item = styled(TRow)<{ active: boolean }>`
  border-bottom: 1px solid ${iceblue8};
  background-color: ${props => (props.active ? ACTIVE_BG_COLOR : ultrawhite)};
  cursor: pointer;
`;
const Category = styled(HOne)`
  width: 22%;
`;
const Title = styled(HTwo)``;
const Author = styled(HTwo)``;
const StartDate = styled(HThree)`
  color: ${iceblue6};
`;
const MultiLineEllipsisText = styled.div<{ active: boolean }>`
  margin: 0 -1rem 0 0;
  padding: 0 1rem 0 0;
  width: calc(100% - 1rem);
  max-height: 2.4rem;
  line-height: 1.2rem;
  overflow: hidden;
  position: relative;
  word-break: break-all;
  :before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
  }
  :after {
    content: '';
    position: absolute;
    right: 0;
    width: 1rem;
    height: 1rem;
    margin-top: 0.2rem;
    background: ${props => (props.active ? ACTIVE_BG_COLOR : ultrawhite)};
  }
`;

interface IProps extends RouteComponentProps {
  header: string[];
  articles: Array<IGetColumnistArticle>;
  hover: number;
  setHover: (idx: number) => void;
  loading: boolean;
  gaClass: string;
  readMoreUrl: string;
}

const listItems: React.FC<IProps> = props => {
  return (
    <ListContainer>
      <Table>
        <HRow>
          <HCategory>{props.header[0]}</HCategory>
          <HTitle>{props.header[1]}</HTitle>
          <HAuthor>{props.header[2]}</HAuthor>
          <HStartDate>{props.header[3]}</HStartDate>
        </HRow>
        {props.articles.map((article, idx) => {
          const activeBool = idx === props.hover;
          return idx < 5 ? (
            <Item
              key={article.id}
              active={activeBool}
              onMouseOver={() => props.setHover(idx)}
              onClick={e => handleArticleAncherOnClick(e, article.id)}
              className={props.gaClass}
            >
              <Category>
                <MultiLineEllipsisText active={activeBool} className="category">
                  {article.columnistArticleCategory ? article.columnistArticleCategory.name : ''}
                </MultiLineEllipsisText>
              </Category>
              <Title>
                <MultiLineEllipsisText active={activeBool} className="title">
                  {article.title}
                </MultiLineEllipsisText>
              </Title>

              <Author>
                <MultiLineEllipsisText active={activeBool}>{article.author}</MultiLineEllipsisText>
              </Author>
              <StartDate>{dateFormatter(article.startDate)}</StartDate>
            </Item>
          ) : (
            ''
          );
        })}
      </Table>
      <ReadMore onClick={() => props.history.push(props.readMoreUrl)} className={props.gaClass}>
        顯示全部
      </ReadMore>
    </ListContainer>
  );
};

export default withRouter(listItems);
