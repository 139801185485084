import React, { useEffect } from 'react';
import AlertModal from 'src/components/ui/interactive/modal/AlertModal';
import LoginAlertModal from 'src/components/ui/interactive/modal/LoginAlertModal';
import LoginFormModal from './LoginFormModal';
import RedirectModal from 'src/components/ui/interactive/modal/RedirectModal';
import { connect } from 'react-redux';
import { ReduxAppState } from 'src/redux/reducers';
import { LoginModalType, CustomModalType } from 'src/types/ModalTypes';

interface IReduxMappingProps {
  isOpen: boolean;
}

const ModalRoot: React.FC<IReduxMappingProps> = props => {
  const disableOnModalOpen = () => {
    document.body.style.overflow = 'hidden';
    if (document.activeElement instanceof HTMLElement) document.activeElement.blur();
  };
  const enableOnModalClose = () => {
    document.body.style.overflow = 'auto';
  };

  useEffect(() => {
    props.isOpen ? disableOnModalOpen() : enableOnModalClose();
  }, [props.isOpen]);

  return (
    <>
      <AlertModal />
      <RedirectModal />
      <LoginAlertModal />
      <LoginFormModal />
    </>
  );
};

const mapStateToProps = (state: ReduxAppState) => {
  return {
    isOpen:
      state.modalReducer.alertModals.length > 0 ||
      state.modalReducer.loginModal.type !== LoginModalType.NONE ||
      state.modalReducer.customModalType !== CustomModalType.NONE ||
      !!state.modalReducer.redirectModal,
  };
};

export default connect(
  mapStateToProps,
  null,
)(ModalRoot);
