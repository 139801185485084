import { AxiosResponse, AxiosError } from 'axios';
import { IPaginatedData } from 'src/types/PaginatedDataTypes';
import { IGetPatentTypes } from 'src/types/IGetPatentTypes';

export const FETCH_BY_IMAGE_RESULT_START = 'FETCH_BY_IMAGE_RESULT_START';
export const FETCH_BY_IMAGE_RESULT_SUCCESS = 'FETCH_BY_IMAGE_RESULT_SUCCESS';
export const FETCH_BY_IMAGE_RESULT_ERROR = 'FETCH_BY_IMAGE_RESULT_ERROR';
export const FETCH_BY_IMAGE_NEXT_PAGE_RESULT_ERROR = 'FETCH_BY_IMAGE_NEXT_PAGE_RESULT_ERROR';

export interface FetchByImageResultState {
  isLoading: boolean;
  paginatedData: IPaginatedData<IGetPatentTypes>;
  nextPageErrMsg?: string;
}

interface FetchByImageResultStartAction {
  type: typeof FETCH_BY_IMAGE_RESULT_START;
}

interface FetchByImageResultSuccessAction {
  type: typeof FETCH_BY_IMAGE_RESULT_SUCCESS;
  response: AxiosResponse;
}

interface FetchByImageResultErrorAction {
  type: typeof FETCH_BY_IMAGE_RESULT_ERROR;
  error: AxiosError;
}

interface FetchByImageNextPageResultErrorAction {
  type: typeof FETCH_BY_IMAGE_NEXT_PAGE_RESULT_ERROR;
  nextPageErrMsg?: string;
}

export type FetchByImageResultActionTypes =
  | FetchByImageResultStartAction
  | FetchByImageResultSuccessAction
  | FetchByImageResultErrorAction
  | FetchByImageNextPageResultErrorAction;
