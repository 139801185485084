import React, { useState } from 'react';
import styled from 'styled-components';
import { FaEllipsisH } from 'react-icons/fa';
import useOutSideClick from 'src/hooks/useOutSideClick';
import { blue7, iceblue2, iceblue5, ultrawhite } from 'src/style/theme/Color';
import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import { alertMessage } from 'src/utils/ModalUtils';
import DeleteModal from 'src/components/ui/interactive/modal/custom/DeleteModal';
import { connect } from 'react-redux';
import { ReduxAppState } from 'src/redux/reducers';
import { openCustomModal, closeCustomModal } from 'src/redux/actions/modalAction';
import { CustomModalType } from 'src/types/ModalTypes';
import { SystemMessage } from 'src/apps/applicationMessages';

const Container = styled.div`
  position: relative;
`;

const IconBox = styled.span`
  :hover {
    cursor: pointer;
  }
`;

const PopupContent = styled.div`
  position: absolute;
  z-index: 1;
  right: 50%;
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  background-color: ${ultrawhite};
`;

const Item = styled.div`
  padding: 4px 16px;
  color: ${iceblue2};
  white-space: nowrap;
  :hover {
    cursor: pointer;
    background-color: ${blue7};
  }
`;

interface IReduxMappingProps {
  customModalType: CustomModalType;
  customModalId?: number;
  openCustomModal: (customModalType: CustomModalType, customModalId?: number) => void;
  closeCustomModal: () => void;
}

interface IProps extends IReduxMappingProps {
  id: number;
  name: string;
  recordStatus: string;
  updateRecordStatus: (id: number, recordStatus: string) => void;
  handleEditClick: (subMemberId: number) => void;
  setIsLoading: (isLoading: boolean) => void;
}

// TODO: dropdown 目錄下的東西，應該整合成Popup元件
// TODO: 子會員CSS重構
/** 子會員設定 */
const SubMemberSetting: React.FC<IProps> = props => {
  const [isShow, setIsShow] = useState(false);
  const node = useOutSideClick({ show: isShow, setShow: setIsShow });

  /** 停用子會員帳號 */
  const disableSubMember = async () => {
    props.closeCustomModal();
    setIsShow(false);
    props.setIsLoading(true);

    try {
      const response = await Api().patch(`member/sub/${props.id}/disable`);
      if (response.status === 200) {
        props.updateRecordStatus(props.id, 'D');
        alertMessage(SystemMessage.SUBMEMBER_DISABLE_SUCCESS);
      }
    } catch (error) {
      errorHandler(error);
    }

    props.setIsLoading(false);
  };

  /** 啟用子會員帳號 */
  const enableSubMember = async () => {
    setIsShow(false);
    props.setIsLoading(true);

    try {
      const response = await Api().patch(`member/sub/${props.id}/enable`);
      if (response.status === 200) {
        props.updateRecordStatus(props.id, 'A');
        alertMessage(SystemMessage.SUBMEMBER_ENABLE_SUCCESS);
      }
    } catch (error) {
      errorHandler(error);
    }

    props.setIsLoading(false);
  };

  // 多個項目有同一個類型的Modal時，原本Modal的用法在目前的設計下會導致只顯示最後一個，
  // 為了避免此種情況，增加customModalId做為額外識別條件。待往後Modal重構解決此類問題。
  const isDisableSubMemberModalOpen =
    props.customModalType === CustomModalType.DISABLE_SUBMEMBER && props.customModalId === props.id;

  return (
    <Container ref={node as any}>
      <IconBox onClick={() => setIsShow(!isShow)}>
        <FaEllipsisH color={iceblue5} />
      </IconBox>
      {isShow && (
        <PopupContent>
          <Item onClick={() => props.handleEditClick(props.id)}>編輯</Item>
          {props.recordStatus !== 'D' && (
            <Item
              onClick={() => props.openCustomModal(CustomModalType.DISABLE_SUBMEMBER, props.id)}
            >
              停用
            </Item>
          )}
          {props.recordStatus !== 'A' && <Item onClick={enableSubMember}>啟用</Item>}
        </PopupContent>
      )}
      {isDisableSubMemberModalOpen && (
        <DeleteModal
          message={`停用帳號（ ${
            props.name
          } ）後，該會員無法登入或使用會員的所有功能，但也可重新啟用，東西不會被刪掉，請問是否要停用？`}
          deleteButtonText="停用"
          submit={disableSubMember}
        />
      )}
    </Container>
  );
};

const mapStateToProps = (state: ReduxAppState) => {
  return {
    customModalType: state.modalReducer.customModalType,
    customModalId: state.modalReducer.customModalId,
  };
};

const mapDispatchToProps = {
  openCustomModal,
  closeCustomModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SubMemberSetting);
