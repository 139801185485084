import React, { useState, useEffect } from 'react';
import { IFacetFieldCount } from 'src/types/PatentSearchTypes';
import { FilteredKeyword } from 'src/style/AccordionStyle';

/** Accordion 共用邏輯 */
function useAccordion(
  content: Array<IFacetFieldCount>,
): {
  isActive: boolean;
  searchKeyword: string;
  filteredContent: Array<IFacetFieldCount>;
  togglePanel: () => void;
  handleKeywordChange: (e: any) => void;
  getHighlightedText: (text: string, highlight: string) => JSX.Element | string;
} {
  const [isActive, setIsActive] = useState(true);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [filteredContent, setFilterContent] = useState(content);

  useEffect(() => {
    setFilterContent(content);
  }, [content]);

  const togglePanel = () => {
    setIsActive(!isActive);
  };

  const handleKeywordChange = (e: any) => {
    const keyword = e.target.value;

    if (keyword !== '') {
      let newFilteredContent = content.filter(function(value) {
        const regExpQuote = keyword.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');

        return value.name.search(new RegExp(regExpQuote, 'i')) !== -1;
      });
      setFilterContent(newFilteredContent);
    } else {
      // 查詢字串為空，還原初始值
      setFilterContent(content);
    }

    setSearchKeyword(keyword);
  };

  const getHighlightedText = (text: string, highlight: string) => {
    // 查詢字串非空才split文字
    if (highlight !== '') {
      const regExpQuote = highlight.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
      let parts = text.split(new RegExp(`(${regExpQuote})`, 'gi'));
      return (
        <span>
          {parts.map((part, i) => (
            <FilteredKeyword
              key={i}
              isMatched={i === 1 && part.toLowerCase() === highlight.toLowerCase()}
            >
              {part}
            </FilteredKeyword>
          ))}
        </span>
      );
    } else {
      return text;
    }
  };

  return {
    isActive,
    searchKeyword,
    filteredContent,
    togglePanel,
    handleKeywordChange,
    getHighlightedText,
  };
}

export default useAccordion;
