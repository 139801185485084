import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { FaPlus, FaMinus } from 'react-icons/fa';

import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import { IGetPatentSubscriptionShare } from 'src/types/api/ShowcaseApiTypes';
import {
  IGetPatentSubscription,
  IGetPatentCollectionCategory,
} from 'src/types/api/ShowcaseApiTypes';
import { IGetRssFollow, IGetRssFollowShareMetadata } from 'src/types/api/NewsApiTypes';

import { LinkOut } from 'src/utils/LinkOut';
import { blue1, blue2, blue8 } from 'src/style/theme/Color';

import { connect } from 'react-redux';
import { ReduxAppState } from 'src/redux/reducers';
import { ITokenPayload } from 'src/apps/auth/Auth';

const ToggleButton = styled.div<{ toggle: boolean }>`
  z-index: 1;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 12px;
  right: 32px;
  position: absolute;
  border-radius: 50%;
  background-color: ${blue2};
  text-align: center;
  :hover {
    cursor: pointer;
  }
  span {
    padding: 4px 0 0;
    display: block;
    color: ${blue8};
    font-weight: bold;
  }
`;
const Container = styled.div<{ toggle: boolean }>`
  padding: ${props => props.toggle && '0 0 30px 0'};
  position: relative;
  background-color: ${blue1};
`;
const Anchor = styled.div`
  margin: auto;
  width: 75%;
  min-width: 1024px;
  max-width: 1440px;
  display: flex;
`;
const Sectors = styled.div`
  margin: auto;
  width: auto;
  display: flex;
  flex-flow: row;
  color: #ffffff;
`;
const Sector = styled.div`
  padding: 15px 15px;
`;
const Feature = styled.div<{ toggle: boolean }>`
  display: ${props => (props.toggle ? 'block' : 'none')};
  margin: auto;
  padding: 2px 0;
  text-align: left;
  a,
  div {
    color: #aaaaaa;
  }
  :hover {
    cursor: pointer;
  }
`;
const SectorHeader = styled(Feature)`
  margin: 0 0 8px 0;
  color: #ffffff;
`;

const sectors: Array<ISectors> = [
  {
    sector: '專利專區',
    path: '/showcase',
    features: [
      { feature: '推薦專利', src: '/showcase/recommend' },
      { feature: '最新專利', src: '/showcase/new' },
      { feature: '我的收藏', src: '/showcase/collections' },
      { feature: '專利追蹤', src: '/showcase/tracking' },
      { feature: '共享追蹤', src: '/showcase/shares' },
      { feature: '專利趨勢統計', src: '/showcase/industrialTrends' },
    ],
  },
  {
    sector: '專利檢索',
    path: '/search',
    features: [
      { feature: '基本檢索', src: '/search/basic' },
      { feature: '進階檢索', src: '/search/advanced' },
      { feature: '以文找文', src: '/search/searchByText' },
      { feature: '表格檢索', src: '/search/form' },
      { feature: '號碼檢索', src: '/search/number' },
      { feature: '布林檢索', src: '/search/boolean' },
      // { feature: '全球書目', src: '/search/docdb' },
      { feature: '美國 PTAB 檢索', src: '/search/patentTrial' },
      { feature: '設計專利以圖找圖', src: '/search/searchByImage' },
    ],
  },
  {
    sector: '新聞專區',
    path: '/news',
    features: [
      { feature: '新聞搜尋', src: '/news/search' },
      { feature: '新聞頻道', src: '/news/latest' },
      { feature: '我的頻道', src: '/news/subscribe' },
      { feature: '他人分享頻道', src: '/news/shares' },
    ],
  },
  {
    sector: '開放資料',
    path: '/data',
    features: [
      { feature: '公報 OPEN DATA 下載', src: '/data/opendata' },
      { feature: '公報清單查詢 API', src: '/data/inventPub' },
      { feature: '公報ZIP檔下載 API', src: '/data/inventPubReport' },
      { feature: '專利查詢 API', src: '/data/search' },
      { feature: '專利說明書 API', src: '/data/patent' },
      { feature: '雙語詞彙 API', src: '/data/bilingual' },
      { feature: '技術辭庫下載', src: '/data/technical' },
    ],
  },
  {
    sector: '專欄文章',
    path: '/article',
    features: [
      { feature: '專欄文章', src: '/article' },
      { feature: '影片專區', src: '/article/video' },
    ],
  },
  {
    sector: '學界專家',
    path: '/expert',
    features: [{ feature: '專家履歷', src: '/expert' }],
  },
  {
    sector: '常見問題',
    path: '/question',
    features: [
      { feature: '網站導覽', src: '/question/sitemap' },
      // { feature: '使用教學', src: '' },
      { feature: 'FAQ', src: '/question/faq' },
      { feature: '聯絡我們', src: '/question/contactus' },
    ],
  },
  {
    sector: '相關連結',
    path: '',
    features: [
      // { feature: 'Facebook 粉專', src: '' },
      // { feature: 'Line@ 生活圈', src: '' },
      {
        feature: 'Youtube 頻道',
        src: '',
        link: 'https://www.youtube.com/channel/UCN6FizJF6kY39W0SWqb5d8Q/videos',
      },
    ],
  },
];

interface ISectors {
  sector: string;
  path: string;
  features: Array<IFeatures>;
}
interface IFeatures {
  feature: string;
  src: string;
  link?: string;
}

interface IReduxMappingProps {
  member: ITokenPayload;
  isLogin: boolean;
}
interface IProps extends RouteComponentProps, IReduxMappingProps {}

function SitemapLayout({ member, isLogin, history }: IProps) {
  const [toggle, setToggle] = useState(false);
  const toggleHandler = () => {
    setToggle(prev => !prev);
  };

  const [fetched, setFetched] = useState(false);
  const [collects, setCollects] = useState<Array<IGetPatentCollectionCategory>>([]);
  const [tracks, setTracks] = useState<Array<IGetPatentSubscription>>([]);
  const [trackShares, setTrackShares] = useState<Array<IGetPatentSubscriptionShare>>([]);
  const [follow, setFollow] = useState<Array<IGetRssFollow>>([]);
  const [followShares, setFollowShares] = useState<Array<IGetRssFollowShareMetadata>>([]);
  useEffect(() => {
    const fetchPersonalMenu = async () => {
      if (member.info.guest) {
        setFetched(true);
      } else {
        try {
          const col = Api().get('patent/collection/category');
          const sub = Api().get('patent/subscription');
          const subShare = Api().get('patent/subscription/share/notification');
          const fol = Api().get('rss/follow');
          const folShare = Api().get('rss/follow/share/notification');
          const resArr = await Promise.all([col, sub, subShare, fol, folShare]);

          resArr[0].data.length > 0 && setCollects(resArr[0].data);
          resArr[1].data.length > 0 && setTracks(resArr[1].data);
          resArr[2].data.length > 0 && setTrackShares(resArr[2].data);
          resArr[3].data.length > 0 && setFollow(resArr[3].data);
          resArr[4].data.length > 0 && setFollowShares(resArr[4].data);
        } catch (error) {
          errorHandler(error);
        } finally {
          setFetched(true);
        }
      }
    };

    fetchPersonalMenu();
  }, [member.info.guest]);

  const replaceUrl = (item: IFeatures) => {
    switch (item.feature) {
      case '我的收藏':
        return item.src + (collects.length > 0 ? `/${collects[0].name}` : '');
      case '專利追蹤':
        return item.src + (tracks.length > 0 ? `/${tracks[0].name}` : '');
      case '共享追蹤':
        return item.src + (trackShares.length > 0 ? `/${trackShares[0].name}` : '');
      case '我的頻道':
        return item.src + (follow.length > 0 ? `/${follow[0].keyword}` : '');
      case '他人分享頻道':
        return item.src + (followShares.length > 0 ? `/${followShares[0].keyword}` : '');
      default:
        return item.src;
    }
  };

  const scollToTop = useCallback(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {fetched && (
        <Container toggle={toggle}>
          <ToggleButton toggle={toggle} onClick={toggleHandler}>
            <span>{toggle ? <FaMinus /> : <FaPlus />}</span>
          </ToggleButton>
          <Anchor>
            <Sectors>
              {sectors.map(item => {
                return (
                  <Sector key={item.sector}>
                    <SectorHeader
                      toggle={true}
                      onClick={() => {
                        switch (item.sector) {
                          case '相關連結':
                            return toggleHandler();
                          default:
                            window.scrollTo(0, 0);
                            return history.push(item.path);
                        }
                      }}
                    >
                      {item.sector}
                    </SectorHeader>
                    {item.features.map(link => (
                      <Feature key={link.feature} toggle={toggle}>
                        {link.link ? (
                          <LinkOut name={link.feature} href={link.link} />
                        ) : (
                          <Link to={replaceUrl(link)} onClick={scollToTop}>
                            {link.feature}
                          </Link>
                        )}
                      </Feature>
                    ))}
                  </Sector>
                );
              })}
            </Sectors>
          </Anchor>
        </Container>
      )}
    </>
  );
}

const mapStateToProps = (state: ReduxAppState) => {
  return {
    member: state.memberReducer.memberPayload,
    isLogin: state.memberReducer.authData.isLogin,
  };
};

export default connect(
  mapStateToProps,
  null,
)(withRouter(SitemapLayout));
