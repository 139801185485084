import React from 'react';
import styled from 'styled-components';
import time_range_and_order from 'src/assets/images/manual/search-result-analysis/time_range_and_order.png';

const ImgContainer = styled.div<{ height: string; imageUrl: string }>`
  position: relative;
  height: ${props => props.height}px;
  padding: 10px 0;
  background-image: url(${props => props.imageUrl});
  background-size: contain;
  background-repeat: no-repeat;
`;
export default function SearchResultAnalysisManual() {
  return (
    <>
      <p>可以顯示不同的時間範圍及進行排序</p>
      <br />
      <ImgContainer height="700" imageUrl={time_range_and_order} />
    </>
  );
}
