import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';

import BrowseLogContent from 'src/components/ui/content/Member/BrowseLogContent';
import { IGetPatentViewed } from 'src/types/api/MemberApiTypes';
import LogItem from './item/LogItem';

const BrowseLog: React.FC = () => {
  const [logs, setLogs] = useState<Array<IGetPatentViewed>>();
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const fetchData = async () => {
      try {
        const res = await Api().get('patent/viewed', {
          cancelToken: source.token,
        });
        setLogs(res.data);
      } catch (error) {
        errorHandler(error);
      }
    };
    fetchData();
    return () => source.cancel();
  }, []);
  return (
    <BrowseLogContent sector="個人專區" feature="瀏覽紀錄">
      <>
        {logs && logs.length > 0 && logs.map(item => <LogItem key={item.createTime} log={item} />)}
      </>
    </BrowseLogContent>
  );
};

export default BrowseLog;
