import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { FaSearch, FaQuestionCircle, FaUserCircle, FaTimes } from 'react-icons/fa';

import { blue3, ultrawhite } from 'src/style/theme/Color';
import { zProfile } from 'src/style/theme/Z-Index';
import ProfileDropdown from 'src/components/common/nav/top-nav/dropdown/ProfileDropdown';
import useOutSideClick from 'src/hooks/useOutSideClick';

import useLoginChecker from 'src/apps/auth/useLoginChecker';
import { ArticleContext } from 'src/components/article/item/ColumnistArticleItem';

const Container = styled.div`
  z-index: ${zProfile};
  height: 100%;
  display: flex;
  align-items: center;
  background-color: ${ultrawhite};
`;
const IconGroup = styled.div`
  display: flex;
`;
const IconStyle = styled.i<{ vis?: boolean }>`
  margin: 0 10px;
  display: block;
  visibility: ${props => props.vis && 'hidden'};
  svg {
    vertical-align: middle;
  }
`;
const PicStyle = styled.div<{ vis: boolean }>`
  padding: 0 20px 0 26px;
  position: relative;
  visibility: ${props => props.vis && 'hidden'};
  svg {
    vertical-align: middle;
  }
  a {
    font-size: 18px;
    color: ${blue3};
  }
`;
const ProfileDropdownStyle = styled.div`
  z-index: 1;
  margin: 10px 0;
  min-width: 296px;
  max-width: 40vw;
  right: 16px;
  position: absolute;
`;

interface IProps extends RouteComponentProps {
  handleSearch: () => void;
  isSearch: boolean;
}

function Profile(props: IProps) {
  const isArticlePage = React.useContext(ArticleContext);

  const [dropdown, setDropdown] = useState(false);
  const isLogin = useLoginChecker();

  const node = useOutSideClick({
    show: dropdown,
    setShow: setDropdown,
  });

  return (
    <Container>
      <IconGroup>
        <IconStyle>
          {isArticlePage
            ? null
            : props.isSearch
            ? !props.location.pathname.match(/^\/result/) && (
                <FaTimes color="#7b8387" size="20px" onClick={props.handleSearch} />
              )
            : !props.location.pathname.match(/^\/result/) && (
                <FaSearch color={blue3} size={20} onClick={props.handleSearch} />
              )}
        </IconStyle>
        <IconStyle vis={props.isSearch}>
          {isArticlePage ? (
            <a href="/question/faq">
              <FaQuestionCircle color={blue3} size={20} />
            </a>
          ) : (
            <Link to="/question/faq">
              <FaQuestionCircle color={blue3} size={20} />
            </Link>
          )}
        </IconStyle>
      </IconGroup>
      <PicStyle vis={props.isSearch}>
        {isLogin ? (
          <i>
            <FaUserCircle
              color={blue3}
              size="48px"
              onClick={() => {
                setDropdown(prev => !prev);
              }}
            />
          </i>
        ) : isArticlePage ? (
          <a href="/login">登入</a>
        ) : (
          <Link to="/login">登入</Link>
        )}
        <ProfileDropdownStyle ref={node as any}>
          {dropdown && <ProfileDropdown setDropdown={setDropdown} />}
        </ProfileDropdownStyle>
      </PicStyle>
    </Container>
  );
}

export default withRouter(Profile);
