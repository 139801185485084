import React from 'react';
import styled from 'styled-components';
import { FaSearch } from 'react-icons/fa';
import { zToolbar } from 'src/style/theme/Z-Index';
import { ultrawhite, blue3rgba } from 'src/style/theme/Color';

const Container = styled.div`
  position: fixed;
  z-index: ${zToolbar};
  top: 96px;
  right: 48px;
  padding: 8px 0;
  border-radius: 4px;
  box-shadow: 0 5px 20px -5px rgba(0, 63, 84, 0.3);
  background-image: linear-gradient(127deg, #1f8ba3, #323846);
  color: ${ultrawhite};
`;

const Shortcut = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  background-color: ${props => props.active && blue3rgba(0.2)};
  padding: 8px 16px;

  :hover {
    background-color: ${blue3rgba(0.2)};
    cursor: pointer;
  }

  svg {
    margin-right: 8px;
  }
`;

interface IProps {
  activePane: string;
  handleShortcutClick: (activePane: string) => void;
}

const Shortcuts: React.FC<IProps> = props => {
  const rightNavs = ['IPC 代碼', '行業代碼'];

  return (
    <Container>
      {rightNavs.map(item => (
        <Shortcut
          key={item}
          active={props.activePane === item}
          onClick={() => props.handleShortcutClick(item)}
        >
          <FaSearch />
          <span>{item}</span>
        </Shortcut>
      ))}
    </Container>
  );
};

export default Shortcuts;
