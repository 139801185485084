import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import { FaPlus, FaChevronLeft } from 'react-icons/fa';

import { IGetMemberSub } from 'src/types/api/MemberApiTypes';
import Toolbar from 'src/components/ui/interactive/Toolbar';
import ManageContent from 'src/components/ui/content/Member/ManageContent';
import ManageItem from './item/ManageItem';
import Edit from 'src/components/member/Edit';

const ItemContainer = styled.div`
  position: relative;
  svg {
    padding: 4px 0 0;
  }
`;

function Manage() {
  const [members, setMembers] = useState<Array<IGetMemberSub>>();
  const [showMembers, setShowMembers] = useState(true);
  const [subMemberId, setSubMemberId] = useState<number>();

  const handlePageSwitch = () => {
    // 關閉時編輯頁時清空 subMemberId
    showMembers && setSubMemberId(undefined);
    setShowMembers(prev => !prev);
  };

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const res = await Api().get('member/sub');

        setMembers(res.data);
      } catch (error) {
        errorHandler(error);
      }
    };

    fetchMembers();
  }, [showMembers]);

  /** 更新頁面上的子會員狀態。 */
  const updateRecordStatus = (id: number, recordStatus: string) => {
    // TODO: 確認作法
    const newSubMembers =
      members &&
      members.map(item => {
        if (item.id === id) {
          return {
            ...item,
            recordStatus: recordStatus,
          };
        } else {
          return item;
        }
      });

    setMembers(newSubMembers);
  };

  /** 子會員編輯按鈕點擊。 */
  const handleEditClick = (subMemberId: number) => {
    setSubMemberId(subMemberId);
    setShowMembers(false);
  };

  return showMembers ? (
    <ManageContent sector="個人專區" feature="管理會員帳號">
      <>
        {members &&
          members.length > 0 &&
          members.map((item, idx) => (
            <ManageItem
              key={item.email + idx}
              updateRecordStatus={updateRecordStatus}
              handleEditClick={handleEditClick}
              {...item}
            />
          ))}
        <Toolbar
          redFuncs={
            <ItemContainer onClick={handlePageSwitch}>
              <FaPlus />
              &ensp;新增子會員
            </ItemContainer>
          }
        />
      </>
    </ManageContent>
  ) : (
    <Edit
      isSubMember={true}
      subMemberId={subMemberId}
      handlePageSwitch={handlePageSwitch}
      toolbar={
        <Toolbar
          redFuncs={
            <ItemContainer onClick={handlePageSwitch}>
              <FaChevronLeft />
              &ensp;會員列表
            </ItemContainer>
          }
        />
      }
    />
  );
}

export default Manage;
