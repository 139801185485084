import React, { useState, useEffect } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import styled from 'styled-components';
import axios from 'axios';

import MobileVideoCard from 'src/components/mobile/article/MobileVideoCard';
import InfiniteScroll from 'src/components/ui/interactive/InfiniteScroll';
import { Spinner } from 'src/components/ui/interactive/Spinner';

import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import { SystemMessage } from 'src/apps/applicationMessages';

import { blue2, blue8 } from 'src/style/theme/Color';
import { IGetFilm } from 'src/types/api/FilmApiTypes';

const NoResult = styled.div`
  margin: 16px 32px;
  padding: 16px 32px;
  background-color: ${blue8};

  div {
    color: ${blue2};
    font-weight: 600;
    margin-bottom: 8px;
  }
`;
const ContentStyle = styled.div`
  padding: 0 0 16px;
  width: 100%;
`;

interface IProps {}

const MobileVideo: React.FC<IProps> = () => {
  const [videos, setVideos] = useState<Array<IGetFilm>>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [noData, setNoData] = useState(false);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);
  const [pageNo, setPageNo] = useState<number>(1);
  const [isNextPageLoading, setIsNextPageLoading] = useState<boolean>(false);
  const [isNextPageError, setIsNextPageError] = useState<boolean>(false);

  const loadClickHandler = () => {
    unstable_batchedUpdates(() => {
      setIsNextPageLoading(true);
      setPageNo(pageNo + 1);
    });
  };
  const reloadClickHandler = () => {
    if (pageNo === 1) {
      setIsLoading(true);
    } else {
      setIsNextPageLoading(true);
    }
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const fetchData = async () => {
      try {
        const res = await Api().get('film/lists', {
          params: {
            pageNo: pageNo,
          },
          cancelToken: source.token,
        });

        setTotalCount(res.data.total);
        setTotalPages(res.data.totalPages);
        if (res.data.data && res.data.data.length > 0) {
          setVideos(art => [...(art || []), ...res.data.data]);
          isNextPageLoading && setIsNextPageError(false);
          setNoData(false);
        } else {
          setNoData(true);
        }
      } catch (error) {
        errorHandler(error);
        isNextPageLoading && setIsNextPageError(true);
      } finally {
        setIsLoading(false);
        setIsNextPageLoading(false);
      }
    };

    (isLoading || isNextPageLoading) && fetchData();

    return () => source.cancel();
  }, [pageNo, isLoading, isNextPageLoading]);

  return (
    <ContentStyle>
      {isLoading && <Spinner width="150px" />}
      {!isLoading && noData ? (
        <NoResult>
          <div>{SystemMessage.COLUMNIST_ARTICLE_NO_DATA}</div>
        </NoResult>
      ) : (
        <InfiniteScroll
          isNextPageLoading={isNextPageLoading}
          isNextPageError={isNextPageError}
          errorMessage={'系統發生異常'}
          handleNextPageLoad={loadClickHandler}
          handleReload={reloadClickHandler}
          totalCount={totalCount}
          currentPage={pageNo}
          totalPages={totalPages}
          loadMorePadding={'0'}
        >
          {videos && videos.map(item => <MobileVideoCard key={item.id} columnData={item} />)}
        </InfiniteScroll>
      )}
    </ContentStyle>
  );
};

export default MobileVideo;
