import React, { useState, useEffect } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import axios from 'axios';

import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import { IPaginatedData } from 'src/types/PaginatedDataTypes';
import { IGetPatentTypes } from 'src/types/IGetPatentTypes';

import PatentTrack from 'src/components/ui/content/Showcase/TrackContent';
import TrackItem from 'src/components/showcase/item/TrackItem';
import { Spinner } from 'src/components/ui/interactive/Spinner';
import {
  TutorialContainer,
  TutorialTitle,
  TutorialContent,
  TutorialHighlight,
} from 'src/style/TutorialStyle';
import InfiniteScroll from 'src/components/ui/interactive/InfiniteScroll';

interface IProps {
  isShare: boolean;
  shareUser?: string;
  feature: string;
  name: string;
  keyword: string;
  trackId: number;
  handleSubmit: (option: string, trackId: number, data: string) => void;
}

const Track: React.FC<IProps> = props => {
  const [noTracks, setNoTracks] = useState<boolean>(false);
  const [patents, setPatents] = useState<IPaginatedData<IGetPatentTypes>>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [pageNo, setPageNo] = useState<number>(1);
  const [isNextPageLoading, setIsNextPageLoading] = useState<boolean>(false);
  const [isNextPageError, setIsNextPageError] = useState<boolean>(false);

  const handleSubmit = (option: string, data: string) => {
    props.handleSubmit(option, props.trackId, data);
  };

  const loadClickHandler = () => {
    unstable_batchedUpdates(() => {
      setPageNo(pageNo + 1);
      setIsNextPageLoading(true);
    });
  };

  const reloadClickHandler = () => {
    if (pageNo === 1) {
      setIsLoading(true);
      setPatents(undefined);
    } else {
      setIsNextPageLoading(true);
    }
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const fetchData = async () => {
      try {
        const subscriptionUrl = `patent/subscription/${props.trackId}`;
        const apiUrl = props.isShare ? `${subscriptionUrl}/share` : subscriptionUrl;

        const res = await Api().get(apiUrl, {
          params: {
            pageNo: pageNo,
          },
          cancelToken: source.token,
        });

        if (isNextPageLoading && patents) {
          setPatents({
            ...patents,
            data: [...patents.data, ...res.data.data],
            pageNo: res.data.pageNo,
          });
        } else {
          setPatents(res.data);
        }

        isNextPageLoading && setIsNextPageError(false);
      } catch (error) {
        errorHandler(error);
        isNextPageLoading && setIsNextPageError(true);
      } finally {
        setIsLoading(false);
        setIsNextPageLoading(false);
      }
    };

    if (
      props.trackId !== -1 &&
      (!patents || pageNo !== patents.pageNo) &&
      (isLoading || isNextPageLoading)
    ) {
      fetchData();
    } else if (props.trackId === -1) {
      setNoTracks(true);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }

    return () => source.cancel();
  }, [patents, props.trackId, pageNo, isNextPageLoading, props.isShare, isLoading]);

  const switchContent = () => {
    const listPatents = () => {
      if (patents) {
        return (
          <InfiniteScroll
            isNextPageLoading={isNextPageLoading}
            isNextPageError={isNextPageError}
            errorMessage={'系統發生異常'}
            handleNextPageLoad={loadClickHandler}
            handleReload={reloadClickHandler}
            totalCount={patents.totalCount}
            currentPage={pageNo}
            totalPages={patents.totalPages}
          >
            {patents.data.map(patent => (
              <TrackItem key={patent.id} trackData={patent} />
            ))}
          </InfiniteScroll>
        );
      } else {
        return <div>無法取得資料</div>;
      }
    };

    if (props.isShare) {
      if (noTracks) {
        return (
          <TutorialContainer>
            <TutorialTitle>與他人一同分享新的發現</TutorialTitle>
            <TutorialContent>
              您可以在專利追蹤與他人分享您的追蹤條件，追蹤該檢索條件的專利項目。
            </TutorialContent>
            <TutorialHighlight>
              1. 點擊左側選單的專利追蹤
              <br />
              2. 在既有的專利追蹤標題列點選分享
              <br />
              3. 選擇您想分享的對象
              <br />
              4. 按分享按鈕，您分享的對象即可看見您的追蹤
              <br />
              5. 請他人與您分享，即可在此看到對方追蹤了什麼
            </TutorialHighlight>
          </TutorialContainer>
        );
      } else {
        return listPatents();
      }
    } else {
      if (noTracks) {
        return (
          <TutorialContainer>
            <TutorialTitle>建立新的追蹤</TutorialTitle>
            <TutorialContent>
              看到本頁代表您尚未建立專利追蹤，您可以在搜尋結果右方工具列新增追蹤，追蹤該檢索條件的檢索結果。
            </TutorialContent>
            <TutorialHighlight>
              1. 點擊專利追蹤
              <br />
              2. 在名稱設定的白色輸入框輸入名稱
              <br />
              3. 追蹤條件中會顯示目前的檢索條件
              <br />
              4. 按追蹤按鈕即可完成追蹤
            </TutorialHighlight>
          </TutorialContainer>
        );
      } else {
        return listPatents();
      }
    }
  };

  return (
    <PatentTrack
      sector="專利專區"
      feature={props.feature}
      name={props.name}
      keyword={props.keyword}
      trackId={props.trackId}
      submit={handleSubmit}
      noTracks={noTracks}
      isShare={props.isShare}
      shareUser={props.shareUser}
    >
      {isLoading ? <Spinner width="150px" /> : switchContent()}
    </PatentTrack>
  );
};

export default Track;
