import React from 'react';
import { Container, Message } from 'src/style/AccordionStyle';

interface IProps {
  message?: string;
}

/** 查無結果 */
const NoResult = ({ message }: IProps) => (
  <Container>
    <Message>
      {message || '本次檢索結果主題性不足或是查無資料，請嘗試變更主題或修改關鍵字。'}
    </Message>
  </Container>
);

export default NoResult;
