import React from 'react';
import styled from 'styled-components';

const SlideCBox = styled.label<{ checked: boolean }>`
  width: 52px;
  height: 24px;
  display: flex;
  position: relative;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  input + span {
    background-color: ${props => (props.checked ? '#077790' : null)};
  }
  input:checked + span:before {
    transform: translateX(28px);
  }

  span {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #b5e4ec;
    border: solid 1px ${props => (props.checked ? '#077790' : '#7dd3e2')};
    transition: 0.4s;
    border-radius: 100px;
    cursor: pointer;

    :before {
      height: 18px;
      width: 18px;
      left: 1px;
      bottom: 1px;
      position: absolute;
      background-color: #ffffff;
      border: solid 1px ${props => (props.checked ? '#077790' : '#7dd3e2')};
      transition: 0.4s;
      border-radius: 100px;
      content: '';
    }
  }
`;

interface IProps {
  checked: boolean;
  change: (idx: number | null) => void;
  idx?: number;
}

const slideToggle: React.FC<IProps> = props => (
  <SlideCBox checked={props.checked}>
    <input
      type="checkbox"
      checked={props.checked}
      onChange={() => (props.idx ? props.change(props.idx) : props.change(null))}
    />
    <span />
  </SlideCBox>
);

export default slideToggle;
