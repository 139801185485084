import React from 'react';
import { getCountryName } from 'src/utils/PatentUtils';
import { ErrorCode } from 'src/apps/error-handler/ErrorCode';
import OpendataContent from 'src/components/ui/content/Opendata/OpendataContent';
import ApplyNumberPane from 'src/components/opendata/pane/ApplyNumberPane';
import ApplicantNumberPane from 'src/components/opendata/pane/ApplicantNumberPane';
import IpcNumberPane from 'src/components/opendata/pane/IpcNumberPane';

interface IState {
  collapse: boolean;
  activeTab: string;
}

const tabList = ['發明專利總筆數', '前10名IPC件數', '前5名申請人件數'];
const countryList = [
  { name: getCountryName('TW'), value: 'TW' },
  { name: getCountryName('US'), value: 'US' },
  { name: getCountryName('EP'), value: 'EP' },
  { name: getCountryName('JP'), value: 'JP' },
  { name: getCountryName('CN'), value: 'CN' },
];

const typeList = [{ name: '發明公開', value: 'A' }, { name: '發明公告', value: 'B' }];

class Analysis extends React.Component<{}, IState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      collapse: true,
      activeTab: tabList[0],
    };
  }

  toggleTab = (activeTab: string) => {
    if (activeTab !== this.state.activeTab) {
      this.setState({ activeTab: activeTab });
    }
  };
  switchContent = (activeTab: string) => {
    switch (activeTab) {
      case tabList[0]:
        return <ApplyNumberPane countryList={countryList} typeList={typeList} />;
      case tabList[1]:
        return <IpcNumberPane countryList={countryList} typeList={typeList} />;
      case tabList[2]:
        return <ApplicantNumberPane countryList={countryList} typeList={typeList} />;
      default:
        throw new Error(ErrorCode.UNHANDLED_CASE_IN_SWITCH);
    }
  };

  render() {
    return (
      <OpendataContent
        sector="專利專區"
        feature="專利趨勢統計分析年報"
        foldableDescription={{
          abstract:
            '提供各年度發明專利件數統計報表。統計範圍：' +
            (new Date().getFullYear() - 5) +
            ' - ' +
            (new Date().getFullYear() - 1) +
            ' 年',
        }}
        activeTab={this.state.activeTab}
        activeTabHandler={this.toggleTab}
        tabs={tabList}
        contentWithoutPadding={true}
      >
        {this.switchContent(this.state.activeTab)}
      </OpendataContent>
    );
  }
}

export default Analysis;
