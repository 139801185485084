import React from 'react';
import styled from 'styled-components';
// import { FaSortAmountUp } from 'react-icons/fa';
import { ultrawhite, ultrawhitergba, ultrablackrgba } from 'src/style/theme/Color';
import { getCountryName } from 'src/utils/PatentUtils';

const Container = styled.div`
  border-radius: 4px;
  box-shadow: 0 0 50px 0 ${ultrablackrgba(0.1)};
`;
const Header = styled.div`
  height: 88px;
  display: flex;
  flex-flow: row;
  align-items: center;
  color: ${ultrawhite};
  background-image: linear-gradient(95deg, #2ec3c2, #0cafcc);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;
const HeaderText = styled.div`
  padding: 16px 32px;
`;
const HeaderRoute = styled.div`
  color: ${ultrawhitergba(0.8)};
`;
const Feature = styled.div`
  height: 36px;
  line-height: 36px;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  align-items: flex-end;
  span {
    padding: 0 0 0 8px;
    display: block;
    font-size: 15px;
    line-height: 24px;
    font-weight: normal;
  }
`;
const Subheaders = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  color: #979ea1;
  background-color: #edf6f7;
`;
const Description = styled.div`
  padding: 0 24px 0 32px;
  width: 98px;
`;
const Countries = styled.div`
  width: calc(85% - 98px);
  height: 100%;
  display: flex;
  align-items: flex-end;
  padding-bottom: 16px;
`;
const Country = styled.div<{ active: boolean }>`
  position: relative;
  flex-shrink: 0;
  margin-right: 24px;
  font-size: 20px;
  color: ${props => (props.active ? '#424242' : '#828f92')};
  font-weight: ${props => props.active && 'bold'};
  :before {
    content: '';
    float: left;
    position: absolute;
    left: -10%;
    width: 120%;
    background: transparent;
    border-radius: 10px 10px 0 0;
    box-shadow: 0 42px 0 0 ${props => (props.active ? '#077790' : 'transparent')};
    height: 3px;
  }
  :hover {
    cursor: pointer;
  }
`;
/** should be remove, requires check */
// const Sort = styled.div`
//   float: right;
//   width: 15%;
//   padding: 0;
//   color: #0cafcc;
// `;
// // TODO: 翻轉
// const StyleSortIcon = styled(FaSortAmountUp)`
//   margin-left: 8px;
// `;
const Content = styled.div`
  padding: 0 0 24px;
  min-height: 450px;
`;

interface IProps {
  sector: string;
  feature: string;
  update: string;
  activeCountry: string;
  countries: string[];
  subheader: boolean;
  click: (country: string) => void;
  children: JSX.Element;
}

const listContent: React.FC<IProps> = props => (
  <Container>
    <Header>
      <HeaderText>
        <HeaderRoute>
          首頁&nbsp;/&nbsp;{props.sector}&nbsp;/&nbsp;
          {props.feature}
        </HeaderRoute>
        <Feature>
          {props.feature}
          <span>{props.update}</span>
        </Feature>
      </HeaderText>
    </Header>
    {props.subheader ? (
      <Subheaders>
        <Description>國別</Description>
        <Countries>
          {props.countries.map(country => (
            <Country
              key={country}
              active={props.activeCountry === country}
              onClick={() => props.click(country)}
            >
              {getCountryName(country)}
            </Country>
          ))}
        </Countries>
        {/** should be remove, requires check */
        /* <Sort>
          排序
          <StyleSortIcon />
        </Sort> */}
      </Subheaders>
    ) : null}
    <Content>{props.children}</Content>
  </Container>
);

export default listContent;
