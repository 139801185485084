import React from 'react';
import styled from 'styled-components';
import { FaChevronRight } from 'react-icons/fa';
import Checkbox from 'src/components/ui/interactive/checkbox/Checkbox';
import { IIndustrialClassType } from 'src/types/IndustryTypes';
import { blue7, iceblue2, iceblue7 } from 'src/style/theme/Color';

const Container = styled.div<{ selected?: boolean }>`
  padding: 0 16px 3px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${props => props.selected && blue7};
  :hover {
    background-color: ${blue7};
    cursor: pointer;
  }
`;
const CheckboxLabel = styled.div`
  display: flex;
  align-items: center;
`;
const Label = styled.span`
  color: ${iceblue2};
  padding: 4px 0;
`;
const Chevron = styled(FaChevronRight)`
  width: 16px;
  height: 16px;
  color: ${iceblue7};
  margin: 4px 0 0 16px;
`;

interface IProps {
  item: IIndustrialClassType;
  checked: boolean; //是否勾選
  selected?: boolean; //是否展開子項
  partialChecked?: boolean; //是否有子項被勾選
  disableChecked?: boolean; //是否父項被勾選
  hasChildren?: boolean; //是否包含子項
  onActive?: (id: number) => void;
  onCheck: (item: IIndustrialClassType) => void;
}

const MenuItem: React.FC<IProps> = ({
  item,
  checked,
  selected,
  partialChecked,
  disableChecked,
  hasChildren,
  onActive,
  onCheck,
}) => {
  return (
    <Container
      selected={selected}
      onClick={() => typeof onActive !== 'undefined' && onActive(item.id)}
    >
      <CheckboxLabel>
        <Checkbox
          name=""
          checked={checked}
          partialChecked={partialChecked}
          disableChecked={disableChecked}
        >
          <input type="checkbox" checked={checked} onChange={() => onCheck(item)} />
        </Checkbox>
        <Label>{item.description}</Label>
      </CheckboxLabel>
      {hasChildren && <Chevron />}
    </Container>
  );
};
export default MenuItem;
