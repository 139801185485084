import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { ISolrResult } from 'src/types/api/IndexApiTypes';
import { dateFormatter } from 'src/utils/Formatter';

import { blue2, blue8, iceblue5, alarm9, fontblack1, fontblack2 } from 'src/style/theme/Color';

// Layout
const Container = styled.div`
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  :hover {
    background-color: ${blue8};
  }
`;
const LeftContainer = styled.div`
  padding: 20px 8px 20px 32px;
  width: 70%;
`;
const RightContainer = styled.div`
  width: 30%;
  padding: 20px 32px 20px 8px;
  display: flex;
  align-items: flex-start;
  img {
    display: block;
    width: 90%;
    max-height: 200px;
  }
  i {
    margin: 0 0 0 4px;
    display: block;
    color: ${iceblue5};
    font-size: 20px;
    cursor: pointer;
  }
`;

// Elements
const Title = styled.div`
  padding: 0 0 8px 0;
  font-size: 18px;
  a {
    color: ${blue2};
  }
`;
const Text = styled.div`
  color: ${fontblack2};
  padding: 0 0 4px 0;
`;
const TagList = styled.div`
  padding: 6px 0 0 0;
  color: ${iceblue5};
  word-break: break-all;
`;
const HighlightTag = styled.span`
  color: ${fontblack1};
  background-color: ${alarm9};
`;

interface IProps {
  solrResult: ISolrResult;
  targetTag: string;
}

const patentTagsItem: React.FC<IProps> = props => {
  const data = props.solrResult;
  const otherTags = data.tags.filter(tag => tag !== props.targetTag);
  const documentId = data.bibliographic.publicationReference.documentId;
  const notKindA =
    documentId.kind &&
    !documentId.kind.includes('A') &&
    !documentId.kind.includes('P1') &&
    !documentId.kind.includes('P4') &&
    !documentId.kind.includes('P9');
  return (
    <Container>
      <LeftContainer>
        <Title>
          <Link to={`/patent/${data.id}/view`}>
            {data.bibliographic.inventionTitle.title.join(' | ')}
          </Link>
        </Title>
        <Text>
          發明人：
          {data.bibliographic.parties &&
            data.bibliographic.parties.inventor &&
            data.bibliographic.parties.inventor.addressbook.name &&
            data.bibliographic.parties.inventor.addressbook.name.join(' ')}
        </Text>
        <Text>
          申請人：
          {data.bibliographic.parties &&
            data.bibliographic.parties.applicant &&
            data.bibliographic.parties.applicant.addressbook.name &&
            data.bibliographic.parties.applicant.addressbook.name.join(' ')}
        </Text>
        <Text>
          {notKindA ? '公告號：' : '公開號：'}
          {documentId && documentId.docNumber + documentId.kind}
        </Text>
        <Text>
          {notKindA ? '公告日：' : '公開日：'}
          {documentId && documentId.date ? dateFormatter(documentId.date) : '無日期資訊'}
        </Text>
        <TagList>被標記次數：&nbsp;{data.tagTimes}</TagList>
        <TagList>
          標籤：<HighlightTag>{props.targetTag}</HighlightTag>
          {otherTags.length > 0 && '、'.concat(otherTags.join('、'))}
        </TagList>
      </LeftContainer>
      <RightContainer>
        {data.img_alias && data.img_alias.length > 0 && (
          <img
            src={process.env.REACT_APP_API_URL + 'patent/' + data.id + '/img/' + data.img_alias[0]}
            alt=""
          />
        )}
      </RightContainer>
    </Container>
  );
};

export default patentTagsItem;
