export interface IIndustryListItem {
  name: string;
  value: string;
  type: string;
}

export interface IIndustrialClassType {
  id: number;
  description: string;
  children?: IIndustrialClassType[];
}

export interface IIndustrialTrendApiParamType {
  industrialClassIds: Array<number>;
  kind: string;
}

export interface IIndustrialTrendType {
  category: string;
  description: string;
  count: number;
  total: number;
}

export interface IIndustrialTrendRankType {
  [key: string]: INameCountType;
}

export interface IIndustrialTrendApplicantType {
  time: string;
  data: Array<IIndustrialTrendApplicantDataType>;
}
interface IIndustrialTrendApplicantDataType {
  counts: string;
  kindCode: string;
  applicant: string;
}

export interface IIndustrialTrendCardType {
  country: string;
  industrialClassId: string;
  industrialClassDescription: string;
  lastYear: Array<INameCountType>;
  thisYear: Array<INameCountType>;
}
export interface INameCountType {
  name: string;
  count: number;
}

export interface ILineChartType {
  name: string;
  data: Array<ILineChartDataType>;
}

export interface ILineChartDataType {
  count: number;
  time: string;
}

export interface IBubbleChartDataType {
  code: string;
  description: string;
  count: number;
}

export interface IGroupBarChartType {
  start: string;
  end: string;
  data: Array<IGroupBarChartDataType>;
}

export interface IGroupBarChartDataType {
  country: string;
  counts: number;
}

export interface IBarChartType {
  name: string;
  count: number;
  rank?: number;
  percentage: string;
}
export interface IIpcChartType extends IBarChartType {
  description: string;
  children: Array<IIpcChartType>;
}

export enum TimePeriod {
  /** Total 不限時間 */
  U = '不限時間',
  /** Year 年 */
  Y = '年',
  /** Half 半年 */
  H = '半年',
  /** Quarter 季 */
  Q = '季',
  /** Month 月 */
  M = '月',
  /** Week 週 */
  W = '週',
}

export interface IIndustrialRankingApiParamType {
  industrialClassId: string | null;
  timePeriod: keyof typeof TimePeriod;
}
