import React from 'react';
import styled from 'styled-components';

import PatentMiniImage from './PatentMiniImage';

const PatentImgs = styled.div`
  position: relative;
  text-align: center;
  margin: -40px 32px 16px 0;
  z-index: 10px;
`;
const DisplayImg = styled.div<{ bgImg: string }>`
  margin: 4px;
  width: 300px;
  height: 300px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  background-image: ${props => 'url(' + props.bgImg + ')'};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
`;

interface IProps {
  images: string[];
  handleDisplayImgClick: any;
  handleMiniImgClick: any;
  total: number;
}
interface IState {
  displayImgIdx: number;
}

class PatentDisplayImage extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      displayImgIdx: 0,
    };
  }

  handleMiniImgDisplay = (e: any) => {
    var idx: number = e.currentTarget.getAttribute('data-idx');
    this.setState({
      displayImgIdx: idx,
    });
  };

  render() {
    return (
      <PatentImgs>
        <DisplayImg
          bgImg={this.props.images[this.state.displayImgIdx]}
          onClick={() => this.props.handleDisplayImgClick(this.state.displayImgIdx)}
        />
        <PatentMiniImage
          images={this.props.images}
          activeImg={this.state.displayImgIdx}
          openLightbox={this.props.handleMiniImgClick}
          changeDisplay={this.handleMiniImgDisplay}
          totalImg={this.props.total}
        />
      </PatentImgs>
    );
  }
}
export default PatentDisplayImage;
