import {
  FETCH_PATENT_RESULT_START,
  FETCH_PATENT_RESULT_SUCCESS,
  FETCH_PATENT_RESULT_ERROR,
  FETCH_PATENT_NEXT_PAGE_RESULT_ERROR,
  FetchPatentResultState,
  FetchPatentResultActionTypes,
} from 'src/redux/types/patentResultTypes';
import { defaultPaginatedData } from 'src/types/defaultData/paginatedDataDefault';

const initialState: FetchPatentResultState = {
  isLoading: false,
  paginatedData: defaultPaginatedData,
};

const patentResultReducer = (
  state = initialState,
  action: FetchPatentResultActionTypes,
): FetchPatentResultState => {
  switch (action.type) {
    case FETCH_PATENT_RESULT_START:
      return { ...state, isLoading: true, nextPageErrMsg: undefined };
    case FETCH_PATENT_RESULT_SUCCESS: {
      let { data, status } = action.response;
      if (status === 200) {
        if (data.pageNo === 1) {
          return { ...state, paginatedData: data, isLoading: false };
        } else {
          // 第二頁以後串接專利資料List，更新查詢時間與頁碼，其餘不變。
          const paginatedData = state.paginatedData;
          return {
            ...state,
            paginatedData: {
              ...paginatedData,
              costTime: data.costTime,
              data: paginatedData.data.concat(data.data),
              pageNo: data.pageNo,
            },
            isLoading: false,
          };
        }
      } else {
        return { ...initialState };
      }
    }
    case FETCH_PATENT_RESULT_ERROR:
      return { ...initialState };
    case FETCH_PATENT_NEXT_PAGE_RESULT_ERROR:
      return {
        ...state,
        isLoading: false,
        nextPageErrMsg: action.nextPageErrMsg,
      };
    default:
      return { ...state };
  }
};

export default patentResultReducer;
