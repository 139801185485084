import React from 'react';
import styled from 'styled-components';
import { Route, RouteComponentProps } from 'react-router-dom';

import MailToUser from 'src/components/common/user/MailToUser';

const Container = styled.div`
  margin: auto;
  padding: 16px 0 0;
  width: 75%;
`;

const PWD_DATA = {
  sector: '忘記密碼',
  title: '重設密碼',
  description: '此功能提供會員忘記密碼之用，請輸入先前填寫的註冊信箱，系統將發送重設密碼信。',
  url: 'password/forgot',
};

const CONFIRM_DATA = {
  sector: '未收到確認信',
  title: '會員帳號啟用確認信',
  description:
    '請輸入您先前註冊填寫的 Email，系統將重新發送會員啟用確認信，以完成產業專利知識平台會員啟用',
  url: 'email/confirm/resend',
};

const userLayout: React.FC<RouteComponentProps> = props => (
  <Container>
    <Route
      path={props.match.url + '/forget-password'}
      component={() => <MailToUser {...PWD_DATA} />}
    />
    <Route
      path={props.match.url + '/confirmation'}
      component={() => <MailToUser {...CONFIRM_DATA} />}
    />
  </Container>
);

export default userLayout;
