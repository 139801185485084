import React, { useState } from 'react';
import styled from 'styled-components';

import useLoginChecker from 'src/apps/auth/useLoginChecker';
import { blue8, blue2, blue1 } from 'src/style/theme/Color';
import { Api } from 'src/api/helpers/apiBase';
import { HoverMask } from 'src/style/SideMenuStyle';
import MiniLoader from 'src/components/ui/interactive/MiniLoader';

const KeywordContainer = styled.div<{ isLogin: boolean; last: boolean }>`
  position: relative;
  display: inline-flex;
  align-items: center;
  margin-right: 8px;
  :hover {
    ${props => props.isLogin && 'text-decoration: underline;'}
  }

  :before {
    position: absolute;
    top: 0;
    margin-top: 25px;
    width: 100%;
    height: 10px;
    content: '';
    background-color: transparent;
  }

  :after {
    content: ${props => (props.last ? '' : "','")};
  }
`;
const FeedbackContainer = styled.div`
  border-radius: 4px;
  display: inline-block;
  width: 168px;
  height: 72px;
  background-color: ${blue1};
  box-shadow: 0 5px 20px -5px rgba(0, 63, 84, 0.3);
  color: #ffffff;
  position: absolute;
  z-index: 20;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 0;
  left: 50%;
  margin-top: 32px;
  text-align: center;
  :after {
    width: 0px;
    height: 0px;
    top: -16px;
    right: 76px;
    position: absolute;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent ${blue1} transparent;
    content: '';
  }
`;

const Button = styled.span`
  border-radius: 4px;
  position: relative;
  display: inline-block;
  background-color: ${blue2};
  padding: 8px 16px;
  line-height: 15px;
  font-size: 15px;
  color: ${blue8};
  margin: 0 4px;
  cursor: 'pointer';
`;

const Dot = styled.div`
  :before {
    position: absolute;
    top: 0;
    left: -16px;
    content: '・';
  }
`;

interface ItemProps {
  isLogin: boolean;
  sendFeedback: (keyword: string) => void;
  keyword: string;
  last: boolean;
  isLoading: boolean;
  dot?: boolean;
}

function KeywordItem(props: ItemProps) {
  const [showFeedbackToollip, setShowFeedbackToollip] = useState<boolean>(false);
  const sendFeedback = (keyword: string) => {
    props.sendFeedback(keyword);
  };

  return (
    <KeywordContainer
      last={props.last}
      isLogin={props.isLogin}
      onMouseEnter={() => setShowFeedbackToollip(true)}
      onMouseLeave={() => setShowFeedbackToollip(false)}
    >
      {props.isLoading ? (
        <div>
          {props.dot && <Dot />}
          <MiniLoader diameter="12px" borderWidth="2px" margin="0 8px 0 0" />
        </div>
      ) : (
        <>
          <div>
            {props.dot && <Dot />}
            {props.keyword}
          </div>
        </>
      )}
      {props.isLogin && showFeedbackToollip && (
        <FeedbackContainer>
          <div>回報為不適當關鍵字&nbsp;？</div>
          <div>
            <Button
              key={'keyword'}
              onClick={() => {
                sendFeedback(props.keyword);
              }}
            >
              {<HoverMask borderRadius={'4px'} />}確定
            </Button>
          </div>
        </FeedbackContainer>
      )}
    </KeywordContainer>
  );
}

interface IProps {
  keywords: Array<string>;
  dot?: boolean;
}

function KeywordFeedback(props: IProps) {
  const isLogin = useLoginChecker();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [keywords, setKeywords] = useState<string[]>(props.keywords);

  const sendFeedback = async (keyword: string) => {
    try {
      setIsLoading(true);
      await Api().post('/patent/keyword/disable', {
        keyword: keyword,
      });
    } finally {
      setKeywords(prev => prev.filter(item => item !== keyword));
      setIsLoading(false);
    }
  };

  return (
    <>
      {keywords.map((item, index) => (
        <KeywordItem
          key={item}
          isLogin={isLogin ? true : false}
          sendFeedback={sendFeedback}
          keyword={item}
          last={index === keywords.length - 1}
          isLoading={isLoading}
          dot={props.dot}
        />
      ))}
    </>
  );
}

export default KeywordFeedback;
