import styled from 'styled-components';

import {
  blue1,
  blue2,
  blue6rgba,
  iceblue2,
  iceblue6,
  ultrawhite,
  ultrawhitergba,
  white2,
  white2rgba,
} from 'src/style/theme/Color';
import OVERVIEW_BG_IMG from 'src/assets/images/stat-bg.svg';

export const Container = styled.div<{ number?: number }>`
  width: 60vw;
  min-width: 750px;
  margin: 16px auto;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  min-height: calc(100vh - 62px - 32px);
  background-image: url(${OVERVIEW_BG_IMG});
  background-repeat: no-repeat;
  background-size: cover;
`;

export const Header = styled.div`
  display: flex;
  border-radius: 4px 4px 0 0;
  background-image: linear-gradient(95deg, #2ec3c2, #0cafcc);
  padding: 16px 24px 12px 24px;
`;

export const Avatar = styled.div<{ size?: number }>`
  width: ${props => (props.size ? props.size + 'px' : '100px')};
  height: ${props => (props.size ? props.size + 'px' : '100px')};
  margin-right: 16px;
  margin-bottom: -44px;

  /* CSS Trick for Fit the image inside the square. */
  position: relative;

  :after {
    content: '';
    display: block;
    padding-bottom: 100%; /* The padding depends on the width, not on the height, so with a padding-bottom of 100% you will get a square */
  }

  img {
    position: absolute; /* Take your picture out of the flow */
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; /* Make the picture taking the size of it's parent */
    width: 100%; /* This if for the object-fit */
    height: 100%; /* This if for the object-fit */
    object-fit: cover; /* Equivalent of the background-size: cover; of a background-image */
    object-position: center;
    border-radius: 50%;
    backdrop-filter: blur(8px);
    box-shadow: 2px 8px 8px 0px rgba(38, 50, 55, 0.4);
    background-color: ${ultrawhite}; /* 避免去背圖 背景透到後方 Header */
  }
`;

export const HeaderTitle = styled.div`
  color: ${ultrawhitergba(0.8)};
`;

export const HeaderInfo = styled.div``;

export const Name = styled.span`
  font-size: 24px;
  font-weight: 600;
  margin-right: 16px;
  color: ${ultrawhite};
`;

export const UpdateTime = styled.span`
  color: ${ultrawhitergba(0.8)};
  font-size: 12px;
  font-weight: 500;
`;

export const OverView = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-height: 90px;
  padding: 16px 32px;
  line-height: 2;

  /** CSS trick for changing background opacity */
  position: relative;
  ::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background-image: url(${OVERVIEW_BG_IMG});
    background-size: cover;
    opacity: 0.6;
    z-index: -1;
  }
`;

export const Profile = styled.div`
  display: flex;
  flex-basis: 100%;
  margin-top: 28px;
  color: ${iceblue2};
`;

export const ProfileColumn = styled.div`
  flex-basis: 50%;

  &:nth-of-type(odd) {
    padding-right: 48px;
  }
`;

export const Field = styled.div`
  padding: 4px 0;
  display: flex;
`;

export const FieldName = styled.span`
  color: ${blue1};
  font-weight: bold;
  margin-right: 4px;
  white-space: nowrap;
`;

export const FieldValueList = styled.div`
  word-break: break-all;

  > div {
    padding-bottom: 4px;
    :last-of-type {
      padding-bottom: 0px;
    }
    > span {
      margin-right: 4px;
    }
  }
`;

export const NoResult = styled.div`
  text-align: center;
  color: ${iceblue6};
`;

export const Dot = styled.span`
  :before {
    position: absolute;
    top: 0;
    left: 0;
    content: '・';
  }
`;

export const CardContent = styled.div`
  position: relative;
  padding-left: 1em;
  padding-right: 2em;
  line-height: 2;
`;

export const CardContentNote = styled.span`
  word-break: normal;
  padding: 0 4px;
  font-size: 14px;
  color: #828f92;
  background-color: ${white2};
`;

export const KnowledgeGraphCard = styled.div<{ last?: boolean }>`
  margin: 0px 4px;
  padding: 4px 16px;
  flex-basis: ${props => (props.last ? 'calc(100% - 16px)' : 'calc(33% - 8px)')};
  min-height: 200px;
  background-color: ${white2rgba(0.8)};
  border-radius: 4px;
  color: #436469;

  > * {
    margin-top: 36px;
  }
  > ${CardContent} {
    margin-top: 0px;
    margin-bottom: 4px;
  }
`;
/**
 * 為避免IE11環境下 flex換行條件與其他瀏覽器不同導致版面不一致,
 * 用此style將同一列的component包住,並設置 flex-wrap: nowrap.
 */
export const KnowledgeGraphRow = styled.div`
  width: 100%;
  margin: 4px 0px;
  padding: 4px 0px;
  min-height: 180px;
  border-radius: 4px;
  color: #436469;
  display: flex;
  flex-wrap: nowrap;
  > * {
    margin-top: 36px;
  }
  > ${CardContent} {
    margin-top: 0px;
    margin-bottom: 4px;
  }
`;

export const KnowledgeGraph = styled.div`
  flex: 1 1 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 16px -22px 32px -22px;
`;

export const CardHeader = styled.div`
  display: flex;
  margin-top: 6px;
  line-height: 2.1;
`;

export const CardIcon = styled.div`
  margin: 6px 0px 0px 0px;
  color: ${blue2};
  > svg {
    font-size: 20px;
  }
`;

export const CardTitle = styled.div`
  margin: 2px 0 0 9px;
  color: ${iceblue2};
`;

export const RelatedExpertContainer = styled.div`
  padding: 0 18px;
  margin-top: 16px;
  flex-basis: 100%;
  color: ${blue1};

  > svg {
    width: 30px;
  }

  ${NoResult} {
    display: inline-block;
  }
`;

export const RelatedExpert = styled.span<{ linkable: boolean }>`
  margin: 0 8px;
  color: #424242;

  a {
    padding: 4px 16px;
    border-radius: 4px;
    color: ${blue1};
    background-color: ${blue6rgba(0.5)};
  }
`;
export const ExpertCompareTable = styled.table`
  background-image: url(${OVERVIEW_BG_IMG});
  border-collapse: collapse;
`;
export const Th = styled.th<{ numOfCol: number }>`
  width: ${props => 95 / props.numOfCol + '%'};
  border: solid white;
  border-width: 0 2px;
`;

export const Td = styled.td<{ padding?: string }>`
  padding: ${props => props.padding || '16px 8px'};
  vertical-align: text-top;
  border: solid white;
  border-width: 0 2px;
  // 為了讓內部div可以撐滿高度,需要以下設定
  height: 1px;
  > div {
    height: 100%;
  }
`;
