import { IPaginatedData } from './PaginatedDataTypes';

export interface IExpertRequest {
  keyword: string;
  industrialClassIds?: number[];
  dateRange?: string;
  sortBasis?: string;
  pageNo: number;
  searchType?: string;
}

export interface IExpertBrief {
  id: number;
  name: string;
  photo?: string;
  jobUnit: string;
  jobDepartment: string;
  jobTitle: string;
  expertise?: string;
  patentCount: number;
  inventionPatentCount: number;
  utilityPatentCount: number;
  designPatentCount: number;
  relatedPatents: IPaginatedData<IExpertRelatedPatent>;
  latestPatentId?: number;
}

export interface IExpertRelatedPatent {
  id: string;
  country: string;
  bibliographic: {
    inventionTitle: {
      title: string[];
    };
    parties: {
      inventor: {
        addressbook: {
          name: string[];
        };
      };
      applicant: {
        addressbook: {
          name: string[];
        };
      };
    };
    publicationReference: {
      documentId: {
        date: string;
      };
    };
  };
  inventionSpecification: {
    abstract: {
      text: string[];
    };
  };
}

export interface IExpertLatestPatent {
  id: string;
  bibliographic: {
    inventionTitle: {
      title: string[];
    };
  };
}

export interface IExpert {
  id: number;
  name: string;
  photo?: string;
  email?: string;
  phone?: string;
  jobUnit?: string;
  jobDepartment?: string;
  jobTitle?: string;
  expertise?: string;
  experiences?: Array<IExperience>;
  educations?: Array<IEducation>;
  publications?: Array<IPublication>;
  theses?: Array<IThesis>;
  updateTime: number;
  inventionCount: number;
  utilityCount: number;
  designCount: number;
}

export interface IExperience {
  startYear?: string;
  endYear?: string;
  title: string;
}

export interface IEducation {
  graduateYear: string;
  title: string;
}

export interface IPublication {
  title: string;
}

export interface IThesis {
  title: string;
}

export interface IExpertPatentRequest {
  dateRange?: string;
  sortByDate?: string;
  pageNo: number;
}

export interface IExpertKnowledgeGraph {
  organizations: Array<IKnowledgeGraphItem>;
  industrialClasses: Array<IKnowledgeGraphItem>;
  keywords: Array<IKnowledgeGraphItem>;
  ipcs: Array<IKnowledgeGraphItem>;

  relatedExperts: Array<IKnowledgeGraphRelatedExpert>;
}

export interface IKnowledgeGraphItem {
  name: string;
  note: string;
  number: number;
}

export interface IKnowledgeGraphRelatedExpert {
  id?: number;
  name: string;
  patentCount: number;
  ipcCount: number;
  keywordCount: number;
}

export enum ExpertPatentKind {
  INVENTION = 'INVENTION',
  UTILITY = 'UTILITY',
  DESIGN = 'DESIGN',
}
