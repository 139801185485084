import store from 'src/redux/store';
import { openAlertModal } from 'src/redux/actions/modalAction';
import { LoginModalType, CustomModalType } from 'src/types/ModalTypes';

export const alertMessage = (message: string, description?: string, callback?: () => void) => {
  store.dispatch(
    openAlertModal({
      message: message,
      description: description,
      callback: callback,
    }),
  );
};

export const isModalOpen = () => {
  const reduxState = store.getState();
  return (
    reduxState.modalReducer.alertModals.length > 0 ||
    reduxState.modalReducer.loginModal.type !== LoginModalType.NONE ||
    reduxState.modalReducer.customModalType !== CustomModalType.NONE
  );
};
