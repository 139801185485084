import React, { useState } from 'react';
import styled from 'styled-components';
import Dropzone from 'react-dropzone';
import { FaPaperclip } from 'react-icons/fa';

import SearchByTextContent from 'src/components/ui/content/Search/ByTextContent';
import { Button } from 'src/style/theme/Common';
import { Row } from 'src/style/PatentSearchStyle';

import { RouteComponentProps } from 'react-router-dom';
import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import { alertMessage } from 'src/utils/ModalUtils';
import { getCountryName } from 'src/utils/PatentUtils';
import { SystemMessage } from 'src/apps/applicationMessages';
import { connect } from 'react-redux';
import { composeUuidListRequest } from 'src/redux/actions/patentSearchAction';
import { blue2, iceblue1, iceblue2, iceblue6, white, ultrawhite } from 'src/style/theme/Color';
import { IBasicRequest } from 'src/types/PatentSearchTypes';

const Column = styled.div<{ width: string }>`
  margin-right: 32px;
  width: ${props => props.width};
  color: ${iceblue1};
`;

const Textarea = styled.textarea`
  resize: none;
  width: 100%;
  height: 150px;
  border-radius: 4px;
  border: solid 1px ${iceblue6};
  background-color: ${ultrawhite};
  padding: 8px 16px;
  ::placeholder {
    color: ${iceblue6};
  }
`;

const CustomRadioButton = styled.div`
  margin-right: 24px;
  display: inline-block;
  position: relative;
  user-select: none;
  input {
    /* Hide the browser's default radio button */
    position: absolute;
    opacity: 0;
    cursor: pointer;
    :checked ~ span {
      border: none;
      background-color: ${blue2};
    }
    :checked ~ span::after {
      display: block;
    }
  }

  /* Create a custom radio button */
  span {
    position: absolute;
    top: 3px;
    left: 0;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: solid 1px ${iceblue6};
    ::after {
      content: '';
      position: absolute;
      display: none;
      top: 6px;
      left: 6px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: ${ultrawhite};
    }
  }

  label {
    margin-left: 26px;
    color: ${iceblue1};
    &:hover {
      cursor: pointer;
    }
  }
`;

const DropZoneStyle = styled.div`
  width: 100%;
  height: 150px;
  line-height: 150px;
  text-align: center;
  border-radius: 4px;
  border: dashed 2px ${iceblue6};
  background-color: ${white};
  color: ${iceblue6};

  span {
    margin-right: 16px;
  }
`;

const DropzoneMsg = styled.span<{ color?: string }>`
  color: ${props => props.color};
`;

const StyledFaPaperclip = styled(FaPaperclip)`
  transform: rotateX(180deg);
`;

const ProcessMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  font-size: 20px;
`;

interface IReduxProps {
  composeUuidListRequest: (basicRequest: IBasicRequest) => void;
}

interface IProps extends IReduxProps, RouteComponentProps {}

/** 以文找文 */
const SearchByText: React.FC<IProps> = props => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [selectedCountry, setSelectedCountry] = useState<string>('TW');
  const [text, setText] = useState<string>('');
  const [files, setFiles] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  /** 查詢送出。 */
  const handleSumit = (e: any) => {
    // 停用submit預設行為。
    e.preventDefault();
    if (activeTab === 0 && text.trim() !== '') {
      fetchUuidListByText(selectedCountry, text.trim());
    } else if (activeTab === 1 && files.length > 0) {
      fetchUuidListByFile(selectedCountry, files);
    } else {
      alertMessage(SystemMessage.SEARCH_BY_TEXT_INPUT_EMPTY);
    }
  };

  /** 清除文字與上傳檔案、重設專利國別。 */
  const handleReset = () => {
    setSelectedCountry('TW');
    setText('');
    setFiles([]);
  };

  /** 文字輸入取回專利 UUID List。 */
  const fetchUuidListByText = async (selectedCountry: string, text: string) => {
    setIsLoading(true);

    try {
      const response = await Api().post('patent/similar/text', {
        country: selectedCountry,
        text: text,
      });

      if (response.data === '') {
        setIsLoading(false);
        alertMessage(SystemMessage.SEARCH_RESULT_EMPTY);
      } else {
        // 送出request並跳到搜尋結果頁
        props.composeUuidListRequest({
          queryString: response.data,
          advanceKeywords: [{ keyCode: 'PO', keyword: selectedCountry }],
        });
        props.history.push('/result');
      }
    } catch (error) {
      setIsLoading(false);
      errorHandler(error);
    }
  };

  /** 檔案上傳取回專利 UUID List。 */
  const fetchUuidListByFile = async (selectedCountry: string, files: File[]) => {
    setIsLoading(true);

    let formData = new FormData();
    formData.append('file', files[0]);

    try {
      const response = await Api({
        'Content-Type': 'multipart/form-data',
      }).post(`patent/similar/country/${selectedCountry}/file`, formData);

      if (response.data === '') {
        setIsLoading(false);
        alertMessage(SystemMessage.SEARCH_RESULT_EMPTY);
      } else {
        // 送出request並跳到搜尋結果頁
        props.composeUuidListRequest({
          queryString: response.data,
          advanceKeywords: [{ keyCode: 'PO', keyword: selectedCountry }],
        });
        props.history.push('/result');
      }
    } catch (error) {
      setIsLoading(false);
      errorHandler(error);
    }
  };

  // TODO: 有沒有更好在JS中取得JSX物件的方法?
  const getTextInputJsx = () => {
    return (
      <Textarea
        value={text}
        onChange={e => setText(e.target.value)}
        placeholder="請輸入一段文字、一篇短文或是文件內容"
      />
    );
  };

  const getFileUploadJsx = () => {
    const maxSize = 5242880; // 5mb
    // TODO: 拖放區域是否允許Click待確認，目前設定為不允許
    return (
      <Dropzone
        onDrop={acceptFiles => setFiles(acceptFiles)}
        // .txt無效，要用text/plain => 應該說用副檔名無效，要用MIME Type
        // (https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Complete_list_of_MIME_types)
        accept="text/plain, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf"
        minSize={0}
        maxSize={maxSize}
        noClick={true}
      >
        {({
          getRootProps,
          getInputProps,
          isDragActive,
          isDragAccept,
          isDragReject,
          rejectedFiles,
          open,
        }) => {
          const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;
          const isFileDropped = files.length > 0;
          return (
            <DropZoneStyle {...getRootProps()}>
              <input {...getInputProps()} />
              {!isDragActive && (
                <>
                  {!isFileDropped ? (
                    <span>將檔案拖放到這裡，或</span>
                  ) : (
                    <DropzoneMsg color={iceblue2}>{files[0].name}</DropzoneMsg>
                  )}
                  <Button template="primary" onClick={open}>
                    <StyledFaPaperclip /> 選擇檔案
                  </Button>
                </>
              )}
              {isDragAccept && <DropzoneMsg color="#32CD32">請將檔案放到這裡</DropzoneMsg>}
              {isDragReject && <DropzoneMsg color="#E57F7F">請選擇符合格式的檔案</DropzoneMsg>}
              {isFileTooLarge && <DropzoneMsg color="#E57F7F">上傳的檔案過大</DropzoneMsg>}
            </DropZoneStyle>
          );
        }}
      </Dropzone>
    );
  };

  const tablist = [
    {
      title: '文字輸入',
      searchField: '輸入文字',
      searchContent: getTextInputJsx(),
    },
    {
      title: '檔案上傳',
      searchField: '上傳檔案',
      searchContent: getFileUploadJsx(),
    },
  ];
  const countries = ['TW', 'US', 'EP', 'CN'];

  return (
    <SearchByTextContent
      sector="專利檢索"
      feature="以文找文"
      activeTab={activeTab}
      tabs={tablist.map(it => it.title)}
      click={tabIdx => setActiveTab(tabIdx)}
    >
      {isLoading ? (
        <ProcessMessage>資料處理中，請稍後...</ProcessMessage>
      ) : (
        <form onSubmit={e => handleSumit(e)} onReset={() => handleReset()}>
          <Row>
            <Column width="10%">檢索範圍</Column>
            <Column width="80%">
              {countries.map(item => (
                <CustomRadioButton key={item}>
                  <label>
                    <input
                      type="radio"
                      name="country"
                      value={item}
                      checked={item === selectedCountry}
                      onChange={e => setSelectedCountry(e.target.value)}
                    />
                    <span />
                    {getCountryName(item)}
                  </label>
                </CustomRadioButton>
              ))}
            </Column>
          </Row>
          <Row alignItems="flex-start">
            <Column width="10%">{tablist[activeTab].searchField}</Column>
            <Column width="80%">
              <Row>{tablist[activeTab].searchContent}</Row>
              <Row>檔案大小限制：5mb&nbsp;&nbsp;&nbsp;檔案種類限制：txt, doc, docx 和 pdf</Row>
              <Row justifyContent="flex-start">
                <Button type="submit" template="primary">
                  查詢
                </Button>
                <Button type="reset">清除</Button>
              </Row>
            </Column>
          </Row>
        </form>
      )}
    </SearchByTextContent>
  );
};

const mapDispatchToProps = {
  composeUuidListRequest,
};

export default connect(
  null,
  mapDispatchToProps,
)(SearchByText);
