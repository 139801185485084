import React from 'react';
import styled from 'styled-components';
import ptab_search from 'src/assets/images/manual/patent-search/ptab_search.png';

const ImgContainer = styled.div<{ height: string; imageUrl: string }>`
  position: relative;
  height: ${props => props.height}px;
  padding: 10px 0;
  background-image: url(${props => props.imageUrl});
  background-size: contain;
  background-repeat: no-repeat;
`;
export default function PatentSearchManual() {
  return (
    <>
      <p>
        美國PTAB資料檢索與基本檢索相同，唯此項目搜尋範圍在具有美國專利審查與上訴委員會(PTAB)資料之專利案件。
      </p>
      <ol>
        <li>從功能列點選「專利檢索」。</li>
        <li>點選「美國PTAB資料檢索」功能。</li>
        <li>選擇要檢索的行業別範圍，預設為不分行業。</li>
        <li>輸入要檢索的字串，可直接輸入關鍵字，或參照運算元與檢索欄位列表。</li>
        <li>點選右方放大鏡圖示進行查詢</li>
      </ol>
      <ImgContainer height="500" imageUrl={ptab_search} />
    </>
  );
}
