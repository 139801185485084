import styled from 'styled-components';
import { Field, Form } from 'formik';
import {
  blue7,
  iceblue1,
  iceblue3,
  iceblue5,
  iceblue6,
  iceblue7,
  iceblue8,
  ultrawhite,
  ultrawhitergba,
} from 'src/style/theme/Color';

interface IRow {
  justifyContent?: string;
  alignItems?: string;
  flexWrap?: string;
  hasBorderBottom?: boolean;
}

export const Row = styled.div<IRow>`
  width: 100%;
  display: flex;
  flex-wrap: ${props => props.flexWrap};
  align-items: ${props => props.alignItems || 'center'};
  justify-content: ${props => props.justifyContent || 'space-between'};
  border-bottom: ${props => props.hasBorderBottom && `1px solid ${iceblue8}`};
  margin-bottom: 16px;
`;

// Form
export const StyledField = styled(Field)<{ width?: string }>`
  width: ${props => props.width};
  padding: 5px 16px;
  border-radius: 4px;
  border: solid 1px ${iceblue5};
  background-color: ${ultrawhite};
  color: ${iceblue1};
  ::placeholder {
    color: ${iceblue6};
  }
`;

interface IStyledTextareaField {
  width?: string;
  height?: string;
  padding?: string;
  resize?: string;
  minHeight?: string;
}

export const StyledTextareaField = styled(Field)<IStyledTextareaField>`
  resize: ${props => props.resize || 'none'};
  width: ${props => props.width || '100%'};
  height: ${props => props.height || '64px'};
  min-height: ${props => props.minHeight};
  padding: ${props => props.padding || '8px 16px'};
  border-radius: 4px;
  border: solid 1px ${iceblue5};
  background-color: ${ultrawhite};
  ::placeholder {
    color: ${iceblue6};
  }
`;

export const IconLabel = styled.label<{ width?: string }>`
  width: ${props => props.width};
  display: flex;
  align-items: center;
  position: relative;

  svg {
    position: absolute;
    right: 12px;
    font-size: 16px;
    :hover {
      cursor: pointer;
    }
    z-index: 1;
  }
`;

interface ITextLabel {
  width?: string;
  marginBottom?: string;
}

export const TextLabel = styled.label<ITextLabel>`
  width: ${props => props.width};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${props => props.marginBottom};
`;

export const Symbol = styled.span`
  width: 16px;
  text-align: center;
`;

export const Term = styled.div<{ width: string }>`
  width: ${props => props.width};
  margin-bottom: 16px;
`;

interface ITermTag {
  width?: string;
  marginRight?: string;
  isButton?: boolean;
}

export const TermTag = styled.span<ITermTag>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.width || '62px'};
  padding: 8px 16px;
  line-height: 1;
  border-radius: 4px;
  margin-right: ${props => props.marginRight || '16px'};
  background-color: ${blue7};
  color: ${iceblue3};
  :hover {
    cursor: ${props => props.isButton && 'pointer'};
  }
`;

export const TermText = styled.span<{ color?: string }>`
  color: ${props => props.color || iceblue1};
  margin-right: 8px;
`;

export const IpcCodeSearchForm = styled(Form)`
  display: flex;
  align-items: center;
  height: 60px;
  width: calc(100% - 68px);
  line-height: 60px;
  border-radius: 4px;
  box-shadow: 0 4px 16px -4px rgba(38, 50, 55, 0.3);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  background-color: ${ultrawhitergba(0.9)};
  button {
    flex-shrink: 0;
    height: 60px;
    width: 92px;
    display: block;
  }
  label {
    flex-grow: 1;
    width: 300px;
    display: flex;
    border: 1px solid;
    border-color: transparent ${iceblue7} transparent transparent;
    line-height: 60px;
    span {
      flex-shrink: 0;
      padding: 0 16px;
      color: ${iceblue6};
    }
    input {
      flex-grow: 1;
      min-width: 0;
      padding-right: 16px;
      color: ${iceblue1};
      background-color: transparent;
      border: none;
      outline: none;
      ::placeholder {
        color: ${iceblue6};
      }
    }
  }
`;
