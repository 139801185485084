import React, { useState, useRef, useLayoutEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import {
  FaFilter,
  FaPencilAlt,
  FaTrashAlt,
  FaSortAmountUp,
  FaAngleDoubleUp,
  FaAngleDoubleDown,
} from 'react-icons/fa';

import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';

import EditPopup from 'src/components/ui/interactive/dropdown/EditCollect';
import DeleteModal from 'src/components/ui/interactive/modal/custom/DeleteModal';
import SortActionMenu from 'src/components/ui/interactive/dropdown/SortActionMenu';
import {
  blue3,
  blue4,
  blue7,
  blue8,
  iceblue3,
  iceblue5,
  ultrawhite,
  ultrawhitergba,
  ultrablackrgba,
} from 'src/style/theme/Color';

import { connect } from 'react-redux';
import { ReduxAppState } from 'src/redux/reducers';
import { openCustomModal, closeCustomModal } from 'src/redux/actions/modalAction';
import { CustomModalType } from 'src/types/ModalTypes';

const Container = styled.div`
  border-radius: 4px;
  box-shadow: 0 0 50px 0 ${ultrablackrgba(0.1)};
`;
const Header = styled.div`
  height: 88px;
  display: flex;
  flex-flow: row;
  align-items: center;
  color: ${ultrawhite};
  background-image: linear-gradient(95deg, #2ec3c2, ${blue3});
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;
const HeaderText = styled.div`
  padding: 16px 0 16px 32px;
  width: 70%;
`;
const HeaderRoute = styled.div`
  color: ${ultrawhitergba(0.8)};
`;
const Feature = styled.div`
  height: 36px;
  line-height: 36px;
  font-size: 24px;
  font-weight: bold;
`;
const Anchor = styled.div`
  width: 30%;
  padding: 0 32px 0 0;
`;
const HeaderFunctions = styled.div`
  float: right;
  display: flex;
  position: relative;
`;
const HeaderFunction = styled.div`
  margin: 0 0 0 20px;
  display: flex;
  cursor: pointer;
  i {
    display: block;
    margin: 2px 8px 0 0;
  }
`;
const Subheaders = styled.div<{ collapse: boolean }>`
  padding: 16px 0;
  height: ${props => (props.collapse ? '60px' : null)};
  display: flex;
  align-items: center;
  color: ${iceblue5};
  background-color: ${blue7};
`;
const Description = styled.div`
  padding: 0 16px 0 32px;
  width: 104px;
  display: flex;
  i {
    display: block;
  }
`;
const Tags = styled.div`
  width: calc(85% - 104px);
`;
interface IFoldProps {
  fold: boolean;
  collapse: boolean;
}
const FoldableInfoDiv = styled.div<IFoldProps>`
  padding: 0 56px 0 0;
  height: ${props => (props.collapse ? '34px' : null)};
  overflow: hidden;
  position: relative;
  color: ${ultrawhite};

  > div {
    display: flex;
    flex-flow: row wrap;
  }
`;
const Tag = styled.div<{ active: boolean }>`
  margin: 2px 8px;
  padding: 4px 16px;
  border-radius: 4px;
  color: ${props => (props.active ? ultrawhite : iceblue3)};
  background-color: ${props => (props.active ? blue3 : blue8)};
  cursor: pointer;

  line-height: 22px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const Sort = styled.div`
  float: right;
  width: 15%;
  padding: 0;
  color: ${blue3};
  :hover {
    cursor: pointer;
  }
`;
const BtnFold = styled.div<{ fold: boolean }>`
  display: ${props => (props.fold ? 'block' : 'none')};
  width: 34px;
  height: 34px;
  bottom: 0;
  right: 16px;
  position: absolute;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background-color: ${blue4};

  svg {
    display: block;
  }
  :hover {
    cursor: pointer;
  }
`;
const Content = styled.div`
  padding: 0 0 24px;
  min-height: 450px;
`;
// TODO: 翻轉
const StyleSortIcon = styled(FaSortAmountUp)`
  margin-left: 8px;
`;

interface IReduxMappingProps {
  isDeleteCategoryModalOpen: boolean;
  openCustomModal: (customModalType: CustomModalType) => void;
  closeCustomModal: () => void;
}

interface IProps extends IReduxMappingProps {
  sector: string;
  feature: string;
  name: string;
  activeTag: Array<string>;
  tags: string[];
  click: (tag: string) => void;
  children: JSX.Element;
  collectId: number;
  submit: (option: string, data: any) => void;
  sortRating: () => void;
  sortDate: () => void;
  noCollections: boolean;
}

function CollectContent(props: IProps) {
  const [edit, setEdit] = useState(false);

  const submitRename = async (newName: string) => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    try {
      const response = await Api().patch(`patent/collection/category/${props.collectId}`, newName, {
        cancelToken: source.token,
      });
      if (response.status === 200) {
        setEdit(false);
        props.submit('EditCollectName', newName);
      }
    } catch (error) {
      errorHandler(error);
    }

    return () => source.cancel();
  };

  const apiRemoveToken = axios.CancelToken;
  const removeTokenSource = apiRemoveToken.source();
  const submitRemove = async () => {
    try {
      const response = await Api().delete(`patent/collection/category/${props.collectId}`, {
        cancelToken: removeTokenSource.token,
      });

      if (response.status === 200) {
        props.closeCustomModal();
        props.submit('DeleteCollect', null);
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const [show, setShow] = useState(false);

  const [collapse, setCollapse] = useState(true);
  const collapseHandler = () => {
    setCollapse(prev => !prev);
  };
  const [fold, setFold] = useState(false);
  const srcRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const lineRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const FoldableInfo = (props: {
    lineRef: React.MutableRefObject<HTMLDivElement>;
    srcRef: React.MutableRefObject<HTMLDivElement>;
    activeTag: Array<string>;
    tags: string[];
    click: (tag: string) => void;
  }) => (
    <div ref={props.lineRef}>
      <FoldableInfoDiv fold={fold} collapse={collapse}>
        <div ref={props.srcRef}>
          {props.tags.map(tag => (
            <Tag key={tag} active={props.activeTag.includes(tag)} onClick={() => props.click(tag)}>
              {tag}
            </Tag>
          ))}
        </div>
        {fold && (
          <BtnFold fold={fold} onClick={collapseHandler}>
            {collapse ? <FaAngleDoubleDown /> : <FaAngleDoubleUp />}
          </BtnFold>
        )}
      </FoldableInfoDiv>
    </div>
  );
  useLayoutEffect(() => {
    const handleResize = () => {
      setFold(srcRef.current.scrollHeight !== lineRef.current.clientHeight);
    };

    if (props.tags && props.tags.length > 0 && srcRef.current) {
      setFold(srcRef.current.scrollHeight !== lineRef.current.clientHeight);
      window.addEventListener('resize', handleResize);
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [srcRef, lineRef, props.tags]);

  return (
    <Container>
      <Header>
        <HeaderText>
          <HeaderRoute>
            首頁&nbsp;/&nbsp;{props.sector}&nbsp;/&nbsp;
            {props.feature}&nbsp;/&nbsp;{props.name}
          </HeaderRoute>
          <Feature>{props.name}</Feature>
        </HeaderText>
        <Anchor>
          {!props.noCollections && (
            <HeaderFunctions>
              <HeaderFunction onClick={() => setEdit(!edit)}>
                <i>
                  <FaPencilAlt />
                </i>
                編輯
              </HeaderFunction>
              {edit && (
                <EditPopup name={props.name} show={edit} submit={submitRename} setShow={setEdit} />
              )}
              <HeaderFunction
                onClick={() => props.openCustomModal(CustomModalType.DELETE_CATEGORY)}
              >
                <i>
                  <FaTrashAlt />
                </i>
                刪除
              </HeaderFunction>
              {props.isDeleteCategoryModalOpen && (
                <DeleteModal
                  message="刪除此收藏分類後，您在內所有的收藏專利均會消失，請問是否要移除收藏？"
                  submit={submitRemove}
                />
              )}
            </HeaderFunctions>
          )}
        </Anchor>
      </Header>
      {!props.noCollections && (
        <Subheaders collapse={collapse}>
          <Description>
            <i>
              <FaFilter />
            </i>
            標籤
          </Description>
          <Tags>
            <FoldableInfo
              lineRef={lineRef}
              srcRef={srcRef}
              activeTag={props.activeTag}
              tags={props.tags}
              click={props.click}
            />
          </Tags>
          <Sort>
            <span onClick={() => setShow(true)}>排序</span>
            <StyleSortIcon onClick={() => setShow(true)} />
            {show && (
              <SortActionMenu
                show={show}
                setShow={setShow}
                sortRating={props.sortRating}
                sortDate={props.sortDate}
              />
            )}
          </Sort>
        </Subheaders>
      )}
      <Content>{props.children}</Content>
    </Container>
  );
}

const mapStateToProps = (state: ReduxAppState) => {
  return {
    isDeleteCategoryModalOpen:
      state.modalReducer.customModalType === CustomModalType.DELETE_CATEGORY,
  };
};

const mapDispatchToProps = {
  openCustomModal,
  closeCustomModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CollectContent);
