import React, { useState, useRef } from 'react';
import styled, { css } from 'styled-components';

import { FaSearch } from 'react-icons/fa';

import { RouteComponentProps, withRouter } from 'react-router';

import SearchSuggestion from 'src/components/common/search-bar/SearchSuggestion';
import useOutSideClick from 'src/hooks/useOutSideClick';
import { alertMessage } from 'src/utils/ModalUtils';
import { SystemMessage } from 'src/apps/applicationMessages';

// redux
import { connect } from 'react-redux';
import { ReduxAppState } from 'src/redux/reducers';
import { composeBasicRequest } from 'src/redux/actions/patentSearchAction';
import { IBasicRequest } from 'src/types/PatentSearchTypes';
import { ITokenPayload } from 'src/apps/auth/Auth';
import { iceblue1, blue5rgba } from 'src/style/theme/Color';
import { CountryDropdown } from 'src/components/search/CountryDropdown';
import { countryItems } from 'src/constants/PatentSearchConstants';
import { IDropdownItem } from 'src/components/common/Dropdown';

const SearchArea = styled.div`
  margin: auto;
  width: 100%;
  position: relative;
  display: flex;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 4px 16px -4px rgba(0, 0, 0, 0.3);
  line-height: 60px;
  text-align: left;
`;
const InputContainer = styled.div`
  width: calc(100% - 164px);
  position: relative;
`;
const Input = styled.input.attrs({ type: 'text' })`
  padding: 20px 16px;
  width: calc(100% - 44px);
  border-radius: 4px;
  color: #333333;
  background-color: #ffffff;
  border: none;
  outline: none;
  ::placeholder {
    color: #b3b9bc;
  }
`;
const Icon = styled.div`
  background-color: #ffffff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  svg {
    display: block;
    :hover {
      cursor: pointer;
    }
  }
`;

const customStyleCountryButton = css<{ active: boolean }>`
  padding-left: 16px;
  background-color: ${blue5rgba(0.15)};
  color: ${iceblue1};
  &:hover {
    background-color: ${blue5rgba(0.15)};
  }
  text-align: center;
  border-radius: 0px;
`;

interface IReduxMappingProps {
  member: ITokenPayload;
  composeBasicRequest: (basicRequest: IBasicRequest) => void;
}

interface IProps extends RouteComponentProps, IReduxMappingProps {}

const SearchBar: React.FC<IProps> = props => {
  const [inputText, setInputText] = useState('');
  const [isSuggestionShow, setIsSuggestionShow] = useState(false);
  // 下拉選單預設選取值
  const sectionDropdownDefault: Array<IDropdownItem> = [{ name: '本國', value: 'TW' }];
  const [selectedItem, setSelectedItem] = useState<IDropdownItem>(sectionDropdownDefault[0]);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleSuggestionClick = (value?: string) => {
    if (value && value !== '') {
      setInputText(value);
    }
    inputRef.current && inputRef.current.focus();
  };

  const handleSuggestionClose = () => {
    setIsSuggestionShow(false);
  };

  const handleSubmit = () => {
    if (inputText.trim() === '') {
      alertMessage(SystemMessage.SEARCH_KEYWORD_EMPTY);
    } else {
      props.composeBasicRequest({
        industrialClassIds: [],
        queryString: inputText,
        advanceKeywords: [{ keyCode: 'PO', keyword: selectedItem.value }],
      });
      props.history.push('/result');
    }
  };

  const node = useOutSideClick({
    show: isSuggestionShow,
    setShow: setIsSuggestionShow,
  });

  return (
    <SearchArea>
      <CountryDropdown
        items={countryItems}
        activeItem={selectedItem}
        handleOnclick={item => {
          setSelectedItem(item);
        }}
        customStyleSelectedItem={customStyleCountryButton}
        width="120px"
      />
      <InputContainer ref={node as any}>
        <Input
          ref={inputRef as any}
          value={inputText}
          onChange={e => setInputText(e.target.value)}
          onFocus={() => setIsSuggestionShow(true)}
          onKeyDown={e => e.keyCode === 13 && handleSubmit()}
          placeholder="請輸入關鍵字 / 專利號 / 申請人...."
        />
        <SearchSuggestion
          cssTop="68px"
          isShow={isSuggestionShow}
          industryIds={props.member.info.industrialClassIds}
          inputText={inputText}
          handleSuggestionClick={handleSuggestionClick}
          handleSuggestionClose={handleSuggestionClose}
        />
      </InputContainer>
      <Icon>
        <FaSearch color="#077790" size="20px" onClick={handleSubmit} />
      </Icon>
    </SearchArea>
  );
};

const mapStateToProps = (state: ReduxAppState) => {
  return {
    member: state.memberReducer.memberPayload,
  };
};

const mapDispatchToProps = {
  composeBasicRequest,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SearchBar),
);
