import React from 'react';
import styled from 'styled-components';
import patent_export from 'src/assets/images/manual/search-result-analysis/patent_export.png';

const ImgContainer = styled.div<{ height: string; imageUrl: string }>`
  position: relative;
  height: ${props => props.height}px;
  padding: 10px 0;
  background-image: url(${props => props.imageUrl});
  background-size: contain;
  background-repeat: no-repeat;
`;
export default function SearchResultAnalysisManual() {
  return (
    <>
      <p>檢索結果主要資訊清單列表匯出</p>
      <br />
      <ImgContainer height="350" imageUrl={patent_export} />
    </>
  );
}
