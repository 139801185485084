import React, { useState } from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router';
import { Api } from 'src/api/helpers/apiBase';
import { IErrorResponseData } from 'src/apps/error-handler/ErrorHandler';

import * as Color from 'src/style/theme/Color';
import Content from 'src/components/ui/content/Content';
import Footer from 'src/components/common/Footer';
import { Button } from 'src/style/theme/Common';
import { alertMessage } from 'src/utils/ModalUtils';

const Container = styled.div`
  text-align: center;
`;
const InfoMessage = styled.div<{ success: boolean }>`
  margin: 0 0 8px;
  padding: 16px 26px;
  display: inline-block;
  border-radius: 4px;
  background-color: ${props => (props.success ? Color.blue7 : Color.alarm8)};
`;
const FooterStyle = styled.div`
  margin: 20px 0 0;
  width: 100vw;
  left: 0;
  position: absolute;
  border-top: 1px solid ${Color.iceblue8};
  background-color: #ffffff;
`;

// After submit
const Result = styled.div<{ success: boolean }>`
  color: ${props => (props.success ? Color.blue3 : Color.pink1)};
  font-size: 24px;
  font-weight: bold;
`;
const SuggestMessage = styled.div`
  padding: 8px 16px;
  a {
    color: ${Color.blue3};
  }
`;

// Before submit
const DivTable = styled.div`
  margin: auto;
  width: 800px;
  display: table;
  border-collapse: collapse;
`;
const DivTr = styled.div`
  display: table-row;
`;
const DivLabel = styled.div`
  display: table-cell;
  text-align: right;
`;
const DivInput = styled.div`
  padding: 8px 16px;
  display: table-cell;
  text-align: left;

  input {
    padding: 0 8px;
    height: 36px;
    border-radius: 4px;
    border: solid 1px ${Color.iceblue5};
  }
`;
const ErrorMsg = styled.div`
  max-width: 350px;
  span {
    color: #ff0000;
  }
`;

interface IProps {
  identifier: string;
}

function PwdReset(props: RouteComponentProps<IProps>) {
  let [patchFinished, setPatchFinished] = useState(false);
  let [patchSuccess, setPatchSuccess] = useState(false);
  let [pwd1, setPwd1] = useState<string>();
  let [pwd2, setPwd2] = useState<string>();
  const [message, setMessage] = useState<string>();

  const token = props.location.search.replace('?', '');
  const SUCCESS_MSG = '已重設密碼，請重新登入，歡迎繼續使用本網站。';

  const pwdValidate = (value: string) => {
    let errorMessage;
    if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d()[\]{}&lt;&gt;+-\\*/?,.:;&quot;'_\\|~`!@#$%^&=]{8,32}$/.test(
        value,
      )
    ) {
      errorMessage = '無效的密碼, 請輸入8到32位包含英文大小寫字母，以及數字';
      if (value.trim().length !== value.length) {
        errorMessage = '頭尾不得為空白';
      } else if (value === '') {
        errorMessage = undefined;
      }
    }
    return errorMessage;
  };

  const handleString = (value: string, setter: (value: string) => void) => {
    const validate = pwdValidate(value);
    if (validate) {
      setMessage(validate);
    } else {
      setMessage(undefined);
      setter(value);
      if (pwd1 !== value && pwd2 !== value) {
        setMessage('密碼驗證不符');
      }
    }
  };

  const handleSubmit = async () => {
    if (message) {
      alertMessage('請先修正密碼');
    } else {
      try {
        const res = await Api({ 'Content-Type': 'application/json' }).patch('password/reset', {
          token: token,
          password: pwd1 === pwd2 ? pwd1 : 'fail',
        });

        if (res.status === 200) {
          setPatchSuccess(true);
          setMessage(SUCCESS_MSG);
        }
      } catch (error) {
        if (error.response && error.response.data) {
          setMessage((error.response.data as IErrorResponseData).resultMessage);
        }
      } finally {
        setPatchFinished(true);
      }
    }
  };

  return (
    <Container>
      <Content root="會員功能" sector="重設密碼">
        {patchFinished ? (
          <>
            <InfoMessage success={patchSuccess}>
              <Result success={patchSuccess}>{patchSuccess ? '成功' : '失敗'}</Result>
              {message}
            </InfoMessage>
            <SuggestMessage>
              {!patchSuccess && (
                <>
                  因為上述原因，本次的確認操作失敗了。
                  <br />
                  如有需要、請您與
                  <a href="mailto:tipoeservice@tipo.gov.tw">系統管理員</a>
                  聯繫，我們會儘速為您處理，謝謝。
                </>
              )}
            </SuggestMessage>
          </>
        ) : (
          <DivTable>
            <DivTr>
              <DivLabel>新密碼</DivLabel>
              <DivInput>
                <input type="password" onChange={e => handleString(e.target.value, setPwd1)} />
              </DivInput>
            </DivTr>
            <DivTr>
              <DivLabel>再次確認</DivLabel>
              <DivInput>
                <input type="password" onChange={e => handleString(e.target.value, setPwd2)} />
              </DivInput>
            </DivTr>
            {message && (
              <DivTr>
                <DivLabel />
                <DivInput>
                  <ErrorMsg>
                    <span>{message}</span>
                  </ErrorMsg>
                </DivInput>
              </DivTr>
            )}
            <DivTr>
              <DivLabel />
              <DivInput>
                <Button template="primary" onClick={handleSubmit}>
                  送出
                </Button>
              </DivInput>
            </DivTr>
          </DivTable>
        )}
      </Content>
      <FooterStyle>
        <Footer />
      </FooterStyle>
    </Container>
  );
}

export default PwdReset;
