import React, { Component } from 'react';

class GoogleTranslate extends Component {
  googleTranslateElementInit = () => {
    // @ts-ignore
    new window.google.translate.TranslateElement(
      {
        pageLanguage: '',
        // @ts-ignore
        layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
        autoDisplay: false,
      },
      'google_translate_element',
    );
  };

  componentDidMount() {
    var addScript = document.createElement('script');
    addScript.setAttribute(
      'src',
      '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit',
    );
    document.body.appendChild(addScript);
    // @ts-ignore
    window.googleTranslateElementInit = this.googleTranslateElementInit;
  }

  render() {
    return <div id="google_translate_element" />;
  }
}

export default GoogleTranslate;
