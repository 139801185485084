import React from 'react';
import styled from 'styled-components';
import { Route, RouteComponentProps } from 'react-router-dom';

import TagInfo from 'src/components/common/tag-info/TagInfo';

const Container = styled.div`
  margin: auto;
  padding: 16px 0 0;
  width: 75%;
`;

const tagInfoLayout: React.FC<RouteComponentProps> = props => {
  return (
    <Container>
      <Route path="/tag/info" component={TagInfo} />
    </Container>
  );
};

export default tagInfoLayout;
