import React from 'react';
import styled from 'styled-components';

import * as Color from 'src/style/theme/Color';
import Content from 'src/components/ui/content/Content';
import Footer from 'src/components/common/Footer';

const Section = styled.section`
  padding: 0 0 48px;
  color: ${Color.iceblue3};

  span {
    display: block;
    padding: 0 0 8px;
    color: ${Color.blue2};
    font-size: 18px;
  }
  > p {
    display: block;
    margin: 0 0 8px;
  }
  > code {
    padding: 16px 26px;
    display: inline-block;
    border-radius: 4px;
    background-color: ${Color.blue7};
  }

  > div {
    margin: 16px 0;
    padding: 16px 32px;
    border-radius: 4px;
    background-color: ${Color.blue7};
    span {
      color: ${Color.iceblue2};
      font-size: 15px;
      font-weight: 600;
    }
  }
`;
const FooterStyle = styled.div`
  margin: 20px 0 0;
  width: 100vw;
  left: 0;
  position: absolute;
  border-top: 1px solid ${Color.iceblue8};
  background-color: #ffffff;
`;

const Policy = () => (
  <>
    <Content sector="Cookie 政策">
      <>
        <Section>
          <span>Cookie 是什麼？我們為什麼要使用 Cookie？</span>
          <p>
            Cookie 是您造訪之網站或應用程式放置在您電腦中的小型文字檔。
            <br />
            Cookie
            的作用是幫助我們提升您在使用我們的網站時的體驗，例如儲存慣用語言設定，方便下次造訪時使用。
            <br />
            <br />
            我們透過 Cookie 所收集到的資訊具有下列用途：
          </p>
          <code>
            ・根據您的個人需求調整我們的網站。
            <br />
            ・記憶您曾經收到的通知，並且不再顯示這些通知。
            <br />
            ・根據您的使用方式，改善並更新我們的網站。
            <br />
            ・我們通常不會運用 Cookie 識別您的個人身分。
          </code>
        </Section>
        <Section>
          <span>我們會使用哪一類 Cookie？</span>
          <p>我們所使用的 Cookie 類別如下：</p>
          <div>
            <span>第 1 類：必要的 Cookie </span>
            <p>
              必要的 Cookie 可以讓您使用我們的網站以及網站上的所有功能，例如存取網站中的安全區域。
              不使用這類 Cookie 可能就無法使用網站上的部分功能。
            </p>
          </div>
          <div>
            <span>第 2 類：效能 Cookie </span>
            <p>
              Cookie 會收集您的網站使用相關資訊，讓我們能夠繼續提供更好的網站服務。 例如，此類
              Cookie 會收集您最常造訪的頁面，以及您可能收到的所有錯誤訊息。這類 Cookie
              收到的資訊皆經匿名處理。 它們不會收集任何可識別您個人身分的資訊。
              <br />
              <br />
              Google Analytics 通用分析：我們使用 Google 通用
              Analytics（分析）監控我們的網站績效以及查看訪客活動，以便根據訪客期望調整我們的內容。
            </p>
          </div>
          <div>
            <span>第 3 類：功能性 Cookie </span>
            <p>
              功能性 Cookie 可以讓我們提供更符合個人需求的使用體驗。 此類 Cookie
              讓我們能夠記憶資料（例如使用者名稱和語言偏好設定），下次您再度造訪時就不需重複輸入這些資料。
              這類 Cookie 收集的資訊皆經匿名處理。 它們無法追蹤您在其他網站上的瀏覽活動。
            </p>
          </div>
        </Section>
      </>
    </Content>
    <FooterStyle>
      <Footer />
    </FooterStyle>
  </>
);

export default Policy;
