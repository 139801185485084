import {
  COMPOSE_BASIC_REQUEST,
  COMPOSE_UUID_LIST_REQUEST,
  NAVIGATE_TO_NEXT_PAGE,
  SELECT_DATE_RANGE,
  CHANGE_SORT_ORDER,
  APPEND_ADVANCED_KEYWORD,
  DETACH_ADVANCED_KEYWORD,
  APPEND_ADVANCED_KEYWORD_COUNTRY,
  APPEND_CLUSTER_KEYWORD,
  TOGGLE_BILINGUAL_TERM,
  TOGGLE_DEDUPE,
  TOGGLE_TECH_FUNC_QUERY,
  PatentSearchActionTypes,
  SEARCH_PTAB_DATA,
} from 'src/redux/types/patentSearchTypes';
import { IBasicRequest, IFilterTag } from 'src/types/PatentSearchTypes';
import { escapeInvalidRegex } from 'src/utils/TextUtils';

function getHighlightWords(basicRequest: IBasicRequest): string[] {
  let result: string[] = [];
  const { queryString, expert } = basicRequest;
  if (queryString.trim() !== '' && isSingleKeyword(queryString)) {
    // 依空白切割字串，排除雙引號包覆的文字
    // eg. 'I have "a dream"' => ['I', 'have', '"a dream"'];
    const matchesArray = queryString.match(/(?:[^\s"]+|"[^"]*")+/g);
    if (matchesArray) {
      result = matchesArray.map(item => {
        // 刪掉字串中所有雙引號
        let highlightword = item.replace(/["]/g, '');

        // 如果是英文、數字、底線、空白的組合，則對整段文字中每個word進行精確比對(exact match)
        // ps. 在react-highlight-words中，可使用正規表達式\b進行word boundary match
        // 如果不是的話，react-highlight-words預設對整段文字進行模糊比對(fuzzy match)
        if (highlightword.match(/^[a-zA-Z0-9_ ]*$/g)) {
          highlightword = '\\b' + highlightword + '\\b';
        } else {
          // 使用者可能輸入 "+"、"(" 等等會造成 Invalid regular expression Error的字串，
          // 因為前面有用\b處理highlightword，無法利用react-highlight-words提供的autoEscape屬性，所以自行處理
          highlightword = escapeInvalidRegex(highlightword);
        }
        return highlightword;
      });
    }
  }
  if (expert !== undefined) {
    result.push(expert.name);
  }
  return result;
}

// 判斷是否為單一關鍵字查詢 (關鍵字中不包含進階檢索欄位與條件)
function isSingleKeyword(queryString: string): boolean {
  let result = true;
  const connditions = [' AND ', ' OR ', ' NOT '];
  const patentFacetFields = [
    'TI=',
    'AB=',
    'CL=',
    'DE=',
    'PN=',
    'AN=',
    'IC=',
    'IN=',
    'PA=',
    'LX=',
    'ID=',
    'AD=',
    'PO=',
  ];

  const uppercaseQueryString = queryString.toUpperCase();
  const multiKeywordTerms = connditions.concat(patentFacetFields);
  const len = multiKeywordTerms.length;
  for (let i = 0; i < len; i++) {
    if (uppercaseQueryString.indexOf(multiKeywordTerms[i]) !== -1) {
      return false;
    }
  }
  return result;
}

export function composeBasicRequest(basicRequest: IBasicRequest): PatentSearchActionTypes {
  return {
    type: COMPOSE_BASIC_REQUEST,
    basicRequest: basicRequest,
    highlightWords: getHighlightWords(basicRequest),
  };
}

export function composeUuidListRequest(basicRequest: IBasicRequest): PatentSearchActionTypes {
  return {
    type: COMPOSE_UUID_LIST_REQUEST,
    basicRequest: basicRequest,
  };
}

export function navigateToNextPage(): PatentSearchActionTypes {
  return {
    type: NAVIGATE_TO_NEXT_PAGE,
  };
}

export function searchPtabData(ptab: string): PatentSearchActionTypes {
  return {
    type: SEARCH_PTAB_DATA,
    ptab: ptab,
  };
}

export function selectDateRange(dateRange: string): PatentSearchActionTypes {
  return {
    type: SELECT_DATE_RANGE,
    dateRange: dateRange,
  };
}

export function changeSortOrder(sortByDate: string): PatentSearchActionTypes {
  return {
    type: CHANGE_SORT_ORDER,
    sortByDate: sortByDate,
  };
}

export function appendAdvancedKeyword(keyCode: string, keyword: string): PatentSearchActionTypes {
  return {
    type: APPEND_ADVANCED_KEYWORD,
    keyCode: keyCode,
    keyword: keyword,
  };
}

export function detachAdvancedKeyword(keyCode: string): PatentSearchActionTypes {
  return {
    type: DETACH_ADVANCED_KEYWORD,
    keyCode: keyCode,
  };
}

export function appendAdvancedKeywordCountry(
  keyCode: string,
  keyword: string,
): PatentSearchActionTypes {
  return {
    type: APPEND_ADVANCED_KEYWORD_COUNTRY,
    keyCode: keyCode,
    keyword: keyword,
  };
}

export function appendClusterKeyword(clusterkeyword: string): PatentSearchActionTypes {
  return {
    type: APPEND_CLUSTER_KEYWORD,
    clusterKeyword: clusterkeyword,
  };
}

export function toggleBilingualTerm(bilingualTerm?: string): PatentSearchActionTypes {
  return {
    type: TOGGLE_BILINGUAL_TERM,
    bilingualTerm: bilingualTerm,
  };
}

export function toggleDedupe(dedupe: boolean): PatentSearchActionTypes {
  return {
    type: TOGGLE_DEDUPE,
    dedupe: dedupe,
  };
}

export function toggleTechFuncQuery(
  techQuery?: string,
  funcQuery?: string,
  filterTag?: IFilterTag,
): PatentSearchActionTypes {
  return {
    type: TOGGLE_TECH_FUNC_QUERY,
    techQuery: techQuery,
    funcQuery: funcQuery,
    filterTag: filterTag,
  };
}
