import React, { useState, useEffect, useRef, useCallback } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import axios from 'axios';
import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import {
  IGetRssLatest,
  IAdvancedKeyword,
  IRssFacet,
  IRssSearchSuggestion,
  IGetRssTopic,
  IFacetFieldCount,
} from 'src/types/api/NewsApiTypes';

import { dateFormatter } from 'src/utils/Formatter';
import LatestContent from 'src/components/ui/content/News/LatestContent';
import LatestItem from './item/LatestItem';
import { Spinner } from 'src/components/ui/interactive/Spinner';
import InfiniteScroll from 'src/components/ui/interactive/InfiniteScroll';
import styled from 'styled-components';

const NoResult = styled.div`
  padding: 16px 32px;
  border-bottom: 1px solid #eceff1;
  align-items: left;
  display: flex;
  :hover {
    background-color: #f5fdfe;
  }
`;

const SOLR_QUERY_REGEX = /OR|NOT|AND|"|\(|\)|\*|\+|:\d*|&|\[|\]|\?|~|\^|-|!|\|/g;
interface IProps {
  sector: string;
  feature: string;
  isLogin: boolean;
  topicId: number;
  followedTopic: boolean;
  queryString: string;
  tags: Array<IRssSearchSuggestion>;
  switchTopic: () => void;
  handleSearch: (queryString: string) => void;
  handleSubscribe: (option: string, id: number, data: { keyword: string }) => void;
  handleSuggestionClick: (suggestion?: IRssSearchSuggestion) => void;
  handleTagCancel: (type: string, value: string) => void;
}

function Latest(props: IProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [isFacetLoading, setIsFacetLoading] = useState(true);
  const [newsList, setNewsList] = useState<Array<IGetRssLatest>>([]);
  const [pageUpdateTime, setPageUpdateTime] = useState('');
  const [advanceKeywords, setAdvanceKeywords] = useState<Array<IAdvancedKeyword>>([]);
  const [facets, setFacets] = useState<Array<IRssFacet>>([]);
  const [topic, setTopic] = useState<IGetRssTopic>();
  const [pageNo, setPageNo] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [isNextPageLoading, setIsNextPageLoading] = useState<boolean>(false);
  const [isNextPageError, setIsNextPageError] = useState<boolean>(false);

  const initFinished = useRef<boolean>(false);
  const { handleSuggestionClick, switchTopic, queryString } = props;

  const loadClickHandler = useCallback(() => {
    unstable_batchedUpdates(() => {
      setIsNextPageLoading(true);
      setPageNo(prev => prev + 1);
    });
  }, []);

  const reloadClickHandler = useCallback(() => {
    if (pageNo === 1) {
      setIsLoading(true);
      setNewsList([]);
    } else {
      setIsNextPageLoading(true);
    }
  }, [pageNo]);

  // component did mount
  // 依 topic id 取得 新聞主題內容
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const fetchData = async () => {
      try {
        const res = await Api().get('/rss/topic/' + props.topicId + '/detail', {
          cancelToken: source.token,
        });
        const resTopic: IGetRssTopic = res.data;
        if (resTopic) {
          setTopic(resTopic);
          switchTopic();
          handleSuggestionClick({
            type: 'TOPIC',
            value: resTopic.id.toString(),
            displayName: resTopic.name,
            queryString: resTopic.queryString,
            sources: resTopic.sources,
          });
        }
        setIsLoading(true);
      } catch (error) {
        errorHandler(error);
      } finally {
        initFinished.current = true;
      }
    };
    !initFinished.current && fetchData();

    return () => source.cancel();
  }, [props.topicId, handleSuggestionClick, switchTopic]);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const fetchData = async () => {
      try {
        const res = await Api().post(
          '/rss/topic/search',
          {
            topicId: props.topicId,
            pageNo: pageNo,
            paginationSize: 20,
            queryString: queryString,
            advanceKeywords: advanceKeywords,
          },
          { cancelToken: source.token },
        );
        setNewsList(prev => [...prev, ...res.data.data]);
        setTotalPages(res.data.totalPages);
        setTotalCount(res.data.total);

        if (pageNo === 1) {
          const time = dateFormatter(res.data.data[0] && res.data.data[0].createTime);
          setPageUpdateTime(time);
        }

        setIsNextPageError(false);
      } catch (error) {
        errorHandler(error);
        isNextPageLoading && setIsNextPageError(true);
      } finally {
        setIsLoading(false);
        setIsNextPageLoading(false);
      }
    };

    initFinished.current && (isLoading || isNextPageLoading) && fetchData();

    return () => source.cancel();
  }, [isLoading, isNextPageLoading, pageNo, queryString, props.topicId, advanceKeywords]);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const fetchData = async () => {
      try {
        const res = await Api().post(
          '/rss/facet/search',
          {
            topicId: props.topicId,
            paginationSize: 0,
            queryString: queryString,
            advanceKeywords: advanceKeywords,
          },
          { cancelToken: source.token },
        );

        setFacets(res.data);
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsFacetLoading(false);
      }
    };
    isFacetLoading && fetchData();

    return () => source.cancel();
  }, [isFacetLoading, queryString, props.topicId, advanceKeywords]);

  const handleSubscribe = (option: string, data: any) => {
    props.handleSubscribe(option, -1, data);
  };

  const handleFacetClick = useCallback(
    (keyCode: string, facetFieldCount?: IFacetFieldCount) => {
      setNewsList([]);
      setPageNo(1);
      if (facetFieldCount) {
        advanceKeywords
          ? setAdvanceKeywords(prev => [
              ...prev,
              {
                keyCode: keyCode,
                keyword: facetFieldCount.name,
                identity: facetFieldCount.identity,
              },
            ])
          : setAdvanceKeywords([
              {
                keyCode: keyCode,
                keyword: facetFieldCount.name,
                identity: facetFieldCount.identity,
              },
            ]);
      } else {
        const newAdvancedKeywords = advanceKeywords.slice();
        newAdvancedKeywords.splice(
          newAdvancedKeywords.findIndex(item => item.keyCode === keyCode),
          1,
        );
        setAdvanceKeywords(newAdvancedKeywords);
      }
      setIsLoading(true);
      setIsFacetLoading(true);
    },
    [advanceKeywords],
  );

  const getSearchWords = useCallback(() => {
    let result: Array<string> = [];
    props.queryString &&
      result.push(...props.queryString.replace(SOLR_QUERY_REGEX, ' ').split(' '));
    advanceKeywords &&
      advanceKeywords.length > 0 &&
      result.push(...advanceKeywords.filter(adk => adk.keyCode !== 'DATE').map(adk => adk.keyword));
    if (topic) {
      topic.queryString &&
        result.push(...topic.queryString.replace(SOLR_QUERY_REGEX, ' ').split(' '));
      topic.sources && topic.sources.length > 0 && result.push(...topic.sources);
    }

    return result.filter(word => word.length > 0);
  }, [advanceKeywords, props.queryString, topic]);

  return (
    <LatestContent
      topicId={props.topicId}
      followed={props.followedTopic}
      sector={props.sector}
      feature={props.feature}
      update={
        (props.topicId === 0 ? '提供您最推薦的新聞消息。' : '') + `更新時間 ${pageUpdateTime}`
      }
      search={props.handleSearch}
      facets={facets}
      isFacetLoading={isFacetLoading}
      handleFacetClick={handleFacetClick}
      handleSuggestionClick={props.handleSuggestionClick}
      handleTagCancel={props.handleTagCancel}
      handleSubscribe={handleSubscribe}
      advanceKeywords={advanceKeywords}
      tags={props.tags}
      queryString={initFinished.current ? props.queryString : ''}
    >
      <>
        {!initFinished.current || isLoading ? (
          <Spinner width="150px" />
        ) : newsList.length > 0 ? (
          <InfiniteScroll
            isNextPageLoading={isNextPageLoading}
            isNextPageError={isNextPageError}
            errorMessage={'系統發生異常'}
            handleNextPageLoad={loadClickHandler}
            handleReload={reloadClickHandler}
            totalCount={totalCount}
            currentPage={pageNo}
            totalPages={totalPages}
          >
            {newsList.map(news => (
              <LatestItem
                key={news.id}
                newsData={news}
                searchWords={getSearchWords()}
                topicId={props.topicId.toString()}
              />
            ))}
          </InfiniteScroll>
        ) : (
          <NoResult>無新聞訊息</NoResult>
        )}
      </>
    </LatestContent>
  );
}

export default Latest;
