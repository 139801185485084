import React from 'react';

import OpendataContent from 'src/components/ui/content/Opendata/OpendataContent';
import {
  ApiTypeGet,
  ApiInfoTitleStyle,
  ApiParamThStyle,
  ApiParamTdStyle,
  ApiParamTrStyle,
  ApiOutputStyle,
  apiParamNameWidth,
  FirstLineIndent,
} from 'src/style/OpendataStyle';
import ApiToken from 'src/components/opendata/common/ApiToken';

const InventPubList: React.FC = () => {
  return (
    <OpendataContent
      sector="開放資料"
      feature="公報清單查詢 API"
      foldableDescription={{
        abstract:
          '使用者可透過本API查詢本國(TIPO)、美國(USPTO)、歐盟(EPO)、日本(JPO)及韓國(KIPO)各期專利公報之發行日(公開日/公告日)或案號(公開號/公告號)清單。',
        detail: (
          <>
            <FirstLineIndent indent={-31}>
              例1. 查詢2019年11月間美國專利公報之發行日，則可輸入參數 country=“US”,
              start=“20191101”, end=“20190130”
              查詢，結果可得20191105、20191107、20191112、20191114、20191119、20191121、20191126、20191128等8個美國專利公報發行日。
            </FirstLineIndent>
            <FirstLineIndent indent={-31}>
              例2. 查詢發行日為2019年11月14日的美國專利公報詳細案號清單，則可輸入參數 country=“US”,
              start=“20191114”，結果可得20190349249 A1、20190349327 A1、20190349650 A1等案號資料。
            </FirstLineIndent>
          </>
        ),
      }}
    >
      <>
        <p>提供公報清單查詢 API：</p>
        <ApiTypeGet />
        {process.env.REACT_APP_API_URL + 'open/api/patent/inventPub'}
        <ApiToken />

        <ApiInfoTitleStyle>輸入參數說明</ApiInfoTitleStyle>
        <ApiParamThStyle>
          <ApiParamTdStyle width={apiParamNameWidth}>Name</ApiParamTdStyle>
          <ApiParamTdStyle>Value</ApiParamTdStyle>
        </ApiParamThStyle>
        <ApiParamTrStyle>
          <ApiParamTdStyle width={apiParamNameWidth}>country</ApiParamTdStyle>
          <ApiParamTdStyle>
            國別：TW - 本國 | US - 美國 | EP - 歐盟 | JP - 日本 | KR - 韓國
          </ApiParamTdStyle>
        </ApiParamTrStyle>
        <ApiParamTrStyle>
          <ApiParamTdStyle width={apiParamNameWidth}>start</ApiParamTdStyle>
          <ApiParamTdStyle>公開/公告日起：yyyyMMdd</ApiParamTdStyle>
        </ApiParamTrStyle>
        <ApiParamTrStyle>
          <ApiParamTdStyle width={apiParamNameWidth}>end</ApiParamTdStyle>
          <ApiParamTdStyle>
            公開/公告日迄：yyyyMMdd
            <br />
            - 有輸入此參數時，回傳公開/公告日清單
            <br />- 未輸入此參數時，回傳公開/公告號及專利類別碼清單
          </ApiParamTdStyle>
        </ApiParamTrStyle>
        <ApiInfoTitleStyle>輸出結果範例：有輸入 end 參數</ApiInfoTitleStyle>
        <ApiOutputStyle>
          &emsp; [
          <br />
          &emsp;&emsp;{'{'}
          <br />
          &emsp;&emsp;&emsp;"publicationDate": "20190901"
          <br />
          &emsp;&emsp;{'}'},
          <br />
          &emsp;&emsp;{'{'}
          <br />
          &emsp;&emsp;&emsp;"publicationDate": "20190916"
          <br />
          &emsp;&emsp;{'}'},
          <br />
          &emsp;&emsp;{'{'}
          <br />
          &emsp;&emsp;&emsp;"publicationDate": "20190921"
          <br />
          &emsp;&emsp;{'}'}
          <br />
          &emsp;]
        </ApiOutputStyle>
        <ApiInfoTitleStyle>輸出結果範例：未輸入 end 參數</ApiInfoTitleStyle>
        <ApiOutputStyle>
          &emsp; [
          <br />
          &emsp;&emsp;{'{'}
          <br />
          &emsp;&emsp;&emsp;"kindCode": "S"
          <br />
          &emsp;&emsp;&emsp;"publicationNumber": "D000001"
          <br />
          &emsp;&emsp;{'}'},
          <br />
          &emsp;&emsp;{'{'}
          <br />
          &emsp;&emsp;&emsp;"kindCode": "B"
          <br />
          &emsp;&emsp;&emsp;"publicationNumber": "I000002"
          <br />
          &emsp;&emsp;{'}'},
          <br />
          &emsp;&emsp;{'{'}
          <br />
          &emsp;&emsp;&emsp;"kindCode": "B"
          <br />
          &emsp;&emsp;&emsp;"publicationNumber": "I000003"
          <br />
          &emsp;&emsp;{'}'}
          <br />
          &emsp;]
        </ApiOutputStyle>
      </>
    </OpendataContent>
  );
};

export default InventPubList;
