import React from 'react';
import styled from 'styled-components';
import { FaRss, FaChartBar, FaTh, FaDownload } from 'react-icons/fa';

import SubscriptionPane from './subscription/SubscriptionPane';
import StatisticsPane from './statistics/StatisticsPane';
import MatrixPane from './matrix/MatrixPane';
import useOutSideClick from 'src/hooks/useOutSideClick';
import { SystemMessage } from 'src/apps/applicationMessages';
import { alertMessage } from 'src/utils/ModalUtils';
import { checkLogin } from 'src/apps/auth/LoginUtils';
import { zToolbar } from 'src/style/theme/Z-Index';
import { ultrawhite, blue3rgba, pink6rgba } from 'src/style/theme/Color';

import { connect } from 'react-redux';
import { ReduxAppState } from 'src/redux/reducers';
import { changeActivePane } from 'src/redux/result/toolbar/action';
import { IPaginatedData } from 'src/types/PaginatedDataTypes';
import { IGetPatentTypes } from 'src/types/IGetPatentTypes';
import { DividingLine } from 'src/style/PatentContentStyle';
import { CSVLink } from 'react-csv';

const Container = styled.div`
  position: fixed;
  z-index: ${zToolbar};
  top: 96px;
  right: 48px;
`;

const Group = styled.div`
  margin-bottom: 8px;
  padding: 8px 0;
  border-radius: 4px;
`;

const GroupRed = styled(Group)`
  box-shadow: 0 5px 20px -5px rgba(0, 63, 84, 0.4);
  background-image: linear-gradient(to right, #f5406f, #bf244d 100%);
`;

const GroupBlue = styled(Group)`
  box-shadow: 0 5px 20px -5px rgba(0, 63, 84, 0.3);
  background-image: linear-gradient(127deg, #1f8ba3, #323846);
`;

const GroupItem = styled.div<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  color: ${ultrawhite};
  :hover {
    cursor: pointer;
  }
  svg {
    margin-right: 8px;
  }
`;

const GroupItemRed = styled(GroupItem)`
  background-color: ${props => props.isActive && pink6rgba(0.2)};
  :hover {
    background-color: ${pink6rgba(0.2)};
  }
`;

const GroupItemBlue = styled(GroupItem)`
  background-color: ${props => props.isActive && blue3rgba(0.2)};
  :hover {
    background-color: ${blue3rgba(0.2)};
  }
  a {
    color: ${ultrawhite};
  }
`;

interface IPaneContainer {
  offset: string;
  width: string;
  triangleColor: string;
}

const PaneContainer = styled.div<IPaneContainer>`
  width: ${props => props.width};
  border-radius: 4px;
  background-image: linear-gradient(155deg, #077790, #003f54);
  color: ${ultrawhite};
  position: absolute;
  z-index: 1;
  top: 0;
  /* 必須比zeplin的距離再多加上三角形的border-width，因為這段是算長方形的邊到最右的距離而不是三角形頂點到最右的距離。 */
  right: calc(116px + 4px + 10px);
  padding: 16px 32px;

  /* 不能加，會跑版 */
  /* overflow: auto; */

  ::after {
    /* # 無法漸層，先大概抓一個接近的顏色，如果寫死的話未來需要根據位置調整不同顏色。 */
    border-color: transparent transparent transparent ${props => props.triangleColor};
    border-style: solid;
    border-width: 10px;
    /* 必須指定，才能顯示內容 */
    content: '';
    height: 0px;
    right: -20px;
    /* 必須指定，否則會變梯形 */
    position: absolute;
    top: ${props => props.offset}; /* 15，75，130 */
    width: 0px;
  }
`;

interface IReduxProps {
  isPatentResultLoading: boolean;
  paginatedDataCount: number;
  isMatrixTagNotExist: boolean;
  isFacetLoading: boolean;
  facetsLength: number;
  activePane: string;
  changeActivePane: (pane: string) => void;
  paginatedData?: IPaginatedData<IGetPatentTypes>;
}

interface IProps extends IReduxProps {}

/** 搜尋結果-右側工具列 */
const RightToolbar: React.FC<IProps> = ({
  isPatentResultLoading,
  paginatedDataCount,
  isMatrixTagNotExist,
  isFacetLoading,
  facetsLength,
  activePane,
  changeActivePane,
  paginatedData,
}) => {
  const togglePane = (pane: string | boolean) => {
    if (typeof pane === 'boolean' && !pane) {
      changeActivePane('');
    } else if (typeof pane === 'string') {
      changeActivePane(pane);
    }
  };

  const closePane = () => {
    changeActivePane('');
  };

  const handleStatisticsClick = () => {
    if (isPatentResultLoading || isFacetLoading) {
      alertMessage(SystemMessage.RESULT_IS_LOADING);
    } else if (paginatedDataCount <= 0 || facetsLength === 0) {
      alertMessage(SystemMessage.RESULT_COUNT_NOT_ENOUGH);
    } else {
      togglePane('統計分析');
    }
  };

  const handleMatrixClick = () => {
    if (isPatentResultLoading) {
      alertMessage(SystemMessage.RESULT_IS_LOADING);
    } else if (paginatedDataCount <= 0 && isMatrixTagNotExist) {
      alertMessage(SystemMessage.RESULT_COUNT_NOT_ENOUGH);
    } else {
      togglePane('功效矩陣');
    }
  };

  const isSubscriptionShow = activePane === '專利追蹤';

  const nodeSubscription = useOutSideClick({
    show: isSubscriptionShow,
    setShow: togglePane,
  });

  const isStatisticsShow = activePane === '統計分析';

  const nodeStatistics = useOutSideClick({
    show: isStatisticsShow,
    setShow: togglePane,
  });

  const isMatrixShow = activePane === '功效矩陣';

  const nodeMatrix = useOutSideClick({
    show: isMatrixShow,
    setShow: togglePane,
  });

  // CSV EXPORT
  const csvFileName = '專利匯出結果.csv';
  const csvHeaders = [
    { label: '專利名稱', key: 'bibliographic.inventionTitle.title' },
    { label: '國別', key: 'country' },
    { label: '公開/公告號', key: 'publicationReferenceNumber' },
    { label: '公開/公告日', key: 'bibliographic.publicationReference.documentId.date' },
    { label: '發明人', key: 'bibliographic.parties.inventor.addressbook.name' },
    { label: '申請人', key: 'bibliographic.parties.applicant.addressbook.name' },
    { label: '摘要', key: 'inventionSpecification.abstract.text' },
  ];

  return (
    <Container>
      <GroupRed>
        <div ref={nodeSubscription as any}>
          <GroupItemRed
            isActive={isSubscriptionShow}
            onClick={() => checkLogin(() => togglePane('專利追蹤'))}
          >
            <FaRss size="16px" />
            專利追蹤
          </GroupItemRed>
          {isSubscriptionShow && (
            <PaneContainer offset="15px" width="304px" triangleColor=" #066981">
              <SubscriptionPane closePane={closePane} />
            </PaneContainer>
          )}
        </div>
      </GroupRed>
      <GroupBlue>
        <div ref={nodeStatistics as any}>
          <GroupItemBlue isActive={isStatisticsShow} onClick={handleStatisticsClick}>
            <FaChartBar size="16px" />
            統計分析
          </GroupItemBlue>
          {isStatisticsShow && (
            <PaneContainer offset="80px" width="304px" triangleColor=" #046279">
              <StatisticsPane />
            </PaneContainer>
          )}
        </div>
        <div ref={nodeMatrix as any}>
          <GroupItemBlue isActive={isMatrixShow} onClick={handleMatrixClick}>
            <FaTh size="16px" />
            功效矩陣
          </GroupItemBlue>
          {isMatrixShow && (
            <PaneContainer offset="120px" width="590px" triangleColor=" #035D74">
              <MatrixPane />
            </PaneContainer>
          )}
        </div>
        <DividingLine />
        <div>
          {paginatedData && (
            <GroupItemBlue isActive={isFacetLoading}>
              <FaDownload size="14px" />
              <CSVLink
                filename={csvFileName}
                headers={csvHeaders}
                data={paginatedData.data.map(prev => {
                  return {
                    ...prev,
                    publicationReferenceNumber:
                      prev.bibliographic.publicationReference.documentId.docNumber +
                      prev.bibliographic.publicationReference.documentId.kind,
                  };
                })}
              >
                專利匯出
              </CSVLink>
            </GroupItemBlue>
          )}
        </div>
      </GroupBlue>
    </Container>
  );
};

const mapStateToProps = (state: ReduxAppState) => {
  return {
    isPatentResultLoading: state.patentResultReducer.isLoading,
    paginatedDataCount: state.patentResultReducer.paginatedData.totalCount,
    isMatrixTagNotExist: !state.patentSearchReducer.filterTag,
    isFacetLoading: state.patentFilterReducer.isFacetsLoading,
    facetsLength: state.patentFilterReducer.facets.length,
    activePane: state.resultToolbarReducer.activePane,
  };
};

const mapDispatchToProps = {
  changeActivePane,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RightToolbar);
