import React from 'react';
import styled from 'styled-components';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import useRssFeedNews from 'src/hooks/useRssFeedNews';
import { IGetRssLatest } from 'src/types/api/IndexApiTypes';

import { dateFormatter } from 'src/utils/Formatter';

import {
  Container,
  Table,
  TRow,
  HRow,
  HOne,
  HTwo,
  HThree,
  ReadMore,
} from 'src/style/IndexTabStyle';
import { blue8, iceblue6, iceblue8, ultrawhite, fontblack1 } from 'src/style/theme/Color';

const ACTIVE_BG_COLOR = blue8;

const ListContainer = styled(Container)`
  width: 100%;
`;
const Header = styled(HRow)``;
const HSource = styled(HOne)``;
const HTitle = styled(HTwo)``;
const HPubTime = styled(HThree)``;
const Item = styled(TRow)<{ active: boolean }>`
  border-bottom: 1px solid ${iceblue8};
  background-color: ${props => (props.active ? ACTIVE_BG_COLOR : ultrawhite)};
  cursor: pointer;
`;
const Source = styled(HOne)`
  color: ${fontblack1};
`;
const Title = styled(HTwo)``;
const MultiLineEllipsisText = styled.div<{ active: boolean }>`
  margin: 0 -1rem 0 0;
  padding: 0 1rem 0 0;
  width: calc(100% - 1rem);
  max-height: 2.4rem;
  line-height: 1.2rem;
  overflow: hidden;
  position: relative;
  word-break: break-all;
  :before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
  }
  :after {
    content: '';
    position: absolute;
    right: 0;
    width: 1rem;
    height: 1rem;
    margin-top: 0.2rem;
    background: ${props => (props.active ? ACTIVE_BG_COLOR : ultrawhite)};
  }
`;
const PubTime = styled(HThree)`
  color: ${iceblue6};
`;

interface IProps extends RouteComponentProps {
  header: string[];
  news: Array<IGetRssLatest>;
  hover: number;
  setHover: (idx: number) => void;
  gaClass: string;
  readMoreUrl: string;
}

const NewsItems = ({ header, news, hover, setHover, gaClass, readMoreUrl, history }: IProps) => {
  const [handleClick] = useRssFeedNews();

  return (
    <ListContainer>
      <Table>
        <Header>
          <HSource>{header[0]}</HSource>
          <HTitle>{header[1]}</HTitle>
          <HPubTime>{header[2]}</HPubTime>
        </Header>
        {news.length > 0 &&
          news.map((item, idx) => {
            const activeBool = idx === hover;
            return idx < 5 ? (
              <Item
                key={item.link + idx}
                active={activeBool}
                onMouseOver={() => setHover(idx)}
                onClick={() => handleClick(item.id, item.link)}
                className={gaClass}
              >
                <Source>
                  <MultiLineEllipsisText active={activeBool}>{item.feedName}</MultiLineEllipsisText>
                </Source>
                <Title>
                  <MultiLineEllipsisText active={activeBool} className="title">
                    {item.title}
                  </MultiLineEllipsisText>
                </Title>
                <PubTime>{dateFormatter(item.releaseDate)}</PubTime>
              </Item>
            ) : (
              ''
            );
          })}
      </Table>
      <ReadMore onClick={() => history.push(readMoreUrl)} className={gaClass}>
        顯示全部
      </ReadMore>
    </ListContainer>
  );
};

export default withRouter(NewsItems);
