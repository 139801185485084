import React, { useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import { withRouter, RouteComponentProps } from 'react-router';
import { FaSearch } from 'react-icons/fa';

import { alertMessage } from 'src/utils/ModalUtils';
import { SystemMessage } from 'src/apps/applicationMessages';

import { blue6, blue7, iceblue1, iceblue2, iceblue6, ultrawhite } from 'src/style/theme/Color';
import SearchSuggestion from 'src/components/common/search-bar/SearchSuggestion';
import useOutSideClick from 'src/hooks/useOutSideClick';
import IndustrialClassDropdown from 'src/components/trend/industrialClass/ui/Dropdown';
import { toDropdownItem } from 'src/utils/IndustryUtils';

// redux
import { connect } from 'react-redux';
import { IIndustrialClassType } from 'src/types/IndustryTypes';
import { ReduxAppState } from 'src/redux/reducers';
import { composeBasicRequest } from 'src/redux/actions/patentSearchAction';
import { IBasicRequest } from 'src/types/PatentSearchTypes';
import { ITokenPayload } from 'src/apps/auth/Auth';
import { CountryDropdown } from 'src/components/search/CountryDropdown';
import { countryItems } from 'src/constants/PatentSearchConstants';
import { IDropdownItem } from 'src/components/common/Dropdown';

const Container = styled.div<{ init: boolean; isSearch: boolean }>`
  width: 100%;
  display: ${props => (props.init ? 'flex' : 'none')};
  align-items: center;
  margin-left: ${props => !props.isSearch && '100%'};

  i {
    padding: 0 12px;
    display: block;
    svg {
      vertical-align: middle;
    }
  }

  animation: ${props => (props.isSearch ? 'easeTopSearchBar .3s' : 'foldTopSearchBar .5s')} ease;
  @keyframes easeTopSearchBar {
    0% {
      margin-left: 100%;
    }
    100% {
      margin-left: 0;
    }
  }
  @keyframes foldTopSearchBar {
    0% {
      margin-left: 0;
    }
    100% {
      margin-left: 100%;
    }
  }
`;
const InputContainer = styled.div`
  width: calc(100% - 200px);
  min-width: 100px;
  position: relative;
`;
const Input = styled.input.attrs({ type: 'text' })`
  width: 100%;
  padding-left: 24px;
  color: ${iceblue2};
  background-color: ${ultrawhite};
  border: none;
  outline: none;
  ::placeholder {
    color: ${iceblue6};
  }
`;

const customStyleDropdownButton = css`
  width: 112px;
  padding: 8px 8px 8px 16px;
  background-color: ${ultrawhite};
  border: 1px solid ${blue6};
`;

const customStyleDropdownButtonActive = css`
  background-color: ${blue6};
`;

const customStyleDropdownMenu = css`
  max-width: calc(100% - 384px);
`;

const customStyleCountryButton = css<{ active: boolean }>`
  background-color: ${props => (props.active ? blue7 : ultrawhite)};
  color: ${iceblue1};
  &:hover {
    background-color: ${blue7};
  }
  border: 1px solid ${blue6};
  padding: 20px 8px 20px 16px;
  width: 112px;
`;

interface IReduxMappingProps {
  member: ITokenPayload;
  composeBasicRequest: (basicRequest: IBasicRequest) => void;
}

interface IProps extends RouteComponentProps, IReduxMappingProps {
  handleSearch: () => void;
  isSearch: boolean;
  initSearch: boolean;
}

const TopSearchBar: React.FC<IProps> = props => {
  const [inputText, setInputText] = useState('');
  const [isSuggestionShow, setIsSuggestionShow] = useState(false);
  const [checkedIndustrialClass, setCheckedIndustrialClass] = useState<IIndustrialClassType[]>(
    toDropdownItem(props.member.info.industrialClassIds.map(id => id.toString())),
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const [countrySelectedItem, setCountrySelectedItem] = useState<IDropdownItem>({
    name: '本國',
    value: 'TW',
  });

  const handleSuggestionClick = (value?: string) => {
    if (value && value !== '') {
      setInputText(value);
    }
    inputRef.current && inputRef.current.focus();
  };

  const handleSuggestionClose = () => {
    setIsSuggestionShow(false);
  };

  const handleSubmit = () => {
    if (checkedIndustrialClass.length === 0 && inputText.trim() === '') {
      alertMessage(SystemMessage.SEARCH_KEYWORD_EMPTY);
    } else {
      props.composeBasicRequest({
        industrialClassIds: checkedIndustrialClass.map(item => item.id.toString()),
        queryString: inputText,
        advanceKeywords: [{ keyCode: 'PO', keyword: countrySelectedItem.value }],
      });
      props.handleSearch();
      props.history.push('/result');
    }
  };

  const node = useOutSideClick({
    show: isSuggestionShow,
    setShow: setIsSuggestionShow,
  });

  return (
    <Container init={props.initSearch} isSearch={props.isSearch}>
      <i>
        <FaSearch color="#7b8387" size="20px" onClick={handleSubmit} />
      </i>
      <IndustrialClassDropdown
        checkedItems={checkedIndustrialClass}
        setCheckedItems={setCheckedIndustrialClass}
        customButtonStyle={customStyleDropdownButton}
        customButtonActiveStyle={customStyleDropdownButtonActive}
        customMenuStyle={customStyleDropdownMenu}
      />
      <CountryDropdown
        items={countryItems}
        activeItem={countrySelectedItem}
        handleOnclick={item => setCountrySelectedItem(item)}
        width="112px"
        customStyleSelectedItem={customStyleCountryButton}
      />
      <InputContainer ref={node as any}>
        <Input
          ref={inputRef as any}
          value={inputText}
          onChange={e => setInputText(e.target.value)}
          onFocus={() => setIsSuggestionShow(true)}
          onKeyDown={e => e.keyCode === 13 && handleSubmit()}
          placeholder="請輸入關鍵字 / 專利號 / 申請人...."
        />
        <SearchSuggestion
          cssTop="48px"
          isShow={isSuggestionShow}
          industryIds={checkedIndustrialClass.map(item => item.id)}
          inputText={inputText}
          handleSuggestionClick={handleSuggestionClick}
          handleSuggestionClose={handleSuggestionClose}
        />
      </InputContainer>
    </Container>
  );
};

const mapStateToProps = (state: ReduxAppState) => ({
  member: state.memberReducer.memberPayload,
});

const mapDispatchToProps = {
  composeBasicRequest,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(TopSearchBar),
);
