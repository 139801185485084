import { useState, useEffect } from 'react';
import _ from 'lodash';

const defaultThreshold = 100;

export function useWindowScroll(threshold: number = defaultThreshold) {
  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    const listener = _.throttle(() => {
      window.scrollY > threshold ? setHasScrolled(true) : setHasScrolled(false);
    }, 300);

    window.addEventListener('scroll', listener);
    return () => {
      window.removeEventListener('scroll', listener);
    };
  }, [threshold]);

  return [hasScrolled];
}
