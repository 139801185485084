import { Api } from 'src/api/helpers/apiBase';
import { IGetFilmList, IPatentStatAmount, IPatentStatRange } from 'src/types/api/IndexApiTypes';

export const IndexService = () => {
  // 取得影片列表
  const getFilmList = async () => {
    const res = await Api().get('film/list');
    return res.data as IGetFilmList;
  };
  // 取得專利數量
  const getPatentAmount = async () => {
    const res = await Api().get('patent/stat/amount');
    return res.data as IPatentStatAmount;
  };
  // 取得專利時間範圍
  const getPatentRange = async () => {
    const res = await Api().get('patent/stat/range');
    return res.data as IPatentStatRange;
  };

  return {
    getFilmList,
    getPatentAmount,
    getPatentRange,
  };
};
