import React from 'react';
import styled from 'styled-components';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { Button } from 'src/style/theme/Common';
import { Spinner } from 'src/components/ui/interactive/Spinner';
import { Container, Table, HRow, HOne, HTwo, HThree } from 'src/style/IndexTabStyle';

const Content = styled.div`
  padding: 16px 32px;
  height: 370px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const MsgAncher = styled.div`
  padding: 0 0 56px;
  text-align: center;
`;
const Message = styled.div`
  padding: 0 0 32px;
`;

interface IProps extends RouteComponentProps {
  tab: string;
  header: Array<string>;
  error: boolean;
  noData: boolean;
  isLoading: boolean;
}

const ExceptionGuestPanel: React.FC<IProps> = ({ tab, header, error, noData, isLoading }) => {
  const switchContent = () => {
    if (isLoading) {
      return <Spinner width="150px" />;
    } else {
      if (noData) {
        return <Message>目前尚無{tab}</Message>;
      } else {
        return (
          <>
            <Message>目前{tab}服務似乎出了點小問題，如有需要，請聯絡系統管理員。</Message>
            <a href="mailto:tipoeservice@tipo.gov.tw">
              <Button type="button" template="primary">
                聯絡系統管理員
              </Button>
            </a>
          </>
        );
      }
    }
  };

  return (
    <Container>
      <Table>
        <HRow>
          <HOne>{header[0]}</HOne>
          <HTwo>{header[1]}</HTwo>
          <HThree>{header[2]}</HThree>
        </HRow>
      </Table>
      <Content>
        <MsgAncher>{switchContent()}</MsgAncher>
      </Content>
    </Container>
  );
};

export default withRouter(ExceptionGuestPanel);
