import React from 'react';
import styled, { css } from 'styled-components';

// prettier-ignore
const Container = styled.div<{ checkedStyle: number, color?: string ,isBold?:boolean}>`
  margin: 8px 0;
  display: flex;
  align-items: center;
  position: relative;
  user-select: none;
  input {
    display: none;
    width: 0;
    height: 0;
    opacity: 0;
    position: absolute;
    cursor: pointer;
  }

  span {
    position: absolute;
    top: 3px;
    left: 0;
    width: 18px;
    height: 18px;
    border-radius: 2px;
    ${props => {
      switch(props.checkedStyle) {
      case 1: // 一般勾選 (打勾)
      case 2: // 子項部分勾選 (橫線)
        return css`
          border: 1px solid #077790;
          background-color: #077790;
        `;
      case 3: // 已勾選且不可更改 (灰色打勾)
        return css`
          border: 1px solid #b3b9bc;
          background-color: #b3b9bc;
        `;
      case 0: // 未勾選
      default:
        return css`
          border: 1px solid #b3b9bc;
          background-color: #ffffff;
        `;
    }}}
    ::after {
      content: '';
      position: absolute;
      border: solid white;
      border-width: 0 3px 3px 0;
      ${props => {
        switch(props.checkedStyle) {
        case 0: // 未勾選
          return css`
            display: none;
          `;
        case 2: // 子項部分勾選 (橫線)
          return css`
            left: 2.5px;
            top: 6px;
            width: 8px;
            height: 0;
          `;
        default:
          return css`
            left: 5px;
            top: 1px;
            width: 4px;
            height: 8px;
            transform: rotate(45deg);
          `;
      }}}
    }
  }

  label {
    min-height: 18px;
    margin: 0 0 0 28px;
    font-weight: ${props => (props.isBold ? 'bold' : 'unset')};
    color: ${props => props.color ? props.color : '#232327'};
    cursor: pointer;
  }
`;
const ReverseThemeContainer = styled(Container)`
  span {
    border: 1px solid #b3b9bc;
    background-color: #ffffff;
    ::after {
      border: solid #077790;
      border-width: 0 3px 3px 0;
    }
  }
`;

interface IProps {
  name: string;
  checked: boolean;
  children: React.ReactElement<HTMLInputElement>;
  color?: string;
  themeReverse?: boolean;
  partialChecked?: boolean;
  disableChecked?: boolean;
  isBold?: boolean;
}

const Checkbox: React.FC<IProps> = props => {
  // 無勾選
  let checkedStyle = 0;

  // checked: 一般勾選
  if (props.checked && !props.partialChecked && !props.disableChecked) {
    checkedStyle = 1;
  }
  // partialChecked: 子項目部分勾選
  else if (props.partialChecked) {
    checkedStyle = 2;
  }
  // disableChecked: 已勾選且不可更改
  else if (props.disableChecked) {
    checkedStyle = 3;
  }

  return (
    <>
      {props.themeReverse ? (
        <ReverseThemeContainer checkedStyle={checkedStyle} color={props.color}>
          <label>
            {props.children}
            <span />
            {props.name}
          </label>
        </ReverseThemeContainer>
      ) : (
        <Container checkedStyle={checkedStyle} color={props.color} isBold={props.isBold}>
          <label>
            {props.children}
            <span />
            {props.name}
          </label>
        </Container>
      )}
    </>
  );
};

export default Checkbox;
