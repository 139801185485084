import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { blue2, iceblue1, iceblue5, iceblue8, ultrawhite } from 'src/style/theme/Color';
import { Carousel } from 'src/style/ResultGridItemStyle';
import BasicCarouselContent from './BasicCarouselContent';
import { IOverlay } from '../ResultGridItem';
import { openCustomModal } from 'src/redux/actions/modalAction';
import { CustomModalType } from 'src/types/ModalTypes';
import { connect } from 'react-redux';
import { ReduxAppState } from 'src/redux/reducers';
import TmMetadataModal from 'src/components/ui/interactive/modal/custom/TmMetadataModal';
import { ICodeDescriptionMapping, TmApplMetadata } from 'src/types/TrademarkTypes';
import { FeedbackType, TrademarkService } from 'src/api/Trademark';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import { FaThumbsUp, FaThumbsDown } from 'react-icons/fa';
import Tooltip from 'src/components/ui/interactive/Tooltip';

const trademarkService = TrademarkService();

const Overlay = styled.div<{ isShow: boolean }>`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: ${iceblue8};
  transition: 0.5s ease;
  opacity: ${props => (props.isShow ? 1 : 0)};
  display: flex;
  user-select: text;
  align-items: center;
  justify-content: space-between;
`;

const BaseInfo = styled.div`
  padding: 8px 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${iceblue1};
  font-weight: 500;
  cursor: default;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const IconWithTooltip = styled(Tooltip)`
  display: inline-block;
  margin: 2px 6px;
`;

const FeedbackIcon = styled.div<{ isSelected?: boolean }>`
  background-color: ${ultrawhite};
  display: inline-block;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  padding: 8px;
  cursor: pointer;

  svg {
    color: ${props => (props.isSelected ? `${blue2}` : `${iceblue5}`)};
  }
  :hover {
    svg {
      color: ${blue2};
    }
  }
}
`;

const CarouselFixed = styled(Carousel)`
  cursor: pointer;
  height: 180px;
`;

interface IReduxMappingProps {
  reduxOpenCustomModal: (customModalType: CustomModalType, customModalId?: number) => void;
  customModalType: CustomModalType;
  customModalId?: number;
}

interface IProps extends IOverlay, IReduxMappingProps {
  caseNo: string;
  imagePaths: Array<string>;
  metadata: TmApplMetadata;
  listIndex?: number;
  handleFeedbackCheck: (caseNo: string, feedbackType: FeedbackType) => void;
  selectedType: FeedbackType;
  imgClassMapping: ICodeDescriptionMapping;
}

/** 帶有 Overlay 的輪播 (for 以圖找圖) */
const OverlayCarousel = ({
  caseNo,
  details,
  detailPageUrl,
  onClickToPreview,
  imagePaths,
  metadata,
  listIndex,
  imgClassMapping,
  handleFeedbackCheck,
  reduxOpenCustomModal,
  customModalType,
  customModalId,
  selectedType,
}: IProps) => {
  const [isOverlayShow, setIsOverlayShow] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(!metadata);
  const [isFailed, setIsFailed] = useState<boolean>(false);

  const isModalOpen =
    customModalType === CustomModalType.TM_METADATA && customModalId === listIndex;

  const handlePreview = () => {
    reduxOpenCustomModal(CustomModalType.TM_METADATA, listIndex);
  };

  const fetchedData = useRef(metadata);
  useEffect(() => {
    // isLoading 初始值根據是否已有 metadata 決定, 如果 1000 張 metadata 還沒有載完, 則會個別呼叫 API 取得資料
    if (isLoading) {
      // 取得檔名
      const imagePath = new URL(imagePaths[0]).searchParams.get('imagePath');
      // call API 取得詮釋資料
      imagePath
        ? trademarkService
            .getTmMetadatas([imagePath])
            .then(response => {
              // 把圖形路徑 imgclass 轉 category 格式(01A01)
              response[0]['img-class'] = response[0]['img-class'].map(
                item => imgClassMapping[item],
              );
              fetchedData.current = response[0];
              // 因非同步關係, 此判斷不可放在finally後, 否則導致詮釋資料一直為 null
              if (!fetchedData.current) {
                throw new Error('詮釋資料取得失敗:' + imagePath);
              }
            })
            .catch(error => {
              errorHandler(error);
              setIsFailed(true);
            })
            .finally(() => setIsLoading(false))
        : setIsFailed(true);
    }
  }, [imagePaths, isLoading, imgClassMapping]);

  return (
    <>
      <CarouselFixed
        // onMouseOver、onMouseOut 帶有 propagation 事件，會造成比onMouseEnter、onMouseLeave 更多事件觸發，
        // 但在此處:
        // onMouseOver 可使 Modal 關閉且游標正處於 Carousel 上方時重新show overlay
        // onMouseOut 可讓 滑鼠快速移動時 Overlay 正常隱藏
        onMouseOver={() => setIsOverlayShow(true)}
        onMouseOut={() => setIsOverlayShow(false)}
        onClick={handlePreview}
      >
        <BasicCarouselContent imagePaths={imagePaths} />
        <Overlay
          isShow={
            isOverlayShow ||
            (selectedType === FeedbackType.POS || selectedType === FeedbackType.NEG)
          }
          onClick={e => e.stopPropagation()}
        >
          <BaseInfo>
            <div>
              <IconWithTooltip overlay="相似度較高" placement="top">
                <FeedbackIcon isSelected={selectedType === FeedbackType.POS}>
                  <FaThumbsUp
                    size={20}
                    onClick={e => handleFeedbackCheck(caseNo, FeedbackType.POS)}
                  />
                </FeedbackIcon>
              </IconWithTooltip>
              <IconWithTooltip overlay="相似度較低" placement="top">
                <FeedbackIcon isSelected={selectedType === FeedbackType.NEG}>
                  <FaThumbsDown
                    size={20}
                    onClick={e => handleFeedbackCheck(caseNo, FeedbackType.NEG)}
                  />
                </FeedbackIcon>
              </IconWithTooltip>
            </div>
            <div>{details[0].content}</div>
          </BaseInfo>
        </Overlay>
      </CarouselFixed>
      {isModalOpen && (
        <TmMetadataModal
          metadata={fetchedData.current}
          imagePath={imagePaths[0]}
          isLoading={isLoading}
          isFailed={isFailed}
          caseNo={caseNo}
          handleFeedbackCheck={handleFeedbackCheck}
          selectedType={selectedType}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: ReduxAppState) => {
  return {
    customModalType: state.modalReducer.customModalType,
    customModalId: state.modalReducer.customModalId,
    imgClassMapping: state.trademarkVisualSearchReducer.imgClassMapping,
  };
};

const mapDispatchToProps = {
  reduxOpenCustomModal: openCustomModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OverlayCarousel);
