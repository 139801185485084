import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Content from 'src/components/ui/content/Content';

const Container = styled.div`
  color: #263237;
`;
const Sector = styled.div`
  margin: 0 -32px 20px;
  padding: 0 32px 20px;
  border-bottom: 1px solid #e5eff1;
`;
const FeatureItems = styled.div`
  padding: 10px 0 0 0;
  display: flex;
  flex-flow: row;
  align-items: flex-start;
`;
const Feature = styled.div`
  width: 20%;
  a,
  span {
    color: #077790;
    cursor: pointer;
  }
`;
const Info = styled.div`
  width: 80%;
  color: #444444;
`;

const sectors = [
  {
    sector: '專利專區',
    features: [
      {
        feature: '推薦專利',
        path: '/showcase/recommend',
        info: '根據您瀏覽的專利，使用ＡＩ推測您可能感興趣的專利，增進使用效率。',
      },
      {
        feature: '最新專利',
        path: '/showcase/new',
        info: '每日更新專利，追蹤最新潮流你我一起。',
      },
    ],
  },
  {
    sector: '專利檢索',
    features: [
      {
        feature: '基本檢索',
        path: '/search/basic',
        info: '可做簡單字串查詢，或是自由組合查詢條件。',
      },
      {
        feature: '進階檢索',
        path: '/search/advanced',
        info: '提供查詢條件產生工具，幫助您快速產生查詢條件。',
      },
      {
        feature: '以文找文',
        path: '/search/searchByText',
        info: '可以上傳檔案或輸入一段文字，讓我們幫您找出最相關的專利。',
      },
      {
        feature: '表格檢索',
        path: '/search/form',
        info: '細分各項查詢條件，幫助您一步一步產生查詢字串。',
      },
      {
        feature: '號碼檢索',
        path: '/search/number',
        info: '利用專利公開號/公告號/申請號，查詢相關的專利資訊。',
      },
      {
        feature: '布林檢索',
        path: '/search/boolean',
        info: '利用布林運算，協助您進一步篩選您所需的專利資訊。',
      },
      // {
      //   feature: '全球書目',
      //   path: '/search/docdb',
      //   info: '查詢世界級專利書目資料庫DOCDB的最新資料，即時掌握全球產業趨勢。',
      // },
      {
        feature: '以圖找圖',
        path: '/search/searchByImage',
        info: '可以上傳並裁切圖片，為您搜尋指定國際工業設計分類下相似的設計專利。',
      },
    ],
  },
  {
    sector: '新聞專區',
    features: [
      {
        feature: '新聞頻道',
        path: '/news/latest',
        info: '提供您最新最快的產業脈動與即時新聞。',
      },
    ],
  },
  {
    sector: '開放資料',
    features: [
      {
        feature: '公報 OPEN DATA 下載',
        path: '/data/opendata',
        info: '提供下載各國專利公報資料，分為「整期ZIP檔」及「單一案件ZIP檔」2種下載方式。',
      },
      {
        feature: '公報清單查詢 API',
        path: '/data/inventPub',
        info: '提供查詢各國專利公報之專利清單。',
      },
      {
        feature: '公報ZIP檔下載 API',
        path: '/data/inventPubReport',
        info: '提供下載單一專利案件ZIP檔。',
      },
      {
        feature: '專利查詢 API',
        path: '/data/search',
        info: '透過自訂檢索條件，查詢符合條件之多篇專利內容。',
      },
      {
        feature: '專利說明書 API',
        path: '/data/patent',
        info: '透過專利識別碼，查詢單一專利案件內容。',
      },
      {
        feature: '雙語詞彙 API',
        path: '/data/bilingual',
        info: '線上繁中、英文、簡中專利技術詞彙查詢 API。',
      },
    ],
  },
  {
    sector: '專欄文章',
    features: [
      {
        feature: '專欄文章',
        path: '/article/columnist',
        info: '不定期向專家邀稿，為您掌握重要資訊。',
      },
      {
        feature: '影片專區',
        path: '/article/video',
        info: '為您提供精選的專利相關影片。',
      },
    ],
  },
  {
    sector: '常見問題',
    features: [
      {
        feature: '網站導覽',
        path: '/question/sitemap',
        info: '簡明說明網站各功能及連結，幫助您搜尋與到達您所需的功能頁面。',
      },
      {
        feature: 'FAQ',
        path: '/question/faq',
        info: '提供網站常見問題查詢。',
      },
      {
        feature: '聯絡我們',
        path: '/question/contactus',
        info: '提供聯絡系統管理員的表單，我們會儘快為您回覆與處理',
      },
    ],
  },
];

const sitemap: React.FC = () => (
  <Content sector="常見問題" feature="網站導覽">
    <Container>
      {sectors.map(item => (
        <Sector key={item.sector}>
          {item.sector}
          {item.features.map(it => (
            <FeatureItems key={it.feature}>
              <Feature>
                <Link to={it.path}>{it.feature}</Link>
              </Feature>
              <Info>{it.info}</Info>
            </FeatureItems>
          ))}
        </Sector>
      ))}
    </Container>
  </Content>
);

export default sitemap;
