import React, { useState } from 'react';
import { FaRegCopy } from 'react-icons/fa';

import {
  ApiTokenStyle,
  ApiTokenCopyStyle,
  ApiInfoTitleStyle,
  ApiParamThStyle,
  ApiParamTrStyle,
  ApiParamTdStyle,
  apiParamNameWidth,
} from 'src/style/OpendataStyle';
import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import MiniLoader from 'src/components/ui/interactive/MiniLoader';

const ApiToken: React.FC = () => {
  const [token, setToken] = useState<string | null>(null);
  const [isTokenError, setIsTokenError] = useState(false);

  const copyToken = () => {
    let token = document.getElementById('token') as HTMLInputElement;
    token.setAttribute('type', 'text');
    token.select();
    document.execCommand('copy');

    token.setAttribute('type', 'hidden');
    const selection = window.getSelection();
    selection && selection.removeAllRanges();
  };

  const getToken = async () => {
    try {
      const res = await Api().get('bilingual/token');
      setToken(res.data);
    } catch (error) {
      setIsTokenError(true);
      errorHandler(error);
    }
  };
  getToken();

  return (
    <>
      <ApiTokenStyle>
        <p>API Key</p>
        {token === null && !isTokenError && (
          <MiniLoader diameter={'24px'} borderWidth={'2px'} margin={'0 4px'} />
        )}
        {token && token !== '' && !isTokenError && (
          <>
            <p>{token}</p>
            <input type="hidden" id="token" value={token} />
            <ApiTokenCopyStyle onClick={copyToken}>
              <FaRegCopy size="15px" />
              複製
            </ApiTokenCopyStyle>
          </>
        )}
        {token === '' && !isTokenError && (
          <p>
            申請 API Key 請電郵&nbsp;
            <a href="mailto:ipoid@tipo.gov.tw?subject=申請產業專利知識平台API Key">
              ipoid@tipo.gov.tw
            </a>
          </p>
        )}
        {isTokenError && <p>目前 API 服務似乎出了點小問題，請稍後嘗試重新整理頁面。</p>}
      </ApiTokenStyle>
      <ApiInfoTitleStyle>Header 參數說明</ApiInfoTitleStyle>
      <ApiParamThStyle>
        <ApiParamTdStyle width={apiParamNameWidth}>Name</ApiParamTdStyle>
        <ApiParamTdStyle>Value</ApiParamTdStyle>
      </ApiParamThStyle>
      <ApiParamTrStyle>
        <ApiParamTdStyle width={apiParamNameWidth}>keyId</ApiParamTdStyle>
        <ApiParamTdStyle>請點選上方顯示 API Key 取得</ApiParamTdStyle>
      </ApiParamTrStyle>
    </>
  );
};

export default ApiToken;
