import React from 'react';
import styled from 'styled-components';
import { ultrawhite, ultrawhitergba, ultrablackrgba } from 'src/style/theme/Color';

const Container = styled.div`
  min-width: 960px;
  border-radius: 4px;
  box-shadow: 0 0 50px 0 ${ultrablackrgba(0.1)};
`;
const Header = styled.div`
  height: 118px;
  color: ${ultrawhite};
  background-image: linear-gradient(95deg, #2ec3c2, #0cafcc);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 16px 32px;
`;
const HeaderRoute = styled.div`
  color: ${ultrawhitergba(0.8)};
`;
const Feature = styled.div`
  margin-bottom: 4px;
  height: 36px;
  line-height: 36px;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  align-items: flex-end;
  span {
    padding: 0 0 0 8px;
    display: block;
    font-size: 15px;
    line-height: 24px;
    font-weight: normal;
  }
`;
const Content = styled.div`
  min-height: 450px;
`;

interface IProps {
  root?: string;
  sector: string;
  feature?: string;
  subTitle?: string;
  searchBar: JSX.Element;
  children: JSX.Element;
}

const IndustrialTrendsContent: React.FC<IProps> = props => {
  return (
    <Container>
      <Header>
        <HeaderRoute>
          {props.root ? props.root : '首頁'}&nbsp;/&nbsp;{props.sector}
          {props.feature && ` / ${props.feature}`}
        </HeaderRoute>
        <Feature>
          {props.feature ? props.feature : props.sector}
          <span>{props.subTitle}</span>
        </Feature>
        {props.searchBar}
      </Header>
      <Content>{props.children}</Content>
    </Container>
  );
};

export default IndustrialTrendsContent;
