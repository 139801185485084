import { IDropdownProps, ISelectedItem } from 'src/components/common/Dropdown';

/** TODO: 功能識別前綴字 BASE_ACTION_PREFIX 提出為變數的方法? */
/** 更改圖表標題 */
export const CHANGE_CHART_TITLE = 'iPKM/result/toolbar/statistics/CHANGE_CHART_TITLE';
/** 更改分析項目選取項目 */
export const CHANGE_SELECTED_FACET_FIELD =
  'iPKM/result/toolbar/statistics/CHANGE_SELECTED_FACET_FIELD';
/** 更改分析數量選取項目 */
export const CHANGE_SELECTED_ANALYSIS_NUM =
  'iPKM/result/toolbar/statistics/CHANGE_SELECTED_ANALYSIS_NUM';
/** 更改圖表類型 */
export const CHANGE_SELECTED_CHART_TYPE =
  'iPKM/result/toolbar/statistics/CHANGE_SELECTED_CHART_TYPE';
/** 清除表單資料 */
export const CLEAR_STATISTICS_FORM = 'iPKM/result/toolbar/statistics/CLEAR_STATISTICS_FORM';

export interface StatisticsFormState {
  chartTitle: string;
  selectedItem: ISelectedItem;
  selectedChartType: string;
}

interface ChangeChartTitleAction {
  type: typeof CHANGE_CHART_TITLE;
  chartTitle: string;
}

interface ChangeSelectedFacetField {
  type: typeof CHANGE_SELECTED_FACET_FIELD;
  facetField: IDropdownProps['activeItem'];
}

interface ChangeSelectedAnalysisNum {
  type: typeof CHANGE_SELECTED_ANALYSIS_NUM;
  analysisNum: IDropdownProps['activeItem'];
}

interface ChangeSelectedChartTypeAction {
  type: typeof CHANGE_SELECTED_CHART_TYPE;
  selectedChartType: string;
}

interface ClearStatisticsFormAction {
  type: typeof CLEAR_STATISTICS_FORM;
}

export type StatisticsFormActionTypes =
  | ChangeChartTitleAction
  | ChangeSelectedFacetField
  | ChangeSelectedAnalysisNum
  | ChangeSelectedChartTypeAction
  | ClearStatisticsFormAction;
