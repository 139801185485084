import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { FaSortAmountDown } from 'react-icons/fa';

import ResultListItem from 'src/components/result/ResultListItem';
import FilterDropdown from 'src/components/ui/interactive/dropdown-select/FilterDropdown';
import InfiniteScroll from 'src/components/ui/interactive/InfiniteScroll';
import { Spinner } from 'src/components/ui/interactive/Spinner';

import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import { IPaginatedData } from 'src/types/PaginatedDataTypes';
import { IGetPatentTypes } from 'src/types/IGetPatentTypes';
import { IExpertPatentRequest, ExpertPatentKind, IExpert } from 'src/types/ExpertTypes';
import { dateRangeItems, sortTypeItems } from 'src/constants/PatentSearchConstants';
import { defaultPaginatedData } from 'src/types/defaultData/paginatedDataDefault';
import {
  ultrawhite,
  blue2,
  blue6,
  blue7,
  blue8,
  iceblue3,
  iceblue5,
  iceblue4,
} from 'src/style/theme/Color';
import { numFormatter } from 'src/utils/Formatter';

const Container = styled.div`
  border-top: 1px solid ${blue6};
  background-color: ${ultrawhite};
`;

const Header = styled.div`
  padding: 0 32px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${blue7};
`;

const HeaderLeft = styled.div`
  font-size: 16px;
  color: ${iceblue5};
  align-items: center;
  display: flex;
  > span {
    margin-right: 36px;
  }
`;

const HeaderRight = styled.div`
  width: 23.5%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 8px 24px;
`;

const SpinnerBox = styled.div`
  min-height: 350px;
`;

const NoResult = styled.div`
  margin: 16px 32px;
  padding: 16px 32px;
  background-color: ${blue8};

  div {
    color: ${blue2};
    font-weight: 600;
    margin-bottom: 8px;
  }
  span {
    color: ${iceblue3};
  }
`;

const PatentKindTab = styled.div<{ selected: boolean; clickAble: boolean }>`
  position: relative;
  margin-right: 44px;
  font-size: ${props => (props.selected ? '20px' : '19px')};
  color: ${props => (props.selected ? '#333333' : iceblue4)};
  font-weight: ${props => (props.selected ? '600' : 'normal')};
  text-align: center;
  ::after {
    position: absolute;
    bottom: -14px;
    width: 100%;
    height: 3px;
    left: 0;
    content: '';
    background-color: ${props => (props.selected ? blue2 : '')};
    border-radius: 4px;
  }
  cursor: ${props => (props.clickAble ? 'pointer' : 'not-allowed')};

  border-radius: 4px;
`;

interface IProps {
  expertId?: string;
  expert: IExpert;
}

/** 專家履歷 - 相關專利文獻 */
const ExpertPatent: React.FC<IProps> = ({ expertId, expert }) => {
  // 頁面讀取狀態
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  // 頁面控制項目
  const defaultSelectedItem = {
    dateRange: { name: '不限時間', value: '' },
    sortByDate: { name: '排序', value: '' },
  };
  const [selectedItem, setSelectedItem] = useState(defaultSelectedItem);
  // 查詢參數
  const [reqParams, setReqParams] = useState<IExpertPatentRequest>({
    pageNo: 1,
  });
  const [patentKind, setPatentKind] = useState<ExpertPatentKind>(
    expert.inventionCount > 0
      ? ExpertPatentKind.INVENTION
      : expert.utilityCount > 0
      ? ExpertPatentKind.UTILITY
      : expert.designCount > 0
      ? ExpertPatentKind.DESIGN
      : ExpertPatentKind.INVENTION,
  );
  // API 回傳結果
  const [resPatents, setResPatents] = useState<IPaginatedData<IGetPatentTypes>>(
    defaultPaginatedData,
  );

  const patentKindCount: Array<{ kind: ExpertPatentKind; name: string; count: number }> = [
    {
      kind: ExpertPatentKind.INVENTION,
      name: '發明',
      count: expert.inventionCount,
    },
    {
      kind: ExpertPatentKind.UTILITY,
      name: '新型',
      count: expert.utilityCount,
    },
    {
      kind: ExpertPatentKind.DESIGN,
      name: '設計',
      count: expert.designCount,
    },
  ];

  const handleSwitchPatentKind = useCallback((newPatentKind: ExpertPatentKind) => {
    setPatentKind(prev => {
      if (prev !== newPatentKind) {
        setReqParams(prevReq => ({ ...prevReq, pageNo: 1 }));
      }
      return newPatentKind;
    });
  }, []);

  const handleNextPageLoad = () => {
    setReqParams(prev => ({
      ...prev,
      pageNo: prev.pageNo + 1,
    }));
  };

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    setIsError(false);

    try {
      const res = await Api().get(`expert/${expertId}/patents`, {
        params: {
          sortByDate: reqParams.sortByDate,
          dateRange: reqParams.dateRange,
          pageNo: reqParams.pageNo,
          patentKind: patentKind,
        },
      });

      if (res.data.pageNo > 1) {
        setResPatents(prev => ({
          ...prev,
          data: [...prev.data, ...res.data.data],
          pageNo: res.data.pageNo,
        }));
      } else {
        setResPatents(res.data);
      }
    } catch (error) {
      errorHandler(error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, [expertId, reqParams, patentKind]);

  useEffect(() => {
    expertId && fetchData();
  }, [expertId, fetchData]);

  return (
    <Container>
      <Header>
        <HeaderLeft>
          <span>相關專利</span>
          {patentKindCount.map(item => (
            <PatentKindTab
              key={item.kind}
              onClick={() => item.count > 0 && handleSwitchPatentKind(item.kind)}
              selected={item.kind === patentKind}
              clickAble={item.count > 0}
            >
              {item.name}&nbsp;({numFormatter(item.count)})
            </PatentKindTab>
          ))}
        </HeaderLeft>
        <HeaderRight>
          <FilterDropdown
            items={dateRangeItems}
            activeItem={selectedItem.dateRange}
            handleOnclick={item => {
              setReqParams(prev => ({
                ...prev,
                dateRange: item.value,
                pageNo: 1,
              }));
              setSelectedItem(prev => ({
                ...prev,
                dateRange: item,
              }));
            }}
            customStyleSvgSize={16}
          />
          <FilterDropdown
            items={sortTypeItems}
            activeItem={selectedItem.sortByDate}
            handleOnclick={item => {
              setReqParams(prev => ({
                ...prev,
                sortByDate: item.value,
                pageNo: 1,
              }));
              setSelectedItem(prev => ({
                ...prev,
                sortByDate: item,
              }));
            }}
            customStyleSvg={<FaSortAmountDown size={16} />}
          />
        </HeaderRight>
      </Header>
      <div>
        {isLoading && reqParams.pageNo === 1 ? (
          <SpinnerBox>
            <Spinner width="200px" margin="100px auto" />
          </SpinnerBox>
        ) : resPatents.data && resPatents.data.length > 0 ? (
          <InfiniteScroll
            isNextPageLoading={isLoading && reqParams.pageNo > 1}
            isNextPageError={isError && reqParams.pageNo > 1}
            errorMessage={'系統發生異常'}
            handleNextPageLoad={handleNextPageLoad}
            handleReload={fetchData}
            totalCount={resPatents.totalCount}
            currentPage={resPatents.pageNo}
            totalPages={resPatents.totalPages}
          >
            {resPatents.data.map(item => (
              <ResultListItem
                key={item.id}
                isPreviewMode={false}
                isPreviewing={false}
                patent={item}
                highlightWords={[expert.name]}
              />
            ))}
          </InfiniteScroll>
        ) : (
          <NoResult>
            <div>無相關專利文獻</div>
            <span>請嘗試變更篩選條件。</span>
          </NoResult>
        )}
      </div>
    </Container>
  );
};

export default ExpertPatent;
