import React from 'react';
import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components';
import { blue1, blue1rgba, white } from 'src/style/theme/Color';
import { zModal } from 'src/style/theme/Z-Index';

/** 三角形寬度 */
const triangleWidth = 6;

const topStyle = css`
  /* 上移自己高度的100%，左移自己寬度50% */
  transform: translateY(-100%) translateX(-50%);
  ::after {
    border-color: ${blue1} transparent transparent transparent;
    right: calc(50% - ${triangleWidth}px);
    top: 100%;
  }
`;

const rightStyle = css`
  /* 上移自己高度的50% */
  transform: translateY(-50%);
  ::after {
    border-color: transparent ${blue1} transparent transparent;
    top: calc(50% - ${triangleWidth}px);
    right: 100%;
  }
`;

const bottomStyle = css`
  /* 左移自己寬度50% */
  transform: translateX(-50%);
  ::after {
    border-color: transparent transparent ${blue1} transparent;
    right: calc(50% - ${triangleWidth}px);
    bottom: 100%;
  }
`;

const leftStyle = css`
  /* 上移自己高度的50%，左移自己寬度100% */
  transform: translateY(-50%) translateX(-100%);
  ::after {
    border-color: transparent transparent transparent ${blue1};
    top: calc(50% - ${triangleWidth}px);
    left: 100%;
  }
`;

interface IStyledContent {
  left: number;
  top: number;
  placement: string;
}

/**
 * Consider using the attrs method, together with a style object for frequently changed styles
 */
export const Content = styled.div.attrs<IStyledContent>(({ left, top }: IStyledContent) => ({
  style: {
    left: left,
    top: top,
  },
}))`
  /* 避免重複觸發 svg 之 mouseover、mouseout事件 */
  pointer-events: none;
  position: fixed;
  /* TODO: z-index 調整，tooltip應在Modal之上 */
  z-index: ${zModal.custom};

  max-width: 250px;
  padding: 8px 16px;
  border-radius: 4px;
  box-shadow: 0 5px 20px -5px ${blue1rgba(0.1)};
  background-color: ${blue1};
  color: ${white};
  word-break: break-all;

  ${props =>
    (props.placement === 'top' && topStyle) ||
    (props.placement === 'right' && rightStyle) ||
    (props.placement === 'bottom' && bottomStyle) ||
    (props.placement === 'left' && leftStyle)}

  ::after {
    position: absolute;
    border-style: solid;
    border-width: ${triangleWidth}px;
    content: '';
    height: 0px;
    width: 0px;
  }
`;

// 取得.html檔中要渲染內容的DOM物件，不存在的話自己建一個div(for typescript check)
const tooltip = document.getElementById('tooltip');
const portalDiv = tooltip ? tooltip : document.createElement('div');

interface IProps {
  overlay: React.ReactNode;
  left: number;
  top: number;
  placement?: string;
}

// TODO: 待整合 (和功效矩陣的tooltip除了底色和placement參數之外完全一樣)
const Tooltip: React.FC<IProps> = props => {
  return ReactDOM.createPortal(
    <Content left={props.left} top={props.top} placement={props.placement || 'top'}>
      {props.overlay}
    </Content>,
    portalDiv,
  );
};

export default Tooltip;
