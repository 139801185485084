import styled, { css } from 'styled-components';
import { FaAngleRight, FaAngleLeft } from 'react-icons/fa';
import {
  blue1,
  blue2,
  blue3,
  blue7,
  blue8,
  iceblue1,
  iceblue3,
  iceblue7,
  blue6rgba,
} from 'src/style/theme/Color';

// 專利趨勢總筆數樣式

export const Title = styled.div`
  font-size: 24px;
  color: ${iceblue1};
  font-weight: bold;
  margin-bottom: 8px;
`;
export const BlockTitle = styled.div`
  font-size: 20px;
  color: ${blue1};
  font-weight: bold;
  border-bottom: 1px solid #e5eff1;
  padding: 24px 0 8px 0;
`;
export const StatSummaryTextContainer = styled.div`
  width: 200px;
  padding: 48px 0 16px 0;
`;
export const StatSummaryGraphContainer = styled.div`
  padding: 16px 0;
`;
export const StatSummaryTextName = styled.p`
  color: ${blue2};
  font-weight: bold;
  font-size: 20px;
  margin: 4px 0 20px 0;
  word-wrap: break-word;
`;

// 專利趨勢內容樣式
interface FlexboxProps {
  justifyContent?: string;
  alignItems?: string;
  wrap?: string;
}
export const Flexbox = styled.div<FlexboxProps>`
  display: flex;
  flex-wrap: ${props => props.wrap || 'wrap'};
  min-width: 0;
  justify-content: ${props => props.justifyContent || 'flex-start'};
  align-items: ${props => props.alignItems || 'flex-start'};
`;

export const LineChartToggler = styled.div`
  font-size: 20px;
  color: ${blue2};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 64px;
`;

export const ArrowLeft = styled(FaAngleLeft)<{ disabled: boolean }>`
  margin-right: 16px;
  font-size: 24px;
  color: ${props => (props.disabled ? iceblue7 : iceblue3)};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
`;

export const ArrowRight = styled(FaAngleRight)<{ disabled: boolean }>`
  margin-left: 16px;
  font-size: 24px;
  color: ${props => (props.disabled ? iceblue7 : iceblue3)};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
`;

export const StatRightContainer = styled.div`
  padding: 16px 16px 16px 32px;
  height: 360px;
  width: 480px;
`;

export const LineChartLoader = styled.div`
  height: 52px;
  position: relative;
  margin-top: 80px;
  :before {
    content: '';
    position: absolute;
    left: calc(50% - 60px);
    margin: 4px auto;
    border: 5px solid ${iceblue7};
    border-radius: 50%;
    border-top: 5px solid ${iceblue3};
    width: 36px;
    height: 36px;
    animation: spin 1s linear infinite;
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
  :after {
    content: '資料分析中...';
    position: absolute;
    left: 50%;
    line-height: 52px;
    color: ${iceblue3};
  }
`;

export const StatLeftContainer = styled.div`
  padding: 16px 0;
  height: 320px;
  width: calc(100% - 480px);
  min-width: 320px;
  flex-grow: 1;
`;

export const GraphTitle = styled.div`
  margin-bottom: 12px;
  min-height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  > svg {
    margin-left: 8px;
    color: ${blue3};
    cursor: pointer;
  }
`;

export const RightGraphTitle = styled(GraphTitle)`
  margin-left: 64px;
  display: block;
  text-align: center;
`;

export const SummaryGraphTitle = styled(GraphTitle)`
  margin-bottom: 12px;
  min-height: 0;
`;

export const PartyRankItem = styled.div<{ active: boolean }>`
  width: 100%;
  padding: 10px 16px;
  margin: 4px 0;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  background-color: ${props => (props.active ? blue6rgba(0.6) : blue7)};
  cursor: pointer;
`;

export const PartyRankName = styled.div`
  color: ${iceblue1};
  min-width: 0;
  padding-right: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const PartyRankCount = styled.div`
  font-weight: bold;
  color: ${blue2};
  flex-shrink: 0;
`;

export const NoResult = styled.div`
  margin: 16px 32px;
  padding: 16px 32px;
  background-color: ${blue8};

  div {
    color: ${blue2};
    font-weight: 600;
    margin-bottom: 8px;
  }
  span {
    color: ${iceblue3};
  }
`;
export const ToolTipTitle = styled.div`
  font-weight: bold;
`;
export const ToolTipContent = styled.div`
  > p {
    margin-top: 8px;
  }
`;

export const customStyleSelectedItemStyle = css`
  width: 124px;
  color: ${blue2};
  > div {
    text-align: right;
    font-size: 20px;
  }
`;
