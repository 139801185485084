import styled from 'styled-components';
import { ultrawhite, iceblue1, blue6, blue6rgba } from 'src/style/theme/Color';

export const Title = styled.div`
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: 600;
  color: ${ultrawhite};
`;

export const Field = styled.div`
  margin-bottom: 24px;

  > span {
    display: block;
    margin-bottom: 4px;
  }
`;

/**
 * @description
 * Styled-components attrs` with TypeScript:
 *   styled(Extend).attrs({as: 'attr'}) -> Type Problem, can't access default props
 *   styled(Extend).attrs<{}>({as: 'attr'}) -> Working but missing Highlighting
 *   styled(Extend).attrs(() => ({as: 'attr'})) -> Arrow function is both working
 */
export const LabelField = styled(Field).attrs(() => ({ as: 'label' }))`
  display: block;

  input {
    width: 100%;
    border-radius: 4px;
    background-color: ${ultrawhite};
    color: ${iceblue1};
    padding: 5px 12px;
    border: none;
  }
`;

export const QueryString = styled.div`
  color: ${blue6};
  min-height: 23px;
  max-height: 45px;
  overflow-x: hidden;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    box-shadow: inset 0 0 8px 8px ${blue6rgba(0.5)};
    border: solid 2px transparent;
  }
  scrollbar-width: thin;
`;

export const ButtonArea = styled.div`
  margin-bottom: 24px;
  text-align: center;
`;

export const Description = styled.div`
  margin-bottom: 8px;
  opacity: 0.8;
`;
