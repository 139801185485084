import { IIndustrialClassType } from 'src/types/IndustryTypes';
import store from 'src/redux/store';

/** 從行業 id 列表轉換成行業 Item 列表 */
export function toDropdownItem(ids: Array<string> | undefined) {
  let result: Array<IIndustrialClassType> = [];
  let flatList: Array<IIndustrialClassType> = [];
  const flatten = (d: Array<IIndustrialClassType>) => {
    d.forEach(item => {
      flatList.push(item);
      item.children && flatten(item.children);
    });
  };
  flatten(getIndustrialClassList());
  ids &&
    ids.forEach(id => {
      const found = flatList.find(item => item.id === parseInt(id));
      found && result.push(found);
    });
  return result;
}

/** 取得行業列表 */
export function getIndustrialClassList(): Array<IIndustrialClassType> {
  return store.getState().industrialClassReducer.industrialClassList;
}
