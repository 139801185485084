export const numFormatter = (num: number | string | undefined, showDecimal?: boolean) => {
  if (typeof num === 'undefined') {
    return '';
  } else if (typeof num === 'number') {
    const remainder = showDecimal ? (num % 1).toFixed(2).slice(1) : '';
    return num.toFixed(0).replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') + remainder;
  } else {
    return num.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
  }
};

export const dateFormatter = (date: number | string | undefined): string => {
  if (date) {
    const dateStr = date + '';
    if (dateStr.length > 6) {
      return (
        dateStr.substring(0, 4) + '-' + dateStr.substring(4, 6) + '-' + dateStr.substring(6, 8)
      );
    }
    return dateStr.substring(0, 4) + '-' + dateStr.substring(4, 6);
  } else {
    return '無日期資訊';
  }
};
// dd-mm-yyyy -> yyyy
export const yearFormatter = (date: number | string | undefined): string => {
  if (date) {
    const dateStr = date + '';
    return dateStr.substring(0, 4);
  } else {
    return '無日期資訊';
  }
};

export const millisFormatter = (
  timestamp: number | undefined,
  toMinute?: boolean,
  toSecond?: boolean,
) => {
  let result = '';
  if (timestamp && typeof timestamp === 'number') {
    const date = new Date(timestamp);
    result = formatDateTime(date, toMinute, toSecond);
  }
  return result;
};

export const formatDateTime = (date: Date, toMinute?: boolean, toSecond?: boolean) => {
  let result =
    date.getFullYear() +
    '-' +
    padZeroToTime(date.getMonth() + 1) +
    '-' +
    padZeroToTime(date.getDate());
  if (toMinute) {
    result += ' ' + padZeroToTime(date.getHours()) + ':' + padZeroToTime(date.getMinutes());
    if (toSecond) {
      result += ':' + padZeroToTime(date.getSeconds());
    }
  }
  return result;
};

const padZeroToTime = (date: number | string) => {
  const number = date || 0;
  if (typeof number === 'number') {
    return number < 10 ? `0${number}` : `${number}`;
  } else {
    return number.length < 2 ? `0${number}` : `${number}`;
  }
};
