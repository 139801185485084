import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { Link, RouteComponentProps } from 'react-router-dom';

import MiniLoader from 'src/components/ui/interactive/MiniLoader';

import { Api, baseURL } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import { numFormatter, dateFormatter } from 'src/utils/Formatter';
import { IExpertBrief, IExpertLatestPatent } from 'src/types/ExpertTypes';
import {
  blue1,
  blue2,
  blue8,
  iceblue1,
  iceblue5,
  iceblue8,
  pink7,
  white,
} from 'src/style/theme/Color';
import EXPERT_AVATAR from 'src/assets/images/expert-avatar.png';
import ResultHighlighter from '../result/ResultHighlighter';
import { IBasicRequest } from 'src/types/PatentSearchTypes';
import { composeBasicRequest } from 'src/redux/actions/patentSearchAction';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 32px;
  border-bottom: 1px solid ${iceblue8};
  color: ${iceblue1};

  :hover {
    background-color: ${blue8};
  }
`;

const Avatar = styled.div`
  width: 10.5%;
  margin-right: 32px;
  align-self: flex-start; /* Make itself align to top of parent */

  /* CSS Trick for Fit the image inside the square. */
  position: relative;

  :after {
    content: '';
    display: block;
    padding-bottom: 100%; /* The padding depends on the width, not on the height, so with a padding-bottom of 100% you will get a square */
  }

  img {
    position: absolute; /* Take your picture out of the flow */
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; /* Make the picture taking the size of it's parent */
    width: 100%; /* This if for the object-fit */
    height: 100%; /* This if for the object-fit */
    object-fit: cover; /* Equivalent of the background-size: cover; of a background-image */
    object-position: center;
    border-radius: 50%;
  }
`;

const BriefInfo = styled.div`
  flex: 1;
  min-width: 0;
`;

const Person = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

const Name = styled.div`
  font-weight: 600;
  color: ${blue2};
  margin-right: 16px;
`;

const Research = styled.div`
  /* display: flex; */
  align-items: center;
  margin-bottom: 4px;
`;

const Field = styled.div`
  display: flex;
  * {
    line-height: 2;
  }
`;
const FieldTitle = styled.div`
  color: ${iceblue5};
  margin-right: 20px;
  white-space: nowrap;
`;

const EllipsisCss = css<{ maxLine?: number }>`
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${props => (props.maxLine ? props.maxLine : 1)};
`;

const EllipsisText = styled.span`
  ${EllipsisCss};
`;

const LatestPatent = styled.div`
  display: flex;
  > a {
    flex: 1;
  }
`;
const RelatedPatents = styled.div`
  padding: 12px 16px;
  background-color: ${white};
  border: solid 1px ${iceblue8};
  border-radius: 4px;
  > a {
    flex: 1;
  }
  * {
    line-height: normal;
  }
  > ${FieldTitle} {
    color: ${iceblue1};
  }
`;

const RelatedPatent = styled.div`
  padding: 8px 0;
`;

const highlightStyle = {
  color: 'inherit',
  backgroundColor: pink7,
  borderRadius: '4px',
};

const PatentTitle = styled.div`
  font-weight: 500;
  color: ${blue2};
  ${EllipsisCss};
`;

const Info = styled.div`
  color: ${iceblue5};
  padding-right: 32px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  > ${Info} {
    flex-basis: 50%;
  }
`;

const PatentQueryLink = styled.div`
  line-height: 2;
  color: ${blue1};
  cursor: pointer;
`;

interface IReduxProps extends RouteComponentProps {
  composeBasicRequest: (basicRequest: IBasicRequest) => void;
}

interface IProps extends IReduxProps {
  expert: IExpertBrief;
  keyword: string;
  searchWords: string[];
}

/** 專家履歷 - 單項資料 */
const ExpertResultItem: React.FC<IProps> = props => {
  const { expert, keyword, searchWords } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [latestPatent, setLatestPatent] = useState<IExpertLatestPatent>();
  const toGetLatest = !expert.relatedPatents;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const res = await Api().get(`expert/${expert.latestPatentId}/patent`);
        setLatestPatent(res.data);
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsLoading(false);
      }
    };

    toGetLatest && expert.latestPatentId && fetchData();
  }, [expert.id, expert.latestPatentId, toGetLatest]);

  const handlePatentQueryByExpert = () => {
    props.composeBasicRequest({
      queryString: keyword,
      expert: { id: expert.id, name: expert.name },
    });
    props.history.push('/result');
  };

  return (
    <Container>
      <Avatar>
        <div>
          <img
            src={
              expert.photo && expert.photo !== ''
                ? `${baseURL}expert/${expert.id}/photo`
                : EXPERT_AVATAR
            }
            alt={expert.name}
          />
        </div>
      </Avatar>
      <BriefInfo>
        <Person>
          <Link to={`/expert/${expert.id}`} target="_blank">
            <Name>{expert.name}</Name>
          </Link>
          <span>
            {expert.jobUnit}&nbsp;{expert.jobDepartment}&nbsp;{expert.jobTitle}
          </span>
        </Person>
        <Research>
          <Field>
            <FieldTitle>專長項目</FieldTitle>
            <EllipsisText maxLine={2}>
              <span>{expert.expertise}</span>
            </EllipsisText>
          </Field>
          <Field>
            <FieldTitle>專利件數</FieldTitle>
            <span>
              總數&nbsp;{numFormatter(expert.patentCount)}&nbsp;－&nbsp; 發明專利&nbsp;
              {numFormatter(expert.inventionPatentCount)}&nbsp;件、新型專利&nbsp;
              {numFormatter(expert.utilityPatentCount)}&nbsp;件、設計專利&nbsp;
              {numFormatter(expert.designPatentCount)}&nbsp;件
            </span>
          </Field>
        </Research>
        {toGetLatest ? (
          <LatestPatent>
            <FieldTitle>最新專利</FieldTitle>
            {isLoading ? (
              <MiniLoader diameter="15px" borderWidth="2px" margin="auto 0" />
            ) : (
              latestPatent && (
                <Link to={`/patent/${latestPatent.id}/view`} target="_blank">
                  <PatentTitle>
                    {latestPatent.bibliographic &&
                    latestPatent.bibliographic.inventionTitle &&
                    latestPatent.bibliographic.inventionTitle.title
                      ? latestPatent.bibliographic.inventionTitle.title.join(' | ')
                      : ''}
                  </PatentTitle>
                </Link>
              )
            )}
          </LatestPatent>
        ) : (
          <RelatedPatents>
            <FieldTitle>相關專利</FieldTitle>
            {expert.relatedPatents.data.map(item => (
              <RelatedPatent key={item.id}>
                <Link to={`/patent/${item.id}/view`} target="_blank">
                  <PatentTitle>
                    <ResultHighlighter
                      highlightStyle={highlightStyle}
                      searchWords={searchWords}
                      textToHighlight={
                        item.bibliographic &&
                        item.bibliographic.inventionTitle &&
                        item.bibliographic.inventionTitle.title
                          ? item.bibliographic.inventionTitle.title.join(' | ')
                          : ''
                      }
                    />
                  </PatentTitle>
                </Link>
                <EllipsisText maxLine={2}>
                  <ResultHighlighter
                    highlightStyle={highlightStyle}
                    searchWords={searchWords}
                    textToHighlight={
                      item.inventionSpecification &&
                      item.inventionSpecification.abstract &&
                      item.inventionSpecification.abstract.text
                        ? item.inventionSpecification.abstract.text.join(' ')
                        : ''
                    }
                  />
                </EllipsisText>
                <InfoContainer>
                  <Info>
                    <EllipsisText>
                      發明人：
                      <ResultHighlighter
                        highlightStyle={highlightStyle}
                        searchWords={searchWords}
                        textToHighlight={
                          item.bibliographic &&
                          item.bibliographic.parties &&
                          item.bibliographic.parties.inventor &&
                          item.bibliographic.parties.inventor.addressbook &&
                          item.bibliographic.parties.inventor.addressbook.name
                            ? item.bibliographic.parties.inventor.addressbook.name.join('、')
                            : ''
                        }
                      />
                    </EllipsisText>
                  </Info>
                  <Info>
                    <EllipsisText>
                      公開日：
                      <ResultHighlighter
                        highlightStyle={highlightStyle}
                        searchWords={searchWords}
                        textToHighlight={dateFormatter(
                          item.bibliographic &&
                            item.bibliographic.publicationReference &&
                            item.bibliographic.publicationReference.documentId &&
                            item.bibliographic.publicationReference.documentId.date,
                        )}
                      />
                    </EllipsisText>
                  </Info>
                  <Info>
                    <EllipsisText>
                      申請人：
                      <ResultHighlighter
                        highlightStyle={highlightStyle}
                        searchWords={searchWords}
                        textToHighlight={
                          item.bibliographic &&
                          item.bibliographic.parties &&
                          item.bibliographic.parties.applicant &&
                          item.bibliographic.parties.applicant.addressbook &&
                          item.bibliographic.parties.applicant.addressbook.name
                            ? item.bibliographic.parties.applicant.addressbook.name.join('、')
                            : ''
                        }
                      />
                    </EllipsisText>
                  </Info>
                  <Info>
                    <EllipsisText>
                      專利局：
                      <ResultHighlighter
                        highlightStyle={highlightStyle}
                        searchWords={searchWords}
                        textToHighlight={item.country ? item.country : ''}
                      />
                    </EllipsisText>
                  </Info>
                </InfoContainer>
              </RelatedPatent>
            ))}
            {expert.relatedPatents.totalCount > 3 && (
              <PatentQueryLink onClick={handlePatentQueryByExpert}>
                與其他&nbsp;{expert.relatedPatents.totalCount - 3}&nbsp;則相關專利
              </PatentQueryLink>
            )}
          </RelatedPatents>
        )}
      </BriefInfo>
    </Container>
  );
};

const mapDispatchToProps = {
  composeBasicRequest,
};

export default connect(
  null,
  mapDispatchToProps,
)(ExpertResultItem);
