import styled from 'styled-components';
import { blue1, iceblue6, ultrablackrgba } from 'src/style/theme/Color';

interface IBackDrop {
  zIndex: number;
  alignItems?: string;
  justifyContent?: string;
}

export const Backdrop = styled.div<IBackDrop>`
  position: fixed;
  z-index: ${props => props.zIndex};
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${ultrablackrgba(0.5)};
  display: flex;
  align-items: ${props => props.alignItems || 'flex-start'};
  justify-content: ${props => props.justifyContent || 'center'};

  @media print {
    height: auto;
    position: absolute;
    background-color: #ffffff;
  }
`;

export const Container = styled.div`
  width: 465px;
  margin: 216px 0;
  padding: 32px;
  border-radius: 4px;
  box-shadow: 0 0 50px 0 ${ultrablackrgba(0.1)};
  background-color: #ffffff;
`;

export const Body = styled.div<{ hasDescription?: boolean }>`
  text-align: ${props => (props.hasDescription ? 'left' : 'center')};
  margin-bottom: 32px;
`;

export const Message = styled.div`
  color: ${blue1};
  font-weight: 500;
  margin-bottom: 16px;
`;

export const Description = styled.div`
  color: ${iceblue6};
  margin-bottom: 16px;
`;

export const Footer = styled.div`
  text-align: center;
`;
