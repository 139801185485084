import React, { useState } from 'react';
import styled from 'styled-components';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';

import { blue2rgba, iceblue3, iceblue8 } from 'src/style/theme/Color';

const CarouselImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const Mask = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #ffffff;
  :hover {
    background-color: ${blue2rgba(0.7)};
  }
`;

const HalfCircleButtonBase = styled.div`
  position: absolute;
  width: 37px;
  height: 75px;
  background-color: ${iceblue8};
  display: flex;
  align-items: center;
  :hover {
    cursor: pointer;
  }

  svg {
    font-size: 28px;
    color: ${iceblue3};
  }
`;

const Prev = styled(HalfCircleButtonBase)`
  left: 0;
  border-bottom-right-radius: 75px;
  border-top-right-radius: 75px;
  justify-content: flex-start;
`;

const Next = styled(HalfCircleButtonBase)`
  right: 0;
  border-bottom-left-radius: 75px;
  border-top-left-radius: 75px;
  justify-content: flex-end;
`;

interface IProps {
  imagePaths: Array<string>;
  isOverlayDetailShow?: boolean;
}

/** 基本 輪播內容 */
const BasicCarouselContent: React.FC<IProps> = ({ imagePaths, isOverlayDetailShow }) => {
  const [activeImgIdx, setActiveImgIdx] = useState<number>(0);

  const handleToNextClick = (e: any) => {
    e.stopPropagation();
    if (activeImgIdx < imagePaths.length - 1) {
      setActiveImgIdx(activeImgIdx + 1);
    }
  };

  const handleToPrevClick = (e: any) => {
    e.stopPropagation();
    if (activeImgIdx > 0) {
      setActiveImgIdx(activeImgIdx - 1);
    }
  };

  return (
    <>
      {isOverlayDetailShow ? (
        <CarouselImage>
          <img src={`${imagePaths[activeImgIdx]}`} alt="" />
        </CarouselImage>
      ) : (
        <>
          {activeImgIdx > 0 && (
            <Prev onClick={e => handleToPrevClick(e)}>
              <FaAngleLeft />
            </Prev>
          )}

          <CarouselImage>
            <Mask />
            <img src={`${imagePaths[activeImgIdx]}`} alt="" />
          </CarouselImage>
          {activeImgIdx < imagePaths.length - 1 && (
            <Next onClick={e => handleToNextClick(e)}>
              <FaAngleRight />
            </Next>
          )}
        </>
      )}
    </>
  );
};

export default BasicCarouselContent;
