import React, { useState, useEffect, useRef } from 'react';
import { FormikProps } from 'formik';
import { Row, StyledTextareaField, TermTag } from 'src/style/PatentSearchStyle';
import { IAdvancedSearch, IFormSearch } from 'src/types/form/SearchFormTypes';

interface IProps {
  formikBag: FormikProps<IAdvancedSearch | IFormSearch>;
  ipcCodeList?: string[];
  handleIpcCodeList?: (code?: string[]) => void;
}

/**
 * 條件按鈕組合查詢字串
 * @description 對應 Formik 欄位名稱: "queryString"
 */
const QueryCombination: React.FC<IProps> = props => {
  const [cursorPosition, setCursorPosition] = useState<number>(0);

  const textareaInput = useRef<HTMLTextAreaElement>();

  const { formikBag } = props;
  //當ipcCodeList的值變動時(IPC列表勾選確認)，在formikBag中將ipcCodeList陣列轉為string，
  //並將其用大括號框住，並對其元素添加 IC=。
  useEffect(() => {
    props.ipcCodeList &&
      formikBag.setFieldValue('queryString', ' (IC=' + props.ipcCodeList.join(' OR IC=') + ')');
  }, [props.ipcCodeList, formikBag]);

  useEffect(() => {
    // 當setState(包含Formik的setValues、setFieldValue) 執行完後才移動游標
    const current = textareaInput.current;
    if (current) {
      current.focus();
      current.setSelectionRange(cursorPosition, cursorPosition);
    }
  }, [cursorPosition]);

  const handleConditionClick = (e: any) => {
    const queryString = formikBag.values.queryString;
    const condition = e.target.textContent;
    const current = textareaInput.current;

    let newCursorPosition = current ? current.selectionStart : 0;

    if (condition === 'Clear') {
      formikBag.setFieldValue('queryString', '');
      newCursorPosition = 0;
    } else {
      const prev = queryString.substring(0, newCursorPosition);
      const post = queryString.substring(newCursorPosition, queryString.length);

      formikBag.setFieldValue('queryString', prev + ' ' + condition + ' ' + post);

      if (condition === '[]' || condition === '()') {
        newCursorPosition += 2;
      } else {
        newCursorPosition += condition.length + 2;
      }
    }

    setCursorPosition(newCursorPosition);
  };

  const conditionButtons = ['Clear', 'AND', 'OR', 'NOT', '()', '[]', '=', '?', '*'];

  return (
    <>
      <Row>
        <StyledTextareaField
          name="queryString"
          component="textarea"
          innerRef={textareaInput}
          placeholder="輸入檢索式"
        />
      </Row>
      <Row justifyContent="flex-start">
        {conditionButtons.map(item => (
          <TermTag key={item} isButton marginRight="8px" onClick={e => handleConditionClick(e)}>
            {item}
          </TermTag>
        ))}
      </Row>
    </>
  );
};

export default QueryCombination;
