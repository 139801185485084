import styled from 'styled-components';
import { blue4, iceblue1, iceblue3, iceblue7, iceblue8 } from 'src/style/theme/Color';

// 第一層標題寬度
const subjectWidth = 120;

export const Subject = styled.div<{ width?: string; inactive?: boolean }>`
  width: ${props => props.width || 'auto'};
  color: ${iceblue1} ${props => props.inactive && '!important'}; // 防止 inactive 時 hover 改變字體顏色
  font-weight: 600;
`;
export const Description = styled.div`
  color: ${iceblue3};
  flex-grow: 1;
`;
export const Inform = styled.div`
  flex-grow: 0;
  padding: 0 16px;
`;
export const Container = styled.div`
  height: 52px;
  padding: 0 32px;
  border-bottom: 1px solid ${iceblue8};
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${Subject} {
    width: ${subjectWidth + 'px'};
  }
`;
export const SubContainer = styled.div`
  padding: 0 32px;
  border-bottom: 1px solid ${iceblue8};
`;
export const SubItem = styled.div<{ child?: boolean }>`
  ${props =>
    props.child &&
    `padding-left:12px;
   border-left: 1px solid ${iceblue7}`};
  margin-left: ${props => (props.child ? subjectWidth + 10 : subjectWidth) + 'px'};
  line-height: 2.5em;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${Subject} {
    font-weight: normal;
  }
  :hover {
    ${Subject} {
      color: ${blue4};
    }
  }
`;
