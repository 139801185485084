import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { IndexService } from 'src/api/indexApi';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import { IGetFilmList } from 'src/types/api/IndexApiTypes';

import { FilmCarousel } from 'src/components/index/FilmCarousel';
import { Button } from 'src/style/theme/Common';
import { blue2, blue3, blue8, iceblue1 } from 'src/style/theme/Color';
import { ReactComponent as ARROW } from 'src/assets/images/arrow-more.svg';

const api = IndexService();

const Container = styled.div`
  margin: 50px 0;
  padding: 0 50px;
`;
const Anchor = styled.div`
  margin: auto;
  width: 90%;
  height: 100%;
  max-width: 1440px;
`;
const FlexDiv = styled.div`
  display: flex;
`;
const Text = styled.div``;
const AreaTitle = styled.div`
  padding: 8px 0;
  color: #003f54;
  font-size: 36px;
  font-weight: bold;
`;
const AreaDescription = styled.div`
  margin: 0 0 24px 0;
  width: 216px;
  color: ${iceblue1};
`;
const ErrorMsg = styled.div`
  margin: 16px 32px;
  padding: 16px 32px;
  background-color: ${blue8};
  text-align: center;

  div {
    padding: 16px 0;
  }
  a {
    display: block;
  }
`;
const More = styled.div`
  padding: 0 0 16px 0;
  color: ${blue2};
  white-space: nowrap;
  a {
    color: ${blue2};
  }
  svg {
    margin: 0 0 0 16px;
    vertical-align: middle;
    line {
      stroke: ${blue2};
    }
  }
  :hover {
    a {
      color: ${blue3};
    }
    svg line {
      stroke: ${blue3};
    }
  }
`;

function Video() {
  const [films, setFilms] = useState<IGetFilmList>();
  const [hasError, setHasError] = useState(false);
  useEffect(() => {
    const fetchFilms = async () => {
      try {
        const data = await api.getFilmList();
        setFilms(data);
        setHasError(false);
      } catch (error) {
        errorHandler(error);
        setHasError(true);
      }
    };

    !films && fetchFilms();
  }, [films]);

  return (
    <Container>
      <Anchor>
        <FlexDiv>
          <Text>
            <AreaTitle>影片專區</AreaTitle>
            <AreaDescription>
              從不同單位中集合了智慧財產相關的解說影片，幫助民眾以輕鬆的角度接觸專利。
            </AreaDescription>
            <More>
              <Link to={'/article/video'} onClick={() => window.scrollTo(0, 0)}>
                更多影片
                <ARROW />
              </Link>
            </More>
          </Text>
          {hasError ? (
            <ErrorMsg>
              <div>目前影片服務似乎出了點小問題，如有需要，請聯絡系統管理員。</div>
              <a href="mailto:tipoeservice@tipo.gov.tw">
                <Button type="button" template="primary">
                  聯絡系統管理員
                </Button>
              </a>
            </ErrorMsg>
          ) : (
            films && <FilmCarousel filmData={films} />
          )}
        </FlexDiv>
      </Anchor>
    </Container>
  );
}

export default Video;
