import React from 'react';
import ReactDOM from 'react-dom';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { ReduxAppState } from 'src/redux/reducers';
import { closeLoginModal, openLoginFormModal } from 'src/redux/actions/modalAction';
import { Button } from 'src/style/theme/Common';
import { Backdrop, Container, Body, Message, Footer } from 'src/style/ModalStyle';
import { LoginModalType } from 'src/types/ModalTypes';

import { zModal } from 'src/style/theme/Z-Index';

interface IReduxMappingProps {
  isOpen: boolean;
  isFromRoute: boolean;
  closeLoginModal: () => void;
  openLoginFormModal: () => void;
}

interface IProps extends RouteComponentProps, IReduxMappingProps {}

const modal = document.getElementById('modal-login');
const portalDiv = modal ? modal : document.createElement('div');

const LoginAlertModal: React.FC<IProps> = props => {
  const isOpen = props.isOpen;

  const handleCancel = () => {
    props.closeLoginModal();
    if (props.isFromRoute) {
      props.history.length > 2 ? props.history.goBack() : props.history.push('/');
    }
  };

  return ReactDOM.createPortal(
    isOpen && (
      <Backdrop zIndex={zModal.login}>
        <Container>
          <Body>
            <Message>請先登入會員即可使用此功能</Message>
          </Body>
          <Footer>
            <Button
              template="primary"
              onClick={() => {
                props.openLoginFormModal();
              }}
            >
              登入
            </Button>
            <Button onClick={handleCancel}>取消</Button>
          </Footer>
        </Container>
      </Backdrop>
    ),
    portalDiv,
  );
};

const mapStateToProps = (state: ReduxAppState) => {
  return {
    isOpen: state.modalReducer.loginModal.type === LoginModalType.ALERT,
    isFromRoute: state.modalReducer.loginModal.isFromRoute,
  };
};

const mapDispatchToProps = {
  closeLoginModal,
  openLoginFormModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(LoginAlertModal));
