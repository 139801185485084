const getUserAgent = () => {
  const inBrowser = typeof window !== 'undefined';
  return inBrowser && window.navigator.userAgent.toLowerCase();
};
const UA = getUserAgent();

export const isIE = UA && /msie|trident/.test(UA);
export const isIE9 = UA && UA.indexOf('msie 9.0') > 0;
export const isEdge = UA && UA.indexOf('edge/') > 0;
export const isAndroid = UA && UA.indexOf('android') > 0;
export const isIOS = UA && /iphone|ipad|ipod|ios/.test(UA);
export const isChrome = UA && /chrome\/\d+/.test(UA) && !isEdge;
export const isPhantomJS = UA && /phantomjs/.test(UA);
export const isFF = UA && UA.match(/firefox\/(\d+)/);
export const isMobile = !!(isIOS || isAndroid);

export default getUserAgent;
