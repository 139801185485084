import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import { numFormatter, dateFormatter } from 'src/utils/Formatter';
import { getCountryName } from 'src/utils/PatentUtils';
import { formatYearMonth } from 'src/components/trend/TrendsHelper';
import {
  IIndustrialTrendApiParamType,
  IIndustrialTrendCardType,
  INameCountType,
  ILineChartType,
  ILineChartDataType,
} from 'src/types/IndustryTypes';
import StaticLineChart from 'src/components/trend/industrialTrends/chart/StaticLineChart';
import { Dropdown, IDropdownItem } from 'src/components/common/Dropdown';
import { Button } from 'src/style/theme/Common';
import { Spinner } from 'src/components/ui/interactive/Spinner';
import EditTrends from 'src/components/ui/interactive/dropdown/EditTrends';

import BG_IMG from 'src/assets/images/stat-bg.svg';
import { ReactComponent as ARROW } from 'src/assets/images/arrow-more.svg';
import { blue2, blue3, iceblue1, ultrawhite } from 'src/style/theme/Color';
import { Flexbox } from 'src/style/IndustrialTrendStyle';

const Container = styled.div`
  margin: 40px 0;
  padding: 24px 50px 32px;
  background-image: url(${BG_IMG});
  background-size: cover;
`;
const Anchor = styled.div`
  margin: auto;
  width: 90%;
  height: 100%;
  max-width: 1440px;
`;
const AreaTitle = styled.p`
  color: #003f54;
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 8px;
`;
const AreaDescription = styled.div`
  max-width: 768px;
  color: ${iceblue1};
`;
const AreaHeader = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 16px 0 12px 0;
  p {
    color: #003f54;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 2px;
  }
`;
const AreaBody = styled.div`
  display: flex;
  /* justify-content: space-between; */
  min-height: 278px;
`;
const Card = styled.div`
  width: 220px;
  height: 278px;
  padding: 20px;
  margin-right: 20px;
  border-radius: 4px;
  box-shadow: 0 12px 32px -16px rgba(66, 77, 82, 0.3);
  background-color: #ffffff;
  text-align: center;
  z-index: 0;
  > svg {
    margin: 8px 0;
  }
  button {
    display: none;
  }
  :hover {
    button {
      display: inline-block;
    }
  }
`;
const CardName = styled.div`
  margin: 0 0 16px 0;
  height: 56px;
  font-size: 20px;
  color: #263237;
  font-weight: bold;
  padding: 0 1rem 0 0;
  line-height: 2rem;
  overflow: hidden;
  position: relative;
  word-break: break-all;
  :before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
  }
  :after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    width: 1.2rem;
    height: 1rem;
    margin-top: 0.2rem;
    background: ${ultrawhite};
  }
`;
const CardTitle = styled.div`
  color: #5f686c;
  opacity: 0.5;
  text-align: center;
`;
const CardCompare = styled.div<{ count: number }>`
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  ${props => {
    const num = numFormatter(Math.abs(props.count));
    if (props.count > 0) {
      return css`
        ::after {
          content: '${num + '▴'}';
          color: #00d055;
        }
      `;
    } else if (props.count === 0) {
      return css`
        ::after {
          content: '${num + '‐'}';
          color: #424d52;
        }
      `;
    } else if (props.count < 0) {
      return css`
        ::after {
          content: '${num + '▾'}';
          color: #e7154d;
        }
      `;
    }
  }}
`;
const More = styled.div`
  width: 100%;
  text-align: center;
  white-space: nowrap;
  margin: 32px 0 8px 0;
  a {
    color: ${blue2};
  }
  svg {
    margin: 0 0 0 16px;
    vertical-align: middle;
    line {
      stroke: ${blue2};
    }
  }
  :hover {
    a {
      color: ${blue3};
    }
    svg line {
      stroke: ${blue3};
    }
  }
`;

const customDropdownSelectedItem = css`
  color: ${blue2};
  div {
    font-size: 18px;
    font-weight: bold;
  }
`;

const customDropdownItemBlock = css`
  right: -20px;
  left: 0;
`;

const countryDropdownList: Array<IDropdownItem> = [
  { name: getCountryName('TW'), value: 'TW' },
  { name: getCountryName('US'), value: 'US' },
  { name: getCountryName('EP'), value: 'EP' },
  { name: getCountryName('JP'), value: 'JP' },
  { name: getCountryName('CN'), value: 'CN' },
  { name: getCountryName('KR'), value: 'KR' },
];

const StatisticPanel: React.FC = () => {
  const [cardLoading, setCardLoading] = useState(true);
  const [activeCountry, setActiveCountry] = useState<IDropdownItem>(countryDropdownList[0]);
  const [apiParams, setApiParams] = useState<IIndustrialTrendApiParamType>({
    industrialClassIds: [],
    kind: 'B', //首頁只放發明公告
  });
  const [apiData, setApiData] = useState<Array<IIndustrialTrendCardType>>([]);
  const [updateTime, setUpdateTime] = useState<string>();
  const [latestTime, setLatestTime] = useState<string>();
  const [latestPublicationTime, setLatestPublicationTime] = useState<string>();

  // 資料更新時間
  useEffect(() => {
    const fetchData = async () => {
      try {
        const updateTimeRes = await Api().get('trend/industry/updateTime');
        const latestTimeRes = await Api().get(
          'trend/industry/lastTwoYear/latestTime/' + activeCountry.value,
        );
        updateTimeRes.data && setUpdateTime(updateTimeRes.data);
        latestTimeRes.data && setLatestTime(latestTimeRes.data.latestTime);
        latestTimeRes.data && setLatestPublicationTime(latestTimeRes.data.latestPublicationTime);
      } catch (error) {
        errorHandler(error);
      }
    };
    fetchData();
  }, [activeCountry]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await Api().post('trend/industry/lastTwoYear', {
          industrialClassIds: apiParams.industrialClassIds,
          country: activeCountry.value,
        });
        if (res.data) {
          setApiData(res.data.slice(0, 5));
          setCardLoading(false);
        }
      } catch (error) {
        errorHandler(error);
      }
    };
    setCardLoading(true);
    fetchData();
  }, [apiParams, activeCountry]);

  useEffect(() => {
    // 若使用者未勾選任何行業, 則將勾選項目恢復成前次查詢的狀態
    if (apiData.length !== 0 && apiParams.industrialClassIds.length === 0) {
      const ids = apiData.map(item => parseInt(item.industrialClassId));
      setApiParams({ ...apiParams, industrialClassIds: ids });
    }
  }, [apiParams, apiData]);

  const getLineChartData = (origData: IIndustrialTrendCardType) => {
    const convert = (d: Array<INameCountType>, isLatestYear: boolean) => {
      const latestMonth = latestTime ? parseInt(latestTime.substring(4)) : 0;
      let r: Array<ILineChartDataType> = [];
      for (let i = 1; i <= 12; i++) {
        if (!isLatestYear || i <= latestMonth) {
          const month = i < 10 ? '0' + i : i.toString();
          const find = d.find(item => item.name.substring(4) === month);
          r.push({ time: i.toString(), count: find ? find.count : 0 });
        }
      }
      return r;
    };
    let result: Array<ILineChartType> = [];
    result.push({ name: '', data: convert(origData.lastYear, false) });
    result.push({ name: '', data: convert(origData.thisYear, true) });
    return result;
  };

  return (
    <Container>
      <Anchor>
        <AreaTitle>行業別趨勢統計報表</AreaTitle>
        <AreaDescription>
          本報表為{activeCountry.name}發明專利公告 2 年間{' '}
          {latestTime &&
            ' ( ' +
              dateFormatter(parseInt(latestTime.substring(0, 4)) - 1 + '01') +
              ' ~ ' +
              dateFormatter(latestTime) +
              ' ) '}
          之發證趨勢。
          <br />
          {activeCountry.name}最新資料為{' ' + dateFormatter(latestPublicationTime) + ' '}
          公告專利，統計數據更新時間為
          {updateTime && ' ' + dateFormatter(updateTime)}。
        </AreaDescription>
        <AreaHeader>
          <Dropdown
            items={countryDropdownList}
            activeItem={activeCountry}
            handleOnclick={item => {
              setActiveCountry(item);
            }}
            customStyleSelectedItem={customDropdownSelectedItem}
            customStyleItemBlock={customDropdownItemBlock}
          />
          <Flexbox>
            <EditTrends
              industrialClassIds={apiParams.industrialClassIds}
              onSubmit={(ids: Array<number>) =>
                setApiParams({ ...apiParams, industrialClassIds: ids })
              }
            />
          </Flexbox>
        </AreaHeader>
        <AreaBody>
          {!cardLoading && apiData.length !== 0 ? (
            apiData.map(card => {
              const latest = latestTime
                ? card.thisYear.find(item => item.name === latestTime)
                : undefined;
              const latestCompare = latestTime
                ? card.lastYear.find(item => item.name.substring(4) === latestTime.substring(4))
                : undefined;
              const lastCount = latest ? latest.count : 0;
              const latestCompareCount = latestCompare ? latestCompare.count : 0;
              return (
                <Card key={card.industrialClassId}>
                  <CardName>{card.industrialClassDescription}</CardName>
                  <CardTitle>{latestTime && formatYearMonth(latestTime)}與去年同期比較</CardTitle>
                  <CardCompare count={lastCount - latestCompareCount} />
                  <CardTitle>年度趨勢</CardTitle>
                  <StaticLineChart dataset={getLineChartData(card)} />
                  <Link to={'/showcase/industrialTrends/' + card.industrialClassId}>
                    <Button
                      type="submit"
                      template="primary"
                      margin="0"
                      radius="4px"
                      fontSize={15}
                      width={112}
                    >
                      查看趨勢
                    </Button>
                  </Link>
                </Card>
              );
            })
          ) : (
            <Spinner width="180px" margin="40px auto" />
          )}
        </AreaBody>
        <More>
          <Link to="/showcase/industrialTrends">
            更多統計報表
            <ARROW />
          </Link>
        </More>
      </Anchor>
    </Container>
  );
};

export default StatisticPanel;
