import {
  APPEND_ADVANCED_KEYWORD,
  APPEND_CLUSTER_KEYWORD,
  APPEND_ADVANCED_KEYWORD_COUNTRY,
  CHANGE_SORT_ORDER,
  COMPOSE_BASIC_REQUEST,
  COMPOSE_UUID_LIST_REQUEST,
  DETACH_ADVANCED_KEYWORD,
  NAVIGATE_TO_NEXT_PAGE,
  PatentSearchActionTypes,
  PatentSearchState,
  SEARCH_PTAB_DATA,
  SELECT_DATE_RANGE,
  TOGGLE_BILINGUAL_TERM,
  TOGGLE_DEDUPE,
  TOGGLE_TECH_FUNC_QUERY,
} from 'src/redux/types/patentSearchTypes';
import { IAdvancedKeyword } from 'src/types/PatentSearchTypes';

const initialAdvanceKeyword: Array<IAdvancedKeyword> = [{ keyCode: 'PO', keyword: 'TW' }];

const initialState: PatentSearchState = {
  request: {
    industrialClassIds: [],
    dedupe: false,
    queryString: '',
    pageNo: 1,
    advanceKeywords: initialAdvanceKeyword,
  },
  isUuidListSearch: false,
  highlightWords: [],
};

const patentSearchReducer = (
  state = initialState,
  action: PatentSearchActionTypes,
): PatentSearchState => {
  const prevRequest = state.request;

  switch (action.type) {
    case COMPOSE_BASIC_REQUEST:
      return {
        request: {
          ...action.basicRequest,
          pageNo: 1, // 預設查詢第一頁
          advanceKeywords: action.basicRequest.advanceKeywords
            ? action.basicRequest.advanceKeywords
            : initialAdvanceKeyword, // 基本查詢預設查詢 TW
        },
        isUuidListSearch: false,
        highlightWords: action.highlightWords,
      };
    case COMPOSE_UUID_LIST_REQUEST:
      return {
        request: {
          ...action.basicRequest,
          pageNo: 1, // 預設查詢第一頁
        },
        isUuidListSearch: true,
        highlightWords: [],
      };
    case NAVIGATE_TO_NEXT_PAGE:
      return {
        ...state,
        request: {
          ...prevRequest,
          pageNo: prevRequest.pageNo + 1,
        },
      };
    case SELECT_DATE_RANGE:
      return {
        ...state,
        request: {
          ...prevRequest,
          dateRange: action.dateRange,
          pageNo: 1,
        },
      };
    case SEARCH_PTAB_DATA:
      return {
        ...state,
        request: {
          ...prevRequest,
          ptab: '1',
          pageNo: 1,
        },
      };
    case CHANGE_SORT_ORDER:
      return {
        ...state,
        request: {
          ...prevRequest,
          sortByDate: action.sortByDate,
          pageNo: 1,
        },
      };
    case APPEND_ADVANCED_KEYWORD: {
      const advanceKeywords = prevRequest.advanceKeywords;
      const newAdvancedKeyword = {
        keyCode: action.keyCode,
        keyword: action.keyword,
      };
      return {
        ...state,
        request: {
          ...prevRequest,
          advanceKeywords: advanceKeywords
            ? advanceKeywords.concat([newAdvancedKeyword])
            : [newAdvancedKeyword],
          pageNo: 1,
        },
      };
    }
    case DETACH_ADVANCED_KEYWORD: {
      const advanceKeywords = prevRequest.advanceKeywords;
      if (advanceKeywords) {
        const newAdvancedKeywords = advanceKeywords.slice();
        newAdvancedKeywords.splice(
          newAdvancedKeywords.findIndex(item => item.keyCode === action.keyCode),
          1,
        );
        return {
          ...state,
          request: {
            ...prevRequest,
            advanceKeywords: newAdvancedKeywords,
            pageNo: 1,
          },
        };
      } else {
        return {
          ...state,
        };
      }
    }
    case APPEND_ADVANCED_KEYWORD_COUNTRY: {
      const advanceKeywords = prevRequest.advanceKeywords;
      const newAdvancedKeyword = {
        keyCode: action.keyCode,
        keyword: action.keyword,
      };
      if (advanceKeywords) {
        // 置換 advanceKeywords 之 keyCode 與 keyword 欄位
        const idx = advanceKeywords.findIndex(item => item.keyCode === action.keyCode);
        advanceKeywords[idx] = {
          keyCode: action.keyCode,
          keyword: action.keyword,
        };
      }
      return {
        ...state,
        request: {
          ...prevRequest,
          advanceKeywords: advanceKeywords ? advanceKeywords : [newAdvancedKeyword],
          pageNo: 1,
        },
      };
    }
    case APPEND_CLUSTER_KEYWORD: {
      const queryString = prevRequest.queryString;
      const concatWord = queryString.trim() === '' ? '' : ' AND ';
      const newQueryString = queryString + concatWord + '"' + action.clusterKeyword + '"';
      return {
        // 聚類關鍵字添加視為重新查詢，除檢索式外回復原始查詢條件之預設查詢狀態
        // 關鍵字APPEND過即非單一關鍵字，故沒有 highlightWords。
        // 技術功效再檢索 filterTag 亦清空
        request: {
          queryString: newQueryString,
          industrialClassIds: prevRequest.industrialClassIds,
          pageNo: 1,
          advanceKeywords: prevRequest.advanceKeywords,
        },
        isUuidListSearch: state.isUuidListSearch,
        highlightWords: [],
      };
    }
    case TOGGLE_BILINGUAL_TERM:
      return {
        ...state,
        request: {
          ...prevRequest,
          bilingualTerm: action.bilingualTerm,
          pageNo: 1,
        },
      };
    case TOGGLE_DEDUPE:
      return {
        ...state,
        request: {
          ...prevRequest,
          dedupe: action.dedupe,
          pageNo: 1,
        },
      };
    case TOGGLE_TECH_FUNC_QUERY:
      return {
        ...state,
        request: {
          // 回復原始查詢條件之預設查詢狀態，再加上技術與功效檢索式
          queryString: prevRequest.queryString,
          industrialClassIds: prevRequest.industrialClassIds,
          advanceKeywords: prevRequest.advanceKeywords,
          techQuery: action.techQuery,
          funcQuery: action.funcQuery,
          pageNo: 1,
        },
        filterTag: action.filterTag,
      };
    default:
      return { ...state };
  }
};

export default patentSearchReducer;
