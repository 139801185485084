import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { FaPlus, FaTimes, FaGripVertical } from 'react-icons/fa';
import {
  Formik,
  Form,
  FormikProps,
  FormikActions,
  Field,
  FieldArray,
  FieldArrayRenderProps,
  ErrorMessage,
  getIn,
} from 'formik';
import * as Yup from 'yup';
import { Button } from 'src/style/theme/Common';
import {
  pink3,
  pink7,
  pink8,
  blue1,
  blue6,
  blue7,
  blue6rgba,
  iceblue5,
  iceblue6,
  alarm5,
  alarm6,
  ultrawhite,
} from 'src/style/theme/Color';
import ChartModal from 'src/components/ui/interactive/modal/custom/ChartModal';
import Chart from './Chart';
import { ButtonArea } from 'src/style/ResultToolbarStyle';
import { ISearchRequest } from 'src/types/PatentSearchTypes';
import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import { alertMessage } from 'src/utils/ModalUtils';
import { SystemMessage } from 'src/apps/applicationMessages';
import { saveAsPdf } from 'src/utils/svgDownloadUtils';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { CSVLink } from 'react-csv';
import _ from 'lodash';
import Papa from 'papaparse';
import Dropzone from 'react-dropzone';

import { connect } from 'react-redux';
import { ReduxAppState } from 'src/redux/reducers';
import { CustomModalType } from 'src/types/ModalTypes';
import { openCustomModal } from 'src/redux/actions/modalAction';
import {
  saveMatrixForm,
  saveMatrixAxisData,
  saveMatrixDataset,
} from 'src/redux/result/toolbar/action';
import { IFieldValue, IMatrixData } from 'src/redux/result/toolbar/types';
import { defaultFieldValue, formInitialValues } from 'src/types/defaultData/matrixFormDefault';

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  margin-right: 8px;
  font-size: 20px;
  font-weight: 600;
  color: ${ultrawhite};
`;

const scrollbarStyle = css`
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    box-shadow: inset 0 0 8px 8px ${blue6rgba(0.5)};
    border: solid 2px transparent;
  }
`;

const Body = styled.div`
  max-height: 62.5vh;
  overflow: auto;
  ${scrollbarStyle}
  margin-bottom: 24px;
`;

const technologyStyle = (hasHover?: boolean) => css`
  color: ${blue1};
  background-color: ${blue7};

  :hover {
    background-color: ${hasHover && blue6};
  }
`;

const functionStyle = (hasHover?: boolean) => css`
  color: ${pink3};
  background-color: ${pink8};

  :hover {
    background-color: ${hasHover && pink7};
  }
`;

const QueryItem = styled.div<{ type: string }>`
  user-select: none;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px;
  border-radius: 4px;
  margin-bottom: 8px;

  svg:nth-child(1) {
    color: ${iceblue5};
    margin-right: 16px;
  }

  span {
    font-weight: 600;
    margin-right: 16px;
  }

  ${props =>
    (props.type === 'technology' && technologyStyle()) ||
    (props.type === 'function' && functionStyle())}
`;

const QueryItemName = styled.div<{ hasError?: boolean }>`
  position: relative;
  width: 20%;
  margin-right: 8px;

  input {
    width: 100%;
    padding: 8px;
    border: 1px solid ${props => (props.hasError ? alarm6 : iceblue6)};
    border-radius: 4px;

    ::placeholder {
      color: ${iceblue5};
    }
  }
`;

const QueryItemKeyword = styled.div<{ hasError?: boolean }>`
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;

  textarea {
    width: 100%;
    height: 40px; /** Firefox 對 textarea 解析和 chrome、IE 不同，所以寫死 */
    resize: vertical;
    padding: 8px;
    border: 1px solid ${props => (props.hasError ? alarm6 : iceblue6)};
    border-radius: 4px;

    ::placeholder {
      color: ${iceblue5};
    }
  }
`;

const ErrorBox = styled.div`
  position: absolute;
  bottom: -16px;
  left: 8px;
  color: ${alarm5};
  font-size: 12px;
`;

const QueryItemAddArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
`;

const QueryItemAddButton = styled.div<{ type: string }>`
  width: calc((100% - 12px) / 2);
  padding: 8px;
  border-radius: 4px;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 8px;
  }

  :hover {
    cursor: pointer;
  }

  ${props =>
    (props.type === 'technology' && technologyStyle(true)) ||
    (props.type === 'function' && functionStyle(true))}
`;

const QueryItemHiddenButton = styled.div`
  display: none;
`;

const StyledFaTimes = styled(FaTimes)`
  position: absolute;
  top: 4px;
  right: 4px;
  color: ${blue1};
  font-size: 12px;

  :hover {
    cursor: pointer;
  }
`;

const DragHandleItem = styled.div`
  display: flex;
  align-items: center;
`;

interface IConfigData {
  axis: string;
  name: string;
  keyword: string;
}

interface IReduxProps {
  searchRequest: ISearchRequest;
  isChartModalOpen: boolean;
  openCustomModal: (customModalType: CustomModalType) => void;
  dataset: Array<Array<number>>;
  technologyList: Array<IFieldValue>;
  functionList: Array<IFieldValue>;
  matrixForm: IMatrixData;
  saveMatrixForm: (matrixForm: IMatrixData) => void;
  saveMatrixAxisData: (
    technologyList: Array<IFieldValue>,
    functionList: Array<IFieldValue>,
  ) => void;
  saveMatrixDataset: (dataset: Array<Array<number>>) => void;
}

interface IProps extends IReduxProps {}

// TODO: Refactor, simplify component structure
// TODO: 文字訊息、固定字串定義方式調整
/** 功效矩陣 */
const MatrixPane: React.FC<IProps> = ({
  searchRequest,
  isChartModalOpen,
  openCustomModal,
  dataset,
  technologyList,
  functionList,
  matrixForm,
  saveMatrixForm,
  saveMatrixAxisData,
  saveMatrixDataset,
}) => {
  const [isAnalysisLoading, setIsAnalysisLoading] = useState<boolean>(false);
  const [isAnalysisError, setIsAnalysisError] = useState<boolean>(false);
  const [configData, setConfigData] = useState<Array<IConfigData>>([]);

  const technologyHiddenRef = useRef<HTMLDivElement>(null);
  const functionHiddenRef = useRef<HTMLDivElement>(null);

  const chartModalTitle = '技術功效矩陣繪圖';
  const configFilename = '技術功效矩陣設定檔.csv';
  const configDataHeaders = [
    { label: 'axis', key: 'axis' },
    { label: 'name', key: 'name' },
    { label: 'keyword', key: 'keyword' },
  ];
  const maxSize = 1048576; // 1mb
  const maxListSize = 6;

  const handleTechnologyAdd = () => {
    const current = technologyHiddenRef && technologyHiddenRef.current;
    current && current.click();
  };

  const handleFunctionAdd = () => {
    const current = functionHiddenRef && functionHiddenRef.current;
    current && current.click();
  };

  const handleHiddenAdd = (type: string, len: number, arrayHelpers: FieldArrayRenderProps) => {
    if (len >= maxListSize) {
      alertMessage(type + SystemMessage.MATRIX_FIELD_LIMIT);
    } else {
      arrayHelpers.push(defaultFieldValue);
    }
  };

  const handleSubmit = async (values: IMatrixData, actions: FormikActions<IMatrixData>) => {
    // 先設定讀取中再開啟Modal，否則會先渲染一次無資料的圖表
    setIsAnalysisLoading(true);
    setIsAnalysisError(false);
    openCustomModal(CustomModalType.CHART);

    saveMatrixAxisData(values.technologyList, values.functionList);

    actions.setSubmitting(false);

    try {
      // 分析母體不包含進階篩選條件
      const { industrialClassIds, queryString } = searchRequest;
      const response = await Api().post('patent/search/matrix', {
        industrialClassIds: industrialClassIds,
        queryString: queryString,
        techQueries: values.technologyList.map(item => item.keyword),
        funcQueries: values.functionList.map(item => item.keyword),
      });
      saveMatrixDataset(response.data);
    } catch (error) {
      errorHandler(error);
      setIsAnalysisError(true);
    } finally {
      setIsAnalysisLoading(false);
    }
  };

  /** 轉換雙引號 (資料內容的雙引號以兩個表示，Excel才能正常解析) */
  const encodeQuote = (text: string) => {
    return text.replace(/"/g, '""');
  };

  /** 設定檔下載 */
  const handleConfigFileDownload = async (
    formikBag: FormikProps<IMatrixData>,
    done: (proceed?: boolean | undefined) => void,
  ) => {
    const values = formikBag.values;

    const errors = await formikBag.validateForm();

    // TODO: formik set all fields touched 的方法?
    // setTouched 會導致 errors 產生 isCanceled:true，原因待查。 (https://github.com/jaredpalmer/formik/issues/374)
    // 目前暫時放在 validateForm 後執行
    values.technologyList.forEach((item, idx) => {
      formikBag.setFieldTouched(`technologyList[${idx}].name`, true);
      formikBag.setFieldTouched(`technologyList[${idx}].keyword`, true);
    });
    values.functionList.forEach((item, idx) => {
      formikBag.setFieldTouched(`functionList[${idx}].name`, true);
      formikBag.setFieldTouched(`functionList[${idx}].keyword`, true);
    });

    if (_.isEmpty(errors)) {
      const techConfigs = values.technologyList.map(item => ({
        axis: '技術',
        name: encodeQuote(item.name),
        keyword: encodeQuote(item.keyword),
      }));
      const funcConfigs = values.functionList.map(item => ({
        axis: '功效',
        name: encodeQuote(item.name),
        keyword: encodeQuote(item.keyword),
      }));

      const result = techConfigs.concat(funcConfigs);

      setConfigData(result);

      done();
    } else {
      done(false);
    }
  };

  /** 設定檔上傳 */
  const handleConfigFileUpload = (
    acceptFiles: File[],
    rejectedFiles: File[],
    formikBag: FormikProps<IMatrixData>,
  ) => {
    if (rejectedFiles.length > 0) {
      const rejectedFile = rejectedFiles[0];
      if (rejectedFile.type !== 'application/vnd.ms-excel') {
        alertMessage(
          SystemMessage.MATRIX_FILE_UPLOAD_FAILED,
          SystemMessage.MATRIX_FILE_UPLOAD_FORMAT_CHECK,
        );
      } else if (rejectedFile.size > maxSize) {
        alertMessage(
          SystemMessage.MATRIX_FILE_UPLOAD_FAILED,
          SystemMessage.MATRIX_FILE_UPLOAD_LIMIT_OVER,
        );
      } else {
        alertMessage(SystemMessage.MATRIX_FILE_UPLOAD_FAILED);
      }
    } else if (acceptFiles.length > 0) {
      parseAcceptFile(acceptFiles[0], formikBag);
    }
  };

  /** 解析符合基本要求(格式、檔案大小)的檔案 */
  const parseAcceptFile = (acceptFile: File, formikBag: FormikProps<IMatrixData>) => {
    Papa.parse(acceptFile, {
      header: true,
      skipEmptyLines: true,
      error: () => {
        // A callback to execute if FileReader encounters an error.
        alertParseErrMsg({
          description: SystemMessage.MATRIX_FILE_UPLOAD_CONTENT_CHECK,
        });
      },
      complete: results => {
        if (results.errors.length > 0) {
          alertParseErrMsg({
            description: SystemMessage.MATRIX_FILE_UPLOAD_CONTENT_CHECK,
          });
        } else {
          // 轉換parse完的Json物件至Formik Fields
          const techList: Array<IFieldValue> = [];
          const funcList: Array<IFieldValue> = [];
          let parseError = '';

          for (let item of results.data as Array<IConfigData>) {
            if (!item.name) {
              parseError = '找不到名稱 (name) 欄位';
              break;
            } else if (!item.keyword) {
              parseError = '找不到檢索式 (keyword) 欄位';
              break;
            } else {
              if (item.axis === '技術') {
                techList.push({
                  name: item.name,
                  keyword: item.keyword,
                });
              } else if (item.axis === '功效') {
                funcList.push({
                  name: item.name,
                  keyword: item.keyword,
                });
              } else {
                parseError = `無效的座標 (axis) 欄位名稱: "${item.axis}"`;
                break;
              }
            }
          }

          if (parseError !== '') {
            alertParseErrMsg({
              msg: parseError,
              description: SystemMessage.MATRIX_FILE_UPLOAD_CONTENT_CHECK,
            });
          } else if (techList.length === 0) {
            alertParseErrMsg({
              msg: '技術' + SystemMessage.MATRIX_FIELD_EMPTY,
            });
          } else if (funcList.length === 0) {
            alertParseErrMsg({
              msg: '功效' + SystemMessage.MATRIX_FIELD_EMPTY,
            });
          } else if (techList.length > maxListSize) {
            alertParseErrMsg({
              msg: '技術' + SystemMessage.MATRIX_FIELD_LIMIT_OVER,
            });
          } else if (funcList.length > maxListSize) {
            alertParseErrMsg({
              msg: '功效' + SystemMessage.MATRIX_FIELD_LIMIT_OVER,
            });
          } else {
            formikBag.setFieldValue('technologyList', techList);
            formikBag.setFieldValue('functionList', funcList);
          }
        }
      },
    });
  };

  /** 顯示檔案解析錯誤之訊息 */
  const alertParseErrMsg = (msgObj: { msg?: string; description?: string }) => {
    const { msg, description } = msgObj;
    let message: string = SystemMessage.MATRIX_FILE_PARSE_FAILED;
    msg && (message += ', ' + msg);
    alertMessage(message, description);
  };

  return (
    <>
      <Formik
        initialValues={matrixForm}
        validate={_.debounce((values: IMatrixData) => {
          // 欄位onFocus、onBlur或其他操作觸發validate，表單資料無變化則不需要寫入redux
          if (!_.isEqual(values, matrixForm)) {
            // 利用validate來實現Formik values onChange時把資料儲存到全域的想法，values變動或form submit皆會觸發此操作，
            // TODO: 不確定是否會與validationSchema有所衝突。
            saveMatrixForm(values);
          }
        }, 1000)}
        validationSchema={Yup.object().shape({
          technologyList: Yup.array()
            .of(
              Yup.object().shape({
                name: Yup.string()
                  .trim()
                  .required('請輸入技術名稱'),
                keyword: Yup.string()
                  .trim()
                  .required('請輸入技術檢索式'),
              }),
            )
            .required('請新增技術欄位')
            .min(1, '技術欄位至少需一筆資料'),
          functionList: Yup.array()
            .of(
              Yup.object().shape({
                name: Yup.string()
                  .trim()
                  .required('請輸入功效名稱'),
                keyword: Yup.string()
                  .trim()
                  .required('請輸入功效檢索式'),
              }),
            )
            .required('請新增功效欄位')
            .min(1, '功效欄位至少需一筆資料'),
        })}
        onSubmit={(values, actions) => handleSubmit(values, actions)}
        render={(formikBag: FormikProps<IMatrixData>) => {
          return (
            <Form>
              <Header>
                <HeaderLeft>
                  <Title>技術功效分析</Title>
                  {!_.isEqual(formikBag.values, formInitialValues) && (
                    <Button
                      type="button"
                      template="primary-dark"
                      onClick={() => {
                        // setValues會觸發validate，在此 setErrors({}) 無效，寫了formikBag.errors還是會有值。
                        // 雖然不影響使用，但若想要讓 submit button 的 disabled={!_.isEmpty(formikBag.errors)} 可能需要另外想辦法處理。
                        formikBag.setValues({ ...formInitialValues });
                        formikBag.setTouched({});
                      }}
                    >
                      清除
                    </Button>
                  )}
                </HeaderLeft>
                <Dropzone
                  onDrop={(acceptFiles, rejectedFiles) =>
                    handleConfigFileUpload(acceptFiles, rejectedFiles, formikBag)
                  }
                  accept=".csv"
                  minSize={0}
                  maxSize={maxSize}
                  noClick={true}
                >
                  {({ getRootProps, getInputProps, isDragActive, open }) => {
                    return (
                      <label {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Button type="button" template="light" onClick={open}>
                          {isDragActive ? '拖放檔案至此' : '上傳設定檔'}
                        </Button>
                      </label>
                    );
                  }}
                </Dropzone>
              </Header>
              <Body>
                <FieldArray
                  name="technologyList"
                  render={arrayHelpers => {
                    const errors = getIn(formikBag.errors, 'technologyList');
                    const touches = getIn(formikBag.touched, 'technologyList');
                    return (
                      <>
                        <DragDropContext
                          onDragEnd={result => {
                            result.destination &&
                              arrayHelpers.move(result.source.index, result.destination.index);
                          }}
                        >
                          <Droppable droppableId="technologyList-droppableId">
                            {provided => (
                              <div ref={provided.innerRef as any} {...provided.droppableProps}>
                                {formikBag.values.technologyList.map((obj, idx) => {
                                  const touch = touches && touches[idx];
                                  const error = errors && errors[idx];
                                  const touchName = touch && touch.name;
                                  const errorName = error && error.name;
                                  const touchKeyword = touch && touch.keyword;
                                  const errorKeyword = error && error.keyword;
                                  return (
                                    <Draggable
                                      draggableId={'technologyList-' + idx}
                                      index={idx}
                                      key={idx}
                                    >
                                      {p => (
                                        <QueryItem
                                          type="technology"
                                          ref={p.innerRef as any}
                                          {...p.draggableProps}
                                        >
                                          {/* {...p.dragHandleProps} 控制點擊誰可以拖動整個元件 */}
                                          <DragHandleItem {...p.dragHandleProps}>
                                            <FaGripVertical />
                                            <span>技術</span>
                                          </DragHandleItem>
                                          <QueryItemName hasError={touchName && errorName}>
                                            <Field
                                              type="text"
                                              name={`technologyList[${idx}].name`}
                                              maxLength={32}
                                              placeholder="名稱"
                                            />
                                            <ErrorMessage
                                              component={ErrorBox}
                                              name={`technologyList[${idx}].name`}
                                            />
                                          </QueryItemName>
                                          <QueryItemKeyword hasError={touchKeyword && errorKeyword}>
                                            <Field
                                              component="textarea"
                                              name={`technologyList[${idx}].keyword`}
                                              rows={1}
                                              placeholder="檢索式"
                                            />
                                            <ErrorMessage
                                              component={ErrorBox}
                                              name={`technologyList[${idx}].keyword`}
                                            />
                                          </QueryItemKeyword>
                                          {formikBag.values.technologyList.length > 1 && (
                                            <StyledFaTimes
                                              onClick={() => arrayHelpers.remove(idx)}
                                            />
                                          )}
                                        </QueryItem>
                                      )}
                                    </Draggable>
                                  );
                                })}
                                <QueryItemHiddenButton
                                  ref={technologyHiddenRef as any}
                                  onClick={() =>
                                    handleHiddenAdd(
                                      '技術',
                                      formikBag.values.technologyList.length,
                                      arrayHelpers,
                                    )
                                  }
                                />
                                {/* 預留拖曳時空白的位置，不加或加錯位置會導致拖曳時元件位置跑掉。 */}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </>
                    );
                  }}
                />
                <FieldArray
                  name="functionList"
                  render={arrayHelpers => {
                    const errors = getIn(formikBag.errors, 'functionList');
                    const touches = getIn(formikBag.touched, 'functionList');
                    return (
                      <>
                        <DragDropContext
                          onDragEnd={result => {
                            result.destination &&
                              arrayHelpers.move(result.source.index, result.destination.index);
                          }}
                        >
                          <Droppable droppableId="functionList-droppableId">
                            {provided => (
                              <div ref={provided.innerRef as any} {...provided.droppableProps}>
                                {formikBag.values.functionList.map((obj, idx) => {
                                  const touch = touches && touches[idx];
                                  const error = errors && errors[idx];
                                  const touchName = touch && touch.name;
                                  const errorName = error && error.name;
                                  const touchKeyword = touch && touch.keyword;
                                  const errorKeyword = error && error.keyword;
                                  return (
                                    <Draggable
                                      draggableId={'functionList-' + idx}
                                      index={idx}
                                      key={idx}
                                    >
                                      {p => (
                                        <div ref={p.innerRef as any} {...p.draggableProps}>
                                          <QueryItem type="function" key={idx}>
                                            <DragHandleItem {...p.dragHandleProps}>
                                              <FaGripVertical />
                                              <span>功效</span>
                                            </DragHandleItem>
                                            <QueryItemName hasError={touchName && errorName}>
                                              <Field
                                                type="text"
                                                name={`functionList[${idx}].name`}
                                                maxLength={32}
                                                placeholder="名稱"
                                              />
                                              <ErrorMessage
                                                component={ErrorBox}
                                                name={`functionList[${idx}].name`}
                                              />
                                            </QueryItemName>
                                            <QueryItemKeyword
                                              hasError={touchKeyword && errorKeyword}
                                            >
                                              <Field
                                                component="textarea"
                                                name={`functionList[${idx}].keyword`}
                                                rows={1}
                                                placeholder="檢索式"
                                              />
                                              <ErrorMessage
                                                component={ErrorBox}
                                                name={`functionList[${idx}].keyword`}
                                              />
                                            </QueryItemKeyword>
                                            {formikBag.values.functionList.length > 1 && (
                                              <StyledFaTimes
                                                onClick={() => arrayHelpers.remove(idx)}
                                              />
                                            )}
                                          </QueryItem>
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                })}
                                <QueryItemHiddenButton
                                  ref={functionHiddenRef as any}
                                  onClick={() =>
                                    handleHiddenAdd(
                                      '功效',
                                      formikBag.values.functionList.length,
                                      arrayHelpers,
                                    )
                                  }
                                />
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </>
                    );
                  }}
                />
                <QueryItemAddArea>
                  <QueryItemAddButton type="technology" onClick={handleTechnologyAdd}>
                    <FaPlus />
                    <span>新增技術</span>
                  </QueryItemAddButton>
                  <QueryItemAddButton type="function" onClick={handleFunctionAdd}>
                    <FaPlus />
                    <span>新增功效</span>
                  </QueryItemAddButton>
                </QueryItemAddArea>
              </Body>
              <ButtonArea>
                <Button type="submit" template="primary">
                  分析結果
                </Button>
                <CSVLink
                  filename={configFilename}
                  headers={configDataHeaders}
                  data={configData}
                  asyncOnClick={true}
                  onClick={(event: any, done) => {
                    /**
                     * TODO: 若不加 `event.persist()` 功能會失效，不確定是否與套件背後的實作內容有關，待研究。
                     * Warning: This synthetic event is reused for performance reasons.
                     * If you're seeing this, you're accessing the method `preventDefault` on a released/nullified synthetic event.
                     * This is a no-op function. If you must keep the original synthetic event around, use event.persist().
                     * See https://fb.me/react-event-pooling for more information.
                     */
                    event.persist();
                    handleConfigFileDownload(formikBag, done);
                  }}
                >
                  <Button type="button" template="light">
                    下載設定檔
                  </Button>
                </CSVLink>
              </ButtonArea>
            </Form>
          );
        }}
      />
      {isChartModalOpen && (
        <ChartModal
          title={chartModalTitle}
          isDataLoading={isAnalysisLoading}
          isError={isAnalysisError}
          extraDownloadItem={<div onClick={() => saveAsPdf(chartModalTitle)}>PDF 格式</div>}
        >
          <Chart
            queryString={searchRequest.queryString}
            technologyList={technologyList}
            functionList={functionList}
            dataset={dataset}
          />
        </ChartModal>
      )}
    </>
  );
};

const mapStateToProps = (state: ReduxAppState) => {
  return {
    searchRequest: state.patentSearchReducer.request,
    isChartModalOpen: state.modalReducer.customModalType === CustomModalType.CHART,
    dataset: state.resultToolbarReducer.matrixChart.dataset,
    technologyList: state.resultToolbarReducer.matrixChart.technologyList,
    functionList: state.resultToolbarReducer.matrixChart.functionList,
    matrixForm: state.resultToolbarReducer.matrixForm,
  };
};

const mapDispatchToProps = {
  openCustomModal,
  saveMatrixForm,
  saveMatrixAxisData,
  saveMatrixDataset,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MatrixPane);
