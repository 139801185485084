import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import { IGetPatentSubscription, IGetRssFollow, INotifyItem } from 'src/types/api/MemberApiTypes';
import { IGetPatentSubscriptionShare } from 'src/types/api/ShowcaseApiTypes';
import { IGetRssFollowShareMetadata, IGetRssTopicFollow } from 'src/types/api/NewsApiTypes';
import { isCompanyMember } from 'src/apps/auth/roleChecker';

import NotifyItems from './item/NotifyItems';
import MiniLoader from 'src/components/ui/interactive/MiniLoader';
import SlideToggle from 'src/components/ui/interactive/SlideToggle';
import { Spinner } from 'src/components/ui/interactive/Spinner';
import { Container, Subject, Description, Inform } from 'src/style/MemberNotificationStyle';

export enum Category {
  SUBSCRIPTION = 'SUBSCRIPTION',
  WEEKLY_INFO = 'WEEKLY_INFO',
}

const NotificationSubscription: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [switchLoading, setSwitchLoading] = useState<boolean>(true);
  const [subscriptionSwitch, setSubscriptionSwitch] = useState<boolean>();
  const [trackNotify, setTrackNotify] = useState<Array<IGetPatentSubscription>>([]);
  const [trackShareNotify, setTrackShareNotify] = useState<Array<IGetPatentSubscriptionShare>>([]);
  const [newsNotify, setNewsNotify] = useState<Array<IGetRssFollow>>([]);
  const [newsShareNotify, setNewsShareNotify] = useState<Array<IGetRssFollowShareMetadata>>([]);
  const [newsTopicNotify, setNewsTopicNotify] = useState<Array<IGetRssTopicFollow>>([]);

  useEffect(() => {
    const fetchTrack = async () => {
      try {
        const subSwitch = Api().get('edm/subscription/notification');
        const patent = Api().get('patent/subscription');
        const patentShare = Api().get('patent/subscription/share/notification');
        const rss = Api().get('rss/follow/keyword/notification');
        const rssShare = Api().get('rss/follow/share/notification');
        const rssTopic = Api()
          .get('rss/follow/topic/notification')
          .catch(error => errorHandler(error));
        const resArr = await Promise.all([subSwitch, patent, patentShare, rss, rssShare, rssTopic]);
        resArr[0].data !== undefined && setSubscriptionSwitch(resArr[0].data);
        resArr[1].data.length > 0 && setTrackNotify(resArr[1].data);
        resArr[2].data.length > 0 && setTrackShareNotify(resArr[2].data);
        resArr[3].data.length > 0 && setNewsNotify(resArr[3].data);
        resArr[4].data.length > 0 && setNewsShareNotify(resArr[4].data);
        resArr[5] && resArr[5].data.length > 0 && setNewsTopicNotify(resArr[5].data);
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsLoading(false);
        setSwitchLoading(false);
      }
    };

    fetchTrack();
  }, []);

  const setting = [
    // 專利追蹤
    { feature: 'trackNotify', api: 'patent/subscription/{id}/notify', data: trackNotify },
    // 我的頻道
    { feature: 'newsNotify', api: 'rss/follow/{id}/notify', data: newsNotify },
    // 共享專利追蹤
    { feature: 'trackShareNotify', api: 'patent/subscription/{id}/share', data: trackShareNotify },
    // 他人分享頻道
    { feature: 'newsShareNotify', api: 'rss/follow/{id}/share', data: newsShareNotify },
    // 新聞頻道
    { feature: 'newsTopicNotify', api: 'rss/follow/topic/{id}/notify', data: newsTopicNotify },
  ];

  type IDatatype = Array<
    | IGetRssFollow
    | IGetPatentSubscription
    | IGetPatentSubscriptionShare
    | IGetRssFollowShareMetadata
    | IGetRssTopicFollow
  >;

  const handleNotification = (idx: number, type: string) => {
    let dataArr: IDatatype = [];
    let temp: IDatatype = [];
    const patchShare = async (id: number, api: string) => {
      try {
        temp = _.cloneDeep(dataArr);
        const patchBody = dataArr[idx].notification ? 'false' : 'true';

        await Api({ 'Content-Type': 'application/json' })
          .patch(api.replace('{id}', id.toString()), patchBody)
          .then(resp => {
            if (resp.status === 200) temp[idx].notification = !dataArr[idx].notification;
          });
      } catch (error) {
        errorHandler(error);
      }
    };

    switch (type) {
      case setting[0].feature: // 專利追蹤
        dataArr = setting[0].data;
        if (dataArr.length > 0) {
          patchShare(trackNotify[idx].id, setting[0].api).then(() =>
            setTrackNotify(temp as Array<IGetPatentSubscription>),
          );
        }
        break;
      case setting[1].feature: // 我的頻道
        dataArr = setting[1].data;
        if (dataArr.length > 0) {
          patchShare(newsNotify[idx].id, setting[1].api).then(() =>
            setNewsNotify(temp as Array<IGetRssFollow>),
          );
        }
        break;
      case setting[2].feature: // 共享專利追蹤
        dataArr = setting[2].data;
        if (dataArr.length > 0) {
          patchShare(trackShareNotify[idx].id, setting[2].api).then(() =>
            setTrackShareNotify(temp as Array<IGetPatentSubscriptionShare>),
          );
        }
        break;
      case setting[3].feature: // 他人分享頻道
        dataArr = setting[3].data;
        if (dataArr.length > 0) {
          patchShare(newsShareNotify[idx].id, setting[3].api).then(() =>
            setNewsShareNotify(temp as Array<IGetRssFollowShareMetadata>),
          );
        }
        break;
      case setting[4].feature: // 新聞頻道
        dataArr = setting[4].data;
        if (dataArr.length > 0) {
          patchShare(newsTopicNotify[idx].id, setting[4].api).then(() =>
            setNewsTopicNotify(temp as Array<IGetRssTopicFollow>),
          );
        }
        break;
      default:
        break;
    }
  };

  const handleSwitch = () => {
    setSwitchLoading(true);
    const subStatus = !subscriptionSwitch;
    Api({ 'Content-Type': 'application/json' })
      .patch(`edm/subscription/notification`, subStatus.toString())
      .then(() => {
        setSubscriptionSwitch(subStatus);
        setSwitchLoading(false);
      });
  };

  return (
    <>
      {isLoading ? (
        <Spinner width="150px" />
      ) : (
        <>
          <Container>
            <Subject>專利及新聞訂閱</Subject>
            <Description>依據下方訂閱項目，每週通知您是否有新的資訊。</Description>
            <Inform>
              {switchLoading ? (
                <MiniLoader diameter={'24px'} borderWidth={'2px'} margin={'0 4px'} />
              ) : (
                <SlideToggle checked={subscriptionSwitch || false} change={() => handleSwitch()} />
              )}
            </Inform>
          </Container>
          <NotifyItems
            data={trackNotify.map(item => ({ ...item } as INotifyItem))}
            type="trackNotify"
            subject="專利追蹤"
            description="依據您追蹤的專利，通知您是否有更新狀態。"
            click={handleNotification}
          />
          {isCompanyMember() && (
            <NotifyItems
              data={trackShareNotify.map(item => ({ ...item } as INotifyItem))}
              type="trackShareNotify"
              subject="共享專利追蹤"
              description="依據他人與您共享的追蹤專利，通知您是否有更新狀態。"
              click={handleNotification}
            />
          )}
          <NotifyItems
            data={newsTopicNotify.map(item => ({ ...item } as INotifyItem))}
            type="newsTopicNotify"
            subject="新聞頻道"
            description="依據網站推薦的新聞頻道，通知您是否有新的新聞。"
            click={handleNotification}
          />
          <NotifyItems
            data={newsNotify.map(item => ({ ...item } as INotifyItem))}
            type="newsNotify"
            subject="我的頻道"
            description="依據您自訂的新聞頻道，通知您是否有新的新聞。"
            click={handleNotification}
          />
          {isCompanyMember() && (
            <NotifyItems
              data={newsShareNotify.map(item => ({ ...item } as INotifyItem))}
              type="newsShareNotify"
              subject="他人分享頻道"
              description="依據他人與您分享的新聞頻道，通知您是否有新的新聞。"
              click={handleNotification}
            />
          )}
        </>
      )}
    </>
  );
};

export default NotificationSubscription;
