import React from 'react';
import styled from 'styled-components';

import { baseURL } from 'src/api/helpers/apiBase';

import { dateFormatter } from 'src/utils/Formatter';
import { IGetColumnistArticle } from 'src/types/api/ColumnistArticleApiTypes';
import { blue2, iceblue6 } from 'src/style/theme/Color';

const Container = styled.div`
  box-shadow: 0 12px 50px 0 rgba(170, 170, 170, 0.2);
  margin-bottom: 16px;
  :hover {
    background-color: #f5fdfe;
    cursor: pointer;
  }
`;

const ImageBox = styled.div`
  height: 180px;
  overflow: hidden;
  img {
    width: 100%;
  }
`;

const Body = styled.div`
  padding: 16px;
`;

const Info = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 8px;
  font-size: 12px;
  font-weight: 500;
  color: ${iceblue6};
`;

const Title = styled.a`
  padding: 2px 0;
  margin-bottom: 8px;
  font-size: 16px;
  color: ${blue2};
`;

const Text = styled.div`
  font-size: 14px;
  color: ${iceblue6};
`;

export interface IProps {
  columnData: IGetColumnistArticle;
}

const MobileColumnistArticleCard: React.FC<IProps> = ({ columnData }) => {
  const handleArticleAncherOnClick = (ColumnistArticleId: number) => {
    window.open(process.env.REACT_APP_MOBILE_URL + 'article/columnist/' + ColumnistArticleId);
  };

  return (
    <Container onClick={() => handleArticleAncherOnClick(columnData.id)}>
      <ImageBox>
        {columnData.mainImage && columnData.mainImage.id && (
          <img
            src={
              baseURL + 'article/columnist/' + columnData.id + '/image/' + columnData.mainImage.id
            }
            alt=""
          />
        )}
      </ImageBox>
      <Body>
        <Info>
          <div>
            {columnData.columnistArticleCategory ? columnData.columnistArticleCategory.name : ''}
          </div>
          <div>{dateFormatter(columnData.startDate)}</div>
        </Info>
        <Title>{columnData.title}</Title>
        <Text>作者：{columnData.author}</Text>
      </Body>
    </Container>
  );
};

export default MobileColumnistArticleCard;
