import React from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router';

import LOGIN_BG from 'src/assets/images/login-bg-2x.png';
import LoginCore from 'src/components/common/login/LoginCore';

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  min-height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${LOGIN_BG});
  background-size: cover;
`;

const LoginLayout = (props: RouteComponentProps) => (
  <Container>
    <LoginCore {...props} />
  </Container>
);

export default LoginLayout;
