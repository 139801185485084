import React, { useState, useEffect, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';
import axios from 'axios';
import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import {
  IGetRssFollow,
  IGetRssFollowShareMetadata,
  IGetRssTopic,
  IRssSearchSuggestion,
} from 'src/types/api/NewsApiTypes';
import { PrivateRoute } from 'src/routes/PrivateRoute';

import NestedSideNav from 'src/components/common/nav/side-nav/NestedSideNav';
import Latest from './Latest';
import Subscribe from './Subscribe';
import Result from './Result';

import { connect } from 'react-redux';
import { ReduxAppState } from 'src/redux/reducers';
import { ITokenPayload } from 'src/apps/auth/Auth';
import SubscribeToolbar from './SubscribeToolbar';
import SubscribePane from './SubscribePane';

const Container = styled.div`
  margin: 0 auto;
  padding: 16px 140px 0 140px;
  max-width: 1440px;
  display: flex;
  align-items: flex-start;
  align-content: space-between;
  flex-flow: row;
`;
const SideNavStyle = styled.div`
  width: 180px;
  display: inline-flex;
`;
const ContentStyle = styled.div`
  padding: 0 0 0 16px;
  width: calc(100% - 180px);
  position: relative;
`;

const EMPTY_RSS = [
  {
    id: -1,
    createUser: null,
    createTime: 0,
    updateUser: null,
    updateTime: 0,
    systemAccountId: 0,
    keyword: '我的頻道說明',
    notification: false,
    name: '',
    rssTopicId: 0,
    email: null,
  },
];
const EMPTY_SHARE = [
  {
    id: -1,
    createUser: null,
    createTime: 0,
    updateUser: null,
    updateTime: 0,
    systemAccountId: 0,
    keyword: '共享說明',
    notification: false,
    name: '',
    rssTopicId: 0,
    email: null,
  },
];

const sector = '新聞專區';
const features = [
  { feature: '新聞搜尋', path: '/search', subfeatures: false },
  { feature: '新聞頻道', path: '/latest', subfeatures: true },
  { feature: '我的頻道', path: '/subscribe', subfeatures: true },
  { feature: '他人分享頻道', path: '/shares', subfeatures: true },
];

interface IReduxMappingProps {
  member: ITokenPayload;
  isLogin: boolean;
}
interface IUpdateInfo {
  activate: boolean;
  path?: string;
  prevLength?: Array<number>;
}
interface IProps extends RouteComponentProps, IReduxMappingProps {}

function NewsLayout(props: IProps) {
  const [queryString, setQueryString] = useState<string>('');
  const [tags, setTags] = useState<Array<IRssSearchSuggestion>>([]);
  const [topics, setTopics] = useState<Array<IGetRssTopic>>([]);
  const [follow, setFollow] = useState<Array<IGetRssFollow>>([]);
  const [shareMeta, setShareMeta] = useState<Array<IGetRssFollowShareMetadata>>([]);
  const [displaySubscribePane, setDisplaySubscribePane] = useState<boolean>(false);
  const [currentSubscribe, setCurrentSubscribe] = useState<IGetRssFollow | undefined>(undefined);
  const [fetchFinished, setFetchFinished] = useState(false);
  const [topicFetchFinished, setTopicFetchFinished] = useState(false);

  const [forceUpdate, setForceUpdate] = useState<IUpdateInfo>({ activate: false });
  const [stopUpdate, setStopUpdate] = useState(false);

  // 選擇左側 nav 時關閉訂閱 pane
  useEffect(() => {
    setDisplaySubscribePane(false);
  }, [props.location.pathname]);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const fetchRssFollow = async (path?: string, prevLength?: Array<number>) => {
      let redirectUrl = '';

      const handleUrlAfterDelete = (nextPromiseData: Array<any>, prevLength: Array<number>) => {
        const pageArr = ['/news/subscribe', '/news/shares'];
        pageArr.forEach((pageUrl, idx) => {
          if (nextPromiseData[idx].data.length < prevLength[idx]) {
            const nextData = nextPromiseData[idx].data[0];
            redirectUrl = nextData ? pageUrl + '/' + nextData.id : pageUrl;
          }
        });

        // clear parameter
        setForceUpdate(prev => ({ activate: prev.activate }));
      };

      try {
        const promiseFol = Api().get('rss/follow', { cancelToken: source.token });
        const promiseMet = Api().get('rss/follow/share/metadata', { cancelToken: source.token });

        const resArr = await Promise.all([promiseFol, promiseMet]);

        setFollow(resArr[0].data);
        setShareMeta(resArr[1].data);

        setStopUpdate(true);
        setFetchFinished(true);

        path && props.history.replace(path);
        prevLength && handleUrlAfterDelete(resArr, prevLength);
        redirectUrl !== '' && props.history.replace(redirectUrl);
      } catch (error) {
        errorHandler(error);
      }
    };

    if (props.member.info.guest) {
      setFetchFinished(true);
    } else if (!stopUpdate) {
      fetchRssFollow(forceUpdate.path, forceUpdate.prevLength);
    }

    return () => source.cancel();
  }, [
    forceUpdate.path,
    forceUpdate.prevLength,
    stopUpdate,
    props.history,
    props.member.info.guest,
  ]);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const fetchRssTopic = async () => {
      try {
        const res = await Api().get('rss/topic/parent', { cancelToken: source.token });

        setTopics(res.data);
        setTopicFetchFinished(true);
      } catch (error) {
        errorHandler(error);
      }
    };
    !topicFetchFinished && fetchRssTopic();

    return () => source.cancel();
  }, [topicFetchFinished]);

  const toggleDisplaySubscribePane = useCallback(() => {
    setCurrentSubscribe(undefined);
    setDisplaySubscribePane(prev => !prev);
  }, []);

  const handleSubscribe = useCallback(
    async (option: string, id: number, data: any) => {
      setStopUpdate(false);
      switch (option) {
        case 'AddNewSubscribe':
        case 'EditSubscribe':
          setForceUpdate(prev => ({
            activate: !prev.activate,
            path: `/news/subscribe/${id}`,
          }));
          break;
        case 'EditNotification':
          setForceUpdate(prev => ({ activate: !prev.activate }));
          break;
        case 'DeleteSubscribe':
        case 'DeleteSubscribeShare':
          setForceUpdate(prev => ({
            activate: !prev.activate,
            prevLength: [follow.length, shareMeta.length],
          }));
          break;
        default:
          break;
      }
    },
    [follow.length, shareMeta.length],
  );

  const handleSubscribePaneDisplay = useCallback(
    (display: boolean, subscribeIdToEdit?: number) => {
      if (subscribeIdToEdit) {
        setCurrentSubscribe(follow.find(item => item.id === subscribeIdToEdit));
      } else {
        setCurrentSubscribe(undefined);
      }
      setDisplaySubscribePane(display);
    },
    [follow],
  );

  const handleSearch = useCallback(
    (input: string) => {
      setQueryString(input);
      const topicTag = tags.find(tag => tag.type === 'TOPIC');

      props.history.push(props.match.url + features[0].path, {
        stateId: topicTag && topicTag.value,
      });
    },
    [tags, props.history, props.match.url],
  );

  const handleSuggestionClick = useCallback((suggestion?: IRssSearchSuggestion) => {
    suggestion && suggestion.type !== 'QUERY_STRING' && setTags(prev => [...prev, suggestion]);
  }, []);

  const handleTagCancel = useCallback((type: string, value: string) => {
    setTags(prev => prev.filter(tag => (tag.type !== type ? true : tag.value !== value)));
  }, []);

  const switchTopic = useCallback(() => {
    setTags([]);
    setQueryString('');
  }, []);

  const cleanTag = useCallback(() => setTags([]), []);

  const customizeSubscribe = useMemo(() => follow.filter(item => item.rssTopicId === null), [
    follow,
  ]);

  return (
    <Container>
      {fetchFinished && topicFetchFinished && (
        <>
          <SideNavStyle>
            <NestedSideNav
              sector={sector}
              features={features}
              subFeatArr={[
                {
                  idx: 1,
                  items: topics
                    ? topics.map(item => ({
                        id: item.id,
                        name: item.name,
                        linkable: true,
                        nestedSubfeatures: item.childTopics && item.childTopics.length > 0,
                        nestedSubfeat: item.childTopics
                          ? item.childTopics.map(child => ({
                              id: child.id,
                              name: child.name,
                            }))
                          : [],
                      }))
                    : [],
                },
                {
                  idx: 2,
                  items: customizeSubscribe
                    ? customizeSubscribe.map(item => ({
                        id: item.id,
                        name: item.name,
                      }))
                    : [],
                },
                {
                  idx: 3,
                  items: shareMeta
                    ? shareMeta.map(item => ({
                        id: item.id,
                        name: item.name,
                      }))
                    : [],
                },
              ]}
              {...props}
            />
          </SideNavStyle>

          <ContentStyle>
            {props.isLogin && displaySubscribePane ? (
              <SubscribePane
                currentSubscribe={currentSubscribe}
                keyword={queryString}
                show={displaySubscribePane}
                setShow={handleSubscribePaneDisplay}
                submit={handleSubscribe}
              />
            ) : (
              <Switch>
                <Route
                  key={'search'}
                  path={props.match.url + features[0].path}
                  exact
                  {...props}
                  render={routeProps => {
                    return (
                      <Result
                        queryString={queryString}
                        tags={tags}
                        sector={sector}
                        isLogin={props.isLogin}
                        handleSearch={handleSearch}
                        handleSuggestionClick={handleSuggestionClick}
                        handleTagCancel={handleTagCancel}
                        cleanTag={cleanTag}
                        {...routeProps}
                      />
                    );
                  }}
                />
                {topics.length > 0 &&
                  topics.map(item =>
                    item.childTopics.length > 0 ? (
                      [
                        <Route
                          key={item.id}
                          isLogin={props.isLogin}
                          path={props.match.url + features[1].path + '/' + item.id}
                          exact
                          render={() => (
                            <Latest
                              sector={sector}
                              feature={item.name}
                              tags={tags}
                              switchTopic={switchTopic}
                              queryString={queryString}
                              topicId={item.id}
                              followedTopic={
                                follow.length > 0 &&
                                follow.find(followItem => followItem.rssTopicId === item.id) !==
                                  undefined
                              }
                              isLogin={props.isLogin}
                              handleSubscribe={handleSubscribe}
                              handleSearch={handleSearch}
                              handleSuggestionClick={handleSuggestionClick}
                              handleTagCancel={handleTagCancel}
                            />
                          )}
                        />,
                        item.childTopics.map(child => (
                          <Route
                            key={child.id}
                            isLogin={props.isLogin}
                            path={
                              props.match.url + features[1].path + '/' + item.id + '/' + child.id
                            }
                            exact
                            render={() => (
                              <Latest
                                sector={sector}
                                feature={child.name}
                                topicId={child.id}
                                followedTopic={
                                  follow.length > 0 &&
                                  follow.find(followItem => followItem.rssTopicId === child.id) !==
                                    undefined
                                }
                                tags={tags}
                                switchTopic={switchTopic}
                                queryString={queryString}
                                isLogin={props.isLogin}
                                handleSubscribe={handleSubscribe}
                                handleSearch={handleSearch}
                                handleSuggestionClick={handleSuggestionClick}
                                handleTagCancel={handleTagCancel}
                              />
                            )}
                          />
                        )),
                      ]
                    ) : (
                      <Route
                        key={item.id}
                        isLogin={props.isLogin}
                        path={props.match.url + features[1].path + '/' + item.id}
                        exact
                        render={() => (
                          <Latest
                            sector={sector}
                            feature={item.name}
                            tags={tags}
                            switchTopic={switchTopic}
                            queryString={queryString}
                            topicId={item.id}
                            followedTopic={
                              follow.length > 0 &&
                              follow.find(followItem => followItem.rssTopicId === item.id) !==
                                undefined
                            }
                            isLogin={props.isLogin}
                            handleSubscribe={handleSubscribe}
                            handleSearch={handleSearch}
                            handleSuggestionClick={handleSuggestionClick}
                            handleTagCancel={handleTagCancel}
                          />
                        )}
                      />
                    ),
                  )}

                {/* 若無資料則顯示我的頻道說明頁面 */}
                {customizeSubscribe.length === 0 && (
                  <PrivateRoute
                    isLogin={props.isLogin}
                    // `:id` 提供 for EDM 在未登入情況下，可停留在原 URL。
                    path={props.match.url + features[2].path + '/:id(\\d+)?'}
                    exact
                    component={() => (
                      <Subscribe
                        topicId={EMPTY_RSS[0].rssTopicId}
                        isShare={false}
                        sector={sector}
                        feature={features[2].feature}
                        keyword={EMPTY_RSS[0].keyword}
                        id={EMPTY_RSS[0].id}
                        notification={EMPTY_RSS[0].notification}
                        handleSubscribePaneDisplay={handleSubscribePaneDisplay}
                        handleSubmit={handleSubscribe}
                        subscribeName={EMPTY_RSS[0].name}
                      />
                    )}
                  />
                )}
                {/* 若有資料則顯示我的頻道內容 */}
                {customizeSubscribe.length > 0 &&
                  customizeSubscribe.map(item => (
                    <PrivateRoute
                      key={item.id}
                      isLogin={props.isLogin}
                      path={props.match.url + features[2].path + '/' + item.id}
                      exact
                      component={() => (
                        <Subscribe
                          topicId={item.rssTopicId}
                          isShare={false}
                          sector={sector}
                          feature={features[2].feature}
                          keyword={item.keyword}
                          id={item.id}
                          notification={item.notification}
                          handleSubscribePaneDisplay={handleSubscribePaneDisplay}
                          handleSubmit={handleSubscribe}
                          subscribeName={item.name}
                        />
                      )}
                    />
                  ))}
                {/* 若無資料則顯示他人分享頻道說明頁面 */}
                {shareMeta.length === 0 && (
                  <PrivateRoute
                    isLogin={props.isLogin}
                    path={props.match.url + features[3].path}
                    exact
                    render={() => (
                      <Subscribe
                        topicId={EMPTY_SHARE[0].rssTopicId}
                        isShare={true}
                        sector={sector}
                        feature={features[3].feature}
                        keyword={EMPTY_SHARE[0].keyword}
                        id={EMPTY_SHARE[0].id}
                        notification={EMPTY_SHARE[0].notification}
                        handleSubscribePaneDisplay={handleSubscribePaneDisplay}
                        handleSubmit={handleSubscribe}
                        subscribeName={EMPTY_SHARE[0].name}
                      />
                    )}
                  />
                )}
                {/* 若有資料則顯示他人分享頻道內容 */}
                {shareMeta.length > 0 &&
                  shareMeta.map(item => (
                    <PrivateRoute
                      key={item.id}
                      isLogin={props.isLogin}
                      path={props.match.url + features[3].path + '/' + item.id}
                      exact
                      render={() => (
                        <Subscribe
                          topicId={item.rssTopicId}
                          isShare={true}
                          shareUser={item.systemAccountName}
                          shareUserId={item.systemAccountId}
                          sector={sector}
                          feature={features[3].feature}
                          keyword={item.keyword}
                          id={item.id}
                          notification={item.notification}
                          handleSubscribePaneDisplay={handleSubscribePaneDisplay}
                          handleSubmit={handleSubscribe}
                          subscribeName={item.name}
                        />
                      )}
                    />
                  ))}
                <Route render={() => <Redirect to={props.match.url + features[1].path + '/0'} />} />
              </Switch>
            )}
            {props.isLogin && (
              <SubscribeToolbar
                displayPane={displaySubscribePane}
                toggleDiplayPane={toggleDisplaySubscribePane}
              />
            )}
          </ContentStyle>
        </>
      )}
    </Container>
  );
}

const mapStateToProps = (state: ReduxAppState) => {
  return {
    member: state.memberReducer.memberPayload,
    isLogin: state.memberReducer.authData.isLogin,
  };
};

export default connect(
  mapStateToProps,
  null,
)(NewsLayout);
