import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useRssFeedNews from 'src/hooks/useRssFeedNews';

import { IGetRelatedNews, IGetRssLatest } from 'src/types/api/NewsApiTypes';
import { dateFormatter } from 'src/utils/Formatter';
import ResultHighlighter from 'src/components/result/ResultHighlighter';
import { pink1 } from 'src/style/theme/Color';
import RelatedNews from '../Relate';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import { NEWS_KEYWORDS_NUMBER } from 'src/constants/RssSearchConstants';
import { NewsService } from 'src/api/News';

// Layout
const Container = styled.div`
  padding: 16px 32px;
  border-bottom: 1px solid #eceff1;

  :hover {
    background-color: #f5fdfe;
  }
`;
const TopContainer = styled.div`
  display: flex;
`;

const LeftContainer = styled.div`
  width: 75%;
  padding-right: 16px;
  img {
    height: 24px;
  }
`;
const RightContainer = styled.div`
  padding-top: 32px;
  width: 25%;
  text-align: right;
  img {
    width: 150px;
    height: 100px;
    border-radius: 4px;
    border: 0;
  }
`;

const Title = styled.div`
  padding: 0;
  color: #077790;
  font-size: 18px;
  cursor: pointer;
  text-overflow: ellipsis;
  height: 25px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  * {
    font-size: 18px;
  }
`;

const highlightStyle = {
  color: `${pink1}`,
  backgroundColor: 'transparent',
};

const Content = styled.div`
  padding: 16px 0 0 0;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  color: #263237;
`;
const Info = styled.div`
  padding: 8px 0 0 0;
  font-size: 15px;
  color: #979ea1;
`;

const Entities = require('html-entities').Html5Entities;
const htmlEntities = new Entities();
const XmlEntities = require('html-entities').XmlEntities;
const xmlEntities = new XmlEntities();
const regex = /(<([^>]+)>)/gi;
const newsService = NewsService();

interface IProps {
  newsData: IGetRssLatest;
  searchWords?: Array<string>;
  topicId?: string | null;
}

const LatestItem: React.FC<IProps> = props => {
  const [handleClick] = useRssFeedNews();
  const [relatedNews, setRelatedNews] = useState<Array<IGetRelatedNews>>([]);

  useEffect(() => {
    props.newsData.id &&
      newsService
        .getRelatedNews(props.newsData.id, props.topicId)
        .then(data => data && setRelatedNews(data), error => errorHandler(error));
  }, [props.newsData.id, props.topicId]);

  const { explorerKeywords } = props.newsData;

  return (
    <Container>
      <TopContainer>
        <LeftContainer>
          <img
            src={
              process.env.REACT_APP_API_URL + 'rss/account/' + props.newsData.accountId + '/avatar'
            }
            alt=""
          />
          <Title onClick={() => handleClick(props.newsData.id, props.newsData.link)}>
            <ResultHighlighter
              highlightStyle={highlightStyle}
              searchWords={props.searchWords ? props.searchWords : []}
              textToHighlight={htmlEntities
                .decode(xmlEntities.decode(htmlEntities.decode(props.newsData.title)))
                .replace(regex, '')}
            />
          </Title>
          <Content>
            <ResultHighlighter
              highlightStyle={highlightStyle}
              searchWords={props.searchWords ? props.searchWords : []}
              textToHighlight={htmlEntities
                .decode(xmlEntities.decode(htmlEntities.decode(props.newsData.content)))
                .replace(regex, '')}
            />
          </Content>
          <Info>
            {props.newsData.feedName}
            &nbsp;&nbsp;|&nbsp;&nbsp;
            {dateFormatter(props.newsData.releaseDate)}
          </Info>
          {explorerKeywords && explorerKeywords.length > 0 && (
            <Info>
              關鍵字：
              {explorerKeywords.slice(0, NEWS_KEYWORDS_NUMBER).join('、')}
            </Info>
          )}
        </LeftContainer>
        <RightContainer>
          {props.newsData.imageUrl && <img src={props.newsData.imageUrl} alt="" />}
        </RightContainer>
      </TopContainer>

      {relatedNews.length > 0 && (
        <RelatedNews relatedNews={relatedNews} searchWords={props.searchWords} />
      )}
    </Container>
  );
};

export default LatestItem;
