import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { ultrawhite, ultrawhitergba, ultrablackrgba, whitergba } from 'src/style/theme/Color';
import Tooltip from 'src/components/ui/interactive/Tooltip';
import { FaThLarge, FaThList } from 'react-icons/fa';

const Container = styled.div`
  border-radius: 4px;
  box-shadow: 0 0 50px 0 ${ultrablackrgba(0.1)};
`;
const Header = styled.div`
  height: 88px;
  display: flex;
  flex-flow: row;
  align-items: center;
  color: ${ultrawhite};
  background-image: linear-gradient(95deg, #2ec3c2, #0cafcc);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;
const HeaderText = styled.div`
  padding: 16px 32px;
  margin-right: auto;
`;
const HeaderRoute = styled.div`
  color: ${ultrawhitergba(0.8)};
`;
const Feature = styled.div`
  height: 36px;
  line-height: 36px;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  align-items: flex-end;
  span {
    padding: 0 0 0 8px;
    display: block;
    font-size: 15px;
    line-height: 24px;
    font-weight: normal;
  }
`;
const Content = styled.div`
  padding: 0 0 24px;
  min-height: 450px;
`;

const HeaderRight = styled.div<{ isLoading: boolean }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ${props =>
    props.isLoading &&
    css`
      padding-left: 0;
      padding-right: 0;
    `}
`;
const ViewSwitchBox = styled.div`
  display: flex;
  align-items: center;
  margin-right: 60px;
`;
const ViewModeTooltip = styled(Tooltip)<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  margin: 0 8px;
  svg {
    color: ${props => whitergba(props.isActive ? 1 : 0.6)};
  }
  :hover {
    cursor: pointer;
  }
`;

interface IProps {
  root?: string;
  sector: string;
  feature?: string;
  subtitle?: string;
  subheader?: JSX.Element;
  children: JSX.Element;
  //props中傳入parent方法 isGrid() 來改變parent的state 藉此改變模式
  isGrid: (isGrid: boolean) => void;
}

const VideoContent: React.FC<IProps> = props => {
  const [isLoading] = useState(true);
  const [isGridMode, setIsGridMode] = useState(true);
  return (
    <Container>
      <Header>
        <HeaderText>
          <HeaderRoute>
            {props.root ? props.root : '首頁'}&nbsp;/&nbsp;{props.sector}
            {props.feature && ` / ${props.feature}`}
          </HeaderRoute>
          <Feature>
            {props.feature ? props.feature : props.sector}
            <span>{props.subtitle}</span>
          </Feature>
        </HeaderText>
        <HeaderRight isLoading={isLoading}>
          <ViewSwitchBox>
            <ViewModeTooltip
              placement="top"
              overlay="圖片模式"
              isActive={isGridMode}
              onClick={() => {
                setIsGridMode(true);
                props.isGrid(true);
              }}
            >
              <FaThLarge />
            </ViewModeTooltip>
            <ViewModeTooltip
              placement="top"
              overlay="列表模式"
              isActive={!isGridMode}
              onClick={() => {
                setIsGridMode(false);
                props.isGrid(false);
              }}
            >
              <FaThList />
            </ViewModeTooltip>
          </ViewSwitchBox>
        </HeaderRight>
      </Header>
      {props.subheader}
      <Content>{props.children}</Content>
    </Container>
  );
};
export default VideoContent;
