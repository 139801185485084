import React, { useState } from 'react';
import { Formik, Form, FormikActions } from 'formik';
import { Row, StyledTextareaField } from 'src/style/PatentSearchStyle';
import { RouteComponentProps } from 'react-router-dom';

import { INumberSearch } from 'src/types/form/SearchFormTypes';
import Content from 'src/components/ui/content/Content';
import { Button } from 'src/style/theme/Common';
import { CONDITION_OR, countries } from 'src/constants/PatentSearchConstants';
import { enclose, getFieldPrefix } from 'src/utils/TextUtils';
import { IDropdownProps } from 'src/components/common/Dropdown';
import { SearchDropdown } from 'src/components/search/SearchDropdown';
import { connect } from 'react-redux';
import { composeBasicRequest } from 'src/redux/actions/patentSearchAction';
import { IBasicRequest } from 'src/types/PatentSearchTypes';
import { alertMessage } from 'src/utils/ModalUtils';
import { SystemMessage } from 'src/apps/applicationMessages';
import CountryTabs from 'src/components/search/CountryTabs';

interface IReduxProps {
  composeBasicRequest: (basicRequest: IBasicRequest) => void;
}

interface IProps extends IReduxProps, RouteComponentProps {}

/** 號碼檢索 */
const NumberSearch: React.FC<IProps> = (props: IProps) => {
  const handleSubmit = (values: INumberSearch, actions: FormikActions<INumberSearch>) => {
    actions.setSubmitting(false);

    if (values.patentNumber.trim() === '') {
      alertMessage(SystemMessage.SEARCH_NUMBER_EMPTY);
    } else {
      const patentNumbers = values.patentNumber.trim().split(/,|\n/);
      // 送出request並跳到搜尋結果頁
      props.composeBasicRequest({
        queryString: getFieldPrefix(values.numberType) + enclose(patentNumbers.join(CONDITION_OR)),
        advanceKeywords: [{ keyCode: 'PO', keyword: activeTab }],
      });
      props.history.push('/result');
    }
  };

  // 下拉選單預設選取值
  const [selectedItem, setSelectedItem] = useState({
    numberType: { name: '公開/公告號', value: 'PN' },
  });

  const formInitialValues = {
    numberType: 'PN',
    patentNumber: '',
  };

  const numberTypeItems: IDropdownProps['items'] = [
    { name: '公開/公告號', value: 'PN' },
    { name: '申請號', value: 'AN' },
  ];

  const [activeTab, setActiveTab] = useState(countries[0]);

  return (
    <Content sector="專利檢索" feature="號碼檢索">
      <>
        <CountryTabs activeTab={activeTab} click={tab => setActiveTab(tab)} />
        <Formik
          initialValues={formInitialValues}
          onSubmit={(values, actions) => handleSubmit(values, actions)}
          render={({ setFieldValue }) => (
            <Form>
              <Row>
                <SearchDropdown
                  items={numberTypeItems}
                  activeItem={selectedItem.numberType}
                  handleOnclick={item => {
                    setSelectedItem(prevState => ({
                      ...prevState,
                      numberType: item,
                    }));
                    setFieldValue('numberType', item.value);
                  }}
                  width="30%"
                />
                {/* TODO: React does not recognize the `minHeight` prop on a DOM element. */}
                <StyledTextareaField
                  name="patentNumber"
                  component="textarea"
                  placeholder="多組號碼請使用逗號(半形)或 Enter 折行區隔"
                  width="67%"
                  height="34px"
                  minHeight="34px"
                  padding="5px 16px"
                  resize="vertical"
                />
              </Row>
              <Row justifyContent="flex-start">
                <Button type="submit" template="primary">
                  查詢
                </Button>
                <Button type="reset">清除</Button>
              </Row>
            </Form>
          )}
        />
      </>
    </Content>
  );
};

const mapDispatchToProps = {
  composeBasicRequest,
};

export default connect(
  null,
  mapDispatchToProps,
)(NumberSearch);
