import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Cookies from 'js-cookie';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import _ from 'lodash';

import { LinkOut } from 'src/utils/LinkOut';
import { ScrollToTop } from 'src/components/ui/interactive/ScrollToTop';
import { Button } from 'src/style/theme/Common';
import { zCookieFooter } from 'src/style/theme/Z-Index';

const Container = styled.div`
  z-index: ${zCookieFooter};
  width: 100%;
  bottom: 0;
  position: fixed;
  /* 讓滑鼠穿透此div, 及其內的div, 所以CookieFooter及ScrollToTop須調整回不穿透 */
  pointer-events: none;
`;
const Footer = styled.div<{ isMobile?: boolean }>`
  padding: ${props => (props.isMobile ? '28px 20px' : '28px 120px')};
  width: 100%;
  display: flex;
  margin-top: -85px;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(38, 50, 55, 0.9);
  /* CookieFooter不穿透 */
  pointer-events: auto;
  color: #ffffff;
  a {
    color: #7dd3e2;
  }
  > button {
    flex-shrink: 0;
  }
`;

const ScrollAncher = styled.div`
  width: 100%;
  height: 72px;
  position: relative;
`;

// Cookies Setting
const COOKIE_NAME = '@iPKM:GDPR';
const COOKIE_VALUE = 'accept-gdpr-cookie-policy';
const EXPIRE_DAYS = 366;

const CookieFooter: React.FC<RouteComponentProps> = ({ location }) => {
  const [acceptCookie, setAcceptCookie] = useState<boolean>();
  const [showScrollTop, setShowScrollTop] = useState(false);

  const isMobile = location.pathname.startsWith('/lite');

  useEffect(() => {
    const cookieValue = Cookies.get(COOKIE_NAME);
    const scrollThrottle = _.throttle(() => {
      window.scrollY > 100 ? setShowScrollTop(true) : setShowScrollTop(false);
    }, 300);
    window.addEventListener('scroll', scrollThrottle);

    if (!cookieValue) {
      setTimeout(() => {
        setAcceptCookie(false);
      }, 3000);
    }

    return () => window.removeEventListener('scroll', scrollThrottle);
  }, []);

  return (
    <Container>
      {showScrollTop && (
        <ScrollAncher>
          <ScrollToTop showCookie={!acceptCookie} />
        </ScrollAncher>
      )}
      {acceptCookie !== undefined && !acceptCookie && (
        <Footer isMobile={isMobile}>
          <div>
            我們使用 Cookie 來瞭解您如何使用我們的網站並改善您的體驗。
            如繼續使用我們的網站，即表示您接受我們使用 Cookies。請參閱{' '}
            <Link to="/cookie-policy">Cookies 政策</Link>及
            <LinkOut
              href="https://www.tipo.gov.tw/ct.asp?xItem=155007&ctNode=7091&mp=1"
              name="隱私權宣告"
            />
            。
          </div>
          <Button
            template="primary"
            margin="0 0 0 16px"
            onClick={() => {
              Cookies.set(COOKIE_NAME, COOKIE_VALUE, {
                expires: EXPIRE_DAYS,
              });
              setAcceptCookie(true);
            }}
          >
            同意使用
          </Button>
        </Footer>
      )}
    </Container>
  );
};

export default withRouter(CookieFooter);
