import React, { useState, useEffect } from 'react';
import { FaPlus } from 'react-icons/fa';
import _ from 'lodash';

import StarsRating from 'src/components/ui/interactive/rating/StarsRating';
import Checkbox from 'src/components/ui/interactive/checkbox/Checkbox';
import DeleteModal from 'src/components/ui/interactive/modal/custom/DeleteModal';
import { IGetPatentCollectionCategoryTypes } from 'src/types/api/PatentApiTypes';
import { Button } from 'src/style/theme/Common';
import {
  ToolbarPaneContainer,
  ToolbarPaneBodyDiv,
  ToolbarPaneTitle,
  ToolbarPaneField,
  ToolbarPaneScrollDiv,
  ToolbarPaneFooterDiv,
  ToolbarPaneDescription,
  ToolbarPaneButtonArea,
  Stars,
  InputText,
} from 'src/style/PatentContentStyle';
import { alertMessage } from 'src/utils/ModalUtils';
import { SystemMessage } from 'src/apps/applicationMessages';
import { Auth } from 'src/apps/auth/Auth';
import { connect } from 'react-redux';
import { ReduxAppState } from 'src/redux/reducers';
import { openCustomModal, closeCustomModal } from 'src/redux/actions/modalAction';
import { CustomModalType } from 'src/types/ModalTypes';

interface IReduxMappingProps {
  isDeleteCategoryModalOpen: boolean;
  openCustomModal: (customModalType: CustomModalType) => void;
  closeCustomModal: () => void;
}

interface IProps extends IReduxMappingProps {
  activePane: string;
  origRratingStars: number;
  origCollectionCat: IGetPatentCollectionCategoryTypes[] | null;
  newCat: string;
  handleAddCategory: (input: string) => void;
  handleCollectionSubmit: (
    submitRating: number,
    submitCollection: IGetPatentCollectionCategoryTypes[],
  ) => void;
  toggleActivePane: (pane: string) => void;
}

function ToolbarCollectionPane(props: IProps) {
  let [ratingStars, setRatingStars] = useState(0);
  let [collectionCat, setCollectionCat] = useState<IGetPatentCollectionCategoryTypes[]>([]);
  let [categoryInput, setCategoryInput] = useState('');
  let [inputErrorMsg, setInputErrorMsg] = useState('');

  useEffect(() => {
    setRatingStars(props.origRratingStars);
    setCollectionCat(props.origCollectionCat ? props.origCollectionCat : []);
    setCategoryInput('');
  }, [props.activePane, props.origRratingStars, props.origCollectionCat]);

  useEffect(() => {
    setRatingStars(props.origRratingStars);
  }, [props.origRratingStars]);

  useEffect(() => {
    if (props.origCollectionCat) {
      let clonArr = _.cloneDeep(props.origCollectionCat);
      //若有剛新增的標籤則預設打勾
      props.newCat !== '' &&
        clonArr.forEach(item => {
          if (item.name === props.newCat) {
            item.collected = true;
          }
        });
      setCollectionCat(clonArr);
    }
  }, [props.origCollectionCat, props.newCat]);

  const handleCollectionCheckbox = (idx: number) => {
    let collectionCatArr = collectionCat;
    collectionCatArr[idx].collected = !collectionCatArr[idx].collected;
    setCollectionCat([...collectionCatArr]);
  };

  const handleAdd = () => {
    const limit =
      Auth().getPayload() && Auth().getPayload().permissionLimits.PATENT_COLLECTION.CATEGORY_UPPER;
    if (categoryInput.trim() === '') {
      setInputErrorMsg('未輸入分類名稱');
    } else if (collectionCat.length === limit) {
      setInputErrorMsg('最多建立' + limit + '組分類，已達上限');
    } else {
      props.handleAddCategory(categoryInput);
    }
  };

  const handleSubmit = () => {
    const origIsCollect =
      props.origCollectionCat && 0 < props.origCollectionCat.filter(item => item.collected).length;
    const nowIsCollect = 0 < collectionCat.filter(item => item.collected).length;
    if (origIsCollect && !nowIsCollect) {
      //若原本有蒐藏、現在未選取任何分類，顯示確認刪除通知
      props.openCustomModal(CustomModalType.DELETE_CATEGORY);
    } else if (!origIsCollect && !nowIsCollect) {
      //若原本沒蒐藏、現在也未選取任何分類，顯示請選擇分類的提示
      alertMessage(SystemMessage.PATENT_COLLECTION_CATEGORY_UNSELECTED);
    } else {
      props.handleCollectionSubmit(ratingStars, collectionCat);
    }
  };

  const handleDelete = () => {
    props.handleCollectionSubmit(ratingStars, collectionCat);
    props.closeCustomModal();
  };

  const reset = () => {
    setRatingStars(props.origRratingStars);
    setCollectionCat(props.origCollectionCat ? props.origCollectionCat : []);
    setCategoryInput('');
    props.toggleActivePane('');
  };

  return (
    <ToolbarPaneContainer offset="18px">
      <ToolbarPaneBodyDiv>
        <ToolbarPaneTitle>收藏專利</ToolbarPaneTitle>
        <ToolbarPaneField>
          <p>為此專利評等</p>
          <Stars>
            <StarsRating stars={ratingStars} setRatingStars={setRatingStars} />
          </Stars>
        </ToolbarPaneField>
        <ToolbarPaneField>
          <p>選擇收藏分類</p>
          <ToolbarPaneScrollDiv maxHeight="calc(100vh - 480px)">
            {collectionCat.length === 0 ? (
              <ToolbarPaneDescription>尚未新增任何收藏分類。</ToolbarPaneDescription>
            ) : (
              collectionCat.map((item, idx) => (
                <Checkbox
                  key={item.id}
                  name={item.name}
                  checked={item.collected}
                  color="#ffffff"
                  themeReverse={true}
                >
                  <input
                    type="checkbox"
                    value={item.id}
                    checked={item.collected}
                    onChange={() => handleCollectionCheckbox(idx)}
                  />
                </Checkbox>
              ))
            )}
          </ToolbarPaneScrollDiv>
        </ToolbarPaneField>
        <ToolbarPaneField>
          <p>新增分類</p>
          <InputText withButton={true} error={inputErrorMsg !== ''}>
            <input
              type="text"
              className="withButton"
              value={categoryInput}
              placeholder="請輸入分類名稱..."
              onChange={e => {
                setCategoryInput(e.currentTarget.value);
                inputErrorMsg !== '' && setInputErrorMsg('');
              }}
            />
            <span onClick={handleAdd}>
              <FaPlus size="15px" />
            </span>
            {inputErrorMsg !== '' && <p>{inputErrorMsg}</p>}
          </InputText>
        </ToolbarPaneField>
      </ToolbarPaneBodyDiv>
      <ToolbarPaneFooterDiv>
        <ToolbarPaneButtonArea>
          <Button template="primary" onClick={handleSubmit}>
            收藏
          </Button>
          <Button template="light" onClick={() => reset()}>
            取消
          </Button>
        </ToolbarPaneButtonArea>
        <ToolbarPaneDescription>
          可至「專利專區」&gt;「我的收藏」內查看所有收藏，可依照評等排序。
        </ToolbarPaneDescription>
      </ToolbarPaneFooterDiv>
      {props.isDeleteCategoryModalOpen && (
        <DeleteModal
          message="刪除收藏後，您已分享的筆記與已註記的標籤均會消失，請問是否要刪除收藏？"
          description="若重新收藏此篇專利，已註記的筆記與標籤仍會恢復資料。"
          submit={handleDelete}
        />
      )}
    </ToolbarPaneContainer>
  );
}

const mapStateToProps = (state: ReduxAppState) => {
  return {
    isDeleteCategoryModalOpen:
      state.modalReducer.customModalType === CustomModalType.DELETE_CATEGORY,
  };
};

const mapDispatchToProps = {
  openCustomModal,
  closeCustomModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ToolbarCollectionPane);
