import React, { useState } from 'react';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import { connect } from 'react-redux';
import { appendClusterKeyword } from 'src/redux/actions/patentSearchAction';
import {
  Container,
  ClusterHeader,
  ClusterTitle,
  CollapseButton,
  ClusterPanel,
} from 'src/style/AccordionStyle';
import { clusterMapColors } from 'src/style/theme/Color';
import { ICluster } from 'src/types/PatentSearchTypes';
import ClusterBox from './ClusterAccordionBox';

interface IReduxMappingProps {
  appendClusterKeyword: (clusterKeyword: string) => void;
}

interface IProps extends IReduxMappingProps {
  content: ICluster;
  colorIndex: number;
}

/** 聚類分析-摺疊面板 */
const ClusterAccordion: React.FC<IProps> = props => {
  const { content, colorIndex, appendClusterKeyword } = props;
  const [isActive, setIsActive] = useState(true);
  const togglePanel = (e: any) => {
    e.stopPropagation();
    setIsActive(!isActive);
  };
  const textColor =
    colorIndex !== undefined && clusterMapColors[colorIndex]
      ? clusterMapColors[colorIndex].txt
      : undefined;
  return (
    <Container>
      <ClusterHeader color={textColor} onClick={() => appendClusterKeyword(content.keyword)}>
        <ClusterTitle>{content.keyword}</ClusterTitle>
        {content.subClusters && (
          <CollapseButton onClick={e => togglePanel(e)}>
            {isActive ? <FaAngleUp /> : <FaAngleDown />}
          </CollapseButton>
        )}
      </ClusterHeader>
      {content.subClusters && (
        <ClusterPanel isShow={isActive}>
          {content.subClusters.map(subCluster => (
            <ClusterBox
              key={subCluster.keyword}
              content={subCluster}
              appendKeyword={appendClusterKeyword}
            />
          ))}
        </ClusterPanel>
      )}
    </Container>
  );
};

const mapDispatchToProps = {
  appendClusterKeyword,
};

export default connect(
  null,
  mapDispatchToProps,
)(ClusterAccordion);
