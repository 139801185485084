import React, { useState, useEffect, useRef, useCallback } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import axios from 'axios';
import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import {
  IGetRssLatest,
  IAdvancedKeyword,
  IRssFacet,
  IRssSearchSuggestion,
  IFacetFieldCount,
} from 'src/types/api/NewsApiTypes';

import LatestItem from './item/LatestItem';
import { Spinner } from 'src/components/ui/interactive/Spinner';
import InfiniteScroll from 'src/components/ui/interactive/InfiniteScroll';
import ResultContent from 'src/components/ui/content/News/ResultContent';
import { GaSearchCategory, pushGaSiteSearchEvent } from 'src/utils/GoogleAnalytics';
import { isNotEmpty } from 'src/utils/TextUtils';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router-dom';

const NoResult = styled.div`
  padding: 16px 32px;
  border-bottom: 1px solid #eceff1;
  align-items: left;
  display: flex;
  :hover {
    background-color: #f5fdfe;
  }
`;

const SOLR_QUERY_REGEX = / OR | NOT | AND |"|\(|\)|\*|\+|:\d*|&|\[|\]|\?|~|\^|-|!|\|/g;

interface IProps extends RouteComponentProps {
  sector: string;
  isLogin: boolean;
  queryString: string;
  tags: Array<IRssSearchSuggestion>;
  handleSearch: (queryString: string) => void;
  handleSuggestionClick: (suggestion?: IRssSearchSuggestion) => void;
  handleTagCancel: (type: string, value: string) => void;
  cleanTag: () => void;
}

function Result(props: IProps) {
  const topicId: string | undefined =
    props.history.location.state && props.history.location.state.stateId;
  const { tags, cleanTag, handleSearch: propsHandleSearch } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isFacetLoading, setIsFacetLoading] = useState(false);
  const [newsList, setNewsList] = useState<Array<IGetRssLatest>>([]);
  const [advanceKeywords, setAdvanceKeywords] = useState<Array<IAdvancedKeyword>>([]);
  const [facets, setFacets] = useState<Array<IRssFacet>>([]);

  const [pageNo, setPageNo] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [isNextPageLoading, setIsNextPageLoading] = useState<boolean>(false);
  const [isNextPageError, setIsNextPageError] = useState<boolean>(false);
  const [searchWords, setSearchWords] = useState<Array<string>>([]);

  const initFinished = useRef<boolean>(false);

  const topicTag = tags.find(tag => tag.type === 'TOPIC');
  const sourceTag = tags.find(tag => tag.type === 'SOURCE');

  const loadClickHandler = useCallback(() => {
    unstable_batchedUpdates(() => {
      setIsNextPageLoading(true);
      setPageNo(prev => prev + 1);
    });
  }, []);

  const reloadClickHandler = useCallback(() => {
    if (pageNo === 1) {
      setIsLoading(true);
      setNewsList([]);
    } else {
      setIsNextPageLoading(true);
    }
  }, [pageNo]);

  useEffect(() => {
    if (!initFinished.current) {
      !topicId && cleanTag();
      initFinished.current = true;
      setIsLoading(true);
      setIsFacetLoading(true);
    }
  }, [topicId, cleanTag]);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const fetchData = async () => {
      // 送出 GA 新聞搜尋事件(有使用關鍵字查詢才記錄)
      isNotEmpty(props.queryString) &&
        pushGaSiteSearchEvent(GaSearchCategory.NEWS, props.queryString);

      try {
        const res = await Api().post(
          '/rss/topic/search',
          {
            topicId: topicTag ? topicTag.value : null,
            ids: sourceTag ? [sourceTag.value] : null,
            pageNo: pageNo,
            paginationSize: 20,
            queryString: props.queryString,
            advanceKeywords: advanceKeywords,
          },
          { cancelToken: source.token },
        );

        let newSearchWords: Array<string> = [];

        // 使用者搜尋條件
        newSearchWords.push(...props.queryString.replace(SOLR_QUERY_REGEX, ' ').split(' '));
        // 進階篩選
        advanceKeywords.length > 0 &&
          newSearchWords.push(
            ...advanceKeywords.filter(adk => adk.keyCode !== 'DATE').map(adk => adk.keyword),
          );
        // 新聞主題搜尋條件
        if (topicTag) {
          topicTag.queryString &&
            newSearchWords.push(...topicTag.queryString.replace(SOLR_QUERY_REGEX, ' ').split(' '));
          topicTag.sources &&
            topicTag.sources.length > 0 &&
            newSearchWords.push(...topicTag.sources);
        }
        // 來源 tag
        sourceTag && newSearchWords.push(sourceTag.displayName);

        setSearchWords(newSearchWords.filter(word => word.length > 0));
        setNewsList(prev => [...prev, ...res.data.data]);
        setTotalPages(res.data.totalPages);
        setTotalCount(res.data.total);
      } catch (error) {
        errorHandler(error);
        isNextPageLoading && setIsNextPageError(true);
      } finally {
        setIsLoading(false);
        setIsNextPageLoading(false);
      }
    };
    initFinished.current && (isLoading || isNextPageLoading) && fetchData();

    return () => source.cancel();
  }, [
    isLoading,
    isNextPageLoading,
    pageNo,
    props.queryString,
    advanceKeywords,
    topicTag,
    sourceTag,
  ]);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const fetchData = async () => {
      try {
        const res = await Api().post(
          '/rss/facet/search',
          {
            topicId: topicTag ? topicTag.value : null,
            ids: sourceTag ? [sourceTag.value] : null,
            paginationSize: 0,
            queryString: props.queryString,
            advanceKeywords: advanceKeywords,
          },
          { cancelToken: source.token },
        );

        setFacets(res.data);
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsFacetLoading(false);
      }
    };

    initFinished.current && isFacetLoading && fetchData();

    return () => source.cancel();
  }, [isFacetLoading, props.queryString, advanceKeywords, topicTag, sourceTag]);

  const handleSearch = useCallback(
    (queryString: string) => {
      propsHandleSearch(queryString);
      setNewsList([]);
      setPageNo(1);
      setAdvanceKeywords([]);
      setIsLoading(true);
      setIsFacetLoading(true);
    },
    [propsHandleSearch],
  );

  const handleFacetClick = useCallback(
    (keyCode: string, facetFieldCount?: IFacetFieldCount) => {
      setNewsList([]);
      setPageNo(1);

      if (facetFieldCount) {
        advanceKeywords
          ? setAdvanceKeywords(prev => [
              ...prev,
              {
                keyCode: keyCode,
                keyword: facetFieldCount.name,
                identity: facetFieldCount.identity,
              },
            ])
          : setAdvanceKeywords([
              {
                keyCode: keyCode,
                keyword: facetFieldCount.name,
                identity: facetFieldCount.identity,
              },
            ]);
      } else {
        setAdvanceKeywords(prev => prev.filter(item => item.keyCode !== keyCode));
      }
      setIsLoading(true);
      setIsFacetLoading(true);
    },
    [advanceKeywords],
  );

  return (
    <ResultContent
      sector={props.sector}
      queryString={props.queryString}
      search={handleSearch}
      facets={facets}
      isFacetLoading={isFacetLoading}
      handleFacetClick={handleFacetClick}
      handleSuggestionClick={props.handleSuggestionClick}
      advanceKeywords={advanceKeywords}
      tags={props.tags}
      handleTagCancel={props.handleTagCancel}
    >
      <>
        {!initFinished.current || isLoading ? (
          <Spinner width="150px" />
        ) : newsList.length > 0 ? (
          <InfiniteScroll
            isNextPageLoading={isNextPageLoading}
            isNextPageError={isNextPageError}
            errorMessage={'系統發生異常'}
            handleNextPageLoad={loadClickHandler}
            handleReload={reloadClickHandler}
            totalCount={totalCount}
            currentPage={pageNo}
            totalPages={totalPages}
          >
            {newsList.map(news => (
              <LatestItem
                key={news.id}
                newsData={news}
                searchWords={searchWords}
                topicId={topicTag ? topicTag.value : null}
              />
            ))}
          </InfiniteScroll>
        ) : (
          <NoResult>無新聞訊息</NoResult>
        )}
      </>
    </ResultContent>
  );
}

export default Result;
