import { IAlertModal, IRedirectModal } from 'src/types/ModalTypes';
import { LoginModalType, CustomModalType } from 'src/types/ModalTypes';

// ALERT MODAL
export const OPEN_ALERT_MODAL = 'OPEN_ALERT_MODAL';
export const CLOSE_ALERT_MODAL = 'CLOSE_ALERT_MODAL';
// LOGIN MODAL
export const OPEN_LOGIN_ALERT_MODAL = 'OPEN_LOGIN_ALERT_MODAL';
export const OPEN_LOGIN_FORM_MODAL = 'OPEN_LOGIN_FORM_MODAL';
export const CLOSE_LOGIN_MODAL = 'CLOSE_LOGIN_MODAL';
// CUSTOM MODAL
export const OPEN_CUSTOM_MODAL = 'OPEN_CUSTOM_MODAL';
export const CLOSE_CUSTOM_MODAL = 'CLOSE_CUSTOM_MODAL';
// REDIRECT MODAL
export const OPEN_REDIRECT_MODAL = 'OPEN_REDIRECT_MODAL';
export const CLOSE_REDIRECT_MODAL = 'CLOSE_REDIRECT_MODAL';

export interface ModalState {
  alertModals: Array<IAlertModal>;
  loginModal: {
    type: LoginModalType;
    isFromRoute: boolean;
  };
  customModalType: CustomModalType;
  customModalId?: number;
  redirectModal?: IRedirectModal;
}

interface OpenAlertModalAction {
  type: typeof OPEN_ALERT_MODAL;
  alertModal: IAlertModal;
}

interface CloseAlertModalAction {
  type: typeof CLOSE_ALERT_MODAL;
}

interface OpenLoginAlertModalAction {
  type: typeof OPEN_LOGIN_ALERT_MODAL;
  isFromRoute: boolean;
}

interface OpenLoginFormModalAction {
  type: typeof OPEN_LOGIN_FORM_MODAL;
}

interface CloseLoginModalAction {
  type: typeof CLOSE_LOGIN_MODAL;
}

interface OpenCustomModalAction {
  type: typeof OPEN_CUSTOM_MODAL;
  customModalType: CustomModalType;
  customModalId?: number;
}

interface CloseCustomModalAction {
  type: typeof CLOSE_CUSTOM_MODAL;
}

interface OpenRedirectModalAction {
  type: typeof OPEN_REDIRECT_MODAL;
  redirectModal: IRedirectModal;
}

interface CloseRedirectModalAction {
  type: typeof CLOSE_REDIRECT_MODAL;
  shouldRedirect: boolean;
}

export type ModalActionTypes =
  | OpenAlertModalAction
  | CloseAlertModalAction
  | OpenLoginAlertModalAction
  | OpenLoginFormModalAction
  | CloseLoginModalAction
  | OpenCustomModalAction
  | CloseCustomModalAction
  | OpenRedirectModalAction
  | CloseRedirectModalAction;
