import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import * as d3 from 'd3';
import _ from 'lodash';

import Tooltip from 'src/components/result/right-toolbar/statistics/chart/Tooltip';
import { wrap } from 'src/components/result/right-toolbar/statistics/chart/chartHelper';
import {
  blue5,
  clusterMapColors,
  clusterMapDefaultColor,
  clusterMapLv2Color,
  clusterMapLv3Color,
} from 'src/style/theme/Color';
import { IClusterMap } from 'src/types/PatentSearchTypes';

const ToolTipOverlay = styled.div`
  text-align: center;
  color: ${blue5};
  max-width: 180px;
`;

interface IProps {
  mapData: IClusterMap;
  toolTipText: string;
}
interface ITooltip {
  left: number;
  top: number;
  text: string;
}

/** 知識地圖 */
const ClusterTree: React.FC<IProps> = props => {
  const [data, setData] = useState<IClusterMap>();
  const [tooltip, setTooltip] = useState<ITooltip>();
  const svgRef = useRef<SVGSVGElement>(null);
  // 畫布設定
  const width = 246;
  const height = 260;
  const margin = 16;
  // 圓形半徑
  const radius = 24;
  const centerRadius = 36;
  // 連接線
  const lineColor = '#d0d4d6';
  const lineWidth = 1;
  // 動畫持續時間
  const duration = 500;

  useEffect(() => {
    setData(findData(props.mapData));
  }, [props.mapData]);

  useEffect(() => {
    // 有資料才繪圖
    if (data) {
      const lv1Words = props.mapData.children ? props.mapData.children.map(item => item.text) : [];
      const getColor = (d: d3.HierarchyNode<IClusterMap>) => {
        let color = clusterMapDefaultColor;
        if (d.data.level === 1) {
          const index = lv1Words.findIndex(word => word === d.data.text);
          index >= 0 && (color = clusterMapColors[index]);
        } else if (d.data.level === 2) {
          color = clusterMapLv2Color;
        } else if (d.data.level === 3) {
          color = clusterMapLv3Color;
        }
        return color;
      };

      const getRadius = (d: d3.HierarchyNode<IClusterMap>) => {
        return d.depth === 0 ? centerRadius : radius;
      };

      const getFontSize = (d: d3.HierarchyNode<IClusterMap>) => {
        let fontSize = '15px';
        const length = d.data.text ? d.data.text.length : 0;
        if (d.depth !== 0 && length > 4) {
          fontSize = '12px';
        }
        if (d.depth !== 0 && length > 6) {
          fontSize = '10px';
        }
        return fontSize;
      };

      const wrapText = (e: d3.BaseType) => {
        const length = d3.select(e).text().length;
        let patternSize = 4;
        switch (length) {
          // 標籤為4個字時2字一行
          case 4:
            patternSize = 2;
            break;
          // 標籤為5或6個字時3字一行
          case 5:
          case 6:
            patternSize = 3;
            break;
          // 其餘預設4字一行
          default:
            break;
        }
        // 斷行產生<tspan>後還會視需求縮小字體
        // 故寬度不帶入圓圈直徑, 用預設字寬15px*每行字數+2px空間計算
        const width = patternSize * 15 + 2;

        wrap(d3.select(e), width, patternSize);
      };

      const svg = d3
        .select(svgRef.current)
        .append('g')
        .attr('class', 'root')
        .attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')');

      const treeLayout: d3.TreeLayout<any> = d3
        .tree()
        .size([360, width / 2 - margin - radius]) // 樹狀圖展開角度及半徑
        .separation(function(a, b) {
          return (a.parent === b.parent ? 1 : 2) / a.depth;
        });

      // 用hierarchy()在原始資料的每個node自動加上樹狀圖所需屬性
      let root = d3.hierarchy(data, function(d) {
        return d.children;
      });
      root.children && root.children.filter(item => item);
      root = treeLayout(root); // 將資料放進layout

      const line = svg
        .append('g')
        .attr('class', 'line')
        .selectAll('line')
        .data(root.links())
        .enter()
        .append('line')
        .attr('stroke-width', lineWidth)
        .attr('stroke', lineColor);

      const node = svg
        .selectAll('.node')
        .data(root.descendants())
        .enter()
        .append('g')
        .attr('class', 'node')
        .style('cursor', d => d.data.level !== 0 && d.data.level !== 3 && 'pointer');

      // 繪製圓圈
      node
        .append('circle')
        .attr('r', d => getRadius(d))
        .style('fill', d => getColor(d).bg);

      // 繪製文字
      node
        .append('text')
        .text(d => d.data.text)
        .attr('text-anchor', 'middle')
        .style('user-select', 'none')
        .attr('fill', d => getColor(d).txt)
        // 標籤垂直置中: 單行標籤(1~3字)下移3px, 多行標籤上移3px
        .attr('y', (d: any) => (d.data.text && d.data.text.length > 3 ? -3 : 3));

      // 文字斷行及字體調整
      node.selectAll('text').each(function(this: d3.BaseType) {
        wrapText(this);
      });
      node.selectAll('tspan').style('font-size', (d: any) => getFontSize(d));

      // 綁定事件
      node
        .on('mouseenter', function(d: d3.HierarchyNode<IClusterMap>) {
          d3.select(this)
            .select('circle')
            .attr('r', getRadius(d) * 1.1);
          // 用DOMMatrix取得svg的絕對位置並計算tooltip座標
          const svgMatrix = svgRef.current && svgRef.current.getScreenCTM();
          if (d.data.level === 0) {
            setTooltip({
              left: svgMatrix ? svgMatrix.e + width / 2 : 0,
              top: svgMatrix ? svgMatrix.f + height / 2 - 24 : 0,
              text: props.toolTipText,
            });
          }
        })
        .on('mouseleave', function(d: d3.HierarchyNode<IClusterMap>) {
          d3.select(this)
            .select('circle')
            .attr('r', getRadius(d));
          setTooltip(undefined);
        })
        .on('click', d => handleClick(d));

      const setPosition = () => {
        line
          .style('opacity', '0')
          .attr('transform', (d: any) => 'rotate(' + (-d.target.x - 45) + ')')
          .transition()
          .duration(duration)
          .style('opacity', '1')
          .attr('x1', (d: any) => d.source.y)
          .attr('y1', (d: any) => (d.source.x / 180) * Math.PI)
          .attr('x2', (d: any) => d.target.y)
          .attr('y2', (d: any) => (d.target.x / 180) * Math.PI);
        node
          .style('opacity', '0')
          .transition()
          .duration(duration)
          .style('opacity', '1')
          .attr(
            'transform',
            (d: any) =>
              'rotate(' + (-d.x - 45) + ')translate(' + d.y + ')rotate(' + (d.x + 45) + ')',
          );
      };

      const handleClick = (d: d3.HierarchyNode<IClusterMap>) => {
        // 可回到上層或進入下層時才顯示動畫
        if (d.data.level !== 0 && d.data.level !== 3) {
          line
            .merge(line.selectAll('line'))
            .transition()
            .duration(duration)
            .style('opacity', '0')
            .attr('x1', 0)
            .attr('y1', 0)
            .attr('x2', 0)
            .attr('y2', 0);
          node
            .merge(node.selectAll('.node'))
            .transition()
            .duration(duration)
            .attr('transform', 'translate(0,0)')
            .style('opacity', '0');
          svg
            .transition()
            .duration(duration)
            .remove()
            .on('end', () => setData(findData(props.mapData, d)));
        }
      };
      setPosition();
    }
  }, [data, props.mapData, props.toolTipText]);
  return (
    <>
      <svg ref={svgRef} width={width} height={height} />
      {tooltip && tooltip.text !== '' && (
        <Tooltip
          left={tooltip.left}
          top={tooltip.top}
          overlay={
            <ToolTipOverlay>
              <div>{tooltip.text}</div>
            </ToolTipOverlay>
          }
        />
      )}
    </>
  );
};

/** 從原始資料中取出需繪製的資料 */
const findData = (mapData: IClusterMap, d?: d3.HierarchyNode<IClusterMap>): IClusterMap => {
  const target = d ? d.data.text : mapData.text;
  let _mapData = _.cloneDeep(mapData);
  let result;
  const lv1 = _mapData.children || [];
  // 點擊周邊圓圈(1,2階關鍵字)進下一層
  if (d && d.depth !== 0 && d.data.level === 1) {
    result = lv1.find(item => item.text === target);
  } else if (d && d.depth !== 0 && d.data.level === 2) {
    const lv1Item = lv1.find(item => item.text === d.data.parentText);
    const lv2 = (lv1Item && lv1Item.children) || [];
    result = lv2.find(item => item.text === target);
  }
  // 點擊中間圓圈(1,2階關鍵字)回上一層
  else if (d && d.depth === 0 && d.data.level === 1) {
    result = _mapData;
  } else if (d && d.depth === 0 && d.data.level === 2) {
    result = lv1.find(item => item.text === d.data.parentText);
  }
  result && (_mapData = result);
  // 移除不顯示的children
  _mapData.children &&
    _mapData.children.forEach(child => {
      child.children = undefined;
    });
  return _mapData;
};
export default ClusterTree;
