export enum ErrorCode {
  DATA_FETCH_RESPONSE_EMPTY = 'No Data',
  DATA_FETCH_RESPONSE_EMPTY_ARRAY = 'No Data',
  UNHANDLED_CASE_IN_SWITCH = 'Unhandled Case',
  PARAMETER_MISSING = 'Parameter Missing',
}

// TBD: To Be Discussed
export const getErrorMsg = (errCode: string) => {
  switch (errCode) {
    case 'ip.changed':
      return 'IP 變更，新重新登入';
    case 'account.confirm.necessary':
      return '此帳號未啟用，請至個人信箱收取確認信';
    case 'account.confirm.unnecessary':
      return '會員已認證過';
    case 'account.confirm.expired':
      return '超過會員帳號認證時間';
    case 'parameter.missing':
      return '缺少必要參數';
    case 'ip.invalid':
      return '您所在的區域無法使用此系統';
    case 'captcha.expired':
      return '驗證碼過期';
    case 'captcha.mismatch':
      return '驗證碼錯誤';
    case 'login.failed':
      return '帳號或密碼錯誤';
    case 'login.tiponet.serial.number.missing':
      return '請至智慧財產權 e 網通網站上傳憑證';
    case 'account.disabled':
      return '此帳號已停權';
    case 'url.notFound':
      return '網址不存在';
    case 'permission.forbidden':
      return '您沒有使用此功能的權限';
    case 'auth.token.missing':
      return '缺少認證 token';
    case 'auth.token.expired':
      return '認證 token 已過期';
    case 'auth.token.invalid':
      return '認證 token 不合法';
    case 'exception.unknown':
      return '系統發生異常';
    case 'email.notExisted':
      return 'email 不存在';
    case 'http.method.notAllowed':
      return 'Http method 不允許';
    case 'rss.follow.exceed.upper':
      return '自訂頻道超過上限';
    case 'rss.follow.duplicated':
      return '自訂頻道重複';
    case 'mail.send.failed':
      return '發信失敗';
    case 'text.search.by.document.error':
      return '以文找文上傳檔案出現異常。';
    case 'text.PatentSubscription.size.max':
      return '專利情報訂閱已達上限。';
    case 'text.PatentSubscription.name.duplicated':
      return '專利情報訂閱名稱重複。';
  }
};
