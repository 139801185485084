import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FaPlus } from 'react-icons/fa';
import _ from 'lodash';

import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import { alertMessage } from 'src/utils/ModalUtils';
import { IGetPatentTagTypes } from 'src/types/api/PatentApiTypes';

import useOutSideClick from 'src/hooks/useOutSideClick';
import Checkbox from 'src/components/ui/interactive/checkbox/Checkbox';
import { Button } from 'src/style/theme/Common';
import {
  InputText,
  ToolbarPaneField,
  ToolbarPaneScrollDiv,
  ToolbarPaneDescription,
} from 'src/style/PatentContentStyle';
import { SystemMessage } from 'src/apps/applicationMessages';

const Container = styled.div`
  width: 304px;
  border-radius: 4px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  padding: 32px;
  padding-top: 16px;
  cursor: auto;
  z-index: 20;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: #263237;
  margin-bottom: 16px;
`;

const Footer = styled.div`
  text-align: center;
`;

interface IProps {
  show: boolean;
  setShow: (editTag: boolean) => void;
  patentDataId: number;
  patentTags: Array<string>;
  submitEditTags: () => void;
}

function EditTag(props: IProps) {
  const [usedTags, setUsedTags] = useState<Array<IGetPatentTagTypes>>([]);
  const [patentTags, setPatentTags] = useState<Array<string>>(() => _.cloneDeep(props.patentTags));
  useEffect(() => {
    const fetchUsedTags = async () => {
      try {
        const res = await Api().get('patent/tag');
        setUsedTags(res.data);
      } catch (error) {
        errorHandler(error);
      }
    };

    fetchUsedTags();
  }, []);

  const [newTag, setNewTag] = useState('');
  const node = useOutSideClick(props);

  const handleSubmit = async () => {
    try {
      await Api().put(`patent/tag/${props.patentDataId}`, patentTags);
      props.submitEditTags();
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleAddNewTag = () => {
    const trimmedNewTag = newTag.trim();
    if (trimmedNewTag === '') {
      alertMessage(SystemMessage.PATENT_COLLECTION_TAG_EMPTY);
    } else {
      setUsedTags([
        ...usedTags,
        { id: -1, systemAccountId: -1, tag: trimmedNewTag, createTime: -1 },
      ]);
      setPatentTags([...patentTags, trimmedNewTag]);
      setNewTag('');
    }
  };

  return (
    <Container ref={node as any}>
      <Title>管理標籤</Title>
      <InputText withButton={true} error={false}>
        <input
          type="text"
          placeholder="搜尋/新增標籤"
          value={newTag}
          onChange={event => setNewTag(event.target.value)}
        />
        <span onClick={handleAddNewTag}>
          <FaPlus size="15px" />
        </span>
      </InputText>
      <ToolbarPaneField>
        <p>曾使用的標籤</p>
        <ToolbarPaneScrollDiv maxHeight="calc(100vh - 480px)">
          {usedTags.length === 0 ? (
            <ToolbarPaneDescription>尚未新增任何收藏分類。</ToolbarPaneDescription>
          ) : (
            usedTags.map((item, idx) => (
              <Checkbox name={item.tag} checked={patentTags.includes(item.tag)} key={item.tag}>
                <input
                  id={item.tag}
                  type="checkbox"
                  name={item.tag}
                  checked={patentTags.includes(item.tag)}
                  onChange={event => {
                    const index = patentTags.findIndex(item => item === event.target.name);

                    if (index === -1) setPatentTags([...patentTags, event.target.name]);
                    else {
                      patentTags.splice(index, 1);
                      setPatentTags([...patentTags]);
                    }
                  }}
                />
              </Checkbox>
            ))
          )}
        </ToolbarPaneScrollDiv>
      </ToolbarPaneField>
      <Footer>
        <Button template="primary" onClick={handleSubmit}>
          儲存
        </Button>
        <Button onClick={() => props.setShow(false)}>取消</Button>
      </Footer>
    </Container>
  );
}

export default EditTag;
