import { AxiosResponse, AxiosError } from 'axios';

/** 下一頁 */
export const NAVIGATE_TO_NEXT_PAGE = 'NAVIGATE_TO_NEXT_PAGE';
/** 選擇日期範圍 */
export const SELECT_DATE_RANGE = 'SELECT_DATE_RANGE';
/** 改變排序方式 */
export const CHANGE_SORT_ORDER = 'CHANGE_SORT_ORDER';

/** 發送API取回申請號列表 - 開始 */
export const FETCH_APP_NUM_LIST_START = 'FETCH_APP_NUM_LIST_START';
/** 發送API取回申請號列表 - 成功 */
export const FETCH_APP_NUM_LIST_SUCCESS = 'FETCH_APP_NUM_LIST_SUCCESS';
/** 發送API取回申請號列表 - 失敗 */
export const FETCH_APP_NUM_LIST_ERROR = 'FETCH_APP_NUM_LIST_ERROR';

export interface IByImageRequest {
  appNumList: Array<string>;
  dateRange?: string;
  sortByDate?: string;
  pageNo: number;
}
export interface IByImageResponse {
  patent_name: string;
  patent_pic_name: string;
  distance: string;
}

export interface ByImageSearchState {
  request: IByImageRequest;
  isAppNumListLoading: boolean;
  response: Array<IByImageResponse>;
}

interface NavigateToNextPageAction {
  type: typeof NAVIGATE_TO_NEXT_PAGE;
}

interface SelectDateRangeAction {
  type: typeof SELECT_DATE_RANGE;
  dateRange: string;
}

interface ChangeSortOrderAction {
  type: typeof CHANGE_SORT_ORDER;
  sortByDate: string;
}

interface FetchAppNumListStartAction {
  type: typeof FETCH_APP_NUM_LIST_START;
}

interface FetchAppNumListSuccessAction {
  type: typeof FETCH_APP_NUM_LIST_SUCCESS;
  response: AxiosResponse;
}

interface FetchAppNumListErrorAction {
  type: typeof FETCH_APP_NUM_LIST_ERROR;
  error: AxiosError;
}

export type ByImageSearchActionTypes =
  | NavigateToNextPageAction
  | SelectDateRangeAction
  | ChangeSortOrderAction
  | FetchAppNumListStartAction
  | FetchAppNumListSuccessAction
  | FetchAppNumListErrorAction;
