import {
  SEARCH_BY_KEYWORD,
  SELECT_DATE_RANGE,
  CHANGE_SORT_ORDER,
  NAVIGATE_TO_NEXT_PAGE,
  CLEAR_DOCDB_SEARCH,
  DocdbSearchState,
  DocdbSearchActionTypes,
} from 'src/redux/types/docdbSearchTypes';

const initialState: DocdbSearchState = {
  request: {
    queryString: '',
    pageNo: 0,
  },
  highlightWords: [],
};

const docdbResultReducer = (
  state = initialState,
  action: DocdbSearchActionTypes,
): DocdbSearchState => {
  const prevRequest = state.request;

  switch (action.type) {
    case SEARCH_BY_KEYWORD:
      return {
        request: {
          queryString: action.queryString,
          pageNo: 1,
        },
        highlightWords: action.highlightWords,
      };
    case SELECT_DATE_RANGE:
      return {
        ...state,
        request: {
          ...prevRequest,
          dateRange: action.dateRange,
          pageNo: 1,
        },
      };
    case CHANGE_SORT_ORDER:
      return {
        ...state,
        request: {
          ...prevRequest,
          sortByDate: action.sortByDate,
          pageNo: 1,
        },
      };
    case NAVIGATE_TO_NEXT_PAGE:
      return {
        ...state,
        request: {
          ...prevRequest,
          pageNo: prevRequest.pageNo + 1,
        },
      };
    case CLEAR_DOCDB_SEARCH:
      return {
        ...initialState,
      };
    default:
      return { ...state };
  }
};

export default docdbResultReducer;
