import React, { useState, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { FaSearch, FaTimes } from 'react-icons/fa';
import useOutSideClick from 'src/hooks/useOutSideClick';
import SearchSuggestion from './SearchSuggestion';
import { IRssSearchSuggestion } from 'src/types/api/NewsApiTypes';
import { blue3 } from 'src/style/theme/Color';
import Tooltip from '../ui/interactive/Tooltip';

const Container = styled.div`
  position: absolute;
  padding-top: 4px;
  width: calc(100% - 64px);
`;

const SearchArea = styled.div`
  height: 61px;
  width: 100%;
  display: flex;
  border-radius: 4px;
  position: relative;
  line-height: 60px;
  text-align: left;
  border-radius: 4px;
  box-shadow: 0 4px 16px -4px rgba(38, 50, 55, 0.3);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  background-color: rgba(255, 255, 255, 0.9);
`;
const InputContainer = styled.div`
  height: 61px;
  width: 100%;
  padding-left: 20px;
  position: relative;
  display: flex;
`;
const Input = styled.input.attrs({ type: 'text' })`
  height: 61px;
  padding: 20px 8px;
  padding-right: 48px;
  width: 100%;
  border-radius: 4px;
  color: #333333;
  background-color: transparent;
  border: none;
  outline: none;
  ::placeholder {
    color: #b3b9bc;
  }
`;
const Icon = styled.div`
  position: absolute;
  right: 16px;
  height: 61px;
  background-color: transparent;
  border-radius: 4px;
  display: flex;
  align-items: center;
  svg {
    display: block;
    :hover {
      cursor: pointer;
    }
  }
`;

const QueryIntersection = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

const CancelIcon = styled.div`
  align-items: center;
  display: flex;
  border-radius: 50%;
  :hover {
    cursor: pointer;
    color: ${blue3};
  }

  svg {
    display: block;
    margin: auto;
    font-size: 15px;
  }
`;

const Text = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Tag = styled.div`
  display: flex;
  height: 36px;
  margin: auto 0px;
  margin-right: 8px;
  border-radius: 4px;
  background-color: #b5e4ec;
  vertical-align: middle;
  padding: 4px 16px;
  color: #263237;
  cursor: default;
`;

interface IProps {
  queryString: string;
  tags: Array<IRssSearchSuggestion>;
  search: (queryString: string) => void;
  handleSuggestionClick: (suggestion?: IRssSearchSuggestion) => void;
  handleTagCancel: (type: string, value: string) => void;
}

export const SearchBar: React.FC<IProps> = props => {
  const [inputText, setInputText] = useState(props.queryString);
  const [isSuggestionShow, setIsSuggestionShow] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const {
    search,
    handleSuggestionClick: propsHandleSuggestionClick,
    handleTagCancel: propsHandleTagCancel,
  } = props;

  const node = useOutSideClick({
    show: isSuggestionShow,
    setShow: setIsSuggestionShow,
  });

  const handleSearch = useCallback(() => {
    search(inputText);
  }, [search, inputText]);

  const handleSuggestionClick = useCallback(
    (suggestion?: IRssSearchSuggestion) => {
      if (suggestion) {
        propsHandleSuggestionClick(suggestion);
        if (suggestion.type === 'QUERY_STRING') {
          setInputText(suggestion.value);
        }
      }
      inputRef.current && inputRef.current.focus();
    },
    [propsHandleSuggestionClick],
  );

  const handleTagCancel = useCallback(
    (type: string, value: string) => {
      propsHandleTagCancel(type, value);
      inputRef.current && inputRef.current.focus();
    },
    [propsHandleTagCancel],
  );

  const handleSuggestionClose = useCallback(() => {
    setIsSuggestionShow(false);
  }, []);

  const getTagOverlay = useCallback((tag: IRssSearchSuggestion) => {
    return (
      <>
        {tag.queryString && <div>搜尋條件：{tag.queryString}</div>}
        {tag.sources && tag.sources.length > 0 && <div>來源：{tag.sources.join('、')}</div>}
      </>
    );
  }, []);

  return (
    <Container>
      <SearchArea>
        <InputContainer ref={node as any}>
          {props.tags &&
            props.tags.map((tag, index) => (
              <Tag key={index}>
                <QueryIntersection>
                  <Tooltip overlay={getTagOverlay(tag)} placement="bottom">
                    <Text>{tag.displayName}</Text>
                  </Tooltip>
                </QueryIntersection>
                <CancelIcon onClick={() => handleTagCancel(tag.type, tag.value)}>
                  <FaTimes />
                </CancelIcon>
              </Tag>
            ))}

          <Input
            ref={inputRef as any}
            value={inputText}
            onChange={e => setInputText(e.target.value)}
            onFocus={() => setIsSuggestionShow(true)}
            onKeyUp={e => e.keyCode === 13 && handleSearch()}
            placeholder="請輸入搜尋條件"
          />
          <SearchSuggestion
            cssTop="68px"
            isShow={isSuggestionShow}
            tags={props.tags}
            inputText={inputText}
            handleSuggestionClick={handleSuggestionClick}
            handleSuggestionClose={handleSuggestionClose}
          />
        </InputContainer>
        <Icon>
          <FaSearch onClick={handleSearch} color="#077790" size="20px" />
        </Icon>
      </SearchArea>
    </Container>
  );
};
