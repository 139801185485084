import React, { CSSProperties } from 'react';
import { css } from 'styled-components';
import { Dropdown, IDropdownProps } from 'src/components/common/Dropdown';
import { iceblue1 } from 'src/style/theme/Color';

const customStyleContainer = (width: CSSProperties['width']) => css`
  width: ${width};
`;

const customStyleItemBlock = css`
  left: 0;
  margin-top: 31px;
  color: ${iceblue1};
  width: 120px;
`;

const customStyleItem = css`
  padding: 2px 0px 2px 16px;
  min-height: 37px;
  line-height: 37px;
`;

interface IProps extends IDropdownProps {
  width: CSSProperties['width'];
}

export const CountryDropdown: React.FC<IProps> = props => {
  return (
    <Dropdown
      items={props.items}
      activeItem={props.activeItem}
      handleOnclick={props.handleOnclick}
      customStyleContainer={customStyleContainer(props.width)}
      customStyleSelectedItem={props.customStyleSelectedItem}
      customStyleItemBlock={customStyleItemBlock}
      customStyleItem={customStyleItem}
    />
  );
};
