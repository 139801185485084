import React from 'react';
import styled from 'styled-components';
import table_search from 'src/assets/images/manual/patent-search/table_search.png';

const ImgContainer = styled.div<{ height: string; imageUrl: string }>`
  position: relative;
  height: ${props => props.height}px;
  padding: 10px 0;
  background-image: url(${props => props.imageUrl});
  background-size: contain;
  background-repeat: no-repeat;
`;
export default function PatentSearchManual() {
  return (
    <>
      <p>表格檢索可以選擇不同國別、使用檢索式輸入關鍵字、不同日期以及各欄位，來進行專利檢索。</p>
      <ol>
        <li>從功能列點選「專利檢索」。</li>
        <li>點選「表格檢索」功能。</li>
        <li>選擇要檢索的國家範圍，預設為台灣TW。</li>
        <li>輸入要檢索的欄位與字串，可利用快捷鍵按鈕將運算元帶入，可參照運算元與檢索欄位列表。</li>
        <li>選擇要檢索的日期類別，預設為公開/公告日。</li>
        <li>除了上方自行輸入檢索式，也可以選擇下方要檢索的欄位並輸入。</li>
        <li>點選「查詢」進行查詢</li>
      </ol>
      <ImgContainer height="500" imageUrl={table_search} />
    </>
  );
}
