/** MainTab DOM加className讓GA能記錄點擊行為 */
export const getMainTabGaClassName = (tab: string) => {
  switch (tab) {
    case '推薦專利':
      return 'recommend';
    case '最新專利':
      return 'latest';
    case '精選新聞':
      return 'news';
    case '專欄文章':
      return 'article';
    case '專利情報':
      return 'subscribed';
    default:
      return '';
  }
};
/** StatTab DOM加className讓GA能記錄點擊行為 */
export const getStatTabClassName = (ajaxUrl: string) => {
  switch (ajaxUrl) {
    case 'trend/industry/view':
      return 'trendView';
    case 'trend/industry/collect':
      return 'trendCollect';
    case 'trend/industry/keyword':
      return 'trendKeyword';
    case 'trend/industry/tag':
      return 'trendTag';
    default:
      return '';
  }
};
