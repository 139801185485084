import React from 'react';
import styled from 'styled-components';

import { ultrawhite, ultrawhitergba, ultrablackrgba } from 'src/style/theme/Color';

const Container = styled.div`
  border-radius: 4px;
  box-shadow: 0 0 50px 0 ${ultrablackrgba(0.1)};
`;
const Header = styled.div`
  height: 88px;
  display: flex;
  flex-flow: row;
  align-items: center;
  color: ${ultrawhite};
  background-image: linear-gradient(95deg, #2ec3c2, #0cafcc);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;
const HeaderText = styled.div`
  padding: 16px 0 16px 32px;
  width: 70%;
`;
const HeaderRoute = styled.div`
  color: ${ultrawhitergba(0.8)};
`;
const Feature = styled.div`
  height: 36px;
  line-height: 36px;
  font-size: 24px;
  font-weight: bold;
`;
const Subheaders = styled.div`
  padding: 16px 0;
  min-height: 60px;
  display: flex;
  align-items: center;
  color: #979ea1;
  background-color: #edf6f7;
`;
const Description = styled.div`
  padding: 0 16px 0 32px;
  width: 104px;
  display: flex;
`;
const Tags = styled.div`
  width: calc(85% - 104px);
  display: flex;
`;
const Tag = styled.div<{ active: boolean }>`
  margin: 2px 8px;
  padding: 4px 16px;
  width: auto;
  border-radius: 4px;
  color: ${props => (props.active ? '#ffffff' : '#5f686c')};
  background-color: ${props => (props.active ? '#0cafcc' : '#f5fdfe')};
  line-height: 22px;
  cursor: pointer;
`;

const Content = styled.div`
  padding: 0 0 24px;
  min-height: 450px;
`;

interface IProps {
  feature: string;
  tag: string;
  patents: any;
}

const tagContent: React.FC<IProps> = props => {
  return (
    <Container>
      <Header>
        <HeaderText>
          <HeaderRoute>首頁&nbsp;/&nbsp;{props.feature}</HeaderRoute>
          <Feature>{props.feature}</Feature>
        </HeaderText>
      </Header>
      <Subheaders>
        <Description>標籤</Description>
        <Tags>
          <Tag active={true}>{props.tag}</Tag>
        </Tags>
      </Subheaders>
      <Content>{props.children}</Content>
    </Container>
  );
};

export default tagContent;
