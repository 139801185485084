import React from 'react';

import OpendataContent from 'src/components/ui/content/Opendata/OpendataContent';
import {
  ApiTypePost,
  ApiInfoTitleStyle,
  ApiParamThStyle,
  ApiParamTdStyle,
  ApiParamTrStyle,
  ApiOutputStyle,
  apiParamNameWidth,
  FirstLineIndent,
} from 'src/style/OpendataStyle';
import ApiToken from 'src/components/opendata/common/ApiToken';

const Search: React.FC = () => {
  return (
    <OpendataContent
      sector="開放資料"
      feature="專利查詢 API"
      foldableDescription={{
        abstract:
          '使用者可透過本API上傳檢索式查詢「產業專利知識平台」之專利資料，可獲得查詢結果包含專利資料唯一識別碼、申請人、發明人、專利名稱、摘要等。',
        detail: (
          <>
            <FirstLineIndent indent={-23}>
              註. 檢索式規則可參考首頁 / 專利檢索 / 基本檢索 說明頁面。
            </FirstLineIndent>
            <FirstLineIndent indent={-23}>
              例. 查詢微軟(Microsoft)的美國專利案件，則可輸入參數 queryString=“PO=US AND
              PA=*Microsoft”，結果可得多篇相關專利，例如專利資料唯一識別碼為93cbb58e-8ce6-45e2-825c-21ffbd0c7000、案號為20190236106、申請人為Microsoft
              Technology Licensing, LLC、發明人為Zhang Liang, Zhu Lin…、專利名稱為FINDING MEMBERS
              WITH SIMILAR DATA ATTRIBUTES …、摘要為Methods, systems, and computer programs are
              presented for… 。
            </FirstLineIndent>
          </>
        ),
      }}
    >
      <>
        <p>提供專利查詢 API：</p>
        <ApiTypePost />
        {process.env.REACT_APP_API_URL + 'open/api/patent/search'}
        <ApiToken />

        <ApiInfoTitleStyle>Body 格式說明</ApiInfoTitleStyle>
        <ApiOutputStyle>
          {'{'}
          <br />
          &emsp;"queryString": "PO=TW",
          <br />
          &emsp;"applbdate": "20180101",
          <br />
          &emsp;"appledate": "20180131",
          <br />
          &emsp;"publbdate": "20190101",
          <br />
          &emsp;"publedate": "20190131",
          <br />
          &emsp;"priobdate": "20180101",
          <br />
          &emsp;"prioedate": "20181231"
          <br />
          {'}'}
        </ApiOutputStyle>
        <ApiParamThStyle>
          <ApiParamTdStyle width={apiParamNameWidth}>Name</ApiParamTdStyle>
          <ApiParamTdStyle>Value</ApiParamTdStyle>
        </ApiParamThStyle>
        <ApiParamTrStyle>
          <ApiParamTdStyle width={apiParamNameWidth}>queryString</ApiParamTdStyle>
          <ApiParamTdStyle>檢索字串：必填，模糊比對</ApiParamTdStyle>
        </ApiParamTrStyle>
        <ApiParamTrStyle>
          <ApiParamTdStyle width={apiParamNameWidth}>applbdate</ApiParamTdStyle>
          <ApiParamTdStyle>申請日期起：選填，yyyyMMdd</ApiParamTdStyle>
        </ApiParamTrStyle>
        <ApiParamTrStyle>
          <ApiParamTdStyle width={apiParamNameWidth}>appledate</ApiParamTdStyle>
          <ApiParamTdStyle>申請日期迄：選填，yyyyMMdd</ApiParamTdStyle>
        </ApiParamTrStyle>
        <ApiParamTrStyle>
          <ApiParamTdStyle width={apiParamNameWidth}>publbdate</ApiParamTdStyle>
          <ApiParamTdStyle>公開/公告日起：選填，yyyyMMdd</ApiParamTdStyle>
        </ApiParamTrStyle>
        <ApiParamTrStyle>
          <ApiParamTdStyle width={apiParamNameWidth}>publedate</ApiParamTdStyle>
          <ApiParamTdStyle>公開/公告日迄：選填，yyyyMMdd</ApiParamTdStyle>
        </ApiParamTrStyle>
        <ApiParamTrStyle>
          <ApiParamTdStyle width={apiParamNameWidth}>priobdate</ApiParamTdStyle>
          <ApiParamTdStyle>優先權日起：選填，yyyyMMdd</ApiParamTdStyle>
        </ApiParamTrStyle>
        <ApiParamTrStyle>
          <ApiParamTdStyle width={apiParamNameWidth}>prioedate</ApiParamTdStyle>
          <ApiParamTdStyle>優先權日迄：選填，yyyyMMdd</ApiParamTdStyle>
        </ApiParamTrStyle>

        <ApiInfoTitleStyle>輸出結果範例</ApiInfoTitleStyle>
        <ApiOutputStyle>
          {'{'}
          <br />
          &emsp;"costTime": 0.128,
          <br />
          &emsp;"pageNo": 1,
          <br />
          &emsp;"size": 10,
          <br />
          &emsp;"totalCount": 1,
          <br />
          &emsp;"totalPages": 1,
          <br />
          &emsp;"data": [
          <br />
          &emsp;&emsp;{'{'}
          <br />
          &emsp;&emsp;&emsp;"id": "2002c9ee-df5d-4a73-9eea-...",
          <br />
          &emsp;&emsp;&emsp;"country": "TW",
          <br />
          &emsp;&emsp;&emsp;"img_alias":["108000000-A0304-14-0001-1.JPG", ...],
          <br />
          &emsp;&emsp;&emsp;"bibliographic": {'{'}
          <br />
          &emsp;&emsp;&emsp;&emsp;"publicationReference": {'{'}
          <br />
          &emsp;&emsp;&emsp;&emsp;&emsp;"documentId": {'{'}
          <br />
          &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"date": "20191001"
          <br />
          &emsp;&emsp;&emsp;&emsp;&emsp;{'}'}
          <br />
          &emsp;&emsp;&emsp;&emsp;{'}'},
          <br />
          &emsp;&emsp;&emsp;&emsp;"parties": {'{'}
          <br />
          &emsp;&emsp;&emsp;&emsp;&emsp;"applicant": {'{'}
          <br />
          &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"addressbook": {'{'}
          <br />
          &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"name": [ "王小明" ]
          <br />
          &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;{'}'},
          <br />
          &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;{'}'},
          <br />
          &emsp;&emsp;&emsp;&emsp;&emsp;"inventor": {'{'}
          <br />
          &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"addressbook": {'{'}
          <br />
          &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;"name": [ "王小明" ]
          <br />
          &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;{'}'}
          <br />
          &emsp;&emsp;&emsp;&emsp;&emsp;{'}'}
          <br />
          &emsp;&emsp;&emsp;&emsp;{'}'},
          <br />
          &emsp;&emsp;&emsp;&emsp;"inventionTitle": {'{'}
          <br />
          &emsp;&emsp;&emsp;&emsp;&emsp;"title": [ "系統...", "SYSTEM..." ]
          <br />
          &emsp;&emsp;&emsp;&emsp;{'}'}
          <br />
          &emsp;&emsp;&emsp;{'}'},
          <br />
          &emsp;&emsp;&emsp;"inventionSpecification":{'{'}
          <br />
          &emsp;&emsp;&emsp;&emsp;"abstract": {'{'}
          <br />
          &emsp;&emsp;&emsp;&emsp;&emsp;"text": [ "本發明涉及..." ]
          <br />
          &emsp;&emsp;&emsp;&emsp;{'}'}
          <br />
          &emsp;&emsp;&emsp;{'}'}
          <br />
          &emsp;&emsp;{'}'}
          <br />
          &emsp;]
          <br />
          {'}'}
        </ApiOutputStyle>
      </>
    </OpendataContent>
  );
};

export default Search;
