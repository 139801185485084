import { AxiosResponse, AxiosError } from 'axios';
import { IDocdbResultItem } from 'src/types/DocdbSearchTypes';
import { IPaginatedData } from 'src/types/PaginatedDataTypes';

export const FETCH_DOCDB_RESULT_START = 'FETCH_DOCDB_RESULT_START';
export const FETCH_DOCDB_RESULT_SUCCESS = 'FETCH_DOCDB_RESULT_SUCCESS';
export const FETCH_DOCDB_RESULT_ERROR = 'FETCH_DOCDB_RESULT_ERROR';
export const FETCH_DOCDB_NEXT_PAGE_RESULT_ERROR = 'FETCH_DOCDB_NEXT_PAGE_RESULT_ERROR';
export const CLEAR_DOCDB_RESULT = 'CLEAR_DOCDB_RESULT';

export interface DocdbResultState {
  isLoading: boolean;
  paginatedData: IPaginatedData<IDocdbResultItem>;
  nextPageErrMsg?: string;
}

interface FetchDocdbResultStartAction {
  type: typeof FETCH_DOCDB_RESULT_START;
}

interface FetchDocdbResultSuccessAction {
  type: typeof FETCH_DOCDB_RESULT_SUCCESS;
  response: AxiosResponse;
}

interface FetchDocdbResultErrorAction {
  type: typeof FETCH_DOCDB_RESULT_ERROR;
  error: AxiosError;
}

interface FetchDocdbNextPageResultErrorAction {
  type: typeof FETCH_DOCDB_NEXT_PAGE_RESULT_ERROR;
  nextPageErrMsg?: string;
}

interface ClearDocdbResultAction {
  type: typeof CLEAR_DOCDB_RESULT;
}

export type DocdbResultActionTypes =
  | FetchDocdbResultStartAction
  | FetchDocdbResultSuccessAction
  | FetchDocdbResultErrorAction
  | FetchDocdbNextPageResultErrorAction
  | ClearDocdbResultAction;
