import React, { useState, CSSProperties } from 'react';
//@ts-ignore
import styled from 'styled-components/macro';
import { FaCaretDown } from 'react-icons/fa';
import ClickOutside from 'react-onclickout';
import { StyledFunction } from 'styled-components';

export interface IDropdownProps {
  items: IDropdownItem[];
  activeItem: IDropdownItem;
  handleOnclick: (item: IDropdownItem) => void;
  customStyleContainer?: any; //CSSProperties&string;
  customStyleSelectedItem?: any; //CSSProperties&string;
  customStyleItemBlock?: any; //CSSProperties&string;
  customStyleItem?: any; //CSSProperties&string;
  customStyleSvg?: JSX.Element;
  customStyleSvgSize?: number;
  customStyleSvgColor?: CSSProperties['color'];
  isLoading?: boolean;
  disabled?: boolean;
}

export interface IDropdownItem {
  name: string;
  value: string;
  type?: string;
}

export interface ISelectedItem {
  [x: string]: IDropdownProps['activeItem'];
}

//styledProps
interface IDropdownContainerProps {
  active: boolean;
  css?: IDropdownProps['customStyleContainer'];
}
interface IDropdownItemProps {
  itemName: IDropdownItem['name'];
  css?: IDropdownProps['customStyleItem'];
}
const IDropdownSelectedItem: StyledFunction<
  IDropdownContainerProps & React.HTMLProps<HTMLInputElement>
> = styled.div;
const IDropdownItem: StyledFunction<IDropdownItemProps & React.HTMLProps<HTMLInputElement>> =
  styled.div;

const DropdownContainer = styled.div<{ disabled?: boolean }>`
  position: relative;
  display: inline-block;
  font-size: 16px;
  text-align: center;
  cursor: ${(props: { disabled?: boolean }) => (props.disabled ? 'not-allowed' : 'pointer')};
`;

const DropdownSelectedItem = IDropdownSelectedItem`${props => `
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  padding: 3px 8px;
  font-size: 16px;
  color:#fff;
  white-space: nowrap;
  background-color: ${props.active ? 'rgba(245, 253, 254, 0.2)' : 'rgba(245, 253, 254, 0)'};
  &:hover{
    background-color: rgba(245, 253, 254, 0.2);
  }
`}`;
const DropdownSelectedItemName = styled.div`
  width: calc(100% - 20px);
  overflow: hidden;
  text-align: left;
`;

const DropdownItemBlock = styled.div`
  position: absolute;
  z-index: 2;
  right: 0px;
  top: 40px;
  border-radius: 4px;
  padding: 4px 0;
  background-color: #fff;
  font-size: 14px;
  box-shadow: 2px 4px 8px 1px #33333333;
`;

const DropdownItem = IDropdownItem`${props => `
  padding: 2px 16px;
  text-align: left;
  white-space: nowrap;
  &:hover{
    background-color:#edf6f7;
  }
  &::before{
    content:'${props.itemName}';
  }`}
`;

export function Dropdown(props: IDropdownProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOnClick = () => {
    setIsOpen(!isOpen);
  };

  const selectItem = (item: IDropdownItem) => {
    if (props.handleOnclick) props.handleOnclick(item);
    setIsOpen(false);
  };

  return (
    <ClickOutside onClickOut={() => setIsOpen(false)}>
      <DropdownContainer disabled={props.disabled} css={props.customStyleContainer}>
        <DropdownSelectedItem
          active={isOpen}
          onClick={handleOnClick}
          css={props.customStyleSelectedItem}
        >
          <DropdownSelectedItemName>
            {!props.isLoading && props.activeItem.name}
          </DropdownSelectedItemName>
          {props.customStyleSvg || (
            <FaCaretDown size={props.customStyleSvgSize || 20} color={props.customStyleSvgColor} />
          )}
        </DropdownSelectedItem>
        {!props.disabled && isOpen && (
          <DropdownItemBlock css={props.customStyleItemBlock}>
            {props.items.map((item, i) => {
              return (
                <DropdownItem
                  key={i}
                  onClick={() => selectItem(item)}
                  itemName={item.name}
                  css={props.customStyleItem}
                />
              );
            })}
          </DropdownItemBlock>
        )}
      </DropdownContainer>
    </ClickOutside>
  );
}
