import React, { useState } from 'react';
import { FaSearchPlus } from 'react-icons/fa';
import { withRouter, RouteComponentProps } from 'react-router';

import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import { IGetPatentSubscription } from 'src/types/api/SearchBarApiTypes';
import { IBasicRequest } from 'src/types/PatentSearchTypes';
import { Container, Feature, Item } from 'src/style/SearchDropdownStyle';

import { composeBasicRequest, composeUuidListRequest } from 'src/redux/actions/patentSearchAction';
import { connect } from 'react-redux';
import { ReduxAppState } from 'src/redux/reducers';
import { ITokenPayload } from 'src/apps/auth/Auth';
import MiniLoader from 'src/components/ui/interactive/MiniLoader';

const SectionList = [
  { func: '最近檢索' },
  { func: '專利情報訂閱檢索' },
  { func: '推薦專利 - 進階檢索' },
];

interface IReduxMappingProps {
  member: ITokenPayload;
  composeBasicRequest: (basicRequest: IBasicRequest) => void;
  composeUuidListRequest: (basicRequest: IBasicRequest) => void;
}
interface IProps extends IReduxMappingProps, RouteComponentProps {
  recentSearch?: Array<string>;
  patentSubscribe?: Array<IGetPatentSubscription>;
  cssTop?: string;
  handleSuggestionClick: (value?: string) => void;
  handleSuggestionClose: () => void;
}

const InputDropdown: React.FC<IProps> = props => {
  const {
    member,
    composeUuidListRequest,
    recentSearch,
    patentSubscribe,
    cssTop,
    handleSuggestionClick,
    handleSuggestionClose,
  } = props;

  const [isRecommendLoading, setIsRecommendLoading] = useState<boolean>(false);

  const handleRecommend = async (e: any) => {
    e.stopPropagation();
    setIsRecommendLoading(true);
    try {
      const res = await Api().get('patent/search/recommend');
      handleSuggestionClose();
      composeUuidListRequest({
        queryString: res.data,
      });
      setIsRecommendLoading(false);
      props.history.push('/result');
    } catch (error) {
      setIsRecommendLoading(false);
      errorHandler(error);
    }
  };

  const handleSubscriptionClick = (e: any, keyword: string, industrialIds?: string[]) => {
    e.stopPropagation();
    handleSuggestionClose();
    props.history.push('/result');
    props.composeBasicRequest({
      queryString: keyword.trim(),
      industrialClassIds: industrialIds,
    });
  };

  const handleSuggestedItemClick = (e: any, value?: string) => {
    e.stopPropagation();
    handleSuggestionClick(value);
  };

  const handleTracks = () => {
    if (member.info.guest) {
      return <Item>請先登入會員</Item>;
    } else if (patentSubscribe && patentSubscribe.length === 0) {
      return <Item>尚未訂閱任何內容</Item>;
    } else if (patentSubscribe) {
      return (
        <>
          {patentSubscribe.map(item => (
            <Item
              key={item.id}
              onClick={e => handleSubscriptionClick(e, item.keyword, item.industrialIds)}
            >
              <FaSearchPlus />
              <span>{item.name}</span>
            </Item>
          ))}
        </>
      );
    }
    return null;
  };

  return (
    <Container cssTop={cssTop} onClick={() => handleSuggestionClick()}>
      {SectionList &&
        SectionList.map((area, idx) => {
          const btmLine = area.func === SectionList[0].func || area.func === SectionList[1].func;
          return (
            <Feature key={area.func} recent={area.func === SectionList[0].func} btmLine={btmLine}>
              <section>{area.func}</section>
              {idx === 0 && recentSearch && recentSearch.length === 0 ? (
                <Item>尚無檢索紀錄</Item>
              ) : (
                <>
                  {idx === 0 &&
                    recentSearch &&
                    recentSearch.map(item => (
                      <Item key={item} onClick={e => handleSuggestedItemClick(e, item)}>
                        <span>{item}</span>
                      </Item>
                    ))}
                </>
              )}
              {idx === 1 && handleTracks()}
              {idx === 2 && (
                <Item onClick={e => handleRecommend(e)}>
                  {isRecommendLoading ? (
                    <MiniLoader diameter="15px" borderWidth="2px" margin="0 20px 0 0" />
                  ) : (
                    <FaSearchPlus />
                  )}
                  <span>推薦專利</span>
                </Item>
              )}
            </Feature>
          );
        })}
    </Container>
  );
};

const mapStateToProps = (state: ReduxAppState) => {
  return {
    member: state.memberReducer.memberPayload,
  };
};

const mapDispatchToProps = {
  composeBasicRequest,
  composeUuidListRequest,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(InputDropdown),
);
