import React from 'react';
import styled from 'styled-components';
import { FaAngleDown, FaAngleUp, FaTimesCircle } from 'react-icons/fa';
import { IPostIpcDescriptions } from 'src/types/PatentSearchTypes';
import { connect } from 'react-redux';
import { ReduxAppState } from 'src/redux/reducers';
import { appendAdvancedKeyword, detachAdvancedKeyword } from 'src/redux/actions/patentSearchAction';
import Tooltip from 'src/components/ui/interactive/Tooltip';
import { numFormatter } from 'src/utils/Formatter';
import { Spinner } from 'src/components/ui/interactive/Spinner';
import SearchBar from './common/SearchBar';
import {
  Container,
  Header,
  TaggedHeader,
  Title,
  Panel,
  TagWithTooltip,
  FacetLinkBox,
  FacetLinkName,
  FacetLinkCount,
  NoFilteredResult,
} from 'src/style/AccordionStyle';
import { blue2 } from 'src/style/theme/Color';
import { IFacetFieldCount } from 'src/types/PatentSearchTypes';
import useAccordion from './common/useAccordion';

const DescriptionTooltip = styled(Tooltip)`
  width: 60%;
  color: ${blue2};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

interface IReduxMappingProps {
  ipcDescriptions: IPostIpcDescriptions;
  isLoading: boolean;
  appendAdvancedKeyword: (keyCode: string, keyword: string) => void;
  detachAdvancedKeyword: (keyCode: string) => void;
}

interface IProps extends IReduxMappingProps {
  keyCode: string;
  title?: string;
  tag?: string;
  content: Array<IFacetFieldCount>;
}

/** 國際分類號-摺疊面板 */
const IpcAccordion: React.FC<IProps> = props => {
  const {
    keyCode,
    title,
    tag,
    content,
    appendAdvancedKeyword,
    detachAdvancedKeyword,
    isLoading,
    ipcDescriptions,
  } = props;

  const {
    isActive,
    searchKeyword,
    filteredContent,
    togglePanel,
    handleKeywordChange,
    getHighlightedText,
  } = useAccordion(content);

  const isTagged = !!tag;
  const isSearchbarShow = !isTagged && isActive && content && content.length > 6;

  return (
    <Container>
      {isTagged ? (
        <TaggedHeader>
          <Title>{title}</Title>
          <TagWithTooltip
            placement="top"
            overlay={tag}
            // 國際分類號不顯示Tooltip
            isHidden={true}
          >
            {tag}
          </TagWithTooltip>
          <FaTimesCircle size={20} onClick={() => detachAdvancedKeyword(keyCode)} />
        </TaggedHeader>
      ) : (
        <Header onClick={() => togglePanel()}>
          <Title>{title}</Title>
          {isActive ? <FaAngleUp size={20} /> : <FaAngleDown size={20} />}
        </Header>
      )}
      {isSearchbarShow && (
        <SearchBar searchKeyword={searchKeyword} onChange={e => handleKeywordChange(e)} />
      )}
      {isLoading ? (
        <Spinner margin="58px auto" />
      ) : (
        !isTagged && (
          <Panel isShow={isActive}>
            {searchKeyword !== '' && filteredContent.length === 0 ? (
              <NoFilteredResult>查無 "{searchKeyword}" 的符合結果</NoFilteredResult>
            ) : (
              filteredContent.map(item => (
                <FacetLinkBox
                  key={item.name}
                  onClick={() => appendAdvancedKeyword(keyCode, item.name)}
                >
                  <FacetLinkName>{getHighlightedText(item.name, searchKeyword)}</FacetLinkName>
                  <DescriptionTooltip
                    overlay={ipcDescriptions && ipcDescriptions[item.name]}
                    placement="right"
                    offset={40}
                  >
                    <span>{ipcDescriptions && ipcDescriptions[item.name]}</span>
                  </DescriptionTooltip>
                  <FacetLinkCount>({numFormatter(item.count)})</FacetLinkCount>
                </FacetLinkBox>
              ))
            )}
          </Panel>
        )
      )}
    </Container>
  );
};

const mapStateToProps = (state: ReduxAppState) => {
  return {
    ipcDescriptions: state.patentFilterReducer.ipcDescriptions,
    isLoading: state.patentFilterReducer.isIpcDescriptionsLoading,
  };
};

const mapDispatchToProps = {
  appendAdvancedKeyword,
  detachAdvancedKeyword,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IpcAccordion);
