import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { RouteComponentProps } from 'react-router-dom';
import { FaMinus, FaSearch } from 'react-icons/fa';

import Content from 'src/components/ui/content/Content';
import { Row } from 'src/style/PatentSearchStyle';

import {
  pink6,
  iceblue1,
  iceblue5,
  iceblue6,
  ultrawhite,
  blue3,
  white,
} from 'src/style/theme/Color';

import Tooltip from 'src/components/ui/interactive/Tooltip';
import { ITrademarkImageCategory } from 'src/types/TrademarkTypes';
import { PicPathAutoComplete } from './PicPathAutocomplete';
import { DROP_DOWN_ITEMS, SearchType, TrademarkService } from 'src/api/Trademark';
import { Spinner } from 'src/components/ui/interactive/Spinner';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import { Dropdown, IDropdownItem } from 'src/components/common/Dropdown';
import DropzoneAndCtrlByTm from '../DropzoneAndCtrlForTm';

const ButtonTooltip = styled(Tooltip)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  margin-right: 4px;
  :hover {
    cursor: pointer;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  position: absolute;
  left: 101%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
`;

const Label = styled.label`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const InputContainer = styled.div`
  margin: 0 32px 0 0;
  width: 80%;
  min-height: 30px;
  position: relative;
  display: flex;

  > span {
    padding: 0 0 0 17px;
    color: ${pink6};
  }
`;

const Icon = styled.div<{ disable?: boolean }>`
  position: absolute;
  right: 16px;
  height: 36px;
  background-color: transparent;
  border-radius: 4px;
  display: flex;
  align-items: center;
  svg {
    display: block;
    :hover {
      cursor: ${props => (props.disable ? 'not-allowed' : 'pointer')};
    }
  }
`;

const Input = styled.input.attrs<{ width: string }>({ type: 'text' })`
  width: ${props => props.width};
  padding: 5px 16px;
  border-radius: 4px;
  border: solid 1px ${iceblue5};
  background-color: ${ultrawhite};
  color: ${iceblue1};
  :placeholder {
    color: ${iceblue6};
  }
  :disabled {
    background-color: ${white};
    cursor: not-allowed;
    color: ${iceblue6};
  }
`;
const Column = styled.div<{ width: string }>`
  margin-right: 32px;
  width: ${props => props.width};
  color: ${iceblue1};
`;

const customDropdownSelectedItem = css`
  color: inherit;
  padding: 0;
`;

const customDropdownItemBlock = css`
  right: -20px;
  left: 0;
  width: fit-content;
`;

const visualSearchService = TrademarkService();
const HIDE_IMG_CLASS = true; // 是否隱藏圖形路徑查詢選項

export function convertTrademarkImagePathToJSX(selectedCategory: ITrademarkImageCategory) {
  return (
    <>
      {selectedCategory.firstCategory}-{selectedCategory.secondCategory}&nbsp;&nbsp;
      {selectedCategory.chName}&nbsp;&nbsp;{selectedCategory.enName}
    </>
  );
}

/** 商標以圖找圖 */
const TrademarkVisualSearch: React.FC<RouteComponentProps> = props => {
  const [input, setInput] = useState<string>('');
  const [selectedCategory, setSelectedCategory] = useState<ITrademarkImageCategory | undefined>();
  const [isEvalLoading, setIsEvalLoading] = useState<boolean>(false);
  const [searchType, setSearchType] = useState<IDropdownItem>(DROP_DOWN_ITEMS[0]);
  const [imagePath, setImagePath] = useState<string>();
  const [caseNo, setCaseNo] = useState<string>();
  const [errorOnByCaseNo, setErrorOnCaseNo] = useState<boolean>(true);

  /** 選擇圖形路徑時，取得 api 回傳的隨機 caseNo */
  useEffect(() => {
    if (selectedCategory) {
      setIsEvalLoading(true);
      visualSearchService
        .visualSearchEval(selectedCategory)
        .then(response => setCaseNo(response.caseNo))
        .catch(error => {
          errorHandler(error);
          setIsEvalLoading(false);
        });
    }
  }, [selectedCategory]);

  /** caseNo 改變時(by 'select category' or 'search icon on click') 取得該案號對應的圖片 */
  useEffect(() => {
    if (caseNo && caseNo.trim() !== '') {
      setIsEvalLoading(true);
      visualSearchService
        .getImgPathByCaseNo(caseNo)
        .then(data => {
          setImagePath(data);
          setErrorOnCaseNo(false);
        })
        .catch(error => {
          setErrorOnCaseNo(true);
          errorHandler(error);
          // 失敗則將案號重置, 下方顯示之圖會一同重置. 若下方圖無重置, 則案號與圖一同送出時會用錯誤的案號去做以圖找圖
          setCaseNo(undefined);
        })
        .finally(() => setIsEvalLoading(false));
    } else {
      setImagePath(undefined);
    }
  }, [caseNo]);

  /* 點擊搜尋 icon 時改變案號 */
  const handleSearchByCaseNo = (rawInput: string = '') => {
    let inputCaseNo = rawInput.trim();
    setInput(inputCaseNo);
    if (inputCaseNo.length > 0) {
      if (!inputCaseNo.includes('-')) {
        inputCaseNo = inputCaseNo + '-1';
      }
      setCaseNo(inputCaseNo);
    }
  };

  /* 於 input 框點擊 enter 改變案號。*/
  const handleSearchByEnter = (e: any) => {
    if (e.key === 'Enter') {
      handleSearchByCaseNo(e.target.value);
    }
  };

  /* 點擊 search bar 左邊搜尋類型之下拉選單 */
  const handleSearchTypeSelect = (selectedSearchType: IDropdownItem) => {
    setSearchType(selectedSearchType);
    setInput('');
    setCaseNo(undefined);
    setErrorOnCaseNo(true);
    setSelectedCategory(undefined);
  };

  /* 選擇建議的圖形路徑 */
  const handleSuggestionClick = (suggestion?: ITrademarkImageCategory) => {
    setInput('');
    setSelectedCategory(suggestion);
  };

  /** 重置圖形路徑 */
  const resetCategory = () => {
    setInput('');
    setSelectedCategory(undefined);
    setCaseNo(undefined);
  };

  const searchByCategory = searchType.value === SearchType.CATEGORY;
  const warningMessage = searchByCategory
    ? '未選取圖形路徑，請上傳圖片進行查詢'
    : errorOnByCaseNo
    ? '未輸入有效申請號，請輸入 {申請號}-{圖檔編號} 並進行搜尋，圖檔編號預設為 1。例如：109002750 或 109002750-1'
    : '';

  const getPlaceholder = () => {
    if (searchByCategory) {
      return selectedCategory
        ? '已選圖形路徑，將以隨機選取之圖片進行查詢。若欲改以其他方式查詢，請先取消選取'
        : '上傳圖片 或 輸入並選取二階圖形路徑，將隨機選取該圖形路徑之商標進行查詢。例如：02-B';
    } else {
      return '輸入案號並點擊右側放大鏡，將取得該案號之商標圖進行查詢';
    }
  };

  return (
    <Content sector="專利檢索" feature="商標以圖找圖" subtitle="資料範圍：本國商標申請案">
      <>
        <Row>
          <Label>
            {HIDE_IMG_CLASS ? (
              <Column width="10%">{searchType.name}</Column>
            ) : (
              <span onClick={e => e.preventDefault()}>
                <Dropdown
                  items={DROP_DOWN_ITEMS}
                  activeItem={searchType}
                  handleOnclick={handleSearchTypeSelect}
                  customStyleSelectedItem={customDropdownSelectedItem}
                  customStyleItemBlock={customDropdownItemBlock}
                  disabled={!!selectedCategory}
                />
              </span>
            )}
            <InputContainer>
              <Input
                width="100%"
                value={input}
                onChange={event => setInput(event.target.value)}
                onKeyPress={handleSearchByEnter}
                placeholder={getPlaceholder()}
                disabled={selectedCategory !== undefined}
              />
              {!searchByCategory && (
                <Icon>
                  <FaSearch
                    onClick={() => handleSearchByCaseNo(input)}
                    color="#077790"
                    size="20px"
                  />
                </Icon>
              )}
              {searchByCategory && !selectedCategory && (
                <PicPathAutoComplete
                  inputText={input}
                  handleSuggestionClick={handleSuggestionClick}
                />
              )}
            </InputContainer>
          </Label>
        </Row>
        <Row>
          <Label>
            <span />
            <InputContainer>
              {selectedCategory ? (
                <>
                  {convertTrademarkImagePathToJSX(selectedCategory)}
                  <ButtonsContainer>
                    <ButtonTooltip overlay="取消選取" placement="top" onClick={resetCategory}>
                      <FaMinus size={16} color={blue3} />
                    </ButtonTooltip>
                  </ButtonsContainer>
                </>
              ) : (
                <span>{warningMessage}</span>
              )}
            </InputContainer>
          </Label>
        </Row>
        <Row alignItems="flex-start">
          <Column width="10%">上傳圖片</Column>
          <Column width="80%">
            {isEvalLoading ? (
              <Spinner />
            ) : (
              <DropzoneAndCtrlByTm
                imageSrc={imagePath && visualSearchService.getImageUrl(imagePath)}
                imageName={imagePath && imagePath.split('/').pop()}
                resultPageUrl={'/result/trademark/visual'}
                acceptExtension={['png', 'jpg', 'bmp', 'jpeg']}
                paramToSubmit={{
                  category: selectedCategory,
                  searchType: searchType,
                  caseNo: caseNo,
                  imageName: imagePath && imagePath.split('/').pop(),
                  imagePath: imagePath,
                }}
                resetButtonOnClick={resetCategory}
                maxWidth="300px"
                {...props}
              />
            )}
          </Column>
        </Row>
      </>
    </Content>
  );
};
export default TrademarkVisualSearch;
