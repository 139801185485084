import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import { SystemMessage } from 'src/apps/applicationMessages';

import { Spinner } from 'src/components/ui/interactive/Spinner';
import { blue2, blue8 } from 'src/style/theme/Color';
import axios from 'axios';
import { unstable_batchedUpdates } from 'react-dom';
import InfiniteScroll from 'src/components/ui/interactive/InfiniteScroll';
import { IGetFilm } from 'src/types/api/FilmApiTypes';
import VideoListItem from 'src/components/article/item/VideoListItem';
import VideoContent from 'src/components/ui/content/Column/VideoContent';
import WaterfallGrid from 'src/components/ui/interactive/WaterfallGrid';
import VideoGridItem from 'src/components/article/item/VideoGridItem';

const NoResult = styled.div`
  margin: 16px 32px;
  padding: 16px 32px;
  background-color: ${blue8};

  div {
    color: ${blue2};
    font-weight: 600;
    margin-bottom: 8px;
  }
`;
const ContentStyle = styled.div`
  padding: 0 0 16px;
  width: 100%;
`;

interface IProps {
  feature: string;
  isGridMode?: boolean;
}

const Video: React.FC<IProps> = ({ feature: sector }) => {
  const [articles, setArticles] = useState<Array<IGetFilm>>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [noData, setNoData] = useState(false);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isGridMode, setIsGridMode] = useState(true);
  const [pageNo, setPageNo] = useState<number>(1);
  const [isNextPageLoading, setIsNextPageLoading] = useState<boolean>(false);
  const [isNextPageError, setIsNextPageError] = useState<boolean>(false);
  const loadClickHandler = () => {
    unstable_batchedUpdates(() => {
      setIsNextPageLoading(true);
      setPageNo(pageNo + 1);
    });
  };
  const reloadClickHandler = () => {
    if (pageNo === 1) {
      setIsLoading(true);
    } else {
      setIsNextPageLoading(true);
    }
  };
  // 為了讓children可以改變parent的state 傳入方法進children的props
  const isGridHandler = (isGrid: boolean) => {
    setIsGridMode(isGrid);
  };
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const fetchData = async () => {
      try {
        const res = await Api().get('film/lists', {
          params: {
            pageNo: pageNo,
          },
          cancelToken: source.token,
        });

        setTotalCount(res.data.total);
        setTotalPages(res.data.totalPages);
        if (res.data.data && res.data.data.length > 0) {
          setArticles(art => [...(art || []), ...res.data.data]);
          isNextPageLoading && setIsNextPageError(false);
          setNoData(false);
        } else {
          setNoData(true);
        }
      } catch (error) {
        errorHandler(error);
        isNextPageLoading && setIsNextPageError(true);
      } finally {
        setIsLoading(false);
        setIsNextPageLoading(false);
      }
    };

    (isLoading || isNextPageLoading) && fetchData();

    return () => source.cancel();
  }, [pageNo, isLoading, isNextPageLoading]);

  return (
    <ContentStyle>
      <VideoContent sector={sector} subtitle="" isGrid={isGridHandler}>
        <>
          {isLoading && <Spinner width="150px" />}
          {!isLoading && noData ? (
            <NoResult>
              <div>{SystemMessage.COLUMNIST_ARTICLE_NO_DATA}</div>
            </NoResult>
          ) : (
            <>
              {!isGridMode ? (
                <InfiniteScroll
                  isNextPageLoading={isNextPageLoading}
                  isNextPageError={isNextPageError}
                  errorMessage={'系統發生異常'}
                  handleNextPageLoad={loadClickHandler}
                  handleReload={reloadClickHandler}
                  totalCount={totalCount}
                  currentPage={pageNo}
                  totalPages={totalPages}
                >
                  {articles &&
                    articles.map(item => <VideoListItem key={item.id} columnData={item} />)}
                </InfiniteScroll>
              ) : (
                <InfiniteScroll
                  isNextPageLoading={isNextPageLoading}
                  isNextPageError={isNextPageError}
                  errorMessage={'系統發生異常'}
                  handleNextPageLoad={loadClickHandler}
                  handleReload={reloadClickHandler}
                  totalCount={totalCount}
                  currentPage={pageNo}
                  totalPages={totalPages}
                >
                  <WaterfallGrid
                    columns={3}
                    elements={
                      articles &&
                      articles.map(item => <VideoGridItem key={item.id} columnData={item} />)
                    }
                  />
                </InfiniteScroll>
              )}
            </>
          )}
        </>
      </VideoContent>
    </ContentStyle>
  );
};

export default Video;
