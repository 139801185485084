import React, { useState } from 'react';
import styled from 'styled-components';
import { FaAngleDoubleUp, FaAngleDoubleDown } from 'react-icons/fa';
import { ultrawhite, ultrawhitergba, ultrablackrgba, blue2 } from 'src/style/theme/Color';

const Container = styled.div`
  border-radius: 4px;
  box-shadow: 0 0 50px 0 ${ultrablackrgba(0.1)};
`;
const Header = styled.div<{ withoutPadding?: boolean }>`
  color: ${ultrawhite};
  background-image: linear-gradient(95deg, #2ec3c2, #0cafcc);
  border-radius: 4px 4px 0 0;
  padding-bottom: ${props => (props.withoutPadding ? '0' : '16px')};
`;
const HeaderText = styled.div`
  padding: 16px 32px;
`;
const HeaderRoute = styled.div`
  color: ${ultrawhitergba(0.8)};
`;
const Feature = styled.div`
  height: 36px;
  line-height: 36px;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  align-items: flex-end;
  span {
    padding: 0 0 0 8px;
    display: block;
    font-size: 15px;
    line-height: 24px;
    font-weight: normal;
  }
`;
const FoldableInfo = styled.div<{ collapse: boolean }>`
  padding: ${props => (props.collapse ? '8px 76px 8px 32px' : '16px 76px 16px 32px')};
  height: ${props => props.collapse && '40px'};
  position: relative;
  color: ${ultrawhite};
  background-color: ${ultrawhitergba(0.2)};
  overflow: ${props => (props.collapse ? 'hidden' : 'visible')};
  line-height: 24px;
  white-space: ${props => props.collapse && 'nowrap'};
  text-overflow: ellipsis;
`;
const RestInfomation = styled.div<{ collapse: boolean }>`
  display: ${props => (props.collapse ? 'none' : 'auto')};
`;
const BtnFold = styled.div`
  float: right;
  width: 28px;
  height: 26px;
  bottom: 0;
  right: 24px;
  position: absolute;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: ${ultrawhite};
  background-color: ${ultrawhitergba(0.2)};
  text-align: center;
  cursor: pointer;
  svg {
    margin: 6px 0 0;
  }
`;
const Tabs = styled.div`
  padding: 0 0 0 32px;
  height: 54px;
  display: flex;
  align-items: flex-end;
`;
const Tab = styled.div<{ active: boolean }>`
  margin: 0 4px 0 0;
  padding: 8px 16px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: ${props => (props.active ? blue2 : ultrawhite)};
  background-color: ${props => (props.active ? ultrawhite : ultrawhitergba(0.3))};
  cursor: pointer;
`;
const Content = styled.div<{ withoutPadding?: boolean }>`
  padding: ${props => (props.withoutPadding ? '24px 0 0' : '24px 32px')};
  min-height: 400px;
`;

interface IProps {
  sector: string;
  feature: string;
  foldableDescription?: { abstract: string; detail?: JSX.Element };
  tabs?: string[];
  activeTab?: string;
  activeTabHandler?: (tab: string) => void;
  contentWithoutPadding?: boolean;
  children: JSX.Element;
}

function OpendataContent(props: IProps) {
  let [collapse, setCollapse] = useState(false);
  const collapseHandler = () => {
    setCollapse(prev => !prev);
  };

  const description = props.foldableDescription && (
    <FoldableInfo collapse={collapse}>
      {props.foldableDescription.abstract}
      <RestInfomation collapse={collapse}>{props.foldableDescription.detail}</RestInfomation>
      {props.foldableDescription.detail && (
        <BtnFold onClick={collapseHandler}>
          {collapse ? <FaAngleDoubleDown /> : <FaAngleDoubleUp />}
        </BtnFold>
      )}
    </FoldableInfo>
  );

  const tabs = props.tabs && (
    <Tabs>
      {props.tabs.map(tab => (
        <Tab
          key={tab}
          active={props.activeTab === tab}
          onClick={() => props.activeTabHandler && props.activeTabHandler(tab)}
        >
          {tab}
        </Tab>
      ))}
    </Tabs>
  );

  return (
    <Container>
      <Header withoutPadding={props.tabs !== undefined}>
        <HeaderText>
          <HeaderRoute>
            首頁&nbsp;/&nbsp;{props.sector}&nbsp;/&nbsp;
            {props.feature}
          </HeaderRoute>
          <Feature>{props.feature}</Feature>
        </HeaderText>
        {description}
        {tabs}
      </Header>
      <Content withoutPadding={props.contentWithoutPadding}>{props.children}</Content>
    </Container>
  );
}

export default OpendataContent;
