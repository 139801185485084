import React, { useState, useEffect } from 'react';
import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import styled from 'styled-components';

import Content from 'src/components/ui/content/Content';
import { IGetFaq } from 'src/types/api/QuestionApiTypes';
import FaqItem from 'src/components/question/item/FaqItem';
import FaqManualItem from 'src/components/question/item/FaqManualItem';
import SearcherManual from 'src/components/question/manual/PatentSearch/SearcherManual';
import BasicSearchManual from 'src/components/question/manual/PatentSearch/BasicSearchManual';
import AdvancedSearchManual from 'src/components/question/manual/PatentSearch/AdvancedSearchManual';
import ByTextSearchManual from 'src/components/question/manual/PatentSearch/ByTextSearchManual';
import TableSearchManual from 'src/components/question/manual/PatentSearch/TableSearchManual';
import NumberdSearchManual from 'src/components/question/manual/PatentSearch/NumberSearchManual';
import BooleanSearchManual from 'src/components/question/manual/PatentSearch/BooleanSearchManual';
import PTABSearchManual from 'src/components/question/manual/PatentSearch/PTABSearchManual';
import ByImageSearchManual from 'src/components/question/manual/PatentSearch/ByImageSearchManual';
import ReferenceManual from 'src/components/question/manual/PatentSearch/ReferenceManual';
import SRAFunctionConfigurationManual from 'src/components/question/manual/SearchResultAnalysis/SRAFunctionConfigurationManual';
import FilterManual from 'src/components/question/manual/SearchResultAnalysis/FilterManual';
import ClusterManual from 'src/components/question/manual/SearchResultAnalysis/ClusterManual';
import DataRangeManual from 'src/components/question/manual/SearchResultAnalysis/DataRangeManual';
import DisplayModeManual from 'src/components/question/manual/SearchResultAnalysis/DisplayModeManual';
import TimeRangeAndOrderManual from 'src/components/question/manual/SearchResultAnalysis/TimeRangeAndOrderManual';
import PatentTrackManual from 'src/components/question/manual/SearchResultAnalysis/PatentTrackManual';
import StatisticalAnalysisManual from 'src/components/question/manual/SearchResultAnalysis/StatisticalAnalysisManual';
import EfficacyMatrixManual from 'src/components/question/manual/SearchResultAnalysis/EfficacyMatrixManual';
import PatentExportManual from 'src/components/question/manual/SearchResultAnalysis/PatentExportManual';
import PARFunctionConfigurationManual from 'src/components/question/manual/PatentAssistedRead/PARFunctionConfigurationManual';
import ContentNavigationManual from 'src/components/question/manual/PatentAssistedRead/ContentNavigationManual';
import StringSearchManual from 'src/components/question/manual/PatentAssistedRead/StringSearchManual';
import TranslateAndPrintManual from 'src/components/question/manual/PatentAssistedRead/TranslateAndPrintManual';
import TwoColumnCompareManual from 'src/components/question/manual/PatentAssistedRead/TwoColumnCompareManual';
import CollectAndNoteManual from 'src/components/question/manual/PatentAssistedRead/CollectAndNoteManual';
import InformationManual from 'src/components/question/manual/PatentAssistedRead/InformationManual';

const Sector = styled.div`
  padding: 0 0 32px;
`;
const Category = styled.div`
  color: #333333;
  font-weight: 500;
`;

function Faq(props: any) {
  const [faqData, setFaqData] = useState<Array<IGetFaq>>();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await Api().get('faq');
        setFaqData(res.data);
      } catch (error) {
        errorHandler(error);
      }
    };
    fetchData();
  }, []);

  return (
    <Content sector="常見問題" feature="FAQ">
      <>
        {faqData &&
          faqData.map(sector => (
            <Sector key={sector.name}>
              <Category>{sector.name}</Category>
              {sector.faqs.map(item => (
                <FaqItem
                  key={item.question}
                  title={item.question}
                  text={item.answer}
                  active={item.question.includes(props.match.params.quest)}
                />
              ))}
            </Sector>
          ))}
        <Sector>
          <Category>操作說明 - 專利檢索</Category>
          <FaqManualItem title="檢索入口" element={<SearcherManual />} active={false} />
          <FaqManualItem title="基本檢索" element={<BasicSearchManual />} active={false} />
          <FaqManualItem title="進階檢索" element={<AdvancedSearchManual />} active={false} />
          <FaqManualItem title="以文找文" element={<ByTextSearchManual />} active={false} />
          <FaqManualItem title="表格檢索" element={<TableSearchManual />} active={false} />
          <FaqManualItem title="號碼檢索" element={<NumberdSearchManual />} active={false} />
          <FaqManualItem title="布林檢索" element={<BooleanSearchManual />} active={false} />
          <FaqManualItem title="美國PTAB資料檢索" element={<PTABSearchManual />} active={false} />
          <FaqManualItem
            title="本國設計專利以圖找圖(BETA)"
            element={<ByImageSearchManual />}
            active={false}
          />
          <FaqManualItem
            title="參照－運算元與檢索欄位列表"
            element={<ReferenceManual />}
            active={false}
          />
        </Sector>
        <Sector>
          <Category>操作說明 - 搜尋結果分析</Category>
          <FaqManualItem
            title="功能配置"
            element={<SRAFunctionConfigurationManual />}
            active={false}
          />
          <FaqManualItem title="篩選" element={<FilterManual />} active={false} />
          <FaqManualItem title="聚類" element={<ClusterManual />} active={false} />
          <FaqManualItem title="資料範圍" element={<DataRangeManual />} active={false} />
          <FaqManualItem title="顯示模式" element={<DisplayModeManual />} active={false} />
          <FaqManualItem
            title="時間範圍及排序"
            element={<TimeRangeAndOrderManual />}
            active={false}
          />
          <FaqManualItem
            title="專利追蹤(會員登入)"
            element={<PatentTrackManual />}
            active={false}
          />
          <FaqManualItem title="統計分析" element={<StatisticalAnalysisManual />} active={false} />
          <FaqManualItem title="功效矩陣" element={<EfficacyMatrixManual />} active={false} />
          <FaqManualItem title="專利匯出" element={<PatentExportManual />} active={false} />
        </Sector>
        <Sector>
          <Category>操作說明 - 專利輔助閱讀</Category>
          <FaqManualItem
            title="功能配置"
            element={<PARFunctionConfigurationManual />}
            active={false}
          />
          <FaqManualItem title="內容導航" element={<ContentNavigationManual />} active={false} />
          <FaqManualItem title="文內字串檢索" element={<StringSearchManual />} active={false} />
          <FaqManualItem
            title="頁面翻譯及列印"
            element={<TranslateAndPrintManual />}
            active={false}
          />
          <FaqManualItem title="雙欄比對" element={<TwoColumnCompareManual />} active={false} />
          <FaqManualItem
            title="收藏專利及筆記(會員登入)"
            element={<CollectAndNoteManual />}
            active={false}
          />
          <FaqManualItem title="專利相關資訊" element={<InformationManual />} active={false} />
        </Sector>
      </>
    </Content>
  );
}

export default Faq;
