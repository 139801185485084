import styled from 'styled-components';
import {
  blue1,
  blue2,
  blue4,
  blue5,
  iceblue1,
  iceblue4,
  ultrawhite,
  ultrawhitergba,
} from 'src/style/theme/Color';

export const Container = styled.div`
  padding: 0 0 32px 0;
  width: 100%;
  min-height: 400px;
  border-radius: 4px;
  background-color: ${blue2};
`;

export const Sector = styled.div`
  padding: 12px 24px;
  color: ${blue5};
  opacity: 0.8;
  font-size: 20px;
  font-weight: bold;
`;

/* 第一層選單: 僅作為分類項目, 不可被選取(active指其子項被選取) */
export const FeatureUnselectable = styled.div<{ active: boolean }>`
  position: relative;
  padding: 10px 20px 10px 24px;
  color: ${ultrawhite};
  font-size: 15px;
  user-select: none;
  cursor: ${props => !props.active && 'pointer'};
  :hover {
    color: ${props => !props.active && blue4};
  }
`;

/* 第一層選單 */
export const Feature = styled(FeatureUnselectable)`
  ${props =>
    props.active &&
    `
    background-color: rgba(255, 255, 255, 0.1);
    border-left: 4px solid ${blue4};
    padding-left: 20px;
    font-weight: bold;
  `};
`;

/* 第二層選單 */
export const FeatureLv2 = styled(FeatureUnselectable)<{ disable?: boolean }>`
  padding-left: 40px;
  background-color: #035a76;
  :hover {
    color: ${props => !props.active && !props.disable && blue5};
  }
  ${props =>
    props.active &&
    `
    color: ${blue5};
    border-left: 4px solid ${blue4};
    padding-left: 36px;
  `};
  ${props =>
    props.disable &&
    `
    color: ${iceblue4};
    cursor: not-allowed;
  `};
`;

/* 第三層選單 */
export const FeatureLv3 = styled(FeatureLv2)`
  padding-left: 56px;
  background-color: ${blue1};
  ${props =>
    props.active &&
    `
    background-color: ${iceblue1};
    padding-left: 52px;
  `};
`;

export const HoverMask = styled.div<{ maskColor?: string; borderRadius?: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: none;
  border-radius: ${props => props.borderRadius};
  :hover {
    background-color: ${props => (props.maskColor ? props.maskColor : ultrawhitergba(0.2))};
  }
`;

export const FaStyle = styled.i<{ pointer?: boolean }>`
  float: right;
  padding-top: 1px;
  cursor: ${props => (props.pointer ? 'pointer' : 'default')};
  svg {
    color: ${ultrawhite};
  }
`;
