import React from 'react';

import { ErrorCode } from 'src/apps/error-handler/ErrorCode';

import 'react-datepicker/dist/react-datepicker.css';
import OpendataContent from 'src/components/ui/content/Opendata/OpendataContent';
import SingleDownloadPane from './pane/SingleDownloadPane';
import PeriodDownloadPane from './pane/PeriodDownloadPane';
import { FlexStyle } from 'src/style/OpendataStyle';
import { getCountryName } from 'src/utils/PatentUtils';

interface IState {
  collapse: boolean;
  activeTab: string;
}

const tabList = ['整期下載', '單一案件下載'];
const countryList = [
  { name: getCountryName('TW'), value: 'TW' },
  { name: getCountryName('US'), value: 'US' },
  { name: getCountryName('EP'), value: 'EP' },
  { name: getCountryName('JP'), value: 'JP' },
  { name: getCountryName('KR'), value: 'KR' },
];

class Opendata extends React.Component<{}, IState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      collapse: true,
      activeTab: tabList[0],
    };
  }
  toggleTab = (activeTab: string) => {
    if (activeTab !== this.state.activeTab) {
      this.setState({ activeTab: activeTab });
    }
  };

  switchContent = (activeTab: string) => {
    switch (activeTab) {
      case '整期下載':
        return <PeriodDownloadPane countryList={countryList} />;
      case '單一案件下載':
        return <SingleDownloadPane countryList={countryList} />;
      default:
        throw new Error(ErrorCode.UNHANDLED_CASE_IN_SWITCH);
    }
  };

  render() {
    return (
      <OpendataContent
        sector="開放資料"
        feature="公報 OPEN DATA 下載"
        foldableDescription={{
          abstract:
            '提供專利公報資料檔案，包含本國(TIPO)、美國(USPTO)、歐盟(EPO)、日本(JPO)與韓國(KIPO)。分為「整期ZIP檔」及「單一案件ZIP檔(最近1年)」2種下載方式。',
          detail: (
            <FlexStyle>
              <div>更新頻率：</div>
              <div>
                本國(TIPO)&ensp;-&ensp;每2週&emsp;|&emsp;美國(USPTO)&ensp;-&ensp;每週&emsp;|&emsp;歐盟(EPO)&ensp;-&ensp;每週
                <br />
                日本(JPO)&ensp;-&ensp;每週&emsp;|&emsp;韓國(KIPO)&ensp;-&ensp;每季
              </div>
            </FlexStyle>
          ),
        }}
        activeTab={this.state.activeTab}
        activeTabHandler={this.toggleTab}
        tabs={tabList}
        contentWithoutPadding={true}
      >
        {this.switchContent(this.state.activeTab)}
      </OpendataContent>
    );
  }
}

export default Opendata;
