import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { RouteComponentProps } from 'react-router-dom';
import { FaCaretDown } from 'react-icons/fa';

import { useWindowScroll } from 'src/hooks/useWindowScroll';

import { blue2, blue3, blue5, ultrawhite, ultrawhitergba } from 'src/style/theme/Color';
import { zMobileDropdownMenu } from 'src/style/theme/Z-Index';

const Container = styled.div<{ hasScrolled?: boolean }>`
  position: fixed;
  z-index: ${zMobileDropdownMenu};
  left: 0;
  top: ${props => (props.hasScrolled ? '53px' : '92px')};
  width: 100%;
  background-color: #035a76;
  color: ${ultrawhite};
`;

const DropdownSelected = styled.div`
  border-bottom: 1px solid ${blue3};
  padding: 6px 20px;
  height: 48px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  svg {
    font-size: 20px;
  }
`;

const MainFeature = styled.div<{ hasSubFeature: boolean }>`
  ${props =>
    props.hasSubFeature
      ? css`
          font-size: 12px;
          color: ${ultrawhitergba(0.8)};
        `
      : css`
          font-size: 14px;
        `};
`;

const DropdownListMainItem = styled.div<{ isActive?: boolean; isClickable?: boolean }>`
  font-size: 16px;
  cursor: ${props => (props.isClickable ? 'pointer' : 'default')};
  color: ${props => props.isActive && blue5};
  padding: 12px 20px;
`;

const DropdownListSubItemBlock = styled.div`
  margin-left: 24px;
  border-left: 1px solid ${blue2};
`;

const DropdownListSubItem = styled.div<{ isActive?: boolean }>`
  color: ${props => props.isActive && blue5};
  font-size: 14px;
  padding: 16px;
  :hover {
    cursor: pointer;
  }
`;

const DropdownList = styled.div<{ hasScrolled?: boolean }>`
  position: absolute;
  z-index: 2;
  left: 0px;
  top: 48px;
  width: 100%;
  height: ${props =>
    props.hasScrolled ? 'calc(100vh - 53px - 48px)' : 'calc(100vh - 92px - 48px)'};
  background-color: #035a76;
`;

interface IProps extends RouteComponentProps {
  features: Array<{
    feature: string;
    path: string;
    subfeatures: boolean;
  }>;
  subFeatArr: Array<{
    idx: number;
    items: Array<{
      id: number;
      name: string;
      linkable?: boolean; // 第二層 nav 在有第三層時可否作為連結
      disable?: boolean;
      nestedSubfeatures?: boolean;
      nestedSubfeat?: Array<{
        id: number;
        name: string;
        disable?: boolean;
      }>;
    }>;
  }>;
}

const DropdownMenu: React.FC<IProps> = ({ features, subFeatArr, location, match, history }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [selectedMainFeature, setSelectedMainFeature] = useState<string>();
  const [selectedSubFeature, setSelectedSubFeature] = useState<string>();
  const [hasScrolled] = useWindowScroll(0);

  useEffect(() => {
    // TODO: 不一定要用 state，但邏輯可能更複雜。
    const mainFeatureIdx = features.findIndex(item =>
      location.pathname.startsWith(match.url + item.path),
    );

    if (mainFeatureIdx >= 0) {
      const main = features[mainFeatureIdx];
      setSelectedMainFeature(main.feature);
      if (main.subfeatures) {
        const subFeature = subFeatArr[mainFeatureIdx].items.find(item =>
          location.pathname.endsWith(`${match.url}${main.path}/${item.id}`),
        );

        subFeature && setSelectedSubFeature(subFeature.name);
      } else {
        setSelectedSubFeature(undefined);
      }
    }
  }, [features, subFeatArr, match, location.pathname]);

  const handleMenuItemClick = (url: string) => {
    history.push(url);
    enableScrollbar();
    setIsExpanded(false);
  };

  const handleDropdownListToggle = () => {
    setIsExpanded(flag => {
      flag ? enableScrollbar() : disableScrollbar();
      return !flag;
    });
  };

  const disableScrollbar = () => {
    document.body.style.overflow = 'hidden';
  };

  const enableScrollbar = () => {
    document.body.style.overflow = 'auto';
  };

  return (
    <Container hasScrolled={hasScrolled}>
      <DropdownSelected onClick={handleDropdownListToggle}>
        <div>
          <MainFeature hasSubFeature={!!selectedSubFeature}>{selectedMainFeature}</MainFeature>
          {selectedSubFeature && <div>{selectedSubFeature}</div>}
        </div>
        <FaCaretDown />
      </DropdownSelected>
      {isExpanded && (
        <DropdownList hasScrolled={hasScrolled}>
          {features.map((item, idx) => {
            const isClickable = !item.subfeatures;
            return (
              <div key={item.path}>
                <DropdownListMainItem
                  isClickable={isClickable}
                  isActive={isClickable && location.pathname.startsWith(match.url + item.path)}
                  onClick={() => {
                    isClickable && handleMenuItemClick(match.url + item.path);
                  }}
                >
                  {item.feature}
                </DropdownListMainItem>
                {item.subfeatures && (
                  <DropdownListSubItemBlock>
                    {subFeatArr[idx].items.length > 0 &&
                      subFeatArr[idx].items.map(subItem => (
                        <DropdownListSubItem
                          key={subItem.id}
                          isActive={location.pathname.startsWith(
                            `${match.url}${item.path}/${subItem.id}`,
                          )}
                          onClick={() =>
                            handleMenuItemClick(`${match.url}${item.path}/${subItem.id}`)
                          }
                        >
                          {subItem.name}
                        </DropdownListSubItem>
                      ))}
                  </DropdownListSubItemBlock>
                )}
              </div>
            );
          })}
        </DropdownList>
      )}
    </Container>
  );
};

export default DropdownMenu;
