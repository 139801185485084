import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';

import * as Color from 'src/style/theme/Color';
import Content from 'src/components/ui/content/Content';
import { Button } from 'src/style/theme/Common';
import { alertMessage } from 'src/utils/ModalUtils';

const Section = styled.section`
  color: ${Color.iceblue3};

  > span {
    display: block;
    padding: 0 0 8px;
    color: ${Color.blue2};
    font-size: 18px;
  }
  > p {
    display: block;
    margin: 0 0 8px;
  }

  a {
    color: ${Color.blue3};
  }
`;
const SendMail = styled.div`
  padding: 32px 0;
`;
const EmailArea = styled.div`
  color: ${Color.black1};

  > div {
    margin: 0 0 24px;
    height: 32px;
    line-height: 32px;

    input {
      margin: 0 0 0 32px;
      padding: 5px 16px;
      height: 30px;
      border-radius: 4px;
      border: 1px solid ${Color.iceblue6};
      ::placeholder {
        color: ${Color.iceblue6};
      }
    }
  }
`;
const ErrorMessage = styled.span`
  margin: 0 16px;
  color: ${Color.red1};
`;

interface IProps {
  sector: string;
  title: string;
  description: string;
  url: string;
}

function MailToUser(props: IProps) {
  let [mail, setMail] = useState<string>();
  let [checked, setChecked] = useState(false);
  let [onFocus, setOnFocus] = useState(true);
  let [isWrong, setIsWrong] = useState(false);
  let [wasWrong, setWasWrong] = useState(false);
  let [errMsg, setErrMsg] = useState<string>();

  const handleSubmit = () => {
    const sendMail = async () => {
      try {
        await Api({ 'Content-Type': 'application/json' }).post(props.url, mail);
        alertMessage('信件發送成功，請依照信件內容指示完成後續步驟，謝謝。');
      } catch (error) {
        errorHandler(error);
      }
    };

    if (checked && !isWrong) {
      sendMail();
    } else {
      alertMessage('請輸入正確的電子郵件信箱');
    }
  };

  useEffect(() => {
    const mailValidate = (value: string | undefined) => {
      let errorMessage;
      if (checked) {
        if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
          errorMessage = '無效的電子郵件信箱';
          setIsWrong(true);
          if (!onFocus) {
            setWasWrong(true);
          }
        } else if (!value || value.trim() === '') {
          errorMessage = '請輸入電子郵件信箱';
          setIsWrong(true);
          if (!onFocus) {
            setWasWrong(true);
          }
        } else {
          setIsWrong(false);
        }
      }

      return errorMessage;
    };

    let message;
    if (checked) {
      message = mailValidate(mail);

      if ((wasWrong && onFocus && isWrong) || !onFocus) {
        setErrMsg(message);
      }
    }
  }, [checked, mail, wasWrong, onFocus, isWrong]);

  return (
    <Content root="會員登入" sector={props.sector}>
      <Section>
        <span>{props.title}</span>
        <p>{props.description}</p>
        <SendMail>
          <EmailArea>
            <div>
              <label>
                Email
                <input
                  type="text"
                  onChange={event => setMail(event.target.value)}
                  onFocus={() => {
                    setOnFocus(true);
                    setChecked(true);
                  }}
                  onBlur={() => setOnFocus(false)}
                  placeholder="請輸入您的 Email"
                />
                {isWrong && <ErrorMessage>{errMsg}</ErrorMessage>}
              </label>
            </div>
          </EmailArea>
          <Button template="primary" onClick={handleSubmit}>
            送出
          </Button>
        </SendMail>
        <p>
          如果註冊信箱無法使用，請聯絡我們，申請會員請聯絡客服信箱&emsp;
          <a href="mailto:tipoeservice@tipo.gov.tw">tipoeservice@tipo.gov.tw</a>
        </p>
      </Section>
    </Content>
  );
}

export default MailToUser;
