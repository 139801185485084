import React, { useState, useEffect } from 'react';
import { Formik, Field, FormikActions, FormikProps } from 'formik';
import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import { IpcCodeSearchForm } from 'src/style/PatentSearchStyle';
import { Button } from 'src/style/theme/Common';
import { IGetIpc } from 'src/types/PatentSearchTypes';
import CodeSearch from './CodeSearch';

/** IPC查詢表單 */
interface IFormData {
  /** 分類號 */
  code: string;
  /** 關鍵字 */
  keyword: string;
}

interface IProps {
  handleBackClick: () => void;
  handleIpcCodeList?: (ipcCodeList: string[]) => void;
}

/** IPC 代碼查詢 */
const IpcSearch: React.FC<IProps> = props => {
  const [ipcList, setIpcList] = useState<Array<IGetIpc>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [ipcCode, setIpcCode] = useState('');

  useEffect(() => {
    fetchIpcList('', '');
  }, []);

  const handleSubmit = (code: string) => {
    setIpcCode(code);
    fetchIpcList(code, '');
  };

  const fetchIpcList = async (code: string, keyword: string) => {
    setIsLoading(true);

    try {
      const res = await Api().get('ipc', {
        params: {
          code: code.trim(),
          keyword: keyword.trim(),
        },
      });

      setIpcList(res.data);
    } catch (err) {
      setIpcList([]);
      errorHandler(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <CodeSearch
      title="IPC 代碼查詢"
      ipcList={ipcList}
      isLoading={isLoading}
      handleBackClick={props.handleBackClick}
      handleIpcCodeList={props.handleIpcCodeList}
      handleSubmit={handleSubmit}
      searchFormBelowTitle
      searchForm={
        <Formik
          initialValues={{
            code: '',
            keyword: '',
          }}
          onSubmit={(values: IFormData, actions: FormikActions<IFormData>) => {
            actions.setSubmitting(false);
            ipcCode
              ? fetchIpcList(ipcCode, values.keyword)
              : fetchIpcList(values.code, values.keyword);
          }}
          render={(formikBag: FormikProps<IFormData>) => (
            <IpcCodeSearchForm>
              <label>
                <span>代碼</span>
                <Field
                  type="text"
                  name="code"
                  value={ipcCode}
                  onChange={(event: { target: any }) => {
                    setIpcCode(event.target.value);
                    formikBag.setFieldValue('code', event.target.value);
                  }}
                  autocomplete="off"
                  placeholder="請輸入 IPC 代碼"
                />
              </label>
              <label>
                <span>關鍵字</span>
                <Field type="text" name="keyword" autocomplete="off" placeholder="請輸入關鍵字" />
              </label>
              <Button margin="0" radius="0 4px 4px 0" type="submit" template="primary-dark">
                查詢代碼
              </Button>
            </IpcCodeSearchForm>
          )}
        />
      }
    />
  );
};

export default IpcSearch;
