import React, { useState } from 'react';
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';

import { IIndustrialClassType } from 'src/types/IndustryTypes';
import Checkbox from 'src/components/ui/interactive/checkbox/Checkbox';
import { Button } from 'src/style/theme/Common';
import {
  blue1,
  blue2,
  blue7,
  blue8,
  iceblue1,
  iceblue3,
  iceblue7,
  white,
  ultrawhite,
  ultrablackrgba,
} from 'src/style/theme/Color';
import MenuItem from './MenuItem';

import { connect } from 'react-redux';
import { ReduxAppState } from 'src/redux/reducers';
import { openCustomModal, closeCustomModal } from 'src/redux/actions/modalAction';
import { CustomModalType } from 'src/types/ModalTypes';
import IndustrialClassSpecModal from 'src/components/ui/interactive/modal/custom/IndustrialClassSpecModal';

const MenuContainer = styled.div<{ css?: any }>`
  box-shadow: 0 6px 20px 0 ${ultrablackrgba(0.1)};
  position: absolute;
  width: 100%;
  margin-top: 8px;
  ${props => props.css}
`;

const OptionsBlock = styled.div`
  display: flex;
  height: 400px;
  justify-content: space-between;
`;

const OptionsSubBlock = styled.div`
  width: 50%;
  overflow-x: hidden;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0 !important;
  }
  /* ::-ms-overflow-style: none; */
  overflow: -moz-scrollbars-none;
  padding: 8px 0;
`;

const Section = styled(OptionsSubBlock)`
  border-radius: 4px 0 0 0;
  background-color: ${ultrawhite};
`;

const Division = styled(OptionsSubBlock)`
  border-radius: 0;
  background-color: ${white};
  border-left: 1px solid ${iceblue7};
`;

const ResultsBlock = styled.div`
  border-radius: 0 0 4px 4px;
  padding: 4px 16px;
  background-image: linear-gradient(93deg, ${blue2}, ${blue1} 100%);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  > span {
    font-weight: bold;
    line-height: 38px;
    color: ${ultrawhite};
    flex-shrink: 0;
  }
  > button {
    flex-shrink: 0;
  }
`;

const ResultTags = styled.div`
  margin-left: 16px;
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  > span {
    line-height: 38px;
    color: ${ultrawhite};
  }
`;

const ResultTag = styled.div`
  border-radius: 4px;
  padding: 4px 16px;
  margin: 4px;
  background-color: ${blue8};
  color: ${iceblue3};
  user-select: none;
  display: flex;
  align-items: center;
  svg {
    cursor: pointer;
    width: 15px;
    margin-left: 6px;
  }
`;

const SelectAll = styled.div`
  padding: 2px 16px;
  align-items: center;
  :hover {
    background-color: ${blue7};
    cursor: pointer;
  }
`;

const BlockTitle = styled.span`
  margin: 16px;
  font-size: 20px;
  font-weight: bold;
  color: ${iceblue1};
`;

const BlockTitleLink = styled.span`
  margin-top: 16px;
  color: #1f8ba3;
  cursor: pointer;
`;

interface IReduxProps {
  industrialClassList: Array<IIndustrialClassType>;
  isModalOpen: boolean;
  openCustomModal: (customModalType: CustomModalType) => void;
  closeCustomModal: () => void;
}

interface IProps extends IReduxProps {
  checkedItems: Array<IIndustrialClassType>;
  setCheckedItems: (items: Array<IIndustrialClassType>) => void;
  /** 是否可不勾選就送出 */
  allowEmpty?: boolean;
  /** 是否顯示"不分行業"選項 */
  selectAll?: boolean;
  setSelectAll?: (result: boolean) => void;
  onSubmit: () => void;
  onReset: () => void;
  css?: any;
}

/** 行業別選單 */
const IndustrialClassMenu: React.FC<IProps> = ({
  industrialClassList,
  isModalOpen,
  openCustomModal,
  closeCustomModal,
  checkedItems,
  setCheckedItems,
  allowEmpty,
  selectAll,
  setSelectAll,
  onSubmit,
  onReset,
  css,
}) => {
  const [activeSectionId, setActiveSectionId] = useState<number>(0);
  const [activeDivisionId, setActiveDivisionId] = useState<number>(0);

  const checkedIds = checkedItems.map(item => item.id);
  const activeSection = industrialClassList.filter(item => item.id === activeSectionId)[0];
  const divisionList = activeSection && activeSection.children;
  const withSelectAllOption = typeof selectAll != 'undefined' && typeof setSelectAll != 'undefined';

  /** 列出所有子項目 id */
  const listAllChildrenId = (item: IIndustrialClassType) => {
    let childrenIdList: number[] = [];
    item.children &&
      item.children.forEach(child => {
        childrenIdList.push(Object.values(child)[0]);
        child.children &&
          child.children.forEach(grandson => {
            childrenIdList.push(Object.values(grandson)[0]);
          });
      });
    return childrenIdList;
  };

  /** 列出被勾選的子項目 id */
  const listCheckedChildrenId = (item: IIndustrialClassType) => {
    let result: number[] = [];
    listAllChildrenId(item).forEach(childId => {
      if (checkedIds.includes(childId)) {
        result.push(childId);
      }
    });
    return result;
  };

  /** 檢查父項是否有被勾選 */
  const isParentChecked = (id: number) => {
    let result = false;
    checkedItems.forEach(item => {
      if (listAllChildrenId(item).includes(id)) {
        result = true;
      }
    });
    return result;
  };

  const handleCheck = (clickItem: IIndustrialClassType) => {
    const checkedChildrenId = listCheckedChildrenId(clickItem);

    if (checkedIds.includes(clickItem.id)) {
      // 若點擊的項目原本已被勾選，則取消勾選該項目
      checkedItems.splice(checkedIds.indexOf(clickItem.id), 1);
      setCheckedItems([...checkedItems]);
    } else if (isParentChecked(clickItem.id)) {
      // 若點擊的項目的父項已被勾選，則不做任何事
    } else if (selectAll) {
      // 若為全選狀態，則取消全選並勾選點擊的項目
      selectAll && handleSelectAll();
      checkedItems.push(clickItem);
    } else if (checkedChildrenId.length > 0) {
      // 若點擊的項目已有部分子項被勾選，則取消勾選所有子項並勾選點擊的項目
      checkedChildrenId.forEach(item => {
        const i = checkedIds.indexOf(item);
        checkedIds.splice(i, 1);
        checkedItems.splice(i, 1);
      });
      checkedItems.push(clickItem);
      setCheckedItems([...checkedItems]);
    } else if (checkedIds.length < 5) {
      // 最多僅能勾選 5 項
      checkedItems.push(clickItem);
      setCheckedItems([...checkedItems]);
    }
  };

  const handleSelectAll = () => {
    if (typeof setSelectAll !== 'undefined') {
      if (selectAll) {
        // 若已全選，則取消全選
        setSelectAll(false);
      } else {
        // 若尚未全選，則將已選項目清空後再全選
        setCheckedItems([]);
        setSelectAll(true);
      }
    }
  };

  const resetActiveItems = () => {
    setActiveSectionId(0);
    setActiveDivisionId(0);
  };

  return (
    <MenuContainer css={css}>
      <OptionsBlock>
        <Section>
          <BlockTitle>請選擇行業別</BlockTitle>
          <BlockTitleLink onClick={() => openCustomModal(CustomModalType.INDUSTRIAL_CLASS_SPEC)}>
            行業別說明簡表
          </BlockTitleLink>
          {withSelectAllOption && (
            <SelectAll onClick={resetActiveItems}>
              <Checkbox name="不分行業" color={blue2} checked={selectAll || false}>
                <input type="checkbox" checked={selectAll} onChange={handleSelectAll} />
              </Checkbox>
            </SelectAll>
          )}
          {industrialClassList.map(item => (
            <MenuItem
              key={item.id}
              item={item}
              checked={checkedIds.includes(item.id)}
              selected={item.id === activeSectionId}
              partialChecked={listCheckedChildrenId(item).length > 0}
              disableChecked={selectAll}
              hasChildren={typeof item.children != 'undefined'}
              onActive={setActiveSectionId}
              onCheck={handleCheck}
            />
          ))}
        </Section>
        <Division>
          {divisionList &&
            divisionList.map(item => (
              <MenuItem
                key={item.id}
                item={item}
                checked={checkedIds.includes(item.id)}
                selected={item.id === activeDivisionId}
                partialChecked={listCheckedChildrenId(item).length > 0}
                disableChecked={selectAll || checkedIds.includes(activeSectionId)}
                hasChildren={typeof item.children != 'undefined'}
                onActive={setActiveDivisionId}
                onCheck={handleCheck}
              />
            ))}
        </Division>
      </OptionsBlock>
      <ResultsBlock>
        <span>已選擇&emsp;{selectAll ? 1 : checkedIds.length}/5</span>
        <ResultTags>
          {!allowEmpty && checkedIds.length === 0 && !selectAll ? (
            <span>請選擇至少一項行業別。</span>
          ) : (
            <>
              {withSelectAllOption && selectAll && (
                <ResultTag>
                  不分行業
                  <FaTimes onClick={handleSelectAll} />
                </ResultTag>
              )}
              {checkedItems.map(item => (
                <ResultTag key={item.id}>
                  {item.description}
                  <FaTimes onClick={() => handleCheck(item)} />
                </ResultTag>
              ))}
            </>
          )}
        </ResultTags>
        <Button
          template="primary"
          margin="4px 0 0 0"
          onClick={onSubmit}
          disabled={!allowEmpty && checkedIds.length === 0 && !selectAll}
        >
          確定
        </Button>
        <Button template="light" margin="4px 0 0 10px" onClick={onReset}>
          取消
        </Button>
      </ResultsBlock>
      {isModalOpen && <IndustrialClassSpecModal onClose={() => closeCustomModal()} />}
    </MenuContainer>
  );
};

const mapStateToProps = (state: ReduxAppState) => {
  return {
    industrialClassList: state.industrialClassReducer.industrialClassList,
    isModalOpen: state.modalReducer.customModalType === CustomModalType.INDUSTRIAL_CLASS_SPEC,
  };
};

const mapDispatchToProps = {
  openCustomModal,
  closeCustomModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IndustrialClassMenu);
