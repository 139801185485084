import React from 'react';
import styled from 'styled-components';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import { Api, baseURL } from 'src/api/helpers/apiBase';
import { alertMessage } from 'src/utils/ModalUtils';
import { IErrorResponseData } from 'src/apps/error-handler/ErrorHandler';
import { dateFormatter } from 'src/utils/Formatter';
import { IGetColumnistArticle } from 'src/types/api/ColumnistArticleApiTypes';
import { iceblue5 } from 'src/style/theme/Color';
import { isIE } from 'src/apps/browser/UserAgent';

import TopNav from 'src/components/common/nav/top-nav/TopNav';
import store from 'src/redux/store';
import HeaderRoute from 'src/routes/HeaderRoute';

const Container = styled.div`
  display: flex;
  padding: 20px 32px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  :hover {
    background-color: #f5fdfe;
  }
`;
const LeftContainer = styled.div`
  padding-right: 16px;
  flex: 3;
`;
const RightContainer = styled.div`
  flex: 2;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  padding-top: 24px;
  img {
    border-radius: 4px;
    width: 90%;
  }
`;

const Title = styled.a`
  padding: 0 0 8px 0;
  display: block;
  color: #077790;
  font-size: 18px;
  cursor: pointer;
`;
const Text = styled.div`
  color: #666666;
  padding: 0 0 4px 0;
  padding-left: 3em;
  text-indent: -3em;
`;
const Category = styled.div`
  font-size: 15px;
  padding: 0 16px 0 0;
  display: block;
  color: ${iceblue5};
`;
export const handleArticleAncherOnClick = async (
  e: React.MouseEvent<HTMLElement, MouseEvent>,
  id: number,
) => {
  e.preventDefault();
  window.open(`/article/columnist/${id}/view`);
};

export const ArticleContext = React.createContext(false);

function getOrCreateContainer(
  shadowRoot: ShadowRoot,
  bodyContent: string,
): { container: HTMLElement; bodyContainsContainer: boolean } {
  let container = shadowRoot.querySelector('.container') as HTMLElement;
  let potentialContainer = new DOMParser()
    .parseFromString(bodyContent, 'text/html')
    .querySelector('.container');

  if (!container) {
    if (potentialContainer) {
      container = potentialContainer as HTMLElement;
    } else {
      container = document.createElement('div');
      container.className = 'container';
    }
    shadowRoot.appendChild(container);
  }

  return {
    container: container,
    bodyContainsContainer: !!potentialContainer,
  };
}

export const showArticleContent = async (id: number) => {
  try {
    if (isIE) {
      window.location.replace(process.env.REACT_APP_API_URL + 'article/columnist/' + id + '/html');
    } else {
      const columnResponse = await Api({}).get(`article/columnist/${id}`);
      const columnData = columnResponse.data;

      if (!columnData || !columnData.columnistArticleCategory) {
        return;
      }

      const articleHtml = await Api({}).get(`article/columnist/${id}/html`);

      if (!articleHtml.data) {
        return;
      }

      const parser = new DOMParser();
      const doc = parser.parseFromString(articleHtml.data, 'text/html');

      const headers = doc.querySelectorAll('header');

      // 移除 <header></header> 標籤
      headers.forEach(header => {
        if (header.parentNode) {
          header.parentNode.removeChild(header);
        }
      });

      const serializedHtml = new XMLSerializer().serializeToString(doc);

      const bodyContent = serializedHtml.split('<body>')[1].split('</body>')[0];

      const topNavContainer = document.createElement('div');
      document.body.appendChild(topNavContainer);

      ReactDOM.render(
        <Provider store={store}>
          <BrowserRouter>
            <ArticleContext.Provider value={true}>
              <TopNav />
            </ArticleContext.Provider>
          </BrowserRouter>
        </Provider>,
        topNavContainer,
      );

      const contentContainer = document.createElement('div');

      const oldContentByDivRoot = document.getElementById('root');
      if (oldContentByDivRoot != null) {
        document.body.replaceChild(contentContainer, oldContentByDivRoot);
      }

      // 利用 Shadow 隔離文章的 CSS
      const shadowRoot = contentContainer.attachShadow({ mode: 'open' });

      const cssLinks = doc.querySelectorAll('link[rel="stylesheet"]');

      // 將文章內的 CSS 添加到 shadowRoot
      cssLinks.forEach(link => {
        const href = link.getAttribute('href');

        const newLinkElem = document.createElement('link');
        if (href) {
          newLinkElem.href = href;
        }
        newLinkElem.rel = 'stylesheet';
        newLinkElem.type = 'text/css';

        shadowRoot.appendChild(newLinkElem);
      });

      const { container: containerDiv, bodyContainsContainer } = getOrCreateContainer(
        shadowRoot,
        bodyContent,
      );
      containerDiv.style.width = '60%';

      const headerRouteContainer = document.createElement('div');
      ReactDOM.render(
        <HeaderRoute categoryId={columnData.columnistArticleCategory.id} />,
        headerRouteContainer,
      );

      // 在 <div class="container"> 的開頭插入文章路徑
      containerDiv.prepend(headerRouteContainer);

      if (!bodyContainsContainer) {
        const articleContainer = document.createElement('div');
        articleContainer.innerHTML = bodyContent;
        containerDiv.appendChild(articleContainer);
      }
    }
  } catch (error) {
    if (error.response && error.response.data) {
      const errorBlob = new Blob([error.response.data], { type: 'application/json' });
      const reader = new FileReader();
      reader.addEventListener('loadend', function() {
        const resp = JSON.parse(reader.result as string) as IErrorResponseData;
        resp.resultMessage && alertMessage(resp.resultMessage);
      });
      reader.readAsText(errorBlob);
    }
    console.error(error);
  }
};

interface IProps {
  columnData: IGetColumnistArticle;
}

const ColumnistArticleItem = ({ columnData }: IProps) => (
  <Container>
    <LeftContainer>
      <Category>
        {columnData.columnistArticleCategory ? columnData.columnistArticleCategory.name : ''}
      </Category>
      <Title onClick={e => handleArticleAncherOnClick(e, columnData.id)}>{columnData.title}</Title>
      <Text>作者：{columnData.author}</Text>
      <Text>發佈時間：{dateFormatter(columnData.startDate)}</Text>
      <Text>摘要：{columnData.summary}</Text>
    </LeftContainer>
    <RightContainer>
      {columnData.mainImage && columnData.mainImage.id && (
        <img
          src={baseURL + 'article/columnist/' + columnData.id + '/image/' + columnData.mainImage.id}
          alt=""
        />
      )}
    </RightContainer>
  </Container>
);

export default ColumnistArticleItem;
