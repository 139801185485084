import React from 'react';
import styled from 'styled-components';
import Accordion from './accordion/Accordion';
import NoResult from './accordion/common/NoResult';
import { IRssFacet, IAdvancedKeyword, IFacetFieldCount } from 'src/types/api/NewsApiTypes';
import { Spinner } from 'src/components/ui/interactive/Spinner';
import { rssFacetText } from 'src/constants/RssSearchConstants';
import { FaFilter } from 'react-icons/fa';

const Container = styled.div<{ isShow: boolean }>`
  margin-left: 32px;
  margin-top: -16px;
  width: ${props => (props.isShow ? '240px' : '40px')};
  ${props => (props.isShow ? 'min-width: 240px;' : '')}
  max-width: 240px;
  display: block;
  background-color: #f9f9f9;
`;

const MenuTitle = styled.div<{ isShow: boolean }>`
  border-radius: ${props => (props.isShow ? '4px 4px 0 0' : '4px')};
  display: flex;
  align-items: flex-end;
  padding: ${props => (props.isShow ? '8px 16px' : '12px')};
  height: 40px;
  background-image: linear-gradient(99deg, #0cafcc, #077790 100%);
  font-size: 15px;
  font-weight: 500;
  color: #ffffff;
  cursor: ${props => (props.isShow ? 'inherit' : 'pointer')};
  svg : {
    font-size: 18px;
  }
`;

const Title = styled.div`
  cursor: default;
  margin-right: auto;
`;
const Collapse = styled.div`
  cursor: pointer;
  color: #00cddd;
`;

const Content = styled.div`
  padding-top: 16px;
  border: 1px solid #dee2e4;
  border-radius: 0 0 4px 4px;
`;

interface IProps {
  isShow: boolean;
  isLoading: boolean;
  facets: Array<IRssFacet>;
  advanceKeywords?: Array<IAdvancedKeyword>;
  handleFacetClick: (keyCode: string, facetFieldCount?: IFacetFieldCount) => void;
  handleDisplay: (display: boolean) => void;
}

/** 篩選 */
const Filter: React.FC<IProps> = props => {
  const { isLoading, facets, advanceKeywords } = props;

  return (
    <Container isShow={props.isShow}>
      {props.isShow ? (
        <MenuTitle isShow={props.isShow}>
          <Title>進階篩選</Title>
          <Collapse onClick={() => props.handleDisplay(false)}>收合</Collapse>{' '}
        </MenuTitle>
      ) : (
        <MenuTitle isShow={props.isShow} onClick={() => props.handleDisplay(true)}>
          <FaFilter />
        </MenuTitle>
      )}
      {props.isShow && (
        <Content>
          {isLoading ? (
            <Spinner margin="58px auto" />
          ) : facets && facets.length > 0 ? (
            facets.map(field => {
              const advancedKeyword =
                advanceKeywords && advanceKeywords.find(item => item.keyCode === field.keyCode);

              return (
                field.rssFacetFieldCount.length > 0 && (
                  <Accordion
                    key={field.keyCode}
                    keyCode={field.keyCode}
                    title={rssFacetText.get(field.keyCode)}
                    tag={advancedKeyword && advancedKeyword.keyword}
                    content={field.rssFacetFieldCount}
                    handleFacetClick={props.handleFacetClick}
                  />
                )
              );
            })
          ) : (
            <NoResult />
          )}
        </Content>
      )}
    </Container>
  );
};

export default Filter;
