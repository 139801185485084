import React from 'react';
import styled from 'styled-components';
import ReactDatePicker from 'react-datepicker';
import { FaCalendarAlt } from 'react-icons/fa';
import { IconLabel } from 'src/style/PatentSearchStyle';
import { iceblue2, iceblue5, iceblue6, ultrawhite } from 'src/style/theme/Color';

// 必須引入才有樣式
import 'react-datepicker/dist/react-datepicker.css';

const Label = styled(IconLabel)`
  input {
    width: 100%;
    padding: 5px 40px 5px 16px;
    border-radius: 4px;
    border: solid 1px ${iceblue5};
    background-color: ${ultrawhite};
    ::placeholder {
      color: ${iceblue6};
    }
  }

  /* DatePicker 會自己在input外產生兩個div，必須強制給寬度 */
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__input-container {
    width: 100%;
  }
  .react-datepicker-popper {
    z-index: 2;
  }
`;

interface IProps {
  width?: string;
  selected?: Date | null | undefined;
  onChange: (date: Date | null, event: React.SyntheticEvent<any, Event> | undefined) => void;
}

const DatePicker: React.FC<IProps> = props => {
  return (
    <Label width={props.width}>
      <ReactDatePicker
        placeholderText="年-月-日"
        dateFormat="yyyy-MM-dd"
        showMonthDropdown
        showYearDropdown
        scrollableYearDropdown
        yearDropdownItemNumber={5}
        selected={props.selected}
        onChange={props.onChange}
      />
      <FaCalendarAlt color={iceblue2} />
    </Label>
  );
};

export default DatePicker;
