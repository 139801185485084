import React from 'react';
import styled from 'styled-components';
import number_search from 'src/assets/images/manual/patent-search/number_search.png';

const ImgContainer = styled.div<{ height: string; imageUrl: string }>`
  position: relative;
  height: ${props => props.height}px;
  padding: 10px 0;
  background-image: url(${props => props.imageUrl});
  background-size: contain;
  background-repeat: no-repeat;
`;
export default function PatentSearchManual() {
  return (
    <>
      <p>號碼檢索可以選擇不同國別，使用公開/公告號或申請號，來進行專利檢索。</p>
      <ol>
        <li>從功能列點選「專利檢索」。</li>
        <li>點選「號碼檢索」功能。</li>
        <li>選擇要檢索的國家範圍，預設為台灣TW。</li>
        <li>選擇要檢索的號碼類別，預設為公開/公告號。</li>
        <li>點選「查詢」進行查詢</li>
      </ol>
      <ImgContainer height="350" imageUrl={number_search} />
    </>
  );
}
