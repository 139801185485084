import { Api } from 'src/api/helpers/apiBase';
import { IGetRelatedNews } from 'src/types/api/NewsApiTypes';

export const NewsService = () => {
  return {
    getRelatedNews: async (id: string, topicId?: string | null) => {
      const topicIdStr = topicId ? '?topicId=' + topicId : '';
      return Api()
        .get(`rss/${id}/related` + topicIdStr)
        .then(resp => resp.data as Array<IGetRelatedNews>);
    },
    getNewsLink: async (uuid: string) => {
      return Api()
        .get(`rss/${uuid}/view`)
        .then(resp => resp.data as String);
    },
  };
};
