import React, { useState, useEffect } from 'react';
import { Api } from 'src/api/helpers/apiBase';

const HeaderRoute = ({ categoryId }: { categoryId: number }) => {
  const [featureName, setFeatureName] = useState<string>();

  useEffect(() => {
    const fetchFeatureName = async () => {
      try {
        const res = await Api().get('/article/columnist/categories');

        const matchedCategory = res.data.find((cat: any) => cat.id === categoryId);

        if (matchedCategory) setFeatureName(matchedCategory.name);
      } catch (error) {
        console.error('Failed to fetch category name:', error);
      }
    };

    fetchFeatureName();
  }, [categoryId]);

  return <>{`首頁 / 專欄文章 / ${featureName}`}</>;
};

export default HeaderRoute;
