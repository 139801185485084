import React, { useState, useEffect, useRef, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import ReactImageCrop from 'react-image-crop';
import styled from 'styled-components';
import {
  blue2,
  blue3,
  blue7,
  blue8,
  iceblue3,
  iceblue5,
  ultrawhite,
  ultrablackrgba,
  iceblue6,
} from 'src/style/theme/Color';
import { FaMinus, FaSearch, FaSortAmountDown } from 'react-icons/fa';
import { SystemMessage } from 'src/apps/applicationMessages';
import { dateRangeItems, sortTypeItems } from 'src/constants/PatentSearchConstants';
import ResultGridItem from 'src/components/result/ResultGridItem';
import FilterDropdown from 'src/components/ui/interactive/dropdown-select/FilterDropdown';
import WaterfallGrid from 'src/components/ui/interactive/WaterfallGrid';
import InfiniteScroll from 'src/components/ui/interactive/InfiniteScroll';
import { Spinner } from 'src/components/ui/interactive/Spinner';
import { numFormatter } from 'src/utils/Formatter';
import { alertMessage } from 'src/utils/ModalUtils';
import { IPaginatedData } from 'src/types/PaginatedDataTypes';
import { IGetPatentTypes } from 'src/types/IGetPatentTypes';
import { ILocBasic } from 'src/types/loc';

import { connect } from 'react-redux';
import { ReduxAppState } from 'src/redux/reducers';
import { openCustomModal } from 'src/redux/actions/modalAction';
import {
  navigateToNextPage,
  selectDateRange,
  changeSortOrder,
  fetchAppNumList,
} from 'src/redux/byImage/search/action';
import { fetchByImageResult } from 'src/redux/byImage/result/action';
import { IByImageRequest, IByImageResponse } from 'src/redux/byImage/search/type';
import useOutSideClick from 'src/hooks/useOutSideClick';
import { LocAutocomplete } from './loc/LocAutocomplete';
import { getLocClasses } from './ByImage';
import CropImageModal from '../ui/interactive/modal/custom/CropImageModal';
import { CustomModalType } from 'src/types/ModalTypes';

const Container = styled.div`
  width: calc(100% - 200px);
  margin: 16px auto;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 0 50px 0 ${ultrablackrgba(0.1)};
  background-color: ${ultrawhite};
  min-height: calc(100vh - 62px - 32px);
`;

const HeaderBlock = styled.div`
  position: sticky;
  top: 60px;
  z-index: 100;
`;

const Header = styled.div`
  height: 46px;
  color: ${ultrawhite};
  background-image: linear-gradient(95deg, #2ec3c2, #0cafcc);
  border-radius: 4px 4px 0 0;
`;

const Feature = styled.div`
  padding: 16px 32px 0 32px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
`;

const SearchCostBox = styled.div`
  margin-top: 8px;
  min-height: 22px;
  color: ${blue2};
  opacity: 0.6;
`;

const Content = styled.div`
  min-height: 450px;
`;

const Subheaders = styled.div`
  padding: 5px 32px 15px 32px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background-color: ${blue7};
  position: relative;
  * {
    z-index: 10;
  }
`;

const Overlap = styled.div`
  height: 42px;
  width: 100%;
  z-index: 9;
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient(95deg, #2ec3c2, #0cafcc);
`;

const HeaderLeft = styled.div`
  flex-basis: 284px;
  padding-right: 24px;
`;

const HeaderMiddle = styled.div`
  flex: 1;
`;

const HeaderRight = styled.div`
  flex-basis: 16.7%;
  display: flex;
  align-items: flex-start;
  padding-top: 62px;
`;
const ImageController = styled.div`
  position: absolute;
  border-radius: 4px;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 63, 84, 0.7);
  width: 100%;
  height: 100%;
  display: none;
  padding: 0 32px;
  left: 0;
  top: 0;

  span {
    padding: 8px 14px;
    color: ${iceblue3};
    background-color: ${blue7};
    border-radius: 4px;
    cursor: pointer;
    :hover {
      color: ${blue2};
    }
  }
`;

const ImageBasis = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${iceblue5};
  background-color: ${ultrawhite};
  border-radius: 4px;
  width: 260px;
  height: 192px;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);

  > span {
    width: 100%;
    text-align: center;
    padding-top: 25%;
  }

  > input {
    display: none;
  }

  img {
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
    max-width: 260px;
    max-height: 192px;
  }

  :hover {
    ${ImageController} {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
`;

const NoResult = styled.div`
  margin: 16px 32px;
  padding: 16px 32px;
  background-color: ${blue8};

  div {
    color: ${blue2};
    font-weight: 600;
    margin-bottom: 8px;
  }
  span {
    color: ${iceblue3};
  }
`;
const SearchArea = styled.div`
  height: 61px;
  width: 100%;
  display: flex;
  border-radius: 4px;
  position: relative;
  line-height: 60px;
  text-align: left;
  border-radius: 4px;
  box-shadow: 0 4px 16px -4px rgba(38, 50, 55, 0.3);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  background-color: rgba(255, 255, 255, 0.9);
`;

const SearchTitle = styled.div`
  font-size: 13px;
  padding-left: 16px;
  color: ${iceblue6};
`;

const InputContainer = styled.div`
  height: 61px;
  flex: 1;
  padding-left: 16px;
  position: relative;
  display: flex;
`;

const Input = styled.input.attrs({ type: 'text' })`
  height: 61px;
  padding: 20px 8px;
  padding-right: 48px;
  width: 100%;
  border-radius: 4px;
  color: #333333;
  background-color: transparent;
  border: none;
  outline: none;
  ::placeholder {
    color: ${iceblue6};
  }
`;

const Icon = styled.div`
  position: absolute;
  right: 16px;
  height: 61px;
  background-color: transparent;
  border-radius: 4px;
  display: flex;
  align-items: center;
  svg {
    display: block;
    :hover {
      cursor: pointer;
    }
  }
`;

const LocContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  padding: 16px 8px;

  * {
    z-index: 0;
  }
`;

const LocTitle = styled.span`
  /* z-index: 0; */
  position: absolute;
  color: ${iceblue5};
`;

const LocTag = styled.span`
  position: relative;
  color: ${iceblue3};
  background-color: ${blue8};
  border-radius: 4px;
  padding: 4px 34px 4px 16px;
  margin-left: 48px;
  margin-bottom: 8px;
  height: 30px;
  overflow: hidden;
  /* z-index: 0; */
  :before {
    content: '...';
    position: absolute;
    width: 1em;
    height: 30px;
    top: 0;
    right: 34px;
    background-color: ${blue8};
  }

  :after {
    content: '';
    position: absolute;
    width: 1em;
    height: 30px;
    right: 34px;
    background-color: ${blue8};
  }
`;

const LocTagIcon = styled(FaMinus)`
  position: absolute;
  top: 7.5px;
  right: 8px;
  margin-left: 12px;
  :hover {
    color: ${blue3};
    cursor: pointer;
  }
`;

interface IReduxMappingProps {
  byImageRequest: IByImageRequest;
  byImageResponse: Array<IByImageResponse>;
  isAppNumListLoading: boolean;
  isFirstPageLoading: boolean;
  isNextPageLoading: boolean;
  paginatedData: IPaginatedData<IGetPatentTypes>;
  nextPageErrMsg?: string;
  isCropImageModalOpen: boolean;
  navigateToNextPage: () => void;
  selectDateRange: (dateRange: string) => void;
  changeSortOrder: (sortByDate: string) => void;
  fetchByImageResult: () => void;
  fetchAppNumList: (image: File, locClasses: Array<ILocBasic>) => void;
  openCustomModal: (customModalType: CustomModalType) => void;
}

interface IProps extends IReduxMappingProps, RouteComponentProps {}

/** 以圖找圖查詢結果 */
const ByImageResult = (props: IProps) => {
  const {
    byImageRequest,
    isAppNumListLoading,
    isFirstPageLoading,
    paginatedData,
    isNextPageLoading,
    isCropImageModalOpen,
    nextPageErrMsg,
    navigateToNextPage,
    fetchByImageResult,
    fetchAppNumList,
    openCustomModal,
    history,
    location,
  } = props;

  const defaultSelectedItem = {
    dateRange: { name: '不限時間', value: '' },
    sortByDate: { name: '排序', value: '' },
  };
  const [selectedItem, setSelectedItem] = useState(defaultSelectedItem);
  const [inputText, setInputText] = useState('');
  const [isSuggestionShow, setIsSuggestionShow] = useState(false);
  const [selectedLocs, setSelectedLocs] = useState<Array<ILocBasic>>(location.state.locClasses);
  const [croppedImg, setCroppedImg] = useState<string | undefined>();
  const [crop, setCrop] = useState<ReactImageCrop.Crop | undefined>(location.state.crop);
  const [croppedImgFile, setCroppedImgFile] = useState<File | undefined>(location.state.image);
  const [imgSrc, setImgSrc] = useState<string>(location.state.imgSrc);
  const fileUploaderRef = useRef<HTMLInputElement>(null);

  const inputRef = useRef<HTMLInputElement>(null);

  const node = useOutSideClick({
    show: isSuggestionShow,
    setShow: setIsSuggestionShow,
  });

  const didMountRef = useRef(false);

  useEffect(() => {
    const { image, imgSrc: locationImgSrc, locClasses } = location.state;
    const prepareImg = async () => {
      fetch(locationImgSrc).catch(() => {
        // 若連結已失效，則重新自檔案建立 object url
        setImgSrc(URL.createObjectURL(image));
      });
      const fileObjectUrl = URL.createObjectURL(image);
      setCroppedImg(fileObjectUrl);
    };

    if (!didMountRef.current) {
      // Mimic componentDidMount
      didMountRef.current = true;
      prepareImg();

      // 從搜尋頁來或重新整理(redux store內為initial state)的話，要重新發API查詢
      const isFetch = history.action !== 'POP' || byImageRequest.pageNo === 0;
      if (isFetch && location.state) {
        image && locClasses && fetchAppNumList(image, locClasses);
      }
    } else {
      // Mimic componentDidUpdate
      byImageRequest.pageNo > 0 && fetchByImageResult();
    }
  }, [byImageRequest, fetchAppNumList, fetchByImageResult, history.action, location.state]);

  const findDistanceByAppNum = (appNum: string) => {
    const patent = props.byImageResponse.find(resp => resp.patent_name === appNum);
    return patent ? patent.distance : undefined;
  };

  const handleSearch = useCallback(() => {
    croppedImgFile && fetchAppNumList(croppedImgFile, selectedLocs);
  }, [croppedImgFile, selectedLocs, fetchAppNumList]);

  const handleReCrop = () => {
    openCustomModal(CustomModalType.CROP_IMAGE);
  };

  const handleSuggestionClick = (loc: ILocBasic) => {
    if (selectedLocs.find(selected => selected.locClasses === loc.locClasses) === undefined) {
      setSelectedLocs(prev => [...prev, loc]);
      setInputText('');
    } else {
      alertMessage(SystemMessage.LOC_SELECTED);
    }
  };

  const handleFileUpload = () => {
    if (fileUploaderRef.current !== null) {
      const files = fileUploaderRef.current.files;
      const uploadedFile = files !== null ? files[0] : undefined;

      if (uploadedFile) {
        history.replace({ ...location, state: { ...location.state, image: uploadedFile } });
        setImgSrc(URL.createObjectURL(uploadedFile));
        setCroppedImgFile(uploadedFile);
        setCroppedImg(URL.createObjectURL(uploadedFile));

        openCustomModal(CustomModalType.CROP_IMAGE);
      }
    }
  };

  return (
    <Container>
      <HeaderBlock>
        <Header>
          <Feature>
            <Title>以圖找圖搜尋結果</Title>
          </Feature>
        </Header>
        <Subheaders>
          <Overlap />
          <HeaderLeft>
            <ImageBasis>
              <img src={croppedImg} alt="" />
              <input ref={fileUploaderRef} type="file" onChange={() => handleFileUpload()} />
              {!isFirstPageLoading && !isAppNumListLoading && (
                <ImageController>
                  <span
                    onClick={() =>
                      fileUploaderRef.current !== null && fileUploaderRef.current.click()
                    }
                  >
                    重新上傳
                  </span>
                  <span onClick={() => handleReCrop()}>裁切</span>
                </ImageController>
              )}
            </ImageBasis>
          </HeaderLeft>
          <HeaderMiddle>
            <SearchArea>
              <SearchTitle>LOC</SearchTitle>
              <InputContainer ref={node as any}>
                <Input
                  ref={inputRef as any}
                  value={inputText}
                  onChange={e => setInputText(e.target.value)}
                  onFocus={() => setIsSuggestionShow(true)}
                  onKeyUp={e => e.keyCode === 13 && handleSearch()}
                  placeholder="請輸入 LOC"
                />
                <LocAutocomplete
                  cssTop="65px"
                  isShow={isSuggestionShow}
                  setShow={setIsSuggestionShow}
                  inputText={inputText}
                  handleSuggestionClick={handleSuggestionClick}
                />
              </InputContainer>
              <Icon>
                <FaSearch onClick={handleSearch} color="#077790" size="20px" />
              </Icon>
            </SearchArea>
            <LocContainer>
              <LocTitle>
                {selectedLocs.length > 0
                  ? '已選'
                  : '尚未選取國際工業設計分類，將會自所有分類中搜尋'}
              </LocTitle>
              {selectedLocs.length > 0 &&
                selectedLocs.map(loc => (
                  <LocTag key={loc.locClasses}>
                    {getLocClasses(loc)}&nbsp;&nbsp;{loc.twLoc}&nbsp;&nbsp;{loc.enLoc}
                    <LocTagIcon
                      onClick={() => setSelectedLocs(prev => prev.filter(item => item !== loc))}
                    />
                  </LocTag>
                ))}
            </LocContainer>
            <SearchCostBox>
              {!(isFirstPageLoading || isAppNumListLoading) &&
                paginatedData.costTime !== 0 &&
                `共 ${numFormatter(paginatedData.totalCount)} 件搜尋結果 花費 ${
                  paginatedData.costTime
                } 秒`}
            </SearchCostBox>
          </HeaderMiddle>
          <HeaderRight>
            <FilterDropdown
              items={dateRangeItems}
              activeItem={selectedItem.dateRange}
              handleOnclick={item => {
                props.selectDateRange(item.value);
                setSelectedItem(prevState => {
                  return {
                    ...prevState,
                    dateRange: item,
                  };
                });
              }}
              customStyleSvgSize={16}
            />
            <FilterDropdown
              items={sortTypeItems}
              activeItem={selectedItem.sortByDate}
              handleOnclick={item => {
                props.changeSortOrder(item.value);
                setSelectedItem(prevState => {
                  return {
                    ...prevState,
                    sortByDate: item,
                  };
                });
              }}
              customStyleSvg={<FaSortAmountDown size={16} />}
            />
          </HeaderRight>
        </Subheaders>
      </HeaderBlock>
      <Content>
        {isFirstPageLoading || isAppNumListLoading ? (
          <Spinner width="150px" margin="100px auto" />
        ) : paginatedData.totalCount <= 0 ? (
          // 無資料時
          <>
            {/* 第一頁表示已進行查詢 */}
            {paginatedData.pageNo === 1 && (
              <NoResult>
                <div>查無搜尋結果</div>
                <span>請嘗試修改篩選條件或回到以圖找圖檢索頁面重新進行搜尋。</span>
              </NoResult>
            )}
          </>
        ) : (
          // 有資料時
          <InfiniteScroll
            isNextPageLoading={isNextPageLoading}
            isNextPageError={!!nextPageErrMsg}
            errorMessage={nextPageErrMsg}
            handleNextPageLoad={navigateToNextPage}
            handleReload={fetchByImageResult}
            totalCount={paginatedData.totalCount}
            currentPage={paginatedData.pageNo}
            totalPages={paginatedData.totalPages}
          >
            <WaterfallGrid
              columns={3}
              elements={paginatedData.data.map((item, idx) => (
                <ResultGridItem
                  key={item.id}
                  isPreviewMode={false}
                  isPreviewing={false}
                  patent={item}
                  hasOverlay
                  listIndex={idx}
                  distance={findDistanceByAppNum(
                    item.bibliographic.applicationReference.documentId.docNumber,
                  )}
                />
              ))}
            />
          </InfiniteScroll>
        )}
      </Content>
      {isCropImageModalOpen && (
        <CropImageModal
          imgSrc={imgSrc}
          setCroppedImg={setCroppedImg}
          crop={crop}
          setCrop={setCrop}
          setCroppedImgFile={setCroppedImgFile}
        />
      )}
    </Container>
  );
};

const mapStateToProps = (state: ReduxAppState) => {
  return {
    byImageRequest: state.byImageSearchReducer.request,
    byImageResponse: state.byImageSearchReducer.response,
    paginatedData: state.byImageResultReducer.paginatedData,
    isAppNumListLoading: state.byImageSearchReducer.isAppNumListLoading,
    isFirstPageLoading:
      state.byImageSearchReducer.request.pageNo === 1 && state.byImageResultReducer.isLoading,
    isNextPageLoading:
      state.byImageSearchReducer.request.pageNo > 1 && state.byImageResultReducer.isLoading,
    nextPageErrMsg: state.byImageResultReducer.nextPageErrMsg,
    isCropImageModalOpen: state.modalReducer.customModalType === CustomModalType.CROP_IMAGE,
  };
};

const mapDispatchToProps = {
  navigateToNextPage,
  selectDateRange,
  changeSortOrder,
  fetchByImageResult,
  fetchAppNumList,
  openCustomModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ByImageResult);
