import { Api } from 'src/api/helpers/apiBase';
import { IGetMemberCompanyUsers } from 'src/types/api/CommonApiTypes';

export const CommonService = () => {
  return {
    // 取得同公司其餘會員編號及名稱
    getCompanyMember: async () =>
      Api()
        .get('member/company/users')
        .then(res => res.data as Array<IGetMemberCompanyUsers>),
  };
};
