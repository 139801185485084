import React from 'react';
import _ from 'lodash';
import Checkbox from 'src/components/ui/interactive/checkbox/Checkbox';
import SlideToggle from 'src/components/ui/interactive/SlideToggle';

interface IProps {
  checked: boolean; //是否勾選
  partialChecked?: boolean; //是否有子項被勾選
  disableChecked?: boolean; //是否父項被勾選
  idx: number;
  type: string;
  change: (idx: number, type: string) => void;
  toggleStyle?: boolean; // true時為toggle樣式、false或無值時為checkbox樣式
}

const SubscribeCheckbox: React.FC<IProps> = props => {
  const DEBOUNCE_TIME_LIMIT = 200;
  const debouncedApi = _.debounce(() => props.change(props.idx, props.type), DEBOUNCE_TIME_LIMIT);

  return props.toggleStyle ? (
    <SlideToggle checked={props.checked} change={debouncedApi} />
  ) : (
    <Checkbox
      name=""
      checked={props.checked}
      partialChecked={props.partialChecked}
      disableChecked={props.disableChecked}
    >
      <input type="checkbox" checked={props.checked} onChange={debouncedApi} />
    </Checkbox>
  );
};

export default SubscribeCheckbox;
