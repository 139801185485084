import React from 'react';
import { FaStar } from 'react-icons/fa';
import styled from 'styled-components';

const StarSpan = styled.span<{ light: boolean }>`
  color: ${props => (props.light ? '#ffa500' : '#ebebeb')};
`;

const Stars: React.FC<{
  stars: number;
}> = props => {
  const number_array = Array.from(Array(5).keys());

  return (
    <div>
      {number_array.map(i =>
        i < props.stars ? (
          <StarSpan key={`star${i}`} light={true}>
            <FaStar />
          </StarSpan>
        ) : (
          <StarSpan key={`star${i}`} light={false}>
            <FaStar />
          </StarSpan>
        ),
      )}
    </div>
  );
};
export default Stars;
