import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components';
import { blue1, blue1rgba, white } from 'src/style/theme/Color';
import { zTooltip } from 'src/style/theme/Z-Index';

/** 三角形寬度 */
const triangleWidth = 6;

const topStyle = css`
  /* 上移自己高度的100%，左移自己寬度50% */
  transform: translateY(-100%) translateX(-50%);
  ::after {
    border-color: ${blue1} transparent transparent transparent;
    right: calc(50% - ${triangleWidth}px);
    top: 100%;
  }
`;

const rightStyle = css`
  /* 上移自己高度的50% */
  transform: translateY(-50%);
  ::after {
    border-color: transparent ${blue1} transparent transparent;
    top: calc(50% - ${triangleWidth}px);
    right: 100%;
  }
`;

const bottomStyle = css`
  /* 左移自己寬度50% */
  transform: translateX(-50%);
  ::after {
    border-color: transparent transparent ${blue1} transparent;
    right: calc(50% - ${triangleWidth}px);
    bottom: 100%;
  }
`;

const leftStyle = css`
  /* 上移自己高度的50%，左移自己寬度100% */
  transform: translateY(-50%) translateX(-100%);
  ::after {
    border-color: transparent transparent transparent ${blue1};
    top: calc(50% - ${triangleWidth}px);
    left: 100%;
  }
`;

interface IStyledContent {
  left: number;
  top: number;
  placement: string;
}

const Content = styled.div<IStyledContent>`
  position: fixed;
  z-index: ${zTooltip};
  top: ${props => props.top}px;
  left: ${props => props.left}px;
  max-width: 250px;
  padding: 8px 16px;
  border-radius: 4px;
  box-shadow: 0 5px 20px -5px ${blue1rgba(0.3)};
  background-color: ${blue1};
  color: ${white};

  ${props =>
    (props.placement === 'top' && topStyle) ||
    (props.placement === 'right' && rightStyle) ||
    (props.placement === 'bottom' && bottomStyle) ||
    (props.placement === 'left' && leftStyle)}

  ::after {
    position: absolute;
    border-style: solid;
    border-width: ${triangleWidth}px;
    content: '';
    height: 0px;
    width: 0px;
  }
`;

// 取得.html檔中要渲染內容的DOM物件，不存在的話自己建一個div(for typescript check)
const tooltip = document.getElementById('tooltip');
const portalDiv = tooltip ? tooltip : document.createElement('div');

interface IProps {
  className?: string;
  overlay: React.ReactNode;
  placement: string;
  isHidden?: boolean; // 不顯示(使用)Tooltip的條件，可能是長度/字數小於多少或其他自訂條件
  offset?: number;
  onClick?: () => void;
}

const Tooltip: React.FC<IProps> = props => {
  let [isShow, setIsShow] = useState<boolean>(false);
  let [left, setLeft] = useState<number>(0);
  let [top, setTop] = useState<number>(0);

  const handleMouseOver = (e: any) => {
    setIsShow(true);
    // currentTarget始終是事件監聽者，而target是事件的真正發出者。
    const pos = getPosition(e.currentTarget, props.placement, props.offset);
    setLeft(pos.x);
    setTop(pos.y);
  };

  const handleMouseOut = () => {
    setIsShow(false);
  };

  return (
    <>
      {props.isHidden ? (
        <div className={props.className} onClick={props.onClick}>
          {props.children}
        </div>
      ) : (
        <div
          // 要讓外部使用者可以用styled-component extend的話，props必須加className
          className={props.className}
          onMouseOver={e => handleMouseOver(e)}
          onMouseOut={() => handleMouseOut()}
          onClick={props.onClick}
        >
          {props.children}
          {isShow &&
            ReactDOM.createPortal(
              <Content left={left} top={top} placement={props.placement}>
                {props.overlay}
              </Content>,
              portalDiv,
            )}
        </div>
      )}
    </>
  );
};

/** 取得Tooltip的顯示座標位置 */
function getPosition(element: any, placement: string, customOffset?: number) {
  let x = 0;
  let y = 0;

  // 計算"Tooltip長方形邊界"到"hover元素邊界"的距離
  const defaultOffset = 8;
  customOffset = customOffset ? customOffset : 0;
  const totalOffset = triangleWidth + defaultOffset + customOffset;

  // 取得元素長方形四個邊界在瀏覽器中的絕對位置、半長寬
  const rect = element.getBoundingClientRect();
  const rectHalfWidth = (rect.right - rect.left) / 2;
  const rectHalfHeight = (rect.bottom - rect.top) / 2;

  switch (placement) {
    case 'top':
      x += rect.right - rectHalfWidth;
      y += rect.top - totalOffset;
      break;
    case 'right':
      x += rect.right + totalOffset;
      y += rect.top + rectHalfHeight;
      break;
    case 'bottom':
      x += rect.right - rectHalfWidth;
      y += rect.bottom + totalOffset;
      break;
    case 'left':
      x += rect.left - totalOffset;
      y += rect.top + rectHalfHeight;
      break;
  }

  return { x: x, y: y };
}

export default Tooltip;
