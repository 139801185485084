import {
  FETCH_PATENT_CONTENT_START,
  FETCH_PATENT_CONTENT_SUCCESS,
  FETCH_PATENT_CONTENT_ERROR,
  CLEAR_PATENT_CONTENT,
  PatentContentState,
  PatentContentActionTypes,
} from 'src/redux/types/patentContentTypes';

/** 單篇專利資料預設值 */
const defaultPatentData = {
  content: null,
  ipc: [],
};

const initialState: PatentContentState = {
  isLoading: false,
  patentData: defaultPatentData,
};

const patentContentReducer = (
  state = initialState,
  action: PatentContentActionTypes,
): PatentContentState => {
  switch (action.type) {
    case FETCH_PATENT_CONTENT_START:
      return { ...state, patentData: defaultPatentData, isLoading: true };
    case FETCH_PATENT_CONTENT_SUCCESS:
      return { ...state, patentData: action.patentData, isLoading: false };
    case FETCH_PATENT_CONTENT_ERROR:
      return { ...initialState };
    case CLEAR_PATENT_CONTENT:
      return { ...state, patentData: defaultPatentData };
    default:
      return { ...state };
  }
};

export default patentContentReducer;
