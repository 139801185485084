import { useEffect, useState } from 'react';
import * as LoginUtils from 'src/apps/auth/LoginUtils';
import { Auth } from 'src/apps/auth/Auth';
import _ from 'lodash';
import { alertMessage } from 'src/utils/ModalUtils';

function useJwtManager() {
  const [hasToken, setHasToken] = useState(false);
  const [resetFunc, setResetFunc] = useState(false);

  useEffect(() => {
    // settings
    setHasToken(false);
    // load balance session 只保留 10 分鐘, 因此每 9 分鐘刷新 token
    const CHECK_INTERVAL_MILLIS = 540000;
    const THROTTLE_LIMIT_MILLIS = 10000;
    const CUSTOM_ERROR_MSG = 'CantGetPayload';
    let timerIdentifier = 0; // Identifier for set/clear interval

    const getGuestJwt = async () => {
      const statusCode = await LoginUtils.getGuestJwt();
      let shouldRefresh = true;
      if (statusCode === 200) {
        setHasToken(true);
      } else {
        alertMessage('伺服器無法回應，請稍後再重新整理，謝謝您。');
        shouldRefresh = false;
      }

      // force rerun useEffect
      if (shouldRefresh) {
        setResetFunc(prev => !prev);
      }
    };

    const checkAndRefreshToken = _.throttle(
      () => {
        // For monitoring
        // const id = Auth().getPayload() ? Auth().getPayload().info.id : undefined;
        // console.log('userId: ' + id);

        if (Auth().getPayload()) {
          setHasToken(true);
          LoginUtils.refreshJwt();
        } else {
          setHasToken(false);
          throw new Error(CUSTOM_ERROR_MSG);
        }
      },
      THROTTLE_LIMIT_MILLIS,
      { leading: true },
    );

    const handleError = (error: any) => {
      if (error.message && error.message !== CUSTOM_ERROR_MSG) {
        console.error(error.message);
      }
      getGuestJwt();
    };

    try {
      checkAndRefreshToken();
      timerIdentifier = window.setInterval(() => {
        // must add try-catch here in order to catch throwed exception in setInterval
        try {
          checkAndRefreshToken();
        } catch (error) {
          handleError(error);
        }
      }, CHECK_INTERVAL_MILLIS);
    } catch (error) {
      handleError(error);
    }

    return () => window.clearInterval(timerIdentifier);
  }, [resetFunc]);

  return hasToken;
}

export default useJwtManager;
