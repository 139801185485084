import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa';

import Content from 'src/components/ui/content/Content';
import withShortcut from 'src/components/search/withShortcut/withShortcut';

import { connect } from 'react-redux';
import { IIndustrialClassType } from 'src/types/IndustryTypes';
import { composeBasicRequest } from 'src/redux/actions/patentSearchAction';
import { IBasicRequest } from 'src/types/PatentSearchTypes';
import { alertMessage } from 'src/utils/ModalUtils';
import { SystemMessage } from 'src/apps/applicationMessages';
import { blue2, iceblue6 } from 'src/style/theme/Color';
import SearchSuggestion from 'src/components/common/search-bar/SearchSuggestion';
import IndustrialClassDropdown from 'src/components/trend/industrialClass/ui/Dropdown';
import useOutSideClick from 'src/hooks/useOutSideClick';
import Description from 'src/components/search/docdb/Description';
import { SolrFields } from './Basic';

const SearchBar = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 32px;
`;

const InputContainer = styled.div`
  position: relative;
  flex: 1;
  input {
    border: solid 1px ${iceblue6};
    border-left: 0px;
    border-radius: 0 4px 4px 0;
    padding: 8px 40px 8px 16px;
    width: 100%;
    ::placeholder {
      color: ${iceblue6};
    }
  }
`;

const SearchIcon = styled(FaSearch)`
  position: absolute;
  right: 16px;
  font-size: 16px;
  color: ${blue2};
  :hover {
    cursor: pointer;
  }
`;

const CustomTitle = styled.div`
  padding: 0 0 16px 0;
  font-weight: bold;
`;

interface IReduxProps {
  composeBasicRequest: (basicRequest: IBasicRequest) => void;
  ipcCodeList?: string[];
  handleIpcCodeList?: (code?: string[]) => void;
}

interface IProps extends IReduxProps, RouteComponentProps {}

/** 美國PTAB資料檢索 */
const SearchPatentTrial: React.FC<IProps> = props => {
  const [keyword, setKeyword] = useState<string>('');
  const [isSuggestionShow, setIsSuggestionShow] = useState(false);
  const [checkedIndustrialClass, setCheckedIndustrialClass] = useState<IIndustrialClassType[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleKeyWordChange = (e: any) => {
    setKeyword(e.target.value);
  };

  // 當ipcCodeList變化時(IPC列表勾選確認後)，將陣列中的值轉為string，並分別加上IC=，並用大括號框住整段IPC檢索式
  const handleIpcCode = (code?: string[]) => {
    code && setKeyword(prev => prev + ' (IC=' + code.join(' OR IC=') + ')');
  };
  useEffect(() => {
    handleIpcCode(props.ipcCodeList);
  }, [props.ipcCodeList]);

  const handleSuggestionClick = (value?: string) => {
    if (value && value !== '') {
      setKeyword(value);
    }
    inputRef.current && inputRef.current.focus();
  };

  const handleSuggestionClose = () => {
    setIsSuggestionShow(false);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (checkedIndustrialClass.length === 0 && keyword.trim() === '') {
      alertMessage(SystemMessage.SEARCH_KEYWORD_EMPTY);
    } else {
      // 送出request並跳到搜尋結果頁
      props.composeBasicRequest({
        industrialClassIds: checkedIndustrialClass.map(item => item.id.toString()),
        queryString: keyword.trim(),
        ptab: '1',
        advanceKeywords: [{ keyCode: 'PO', keyword: 'US' }],
      });
      props.history.push('/result');
    }
  };

  const node = useOutSideClick({
    show: isSuggestionShow,
    setShow: setIsSuggestionShow,
  });

  return (
    <Content sector="專利檢索" feature="美國 PTAB 資料檢索">
      <>
        <CustomTitle>此項目會搜尋具有美國專利審查與上訴委員會(PTAB)資料之專利案件。</CustomTitle>
        <form onSubmit={handleSubmit}>
          <SearchBar>
            <IndustrialClassDropdown
              checkedItems={checkedIndustrialClass}
              setCheckedItems={setCheckedIndustrialClass}
            />
            <InputContainer ref={node as any}>
              <input
                type="text"
                placeholder="請輸入關鍵字 / 專利號 / 申請人...."
                value={keyword}
                onChange={handleKeyWordChange}
                ref={inputRef}
                onFocus={() => setIsSuggestionShow(true)}
              />
              <SearchSuggestion
                cssTop="48px"
                isShow={isSuggestionShow}
                industryIds={checkedIndustrialClass.map(item => item.id)}
                inputText={keyword}
                handleSuggestionClick={handleSuggestionClick}
                handleSuggestionClose={handleSuggestionClose}
              />
            </InputContainer>
            <SearchIcon onClick={handleSubmit} />
          </SearchBar>
        </form>
        <Description descriptionData={SolrFields} />
      </>
    </Content>
  );
};

const mapDispatchToProps = {
  composeBasicRequest,
};

export default withShortcut(
  withRouter(
    connect(
      null,
      mapDispatchToProps,
    )(SearchPatentTrial),
  ),
);
