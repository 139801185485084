import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';

import { ultrawhite, headerborder } from 'src/style/theme/Color';
import { zTopNav } from 'src/style/theme/Z-Index';
import Logo from 'src/components/ui/Logo';
import TopSearchBar from 'src/components/common/search-bar/TopSearchBar';
import NavItems from './Sectors';
import Profile from './Profile';
import { ArticleContext } from 'src/components/article/item/ColumnistArticleItem';

const Toolbar = styled.header`
  z-index: ${zTopNav};
  height: 62px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  background-color: ${ultrawhite};
  border-bottom: 1px solid ${headerborder};

  i {
    cursor: pointer;
  }

  @media print {
    display: none;
  }
`;
const AdditionalDivForAnimation = styled.div`
  width: calc(100% - 162px);
  display: flex;
`;
const NavStyle = styled.nav<{ isSearch: boolean }>`
  display: ${props => (props.isSearch ? 'none' : 'flex')};

  @media (min-width: 1024px) {
    a,
    span {
      font-size: 18px;
    }
  }
  @media (max-width: 499px) {
    display: none;
  }
`;

const LogoStyle = styled.div`
  padding: 0 42px;
`;
const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

function TopNav(routeProps: RouteComponentProps) {
  const isArticlePage = React.useContext(ArticleContext);

  const [initTopSearch, setInitTopSearch] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const handleSearch = () => {
    setIsSearch(prev => !prev);
    setInitTopSearch(true);
  };

  useEffect(() => {
    if (routeProps.location.pathname.match(/^\/result/)) {
      setIsSearch(false);
    }
  }, [routeProps.location.pathname]);

  return (
    <Toolbar>
      <LogoStyle>
        {isArticlePage ? (
          <a href="/" onClick={() => window.scrollTo(0, 0)}>
            <Logo />
          </a>
        ) : (
          <Link to="/" onClick={() => window.scrollTo(0, 0)}>
            <Logo />
          </Link>
        )}
      </LogoStyle>
      <Container>
        <AdditionalDivForAnimation>
          <NavStyle isSearch={isSearch}>
            <NavItems />
          </NavStyle>
          <TopSearchBar
            handleSearch={handleSearch}
            isSearch={isSearch}
            initSearch={initTopSearch}
          />
        </AdditionalDivForAnimation>
        <Profile handleSearch={handleSearch} isSearch={isSearch} />
      </Container>
    </Toolbar>
  );
}

export default withRouter(TopNav);
