import React from 'react';
import styled from 'styled-components';
import configuration from 'src/assets/images/manual/patent-assisted-read/configuration.png';

const ImgContainer = styled.div<{ height: string; imageUrl: string }>`
  position: relative;
  height: ${props => props.height}px;
  padding: 10px 0;
  background-image: url(${props => props.imageUrl});
  background-size: contain;
  background-repeat: no-repeat;
`;
export default function PatentAssistedReadManual() {
  return (
    <>
      <p>以下為專利輔助閱讀功能配置</p>
      <ol>
        <li>內容導航、文內字串檢索</li>
        <li>專利名稱</li>
        <li>頁面翻譯、列印及雙欄比對</li>
        <li>專利內容資訊</li>
        <li>收藏專利及筆記(會員登入)</li>
        <li>專利相關資訊</li>
      </ol>
      <br />
      <ImgContainer height="350" imageUrl={configuration} />
    </>
  );
}
