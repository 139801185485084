import { AxiosError } from 'axios';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { ReduxAppState } from 'src/redux/reducers';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import {
  NAVIGATE_TO_NEXT_PAGE,
  FETCH_VISUAL_SEARCH_RESULT_START,
  FETCH_VISUAL_SEARCH_RESULT_SUCCESS,
  FETCH_VISUAL_SEARCH_RESULT_ERROR,
  VisualSearchSearchActionTypes,
  SET_VISUAL_SEARCH_REQUEST,
  APPEND_TM_ADVANCED_KEYWORD,
  DETACH_TM_ADVANCED_KEYWORD,
  FETCH_FILTER_RESULT_START,
  FETCH_FILTER_RESULT_SUCCESS,
  FETCH_FILTER_RESULT_ERROR,
  FETCH_TM_FACETS_START,
  FETCH_TM_FACETS_SUCCESS,
  FETCH_TM_FACETS_ERROR,
  FETCH_GOODS_CLASS_DESCRIPTIONS_START,
  FETCH_GOODS_CLASS_DESCRIPTIONS_SUCCESS,
  FETCH_GOODS_CLASS_DESCRIPTIONS_ERROR,
  FETCH_IMG_CLASS_DESCRIPTIONS_SUCCESS,
  FETCH_IMG_CLASS_DESCRIPTIONS_ERROR,
  HANDLE_SUGGESTIONS_START,
  HANDLE_SUGGESTIONS_SUCCESS,
  HANDLE_SUGGESTIONS_ERROR,
  FETCH_TM_METADATAS_SUCCESS,
  FETCH_TM_METADATAS_START,
  FETCH_TM_METADATAS_ERROR,
  FETCH_IMG_CLASS_PREDICT_START,
  FETCH_IMG_CLASS_PREDICT_SUCCESS,
  FETCH_IMG_CLASS_PREDICT_ERROR,
  FETCH_IMG_CLASS_MAPPING_SUCCESS,
} from './type';
import {
  IAdvancedKeyword,
  ICodeDescriptionMapping,
  IFacetFieldCount,
  IImgClassPredictRequest,
  ITrademarkFacet,
  IVisualSearchResult,
  IVisualSearchResultCategory,
  TmApplMetadata,
} from 'src/types/TrademarkTypes';
import { TrademarkService } from 'src/api/Trademark';
import { IPaginatedData } from 'src/types/PaginatedDataTypes';
import { tmFacetText } from 'src/constants/TmSearchConstants';
import { alertMessage } from 'src/utils/ModalUtils';
import { SystemMessage } from 'src/apps/applicationMessages';

const trademarkService = TrademarkService();

export function navigateToNextPage(): VisualSearchSearchActionTypes {
  return {
    type: NAVIGATE_TO_NEXT_PAGE,
  };
}

// 根據進階檢索條件(含搜尋框與篩選bar)過濾顯示結果
const handleFilter = (
  metadata: TmApplMetadata,
  categories: IVisualSearchResultCategory[],
  keywords: Array<IAdvancedKeyword>,
): boolean => {
  let result = true;
  // 因搜尋框為 OR 條件, 故將搜尋框與篩選 bar 分開處理
  let groupedKeywords = keywords.reduce(
    (grouped, keyword) => {
      let key =
        keyword.keyCode === 'GOODS_GROUP' || keyword.keyCode === 'GOODS_NAME'
          ? 'searchBar'
          : 'filterBar';
      (grouped[key] = grouped[key] || []).push(keyword);
      return grouped;
    },
    {} as { [key: string]: IAdvancedKeyword[] },
  );

  groupedKeywords['filterBar'] &&
    groupedKeywords['filterBar'].forEach(item => {
      switch (item.keyCode) {
        case 'GOODS_CLASS':
          // ["006", "008"]
          result =
            result &&
            metadata['goodsclass-code'].includes(
              item.keyword === '無商品服務類別' ? '　' : item.keyword,
            );
          break;
        case 'APPL_DATE':
          // "2020/01/09"
          result = result && metadata['appl-date'].substring(0, 4) === item.keyword;
          break;
        case 'APPLICANT_NAME':
          // ["新竹市政府", "新竹"]
          result = result && metadata['applicant.chinese-name'].includes(item.keyword);
          break;
        case 'APPLICANT_COUNTRY':
          // ["中華民國", "中華民國"]
          result = result && metadata['applicant.country-code'].includes(item.keyword);
          break;
        case 'TMARK_TYPE':
          // "立體"
          result = result && metadata['tmark-type-desc'] === item.keyword;
          break;
        case 'TMARK_COLOR':
          // "墨色"
          result =
            result &&
            metadata['tmark-color-desc'] === (item.keyword === '無圖樣顏色' ? '　' : item.keyword);
          break;
        case 'IMG_CLASS':
          let imgClasses: string[] = categories.map(
            category => category.firstLevel + '-' + category.secondLevel,
          );
          result =
            result && imgClasses.includes(item.keyword === '無圖形路徑' ? 'nan-nan' : item.keyword);
          break;
      }
    });

  // 篩選bar的有符合, 才檢查搜尋框
  // 搜尋框內空白代表 OR
  // 篩選 bar 與搜尋框都符合時 result 才是 true
  if (groupedKeywords['searchBar']) {
    result =
      result &&
      groupedKeywords['searchBar'].some(item => {
        let searchBarResult: boolean = false;
        switch (item.keyCode) {
          case 'GOODS_GROUP':
            // ["0601、0602", "0801、0802"]
            let goodsGroups: string[] = metadata['goods-group'].flatMap(group => group.split('、'));
            searchBarResult = goodsGroups.includes(item.keyword);
            break;
          case 'GOODS_NAME':
            // ["普通金屬製美術品；動物掛鈴。", "錢包；書包。"]
            let goodsNames = metadata['goods-name'].join('');
            searchBarResult = goodsNames.includes(item.keyword);
            break;
        }
        return searchBarResult;
      });
  }
  return result;
};

// 對陣列進行統計分組
const groupBy = <T>(array: T[], predicate: (v: T) => string) => {
  return array.reduce(
    (acc, value) => {
      acc[predicate(value)] = (acc[predicate(value)] || 0) + 1;
      return acc;
    },
    {} as { [key: string]: number },
  );
};

export const getMetadatasAndImgClasses = (
  datas: Array<IVisualSearchResult>,
): [Array<TmApplMetadata>, Array<string>] => {
  let metadatas: Array<TmApplMetadata> = [];
  const imgClasses: Array<string> = [];
  datas.forEach(item => {
    metadatas.push(item.metadata);
    const caseImgClasses: Array<string> = [];
    item.categories.forEach(category => {
      // 目前案件因只取二階, 故有五階不同但二階相同之情況, 故單一案件內之圖形路徑先過濾掉重複的
      caseImgClasses.push(category.firstLevel + '-' + category.secondLevel);
    });
    imgClasses.push(...Array.from(new Set(caseImgClasses)));
  });
  return [metadatas, imgClasses];
};

const getImgClasses = (datas: Array<IVisualSearchResult>): Array<string> => {
  // 取得圖形路徑
  const imgClasses: Array<string> = [];

  datas.forEach(item => {
    const caseImgClasses: Array<string> = [];
    item.categories.forEach(category => {
      caseImgClasses.push(category.firstLevel + '-' + category.secondLevel);
    });
    imgClasses.push(...Array.from(new Set(caseImgClasses)));
  });
  return imgClasses;
};

// 取得商標統計分組
const getFacets = (
  metadatas: Array<TmApplMetadata>,
  imgClasses: Array<string>,
): Array<ITrademarkFacet> => {
  let facets: Array<ITrademarkFacet> = [];
  for (let facetKeyCode of Array.from(tmFacetText.keys())) {
    let statistics: { [key: string]: number } = {};
    let fieldCounts: Array<IFacetFieldCount>;

    // 預設 name 升冪 (nan 或 空白 放到最後)
    let sortBy = (a: string, b: string): number => {
      return a < b ? -1 : 1;
    };

    switch (facetKeyCode) {
      case 'GOODS_CLASS':
        // 類別代碼 升冪排序
        statistics = groupBy(metadatas.flatMap(item => item['goodsclass-code']), goodsClassCode => {
          return goodsClassCode === '　' ? '無商品服務類別' : goodsClassCode;
        });
        break;
      case 'APPL_DATE':
        // 年分 降冪排序
        statistics = groupBy(metadatas, metadata => metadata['appl-date'].substring(0, 4));
        sortBy = (a, b) => {
          return a > b ? -1 : 1;
        };
        break;
      case 'APPLICANT_NAME':
        // 數量 降冪排序
        statistics = groupBy(
          metadatas.flatMap(item => item['applicant.chinese-name']),
          applicantName => applicantName,
        );
        sortBy = (a, b) => {
          return statistics[b] - statistics[a];
        };
        break;
      case 'APPLICANT_COUNTRY':
        // 數量 降冪排序
        statistics = groupBy(
          metadatas.flatMap(item => item['applicant.country-code']),
          applicantCountryCode => applicantCountryCode,
        );
        sortBy = (a, b) => {
          return statistics[b] - statistics[a];
        };
        break;
      case 'TMARK_TYPE':
        statistics = groupBy(
          metadatas.map(item => item['tmark-type-desc']),
          tmarkTypeDesc => tmarkTypeDesc,
        );
        break;
      case 'TMARK_COLOR':
        statistics = groupBy(metadatas.map(item => item['tmark-color-desc']), tmarkColorDesc => {
          return tmarkColorDesc === '　' ? '無圖樣顏色' : tmarkColorDesc;
        });
        break;
      case 'IMG_CLASS':
        // 圖形路徑 升冪排序
        statistics = groupBy(imgClasses, imgClass => {
          return imgClass === 'nan-nan' ? '無圖形路徑' : imgClass;
        });
        break;
    }

    // 排序後轉成 IFacetFieldCount 物件加入清單
    fieldCounts = Object.keys(statistics)
      .sort(sortBy)
      .map(key => ({
        name: key,
        count: statistics[key],
      }));

    facets.push({
      keyCode: facetKeyCode,
      tmFacetFieldCount: fieldCounts,
    });
  }
  return facets;
};

// 過濾查詢結果並更新統計分組
export const filterAndUpdateFacets = (): ThunkAction<void, ReduxAppState, null, Action<string>> => (
  dispatch,
  getState,
) => {
  // 需要詮釋資料已完成才可過濾
  if (getState().trademarkVisualSearchReducer.isMetadataLoaded) {
    // 將狀態改為 loading
    dispatch(fetchFilterResultStart());
    const advancedKeywords = getState().trademarkVisualSearchReducer.advancedKeywords;
    // 過濾資料要使用完整 data, 因此從 response.data 取
    let filteredData = getState().trademarkVisualSearchReducer.response.data;
    // 如果有進階檢索條件, 需先過濾
    if (advancedKeywords) {
      try {
        filteredData = getState().trademarkVisualSearchReducer.response.data.filter(data =>
          handleFilter(data.metadata, data.categories, advancedKeywords),
        );
        dispatch(fetchFilterResultSuccess(filteredData));
      } catch (error) {
        console.log(error);
        dispatch(fetchFilterResultFailure(error));
      }
    }
    // 統計分組 & 推薦詞
    const [metadatas, imgClasses] = getMetadatasAndImgClasses(filteredData);
    dispatch(fetchFacets(metadatas, imgClasses));
    dispatch(handleSuggestions(metadatas));
  }
};

// 根據 visualSearch 回傳回來的圖檔清單取得詮釋資料
export const fetchMetadatas = (): ThunkAction<void, ReduxAppState, null, Action<string>> => async (
  dispatch,
  getState,
) => {
  dispatch(fetchTmMetadatasStart());
  const data = getState().trademarkVisualSearchReducer.response.data;
  const imgClassMapping = getState().trademarkVisualSearchReducer.imgClassMapping;
  trademarkService
    .getTmMetadatas(data.map(item => item.imagePath))
    .then(response => {
      if (imgClassMapping) {
        response.forEach(
          item =>
            (item['img-class'] = item['img-class'].map(imgClass => imgClassMapping[imgClass])),
        );
      }
      dispatch(fetchTmMetadatasSuccess(response));
      // 取得資料後接著做統計分組(不放在此的話會拿不到response)
      const imgClasses = getImgClasses(data);
      dispatch(fetchFacets(response, imgClasses));
      dispatch(handleSuggestions(response));
    })
    .catch(error => dispatch(fetchTmMetadatasFailure(error)));
};

// 商標統計分組
export const fetchFacets = (
  metadatas: Array<TmApplMetadata>,
  imgClasses: Array<string>,
): ThunkAction<void, ReduxAppState, null, Action<string>> => (dispatch, getState) => {
  // 將統計分組狀態改為 loading
  dispatch(fetchFacetsStart());
  try {
    const facets: Array<ITrademarkFacet> = getFacets(metadatas, imgClasses);
    dispatch(fetchFacetsSuccess(facets));

    // 取得呈現時需要的額外資訊 (code 對應之中文敘述)
    // 商標服務固定取全部, 故不須每次重取
    if (Object.keys(getState().trademarkVisualSearchReducer.goodsClassDescriptions).length === 0) {
      dispatch(fetchGoodsClassDescriptions());
    }
    const imgClassFacet = facets.find(item => item.keyCode === 'IMG_CLASS');
    // TODO: 確認這個函數執行的次數, 前幾次 length 是 0
    imgClassFacet &&
      imgClassFacet.tmFacetFieldCount.length > 0 &&
      dispatch(
        fetchImgClassDescriptions(
          imgClassFacet.tmFacetFieldCount.map(item => item.name.replace('-', '')),
        ),
      );
  } catch (error) {
    dispatch(fetchFacetsFailure(error));
  }
};

// 統計所有商品名稱, 推薦出現次數前5名的詞
export const handleSuggestions = (
  metadatas: Array<TmApplMetadata>,
): ThunkAction<void, ReduxAppState, null, Action<string>> => (dispatch, getState) => {
  // 將統計分組狀態改為 loading
  dispatch(handleSuggestionsStart());
  try {
    let goodsNames: string[] = [];
    metadatas.forEach(item => {
      goodsNames.push(
        ...item['goods-name']
          .join('')
          .replace('。', '；')
          .split('；')
          .filter(text => text.length <= 15 && text.trim() !== ''),
      );
    });
    let statistics: { [key: string]: number } = groupBy(goodsNames, goodsName => goodsName);

    dispatch(
      handleSuggestionsSuccess(
        Object.keys(statistics)
          .sort((a, b) => {
            return statistics[b] - statistics[a];
          })
          .slice(0, 5),
      ),
    );
  } catch (error) {
    dispatch(handleSuggestionsFailure(error));
  }
};

export const fetchVisualSearchResult = (): ThunkAction<
  void,
  ReduxAppState,
  null,
  Action<string>
> => async (dispatch, getState) => {
  dispatch(fetchVisualSearchResultStart());
  const request = getState().trademarkVisualSearchReducer.request;
  trademarkService
    .visualSearch(request)
    .then(response => {
      dispatch(fetchVisualSearchResultSuccess(response));
      dispatch(fetchMetadatas());
    })
    .catch(error => dispatch(fetchVisualSearchResultFailure(error)));
};

export function setVisualSearchRequest(
  caseNo: string | undefined,
  categoryId: number | undefined,
  image: File,
  shapeAlpha: number,
  featureType: number,
  readMetadata?: boolean,
): VisualSearchSearchActionTypes {
  return {
    type: SET_VISUAL_SEARCH_REQUEST,
    request: {
      caseNo: caseNo,
      categoryId: categoryId,
      image: image,
      shapeAlpha: shapeAlpha,
      featureType: featureType,
      readMetadata: readMetadata === undefined ? false : readMetadata,
    },
  };
}

// 商品服務類別代碼敘述對照表
export const fetchGoodsClassDescriptions = (): ThunkAction<
  void,
  ReduxAppState,
  null,
  Action<string>
> => async dispatch => {
  dispatch(fetchGoodsClassDescriptionsStart());
  trademarkService
    .getGoodsClassDescriptions()
    .then(response => dispatch(fetchGoodsClassDescriptionsSuccess(response)))
    .catch(error => dispatch(fetchGoodsClassDescriptionsFailure(error)));
};

// 取得圖形路徑(2階)代碼敘述對照表 ex. key: 01-A,  value: 人物-男人
export const fetchImgClassDescriptions = (
  categories: Array<string>,
): ThunkAction<void, ReduxAppState, null, Action<string>> => async dispatch => {
  trademarkService
    .getImgClassDescriptions(categories)
    .then(response => dispatch(fetchImgClassDescriptionsSuccess(toSecondCategoryMapping(response))))
    .catch(error => dispatch(fetchImgClassDescriptionsFailure(error)));
};

/** 把圖形路徑格式轉成二階 01A00 => 01-A */
const toSecondCategoryMapping = (mapping: ICodeDescriptionMapping): ICodeDescriptionMapping => {
  const result: { [key: string]: string } = {};
  const keys = Object.keys(mapping);

  keys.forEach(key => {
    result[key.substring(0, 2) + '-' + key.substring(2, 3)] = mapping[key];
  });
  return result;
};

function fetchVisualSearchResultStart(): VisualSearchSearchActionTypes {
  return {
    type: FETCH_VISUAL_SEARCH_RESULT_START,
  };
}

function fetchVisualSearchResultSuccess(
  response: IPaginatedData<IVisualSearchResult>,
): VisualSearchSearchActionTypes {
  return {
    type: FETCH_VISUAL_SEARCH_RESULT_SUCCESS,
    response: response,
  };
}

function fetchVisualSearchResultFailure(error: AxiosError): VisualSearchSearchActionTypes {
  // TODO: 需要再確認或優化錯誤處理作法
  const errorMessage =
    (error.response && error.response.data && error.response.data.resultMessage) ||
    '網路連線出現異常';
  return {
    type: FETCH_VISUAL_SEARCH_RESULT_ERROR,
    errorMsg: errorMessage,
  };
}

function fetchTmMetadatasStart(): VisualSearchSearchActionTypes {
  return {
    type: FETCH_TM_METADATAS_START,
  };
}

function fetchTmMetadatasSuccess(response: Array<TmApplMetadata>): VisualSearchSearchActionTypes {
  return {
    type: FETCH_TM_METADATAS_SUCCESS,
    response: response,
  };
}

function fetchTmMetadatasFailure(error: AxiosError): VisualSearchSearchActionTypes {
  errorHandler(error);
  return {
    type: FETCH_TM_METADATAS_ERROR,
  };
}

function fetchFilterResultStart(): VisualSearchSearchActionTypes {
  return {
    type: FETCH_FILTER_RESULT_START,
  };
}

function fetchFilterResultSuccess(
  data: IVisualSearchResult[],
  sameCategoryImageNumber?: number,
): VisualSearchSearchActionTypes {
  return {
    type: FETCH_FILTER_RESULT_SUCCESS,
    data: data,
    sameCategoryImageNumber: sameCategoryImageNumber,
  };
}

function fetchFilterResultFailure(error: any): VisualSearchSearchActionTypes {
  console.log(error.message);
  alertMessage(SystemMessage.FILTER_ERROR);
  return {
    type: FETCH_FILTER_RESULT_ERROR,
  };
}

function fetchFacetsStart(): VisualSearchSearchActionTypes {
  return {
    type: FETCH_TM_FACETS_START,
  };
}

function fetchFacetsSuccess(facets: Array<ITrademarkFacet>): VisualSearchSearchActionTypes {
  return {
    type: FETCH_TM_FACETS_SUCCESS,
    facets: facets,
  };
}

function fetchFacetsFailure(error: any): VisualSearchSearchActionTypes {
  console.log(error.message);
  alertMessage(SystemMessage.FACET_ERROR);
  return {
    type: FETCH_TM_FACETS_ERROR,
  };
}

export function appendAdvancedKeyword(
  keyCode: string,
  keyword: string,
): VisualSearchSearchActionTypes {
  return {
    type: APPEND_TM_ADVANCED_KEYWORD,
    keyCode: keyCode,
    keyword: keyword,
  };
}

export function detachAdvancedKeyword(keyCode: string): VisualSearchSearchActionTypes {
  return {
    type: DETACH_TM_ADVANCED_KEYWORD,
    keyCode: keyCode,
  };
}

function fetchGoodsClassDescriptionsStart(): VisualSearchSearchActionTypes {
  return {
    type: FETCH_GOODS_CLASS_DESCRIPTIONS_START,
  };
}

function fetchGoodsClassDescriptionsSuccess(
  response: ICodeDescriptionMapping,
): VisualSearchSearchActionTypes {
  return {
    type: FETCH_GOODS_CLASS_DESCRIPTIONS_SUCCESS,
    response: response,
  };
}

function fetchGoodsClassDescriptionsFailure(error: any): VisualSearchSearchActionTypes {
  console.log(error.message);
  alertMessage(SystemMessage.FETCH_GOODS_CLASS_DESC_ERROR);
  return {
    type: FETCH_GOODS_CLASS_DESCRIPTIONS_ERROR,
  };
}

function fetchImgClassDescriptionsSuccess(
  response: ICodeDescriptionMapping,
): VisualSearchSearchActionTypes {
  return {
    type: FETCH_IMG_CLASS_DESCRIPTIONS_SUCCESS,
    response: response,
  };
}

function fetchImgClassDescriptionsFailure(error: any): VisualSearchSearchActionTypes {
  console.log(error.message);
  alertMessage(SystemMessage.FETCH_IMG_CLASS_DESC_ERROR);
  return {
    type: FETCH_IMG_CLASS_DESCRIPTIONS_ERROR,
  };
}

function handleSuggestionsStart(): VisualSearchSearchActionTypes {
  return {
    type: HANDLE_SUGGESTIONS_START,
  };
}

function handleSuggestionsSuccess(suggestions: Array<string>): VisualSearchSearchActionTypes {
  return {
    type: HANDLE_SUGGESTIONS_SUCCESS,
    suggestions: suggestions.map(item => ({
      value: item,
      //TODO: isAdded不需要
      isAdded: false,
    })),
  };
}

function handleSuggestionsFailure(error: any): VisualSearchSearchActionTypes {
  console.log(error.message);
  alertMessage(SystemMessage.GOODS_NAME_SUGGESTION_ERROR);
  return {
    type: HANDLE_SUGGESTIONS_ERROR,
  };
}

export const fetchImgClassPrediction = (
  request: IImgClassPredictRequest,
): ThunkAction<void, ReduxAppState, null, Action<string>> => async (dispatch, getState) => {
  dispatch(fetchImgClassPredictionStart());
  trademarkService
    .predictImgClass(request)
    .then(response => {
      dispatch(fetchImgClassPredictionSuccess(response.imgClassPrediction));
    })
    .catch(error => dispatch(fetchImgClassPredictionFailure(error)));
};

export const fetchImgClassMapping = (): ThunkAction<
  void,
  ReduxAppState,
  null,
  Action<string>
> => async dispatch => {
  trademarkService
    .getImgClassMapping()
    .then(response => dispatch(fetchImgClassMappingSuccess(response)))
    .catch(error => console.log(error));
};

function fetchImgClassPredictionStart(): VisualSearchSearchActionTypes {
  return {
    type: FETCH_IMG_CLASS_PREDICT_START,
  };
}

function fetchImgClassPredictionSuccess(response: Array<string>): VisualSearchSearchActionTypes {
  return {
    type: FETCH_IMG_CLASS_PREDICT_SUCCESS,
    response: response,
  };
}

function fetchImgClassPredictionFailure(error: AxiosError): VisualSearchSearchActionTypes {
  console.log(error);
  return {
    type: FETCH_IMG_CLASS_PREDICT_ERROR,
  };
}

function fetchImgClassMappingSuccess(
  response: ICodeDescriptionMapping,
): VisualSearchSearchActionTypes {
  return {
    type: FETCH_IMG_CLASS_MAPPING_SUCCESS,
    response: response,
  };
}
