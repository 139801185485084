import React, { useState, useEffect, useRef } from 'react';
import * as d3 from 'd3';
import styled from 'styled-components';
import { IGroupBarChartType } from 'src/types/IndustryTypes';
import {
  blue4,
  iceblue1,
  iceblue8,
  iceblue6,
  ultrawhite,
  trendsGroupBarChartColors,
} from 'src/style/theme/Color';
import Tooltip from 'src/components/trend/industrialTrends/chart/Tooltip';
import { numFormatter } from 'src/utils/Formatter';
import { getCountryName } from 'src/utils/PatentUtils';
import { getTrendsTitleKindText, formatYearMonth } from 'src/components/trend/TrendsHelper';
import { svgId } from 'src/utils/svgDownloadUtils';

const ToolTipOverlay = styled.div`
  color: ${ultrawhite};
`;

const ToolTipNumber = styled.span`
  font-size: 20px;
  font-weight: bold;
  color: ${blue4};
  padding: 0 4px 0 8px;
`;

const Legend = styled.div`
  text-align: center;
  color: ${iceblue1};
  margin: 4px 0 0 64px;
`;
const LegendItem = styled.span<{ color: string }>`
  height: 12px;
  width: 12px;
  margin: 0 8px 0 16px;
  background-color: ${props => props.color};
  display: inline-block;
`;

const width = 650;
const height = 300;
const padding = { top: 30, right: 50, bottom: 30, left: 90 };
const sliceXOffset = padding.left + 52;
const xAxisWidth = width - padding.left - padding.right;
const yAxisWidth = height - padding.top - padding.bottom;

interface IProps {
  dataset: Array<IGroupBarChartType>;
  kind: string;
}

interface ITooltip extends IGroupBarChartType {
  left: number;
  top: number;
}
// 國別順序列表
const countryList = ['TW', 'US', 'EP', 'JP', 'CN', 'KR'];

/** 群組直條圖 */
const GroupBarChart: React.FC<IProps> = ({ dataset, kind }) => {
  const [tooltip, setTooltip] = useState<ITooltip>();
  const svgRef = useRef<SVGSVGElement>(null);

  const getMaxY = (
    data: Array<{ start: string; end: string; data: Array<{ country: string; counts: number }> }>,
  ) => {
    let values: Array<number> = [];
    data.forEach(item =>
      item.data.forEach(data => {
        values.push(data.counts);
      }),
    );
    return Math.max(...values);
  };

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    svg.selectAll('g').remove();

    // 設定X軸標籤
    const xAxisLabels = dataset.map(d => {
      return d.start;
    });
    xAxisLabels.push(dataset[dataset.length - 1].end);

    const getToolTip = (d: any) => {
      const pos = svg.node();
      return {
        left: d3.event.pageX - window.scrollX,
        top: pos ? pos.getBoundingClientRect().top + 32 : d3.event.pageY - window.scrollY,
        start: d.start,
        end: d.end,
        data: d.data,
      };
    };

    const x0Scale = d3
      .scaleBand()
      .domain(xAxisLabels)
      .rangeRound([0, xAxisWidth]);

    const x1Scale = d3
      .scaleBand()
      .domain(countryList)
      .rangeRound([0, x0Scale.bandwidth() * 0.65]);

    const yScale = d3
      .scaleLinear()
      .domain([0, getMaxY(dataset)])
      .range([yAxisWidth, 0])
      .nice(3);

    const xAxis = d3
      .axisBottom(x0Scale)
      .tickSize(8)
      .tickSizeOuter(0)
      .tickFormat((d, i) => formatYearMonth(xAxisLabels[i]))
      .tickPadding(8);

    const yAxis = d3
      .axisLeft(yScale)
      .tickSize(-xAxisWidth)
      .ticks(5);

    // X軸
    const xAxisG = svg
      .append('g')
      .attr('transform', 'translate(' + padding.left + ',' + (padding.top + yAxisWidth) + ')')
      .style('color', iceblue6)
      .call(xAxis);
    // X軸標題
    xAxisG
      .append('text')
      .attr('x', xAxisWidth + 8)
      .attr('dy', 27)
      .attr('fill', iceblue1)
      .style('font-size', '13px')
      .text('月份');
    // X軸文字
    xAxisG
      .selectAll('.tick text')
      .style('font-size', '15px')
      .style('color', iceblue1);

    // Y軸
    const yAxisG = svg
      .append('g')
      .attr('transform', 'translate(' + padding.left + ',' + padding.top + ')')
      .style('color', iceblue1)
      .call(yAxis);
    // Y軸標題
    yAxisG
      .append('text')
      .attr('x', 0)
      .attr('dy', -20)
      .attr('fill', iceblue1)
      .style('font-size', '13px')
      .text('件數 (件)');
    // 調整文字與Y軸之距離
    yAxisG.selectAll('text').attr('x', '-0.8em');
    // 刪除Y軸-軸線
    yAxisG.selectAll('path').remove();
    // Y軸 ticks (作為橫格線) 之樣式設定
    yAxisG
      .selectAll('line')
      .attr('fill', 'none')
      .attr('stroke', `${iceblue8}`);

    // 群組資料
    const slice = svg
      .selectAll('.slice')
      .data(dataset)
      .enter()
      .append('g')
      .attr('class', d => 'g' + d.start)
      .attr(
        'transform',
        d => 'translate(' + (sliceXOffset + (x0Scale(d.start) || 0)) + ',' + padding.top + ')',
      )
      .on('mouseover', d => {
        slice.selectAll('rect').style('opacity', 0.3);
        slice.selectAll('.g' + d.start + ' rect').style('opacity', 1);
        setTooltip(getToolTip(d));
      })
      .on('mouseout', d => {
        slice.selectAll('rect').style('opacity', 1);
        setTooltip(undefined);
      });

    slice
      .selectAll('rect')
      .data(d => d.data)
      .enter()
      .append('rect')
      .attr('width', x1Scale.bandwidth())
      .style('fill', d => trendsGroupBarChartColors[d.country])
      .attr('x', d => x1Scale(d.country) || 0)
      .attr('y', yScale(0))
      .attr('height', yAxisWidth - yScale(0));

    slice
      .selectAll('rect')
      .attr('y', yAxisWidth - padding.top - padding.bottom)
      .transition()
      .duration(1000)
      .attr('y', (d: any) => yScale(d.counts))
      .attr('height', (d: any) => yAxisWidth - yScale(d.counts));
  }, [dataset]);

  return (
    <>
      <svg id={svgId} ref={svgRef} width={width} height={height} />
      <Legend>
        {countryList.map(country => (
          <React.Fragment key={country}>
            <LegendItem color={trendsGroupBarChartColors[country]} />
            {getCountryName(country)}
          </React.Fragment>
        ))}
        <LegendItem color={trendsGroupBarChartColors[1]} />
      </Legend>
      {tooltip && (
        <Tooltip
          left={tooltip.left}
          top={tooltip.top}
          overlay={
            <ToolTipOverlay>
              <div>
                <p>{formatYearMonth(tooltip.start) + ' - ' + formatYearMonth(tooltip.end) + ' '}</p>
                <p>{getTrendsTitleKindText(kind)}</p>
                {countryList.map(country => {
                  const find = tooltip.data.find(item => item.country === country);
                  return (
                    <p key={country}>
                      {getCountryName(country)}
                      <ToolTipNumber>{numFormatter(find ? find.counts : 0)}</ToolTipNumber>件
                    </p>
                  );
                })}
              </div>
            </ToolTipOverlay>
          }
        />
      )}
    </>
  );
};

export default GroupBarChart;
