import React, { useState } from 'react';
import styled from 'styled-components';
import { FaAngleLeft, FaTimes } from 'react-icons/fa';
import { Spinner } from 'src/components/ui/interactive/Spinner';
import { IGetIpc } from 'src/types/PatentSearchTypes';
import Checkbox from 'src/components/ui/interactive/checkbox/Checkbox';
import { Button } from 'src/style/theme/Common';
import {
  blue1,
  blue2,
  blue3,
  blue7,
  blue7rgba,
  blue8,
  iceblue1,
  iceblue3,
  iceblue5,
  iceblue8,
  ultrawhite,
  ultrablackrgba,
} from 'src/style/theme/Color';

const Container = styled.div`
  border-radius: 4px;
  box-shadow: 0 0 50px 0 ${ultrablackrgba(0.1)};
`;

const Header = styled.div<{ searchFormBelowTitle?: boolean }>`
  height: 88px;
  display: flex;
  align-items: ${props => (props.searchFormBelowTitle ? 'flex-start' : 'center')};
  color: ${ultrawhite};
  background-image: linear-gradient(95deg, #2ec3c2, ${blue3});
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

const PrevIconBox = styled.div`
  display: flex;
  align-items: center;
  padding: 32px 16px;
  :hover {
    background-color: ${blue7rgba(0.2)};
    cursor: pointer;
  }
`;
const FeatureSearchForm = styled.div`
  flex-grow: 1;
`;

const Feature = styled.div<{ searchFormBelowTitle?: boolean }>`
  padding: ${props => (props.searchFormBelowTitle ? '16px 16px 4px 16px' : '32px 16px')};
  font-size: 24px;
  font-weight: bold;
`;

const Subheaders = styled.div<{ extraPaddingTop?: boolean }>`
  display: flex;
  align-items: center;
  color: ${iceblue5};
  background-color: ${blue7};
  padding: ${props => (props.extraPaddingTop ? '36px 16px 8px 16px' : '8px 16px')};
`;

const Code = styled.div<{ color?: string; ipc?: string }>`
  width: 20%;
  padding: 8px 16px;
  color: ${props => props.color};
  cursor: ${props => props.ipc};
`;

const Keywords = styled.div<{ color?: string }>`
  width: 70%;
  padding: 8px 16px;
  color: ${props => props.color};
`;

const Copy = styled.div<{ color?: string }>`
  width: 10%;
  padding: 0px 16px;
  color: ${props => props.color};
  svg {
    :hover {
      cursor: pointer;
    }
  }
`;

const Item = styled.div`
  padding: 16px 16px;
  display: flex;
  border-top: 1px solid ${iceblue8};
`;

const Content = styled.div`
  background-color: ${ultrawhite};
  padding-bottom: 24px;
  height: 460px;
  justify-content: space-between;
  overflow-x: hidden;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0 !important;
  }
  ::-ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
`;

const LoadingBox = styled.div`
  display: flex;
  align-items: center;
`;
const ResultsBlock = styled.div`
  border-radius: 0 0 4px 4px;
  padding: 8px 16px;
  background-image: linear-gradient(93deg, ${blue2}, ${blue1} 100%);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  > span {
    font-weight: bold;
    line-height: 38px;
    color: ${ultrawhite};
    flex-shrink: 0;
  }
  > button {
    flex-shrink: 0;
  }
`;
const ResultTags = styled.div`
  margin-left: 16px;
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  > span {
    line-height: 38px;
    color: ${ultrawhite};
  }
`;
const ResultTag = styled.div`
  border-radius: 4px;
  padding: 4px 16px;
  margin: 4px;
  background-color: ${blue8};
  color: ${iceblue3};
  user-select: none;
  display: flex;
  align-items: center;
  svg {
    cursor: pointer;
    width: 15px;
    margin-left: 6px;
  }
`;

const NoResult = styled.div<{ extraPaddingTop?: boolean }>`
  margin: 16px 32px;
  padding: ${props => (props.extraPaddingTop ? '24px 32px 16px 32px' : '16px 32px')};
  background-color: ${blue8};
  div {
    color: ${blue2};
    font-weight: 600;
    margin-bottom: 8px;
  }
  span {
    color: ${iceblue3};
  }
`;

interface IProps {
  title: string;
  ipcList: Array<IGetIpc>;
  isLoading: boolean;
  handleBackClick: () => void;
  searchForm: JSX.Element;
  searchFormBelowTitle?: boolean;
  handleSubmit?: (code: string) => void;
  handleIpcCodeList?: (ipcCodeList: string[]) => void;
}

/** 代碼查詢 */
const CodeSearch: React.FC<IProps> = props => {
  const { title, ipcList, isLoading, handleBackClick, searchForm, searchFormBelowTitle } = props;

  const [ipcCheck, setIpcCheck] = useState<Array<string>>([]);

  // 取消IPC勾選
  const handleCancel = (code: string) => {
    setIpcCheck(prevState => prevState.filter(item => item !== code));
  };
  // 將所有勾選的IPC添加到IpcCodeList，並在每一項代碼後面加上*，回到查詢畫面
  const handleSubmit = () => {
    props.handleIpcCodeList && props.handleIpcCodeList(ipcCheck.map(item => item + '*'));
    handleBackClick();
  };
  // 處理CheckBoX勾選邏輯
  const handleCheckbox = (code: string) => {
    if (!ipcCheck.includes(code)) {
      setIpcCheck(prevState => [...prevState, code]);
    } else if (ipcCheck.includes(code)) {
      setIpcCheck(prevState => prevState.filter(item => item !== code));
    }
  };

  return (
    <Container>
      <Header searchFormBelowTitle={searchFormBelowTitle}>
        <PrevIconBox onClick={handleBackClick}>
          <FaAngleLeft size="24px" />
        </PrevIconBox>
        {props.searchFormBelowTitle ? (
          <FeatureSearchForm>
            <Feature searchFormBelowTitle>{title}</Feature>
            {searchForm}
          </FeatureSearchForm>
        ) : (
          <>
            <Feature>{title}</Feature>
            {searchForm}
          </>
        )}
      </Header>
      {ipcList.length > 0 && (
        <Subheaders extraPaddingTop={searchFormBelowTitle}>
          <Copy>勾選</Copy>
          <Code>代碼</Code>
          <Keywords>關鍵字</Keywords>
        </Subheaders>
      )}
      <Content>
        {isLoading ? (
          <LoadingBox>
            <Spinner width="150px" margin="150px auto" />
          </LoadingBox>
        ) : ipcList.length > 0 ? (
          ipcList.map(ipc => (
            <Item key={ipc.code}>
              <Copy>
                <Checkbox name={''} checked={ipcCheck.includes(ipc.code)}>
                  <input
                    type="checkbox"
                    checked={ipcCheck.includes(ipc.code)}
                    onChange={() => handleCheckbox(ipc.code)}
                  />
                </Checkbox>
              </Copy>
              <Code
                color={blue2}
                ipc={props.handleSubmit && 'pointer'}
                onClick={() => {
                  props.handleSubmit && props.handleSubmit(ipc.code);
                }}
              >
                {ipc.code}
              </Code>
              <Keywords color={iceblue1}>{ipc.description}</Keywords>
            </Item>
          ))
        ) : (
          <NoResult extraPaddingTop={searchFormBelowTitle}>
            <div>查無搜尋結果</div>
            <span>請嘗試變更查詢條件</span>
          </NoResult>
        )}
      </Content>
      <ResultsBlock>
        <span>已選擇&emsp;{ipcCheck && ipcCheck.length}</span>
        <ResultTags>
          {ipcCheck && ipcCheck.length === 0 ? (
            <span>請選擇至少一項。</span>
          ) : (
            <>
              {ipcCheck.map(item => (
                <ResultTag key={item}>
                  {item}
                  <FaTimes onClick={() => handleCancel(item)} />
                </ResultTag>
              ))}
            </>
          )}
        </ResultTags>
        <Button
          template="primary"
          margin="2px 0 2px 0"
          onClick={handleSubmit}
          disabled={ipcCheck.length === 0}
        >
          帶回檢索
        </Button>
      </ResultsBlock>
    </Container>
  );
};

export default CodeSearch;
