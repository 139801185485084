import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { FaAngleLeft } from 'react-icons/fa';
import { Spinner } from 'src/components/ui/interactive/Spinner';
import Checkbox from 'src/components/ui/interactive/checkbox/Checkbox';
import { Button } from 'src/style/theme/Common';
import {
  blue1,
  blue2,
  blue3,
  blue7,
  blue7rgba,
  blue8,
  iceblue1,
  iceblue3,
  iceblue5,
  iceblue8,
  ultrawhite,
  ultrablackrgba,
} from 'src/style/theme/Color';
import { CSVLink } from 'react-csv';
import { csvFileName, csvHeaders, TrademarkService } from 'src/api/Trademark';
import { IExportData, IFeedbackHistory } from 'src/types/TrademarkTypes';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import { IpcCodeSearchForm } from 'src/style/PatentSearchStyle';
import { handleCsvLinkClick } from '../trademark/TrademarkVisualResult';
import { check, Permission } from 'src/apps/permission/Permission';
import { alertMessage } from 'src/utils/ModalUtils';

const Container = styled.div`
  border-radius: 4px;
  box-shadow: 0 0 50px 0 ${ultrablackrgba(0.1)};
  width: calc(100% - 200px);
  margin: 16px auto;
`;

const Header = styled.div`
  height: 88px;
  display: flex;
  align-items: 'flex-start';
  color: ${ultrawhite};
  background-image: linear-gradient(95deg, #2ec3c2, ${blue3});
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

const PrevIconBox = styled.div`
  display: flex;
  align-items: center;
  padding: 32px 16px;
  :hover {
    background-color: ${blue7rgba(0.2)};
    cursor: pointer;
  }
`;
const FeatureSearchForm = styled.div`
  flex-grow: 1;
`;

const Feature = styled.div`
  padding: 16px 16px 4px 16px;
  font-size: 24px;
  font-weight: bold;
`;

const Subheaders = styled.div<{ extraPaddingTop?: boolean }>`
  display: flex;
  align-items: center;
  color: ${iceblue5};
  background-color: ${blue7};
  padding: ${props => (props.extraPaddingTop ? '36px 16px 8px 16px' : '8px 16px')};
`;

const Code = styled.div<{ color?: string; ipc?: string }>`
  width: 20%;
  padding: 8px 16px;
  color: ${props => props.color};
  cursor: ${props => props.ipc};
`;

const CreateTime = styled.div<{ color?: string }>`
  width: 75%;
  padding: 8px 16px;
  color: ${props => props.color};
`;

const Copy = styled.div<{ color?: string; isShow: boolean }>`
  width: 5%;
  padding: 0px 16px;
  color: ${props => props.color};
  visibility: ${props => !props.isShow && 'hidden'};
  svg {
    :hover {
      cursor: pointer;
    }
  }
`;

const Item = styled.div`
  padding: 16px 16px;
  display: flex;
  border-top: 1px solid ${iceblue8};
`;

const Content = styled.div`
  background-color: ${ultrawhite};
  padding-bottom: 24px;
  height: 460px;
  justify-content: space-between;
  overflow-x: hidden;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0 !important;
  }
  ::-ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
`;

const LoadingBox = styled.div`
  display: flex;
  align-items: center;
`;
const ResultsBlock = styled.div<{ isShow: boolean }>`
  visibility: ${props => !props.isShow && 'hidden'};
  border-radius: 0 0 4px 4px;
  padding: 8px 16px;
  background-image: linear-gradient(93deg, ${blue2}, ${blue1} 100%);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  > span {
    font-weight: bold;
    line-height: 38px;
    color: ${ultrawhite};
    flex-shrink: 0;
  }
  > button {
    flex-shrink: 0;
  }
`;
const ResultTags = styled.div`
  margin-left: 16px;
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  > span {
    line-height: 38px;
    color: ${ultrawhite};
  }
`;

const NoResult = styled.div`
  margin: 48px 32px;
  padding: 24px 32px 16px 32px;
  background-color: ${blue8};
  div {
    color: ${blue2};
    font-weight: 600;
    margin-bottom: 8px;
  }
  span {
    color: ${iceblue3};
  }
`;

interface IProps {
  handleBackClick: () => void;
}

const visualSearchService = TrademarkService();

/** 評價紀錄查詢 */
const TmFeedbackSearch: React.FC<IProps> = props => {
  const { handleBackClick } = props;

  const [feedbackList, setFeedbackList] = useState<Array<IFeedbackHistory>>([]);
  const [feedbackCheckList, setFeedbackCheckList] = useState<Array<number>>([]);
  const [input, setInput] = useState<string>('');
  const [searchCaseNo, setSearchCaseNo] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [exportData, setExportData] = useState<Array<IExportData>>([]);

  const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
  const TOP_K = 5; // 限制勾選匯出筆數

  // 匯出選取功能
  const handleExportData = async (): Promise<IExportData[]> => {
    let result: IExportData[] = [];
    setIsLoading(true);
    try {
      // 取得明細
      let resp = await visualSearchService.getFeedbackDetails(feedbackCheckList);
      resp.forEach(batch => {
        result = result.concat(
          batch.tmFeedbackRecords.map(record => {
            return {
              batchNo: '=""' + batch.batchNo + '""', // 避免被轉成科學記號
              caseNo: batch.caseNo,
              similarCaseNo: record.caseNo,
              rank: record.rank,
              feedback: record.type,
            } as IExportData;
          }),
        );
      });
      setExportData(result);
      csvLinkRef.current && csvLinkRef.current.link.click();
      setFeedbackCheckList([]);
    } catch (err) {
      errorHandler(err);
    } finally {
      setIsLoading(false);
    }
    return result;
  };

  // 以來源圖案號查詢紀錄
  const handleSearch = (caseNo: string) => {
    setSearchCaseNo(caseNo);
    setFeedbackCheckList([]);
  };

  /* 於 input 框點擊 enter 改變案號。*/
  const handleSearchByEnter = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSearch(e.target.value);
    }
  };

  // 處理 CheckBoX 勾選邏輯
  const handleCheckbox = (id: number) => {
    if (feedbackCheckList.length >= TOP_K && !feedbackCheckList.includes(id)) {
      // 如果筆數已超過, 不可勾選新的
      alertMessage(`至多勾選 ${TOP_K} 筆`);
    } else if (!feedbackCheckList.includes(id)) {
      setFeedbackCheckList(prevState => [...prevState, id]);
    } else if (feedbackCheckList.includes(id)) {
      setFeedbackCheckList(prevState => prevState.filter(item => item !== id));
    }
  };

  const fetchTmFeedbacks = async (sourceCaseNo: string) => {
    setIsLoading(true);
    try {
      let resp = await visualSearchService.getFeedbacks(sourceCaseNo);
      setFeedbackList(resp);
    } catch (err) {
      setFeedbackList([]);
      errorHandler(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTmFeedbacks(searchCaseNo);
  }, [searchCaseNo]);

  return (
    <Container>
      <Header>
        <PrevIconBox onClick={handleBackClick}>
          <FaAngleLeft size="24px" />
        </PrevIconBox>
        <FeatureSearchForm>
          <Feature>評價紀錄查詢</Feature>
          <IpcCodeSearchForm>
            <label>
              <span>來源圖案號</span>
              <input
                type="text"
                onChange={event => setInput(event.target.value)}
                onKeyPress={handleSearchByEnter}
                value={input}
                placeholder="請輸入 {申請號}-{圖檔編號}，圖檔編號預設為 1。例如：109002750 或 109002750-1。無申請號之來源圖案號為 {提交回饋批號}-1"
              />
            </label>
            <Button
              margin="0"
              radius="0 4px 4px 0"
              type="button"
              template="primary-dark"
              onClick={() => handleSearch(input)}
            >
              查詢
            </Button>
          </IpcCodeSearchForm>
        </FeatureSearchForm>
      </Header>
      {feedbackList.length > 0 && (
        <Subheaders extraPaddingTop={true}>
          <Copy isShow={check(Permission.TM_FEEDBACK_EXPORT)}>勾選</Copy>
          <Code>來源圖案號</Code>
          <CreateTime>建立時間</CreateTime>
        </Subheaders>
      )}
      <Content>
        {isLoading ? (
          <LoadingBox>
            <Spinner width="150px" margin="150px auto" />
          </LoadingBox>
        ) : feedbackList.length > 0 ? (
          feedbackList.map(feedback => (
            <Item key={feedback.id}>
              <Copy isShow={check(Permission.TM_FEEDBACK_EXPORT)}>
                <Checkbox name={''} checked={feedbackCheckList.includes(feedback.id)}>
                  <input
                    type="checkbox"
                    checked={feedbackCheckList.includes(feedback.id)}
                    onChange={() => handleCheckbox(feedback.id)}
                  />
                </Checkbox>
              </Copy>
              <Code color={iceblue1}>{feedback.caseNo}</Code>
              <CreateTime color={iceblue1}>{feedback.createTime}</CreateTime>
            </Item>
          ))
        ) : (
          <NoResult>
            <div>查無評價紀錄</div>
            <span>請提交回饋或變更查詢條件</span>
          </NoResult>
        )}
      </Content>
      <ResultsBlock isShow={check(Permission.TM_FEEDBACK_EXPORT)}>
        <span>已選擇&emsp;{feedbackCheckList && feedbackCheckList.length}</span>
        <ResultTags>
          {feedbackCheckList && feedbackCheckList.length === 0 && (
            <span>請選擇至少一項，至多五項。</span>
          )}
        </ResultTags>
        {/* 不可將 Button 包在 CSVLink 內, 會觸發兩次下載檔案 */}
        <CSVLink
          filename={csvFileName}
          headers={csvHeaders}
          data={exportData}
          onClick={handleCsvLinkClick}
          ref={csvLinkRef}
        />
        <Button
          template="primary"
          margin="2px 0 2px 0"
          onClick={handleExportData}
          disabled={feedbackCheckList.length === 0}
        >
          匯出
        </Button>
      </ResultsBlock>
    </Container>
  );
};

export default TmFeedbackSearch;
