import React from 'react';
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';

import { blue3, blue7, ultrawhite, ultrawhitergba } from 'src/style/theme/Color';

import { connect } from 'react-redux';
import { ReduxAppState } from 'src/redux/reducers';
import { toggleTechFuncQuery } from 'src/redux/actions/patentSearchAction';
import { changeActivePane } from 'src/redux/result/toolbar/action';
import { openCustomModal } from 'src/redux/actions/modalAction';
import { CustomModalType } from 'src/types/ModalTypes';
import { IFilterTag } from 'src/types/PatentSearchTypes';

const Container = styled.div`
  display: flex;
  align-items: center;
  max-width: 200px;
  margin-right: 16px;
  padding: 8px 8px 8px 12px;
  border-radius: 4px;
  background-color: #035a76;
  color: ${ultrawhite};
  box-shadow: -32px 0px 32px 0px ${ultrawhitergba(0.9)};
  :hover {
    cursor: pointer;
  }
`;

const QueryIntersection = styled.div`
  display: flex;
  align-items: center;
  margin-right: 4px;
`;

const CancelIcon = styled.div`
  padding: 4px;
  border-radius: 50%;
  :hover {
    cursor: pointer;
    color: ${blue3};
    background-color: ${blue7};
  }

  svg {
    display: block;
    margin: auto;
    font-size: 16px;
  }
`;

const Name = styled.span`
  display: inline-block;
  max-width: 45px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

interface IReduxProps {
  filterTag?: IFilterTag;
  toggleTechFuncQuery: (techQuery?: string, funcQuery?: string, filterTag?: IFilterTag) => void;
  changeActivePane: (pane: string) => void;
  openCustomModal: (customModalType: CustomModalType) => void;
}

interface IProps extends IReduxProps {}

/** 技術功效矩陣 - 再檢索TAG */
const MatrixFilterTag: React.FC<IProps> = ({
  filterTag,
  changeActivePane,
  openCustomModal,
  toggleTechFuncQuery,
}) => {
  const handleTagClick = () => {
    changeActivePane('功效矩陣');
    openCustomModal(CustomModalType.CHART);
  };

  const handleCancelClick = (e: any) => {
    e.stopPropagation();
    toggleTechFuncQuery();
  };

  return filterTag ? (
    <Container onClick={handleTagClick}>
      <QueryIntersection>
        <Name>{filterTag.techName}</Name>
        <span>/</span>
        <Name>{filterTag.funcName}</Name>
        <span>({filterTag.count})</span>
      </QueryIntersection>
      <CancelIcon onClick={e => handleCancelClick(e)}>
        <FaTimes />
      </CancelIcon>
    </Container>
  ) : null;
};

const mapStateToProps = (state: ReduxAppState) => {
  return {
    filterTag: state.patentSearchReducer.filterTag,
  };
};

const mapDispatchToProps = {
  changeActivePane,
  openCustomModal,
  toggleTechFuncQuery,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MatrixFilterTag);
