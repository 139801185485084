import React from 'react';
import { FaAngleDown, FaAngleUp, FaTimesCircle } from 'react-icons/fa';
import { connect } from 'react-redux';
import { appendAdvancedKeyword, detachAdvancedKeyword } from 'src/redux/actions/patentSearchAction';
import SearchBar from './common/SearchBar';
import { numFormatter } from 'src/utils/Formatter';
import { IFacetFieldCount } from 'src/types/PatentSearchTypes';
import useAccordion from './common/useAccordion';
import {
  Container,
  Header,
  TaggedHeader,
  Title,
  Panel,
  TagWithTooltip,
  FacetLinkBox,
  FacetLinkName,
  FacetLinkCount,
  NoFilteredResult,
} from 'src/style/AccordionStyle';
import { getCountryName } from 'src/utils/PatentUtils';

interface IReduxMappingProps {
  appendAdvancedKeyword: (keyCode: string, keyword: string) => void;
  detachAdvancedKeyword: (keyCode: string) => void;
}

interface IProps extends IReduxMappingProps {
  keyCode: string;
  title?: string;
  tag?: string;
  content: Array<IFacetFieldCount>;
}

/** 篩選-摺疊面板 */
const Accordion: React.FC<IProps> = props => {
  const { keyCode, title, tag, content, appendAdvancedKeyword, detachAdvancedKeyword } = props;

  const {
    isActive,
    searchKeyword,
    filteredContent,
    togglePanel,
    handleKeywordChange,
    getHighlightedText,
  } = useAccordion(content);

  const isTagged = tag !== undefined;
  const isSearchbarShow = !isTagged && isActive && content && content.length > 6;

  return (
    <Container>
      {isTagged ? (
        <TaggedHeader>
          <Title>{title}</Title>
          <TagWithTooltip
            placement="top"
            overlay={tag}
            // 專利種類、國別、公開日不顯示Tooltip
            isHidden={['KC', 'PO', 'ID'].includes(keyCode)}
          >
            {tag}
          </TagWithTooltip>
          <FaTimesCircle size={20} onClick={() => detachAdvancedKeyword(keyCode)} />
        </TaggedHeader>
      ) : (
        <Header onClick={() => togglePanel()}>
          <Title>{title}</Title>
          {isActive ? <FaAngleUp size={20} /> : <FaAngleDown size={20} />}
        </Header>
      )}
      {isSearchbarShow && (
        <SearchBar searchKeyword={searchKeyword} onChange={e => handleKeywordChange(e)} />
      )}
      {!isTagged && (
        <Panel isShow={isActive}>
          {searchKeyword !== '' && filteredContent.length === 0 ? (
            <NoFilteredResult>查無 "{searchKeyword}" 的符合結果</NoFilteredResult>
          ) : (
            filteredContent.map(item => (
              <FacetLinkBox
                key={item.name}
                onClick={() => appendAdvancedKeyword(keyCode, item.name)}
              >
                <FacetLinkName>
                  {keyCode === 'PO'
                    ? getCountryName(item.name)
                    : getHighlightedText(item.name, searchKeyword)}
                </FacetLinkName>
                <FacetLinkCount>({numFormatter(item.count)})</FacetLinkCount>
              </FacetLinkBox>
            ))
          )}
        </Panel>
      )}
    </Container>
  );
};

const mapDispatchToProps = {
  appendAdvancedKeyword,
  detachAdvancedKeyword,
};

export default connect(
  null,
  mapDispatchToProps,
)(Accordion);
