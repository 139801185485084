import { IDocdbRequest } from 'src/types/DocdbSearchTypes';

export const SEARCH_BY_KEYWORD = 'SEARCH_BY_KEYWORD';
export const SELECT_DATE_RANGE = 'SELECT_DATE_RANGE';
export const CHANGE_SORT_ORDER = 'CHANGE_SORT_ORDER';
export const NAVIGATE_TO_NEXT_PAGE = 'NAVIGATE_TO_NEXT_PAGE';
export const CLEAR_DOCDB_SEARCH = 'CLEAR_DOCDB_SEARCH';

export interface DocdbSearchState {
  request: IDocdbRequest;
  highlightWords: string[];
}

interface SearchByKeywordAction {
  type: typeof SEARCH_BY_KEYWORD;
  queryString: string;
  highlightWords: string[];
}

interface SelectDateRangeAction {
  type: typeof SELECT_DATE_RANGE;
  dateRange: string;
}

interface ChangeSortOrderAction {
  type: typeof CHANGE_SORT_ORDER;
  sortByDate: string;
}

interface NavigateToNextPageAction {
  type: typeof NAVIGATE_TO_NEXT_PAGE;
}

interface ClearDocdbSearchAction {
  type: typeof CLEAR_DOCDB_SEARCH;
}

export type DocdbSearchActionTypes =
  | SearchByKeywordAction
  | SelectDateRangeAction
  | ChangeSortOrderAction
  | NavigateToNextPageAction
  | ClearDocdbSearchAction;
