import { AxiosError } from 'axios';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { ReduxAppState } from 'src/redux/reducers';
import {
  FETCH_PATENT_CONTENT_START,
  FETCH_PATENT_CONTENT_SUCCESS,
  FETCH_PATENT_CONTENT_ERROR,
  CLEAR_PATENT_CONTENT,
  PatentContentActionTypes,
} from 'src/redux/types/patentContentTypes';
import { PatentService } from 'src/api/Patent';
import { PatentDataTypes } from 'src/types/api/PatentApiTypes';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';

export const fetchPatentContent = (
  uuid: string,
): ThunkAction<void, ReduxAppState, null, Action<string>> => async dispatch => {
  dispatch(fetchPatentContentStart());
  try {
    const patentData = await PatentService().getPatentData(uuid);
    dispatch(fetchPatentContentSuccess(patentData));
  } catch (error) {
    dispatch(fetchPatentContentFailure(error));
  }
};

export function fetchPatentContentStart(): PatentContentActionTypes {
  return {
    type: FETCH_PATENT_CONTENT_START,
  };
}

export function fetchPatentContentSuccess(patentData: PatentDataTypes): PatentContentActionTypes {
  return {
    type: FETCH_PATENT_CONTENT_SUCCESS,
    patentData: patentData,
  };
}

export function fetchPatentContentFailure(error: AxiosError): PatentContentActionTypes {
  errorHandler(error);
  return {
    type: FETCH_PATENT_CONTENT_ERROR,
    error: error,
  };
}

export function clearPatentContent(): PatentContentActionTypes {
  return {
    type: CLEAR_PATENT_CONTENT,
  };
}
