import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { FaSearch } from 'react-icons/fa';

const Container = styled.div`
  position: absolute;
  padding-top: 4px;
  width: calc(100% - 64px);
`;

const SearchArea = styled.div`
  height: 61px;
  width: 100%;
  display: flex;
  border-radius: 4px;
  position: relative;
  line-height: 60px;
  text-align: left;
  border-radius: 4px;
  box-shadow: 0 4px 16px -4px rgba(38, 50, 55, 0.3);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  background-color: rgba(255, 255, 255, 0.9);
`;
const InputContainer = styled.div`
  height: 61px;
  width: 100%;
  padding-left: 20px;
  position: relative;
  display: flex;
`;
const Input = styled.input.attrs({ type: 'text' })`
  height: 61px;
  padding: 20px 0px;
  padding-right: 48px;
  width: 100%;
  border-radius: 4px;
  color: #333333;
  background-color: transparent;
  border: none;
  outline: none;
  ::placeholder {
    color: #b3b9bc;
  }
`;
const Icon = styled.div`
  position: absolute;
  right: 16px;
  height: 61px;
  background-color: transparent;
  border-radius: 4px;
  display: flex;
  align-items: center;
  svg {
    display: block;
    :hover {
      cursor: pointer;
    }
  }
`;

interface IProps {
  search: (queryString: string) => void;
}

export const SearchBar: React.FC<IProps> = props => {
  const [inputText, setInputText] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSearch = () => {
    props.search(inputText);
  };

  return (
    <Container>
      <SearchArea>
        <InputContainer>
          <Input
            ref={inputRef as any}
            value={inputText}
            onChange={e => setInputText(e.target.value)}
            onKeyUp={e => e.keyCode === 13 && handleSearch()}
            placeholder="請輸入關鍵字"
          />
        </InputContainer>
        <Icon>
          <FaSearch onClick={handleSearch} color="#077790" size="20px" />
        </Icon>
      </SearchArea>
    </Container>
  );
};
