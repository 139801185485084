import React from 'react';
import styled from 'styled-components';
import { ultrawhite, ultrawhitergba, ultrablackrgba, blue2, iceblue4 } from 'src/style/theme/Color';
import { Category } from 'src/components/member/Notification';

const Container = styled.div`
  border-radius: 4px;
  box-shadow: 0 0 50px 0 ${ultrablackrgba(0.1)};
`;
const Header = styled.div`
  height: 88px;
  display: flex;
  flex-flow: row;
  align-items: center;
  color: ${ultrawhite};
  background-image: linear-gradient(95deg, #2ec3c2, #0cafcc);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;
const HeaderText = styled.div`
  padding: 16px 32px;
`;
const HeaderRoute = styled.div`
  color: ${ultrawhitergba(0.8)};
`;
const Feature = styled.div`
  height: 36px;
  line-height: 36px;
  font-size: 24px;
  font-weight: bold;
`;
const Subheaders = styled.div`
  padding-left: 32px;
  height: 60px;
  display: flex;
  align-items: center;
  color: #979ea1;
  background-color: #edf6f7;
`;

const Content = styled.div`
  padding: 0 0 24px;
  min-height: 450px;
`;

const CategoryTab = styled.div<{ selected: boolean }>`
  position: relative;
  margin-right: 44px;
  font-size: ${props => (props.selected ? '20px' : '19px')};
  color: ${props => (props.selected ? '#333333' : iceblue4)};
  font-weight: ${props => (props.selected ? '600' : 'normal')};
  text-align: center;
  cursor: pointer;
  ::after {
    position: absolute;
    bottom: -14px;
    width: 100%;
    height: 3px;
    left: 0;
    content: '';
    background-color: ${props => (props.selected ? blue2 : '')};
    border-radius: 4px;
  }

  border-radius: 4px;
`;

interface IProps {
  sector: string;
  feature: string;
  children: JSX.Element;
  category: Category;
  handleCategory: (category: Category) => void;
}

const notifyContent: React.FC<IProps> = props => {
  const categories: Array<{ category: Category; name: string }> = [
    { category: Category.SUBSCRIPTION, name: '專利及新聞訂閱' },
    { category: Category.WEEKLY_INFO, name: '電子報' },
  ];
  return (
    <Container>
      <Header>
        <HeaderText>
          <HeaderRoute>
            首頁&nbsp;/&nbsp;{props.sector}&nbsp;/&nbsp;
            {props.feature}
          </HeaderRoute>
          <Feature>{props.feature}</Feature>
        </HeaderText>
      </Header>
      <Subheaders>
        {categories.map(item => (
          <CategoryTab
            key={item.category}
            onClick={() => props.handleCategory(item.category)}
            selected={item.category === props.category}
          >
            {item.name}
          </CategoryTab>
        ))}
      </Subheaders>
      <Content>{props.children}</Content>
    </Container>
  );
};

export default notifyContent;
