import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';

import useOutSideClick from 'src/hooks/useOutSideClick';
import Menu from './Menu';
import { IIndustrialClassType } from 'src/types/IndustryTypes';
import {
  blue3,
  blue6,
  blue7,
  iceblue1,
  iceblue6,
  ultrawhite,
  ultrawhitergba,
} from 'src/style/theme/Color';
import { zToolbar } from 'src/style/theme/Z-Index';

const DropdownContainer = styled.div<{ grow: boolean }>`
  flex-grow: ${props => (props.grow ? 1 : 0)};
  height: 100%;
  min-width: 0;
  z-index: ${zToolbar};
`;

const DropdownSimple = styled.div<{ active: boolean; css?: any; cssAct?: any }>`
  width: 118px;
  height: 100%;
  padding: 8px 16px;
  border-radius: 4px 0 0 4px;
  border: 1px solid ${iceblue6};
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${ultrawhite};
  color: ${iceblue1};
  user-select: none;
  cursor: pointer;
  svg {
    color: ${iceblue1};
  }
  ${props => props.css}

  ${props =>
    props.active &&
    css`
      border: 1px solid ${blue6};
      background-color: ${blue7};
      color: #0c4349;
      svg {
        color: ${blue3};
      }
      ${props.cssAct}
    `}
`;

const DropdownDetail = styled.div`
  width: 100%;
  height: 100%;
  padding: 8px 16px;
  border-radius: 4px 0 0 4px;
  background-color: ${ultrawhitergba(0.9)};
  backdrop-filter: blur(8px);
  color: ${iceblue1};
  > span {
    display: block;
    font-size: 13px;
    color: ${iceblue6};
  }
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 22px;
  }
  :hover {
    cursor: pointer;
  }
`;
const CheckedItemNames = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

interface IProps {
  checkedItems: Array<IIndustrialClassType>;
  setCheckedItems: (items: Array<IIndustrialClassType>) => void;
  /** 下拉按鈕樣式: true-顯示已選項目名稱(用於趨勢統計); false-僅顯示已選項目數(用於專利檢索) */
  showDetail?: boolean;
  customButtonStyle?: any;
  customButtonActiveStyle?: any;
  customMenuStyle?: any;
}

const Dropdown: React.FC<IProps> = ({
  checkedItems,
  setCheckedItems,
  showDetail,
  customButtonStyle,
  customButtonActiveStyle,
  customMenuStyle,
}) => {
  const [newCheckedItems, setNewCheckedItems] = useState<IIndustrialClassType[]>(checkedItems);
  const [selectAll, setSelectAll] = useState(checkedItems.length === 0);
  const [isOpen, setIsOpen] = useState(false);

  const resetCheckedItems = () => {
    setNewCheckedItems([...checkedItems]);
    setIsOpen(false);
    setSelectAll(checkedItems.length === 0);
  };

  const submitCheckedItems = () => {
    setCheckedItems([...newCheckedItems]);
    setIsOpen(false);
  };

  const node = useOutSideClick({
    show: isOpen,
    setShow: resetCheckedItems,
  });

  const dropdownButtonSimple = (
    <DropdownSimple
      active={isOpen}
      onClick={() => setIsOpen(!isOpen)}
      css={customButtonStyle}
      cssAct={customButtonActiveStyle}
    >
      {selectAll ? '不分行業' : '行業 (' + newCheckedItems.length + ')'}
      {isOpen ? <FaCaretUp /> : <FaCaretDown />}
    </DropdownSimple>
  );

  const dropdownButtonDetail = (
    <DropdownDetail onClick={() => setIsOpen(!isOpen)}>
      <span>行業別&nbsp;({selectAll ? '1' : newCheckedItems.length}/5)</span>
      <div>
        <CheckedItemNames>
          {selectAll ? '不分行業' : newCheckedItems.map(item => Object.values(item)[2]).join('、')}
        </CheckedItemNames>
        {isOpen ? <FaCaretUp color={iceblue1} /> : <FaCaretDown color={iceblue1} />}
      </div>
    </DropdownDetail>
  );

  return (
    <DropdownContainer grow={showDetail || false} ref={node as any}>
      {showDetail ? dropdownButtonDetail : dropdownButtonSimple}
      {isOpen && (
        <Menu
          checkedItems={newCheckedItems}
          setCheckedItems={setNewCheckedItems}
          selectAll={selectAll}
          setSelectAll={setSelectAll}
          onSubmit={submitCheckedItems}
          onReset={resetCheckedItems}
          css={customMenuStyle}
        />
      )}
    </DropdownContainer>
  );
};
export default Dropdown;
