import React, { useState } from 'react';
import styled from 'styled-components';
import { FaPencilAlt, FaTrashAlt, FaShareAlt } from 'react-icons/fa';

import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import { isCompanyMember } from 'src/apps/auth/roleChecker';

import EditPopup from 'src/components/ui/interactive/dropdown/EditCollect';
import DeleteModal from 'src/components/ui/interactive/modal/custom/DeleteModal';
import EditShareForm from 'src/components/ui/interactive/dropdown/EditShareForm';
import { ultrawhite, ultrawhitergba, ultrablackrgba } from 'src/style/theme/Color';

import { connect } from 'react-redux';
import { ReduxAppState } from 'src/redux/reducers';
import { openCustomModal, closeCustomModal } from 'src/redux/actions/modalAction';
import { CustomModalType } from 'src/types/ModalTypes';
import { ITokenPayload } from 'src/apps/auth/Auth';

const Container = styled.div`
  border-radius: 4px;
  box-shadow: 0 0 50px 0 ${ultrablackrgba(0.1)};
`;
const Header = styled.div`
  height: 88px;
  display: flex;
  position: relative;
  flex-flow: row;
  align-items: center;
  color: ${ultrawhite};
  background-image: linear-gradient(95deg, #2ec3c2, #0cafcc);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;
const HeaderText = styled.div`
  padding: 16px 0 16px 32px;
  width: 70%;
`;
const HeaderRoute = styled.div`
  color: ${ultrawhitergba(0.8)};
`;
const Feature = styled.div`
  height: 36px;
  line-height: 36px;
  font-size: 24px;
  font-weight: bold;
  span {
    font-weight: normal;
  }
`;
const Anchor = styled.div`
  width: 30%;
  padding: 0 32px 0 0;
`;
const HeaderFunctions = styled.div`
  float: right;
  display: flex;
  position: relative;
`;
const HeaderFunction = styled.div`
  margin: 0 0 0 20px;
  display: flex;
  cursor: pointer;
  i {
    display: block;
    margin: 2px 8px 0 0;
  }
`;
const Subheaders = styled.div`
  min-height: 60px;
  display: flex;
  align-items: center;
  color: #979ea1;
  background-color: #edf6f7;
  span {
    width: calc(100% - 88px);
    margin: 20px 32px 20px 0;
  }
`;
const Description = styled.div`
  padding: 0 0 0 32px;
  width: 88px;
`;
const Content = styled.div`
  padding: 0 0 24px;
  min-height: 450px;
`;

interface IReduxMappingProps {
  member: ITokenPayload;
  isDeletePatentSubscriptionModalOpen: boolean;
  openCustomModal: (customModalType: CustomModalType) => void;
  closeCustomModal: () => void;
}

interface IProps extends IReduxMappingProps {
  isShare: boolean;
  shareUser?: string;
  sector: string;
  feature: string;
  name: string;
  keyword: string;
  children: JSX.Element;
  trackId: number;
  noTracks: boolean;
  submit: (option: string, data: any) => void;
}

const TrackContent: React.FC<IProps> = props => {
  const [edit, setEdit] = useState(false);
  const [share, setShare] = useState(false);

  const submitRename = async (newName: string) => {
    try {
      const response = await Api().patch(`patent/subscription/${props.trackId}`, {
        name: newName,
      });

      if (response.status === 200) {
        setEdit(false);
        props.submit('EditTrackName', newName);
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const submitRemove = async () => {
    try {
      if (props.isShare) {
        await Api().delete(`patent/subscription/${props.trackId}/share`);
        props.submit('DeleteTrackShare', null);
      } else {
        await Api().delete(`patent/subscription/${props.trackId}`);
        props.submit('DeleteTrack', null);
      }

      props.closeCustomModal();
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <Container>
      <Header>
        <HeaderText>
          <HeaderRoute>
            首頁&nbsp;/&nbsp;{props.sector}&nbsp;/&nbsp;
            {props.feature}&nbsp;/&nbsp;{props.name}
          </HeaderRoute>
          <Feature>
            {props.name}&ensp;{props.shareUser && <span>來自{props.shareUser}的分享</span>}
          </Feature>
        </HeaderText>
        <Anchor>
          {!props.noTracks && (
            <HeaderFunctions>
              {!props.isShare && (
                <>
                  <HeaderFunction onClick={() => setEdit(!edit)}>
                    <i>
                      <FaPencilAlt />
                    </i>
                    編輯
                  </HeaderFunction>
                  {edit && (
                    <EditPopup
                      name={props.name}
                      show={edit}
                      submit={submitRename}
                      setShow={setEdit}
                    />
                  )}
                  {isCompanyMember() && (
                    <>
                      <HeaderFunction onClick={() => setShare(!share)}>
                        <i>
                          <FaShareAlt />
                        </i>
                        分享
                      </HeaderFunction>
                      {share && (
                        <EditShareForm show={share} setShow={setShare} id={props.trackId} />
                      )}
                    </>
                  )}
                </>
              )}
              <HeaderFunction
                onClick={() =>
                  props.openCustomModal(
                    props.isShare
                      ? CustomModalType.DELETE_PATENT_SUBSCRIPTION_SHARE
                      : CustomModalType.DELETE_PATENT_SUBSCRIPTION,
                  )
                }
              >
                <i>
                  <FaTrashAlt />
                </i>
                刪除
              </HeaderFunction>
              {props.isDeletePatentSubscriptionModalOpen && (
                <DeleteModal
                  message="刪除此專利追蹤後，您在內所有追蹤的專利均會消失，請問是否要刪除追蹤？"
                  submit={submitRemove}
                />
              )}
            </HeaderFunctions>
          )}
        </Anchor>
      </Header>
      {!props.noTracks && (
        <Subheaders>
          <Description>條件</Description>
          <span>{props.keyword}</span>
        </Subheaders>
      )}
      <Content>{props.children}</Content>
    </Container>
  );
};

const mapStateToProps = (state: ReduxAppState) => {
  return {
    isDeletePatentSubscriptionModalOpen:
      state.modalReducer.customModalType === CustomModalType.DELETE_PATENT_SUBSCRIPTION ||
      state.modalReducer.customModalType === CustomModalType.DELETE_PATENT_SUBSCRIPTION_SHARE,
    member: state.memberReducer.memberPayload,
  };
};

const mapDispatchToProps = {
  openCustomModal,
  closeCustomModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TrackContent);
