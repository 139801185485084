import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { FaPlus } from 'react-icons/fa';
import { Button } from 'src/style/theme/Common';
import Checkbox from 'src/components/ui/interactive/checkbox/Checkbox';
import _ from 'lodash';
import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import {
  white,
  blue2,
  iceblue1,
  iceblue5,
  iceblue6,
  iceblue8,
  alarm5,
} from 'src/style/theme/Color';
import { Backdrop } from 'src/style/ModalStyle';
import { zModal } from 'src/style/theme/Z-Index';
import { connect } from 'react-redux';
import { closeCustomModal } from 'src/redux/actions/modalAction';

const ModalContainer = styled.div`
  width: 300px;
  margin: 180px auto;
  color: ${iceblue1};
  background-color: ${iceblue8};
  border-radius: 4px;
  box-shadow: 0 5px 50px 0 rgba(0, 63, 84, 0.2);
  padding: 16px 32px;
`;

const EditTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 16px;
`;

const EditBody = styled.div`
  margin-bottom: 32px;
`;

const NewCategoryBox = styled.div`
  div {
    margin-bottom: 4px;
  }
  margin-bottom: 24px;
`;

const NewCategoryName = styled.div<{ hasError: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  background-color: ${white};
  > input {
    width: 100%;
    border-radius: 4px;
    border: solid 1px ${props => (props.hasError ? alarm5 : iceblue6)};
    padding: 8px 36px 8px 8px;
    &::placeholder {
      color: ${iceblue5};
    }
  }
  > svg {
    position: absolute;
    right: 16px;
    color: ${blue2};
    font-size: 16px;
    font-weight: bolder;
    :hover {
      cursor: pointer;
    }
  }
`;

const NewCategoryError = styled.div`
  color: ${alarm5};
  margin-bottom: 16px;
`;

const EditFooter = styled.div`
  text-align: center;
  margin-bottom: 16px;
`;

interface IReduxMappingProps {
  closeCustomModal: () => void;
}

interface IProps extends IReduxMappingProps {
  patentDataId: number;
  collectData: Array<{
    id: number;
    name: string;
    collected: boolean;
  }>;
  submit: (newData: any) => void;
  submitAddCollect: () => void;
}

const modal = document.getElementById('modal-custom');
const portalDiv = modal ? modal : document.createElement('div');

const EditCollectionModal: React.FC<IProps> = props => {
  const [checkedData, setCheckedData] = useState(_.cloneDeep(props.collectData));

  const [newCategoryName, setNewCategoryName] = useState('');
  const [newCategoryError, setNewCategoryError] = useState('');

  const handleAddCollect = async () => {
    if (newCategoryName.trim() === '') {
      setNewCategoryError('分類名稱請勿空白。');
    } else {
      try {
        const res = await Api({
          'Content-Type': 'application/json',
        }).post('patent/collection/category', newCategoryName);

        if (res.status === 200) {
          const dataRes = await Api().get('patent/collection/category', {
            params: {
              patentDataId: props.patentDataId,
            },
          });
          setCheckedData(dataRes.data);
          props.submitAddCollect();
        }
      } catch (error) {
        errorHandler(error);
      }
      setNewCategoryError('');
    }
  };

  return ReactDOM.createPortal(
    <Backdrop zIndex={zModal.custom}>
      <ModalContainer>
        <EditTitle>變更分類收藏</EditTitle>
        <EditBody>
          {checkedData.map((item, index) => (
            <div key={item.name}>
              <Checkbox name={item.name} checked={item.collected}>
                <input
                  id={item.name}
                  type="checkbox"
                  name={item.name}
                  checked={item.collected}
                  onChange={event => {
                    const targetObj = checkedData[index];
                    targetObj.collected = event.target.checked;
                    checkedData[index] = targetObj;

                    setCheckedData([...checkedData]);
                  }}
                />
              </Checkbox>
            </div>
          ))}
        </EditBody>
        <NewCategoryBox>
          <div>新增分類</div>
          <NewCategoryName hasError={newCategoryError !== ''}>
            <input
              type="text"
              placeholder="請輸入分類名稱..."
              value={newCategoryName}
              onChange={event => {
                const inputValue = event.target.value;
                setNewCategoryName(inputValue);
                if (inputValue.trim() === '') {
                  setNewCategoryError('分類名稱請勿空白。');
                } else {
                  setNewCategoryError('');
                }
              }}
            />
            <FaPlus onClick={() => handleAddCollect()} />
          </NewCategoryName>
          <NewCategoryError>{newCategoryError}</NewCategoryError>
        </NewCategoryBox>
        <EditFooter>
          <Button template="primary" onClick={() => props.submit(checkedData)}>
            儲存
          </Button>
          <Button onClick={() => props.closeCustomModal()}>取消</Button>
        </EditFooter>
      </ModalContainer>
    </Backdrop>,
    portalDiv,
  );
};

const mapDispatchToProps = {
  closeCustomModal,
};

export default connect(
  null,
  mapDispatchToProps,
)(EditCollectionModal);
