import React from 'react';
import styled from 'styled-components';
import { blue2, blue3, blue7, iceblue2, iceblue7, iceblue8 } from 'src/style/theme/Color';
import MiniLoader from 'src/components/ui/interactive/MiniLoader';

const Container = styled.div<{ padding?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${props => props.padding || '16px 32px'};
`;

const Content = styled.div`
  font-size: 18px;
  font-weight: 500;
  width: 100%;
  padding: 16px 0;
  text-align: center;
  border-radius: 4px;
`;

const MoreMessage = styled(Content)`
  color: ${blue2};
  background-color: ${blue7};
  :hover {
    cursor: pointer;
  }
`;

const ErrorMessage = styled(Content)`
  color: ${iceblue2};
  background-color: ${iceblue8};
  span {
    font-size: inherit;
    color: ${blue3};
    :hover {
      cursor: pointer;
    }
  }
`;

const EndMessage = styled(Content)`
  color: ${iceblue7};
`;

const PlaceHolder = styled.div`
  height: 58px;
`;

export enum LoadMoreStatus {
  DEFAULT,
  LOADING,
  BUTTON,
  ERROR,
  END,
}

interface IProps {
  status: LoadMoreStatus;
  onLoadClick: () => void;
  onReloadClick: () => void;
  errorMessage?: string;
  padding?: string;
}

/** 取得更多資料按鈕 */
const LoadMore: React.FC<IProps> = props => {
  const renderSwitch = (status: LoadMoreStatus) => {
    switch (status) {
      case LoadMoreStatus.DEFAULT:
        return <PlaceHolder />;
      case LoadMoreStatus.BUTTON:
        return <MoreMessage onClick={props.onLoadClick}>Load more...</MoreMessage>;
      case LoadMoreStatus.LOADING:
        return <MiniLoader />;
      case LoadMoreStatus.ERROR:
        return (
          <ErrorMessage>
            {props.errorMessage || '資料載入失敗'}
            ，點擊<span onClick={props.onReloadClick}>重試</span>。
          </ErrorMessage>
        );
      case LoadMoreStatus.END:
        return <EndMessage>您已到達最底部。</EndMessage>;
    }
  };

  return <Container padding={props.padding}>{renderSwitch(props.status)}</Container>;
};

export default LoadMore;
