import React from 'react';

import { StatusPage } from 'src/components/common/status-page/StatusPage';

const textList = [
  '您所在的區域/國家無法使用此系統',
  'The Service is not available in your region/country.',
];

export const InvalidIp = () => <StatusPage textList={textList} />;
