import {
  CHANGE_CHART_TITLE,
  CHANGE_SELECTED_FACET_FIELD,
  CHANGE_SELECTED_ANALYSIS_NUM,
  CHANGE_SELECTED_CHART_TYPE,
  CLEAR_STATISTICS_FORM,
  StatisticsFormState,
  StatisticsFormActionTypes,
} from './types';

const initialState: StatisticsFormState = {
  chartTitle: '',
  selectedItem: {
    facetField: { name: '請選擇', value: '' }, // name: keyCode, value: index
    analysisNum: { name: '5', value: '5' }, // name: count, value: count
  },
  selectedChartType: '',
};

const resultToolbarReducer = (
  state = initialState,
  action: StatisticsFormActionTypes,
): StatisticsFormState => {
  switch (action.type) {
    case CHANGE_CHART_TITLE:
      return {
        ...state,
        chartTitle: action.chartTitle,
      };
    case CHANGE_SELECTED_FACET_FIELD: {
      const prevSelectedItem = state.selectedItem;
      return {
        ...state,
        selectedItem: {
          ...prevSelectedItem,
          facetField: action.facetField,
        },
      };
    }
    case CHANGE_SELECTED_ANALYSIS_NUM: {
      const prevSelectedItem = state.selectedItem;
      return {
        ...state,
        selectedItem: {
          ...prevSelectedItem,
          analysisNum: action.analysisNum,
        },
      };
    }
    case CHANGE_SELECTED_CHART_TYPE:
      return {
        ...state,
        selectedChartType: action.selectedChartType,
      };
    case CLEAR_STATISTICS_FORM:
      return {
        ...initialState,
      };
    default:
      return { ...state };
  }
};

export default resultToolbarReducer;
