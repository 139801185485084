import React, { memo } from 'react';
import Highlighter from 'react-highlight-words';

import { dateFormatter } from 'src/utils/Formatter';
import PatentInventorName from './PatentInventorName';
import PatentDisplayImage from './PatentDisplayImage';
import PatentMiniImage from './PatentMiniImage';
import PatentInlineImage from './PatentInlineImage';
import { IGetPatentOcrLabelTypes, PatentDataTypes } from 'src/types/api/PatentApiTypes';
import {
  PatentHalfPageTitle,
  TextBox,
  TextBoxTitle,
  TextBoxContent,
  TextBoxOl,
  InfoArea,
  InfoAreaColumn,
  InfoBox,
  InfoBoxTitle,
  InfoBoxContent,
  InfoIpcBox,
  SpaceBetween,
  PatentModalTitle,
  PatentModalImgs,
  Anchor,
} from 'src/style/PatentContentStyle';
import { iceblue5 } from 'src/style/theme/Color';
import KeywordFeedback from 'src/components/patent/content/KeywordFeedback';
import { getImgUrls } from 'src/utils/PatentUtils';

export enum templates {
  FullPage, //0:全頁排版
  HalfPage, //1:半頁排版(雙欄瀏覽)
  HalfPageWithoutTitle, //2:半頁排版,無標題
  Modal, //3:跳窗排版(友善列印或以圖找圖預覽)
}

interface IHighlight {
  setHighlight: ({ children, keywords }: { children: any; keywords: string[] }) => any;
}

interface IProps {
  template: templates;
  patentData: PatentDataTypes;
  searchText?: string[]; //內文highlight參數
  tagColorArray?: string[]; //內文highlight參數
  anchorIndex?: number; //跳讀功能參數
  toggleComparedPane?: (activePane: string) => void;
  setLightboxFirstShowImage?: (lightboxFirstShowImage: number) => void;
  lightboxFirstShowImage?: number;
  patentOcrLabel?: IGetPatentOcrLabelTypes | null;
  isOcrLabelLoading?: boolean;
  isPatentCompare?: boolean;
}

interface IState {
  displayImgIdx: number;
  lightboxIsOpen: boolean;
  activeIndex: number;
  caseSensitive: boolean;
}
const HighlightText = memo(
  ({ activeIndex, caseSensitive, searchText, text, setHighlight }: any) => {
    return (
      <Highlighter
        activeIndex={activeIndex}
        caseSensitive={caseSensitive}
        searchWords={searchText}
        textToHighlight={text}
        highlightTag={setHighlight}
        autoEscape={true}
      />
    );
  },
);
class PatentContent extends React.Component<IProps, IState> implements IHighlight {
  constructor(props: IProps) {
    super(props);
    this.state = {
      displayImgIdx: 0,
      lightboxIsOpen: false,
      activeIndex: -1,
      caseSensitive: false,
    };
  }

  setHighlight({ children }: any) {
    const colorNumber = this.props.searchText ? this.props.searchText.indexOf(children) : -1;
    let classColor: any = '';
    if (this.props.tagColorArray && this.props.tagColorArray[children]) {
      classColor = this.props.tagColorArray[children].replace('#', '');
    }
    if (colorNumber > -1) {
      return (
        <mark
          style={{
            // searchText改變時PatentContent會先re-render一次，此時tagColorArray尚無該searchText的對應顏色，
            // 所以先給定背景色為transparent，避免顯示Highlghter預設的黃色。
            //@ts-ignore
            backgroundColor: this.props.tagColorArray[children] || 'transparent',
            whiteSpace: 'nowrap',
            padding: '2px 4px',
            lineHeight: '1.7',
            borderRadius: '4px',
          }}
          className={`highlight-${classColor}`}
        >
          {children}
        </mark>
      );
    } else {
      return (
        <mark
          style={{
            backgroundColor: '#5F686C',
            color: 'white',
            whiteSpace: 'nowrap',
            padding: '2px 4px',
            lineHeight: '1.7',
            borderRadius: '4px',
          }}
          className="highlight-default"
        >
          {children}
        </mark>
      );
    }
  }
  /** 加上文內圖片及文內搜尋功能所需標籤 */
  highlightText = (text: string) => {
    const patentUuid = this.props.patentData.content ? this.props.patentData.content.id : '';
    const needHighlight =
      this.props.tagColorArray && this.props.searchText && this.props.template !== templates.Modal;
    // 文內圖路徑前後以3個冒號分隔，格式如: "內文內文:::02_image010.JPG:::內文內文內文"
    // 此切法遇到圖片前有冒號時會有錯誤，若改用正則表達雖可切得較精準但文內圖很多時會發生記憶體不足
    return text.split(':::').map((part, i) => {
      const ext = part.substring(part.length - 4).toUpperCase();
      // 內文圖片
      if (ext === '.JPG' || ext === '.TIF' || ext === '.PNG') {
        return <PatentInlineImage key={i} uuid={patentUuid} fileName={part.replace(':', '')} />;
      }
      // 內文文字,需highlight
      else if (needHighlight) {
        return (
          <HighlightText
            key={i}
            text={part}
            searchText={this.props.searchText}
            activeIndex={this.state.activeIndex}
            caseSensitive={this.state.caseSensitive}
            setHighlight={({ children }: any) => this.setHighlight({ children })}
          />
        );
      }
      // 內文文字,純文字
      else {
        return part;
      }
    });
  };

  /** 處理內文換行符號\n */
  splitText = (text: string, idx: number) => {
    return (
      <TextBoxContent key={idx} className="translate">
        {text.split('\n').map((part, key) => {
          return <p key={key}>{this.highlightText(part)}</p>;
        })}
      </TextBoxContent>
    );
  };

  handleImgModal = (currentImage?: number) => {
    this.props.setLightboxFirstShowImage &&
      this.props.setLightboxFirstShowImage(currentImage ? currentImage : 0);
    if (this.props.toggleComparedPane) {
      this.props.isPatentCompare !== true
        ? this.props.toggleComparedPane('PatentOcrLabel')
        : this.props.toggleComparedPane('PatentCompareOcrLabel');
    }
  };

  handleMiniImgClick = (e: any) => {
    var idx = Number(e.currentTarget.getAttribute('data-idx'));
    this.handleImgModal(idx);
  };

  render() {
    const patentContent = this.props.patentData.content;
    const kind =
      (patentContent && patentContent.bibliographic.publicationReference.documentId.kind) || '';
    const notKindA =
      kind &&
      !kind.includes('A') &&
      !kind.includes('P1') &&
      !kind.includes('P4') &&
      !kind.includes('P9');
    const imgUrls =
      patentContent && patentContent.img_alias && !this.props.isOcrLabelLoading
        ? getImgUrls(patentContent.id, patentContent.img_alias, this.props.patentOcrLabel || null)
        : [];

    const infoName = (addressbook: { name?: string[]; lang: string[] }) => {
      return (
        addressbook.name &&
        addressbook.name.map((item, idx) => {
          const lang = addressbook.lang;
          //國別為TW時，同一人的中文名和英文名不換行
          const newLine =
            patentContent &&
            (patentContent.country !== 'TW' ||
              lang[idx].toLowerCase() !== 'tw' ||
              lang[idx] === lang[idx + 1] ||
              idx + 1 === lang.length);
          return (
            <span key={idx}>
              {this.highlightText(item)}
              {newLine ? <br /> : '\u2003'}
            </span>
          );
        })
      );
    };
    const info = (oneColumn: boolean) =>
      patentContent !== null && (
        <InfoArea oneColumn={oneColumn}>
          <InfoAreaColumn basis="45%">
            <InfoBox>
              <InfoBoxTitle>國&emsp;別：</InfoBoxTitle>
              <InfoBoxContent>{this.highlightText(patentContent.country)}</InfoBoxContent>
            </InfoBox>
            <InfoBox>
              <InfoBoxTitle>優先權：</InfoBoxTitle>
              <InfoBoxContent>
                {patentContent.bibliographic.priorityClaim &&
                  patentContent.bibliographic.priorityClaim.country.map((item, idx) => (
                    <p key={idx}>
                      {this.highlightText(item)}
                      &nbsp;
                      {patentContent &&
                        this.highlightText(
                          patentContent.bibliographic.priorityClaim.docNumber[idx],
                        )}
                      &nbsp;
                      {patentContent &&
                        this.highlightText(
                          dateFormatter(patentContent.bibliographic.priorityClaim.date[idx]),
                        )}
                    </p>
                  ))}
              </InfoBoxContent>
            </InfoBox>
            <InfoBox>
              <InfoBoxTitle>申請號：</InfoBoxTitle>
              <InfoBoxContent>
                {this.highlightText(
                  patentContent.bibliographic.applicationReference.documentId.docNumber,
                )}
              </InfoBoxContent>
            </InfoBox>
            <InfoBox>
              <InfoBoxTitle>申請日：</InfoBoxTitle>
              <InfoBoxContent>
                {this.highlightText(
                  dateFormatter(patentContent.bibliographic.applicationReference.documentId.date),
                )}
              </InfoBoxContent>
            </InfoBox>
            <InfoBox>
              <InfoBoxTitle>{notKindA ? '公告號：' : '公開號：'}</InfoBoxTitle>
              <InfoBoxContent>
                {this.highlightText(
                  patentContent.bibliographic.publicationReference.documentId.docNumber + kind,
                )}
              </InfoBoxContent>
            </InfoBox>
            <InfoBox>
              <InfoBoxTitle>{notKindA ? '公告日：' : '公開日：'}</InfoBoxTitle>
              <InfoBoxContent>
                {patentContent.bibliographic.publicationReference &&
                patentContent.bibliographic.publicationReference.documentId &&
                patentContent.bibliographic.publicationReference.documentId.date
                  ? this.highlightText(
                      dateFormatter(
                        patentContent.bibliographic.publicationReference.documentId.date,
                      ),
                    )
                  : '無日期資訊'}
              </InfoBoxContent>
            </InfoBox>
          </InfoAreaColumn>
          <InfoAreaColumn basis="50%">
            <InfoBox>
              <InfoBoxTitle>申請人：</InfoBoxTitle>
              <InfoBoxContent>
                {patentContent.bibliographic.parties &&
                  patentContent.bibliographic.parties.applicant &&
                  infoName(patentContent.bibliographic.parties.applicant.addressbook)}
              </InfoBoxContent>
            </InfoBox>
            <InfoBox>
              <InfoBoxTitle>代理人：</InfoBoxTitle>
              <InfoBoxContent>
                {patentContent.bibliographic.parties &&
                  patentContent.bibliographic.parties.agent &&
                  infoName(patentContent.bibliographic.parties.agent.addressbook)}
              </InfoBoxContent>
            </InfoBox>
            <InfoBox>
              <InfoBoxTitle>發明人：</InfoBoxTitle>
              <InfoBoxContent>
                {patentContent.bibliographic.parties &&
                  patentContent.bibliographic.parties.inventor && (
                    <PatentInventorName
                      country={patentContent.country}
                      addressbook={patentContent.bibliographic.parties.inventor.addressbook}
                      highlightText={this.highlightText}
                    />
                  )}
              </InfoBoxContent>
            </InfoBox>
          </InfoAreaColumn>
        </InfoArea>
      );

    // 若LOC有資料時為設計專利，不顯示IPC
    const infoIpc =
      patentContent &&
      patentContent.bibliographic.classificationLocarno &&
      patentContent.bibliographic.classificationLocarno.mainClassification !== '' ? (
        <InfoIpcBox inline>
          <InfoBoxTitle>國際工業設計分類號/LOC：</InfoBoxTitle>
          <InfoBoxContent>
            {patentContent.bibliographic.classificationLocarno.mainClassification}
          </InfoBoxContent>
        </InfoIpcBox>
      ) : (
        this.props.patentData.ipc && (
          <InfoIpcBox>
            <InfoBoxTitle>國際分類號/IPC：</InfoBoxTitle>
            {this.props.patentData.ipc.map((item, idx) => (
              <InfoBoxContent key={idx}>{item}</InfoBoxContent>
            ))}
          </InfoIpcBox>
        )
      );

    const keywordsText = patentContent &&
      patentContent.keyword &&
      patentContent.keyword.length > 0 && (
        <KeywordFeedback keywords={patentContent.keyword.slice(0, 3)} dot={false} />
      );

    const abstractText =
      patentContent &&
      patentContent.inventionSpecification &&
      patentContent.inventionSpecification.abstract &&
      patentContent.inventionSpecification.abstract.text;

    const abstract = (abstractText || keywordsText) && (
      <TextBox>
        {abstractText && (
          <>
            <Anchor id={'abstract' + (this.props.anchorIndex ? this.props.anchorIndex : '')} />
            <TextBoxTitle>摘要</TextBoxTitle>
            {abstractText.map((item, idx) => this.splitText(item, idx))}
          </>
        )}
        {keywordsText && (
          <TextBoxContent color={iceblue5}>自動關鍵字：{keywordsText}</TextBoxContent>
        )}
      </TextBox>
    );

    const images = imgUrls.length !== 0 && (
      <>
        {this.props.template === templates.FullPage ? (
          // 全頁排版才有大圖預覽
          <PatentDisplayImage
            images={imgUrls.slice(0, 4)}
            handleDisplayImgClick={this.handleImgModal}
            handleMiniImgClick={this.handleMiniImgClick}
            total={imgUrls.length}
          />
        ) : (
          // 半頁排版只有縮圖
          <TextBox>
            <PatentMiniImage
              images={imgUrls.slice(0, 4)}
              openLightbox={this.handleMiniImgClick}
              size="120px"
              totalImg={imgUrls.length}
            />
          </TextBox>
        )}
      </>
    );

    const absAndInfoAndImg = patentContent && (
      <>
        {(this.props.template === templates.HalfPage ||
          this.props.template === templates.HalfPageWithoutTitle) && (
          // 半頁排版: 標題->info(單行)->縮圖->摘要
          <>
            {this.props.template === templates.HalfPage && (
              <PatentHalfPageTitle>
                {patentContent.bibliographic.inventionTitle.title.join(' | ')}
              </PatentHalfPageTitle>
            )}
            {info(true)}
            {infoIpc}
            {images}
            {abstract}
          </>
        )}
        {this.props.template === templates.FullPage && abstractText && (
          // 全頁排版&有摘要: 摘要(左)大圖(右)->info(兩行)
          <>
            <SpaceBetween alignTop>
              {abstract}
              {images}
            </SpaceBetween>
            {info(false)}
            {infoIpc}
          </>
        )}
        {this.props.template === templates.FullPage && !abstractText && (
          // 全頁版面&無摘要(可能有關鍵字): info(左/單行)大圖(右)->infoIpc; 若無圖則info變兩行
          <>
            <SpaceBetween>
              {abstract ? (
                <div>
                  {abstract}
                  {info(imgUrls.length > 0)}
                </div>
              ) : (
                info(imgUrls.length > 0)
              )}
              {images}
            </SpaceBetween>
            {infoIpc}
          </>
        )}
        {this.props.template === templates.Modal && (
          // 跳窗排版: 標題->摘要->info(兩行)->infoIpc->縮圖
          <>
            <PatentModalTitle className="translate">
              {patentContent.bibliographic.inventionTitle.title.join(' | ')}
            </PatentModalTitle>
            {abstract}
            {info(false)}
            {infoIpc}
            <PatentModalImgs>
              {imgUrls.slice(0, 4).map((item, idx) => (
                <img key={idx} src={item} alt="" />
              ))}
            </PatentModalImgs>
          </>
        )}
      </>
    );

    const descriptionAndClaim = patentContent && patentContent.inventionSpecification && (
      <>
        {patentContent.inventionSpecification.description && (
          <TextBox>
            <Anchor id={'description' + (this.props.anchorIndex ? this.props.anchorIndex : '')} />
            <TextBoxTitle>說明</TextBoxTitle>
            {patentContent.inventionSpecification.description.technicalField &&
              patentContent.inventionSpecification.description.technicalField.map((item, idx) =>
                this.splitText(item, idx),
              )}
            {patentContent.inventionSpecification.description.backgroundArt &&
              patentContent.inventionSpecification.description.backgroundArt.map((item, idx) =>
                this.splitText(item, idx),
              )}
            {patentContent.inventionSpecification.description.disclosure &&
              patentContent.inventionSpecification.description.disclosure.map((item, idx) =>
                this.splitText(item, idx),
              )}
            {patentContent.inventionSpecification.description.modeForInvention &&
              patentContent.inventionSpecification.description.modeForInvention.map((item, idx) =>
                this.splitText(item, idx),
              )}
            {patentContent.inventionSpecification.description.descriptionOfDrawings &&
              patentContent.inventionSpecification.description.descriptionOfDrawings
                .descriptionOfElement &&
              patentContent.inventionSpecification.description.descriptionOfDrawings.descriptionOfElement.map(
                (item, idx) => this.splitText(item, idx),
              )}
            {patentContent.inventionSpecification.description.descriptionOfDrawings &&
              patentContent.inventionSpecification.description.descriptionOfDrawings.text &&
              patentContent.inventionSpecification.description.descriptionOfDrawings.text.map(
                (item, idx) => this.splitText(item, idx),
              )}
            {patentContent.inventionSpecification.description.sequenceListText &&
              patentContent.inventionSpecification.description.sequenceListText.map((item, idx) =>
                this.splitText(item, idx),
              )}
            {patentContent.inventionSpecification.description.text &&
              patentContent.inventionSpecification.description.text.map((item, idx) =>
                this.splitText(item, idx),
              )}
          </TextBox>
        )}
        {patentContent.inventionSpecification.claim &&
          patentContent.inventionSpecification.claim.claimText && (
            <TextBox>
              <Anchor id={'claim' + (this.props.anchorIndex ? this.props.anchorIndex : '')} />
              <TextBoxTitle>範圍</TextBoxTitle>
              <TextBoxOl
                className="translate"
                hideNum={patentContent.inventionSpecification.claim.claimText[0].startsWith('1')}
              >
                {patentContent.inventionSpecification.claim.claimText.map((item, idx) => (
                  <li key={idx}>{this.highlightText(item)}</li>
                ))}
              </TextBoxOl>
            </TextBox>
          )}
      </>
    );

    return (
      patentContent && (
        <>
          {absAndInfoAndImg}
          {descriptionAndClaim}
        </>
      )
    );
  }
}

export default PatentContent;
