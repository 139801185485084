import { ITokenPayload, IAuthData } from 'src/apps/auth/Auth';

export const MEMBER_DECODE_TOKEN = 'iPKM/member/DECODE_TOKEN';

export interface IMemberState {
  readonly memberPayload: ITokenPayload;
  readonly authData: IAuthData;
}

interface MemberDecodeTokenType {
  type: typeof MEMBER_DECODE_TOKEN;
  payload: ITokenPayload;
  authData: IAuthData;
}

export type IReduxMemberActionType = MemberDecodeTokenType;
