export const defaultFieldValue = {
  name: '',
  keyword: '',
};

export const formInitialValues = {
  technologyList: new Array(1).fill(defaultFieldValue),
  // 若使用同一個物件來初始化會導致 onSubmit 時 touched 內容不正確，所以解構 clone defaultFieldValue。
  functionList: new Array(1).fill({ ...defaultFieldValue }),
};
