import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Formik, Field, Form, FormikProps } from 'formik';

import { Auth } from 'src/apps/auth/Auth';
import { Api } from 'src/api/helpers/apiBase';
import { errorHandler } from 'src/apps/error-handler/ErrorHandler';
import {
  nonEmptyAccountValidator,
  nonEmptyPwdValidator,
  nonEmptyCaptchaValidator,
} from 'src/apps/validation/formValidator';

import LOGO from 'src/assets/images/ipkm-logo-2x.png';
import Checkbox from 'src/components/ui/interactive/checkbox/Checkbox';
import {
  blue3,
  iceblue5,
  pink3,
  ultrawhite,
  ultrablackrgba,
  iceblue6,
  blue2,
  blue7,
  white2,
  iceblue3,
} from 'src/style/theme/Color';

import TagManager from 'react-gtm-module';

import { connect } from 'react-redux';
import { ReduxAppState } from 'src/redux/reducers';
import { closeLoginModal } from 'src/redux/actions/modalAction';
import { getMemberAuthAndPayloadFromJwt } from 'src/redux/member/action';
import { FaArrowRight } from 'react-icons/fa';
import { HoverMask } from 'src/style/SideMenuStyle';

enum LoginWay {
  IPKM = '/login',
  TIPONET = '/login/tiponet',
}

const Anchor = styled.div`
  width: 376px;
  border-radius: 4px;
  box-shadow: 0 0 50px 0 ${ultrablackrgba(0.1)};
  background-color: ${ultrawhite};
`;
const LogoStyle = styled.div`
  position: relative;
  img {
    width: 128px;
    height: 42px;
    display: block;
    top: -74px;
    left: 124px;
    position: absolute;
  }
`;
const Topbar = styled.div<{ loginWay?: LoginWay }>`
  height: 88px;
  color: #ffffff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-image: ${props =>
    props.loginWay === LoginWay.TIPONET
      ? 'linear-gradient(to left, #005798, #0090c9 0%)'
      : `linear-gradient(103deg, #2ec3c2, ${blue3})`};
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  line-height: 88px;
`;
const Content = styled.div`
  padding: 32px 32px 0;
`;
const FieldName = styled.div`
  width: 25%;
`;
const Input = styled.div<{ width?: string }>`
  display: flex;
  align-items: center;
  input {
    width: ${props => (props.width ? props.width : '75%')};
    height: 30px;
    display: block;
    color: #333333;
    border-radius: 4px;
    padding-left: 8px;
    border: solid 1px ${iceblue6};
  }
  img {
    margin: 0 0 0 5%;
    width: 30%;
  }

  :focus-within {
    input {
      border: solid 1px ${blue3};
      outline: none;
    }
    ${FieldName} {
      color: ${blue2};
    }
  }
`;
const SpaceBetween = styled.div`
  display: flex;
  height: 24px;
`;
const WarningMsg = styled.div`
  color: ${pink3};
`;
const ImageCaptcha = styled.img`
  cursor: pointer;
`;
const BtnLogin = styled.div`
  margin: 0 0 8px 0;
  height: 40px;
  border-radius: 4px;
  color: #ffffff;
  background-color: #0cafcc;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
`;

const Selector = styled.div`
  position: relative;
  display: flex;
  width: 311px;
  height: 76px;
  border-radius: 4px;
  margin-bottom: 16px;
  padding: 0 24px;
  justify-content: space-between;
  cursor: pointer;

  svg {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 20px;
  }
`;

const TextBlock = styled.div`
  margin: auto 0;
  font-size: 20px;
`;

const IpkmSelector = styled(Selector)`
  color: ${white2};
  background-image: linear-gradient(104deg, #2ec3c2, ${blue3});
`;
const TiponetSelector = styled(Selector)`
  color: ${white2};
  background-image: linear-gradient(to left, #005798, #0090c9 0%);
`;
const GuestSelector = styled(Selector)`
  color: ${iceblue3};
  background-color: ${blue7};

  ${TextBlock} {
    font-size: 15px;
    div {
      font-size: 14px;
      color: #a7bbbd;
    }
  }
`;

const HelperLinks = styled.div`
  padding: 8px 0;
  color: #979ea1;
  text-align: center;
  font-size: 14px;
  a,
  span {
    color: ${iceblue5};
    :hover {
      color: ${blue3};
      cursor: pointer;
    }
  }
`;
const SignUp = styled.div`
  padding: 24px 0 32px 0;
  color: #7b8387;
  text-align: center;
  a {
    color: ${blue3};
  }
`;

interface IReduxMappingProps {
  isFromRoute?: boolean;
  closeLoginModal?: () => void;
  getMemberAuthAndPayloadFromJwt: () => void;
}
interface IProps extends RouteComponentProps, IReduxMappingProps {}

interface ILoginForm {
  loginId: string;
  password: string;
  captchaUuid: string;
  captcha: string;
  keepLogin: boolean;
}

interface ILoginWayAttribute {
  backgroundImage: string;
  registerUrl: string;
  forgetPwdUrl: string;
}

function LoginCore(props: IProps) {
  const [loginWay, setLoginWay] = useState<LoginWay>();
  const [captchaUriSuffix, setCaptchaUriSuffix] = useState(Date.now());
  const [captchaUuid, setCaptchaUuid] = useState('');

  useEffect(() => {
    const getCaptchaUuid = async () => {
      const res = await Api().get('captcha/uuid');
      setCaptchaUuid(res.data);
    };
    getCaptchaUuid();
  }, []);

  const reloadCaptcha = () => {
    setCaptchaUriSuffix(Date.now());
  };

  const [longAuth, setLongAuth] = useState(false);
  const isLoginPage = props.location.pathname === '/login';
  const handleSubmit = async (values: ILoginForm) => {
    if (loginWay) {
      try {
        const res = await Api().post(loginWay, {
          ...values,
          loginId: values.loginId.trim(),
          captcha: values.captcha.trim(),
        });

        Auth().setAuthToken({ ...res.data, isLogin: true }, longAuth);

        /* 將使用者帳號流水號傳至 GA */
        const tagManagerArgs = {
          dataLayer: {
            userId: Auth().getPayload().info.id,
          },
          dataLayerName: 'PageDataLayer',
        };
        TagManager.dataLayer(tagManagerArgs);

        props.getMemberAuthAndPayloadFromJwt();
        props.closeLoginModal && props.closeLoginModal();

        if (isLoginPage) {
          props.history.push('/');
        }
      } catch (error) {
        errorHandler(error);
      }
    }
  };

  const handleLink = (link: string) => {
    props.closeLoginModal && props.closeLoginModal();
    props.history.push(link);
  };
  const handleSkip = () => {
    const pageRedirect = (histLen: number) => {
      if (props.isFromRoute) {
        props.history.length > histLen ? props.history.goBack() : props.history.push('/');
      }
    };

    if (isLoginPage) {
      props.history.push('/');
    } else if (props.closeLoginModal) {
      props.closeLoginModal();
      pageRedirect(2);
    } else {
      pageRedirect(3);
    }
  };

  const getLoginSelector = () => (
    <Content>
      <IpkmSelector onClick={() => setLoginWay(LoginWay.IPKM)}>
        <HoverMask />
        <TextBlock>iPKM 帳號</TextBlock>
        <FaArrowRight />
      </IpkmSelector>
      <TiponetSelector onClick={() => setLoginWay(LoginWay.TIPONET)}>
        <HoverMask />
        <TextBlock>智慧財產權 e 網通帳號</TextBlock>
        <FaArrowRight />
      </TiponetSelector>
      <GuestSelector onClick={handleSkip}>
        <HoverMask />
        <TextBlock>
          使用訪客身份登入
          <div>可能無法使用部分功能</div>
        </TextBlock>
        <FaArrowRight />
      </GuestSelector>
    </Content>
  );

  const getLoginForm = () => (
    <Content>
      <Formik
        initialValues={{
          loginId: '',
          password: '',
          captchaUuid: '',
          captcha: '',
          keepLogin: longAuth,
        }}
        onSubmit={(values: ILoginForm) => {
          values.captchaUuid = captchaUuid;
          handleSubmit(values);
        }}
        render={(formikBag: FormikProps<ILoginForm>) => (
          <Form>
            <Input>
              <FieldName>帳號</FieldName>
              <Field type="text" name="loginId" validate={nonEmptyAccountValidator} />
            </Input>
            <SpaceBetween>
              <FieldName />
              {formikBag.errors.loginId && formikBag.touched.loginId && (
                <WarningMsg>{formikBag.errors.loginId}</WarningMsg>
              )}
            </SpaceBetween>
            <Input>
              <FieldName>密碼</FieldName>
              <Field type="password" name="password" validate={nonEmptyPwdValidator} />
            </Input>
            <SpaceBetween>
              <FieldName />
              {formikBag.errors.password && formikBag.touched.password && (
                <WarningMsg>{formikBag.errors.password}</WarningMsg>
              )}
            </SpaceBetween>
            <Input width="40%">
              <FieldName>驗證碼</FieldName>
              <Field type="text" name="captcha" validate={nonEmptyCaptchaValidator} />
              {captchaUuid && (
                <ImageCaptcha
                  src={
                    process.env.REACT_APP_API_URL +
                    'captcha?uuid=' +
                    captchaUuid +
                    '&time=' +
                    captchaUriSuffix
                  }
                  alt="captcha"
                  onClick={reloadCaptcha}
                />
              )}
            </Input>
            <SpaceBetween>
              <FieldName />
              {formikBag.errors.captcha && formikBag.touched.captcha && (
                <WarningMsg>{formikBag.errors.captcha}</WarningMsg>
              )}
            </SpaceBetween>
            <Checkbox name="保持登入 30 天" checked={longAuth}>
              <Field
                type="checkbox"
                name="keepLogin"
                onChange={() => {
                  formikBag.setFieldValue('keepLogin', !longAuth);
                  setLongAuth(prev => !prev);
                }}
              />
            </Checkbox>
            <SpaceBetween />
            <BtnLogin onClick={formikBag.submitForm}>登入</BtnLogin>
          </Form>
        )}
      />
      <HelperLinks>
        <span onClick={() => setLoginWay(undefined)}>返回</span>
        {LoginWay.IPKM === loginWay && (
          <>
            &emsp;|&emsp;
            <span onClick={() => handleLink('/user/forget-password')}>忘記密碼</span>
            &emsp;|&emsp;
            <Link to={'/question/faq/如何申請成為會員'} target="_blank">
              註冊
            </Link>
          </>
        )}
      </HelperLinks>
    </Content>
  );

  return (
    <Anchor>
      {isLoginPage && (
        <LogoStyle>
          <img src={LOGO} alt="" />
        </LogoStyle>
      )}
      <Topbar loginWay={loginWay}>
        {loginWay
          ? loginWay === LoginWay.IPKM
            ? 'iPKM 會員登入'
            : '智慧財產權 e 網通會員登入'
          : '請選擇登入方式'}
      </Topbar>
      {loginWay ? getLoginForm() : getLoginSelector()}
      <SignUp>
        客服信箱&nbsp;
        <a href="mailto:tipoeservice@tipo.gov.tw">tipoeservice@tipo.gov.tw</a>
      </SignUp>
    </Anchor>
  );
}

const mapStateToProps = (state: ReduxAppState) => {
  return {
    isFromRoute: state.modalReducer.loginModal.isFromRoute,
  };
};

const mapDispatchToProps = {
  getMemberAuthAndPayloadFromJwt,
  closeLoginModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginCore);
